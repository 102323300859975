import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { MaintenanceFields } from "../../../../models/reportTemplates/Maintenance";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { getPdfImagesCarousel } from "./imageConfigPdf";

export const getMaintenanceReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    conditionalRender(report.images.length > 0, () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.maintenance.description",
            marginTop: "S",
        },
        children: [getPdfImagesCarousel(images)],
    })),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.maintenance.summary.title",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.maintenance.performingMaintenanceQuestion",
                    fieldValue: getFieldValueByFieldName(MaintenanceFields.wereYouAbleToPerformTheMaintenance, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.maintenance.summary.description",
                    fieldValue: getFieldValueByFieldName(MaintenanceFields.description, report),
                    isRequired: false,
                    shouldAutoTranslate: true,
                },
            },
            ...getPdfUnknownFields(report, Object.values(MaintenanceFields), images),
        ],
    },
];
