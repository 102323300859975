export enum TourExceptionFields {
    comments = "comments",
    checkpoint = "checkpoint",
    exceptionAnswer = "exceptionAnswer",
    exceptionQuestion = "exceptionQuestion",
    tourName = "tourName",
    tourSessionID = "tourSessionID",
    checkpointID = "checkpointID",
    isTrueException = "isTrueException",
}
