import { IconType } from "@secuis/ccp-react-components";
import { CategoryKeys } from "src/components/Insights/common/ChartConfig";
import { ExceptionType } from "src/store/insights/InsightsModel";
import { getDatesArray } from "src/store/insights/PatrolDeviations/PatrolDeviationHelpers";

import { formatToRawDate } from "../../../helpers/date";
import { isNullOrUndefined } from "../../../helpers/PropertiesValueHelper";
import { EXCEPTION_REPORT_TYPES } from "./PatrolTasks.constants";

export const countTotal = (items: ExceptionType[], key: string = CategoryKeys.total) => items.reduce((acc, item) => acc + (item[key] || 0), 0);

export const formatCount = (count: number) => count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const formatDeviations = (
    deviations: {
        [k: string]: { [k: string]: number };
    },
    months: string[],
): ExceptionType[] => {
    if (!deviations || !Object.keys(deviations).length) {
        return [];
    }
    return months.map((x) => {
        return {
            month: Number(x),
            [CategoryKeys.criminalAct]: deviations[x]?.criminal_act_or_suspicious_behavior || 0,
            [CategoryKeys.facility]: deviations[x]?.facility || 0,
            [CategoryKeys.healthAndSafety]: deviations[x]?.health_and_safety || 0,
            [CategoryKeys.internalSecuritas]: deviations[x]?.internal_securitas || 0,
            [CategoryKeys.peopleAndAssets]: deviations[x]?.people_and_assets || 0,
            [CategoryKeys.total]: deviations[x]?.total || 0,
            [CategoryKeys.unspecified]: deviations[x]?.unspecified || 0,
        };
    });
};

export const getMonths = (start: Date, end: Date): string[] => {
    return [...new Set(getDatesArray(start, end, "MM"))];
};

export const generateRedirectUrl = (dateRange: Date[], regions: string[], categories: string[]) =>
    [
        `/reports?selectedReportTypes=${EXCEPTION_REPORT_TYPES.toString()}`,
        `selectedCategoryOne=${categories.toString()}`,
        `selectedRegions=${regions.toString()}`,
        `selectedStartDate=${formatToRawDate(dateRange[0])}`,
        `selectedEndDate=${formatToRawDate(dateRange[1])}`,
    ].join("&");

export const getTrend = (a: number, b: number, isAbsoluteValue?: boolean): { trendResult: string; iconVariant: IconType } => {
    let difference = 0;
    let trendIconVariant: IconType = "TrendMiddle";

    if (isNullOrUndefined(a)) {
        if (isNullOrUndefined(b)) {
            return {
                trendResult: isAbsoluteValue ? "0" : "0%",
                iconVariant: null,
            };
        }
        return {
            trendResult: isAbsoluteValue ? b.toString() : b.toString() + "%",
            iconVariant: null,
        };
    }
    difference = b - a;
    if (difference < 0) {
        trendIconVariant = "TrendGoingDown";
    }
    if (difference > 0) {
        trendIconVariant = "TrendGoingUp";
    }
    return {
        trendResult: isAbsoluteValue ? b.toString() : b.toString() + "%",
        iconVariant: trendIconVariant,
    };
};

export const getTrendType = (previousValue: number, currentValue: number) => {
    const difference = currentValue - previousValue;
    if (difference === 0) {
        return "TrendMiddle";
    }
    if (difference > 0) {
        return "TrendGoingUp";
    }
    if (difference < 0) {
        return "TrendGoingDown";
    }
    return "";
};

export const getScannedCheckpointsPercentage = (missedCheckpointsCount: number, scannedCheckpointsCount: number) => {
    const checkpointsCount = missedCheckpointsCount + scannedCheckpointsCount;
    const scannedCheckpointsPercentage = checkpointsCount ? (scannedCheckpointsCount / checkpointsCount) * 100 : 0;
    return scannedCheckpointsPercentage.toFixed(2) === "99.99" ? 99.9 : parseFloat(scannedCheckpointsPercentage.toFixed(1));
};
