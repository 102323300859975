import { endOfDay, startOfDay } from "../../helpers/date";
import { ToursRequestBody } from "../../store/insights/InsightsModel";

export const createDateTimeFilter = (requestParams: ToursRequestBody) => {
    let fromDate, toDate: undefined | Date;

    if ("fromDate" in requestParams) {
        fromDate = startOfDay(new Date(requestParams.fromDate));
        toDate = endOfDay(new Date(requestParams.toDate));
    } else {
        fromDate = new Date(requestParams.fromDateTime);
        toDate = new Date(requestParams.toDateTime);
    }

    return {
        siteTimestamp: {
            gte: fromDate.getTime(),
            lt: toDate.getTime(),
        },
    };
};
