import { Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useReportCategoryItems } from "../../../../../store/filter/FilterHooks";
import LocationsSelectors from "../../../../../store/locations/LocationsSelectors";
import { getDamagesCount } from "../shared/helpers";
import { useDamageReportsRedirection } from "../shared/hooks";
import { InsightTileHeaderStyled, SiteCardItemStyled, SiteCardWrapperStyled } from "../shared/styles";
import { getTopDamagesCategories } from "./SiteCard.helpers";
import { ButtonStyled, NoWrapButtonTextStyled } from "./SiteCard.styled";

type PropsType = {
    siteId: string;
    reports: { [k: string]: number };
};

export const SiteCard = ({ siteId, reports }: PropsType) => {
    const { t } = useTranslation();
    const siteObject = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));
    const { reportCategories } = useReportCategoryItems();
    const otherLabel = t("insights.damages.otherCategory");
    const reportsList = getTopDamagesCategories(reports, reportCategories, otherLabel);
    const { redirectToLocationDamageReports, redirectToCategory2Location } = useDamageReportsRedirection();
    const locationReportsCount = getDamagesCount(reports);
    const tooltipText = t("insights.damages.siteTooltip");

    return (
        <SiteCardWrapperStyled data-testid="damages-section-site-card-container">
            <InsightTileHeaderStyled tooltipText={tooltipText} id={siteId} iconVariant="Info" headerText={siteObject?.displayName}>
                <NoWrapButtonTextStyled
                    data-testid="damages-number-of-reports-on-site"
                    micro
                    uppercase
                    color="primary"
                    icon="ArrowForward"
                    direction="row-reverse"
                    iconSize="S"
                    onClick={() => {
                        redirectToLocationDamageReports(siteId);
                    }}
                >
                    {t("insights.damages.reports", { count: locationReportsCount })}
                </NoWrapButtonTextStyled>
            </InsightTileHeaderStyled>
            {reportsList.map(({ category, count, label }, index) => (
                <SiteCardItemStyled key={`${label}_${index}`}>
                    <Text data-testid="damages-category-name" small>
                        {label}
                    </Text>
                    <ButtonStyled
                        data-testid="damages-number-of-reports-with-category-lvl2"
                        color="primary"
                        icon="ArrowForward"
                        direction="row-reverse"
                        iconSize="S"
                        type="button"
                        onClick={() => {
                            redirectToCategory2Location(siteId, category);
                        }}
                    >
                        <Text bold small uppercase>
                            {count}
                        </Text>
                    </ButtonStyled>
                </SiteCardItemStyled>
            ))}
        </SiteCardWrapperStyled>
    );
};
