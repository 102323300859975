import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CarInpsectionIcon } from "../../../../../components/Reports/VehicleInspectionDetailsHeader/CarInspectionIcon";
import { getVehicleInspectionReportProperties } from "../../../../../helpers/reportTemplates/vehicleInspectionHelper";
import { DynamicReportPropertyTypeEnum, ReportsListItemType } from "../../../../../models/ReportModel";

type Props = {
    report: ReportsListItemType;
};

const WrappedText = styled(Text)`
    white-space: pre-wrap;
`;

export const ListItemVehicleLine = ({ report }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const dynamicReportProperties = report.properties.map((p) => ({ reportFieldName: p.key, value: p.value, type: DynamicReportPropertyTypeEnum.text }));

    const { licensePlate, vehicleModel, inspectionCount } = getVehicleInspectionReportProperties(dynamicReportProperties);

    return (
        <Stack data-testid="list-item-vehicle-line" flexWrap="wrap" direction={isMobile ? "column" : "row"} gap={isMobile ? "XXS" : "S"}>
            <Stack gap="XXS" flexWrap="wrap">
                <WrappedText bold micro>
                    {licensePlate}
                </WrappedText>
                <Stack gap="XXS">
                    <WrappedText bold micro color="neutral">
                        •
                    </WrappedText>
                    <WrappedText micro color="neutral">
                        {vehicleModel}
                    </WrappedText>
                </Stack>
            </Stack>
            <Stack gap="XXS">
                <CarInpsectionIcon width={16} height={16}></CarInpsectionIcon>
                <WrappedText micro color="neutral">
                    {inspectionCount}
                </WrappedText>
            </Stack>
        </Stack>
    );
};
