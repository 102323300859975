import { useTranslation } from "react-i18next";
import { TableCellProps } from "src/components/shared/Table/Table.types";
import { TourStatus } from "src/components/shared/TourStatus";
import { PatrolTourType } from "src/models/ReportModel";

type Props = TableCellProps<string[], PatrolTourType>;

export const ExceptionsCell = ({ cell }: Props) => {
    const { t } = useTranslation();
    return <TourStatus color="medium">{`${t("reportdetails.patrolTour.exceptions", { count: cell.length })}`}</TourStatus>;
};
