export enum SiteInspectionFields {
    observationsByTheInspector = "observationsByTheInspector",
    actionsTaken = "actionTakenByTheInspector",
    officersUniformsAndAppearance = "officer’sUniform&Appearance",
    regulatoryCompliant = "regulatoryCompliant",
    postOrderAcknowledgment = "officerHasReadAndAcknowledgedPostOrders",
    postOrdersLastRevisionDate = "lastRevisionDateOfPostOrders",
    inspectionDate = "inspectionTimeAndDate",
    officerSignature = "officer'sSignature",
    inspectorSignature = "inspector'sSignature",
}
