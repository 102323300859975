import { Background, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

const shakingAnimation = css`
    @keyframes jiggle1 {
        0% {
            transform: rotate(-0.25deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(0.5deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes jiggle2 {
        0% {
            transform: rotate(0.25deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-0.5deg);
            animation-timing-function: ease-out;
        }
    }

    &:nth-child(2n) {
        animation: jiggle1 250ms ease infinite alternate;
        transform-origin: 50% 3%;
    }

    &:nth-child(2n-1) {
        transform-origin: 40% 50%;
        animation: jiggle2 300ms ease infinite alternate;
    }
`;

export const WrapperStyled = styled(Stack).attrs({
    direction: "column",
})<{ editMode: boolean }>`
    background: ${Background.content};
    position: relative;
    border-radius: ${Spacing.XXS}px;
    ${({ editMode }) => editMode && shakingAnimation};
`;

export const ToolBarStyled = styled(Stack).attrs({
    mh: "S",
    mt: "S",
    justifyContent: "flex-end",
})`
    z-index: 2;
`;

export const ContentStyled = styled.div`
    margin-top: -${Spacing.XS}px;
`;

export const OverlayStyled = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
    background-color: ${Background.disabled};
    opacity: 0.6;
`;
