import { formatDateTime, formatDateTimeWithTimezone } from "src/helpers/date";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { PatrolTourType } from "src/models/ReportModel";
import { CheckpointData } from "src/pages/Reports/ReportsList/ReportDetails/Components/CheckpointSortComponent/CheckpointData";
import { PatrolSummary } from "src/pages/Reports/ReportsList/ReportDetails/Components/PatrolSummaryComponent/PatrolSummary";
import { TourExceptionsOverview } from "src/pages/Reports/ReportsList/ReportDetails/Components/TourExceptionOverviewComponent/TourExceptionsOverview";

import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { DateRangeSummaryField } from "../SharedComponents/DateRangeSummaryField";
import { PatrolDetailsHeader } from "../SharedComponents/ReportDetailsHeader/PatrolDetailsHeader";
import { TextAreaSection } from "../SharedComponents/TextArea";
import { conditionalRender } from "./DynamicTemplateUtils";
import { FeatureFlag } from "../SharedComponents/FeatureFlag";
import { featureFlags } from "../../../data/featureFlags";

export const getPatrolTourReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<PatrolTourType>): DynamicComponentConfigType[] => [
    {
        component: PatrolDetailsHeader,
        props: {
            report: report,
            siteObject: siteObject,
            isShared: !!sharedReportId,
        },
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.patrolTour.checkpointCompletion",
        },
        children: [
            {
                component: PatrolSummary,
                props: {
                    report: report,
                },
            },
        ],
    },
    conditionalRender(!!report.incompleteReason, () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.tourException.comments",
            fullyTranslated: true,
        },
        children: [
            {
                component: TextAreaSection,
                props: {
                    content: report.incompleteReason,
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    conditionalRender(report.exceptionReportIds.length > 0, () => ({
        component: TourExceptionsOverview,
        props: {
            report: report,
            siteObject: siteObject,
            sharedReportId: sharedReportId,
        },
    })),
    {
        component: CheckpointData,
        props: {
            report: report,
        },
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.patrolTour.summary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: FeatureFlag,
                props: {
                    featureName: featureFlags.sitesTimezones,
                },
                children: [
                    {
                        component: DateRangeSummaryField,
                        props: {
                            from: formatDateTimeWithTimezone(report.startDateTime, report.siteIanaTimezone),
                            to: formatDateTimeWithTimezone(report.endDateTime, report.siteIanaTimezone),
                            fieldName: "reportdetails.patrolTour.patrolDateTime",
                            mobileDirection: "column",
                        },
                    },
                ],
            },
            {
                component: FeatureFlag,
                props: {
                    featureName: featureFlags.sitesTimezones,
                    renderWhenOff: true,
                },
                children: [
                    {
                        component: DateRangeSummaryField,
                        props: {
                            from: formatDateTime(report.startDateTime),
                            to: formatDateTime(report.endDateTime),
                            fieldName: "reportdetails.patrolTour.patrolDateTime",
                            mobileDirection: "column",
                        },
                    },
                ],
            },
        ],
    },
];
