import { Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const RightSideStackStyled = styled(Stack).attrs<React.ComponentProps<typeof Stack>>({
    flexWrap: "wrap",
    justifyContent: "flex-end",
    flex: 1,
})`
    min-width: fit-content;
`;

export const TrendsTileHeaderStyled = styled(Stack).attrs<React.ComponentProps<typeof Stack>>({
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
})`
    column-gap: ${Spacing.M}px;
`;

export const EmptyStateContainerStyled = styled(Stack)`
    background-color: ${Palette.Navy800};
    border-radius: 4px;
`;
