import { i18next, i18NextOptions } from "./i18next";

const getCulturesTimestampsFromCache = (): Record<string, number> => {
    const { localizationStorage, cachedLocalizationPrefix } = i18NextOptions;
    const cultureTimestampsInStore: Record<string, number> = {};
    for (let i = 0; i < localizationStorage.length; i++) {
        const fullCultureKey = localizationStorage.key(i);
        if (!fullCultureKey.startsWith(cachedLocalizationPrefix)) {
            continue; //the storage contains other data too, we care only about the locales that are saved with the prefix
        }
        const cachedCulture = localizationStorage.getItem(fullCultureKey);
        const cachedTranslation = JSON.parse(cachedCulture);
        // the name is constructed as `${prefix}culture-[namespace = '']`
        const cultureName = fullCultureKey.substring(cachedLocalizationPrefix.length, fullCultureKey.length - 1);
        if (cachedTranslation[i18NextOptions.timestampInCacheProp]) {
            //the timestamps in the storage are saved in miliseconds, convert them to seconds
            cultureTimestampsInStore[cultureName] = cachedTranslation[i18NextOptions.timestampInCacheProp] / 1000;
        }
    }
    return cultureTimestampsInStore;
};

const calculateCulturesToReload = (cachedCulturesWithTimestamp, culturesWithTimestamp): string[] => {
    return Object.keys(cachedCulturesWithTimestamp).reduce((acc, curr) => {
        if (cachedCulturesWithTimestamp[curr] < culturesWithTimestamp[curr]) {
            acc.push(curr);
        }
        return acc;
    }, []);
};

const getCulturesTimestampsFromApi = async (): Promise<Record<string, number>> => {
    try {
        const culturesResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/localization/cultures/${i18NextOptions.appNameForLocalization}`);
        const culturesWithTimestamp = (await culturesResponse.json()) as Record<string, number>;
        return culturesWithTimestamp;
    } catch (ex) {
        console.warn("Error while getting cultures from the api");
    }
};

const handleCultures = async (): Promise<void> => {
    const culturesFromApi = await getCulturesTimestampsFromApi();
    if (!culturesFromApi) {
        //couldn't fetch the cultures info
        console.warn("Couldn't fetch the cultures info");
        return;
    }
    const culturesInCache = getCulturesTimestampsFromCache();
    const expiredCultures = calculateCulturesToReload(culturesInCache, culturesFromApi);

    expiredCultures.forEach((cultureKey) => {
        //remove from local storage
        i18NextOptions.localizationStorage.removeItem(i18NextOptions.cachedLocalizationPrefix + cultureKey + "-");
    });

    if (expiredCultures.length) {
        //evoke refreshing of the resources
        await i18next.reloadResources(expiredCultures);
    }
    if (expiredCultures.includes(i18next.language)) {
        //fake change language to evoke refresh of labels
        i18next.changeLanguage(i18next.language);
    }
};

/* Triggers re-fetching of cached localization resources if the backend localization service
 contains newer versions. The versions are first checked with getCultures api call
*/
const syncI18nCultures = async () => {
    try {
        await handleCultures();
    } catch (ex) {
        console.error("Error while processing locales");
    }
};

export { syncI18nCultures };
