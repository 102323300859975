import JWT from "jwt-decode";

import { IExpirationTokenDecoded, ITokenDecoded } from "../models/TokenModel";

const tokenExpiresInSeconds = (tokenDecoded: IExpirationTokenDecoded) => {
    return tokenDecoded.exp - Math.floor(Date.now() / 1000);
};

export const hasTokenExpired = (token: string) => {
    const tokenDecoded = JWT<ITokenDecoded>(token);
    return tokenExpiresInSeconds(tokenDecoded) < 0;
};

export const hasTokenHalftimePassed = (decodedToken: IExpirationTokenDecoded) => {
    const threshold = (decodedToken.exp - decodedToken.iat) / 2; // refresh threshold is half the time of token lifespan
    const expiresInSeconds = tokenExpiresInSeconds(decodedToken);
    const tokenNeedsRefresh = expiresInSeconds < threshold;
    return tokenNeedsRefresh;
};
