import { Separator } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PatrolTourType } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { HeaderStackStyled } from "./Common.styled";
import { LocationHeaderLine } from "./LocationHeaderLine";
import { PatrolDetailsHeaderLine1 } from "./PatrolDetailsHeaderLine1";
import { PatrolHeadline } from "./PatrolHeadline";

type Props = {
    report: PatrolTourType;
    siteObject: ISiteObject;
    isShared: boolean;
};

export const PatrolDetailsHeader = ({ report, siteObject, isShared }: Props) => {
    const { t } = useTranslation();

    const headerText = useMemo(() => {
        if (report.exceptionReportIds.length) {
            return `${report.tourName} • ${t("reportdetails.patrolTour.exceptions", { count: report.exceptionReportIds.length })}`;
        }
        return report.tourName;
    }, [report, t]);

    return (
        <HeaderStackStyled>
            <PatrolHeadline header={headerText} subheader={t("reportdetails.header.patrolTour")} />
            <Separator mb="XS" />
            <PatrolDetailsHeaderLine1 patrol={report} siteObject={siteObject} isShared={isShared} />
            <Separator mv="XS" />
            <LocationHeaderLine siteObject={siteObject} />
            <Separator mt="XS" />
        </HeaderStackStyled>
    );
};
