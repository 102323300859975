import createReports from "../creators/createReports";
import createTours from "../creators/createTours";
import config from "../mock.config";
import { SiteObjects } from "../types";

const WHITELIST = [
    "chrome-extension://",
    "launchdarkly.com",
    "events.launchdarkly.com",
    /(?:[a-zA-Z0-9/]*\/api\/v(1|2)\/authorization)+/,
    /(?:[a-zA-Z0-9/]*\/api\/v1\/global\/tracking)\/(?:[a-zA-Z0-9/]*)+/,
    /(?:[a-zA-Z0-9/]*\/api\/v1\/global\/insights\/reports)/,
    /(?:[a-zA-Z0-9/]*\/api\/v1\/global\/reportCategories)/,
];

export const enableMock = async (siteObjects: SiteObjects) => {
    const { worker } = await import("../browser");

    createMockData(siteObjects);

    return await worker.start({
        onUnhandledRequest(request, print) {
            // don't throw warning for whitelisted urls
            if (WHITELIST.some((url) => request.url.match(url))) {
                return;
            }

            print.warning();
        },
    });
};

const createMockData = (siteObjects: SiteObjects) => {
    createReports(config.reports, siteObjects, config.howManyMonths);
    createTours(config.patrols, siteObjects, config.howManyMonths);
};
