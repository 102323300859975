import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { Box, Breakpoints, Stack, Tab, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { featureFlags } from "../../../data/featureFlags";
import { TABLE_TOTAL_KEY } from "../../../store/insights/InsightsModel";
import { DeviationKeys } from "../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import * as PatrolDeviationsSelectors from "../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { PatrolDeviationItem } from "../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";
import { useTours } from "../../../store/insights/Tours/ToursHooks";
import * as ToursSelectors from "../../../store/insights/Tours/ToursSelectors";
import { RequestStatus } from "../../../store/RequestStatus";
import { InsightsWrapper, PageWrapper } from "../Common.styled";
import { TrendingEvents } from "../Events/TrendingEvents";
import { PatrolDeviations } from "../PatrolTasks/PatrolDeviations/PatrolDeviations";
import { getScannedCheckpointsPercentage } from "../PatrolTasks/PatrolTasks.helpers";
import { TotalPerformanceSection } from "../PatrolTasks/TotalPerformance/TotalPerformanceSection";
import { Tours } from "../PatrolTasks/Tours/Tours";
import { TrendIndicatorBar } from "../shared/components/TrendIndicator";
import useSelectDateRangeFromLocation from "../shared/hooks/useSelectDateRangeFromLocation";
import { Barchart } from "./Barchart/Barchart";
import { BarchartBreadcrumbs } from "./Barchart/BarchartBreadcrumbs";
import { useBarchartData } from "./Barchart/hooks/useBarchartData";
import { StackStyled, TabsStyled } from "./InsightsSitePage.styled";
import { SelectedCategories } from "./InsightsSitePage.types";
import { InsightsSitePageHeader } from "./InsightsSitePageHeader";
import { InsightsSitePageViewSchedule } from "./InsightsSitePageViewSchedule";

const EXCLUDED_KEYS = [DeviationKeys.category, DeviationKeys.chartData, DeviationKeys.categoryLabel, DeviationKeys.children];
const EVENTS_TAB_INDEX = 0;

export const InsightsSitePageV2 = () => {
    const { t } = useTranslation();
    const { siteId } = useParams<{ siteId?: string }>();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const navigate = useNavigate();

    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const toursQueryStatus = useSelector(ToursSelectors.getLocationToursQueryStatus);
    const tours = useSelector(ToursSelectors.getLocationTours);
    const deviationsQueryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    useTours();
    useSelectDateRangeFromLocation();

    const [selectedCategories, setSelectedCategories] = useState<SelectedCategories>({ level1: null, level2: null });
    const [activeTab, setActiveTab] = useState(0);
    const { data: incidents, isLoading: isBarchartDataLoading } = useBarchartData(selectedCategories.level2 ?? selectedCategories.level1, siteId);

    const totalPerformanceData = useMemo(() => {
        if (!tours.length && !patrolDeviations.length) {
            return {
                exceptionsCount: 0,
                toursCount: 0,
                scannedCheckpointsPercent: 0,
                historicalExceptionsCount: 0,
                historicalToursCount: 0,
                historicalScannedCheckpointsPercent: 0,
            };
        }
        const totalTours = tours.find((x) => x.mysTourId === TABLE_TOTAL_KEY) || {
            count: 0,
            histCount: 0,
            checkpointsMissed: 0,
            checkpointsScanned: 0,
            histCheckpointsMissed: 0,
            histCheckpointsScanned: 0,
        };
        const totalDeviations = patrolDeviations.find((x) => x.category === TABLE_TOTAL_KEY) || {};
        const totalDeviationsValues = Object.keys(totalDeviations).reduce(
            (acc, key) => {
                if (EXCLUDED_KEYS.includes(DeviationKeys[key])) {
                    return acc;
                }
                return {
                    count: acc.count + (totalDeviations[key] as PatrolDeviationItem).count,
                    histCount: acc.histCount + (totalDeviations[key] as PatrolDeviationItem).histCount,
                };
            },
            { count: 0, histCount: 0 },
        );

        return {
            exceptionsCount: totalDeviationsValues.count,
            toursCount: totalTours.count,
            scannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.checkpointsMissed, totalTours.checkpointsScanned),
            historicalExceptionsCount: totalDeviationsValues.histCount,
            historicalToursCount: totalTours.histCount,
            historicalScannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.histCheckpointsMissed, totalTours.histCheckpointsScanned),
        };
    }, [tours, patrolDeviations]);

    const isTasksTabLoading = toursQueryStatus === RequestStatus.loading;

    const handleScheduleButtonClick = useCallback(() => {
        navigate(`/schedule/${siteId}`);
    }, [navigate, siteId]);
    const isViewScheduleVisible = useBooleanFlagValue(featureFlags.viewSchedule, false);

    const levelChangeHandler = (selectedCategories: SelectedCategories) => {
        setSelectedCategories(selectedCategories);
    };

    const categoryKey = selectedCategories.level2 ?? selectedCategories.level1;
    const isSingleCategory = incidents.length === 1;
    const trendIndicatorTitle = isSingleCategory ? t(`incident.category.${incidents[0].categoryKey}`) : "";
    const trendIndicatorTargetCategory = isSingleCategory ? incidents[0].categoryKey : "";
    const isLevel1OnMobile = selectedCategories.level1 && isMobile;

    const levelResetHandler = (index: number) => {
        if (index === EVENTS_TAB_INDEX && activeTab === EVENTS_TAB_INDEX && selectedCategories.level1) {
            setSelectedCategories({ level1: null, level2: null });
        }
    };

    useEffect(() => {
        if (!isBarchartDataLoading && incidents.length === 0) {
            setSelectedCategories({ level1: null, level2: null });
        }
    }, [incidents.length, isBarchartDataLoading]);

    return (
        <PageWrapper direction="column" pt={isMobile ? "XXS" : "0"}>
            <InsightsWrapper pb="S" direction="column">
                <Box ph={isMobile ? "S" : "L"}>
                    <InsightsSitePageHeader
                        selectedCategories={selectedCategories}
                        activeTab={activeTab}
                        levelChangeHandler={levelChangeHandler}
                        siteId={siteId}
                    />
                    {isViewScheduleVisible && <InsightsSitePageViewSchedule handleScheduleButtonClick={handleScheduleButtonClick} />}
                </Box>
                <Stack gap="S" direction="column">
                    <TabsStyled
                        onChange={setActiveTab}
                        isNavigationVisible={!isLevel1OnMobile}
                        hasBottomSeparator={!isLevel1OnMobile}
                        onClick={levelResetHandler}
                    >
                        <Tab label={t("insights.tabs.incidents")}>
                            <StackStyled ph={isMobile ? "S" : "L"} mt={isMobile ? "S" : "L"} direction="column" flex={1} gap="S">
                                {!isMobile && selectedCategories.level1 && (
                                    <BarchartBreadcrumbs
                                        categoryLevel1={selectedCategories.level1}
                                        categoryLevel2={selectedCategories.level2}
                                        setBarchart={levelChangeHandler}
                                    />
                                )}
                                <TrendIndicatorBar
                                    categoryKey={categoryKey}
                                    siteId={siteId}
                                    title={trendIndicatorTitle}
                                    selectedCategories={selectedCategories}
                                    targetCategory={trendIndicatorTargetCategory}
                                    levelChangeHandler={levelChangeHandler}
                                />
                                {incidents.length !== 1 && (
                                    <Barchart
                                        categoryLevel1={selectedCategories.level1}
                                        categoryLevel2={selectedCategories.level2}
                                        data={incidents}
                                        levelChangeHandler={levelChangeHandler}
                                        isLoading={isBarchartDataLoading}
                                    />
                                )}
                                <TrendingEvents />
                            </StackStyled>
                        </Tab>
                        <Tab label={t("insights.tabs.tasks")}>
                            <StackStyled ph={isMobile ? "S" : "L"} mt="L" direction="column" gap="S" flex={1}>
                                <TotalPerformanceSection hasSingleSite isLoading={isTasksTabLoading} {...totalPerformanceData} />
                                <PatrolDeviations
                                    patrolDeviations={patrolDeviations}
                                    isLoading={deviationsQueryStatus === RequestStatus.loading}
                                    siteIds={[siteId]}
                                    hasSingleSite
                                />
                                <Tours />
                            </StackStyled>
                        </Tab>
                    </TabsStyled>
                </Stack>
            </InsightsWrapper>
        </PageWrapper>
    );
};
