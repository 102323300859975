import { HttpResponse } from "msw";

import { db } from "../../db";
import { ReportProperty } from "../../types";

export const getReportResolver = (reportId: string, locationId: string) => {
    const result = db.reports.findFirst({
        where: {
            id: {
                equals: reportId,
            },
            locationId: {
                equals: locationId,
            },
        },
    });

    result.properties = result.properties.filter((property: ReportProperty) => !("key" in property));

    return HttpResponse.json(result);
};

export default getReportResolver;
