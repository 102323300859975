import { Color, Icon, SignalColor, Stack, Text } from "@secuis/ccp-react-components";
import { ChildrenProps } from "src/models/ChildrenPropModel";

type Props = ChildrenProps & {
    color: Color | SignalColor;
};

export const TourStatus = ({ children, color }: Props) => (
    <Stack alignItems="center">
        <Icon color={color} variant="StatusNew" />
        <Text micro bold>
            {children}
        </Text>
    </Stack>
);
