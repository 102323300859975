import { Outlet } from "react-router-dom";

import { AuthAppContainer, UnauthenticatedMain } from "./Authentication.styles";

export const UnauthenticatedApp: React.FC = () => {
    return (
        <AuthAppContainer>
            <UnauthenticatedMain>
                <Outlet />
            </UnauthenticatedMain>
        </AuthAppContainer>
    );
};
