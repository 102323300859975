import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

export const TooltipIconsList = ({ items, title }: { title?: string; items: { icon: JSX.Element; termKey: string }[] }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" gap="XS">
            {title && <Text small>{title}</Text>}

            {items.map(({ icon, termKey }) => (
                <Stack alignItems="center" key={termKey} gap="S">
                    {icon}
                    <Text micro>{t(termKey)}</Text>
                </Stack>
            ))}
        </Stack>
    );
};
