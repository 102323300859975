import { Icon } from "@secuis/ccp-react-components";

import { ButtonStyled } from "./Pagination.styles";

type PrevButtonProps = {
    currentPage: number;
    previousPage: () => void;
};

export const PrevButton = ({ currentPage, previousPage }: PrevButtonProps) => {
    return currentPage > 1 ? (
        <ButtonStyled onClick={previousPage}>
            <Icon variant="ArrowBack" size="S" data-testid="matrix-pagination-prev" />
        </ButtonStyled>
    ) : (
        <ButtonStyled disabled>
            <Icon variant="ArrowBack" size="S" color="disabled" data-testid="matrix-pagination-prev" />
        </ButtonStyled>
    );
};
