import { ValueTile, ValueTileProps } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import InsightsSelectors from "../../../../../store/insights/InsightsSelectors";

export const SummaryValueTile = (props: ValueTileProps) => {
    const { t } = useTranslation();
    const hasData = useSelector(InsightsSelectors.getClientReportsStatus);

    return <ValueTile description={t("insights.summary.defaultComparison")} hideTrend={!hasData} {...props} />;
};
