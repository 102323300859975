import { Stack } from "@secuis/ccp-react-components";

import { NextButton } from "./NextButton";
import { PrevButton } from "./PrevButton";

type PaginationProps = {
    currentPage: number;
    totalPages: number;
    showPreviousPage: () => void;
    showNextPage: () => void;
};

export const Pagination = ({ currentPage, totalPages, showPreviousPage, showNextPage }: PaginationProps) => {
    return (
        <Stack>
            <PrevButton currentPage={currentPage} previousPage={showPreviousPage} />
            <NextButton currentPage={currentPage} totalPages={totalPages} nextPage={showNextPage} />
        </Stack>
    );
};
