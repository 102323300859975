import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getArrayFromString } from "../../helpers/ArrayHelper";
import { useQueryString } from "../../hooks/CommonHooks";
import { useAuthorizedLocations } from "../locations/LocationsHooks";
import insightsActions from "./InsightsActions";

export const useSetInsightFiltersByParams = () => {
    const dispatch = useDispatch();
    const [wasLoaded, setWasLoaded] = useState<boolean>(false);
    const { siteObjects } = useAuthorizedLocations();
    const { selectedRegions, selectedStartDate, selectedEndDate } = useQueryString();

    useEffect(() => {
        if (!wasLoaded && (selectedRegions || selectedStartDate || selectedEndDate)) {
            dispatch(
                insightsActions.applyPersistedFilters(
                    getArrayFromString(selectedRegions),
                    selectedStartDate ? new Date(selectedStartDate) : null,
                    selectedEndDate ? new Date(selectedEndDate) : null,
                ),
            );

            setWasLoaded(true);
        }
    }, [dispatch, wasLoaded, selectedStartDate, selectedEndDate, selectedRegions, siteObjects]);
};
