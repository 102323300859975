import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../styles/templates/Flex";

export const Tile = styled.div`
    ${flexColumn}
    border-radius: ${Spacing.XXS}px;
    background-color: ${Palette.Navy750};
    padding: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.M}) {
        padding: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;

export const TileContent = styled.div<{ isFilterActive?: boolean }>`
    background-color: ${Palette.Navy750};
    border-radius: ${Spacing.XXS}px;
    padding: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.S}) {
        padding: ${(props) => (props.isFilterActive ? `${Spacing.S}px` : `${Spacing.L}px`)};
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;

export const TileHeaderLinkWrapper = styled(Stack)`
    text-transform: uppercase;

    @media (max-width: ${Breakpoints.XS}) {
        align-self: end;
    }
`;
