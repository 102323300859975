import { Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChipFilter } from "src/components/shared/ChipFilter/ChipFilter";

import { getSeverityItems, getSiteLocations } from "../../../../components/Reports/ReportsFilter";
import { getLeafs } from "../../../../helpers/MultiDropdownHelper";
import FilterActions, { clearCategories, clearRegions, selectReportTypes, selectSeverities, selectSitesObjects } from "../../../../store/filter/FilterActions";
import { useFiltersSelection, useReportCategoryItems, useReportTypeItems } from "../../../../store/filter/FilterHooks";
import FilterSelectors from "../../../../store/filter/FilterSelectors";
import { useSiteObjectItemsByRegions } from "../../../../store/siteObjects/SiteObjectsHooks";

export const FilterSummaryChips = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const severityFilters = useSelector(FilterSelectors.getSelectedSeverityLevelIds);
    const reportTypes = useSelector(FilterSelectors.getSelectedReportTypes);
    const siteLocations = useSelector(FilterSelectors.getSelectedSiteLocations);
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const showOnlyUnread = useSelector(FilterSelectors.getShowOnlyUnreadSelector);
    const { reportCategories, selectedCategories } = useReportCategoryItems();
    const { reportTypeItems } = useReportTypeItems();

    const { sitesByRegions } = useSiteObjectItemsByRegions();

    const { regionsLength, categoriesLength } = useFiltersSelection(
        severityFilters,
        reportTypes,
        selectedCategories,
        siteLocations,
        selectedRegions,
        showOnlyUnread,
    );

    const reportTypesLength = reportTypes.length;
    const siteLocationsLength = siteLocations.length;
    const severityFiltersLength = severityFilters.length;

    const allSeveritiesSelected: boolean = useMemo(
        () => severityFiltersLength === 0 || getLeafs(getSeverityItems()).length === severityFiltersLength,
        [severityFiltersLength],
    );

    const allReportTypesSelected: boolean = useMemo(
        () => reportTypesLength === 0 || getLeafs(reportTypeItems).length === reportTypesLength,
        [reportTypesLength, reportTypeItems],
    );

    const allSiteLocationSelected: boolean = useMemo(
        () => siteLocationsLength === 0 || getLeafs(getSiteLocations()).length === siteLocationsLength,
        [siteLocationsLength],
    );

    const allRegionsSelected: boolean = useMemo(
        () => regionsLength === 0 || getLeafs(sitesByRegions.items).length === regionsLength,
        [regionsLength, sitesByRegions.items],
    );

    const allCategoriesSelected: boolean = useMemo(
        () => categoriesLength === 0 || getLeafs(reportCategories).length === categoriesLength,
        [categoriesLength, reportCategories],
    );

    return (
        <Stack gap="XS" flexWrap="wrap" mt={["XS", "XS", "S"]}>
            <ChipFilter
                data-test-id="filter-chip-locations"
                label={allRegionsSelected ? t("filters.allSites") : `${t("filters.locations.labelPlural")} (${regionsLength})`}
                onRemove={allRegionsSelected ? null : () => dispatch(clearRegions())}
            />
            <ChipFilter
                data-test-id="filter-chip-categories"
                label={allCategoriesSelected ? t("filters.allCategories") : `${t("filters.title.reportcategory")} (${categoriesLength})`}
                onRemove={allCategoriesSelected ? null : () => dispatch(clearCategories())}
            />
            <ChipFilter
                data-test-id="filter-chip-types"
                label={allReportTypesSelected ? t("filters.allReportTypes") : `${t("filters.title.reportType")} (${reportTypesLength})`}
                onRemove={allReportTypesSelected ? null : () => dispatch(selectReportTypes([]))}
            />
            <ChipFilter
                data-test-id="filter-chip-zones"
                label={allSiteLocationSelected ? t("filters.allSiteLocations") : `${t("filters.title.siteLocation")} (${siteLocationsLength})`}
                onRemove={allSiteLocationSelected ? null : () => dispatch(selectSitesObjects([]))}
            />
            <ChipFilter
                data-test-id="filter-chip-priorities"
                label={allSeveritiesSelected ? t("filters.allSeverities") : `${t("filters.title.severitylevel")} (${severityFiltersLength})`}
                onRemove={allSeveritiesSelected ? null : () => dispatch(selectSeverities([]))}
            />
            {showOnlyUnread && (
                <ChipFilter
                    data-test-id="filter-chip-unread"
                    label={t("filters.showOnlyUnread.chipLabel")}
                    onRemove={() => dispatch(FilterActions.showOnlyUnread(false))}
                />
            )}
        </Stack>
    );
};
