import { Breakpoints } from "@secuis/ccp-react-components";
import { TooltipRich } from "src/components/shared/TooltipRich";
import styled from "styled-components";

export const TooltipStyled = styled(TooltipRich)<{ width?: number }>`
    &.custom-tooltip {
        width: ${({ width }) => (width ? `${width}px` : "auto")};

        /*
        /  NOTE: Required as it does not disappear automatically
        /  and may overlap the SCL's Overlay like from the modal
        /  but we need to keep it above elements like map and other content
        /  due to different app layout and components positioning for mobile devices
        */
        z-index: 1;

        @media (max-width: ${Breakpoints.XS}) {
            z-index: 3;
        }
    }
`;
