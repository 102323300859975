import { ButtonText, Stack } from "@secuis/ccp-react-components";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { checkIfUnknown } from "../../../helpers/ReportHelper";
import { DynamicReportType, PatrolTourType } from "../../../models/ReportModel";
import { ISiteObject } from "../../../models/SiteObjectModel";
import ShareReportModal from "../Share/ShareReportModal";
import { DownloadPdfButtonWrapper, NavbarContainer, NavbarStickySpacing, ShareIcon } from "./DetailsNavbar.styles";
import { DownloadPdfButton } from "./DownloadPdfButton";

type Props = {
    report?: DynamicReportType | PatrolTourType;
    onClose?: () => void;
    sharedReportId?: string;
    showCloseButton?: boolean;
    showBackButton?: boolean;
    isSharedReportDesktop?: boolean;
    isStickyHeader: boolean;
    siteObject: ISiteObject;
};

export const DetailsNavbar = ({
    report,
    onClose,
    sharedReportId,
    showCloseButton,
    showBackButton,
    isSharedReportDesktop,
    isStickyHeader,
    siteObject,
}: Props) => {
    const { t } = useTranslation();
    const [showShareReportModal, setShowShareReportModal] = useState(false);

    const showModal = useCallback(() => {
        setShowShareReportModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setShowShareReportModal(false);
    }, []);

    const isUnknown = useMemo(() => ((report as DynamicReportType).template ? checkIfUnknown((report as DynamicReportType).template) : false), [report]);

    return (
        <>
            <NavbarStickySpacing />
            <NavbarContainer isSticky={isStickyHeader}>
                {!showBackButton && showCloseButton && (
                    <ButtonText data-testid="close-report-detail-view" color="primary" icon="Clear" direction="row" onClick={onClose} autoFocus>
                        {t("common.close")}
                    </ButtonText>
                )}
                {showBackButton && (
                    <ButtonText data-testid="back-report-detail-view" color="primary" icon="BackNavigation" direction="row" onClick={onClose} autoFocus>
                        {t("common.back")}
                    </ButtonText>
                )}
                <Stack flex={1} justifyContent="flex-end" pr={isSharedReportDesktop ? "M" : "0"}>
                    <DownloadPdfButtonWrapper>
                        {!isUnknown && <DownloadPdfButton report={report} siteObject={siteObject} sharedReportId={sharedReportId} />}
                    </DownloadPdfButtonWrapper>
                    {!sharedReportId && (
                        <ShareIcon data-testid="share-icon" color="primary" icon="Share" direction="row" onClick={showModal}>
                            {t("common.share")}
                        </ShareIcon>
                    )}
                </Stack>
                {showShareReportModal && <ShareReportModal isOpen={showShareReportModal} report={report} onClose={closeModal} />}
            </NavbarContainer>
            <NavbarStickySpacing />
        </>
    );
};
