import { Palette } from "@secuis/ccp-react-components";

export const CustomizedLabel = ({ x, y, width: barWidth, value }): JSX.Element => {
    const leftMargin = 10;
    const topMargin = 25;

    return (
        <text x={x + leftMargin + barWidth} y={y + topMargin} fill={Palette.Navy100}>
            {value}
        </text>
    );
};
