import { Badge, Breakpoints, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const UnreadDot = styled.div`
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background-color: ${Palette.Purple400};

    @media (max-width: ${Breakpoints.XS}) {
        top: 18.25px;
    }
`;

export const RightAlignText = styled(Text)`
    text-align: right;
`;

export const PercentageBadge = styled(Badge)`
    flex-shrink: 0;
    align-self: flex-start;
    margin-right: ${Spacing.XS}px;
`;
