import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { TextAreaSummaryField, TextAreaSummaryFieldProps } from "./TextArea";
import { TextSummaryField, TextSummaryFieldProps } from "./TextSummaryField";

type Props = {
    textSummaryFieldProps: TextSummaryFieldProps;
    textAreaSummaryFieldProps: TextAreaSummaryFieldProps;
};

const Wrapper = styled(Stack)`
    flex-direction: column;
    > div:first-child:not(:only-child) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    > div:nth-child(2) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
`;

export const TextTextAreaSummaryField = ({ textSummaryFieldProps, textAreaSummaryFieldProps }: Props): JSX.Element => {
    const shouldSkipRender =
        !textSummaryFieldProps.isRequired && !textSummaryFieldProps.fieldValue && !textAreaSummaryFieldProps.content && !textAreaSummaryFieldProps.isRequired;
    if (shouldSkipRender) {
        return <></>;
    }

    return (
        <Wrapper>
            <TextSummaryField {...textSummaryFieldProps} />
            <TextAreaSummaryField {...textAreaSummaryFieldProps} />
        </Wrapper>
    );
};
