export enum AmazonZone6Fields {
    title = "title",
    description = "descriptionOfIncident(Time-Description)",
    reasonOfIncident = "cause/ReasonOfTheIncident:",
    dateTimeStarted = "date&TimeEventSTARTED",
    dateTimeEnded = "date&TimeEventENDED",
    timeOnSite = "timeOnSite:",
    timeOffSite = "timeOffSite:",
    reference = "reference",
    policeInvolved = "policeInvolved",
    medicalServicesInvolved = "medicalServicesInvolved",
    fireDepartmentInvolved = "fireDepartmentInvolved",
    siteSupervisorNotified = "siteSupervisorNotified",
    socActionRequired = "sOCActionRequired",
    facilityManagementActionRequired = "facilityManagementActionRequired",
    whatAlarmsWentOff = "whatAlarmsWentOff?",
    whatCausedTheAlarmsToActivate = "whatCausedTheAlarmsToActivate?",
    additionalInformationOnWhatActivatedTheAlarm = "additionalInformationOnWhatActivatedTheAlarm",
    whereThereAnyDeviationsFromTheProcessDescribedAbove = "whereThereAnyDeviationsFromTheProcessDescribedAbove?",
    ifYesExplainDeviation = "ifYes,ExplainDeviation:",
    ifADeviationOccurredFromTheExpectedProcess = "ifADeviationOccurredFromTheExpectedProcess,WasItLikelyToHaveContributedToTheActivationOfTheAlarm?",
    ifYesExplainContribution = "ifYes,ExplainContribution:",
    wasTheExpectedProcessDescribedInPolicy = "wasTheExpectedProcessDescribedInPolicy,Procedure,WrittenGuidelines,OrIncludedInEmployeeTraining?",
    ifYesExplainTheSource = "ifYes,ExplainTheSource:",
    otherCommentsOrRelevantInformation = "otherCommentsOrRelevantInformation:",
    actionsToBeTakenRecommendation = "actionsToBeTaken/Recommendation",
}
