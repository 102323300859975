import { useMemo } from "react";
import { formatToRawDate, startOfMonth, subMonths } from "src/helpers/date";
import { useApiRequest } from "src/hooks/accessApi";
import { DeviationsAPIData } from "src/pages/Insights/Insights.types";

import { parseDeviationsResponse } from "../helpers";
import { ReportCategoryWithLevel } from "../TrendingEvents.types";

export const useFetchDeviations = (siteIds: string[], categories: ReportCategoryWithLevel[], monthsBack: number) => {
    const { data, isLoading } = useApiRequest<DeviationsAPIData>("/v2/global/insights/tour-exc/monthly-location-category-summary", "POST", {
        locationIds: siteIds,
        categories,
        fromDate: formatToRawDate(startOfMonth(subMonths(new Date(), monthsBack))),
        toDate: formatToRawDate(new Date()),
    });

    const deviations = useMemo(() => {
        if (isLoading || !data) return [];

        return parseDeviationsResponse(
            data,
            categories.map((cat) => cat.category.toString()),
            monthsBack,
        );
    }, [data, isLoading, categories, monthsBack]);

    return { deviations, isLoading };
};
