import { createExplicitDateFromReference, getStartDateByEndTimeAndReferenceDate } from "../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { AmazonGeneralFields } from "../../../models/reportTemplates/AmazonGeneral";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { DateRangeSummaryField } from "../SharedComponents/DateRangeSummaryField";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getAmazonGeneralReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.amazon.description",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(AmazonGeneralFields.description, report),
                    sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    conditionalRender(getFieldValueByFieldName(AmazonGeneralFields.reasonOfIncident, report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.amazon.reasonOfIncident",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(AmazonGeneralFields.reasonOfIncident, report),
                        sharedReportId,
                        imagesData: [],
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    conditionalRender(getFieldValueByFieldName(AmazonGeneralFields.actionsToBeTaken, report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.amazon.actionsToBeTakenRecommendation",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(AmazonGeneralFields.actionsToBeTaken, report),
                        sharedReportId,
                        imagesData: [],
                        isRequired: false,
                    },
                },
            ],
        };
    }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportDetails.amazon.summary.reportSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateRangeSummaryField,
                props: {
                    from: getFieldValueByFieldName(AmazonGeneralFields.dateTimeStarted, report),
                    to: getFieldValueByFieldName(AmazonGeneralFields.dateTimeEnded, report),
                    fieldName: "reportdetails.amazon.summary.eventDuration",
                    desktopDirection: "row",
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportDetails.amazon.summary.title",
                    fieldValue: getFieldValueByFieldName(AmazonGeneralFields.title, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.policeInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.policeInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.medicalsInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.medicalServicesInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.fireDeptInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.fireDepartmentInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: DateRangeSummaryField,
                props: {
                    from: getStartDateByEndTimeAndReferenceDate(
                        getFieldValueByFieldName(AmazonGeneralFields.timeOnSite, report),
                        getFieldValueByFieldName(AmazonGeneralFields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    to: createExplicitDateFromReference(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AmazonGeneralFields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldName: "reportdetails.amazon.summary.timeOnSite",
                    isRequired: false,
                    desktopDirection: "row",
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportDetails.amazon.summary.reference",
                    fieldValue: getFieldValueByFieldName(AmazonGeneralFields.reference, report),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.supervisorNotified",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.siteSupervisorNotified, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.facilityManagementActionReq",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.facilityManagementActionRequired, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.socActionRequired",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.socActionRequired, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(AmazonGeneralFields)),
        ],
    },
];
