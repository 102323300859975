import { View } from "@react-pdf/renderer";

import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { styles } from "../pdfStyles";

export const PdfIndentSection = ({ children }: ChildrenProps) => {
    return (
        <View style={styles.indentedSection}>
            {children}
            <View style={{ ...styles.sectionLineBreak }} />
        </View>
    );
};
