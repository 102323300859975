import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

import { BoxShadow } from "../../../../styles/templates/BoxShadow";

export const CommentEditorWrapper = styled.div`
    //styling the suggestions dropdown
    .suggestionsContainer {
        max-height: 160px;
        margin-top: ${Spacing.XS}px;
        border-radius: ${Spacing.XS}px;
        box-shadow: ${BoxShadow.S};
        padding-top: ${Spacing.S}px;
        padding-bottom: ${Spacing.S}px;
        background: ${Palette.Navy600};
        overflow-y: auto;
        z-index: 0;
        overscroll-behavior: none;

        @media (max-width: ${Breakpoints.XS}) {
            padding-top: ${Spacing.XS}px;
            padding-bottom: ${Spacing.XS}px;
        }
    }

    .mention {
        font-weight: bold;
        color: ${Palette.Purple400};
    }
`;

export const CommentEditorArea = styled.div`
    position: relative;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    overflow-y: auto;
    outline: none;
    border: 2px solid ${Palette.Navy300};
    background-color: ${Palette.Navy750};
    padding: ${Spacing.S}px;
    border-radius: 24px;

    &:focus-within {
        caret-color: ${Palette.Purple300};
        border-color: ${FocusStyles.OutlineColor};
    }

    ::-webkit-scrollbar-track {
        margin: ${Spacing.M}px !important;
    }

    @media (hover: hover) {
        &:hover {
            border-color: ${Palette.Purple400};
        }
    }

    //using the class of draft editor to style the placeholder properly
    .public-DraftEditorPlaceholder-root {
        color: ${Palette.Navy100};
        position: absolute;
        z-index: 0;
        white-space: nowrap;
        user-select: none;
    }

    div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
        line-height: 24px;
    }

    div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr div {
        display: inline-block;
    }

    //styling the suggestions dropdown
    .suggestionsContainer {
        margin-top: ${Spacing.S}px;

        div:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        div:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    &:hover {
        cursor: text;
    }

    @media (max-width: ${Breakpoints.M}) {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
    }
`;
