/**
 * Appends access endpoint to the URL
 */

import { RSAA } from "redux-api-middleware";

const apiAccessMiddleware = () => (next) => async (action) => {
    const callAPI = action[RSAA];

    if (callAPI) {
        if (Object.prototype.hasOwnProperty.call(callAPI, "accessless") && callAPI.accessless) {
            // there are calls that are made not to access API
            // we do not need to append access endpoint
            // remove the "accessless" property from RSAA object and continue
            delete callAPI.accessless;
        } else {
            if (!callAPI.endpoint.startsWith(process.env.REACT_APP_API_ENDPOINT)) {
                callAPI.endpoint = process.env.REACT_APP_API_ENDPOINT + callAPI.endpoint;
            }
        }
    }
    return next(action);
};

export default apiAccessMiddleware;
