import { Breadcrumb, Breadcrumbs, Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectedCategories } from "../InsightsSitePage.types";

interface BarchartBreadcrumbsProps {
    categoryLevel1: string;
    categoryLevel2?: string;
    setBarchart: (selectedCategories: SelectedCategories) => void;
}

export const BarchartBreadcrumbs: React.FC<BarchartBreadcrumbsProps> = ({ categoryLevel1, categoryLevel2, setBarchart }) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const breadcrumbs = [
        {
            key: "allIncidents",
            label: t("insights.sitesPage.allIncidents"),
            callback: () => setBarchart({ level1: null, level2: null }),
        },
        {
            key: "level2",
            label: `${t("incident.category." + categoryLevel1)} ${t("incidentMatrix.table.backButton.events")}`,
            callback: () => setBarchart({ level1: categoryLevel1, level2: null }),
        },
        categoryLevel2 && {
            key: "level3",
            label: `${t("incident.category." + categoryLevel2)} ${t("incidentMatrix.table.backButton.events")}`,
            callback: () => setBarchart({ level1: categoryLevel1, level2: categoryLevel2 }),
        },
    ].filter(Boolean);

    return (
        <Stack alignItems="center" gap="S" flexWrap="wrap" ml={isMobile ? "0" : "XS"} mb="XS">
            <Breadcrumbs data-testid="sites-barcharts-breadcrumbs">
                {breadcrumbs.map(({ key, label, callback }) => (
                    <Breadcrumb onClick={callback} key={key}>
                        {label}
                    </Breadcrumb>
                ))}
            </Breadcrumbs>
        </Stack>
    );
};
