import { PersistedState } from "redux-persist";

import { IUser } from "../../models/UserModel";
import { RequestStatus } from "./../RequestStatus";

export const USER_SET_PREFERENCE_REQUEST = "@@user/USER_SET_PREFERENCE_REQUEST";
export const USER_SET_PREFERENCE_SUCCESS = "@@user/USER_SET_PREFERENCE_SUCCESS";
export const USER_SET_PREFERENCE_FAILURE = "@@user/USER_SET_PREFERENCE_FAILURE";

export const USER_GET_INFO_REQUEST = "@@user/USER_GET_INFO_REQUEST";
export const USER_GET_INFO_SUCCESS = "@@user/USER_GET_INFO_SUCCESS";
export const USER_GET_INFO_FAILURE = "@@user/USER_GET_INFO_FAILURE";

export interface IUserState extends PersistedState {
    info: IUser;
    invalidated: boolean;
    status: RequestStatus;
}
