import i18next from "i18next";
import { differenceInDays, differenceInMinutes } from "src/helpers/date";

export const getSharedLinkExpirationMessage = (expirationTimestampString: string) => {
    const remainingDays = differenceInDays(new Date(Date.parse(expirationTimestampString)), Date.now());
    if (remainingDays > 0) {
        return `${i18next.t("reportdetails.linkExpires")} ${i18next.t("reportdetails.day", { count: remainingDays })}`;
    }
    const minutesDiff = differenceInMinutes(new Date(Date.parse(expirationTimestampString)), Date.now());
    if (minutesDiff < 60) {
        return `${i18next.t("reportdetails.linkExpires")} ${i18next.t("common.minutes", { count: minutesDiff })}`;
    }

    const hours = Math.floor(minutesDiff / 60);
    const minutes = minutesDiff % 60;
    return `${i18next.t("reportdetails.linkExpires")} ${i18next.t("common.hours", { count: hours })} ${i18next.t("common.and")} ${i18next.t("common.minutes", {
        count: minutes,
    })}`;
};
