import { createReducer } from "@reduxjs/toolkit";

import {
    ADD_IN_APP_NOTIFICATION,
    APP_API_ERROR,
    APP_DB_ERROR,
    IAppState,
    REMOVE_IN_APP_NOTIFICATION,
    REMOVE_LAST_IN_APP_NOTIFICATION,
    SET_IS_EMBEDDED_IN_NATIVE_HUB,
    STORE_QUERY_PARMAS,
} from "./types";

const silentErrorCodes = [401, 404, 410];

const defaultState: IAppState = {
    errorInfo: [],
    inAppNotifications: [],
    isEmbeddedInNativeHub: null,
    queryParams: {},
};

const appReducer = createReducer(defaultState, {
    [APP_API_ERROR]: (state, action) => {
        state.errorInfo.push({ ...action.error, silent: silentErrorCodes.includes(action.error?.status) });
    },
    [APP_DB_ERROR]: (state, action) => {
        state.errorInfo.push({ ...action.error, silent: false });
    },
    [ADD_IN_APP_NOTIFICATION]: (state, action) => {
        state.inAppNotifications.push(action.payload.notification);
    },
    [REMOVE_LAST_IN_APP_NOTIFICATION]: (state) => {
        if (state.inAppNotifications.length > 0) {
            state.inAppNotifications.pop();
        }
    },
    [REMOVE_IN_APP_NOTIFICATION]: (state, action) => {
        if (action.payload) {
            state.inAppNotifications = state.inAppNotifications.filter((n) => n.id !== action.payload);
        }
    },
    [SET_IS_EMBEDDED_IN_NATIVE_HUB]: (state, action) => {
        state.isEmbeddedInNativeHub = action.payload;
    },
    [STORE_QUERY_PARMAS]: (state, action: { payload: Record<string, string> }) => {
        state.queryParams = action.payload;
    },
});

export default appReducer;
