import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { isNullOrUndefined } from "../../../helpers/PropertiesValueHelper";
import { DateRangeField, DefaultItemField, Direction } from "../DefaultItemField";

type PropsType = {
    fieldName: string;
    from?: Date;
    to?: Date;
    isRequired?: boolean;
    desktopDirection: Direction;
    mobileDirection: Direction;
};

export const DateRangeSummaryField = ({ fieldName, from, to, isRequired = true, desktopDirection, mobileDirection }: PropsType): JSX.Element => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    const direction = isMobile ? mobileDirection : desktopDirection;

    if (isRequired || (!isNullOrUndefined(from) && !isNullOrUndefined(to))) {
        return (
            <DefaultItemField label={t(fieldName)} direction={direction}>
                <DateRangeField dateFrom={from} dateTo={to} direction={direction} />
            </DefaultItemField>
        );
    }
    return <></>;
};
