import { Outlet } from "react-router-dom";

import { InsightsPageProvider } from "./InsightsPageProvider";

export const InsightsContextWrapper = () => {
    return (
        <InsightsPageProvider>
            <Outlet />
        </InsightsPageProvider>
    );
};
