import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSearchParam } from "../../hooks/CommonHooks";
import { RequestStatus } from "../RequestStatus";
import AccessActions from "./AccessActions";
import AccessSelectors from "./AccessSelectors";

export const useAccessToken = () => {
    const dispatch = useDispatch();
    const { getIdTokenClaims } = useAuth0();
    const t = useSearchParam("t");
    const { accessToken, status } = useSelector(AccessSelectors.getAccessToken);

    useEffect(() => {
        const fetchAccessToken = async () => {
            if (process.env.REACT_APP_RUN_STANDALONE === "true") {
                const claims = await getIdTokenClaims();
                dispatch(AccessActions.getAccessToken(claims.__raw));
            } else {
                dispatch(AccessActions.getAccessTokenBySignOnToken(t));
            }
        };
        if (status === RequestStatus.undefined && !accessToken) {
            fetchAccessToken();
        }
    }, [status, accessToken, dispatch, getIdTokenClaims, t]);

    return { accessToken, status };
};
