import { Breakpoints, Palette, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import i18next from "i18next";
import _isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getGroupsTree } from "../../helpers/LocationsHelper";
import { getAllDescendants, getNewDropdownSelection } from "../../helpers/MultiDropdownHelper";
import { MixpanelEvent } from "../../models/tracking/mixpanelEvents";
import { applyFilters } from "../../store/filter/FilterActions";
import { useFiltersSelection, useReportCategoryItems, useReportTypeItems } from "../../store/filter/FilterHooks";
import FilterSelectors from "../../store/filter/FilterSelectors";
import { useUngroupedSites } from "../../store/locations/LocationsHooks";
import LocationSelectors from "../../store/locations/LocationsSelectors";
import { RequestStatus } from "../../store/RequestStatus";
import { RegionItem, useSiteObjectItemsByRegions } from "../../store/siteObjects/SiteObjectsHooks";
import TrackingActions from "../../store/tracking/TrackingActions";
import { IMultiListItem } from "../shared/CheckboxList/CheckboxListItem.types";
import { FilterObjectWrapper } from "../shared/filters/FilterObjectWrapper";
import { FilterToolbar } from "../shared/filters/FilterToolbar";
import { HorizontalLine } from "../shared/HorizontalLine";
import UnreadReportsFilter from "../UnreadReportsFilter/UnreadReportsFilter";

export const getSiteLocations = () => [
    { value: "1. Public Domain", label: i18next.t("siteLocations.publicDomain") } as IMultiListItem,
    { value: "2. Public Access", label: i18next.t("siteLocations.publicAccess") } as IMultiListItem,
    { value: "3. Secure Zone", label: i18next.t("siteLocations.secureZone") } as IMultiListItem,
    { value: "4. Restricted Area", label: i18next.t("siteLocations.restrictedArea") } as IMultiListItem,
];

export const getSeverityItems = () => [
    { value: "4", label: i18next.t("common.severity.warning.severe") } as IMultiListItem,
    { value: "3", label: i18next.t("common.severity.warning.high") } as IMultiListItem,
    { value: "2", label: i18next.t("common.severity.warning.moderate") } as IMultiListItem,
    { value: "1", label: i18next.t("common.severity.warning.low") } as IMultiListItem,
    { value: "0", label: i18next.t("common.severity.warning.info") } as IMultiListItem,
];

export const UNGROUPED_ITEMS_VALUE = "ungroupedItems";
interface Props {
    onClose: () => void;
}

export const ReportsFilter = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { reportCategories, categoriesFetchStatus, selectedCategories: preselectedCategories } = useReportCategoryItems();
    const { reportTypeItems } = useReportTypeItems();
    const preselectedReportTypes = useSelector(FilterSelectors.getSelectedReportTypes);
    const preselectedSeverityFilters = useSelector(FilterSelectors.getSelectedSeverityLevelIds);
    const preselectedSiteLocations = useSelector(FilterSelectors.getSelectedSiteLocations);
    const preselectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const preselectedShowOnlyUnread = useSelector(FilterSelectors.getShowOnlyUnreadSelector);

    const [selectedSeverities, setSelectedSeverities] = useState(preselectedSeverityFilters);
    const [selectedSiteLocations, setSelectedSiteLocations] = useState(preselectedSiteLocations);
    const [selectedReportTypes, setSelectedReportTypes] = useState(preselectedReportTypes);
    const [selectedCategories, setSelectedCategories] = useState(preselectedCategories);
    const [selectedRegions, setSelectedRegions] = useState<string[]>(preselectedRegions);
    const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(preselectedShowOnlyUnread);

    const [hasSelectionChanged, setHasSelectionChanged] = useState<boolean>(false);

    const { selectedFiltersCount, getFinalCategorySelection } = useFiltersSelection(
        selectedSeverities,
        selectedReportTypes,
        selectedCategories,
        selectedSiteLocations,
        selectedRegions,
        showOnlyUnread,
    );

    const locations = useSelector(LocationSelectors.getAuthorizedLocations);
    const { sitesByRegions } = useSiteObjectItemsByRegions();
    const availableSites = useMemo(() => {
        return Object.keys(sitesByRegions.itemsLookup).map((i) => i);
    }, [sitesByRegions]);
    const sitesByRegionFiltered = useUngroupedSites();

    const singleSite = locations.siteObjects.length === 1 ? [{ label: locations.siteObjects[0].name, value: locations.siteObjects[0].id }] : null;

    const groupList = [...getGroupsTree(locations.groups, locations.siteObjects)] as RegionItem[];

    const groupListLength = groupList.length;

    if (sitesByRegionFiltered.length > 0)
        groupList.push({ label: t("filters.locations.ungrouped"), value: UNGROUPED_ITEMS_VALUE, children: sitesByRegionFiltered });

    const applyFilter = useCallback(() => {
        const finalCategorySelection = getFinalCategorySelection();
        dispatch(
            applyFilters(
                selectedSeverities,
                finalCategorySelection.selectedCategories1,
                finalCategorySelection.selectedCategories2,
                finalCategorySelection.selectedCategories3,
                selectedReportTypes,
                selectedSiteLocations,
                selectedRegions,
                showOnlyUnread,
            ),
        );
        dispatch(
            TrackingActions.trackEvent(MixpanelEvent.ReportListFilterApply, {
                categoriesCount:
                    finalCategorySelection.selectedCategories1.length +
                    finalCategorySelection.selectedCategories2.length +
                    finalCategorySelection.selectedCategories3.length,
                reportTypesCount: selectedReportTypes.length,
                siteZonesCount: selectedSiteLocations.length,
                indicatedPriorityCount: selectedSeverities.length,
                selectedRegionsCount: selectedRegions.length,
            }),
        );

        if (isMobile) onClose();
    }, [
        dispatch,
        getFinalCategorySelection,
        isMobile,
        onClose,
        selectedRegions,
        selectedReportTypes,
        selectedSeverities,
        selectedSiteLocations,
        showOnlyUnread,
    ]);

    const severityItems: IMultiListItem[] = useMemo(() => getSeverityItems(), []);

    const siteLocations: IMultiListItem[] = useMemo(() => getSiteLocations(), []);

    const handleSeverityLevelChange = useCallback(
        (severityLevel: IMultiListItem) => {
            const isSelected = selectedSeverities.some((i) => i === severityLevel.value);

            if (isSelected) {
                setSelectedSeverities(selectedSeverities.filter((s) => s !== severityLevel.value));
            } else {
                setSelectedSeverities([...selectedSeverities, severityLevel.value]);
            }
        },
        [selectedSeverities],
    );

    const handleSiteLocationChange = useCallback(
        (siteLocation: IMultiListItem) => {
            const isSelected = selectedSiteLocations.some((i) => i === siteLocation.value);

            if (isSelected) {
                setSelectedSiteLocations(selectedSiteLocations.filter((s) => s !== siteLocation.value));
            } else {
                setSelectedSiteLocations([...selectedSiteLocations, siteLocation.value]);
            }
        },
        [selectedSiteLocations],
    );

    const handleReportTypeChange = useCallback(
        (type: IMultiListItem) => {
            const isSelected = selectedReportTypes.some((i) => i === type.value);
            if (isSelected) {
                setSelectedReportTypes(selectedReportTypes.filter((s) => s !== type.value));
            } else {
                setSelectedReportTypes([...selectedReportTypes, type.value]);
            }
        },
        [selectedReportTypes],
    );

    const handleCategoryChange = useCallback(
        (category: IMultiListItem) => {
            const newSelection = getNewDropdownSelection(category, selectedCategories);
            setSelectedCategories(newSelection);
        },
        [selectedCategories],
    );

    const handleLocationChange = useCallback(
        (location: IMultiListItem) => {
            const newSelection = getNewDropdownSelection(location, selectedRegions);
            setSelectedRegions(newSelection);
        },
        [selectedRegions],
    );

    const handleUnreadChange = useCallback(() => {
        setShowOnlyUnread((prev) => !prev);
    }, []);

    const clearSelectedSiteLocations = useCallback(() => {
        setSelectedSiteLocations([]);
    }, []);

    const clearSelectedReportCategories = useCallback(() => {
        setSelectedCategories([]);
    }, []);

    const clearSelectedReportTypes = useCallback(() => {
        setSelectedReportTypes([]);
    }, []);

    const clearSelectedSeverityLevels = useCallback(() => {
        setSelectedSeverities([]);
    }, []);

    const clearAll = useCallback(() => {
        setSelectedSeverities([]);
        setSelectedReportTypes([]);
        setSelectedCategories([]);
        setSelectedSiteLocations([]);
        setSelectedRegions([]);
        setShowOnlyUnread(false);
    }, []);

    const selectAllCategories = useCallback(() => {
        const allCategories = reportCategories.reduce((categories, lv1) => {
            categories.push(lv1.value);
            return [...categories, ...getAllDescendants(lv1)];
        }, []);
        setSelectedCategories(allCategories);
    }, [reportCategories]);

    const selectAllSiteLocations = useCallback(() => {
        setSelectedSiteLocations([...siteLocations.map((i) => i.value)]);
    }, [siteLocations]);

    const selectAllReportTypes = useCallback(() => {
        setSelectedReportTypes([...reportTypeItems.map((i) => i.value)]);
    }, [reportTypeItems]);

    const selectAllSeverityLevels = useCallback(() => {
        setSelectedSeverities([...severityItems.map((i) => i.value)]);
    }, [severityItems]);

    useEffect(() => {
        setHasSelectionChanged(
            !(
                _isEqual(selectedSeverities, preselectedSeverityFilters) &&
                _isEqual(selectedReportTypes, preselectedReportTypes) &&
                _isEqual(selectedCategories, preselectedCategories) &&
                _isEqual(selectedSiteLocations, preselectedSiteLocations) &&
                _isEqual(selectedRegions, preselectedRegions) &&
                showOnlyUnread === preselectedShowOnlyUnread
            ),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSeverities, selectedReportTypes, selectedCategories, selectedSiteLocations, selectedRegions, showOnlyUnread]);

    useEffect(() => {
        if (!isMobile && hasSelectionChanged) applyFilter();
    }, [applyFilter, hasSelectionChanged, isMobile]);

    useEffect(() => {
        setSelectedSeverities(preselectedSeverityFilters);
    }, [preselectedSeverityFilters]);

    useEffect(() => {
        setSelectedSiteLocations(preselectedSiteLocations);
    }, [preselectedSiteLocations]);

    useEffect(() => {
        setSelectedReportTypes(preselectedReportTypes);
    }, [preselectedReportTypes]);

    useEffect(() => {
        setSelectedCategories(preselectedCategories);
    }, [preselectedCategories]);

    useEffect(() => {
        setSelectedRegions(preselectedRegions);
    }, [preselectedRegions]);

    useEffect(() => {
        setShowOnlyUnread(preselectedShowOnlyUnread);
    }, [preselectedShowOnlyUnread]);

    return (
        <FilterToolbar
            data-testid="filter-modal"
            activeFiltersCount={selectedFiltersCount}
            canSubmit={hasSelectionChanged}
            onClearAll={clearAll}
            onClose={onClose}
            onSubmit={applyFilter}
        >
            <UnreadReportsFilter showOnlyUnread={isMobile ? showOnlyUnread : preselectedShowOnlyUnread} handleUnreadChange={handleUnreadChange} />
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            {groupListLength < 1 ? (
                <FilterObjectWrapper
                    title={t("filters.locations.labelPlural")}
                    items={singleSite ? singleSite : sitesByRegions.items}
                    isLoading={categoriesFetchStatus === RequestStatus.loading}
                    cleanFilter={() => setSelectedRegions([])}
                    handleCheckChange={handleLocationChange}
                    selectedIds={selectedRegions}
                    selectAll={() => setSelectedRegions(availableSites)}
                />
            ) : (
                <FilterObjectWrapper
                    title={t("filters.locations.labelPlural")}
                    items={groupList}
                    isLoading={categoriesFetchStatus === RequestStatus.loading}
                    cleanFilter={() => setSelectedRegions([])}
                    handleCheckChange={handleLocationChange}
                    selectedIds={selectedRegions}
                    selectAll={() => setSelectedRegions([...locations.groups.map((x) => x.id), ...availableSites, UNGROUPED_ITEMS_VALUE])}
                />
            )}
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            <FilterObjectWrapper
                title={t("filters.title.reportcategory")}
                items={reportCategories}
                isLoading={categoriesFetchStatus === RequestStatus.loading}
                cleanFilter={clearSelectedReportCategories}
                handleCheckChange={handleCategoryChange}
                selectedIds={selectedCategories}
                selectAll={selectAllCategories}
            />
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            <FilterObjectWrapper
                title={t("filters.title.reportType")}
                items={reportTypeItems}
                isLoading={false}
                cleanFilter={clearSelectedReportTypes}
                handleCheckChange={handleReportTypeChange}
                selectedIds={selectedReportTypes}
                selectAll={selectAllReportTypes}
            />
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            <FilterObjectWrapper
                title={t("filters.title.siteLocation")}
                items={siteLocations}
                isLoading={false}
                cleanFilter={clearSelectedSiteLocations}
                handleCheckChange={handleSiteLocationChange}
                selectedIds={selectedSiteLocations}
                selectAll={selectAllSiteLocations}
            />
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            <FilterObjectWrapper
                title={t("filters.title.severitylevel")}
                items={severityItems}
                isLoading={false}
                cleanFilter={clearSelectedSeverityLevels}
                handleCheckChange={handleSeverityLevelChange}
                selectedIds={selectedSeverities}
                selectAll={selectAllSeverityLevels}
            />
        </FilterToolbar>
    );
};
