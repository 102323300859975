import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ScheduleFilters } from "../../components/Schedule/ScheduleFilters";
import { ScheduleHeader } from "../../components/Schedule/ScheduleHeader";
import { ScheduleTable } from "../../components/Schedule/ScheduleTable";
import LocationsSelectors from "../../store/locations/LocationsSelectors";
import { InsightsWrapper, PageWrapper } from "../Insights/Common.styled";
import { useTasks } from "./SchedulePage.hooks";

export const SchedulePage = () => {
    const { locationId } = useParams();
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(locationId));
    const { getTasks, isLoading, isSuccess, data } = useTasks();
    const [date, setDate] = useState<Date>(new Date());

    return (
        <PageWrapper direction="column">
            <InsightsWrapper paddingTop={["L", "L", "S"]} paddingHorizontal={["L", "L", "S"]} direction="column">
                <ScheduleHeader siteName={locationDetails.name} />
                <ScheduleFilters date={date} setDate={setDate} getTasks={getTasks} locationId={locationId} />
                <ScheduleTable siteName={locationDetails.name} isLoading={isLoading} isSuccess={isSuccess} tasks={data} />
            </InsightsWrapper>
        </PageWrapper>
    );
};
