import { Icon, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn, flexColumnJustifyCenterAlignCenter } from "../../../styles/templates/Flex";
import { Direction } from "./SpeechBubble";

const getArrowPositionStyle = (direction: Direction) => {
    switch (direction) {
        case "left":
            return `
                top: 20px;
                left: -8px;
            `;
        case "right":
            return `
                top: 20px;
                right: -8px;
            `;
        case "top":
            return `
                top: -8px;
                left: 27px;
            `;
        default:
            return "";
    }
};

const getBubbleMargin = (direction: Direction) => {
    switch (direction) {
        case "left":
            return "margin-left: 12px;";
        case "right":
            return "margin-right: 12px;";
        case "top":
            return "margin-top: 12px;";
        case null:
            return "";
        default:
            return "";
    }
};

export const Bubble = styled.div<{ direction: Direction }>`
    ${flexColumn}
    background-color: ${Palette.Navy100};
    color: ${Palette.Navy800};
    position: relative;
    align-items: flex-start;
    padding: ${Spacing.S}px ${Spacing.S}px ${Spacing.M}px;
    border-radius: ${Spacing.XXS}px;
    ${(props) => getBubbleMargin(props.direction)}

    ${(props) =>
        props.direction &&
        `&::before {
            position: absolute;
            width: ${Spacing.S}px;
            height: ${Spacing.S}px;
            content: "";
            transform: rotate(45deg);
            background-color: ${Palette.Navy100};
            ${getArrowPositionStyle(props.direction)}
        `};
`;

export const HeaderIcon = styled(Icon)`
    color: ${Palette.Navy800};
    margin-right: ${Spacing.XS}px;
`;

export const BubbleContent = styled.div`
    ${flexColumn}
    width: 100%;
    padding-top: ${Spacing.M}px;
`;

export const RoundedCircleBackground = styled.div`
    ${flexColumnJustifyCenterAlignCenter}
    margin-bottom: ${Spacing.S}px;
    background-color: ${Palette.Navy700};
    width: 130px;
    height: 130px;
    border-radius: 50%;
`;
