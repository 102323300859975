import { Breakpoints, Icon, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CellStyled } from "../../IncidentMatrix.styles";

export const CategoryCellStyled = styled(CellStyled)`
    padding: 0;

    @media (max-width: ${Breakpoints.XS}) {
        min-width: 100px;
    }

    & > div,
    & > div > div {
        display: grid;
        justify-content: start;
    }

    &::before {
        content: none;
    }
`;

export const CategoryNameStyled = styled(Text)<{ isFilterActive: boolean }>`
    padding-right: ${Spacing.S}px;

    @media (max-width: 1800px) {
        width: ${({ isFilterActive }) => (isFilterActive ? 115 : 180)}px;
    }

    @media (max-width: 1600px) {
        width: ${({ isFilterActive }) => (isFilterActive ? 90 : 160)}px;
    }

    @media (max-width: 1500px) {
        width: ${({ isFilterActive }) => (isFilterActive ? 85 : 135)}px;
    }

    @media (max-width: 1380px) {
        width: ${({ isFilterActive }) => (isFilterActive ? 50 : 100)}px;
    }

    @media (max-width: 880px) {
        width: 70px;
    }
`;

export const CategoryTotalStyled = styled(Text)`
    margin-top: 2px;
`;

export const CategoryIconStyled = styled(Icon)`
    margin-right: 5px;
    position: absolute;

    @media (max-width: ${Breakpoints.L}) {
        position: static;
    }
`;
