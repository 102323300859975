import { FetchInterceptor } from "@mswjs/interceptors/fetch";

import { POST_REPORTS_INSIGHTS_URL_REGEX } from "../handlers/constants";

export const initInterceptors = () => {
    const interceptor = new FetchInterceptor();
    interceptor.apply();

    // Modify Accept header for insight reports to accept json instead of sqlite response
    interceptor.on("request", ({ request }) => {
        if (request.url.match(POST_REPORTS_INSIGHTS_URL_REGEX)) {
            request.headers.set("Accept", "application/json");
        }
    });
};

export default initInterceptors;
