import { QueryResult } from "../models/DbModel";

export const parseSqliteResult = <T extends object>(result: QueryResult | undefined, useFirstColumnAsId?: boolean) => {
    const parsedResult: Record<string | number, Partial<T>> = {};

    result?.values?.forEach((rowData: T[], rowNumber) => {
        const parsedRow: Partial<T> = {};
        const key = useFirstColumnAsId ? `${rowData[0]}` : rowNumber;

        rowData.forEach((columnValue, index) => {
            if (!useFirstColumnAsId || index) {
                const columnName = result.columns[index];
                parsedRow[columnName] = columnValue;
            }
        });

        parsedResult[key] = parsedRow;
    });

    return parsedResult;
};
