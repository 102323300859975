import { joinedSorted } from "./../../helpers/ArrayHelper";

interface IGraphOverviewQueryKey {
    locationIds: string[];
    fromDate: Date;
    toDate: Date;
}

export const generateQueryKey = (keyParams: IGraphOverviewQueryKey): string => {
    return `${joinedSorted(keyParams.locationIds || [])}
    _${keyParams.fromDate?.getTime()}
    _${keyParams.toDate?.getTime()}`;
};
