import { Breakpoints, Palette, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import _isEqual from "lodash/isEqual";
import _throttle from "lodash/throttle";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getGroupsTree } from "../../../helpers/LocationsHelper";
import { getItemsFromSelectedIds, getNewDropdownSelection } from "../../../helpers/MultiDropdownHelper";
import { MixpanelEvent } from "../../../models/tracking/mixpanelEvents";
import FilterSelectors from "../../../store/insights/FilterSelectors";
import InsightsActions from "../../../store/insights/InsightsActions";
import { useUngroupedSites } from "../../../store/locations/LocationsHooks";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { RegionItem, useSiteObjectItemsByRegions } from "../../../store/siteObjects/SiteObjectsHooks";
import TrackingActions from "../../../store/tracking/TrackingActions";
import { UNGROUPED_ITEMS_VALUE } from "../../Reports/ReportsFilter";
import { IMultiListItem } from "../../shared/CheckboxList/CheckboxListItem.types";
import { FilterObjectWrapper } from "../../shared/filters/FilterObjectWrapper";
import { FilterToolbar } from "../../shared/filters/FilterToolbar";
import { HorizontalLine } from "../../shared/HorizontalLine";

type Props = {
    onClose: () => void;
};

export const InsightsFilter = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const preselectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const [selectedRegions, setSelectedRegions] = useState<string[]>(preselectedRegions);
    const { sitesByRegions } = useSiteObjectItemsByRegions();
    const { itemsCount } = getItemsFromSelectedIds(selectedRegions, sitesByRegions.items);

    const [hasSelectionChanged, setHasSelectionChanged] = useState<boolean>(false);

    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);

    const availableSites = useMemo(() => Object.keys(sitesByRegions.itemsLookup).map((i) => i), [sitesByRegions]);

    const singleSite = locations.siteObjects.length === 1 ? [{ label: locations.siteObjects[0].name, value: locations.siteObjects[0].id }] : null;

    const sitesByRegionsFiltered = useUngroupedSites();

    const groupList = getGroupsTree(locations.groups, locations.siteObjects) as RegionItem[];

    const groupListLength = groupList.length;

    if (sitesByRegionsFiltered.length > 0)
        groupList.push({ label: t("filters.locations.ungrouped"), value: UNGROUPED_ITEMS_VALUE, children: sitesByRegionsFiltered });

    const throttledChangeHandler = useRef(
        _throttle(
            (dispatchAction, regions) => {
                dispatchAction(TrackingActions.trackEvent(MixpanelEvent.InsightsLocationFilterApply));
                dispatchAction(InsightsActions.selectLocations(regions));
            },
            500,
            { leading: false },
        ),
    );

    const applyFilter = useCallback(() => {
        throttledChangeHandler.current(dispatch, selectedRegions);
        if (isMobile) onClose();
    }, [dispatch, selectedRegions, isMobile, onClose]);

    const clearAll = () => {
        setSelectedRegions([]);
    };

    useEffect(() => {
        setSelectedRegions(preselectedRegions);
    }, [preselectedRegions]);

    const handleLocationChange = useCallback(
        (location: IMultiListItem) => {
            const newSelection = getNewDropdownSelection(location, selectedRegions);
            setSelectedRegions(newSelection);
        },
        [selectedRegions],
    );

    useEffect(() => {
        setHasSelectionChanged(!_isEqual(selectedRegions, preselectedRegions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegions]);

    useEffect(() => {
        if (!isMobile && hasSelectionChanged) {
            applyFilter();
        }
    }, [applyFilter, hasSelectionChanged, isMobile]);

    return (
        <FilterToolbar
            data-testid="filter-modal"
            activeFiltersCount={itemsCount}
            canSubmit={hasSelectionChanged}
            onClearAll={clearAll}
            onClose={onClose}
            onSubmit={applyFilter}
        >
            <HorizontalLine color={Palette.Navy300} marginLeft={0} marginTop={Spacing.XS} marginBottom={Spacing.S} />
            {groupListLength < 1 ? (
                <FilterObjectWrapper
                    title={t("filters.locations.labelPlural")}
                    items={singleSite ? singleSite : sitesByRegions.items}
                    cleanFilter={() => setSelectedRegions([])}
                    handleCheckChange={handleLocationChange}
                    selectedIds={selectedRegions}
                    selectAll={() => setSelectedRegions(availableSites)}
                    isLoading={false}
                />
            ) : (
                <FilterObjectWrapper
                    title={t("filters.locations.labelPlural")}
                    items={groupList}
                    isLoading={false}
                    cleanFilter={() => setSelectedRegions([])}
                    handleCheckChange={handleLocationChange}
                    selectedIds={selectedRegions}
                    selectAll={() => setSelectedRegions([...locations.groups.map((x) => x.id), ...availableSites, UNGROUPED_ITEMS_VALUE])}
                />
            )}
        </FilterToolbar>
    );
};
