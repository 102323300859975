import { http } from "msw";

import { getReportsResolver } from "../../resolvers";
import { GetReportsPayload } from "../../types";
import { POST_REPORTS_SEARCH_URL_REGEX } from "../constants";

export default [
    http.post(POST_REPORTS_SEARCH_URL_REGEX, async ({ request }) => {
        const payload = (await request.json()) as GetReportsPayload;

        return getReportsResolver(request.url, payload);
    }),
];
