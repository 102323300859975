import { useParams } from "react-router-dom";

import { useSharedReport } from "../../../../store/reports/ReportsHooks";
import Generic from "./Generic";
import { SharedEntityPage } from "./SharedEntityPage";

export const SharedReportPage = () => {
    const { sharedReportId } = useParams<{ sharedReportId?: string }>();
    const { report, siteObject, loading, error, expirationTimestamp } = useSharedReport(sharedReportId, false);
    return (
        <SharedEntityPage isLoading={loading} expirationTimestampStr={expirationTimestamp} error={error} shareId={sharedReportId}>
            <Generic report={report} siteObject={siteObject} sharedReportId={sharedReportId} />
        </SharedEntityPage>
    );
};
