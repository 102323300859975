import type { SwipeActionItem } from "../../types";
import { ButtonTextStyled, WrapperStyled } from "./SwipeActionsMenuItem.styles";

type Props = {
    action: SwipeActionItem;
};

export const SwipeActionsMenuItem = ({ action }: Props) => {
    const { label, icon, disabled, onClick } = action;

    return (
        <WrapperStyled onClick={onClick}>
            <ButtonTextStyled icon={icon} disabled={disabled}>
                {label}
            </ButtonTextStyled>
        </WrapperStyled>
    );
};
