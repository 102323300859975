import { ContentState, convertToRaw, EditorState } from "draft-js";

import { sortByField } from "../../../../helpers/ArrayHelper";
import { ICommentModel, makeUserMentionInComment } from "../../../../store/reportComments/models";
import { IUserMention } from "./CommentEditor.types";

export const handleSpecialCharacters = (text: string) => text.replaceAll(/&#(\d+);/g, (_, capture) => String.fromCharCode(capture));

export const recreateEditorFromComment = (comment: ICommentModel, usersForMentions: IUserMention[]) => {
    const mentionMap = {};
    const rawContent = convertToRaw(ContentState.createFromText(comment.content, "\n"));
    //create mention data structure for the plugin
    let key = 0;
    for (const userId of comment.mentionedUserIds) {
        const user = usersForMentions.find((u) => u.id === userId);
        if (user) {
            mentionMap[key] = { data: { mention: user }, mutability: "IMMUTABLE", type: "mention" };
            key++;
        }
    }

    rawContent.entityMap = mentionMap;

    //update content blocks with indices of mentions used in specific part
    rawContent.blocks = rawContent.blocks.map((block) => {
        const mentionRanges = [];
        /*
        characters like &#229; (å) are now handled in BE,
        but in old comments we still need to handle it in FE
       */
        let finalText = handleSpecialCharacters(block.text);

        Object.values(mentionMap).forEach((entity, mentionIndex) => {
            const indices = [];
            const user = (entity as any).data.mention;

            const userMentionRepresentation = makeUserMentionInComment(user.id);
            let currentPosition = finalText.indexOf(userMentionRepresentation);
            while (currentPosition > -1) {
                finalText = finalText.replace(userMentionRepresentation, user.name);
                indices.push(currentPosition);
                currentPosition = finalText.indexOf(userMentionRepresentation);
            }

            if (indices.length) {
                const entityRanges = indices.map((i) => ({ key: mentionIndex, length: user.name.length, offset: i }));
                mentionRanges.push(...entityRanges);
            }
        });
        return { ...block, text: finalText, entityRanges: mentionRanges };
    });
    return rawContent;
};

export const getContentAndMentions = (editorState: EditorState) => {
    const contentStateRaw = convertToRaw(editorState.getCurrentContent());
    const blocks = contentStateRaw.blocks;
    const entitymap = contentStateRaw.entityMap;

    const mentionedUserIds = Object.values(entitymap).map((entity) => entity.data.mention.id);

    let textFinal = "";

    blocks.forEach((block) => {
        const entityRanges = block.entityRanges.sort(sortByField("offset"));
        let index = 0;
        if (textFinal) {
            textFinal += "\n";
        }
        entityRanges.forEach((e) => {
            const substitution = makeUserMentionInComment(entitymap[e.key].data?.mention?.id);
            if (index < e.offset) {
                textFinal += block.text.substring(index, e.offset);
                index = e.offset;
            }
            textFinal += substitution;
            index += e.length;
        });

        if (index < block.text.length) {
            textFinal += block.text.substr(index);
        }
    });
    return { mentionedUsers: mentionedUserIds, textFinal };
};
