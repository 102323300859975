import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled.div<{ collapsable: boolean; elMaxHeight?: string; animationEnabled?: boolean }>`
    transition: ${({ animationEnabled }) => (animationEnabled ? "height 500ms ease-in-out" : null)};
    overflow: ${({ collapsable }) => (collapsable ? "hidden" : null)};
    height: ${({ elMaxHeight }) => (elMaxHeight ? elMaxHeight : null)};
`;

export const RightHeaderPartStyled = styled(Stack).attrs<React.ComponentProps<typeof Stack>>({
    flexWrap: "wrap",
    justifyContent: "flex-end",
    flex: 1,
})`
    text-align: right;
    min-width: fit-content;
`;
