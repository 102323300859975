/**
 * Adds Authorization headers to request
 */

import { RSAA } from "redux-api-middleware";

import { IState } from "./../Store";

const apiAuthMiddleware =
    ({ getState }) =>
    (next) =>
    async (action) => {
        const callAPI = action[RSAA];
        const state: IState = getState();
        const {
            access: { accessToken },
        } = state;

        if (callAPI && callAPI.auth) {
            if (accessToken) {
                callAPI.headers = {
                    ...callAPI.headers,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                };
            }
            delete callAPI.auth;
        }

        return next(action);
    };

export default apiAuthMiddleware;
