export enum ImageSizePaths {
    Small = "smallSizeUrl",
    Medium = "mediumSizeUrl",
    Large = "largeSizeUrl",
}

export interface IImageData {
    format: string;
    [ImageSizePaths.Small]: string;
    [ImageSizePaths.Medium]: string;
    [ImageSizePaths.Large]: string;
    reportFieldName?: string;
}

export interface IReportImage {
    image: string;
    loading: boolean;
    error: any;
    reportFieldName?: string;
}

export interface IReportImagesState {
    byImagePath: Record<string, IReportImage>;
    loadingMultiple: boolean;
}

export const convertImageToBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.onload = () => {
        callback(reader.result);
    };
    reader.onerror = (error) => console.log("File Reader Error: ", error);
    reader.readAsDataURL(file);
};
