import { ChartCategoryType } from "./TrendingEventChart.types";

export const CHART_CATEGORIES: ChartCategoryType[] = [
    {
        color: "dataSeries1",
        iconVariant: "GraphDataSeries1",
        strokeDashArray: "8 4",
    },
    {
        color: "dataSeries2",
        iconVariant: "GraphDataSeries2",
        strokeDashArray: "3 4",
    },
    {
        color: "dataSeries3",
        iconVariant: "GraphDataSeries3",
        strokeDashArray: "1 4",
    },
    {
        color: "dataSeries4",
        iconVariant: "GraphDataSeries4",
        strokeDashArray: "3 2 1 2",
    },
    {
        color: "dataSeries5",
        iconVariant: "GraphDataSeries5",
        strokeDashArray: "5 4",
    },
];
