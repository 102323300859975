import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useSummaryPeriod } from "../shared/hooks";
import { useTourSessionsPerSite } from "../shared/hooks/useTourSessionsPerSite";
import { SummaryPeriod } from "../shared/types";
import { ToursIndicators } from "./PerformanceIndicatorsWidget.types";

export const usePerformanceIndicatorsWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { values: currentValues, isLoading: isLoadingCurrent } = useReducedIndicatorValues(currentPeriod);
    const { values: previousValues, isLoading: isLoadingPrevious } = useReducedIndicatorValues(previousPeriod);

    const indicators = [
        {
            title: t("insights.summary.performanceIndicators.completedTours"),
            value: currentValues.completedTours,
            prevValue: previousValues.completedTours,
        },
        {
            title: t("insights.summary.performanceIndicators.uncompletedTours"),
            value: currentValues.uncompletedTours,
            prevValue: previousValues.uncompletedTours,
        },
    ];

    return { indicators, isLoading: isLoadingCurrent || isLoadingPrevious };
};

const useReducedIndicatorValues = (period: SummaryPeriod) => {
    const { siteIds } = useFilteredSites();
    const { isLoading, tourSessionsMap } = useTourSessionsPerSite(siteIds, period.start, period.end);

    const toursStats: ToursIndicators = useMemo(() => {
        const emptyIndicatorsValues: ToursIndicators = { completedTours: 0, uncompletedTours: 0 };

        if (tourSessionsMap) {
            return Object.values(tourSessionsMap).reduce(
                ({ uncompletedTours, completedTours }, { completedCount, incompleteCount }) => ({
                    completedTours: completedTours + completedCount,
                    uncompletedTours: uncompletedTours + incompleteCount,
                }),
                emptyIndicatorsValues,
            );
        }

        return emptyIndicatorsValues;
    }, [tourSessionsMap]);

    return { values: toursStats, isLoading };
};
