import { Breakpoints, Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { getVehicleInspectionReportProperties } from "../../../helpers/reportTemplates/vehicleInspectionHelper";
import { DynamicReportType } from "../../../models/ReportModel";
import { CarInpsectionIcon } from "./CarInspectionIcon";

type Props = {
    report: DynamicReportType;
};

const StyledStack = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

const StyledCarInspectionStack = styled(Stack)`
    margin-left: 16px;
`;

const StyledCarInspectionIcon = styled(CarInpsectionIcon)`
    margin-top: 1px;
    flex-shrink: 0;
    align-self: flex-start;

    @media (max-width: ${Breakpoints.L}) {
        margin-top: 0px;
    }
`;

export const CarHeaderLine = ({ report }: Props) => {
    const { licensePlate, vehicleModel, inspectionCount } = getVehicleInspectionReportProperties(report.properties);

    return (
        <StyledStack direction="row">
            <Text micro color="primary" bold>
                {licensePlate}
            </Text>
            <Separator variant="vertical" ml="S" mr="S" />
            <Text micro color="secondary">
                {vehicleModel}
            </Text>
            <StyledCarInspectionStack gap="XXS" alignItems="center">
                <StyledCarInspectionIcon width={16} height={16} />
                <Text micro color="secondary" uppercase>
                    {inspectionCount}
                </Text>
            </StyledCarInspectionStack>
        </StyledStack>
    );
};
