import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { ToursRequestBody } from "../../../store/insights/InsightsModel";
import { db } from "../../db";
import { createDateTimeFilter } from "../../helpers";

export const getDailyTourExceptionsSummaryByCategory1LevelResolver = (categoryLevel1: string, payload: ToursRequestBody) => {
    const filter = buildFilter(payload);

    const exceptions = db.reports.findMany({
        where: { ...filter, categoryLevel1: { equals: categoryLevel1 } },
    });

    const groupedExceptions = exceptions.reduce((acc, exception) => {
        const { reportDateTime, categoryLevel2 } = exception;

        const date = reportDateTime.split("T")[0];
        let item = acc.find((i) => i.dateTime === date && i.categoryLevel2 === categoryLevel2);
        if (item) {
            item.count += 1;
        } else {
            item = {
                categoryLevel2,
                count: 1,
                dateTime: date,
            };
            acc.push(item);
        }
        return acc;
    }, []);

    const result = Object.values(groupedExceptions);
    return HttpResponse.json(result);
};

const buildFilter = (requestParams: ToursRequestBody) => {
    const locationFilter = {
        locationId: {
            in: requestParams.siteIds,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter(requestParams),
        ...typeFilter,
    };
};

export default getDailyTourExceptionsSummaryByCategory1LevelResolver;
