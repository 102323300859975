import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { ContactInfo } from "src/components/Reports/SharedComponents/ContactInfo";

import { getConfig, getConfigPatrolTour } from "../../../../components/Reports/reportConfigs/DynamicTemplateConfig";
import { generateReportComponents } from "../../../../components/Reports/reportConfigs/DynamicTemplateUtils";
import { ReportProvider } from "../../../../components/Reports/ReportProvider";
import { Login } from "../../../../components/Reports/SharedComponents/Login";
import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { ReportDetailsContainer } from "./Generic.styles";

type PropsType = {
    report: DynamicReportType | PatrolTourType;
    siteObject: ISiteObject;
    sharedReportId?: string;
};

export const ReportDetailsMain = ({ report, sharedReportId, siteObject }: PropsType) => {
    const isTabletOrMobile = useHasMaxWidth(Breakpoints.M);
    const reportComponents = useMemo(() => {
        if (!report) {
            return null;
        }
        return generateReportComponents(
            {
                report,
                sharedReportId,
                siteObject,
            },
            isPatrolTour(report) ? getConfigPatrolTour : getConfig,
        );
    }, [report, sharedReportId, siteObject]);

    return (
        <ReportProvider report={report}>
            <ReportDetailsContainer direction="column" data-testid="report-details-main" isSharedReport={!!sharedReportId}>
                {!!sharedReportId && isTabletOrMobile && <Login report={report} siteObject={siteObject} />}
                {reportComponents}
                <ContactInfo countryCode={siteObject.countryCode} />
            </ReportDetailsContainer>
        </ReportProvider>
    );
};
