import { Breakpoints, Spacing, Tabs } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TabsStyled = styled(Tabs).attrs({ scrollBehavior: "instant" })<{ isFilterActive?: boolean }>`
    button:focus-visible {
        outline-offset: -4px !important;
        border-radius: 4px !important;
    }

    @media (max-width: ${Breakpoints.S}) {
        padding: ${(props) => (props.isFilterActive ? `0 ${Spacing.S}px` : `0 ${Spacing.M}px`)};
    }
`;
