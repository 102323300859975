import { Stack, Text } from "@secuis/ccp-react-components";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "src/components/shared/Tooltip";
import { useChildOverflows } from "src/hooks/CommonHooks";
import { ISiteObject } from "src/models/SiteObjectModel";

import {
    AddressContainerStyled,
    AddressStyled,
    AddressTextStyled,
    ButtonTextStyled,
    HoverGroupStyled,
    IconStyled,
    SiteTileContainerStyled,
    TextContainerStyled,
    TextStyled,
} from "./SitesTile.styles";

export type ISiteTileObject = Pick<ISiteObject, "id" | "city" | "name" | "address" | "countryCode">;

type Props = {
    site: ISiteTileObject;
};

export const SitesTile = (props: Props) => {
    const { site } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const cityRef = useRef(null);
    const nameRef = useRef(null);
    const addressRef = useRef(null);
    const enableCityTooltip = useChildOverflows(cityRef);
    const enableNameTooltip = useChildOverflows(nameRef, true);
    const enableAddressTooltip = useChildOverflows(addressRef);
    const countryTranslation = t(`country.${site.countryCode}`);

    const renderTitle = () => (
        <TextContainerStyled data-testid="site-tile-title" bold>
            <Tooltip message={site.city} disabled={!enableCityTooltip} id="cityInfo">
                <Text data-testid="site-city" bold ref={cityRef}>
                    {site.city}
                </Text>
            </Tooltip>
            <Tooltip message={site.name} disabled={!enableNameTooltip} id="nameInfo">
                <TextStyled bold ref={nameRef}>
                    {site.name}
                </TextStyled>
            </Tooltip>
        </TextContainerStyled>
    );

    const renderAddress = () => (
        <AddressStyled data-testid="site-tile-address">
            <Tooltip message={`${countryTranslation}, ${site.address}`} disabled={!enableAddressTooltip} id="addressInfo">
                <AddressContainerStyled data-testid="address-site-tile-container">
                    <IconStyled variant="Location" size="S" />
                    <AddressTextStyled micro ref={addressRef}>
                        {countryTranslation}, {site.address}
                    </AddressTextStyled>
                </AddressContainerStyled>
            </Tooltip>
        </AddressStyled>
    );

    const renderButtons = () => (
        <Stack mt="XS" alignItems="center" flexWrap="wrap">
            <HoverGroupStyled>
                <ButtonTextStyled
                    data-testid="site-tile-reports-button"
                    color="accent"
                    icon="Report"
                    direction="row"
                    iconSize="M"
                    onClick={() => navigate(`/reports?selectedRegions=${props.site.id}`)}
                >
                    <Text bold data-testid="site-reports-button">
                        {t("sites.reports")}
                    </Text>
                </ButtonTextStyled>
            </HoverGroupStyled>
            <HoverGroupStyled>
                <ButtonTextStyled
                    data-testid="site-tile-insights-button"
                    color="accent"
                    icon="Insights"
                    direction="row"
                    iconSize="M"
                    onClick={() => navigate(`/insights?selectedRegions=${props.site.id}`)}
                >
                    <Text bold data-testid="site-insights-button">
                        {t("sites.insights")}
                    </Text>
                </ButtonTextStyled>
            </HoverGroupStyled>
        </Stack>
    );

    return (
        <SiteTileContainerStyled data-testid="site-tile-container">
            {renderTitle()}
            {renderAddress()}
            {renderButtons()}
        </SiteTileContainerStyled>
    );
};
