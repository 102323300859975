import "mapbox-gl/dist/mapbox-gl.css";

import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import _debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from "!mapbox-gl";

import { useResizeObserver } from "../../../hooks/CommonHooks";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { StyledMap } from "./Map.styles";
import { LOCATIONS_SOURCE, mapConfig } from "./mapConfig";
import { formatSiteObjectToGeoJson, highlightMarkerHandler, registerMapHoverHandlers } from "./mapHelpers";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

type Props = {
    siteObjects: (ISiteObject & { incidentCount: number })[];
    initialPosition: {
        lng: number;
        lat: number;
        zoom: number;
    };
    isMapExpanded: boolean;
    handleMarkerClick: (siteIds: string[]) => void;
    activeSiteObjectIds?: string[];
    setVisibleMarkers?: (siteIds: string[]) => void;
    highlightedObject?: string;
    updateMapProgress?: (isLoading: boolean) => void;
};

export const MapComponent = ({
    activeSiteObjectIds,
    isMapExpanded,
    siteObjects,
    initialPosition,
    highlightedObject,
    handleMarkerClick,
    setVisibleMarkers,
    updateMapProgress,
}: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { styleUrl, styles, source, navigation, layers, images } = useMemo(() => mapConfig(isMobile), [isMobile]);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const hoveredCluster = useRef(null);
    const [lng, setLng] = useState(initialPosition.lng);
    const [lat, setLat] = useState(initialPosition.lat);
    const [zoom, setZoom] = useState(initialPosition.zoom);
    const geoJson = useMemo(() => formatSiteObjectToGeoJson(siteObjects), [siteObjects]);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: styleUrl,
            center: [lng, lat],
            zoom: zoom,
            projection: styles.projection,
        });
        map.current.boxZoom.disable();
        registerMapHoverHandlers(map.current, hoveredCluster.current, isMobile);
    });
    const [mapContainerWidth] = useResizeObserver(mapContainer.current);

    useEffect(() => {
        return () => {
            map.current = null;
        };
    }, []);

    const debouncedUpdateVisibleMarkers = useRef(
        _debounce(
            (updateVisibleMarkers, geoData) => {
                if (!map.current) {
                    return;
                }
                const bounds = map.current.getBounds();
                const visibleMarkers = geoData.filter((data) => bounds.contains(data.geometry.coordinates));

                updateVisibleMarkers(visibleMarkers.map((m) => m.properties.id));
            },
            1000,
            { leading: false },
        ),
    );

    const handleMapMove = useCallback(() => {
        if (map.current) {
            debouncedUpdateVisibleMarkers.current(setVisibleMarkers, geoJson);
            setLng(Number(map.current.getCenter().lng.toFixed(4)));
            setLat(Number(map.current.getCenter().lat.toFixed(4)));
            setZoom(Number(map.current.getZoom().toFixed(2)));
        }
    }, [geoJson]);

    useEffect(() => {
        map.current?.on("move", handleMapMove);
    }, [handleMapMove]);

    const navigationControl = useMemo(() => {
        return new mapboxgl.NavigationControl(navigation);
    }, [navigation]);

    useEffect(() => {
        if (!isMobile) {
            map.current?.addControl(navigationControl);
        } else {
            map.current?.removeControl(navigationControl);
        }
    }, [isMobile, navigationControl]);

    const updateSource = (geoJsonData) => {
        if (map.current?.getSource(LOCATIONS_SOURCE)) {
            const mapSource = map.current.getSource(LOCATIONS_SOURCE);
            mapSource.setData({
                type: "FeatureCollection",
                features: geoJsonData,
            });
        }
    };

    useEffect(() => {
        map.current?.on("load", () => {
            if (!map.current) {
                return;
            }
            if (!map.current.getSource(LOCATIONS_SOURCE)) {
                images.forEach((image) => {
                    map.current.addImage(image.name, image.el, image.style);
                });

                map.current.addSource(LOCATIONS_SOURCE, {
                    ...source,
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: geoJson,
                    },
                });
                Object.values(layers).forEach((layer) => {
                    if (!map.current.getLayer(layer.id)) {
                        map.current.addLayer(layer);
                    }
                });
            }
            updateSource(geoJson);
        });
        map.current.on("idle", () => {
            updateMapProgress(false);
        });
    }, [geoJson]);

    const updateBounds = (geoJson, boundsStyles) => {
        const bounds = new mapboxgl.LngLatBounds();
        geoJson.forEach((item) => {
            bounds.extend(item.geometry.coordinates);
        });
        map.current.fitBounds(bounds, { ...boundsStyles, center: bounds.getCenter(), offset: [0, -50] });
        updateMapProgress(true);
    };

    const highlightMarker = useCallback(
        (id: string) => {
            highlightMarkerHandler(map.current, id, isMobile);
        },
        [isMobile],
    );

    useEffect(() => {
        if (map.current.isStyleLoaded()) {
            highlightMarker(highlightedObject);
        }
    }, [highlightedObject, highlightMarker]);

    useEffect(() => {
        map.current?.on("click", "markers", (e) => {
            if (!activeSiteObjectIds.includes(e.features[0].properties.id)) {
                map.current.setLayoutProperty("markers", "icon-image", [
                    "match",
                    ["string", ["get", "id"]],
                    e.features[0].properties.id,
                    "activeSitePin",
                    "sitePin",
                ]);
                handleMarkerClick([e.features[0].properties.id]);
            } else {
                handleMarkerClick([]);
            }
        });
        map.current?.on("click", "clusters", (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
                layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            const locationsSource = map.current.getSource(LOCATIONS_SOURCE);
            locationsSource.getClusterExpansionZoom(clusterId, (err, clusterZoom) => {
                if (err) return;
                const currentZoom = Number(map.current.getZoom().toFixed(2));
                locationsSource.getClusterChildren(clusterId, function (error, clusterFeatures) {
                    if (error) return;
                    const bounds = new mapboxgl.LngLatBounds();
                    clusterFeatures.forEach((item) => {
                        bounds.extend(item.geometry.coordinates);
                    });
                    const centerCoords = [bounds.getCenter().lng, bounds.getCenter().lat];
                    map.current.easeTo({
                        center: centerCoords,
                        zoom: clusterZoom,
                        padding: { bottom: 100 },
                    });
                    if (currentZoom >= clusterZoom) {
                        map.current.setLayoutProperty("markers", "icon-image", "sitePin");
                        handleMarkerClick(clusterFeatures.map((f) => f.id));
                    }
                });
            });
        });
    }, [activeSiteObjectIds, handleMarkerClick]);

    useEffect(() => {
        if (!activeSiteObjectIds.length && map.current.getLayer("markers")) {
            map.current.setLayoutProperty("markers", "icon-image", "sitePin");
        }
    }, [activeSiteObjectIds]);

    useEffect(() => {
        map.current?.resize();
    }, [isMapExpanded, mapContainerWidth]);

    useEffect(() => {
        if (geoJson.length) {
            updateSource(geoJson);
            updateBounds(geoJson, styles.bounds);
        }
    }, [geoJson, styles.bounds]);

    return <StyledMap ref={mapContainer} />;
};
