export enum RequestStatus {
    undefined, //default, no action taken
    loading,
    success,
    invalid, //success but data failed preliminary validation
    error,
    skipped,
}

export const isFinishedUnsuccessful = (status: RequestStatus): boolean => {
    return status === RequestStatus.invalid || status === RequestStatus.error;
};

export const isFinished = (status: RequestStatus): boolean => {
    return status === RequestStatus.success || status === RequestStatus.invalid || status === RequestStatus.error || status === RequestStatus.skipped;
};
