import { HighlightsCategories } from "../models";

export const DAMAGED_CATEGORIES: HighlightsCategories[] = [
    {
        categoryLevel2: "cas_burglary",
        categoriesLevel3: ["cas_with_damage"],
    },
    {
        categoryLevel2: "cas_vandalism",
        categoriesLevel3: ["cas_equipment_damage", "cas_graffiti", "cas_property_damage", "cas_structural_damage", "cas_vehicle_vandalised"],
    },
    {
        categoryLevel2: "fac_appliances",
        categoriesLevel3: ["fac_appliances_malfunctioning", "fac_appliances_damaged"],
    },
    {
        categoryLevel2: "fac_doors_windows",
        categoriesLevel3: ["fac_doors_windows_malfunctioning", "fac_doors_windows_damaged"],
    },
    {
        categoryLevel2: "fac_electrical",
        categoriesLevel3: ["fac_electric_malfunctioning", "fac_power_failure"],
    },
    {
        categoryLevel2: "fac_elevator_stairs_escalators",
        categoriesLevel3: ["fac_elevator_stairs_escalators_malfunctioning", "fac_elevator_stairs_escalators_damaged"],
    },
    {
        categoryLevel2: "fac_gates_fences",
        categoriesLevel3: ["fac_gates_fences_damaged", "fac_gates_fences_malfunctioning"],
    },
    {
        categoryLevel2: "fac_heating_ventilation_air_condition",
        categoriesLevel3: ["fac_heating_ventilation_air_condition_malfunctioning", "fac_heating_ventilation_air_condition_damaged"],
    },
    {
        categoryLevel2: "fac_lighting",
        categoriesLevel3: ["fac_lighting_malfunctioning", "fac_lighting_damaged"],
    },
    {
        categoryLevel2: "fac_signage",
        categoriesLevel3: ["fac_signage_malfunctioning", "fac_signage_damaged", "fac_misslabeled", "fac_missing"],
    },
    {
        categoryLevel2: "fac_structures_buildings",
        categoriesLevel3: ["fac_structures_buildings_damaged"],
    },
    {
        categoryLevel2: "fac_water_and_sanitary",
        categoriesLevel3: [
            "fac_water_and_sanitary_malfunctioning",
            "fac_water_and_sanitary_damaged",
            "fac_clogged",
            "fac_flooding",
            "fac_frozen",
            "fac_leakage",
        ],
    },
    {
        categoryLevel2: "paa_asset_management",
        categoriesLevel3: ["paa_asset_management_damaged"],
    },
    {
        categoryLevel2: "paa_goods_and_products",
        categoriesLevel3: ["paa_goods_and_products_damaged"],
    },
    {
        categoryLevel2: "paa_vehicle_and_parking",
        categoriesLevel3: ["paa_malfunctioning", "paa_vehicle_and_parking_damaged"],
    },
];
