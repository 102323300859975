import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../components/shared/Loading";
import { useQueryString } from "../../../../hooks/CommonHooks";
import { NotificationType } from "../../../../models/NotificationModel";
import { ReportTemplateEnum } from "../../../../models/ReportModel";
import { PageNames } from "../../../../models/tracking/pageView";
import { useAuthorizedLocations } from "../../../../store/locations/LocationsHooks";
import { useSingleReport } from "../../../../store/reports/ReportsHooks";
import { RequestStatus } from "../../../../store/RequestStatus";
import { useTrackPageVisit } from "../../../../store/tracking/TrackingHooks";
import { NotFound } from "../../../Error/NotFound";
import Generic from "./Generic";
import { SingleReportWrapper } from "./SingleReportPage.styles";

export const SingleReportPage = () => {
    const params = useParams<{ [k: string]: string }>();
    const queryParams = useQueryString();
    const navigate = useNavigate();
    const { siteObjects, status } = useAuthorizedLocations();
    const hasAccessToSite: boolean = siteObjects.some((site) => site.id === params.locationId);
    const { report, siteObject, loading, error } = useSingleReport(params?.reportId, params?.locationId, queryParams["type"] === ReportTemplateEnum.patrolTour);
    useTrackPageVisit(PageNames.ReportDetails);

    const onClose = useCallback(() => {
        switch (queryParams?.source) {
            case NotificationType.CommentMention:
            case NotificationType.ReportShareRedirect:
            case NotificationType.TourSessionCommentMention:
            case NotificationType.TourSessionShareRedirect: {
                navigate("/reports");
                break;
            }
            default: {
                navigate("/insights");
                return;
            }
        }
    }, [queryParams, navigate]);

    if (!hasAccessToSite || status === RequestStatus.error || error) {
        return <NotFound />;
    }

    return (
        params?.reportId && (
            <SingleReportWrapper data-testid="single-report-view">
                {loading ? <Loading /> : <Generic report={report} siteObject={siteObject} onClose={onClose} />}
            </SingleReportWrapper>
        )
    );
};
