import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

//NOTE: This component serves a purpose of the chart wrapper used in few places, not only is a styled component;
// That's why it's extracted to separate file in the way it is. Some further refactor might be considered.

export const ChartWrapper = styled.div`
    display: flex;
    flex: row;
    flex-direction: column;
    width: 100%;
    min-height: 310px;
    margin-top: ${Spacing.XS}px;
    padding-top: ${Spacing.M}px;
    padding-bottom: ${Spacing.S}px;
    padding-left: ${Spacing.S}px;
    padding-right: ${Spacing.S}px;
    border-radius: ${Spacing.XXS}px;

    @media (max-width: ${Breakpoints.M}) {
        padding-left: ${Spacing.XS}px;
        padding-right: ${Spacing.M}px;
    }

    .recharts-tooltip-wrapper {
        outline: none;
    }

    .recharts-wrapper {
        .recharts-cartesian-grid-vertical {
            line {
                stroke: ${Palette.Navy600};
                stroke-dasharray: 3, 1;
            }

            line:nth-last-child(2),
            line:last-child {
                stroke-opacity: 0;
            }
        }

        .recharts-cartesian-grid-horizontal {
            line {
                stroke: ${Palette.Navy600};
                stroke-dasharray: 0;
            }

            line:nth-last-child(2),
            line:last-child {
                stroke-opacity: 0;
            }
        }
    }

    .recharts-legend-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: ${Spacing.S}px;
        padding-right: 0px;

        @media (max-width: ${Breakpoints.M}) {
            padding-top: ${Spacing.M}px;
            padding-bottom: 0px;
            padding-right: 0px;
            padding-left: 0px;
        }
    }
`;
