import { useMemo } from "react";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";

interface Category {
    key: string;
    level2Categories?: Category[];
    level3Categories?: Category[];
}

export const useBreadcrumbs = (categoryKey: string): string[] => {
    const { reportCategories } = useReportCategories();

    const search = (categories: Category[], breadcrumb: string[] = []): string[] | null => {
        for (const category of categories) {
            const newBreadcrumb = [...breadcrumb, category.key];
            if (category.key === categoryKey) {
                return newBreadcrumb;
            }
            if (category.level2Categories) {
                const result = search(category.level2Categories, newBreadcrumb);
                if (result) {
                    return result;
                }
            } else if (category.level3Categories) {
                const result = search(category.level3Categories, newBreadcrumb);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };

    return useMemo(() => search(reportCategories) || [], [reportCategories, categoryKey]);
};
