import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";

export const DragAndDropContext = createContext({
    editMode: false,
    isDisabled: false, // TODO: update after grid done
    toggleEditMode: () => undefined,
});

export const DragAndDropProvider = ({ children }: PropsWithChildren) => {
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = useCallback(() => {
        setEditMode(!editMode);
    }, [editMode]);

    const contextValue = useMemo(
        () => ({
            isDisabled: false,
            editMode,
            toggleEditMode,
        }),
        [editMode, toggleEditMode],
    );
    return <DragAndDropContext.Provider value={contextValue}>{children}</DragAndDropContext.Provider>;
};
