import { Title } from "@secuis/ccp-react-components";
import { ChartWrapper } from "src/components/Insights/ChartWrapper";
import styled from "styled-components";

const chartHeight = "256px";

export const TitleStyled = styled(Title)`
    white-space: nowrap;
`;

export const ChartWrapperStyled = styled(ChartWrapper)`
    padding: 0;
    height: ${chartHeight};
    min-height: ${chartHeight};
`;
