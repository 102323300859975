import { LoadingIndicator } from "@secuis/ccp-react-components";

import { TileLoaderStyled } from "./TileLoader.styled";

type Props = {
    width?: string;
    height: string;
};

export const TileLoader = (props: Props) => {
    return (
        <TileLoaderStyled $width={props.width} $height={props.height}>
            <LoadingIndicator size={"L"} />
        </TileLoaderStyled>
    );
};
