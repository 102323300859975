import { PersistedState } from "redux-persist";

import { ITokenDecoded } from "../../models/Token";
import { RequestStatus } from "../RequestStatus";

export const ACCESS_TOKEN_REQUEST = "@@auth/ACCESS_TOKEN_REQUEST";
export const ACCESS_TOKEN_SUCCESS = "@@auth/ACCESS_TOKEN_SUCCESS";
export const ACCESS_TOKEN_FAILURE = "@@auth/ACCESS_TOKEN_FAILURE";

export const ACCESS_TOKEN_REFRESH_REQUEST = "@@auth/ACCESS_TOKEN_REFRESH_REQUEST";
export const ACCESS_TOKEN_REFRESH_SUCCESS = "@@auth/ACCESS_TOKEN_REFRESH_SUCCESS";
export const ACCESS_TOKEN_REFRESH_FAILURE = "@@auth/ACCESS_TOKEN_REFRESH_FAILURE";

export const AUTH_PROMOTE_SESSION_TOKEN_REQUEST = "@@auth/AUTH_PROMOTE_SESSION_TOKEN_REQUEST";
export const AUTH_PROMOTE_SESSION_TOKEN_SUCCESS = "@@auth/AUTH_PROMOTE_SESSION_TOKEN_SUCCESS";
export const AUTH_PROMOTE_SESSION_TOKEN_FAILURE = "@@auth/AUTH_PROMOTE_SESSION_TOKEN_FAILURE";

export interface IAccessState extends PersistedState {
    accessToken: string;
    accessTokenDecoded: ITokenDecoded;
    status: RequestStatus;
    authTokenPromotedStatus?: RequestStatus;
    authTokenPromotionRequestedTimestamp?: number;
}
