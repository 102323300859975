import { Button, ButtonText, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import styled from "styled-components";

import { flexRowJustifySpaceBetweenAlignCenter } from "../../../styles/templates/Flex";

const Wrapper = styled.div`
    ${flexRowJustifySpaceBetweenAlignCenter}
    padding-top: ${Spacing.S}px;
    align-items: center;

    > :not(:last-child) {
        margin-right: ${Spacing.M}px;
    }
`;

const LabelText = styled(Text)`
    margin: 0 ${Spacing.XS}px;
`;

type FooterPropsType = {
    primaryButtonText: string;
    primaryButtonAction: () => void;
    label?: string;
    primaryButtonDisabled?: boolean;
    secondaryButtonText?: string;
    secondaryButtonDisabled?: boolean;
    secondaryButtonAction?: () => void;
};

const CommentsFooter = (props: FooterPropsType) => {
    const renderButton = (text: string, disabled: boolean, color, mode, action: () => void) => {
        if (!text) {
            return null;
        }
        return (
            <Button data-testid="comment-submit-button" fitContent disabled={disabled} color={color} mode={mode} onClick={action}>
                {text}
            </Button>
        );
    };

    const renderPrimaryButton = useCallback(() => {
        return renderButton(props.primaryButtonText, props.primaryButtonDisabled, "accent", "contained", props.primaryButtonAction);
    }, [props.primaryButtonText, props.primaryButtonDisabled, props.primaryButtonAction]);

    const renderSecondaryButton = useCallback(() => {
        if (!props.secondaryButtonText) {
            return null;
        }
        return (
            <ButtonText data-testid="cancel-comment-button" disabled={props.secondaryButtonDisabled} onClick={props.secondaryButtonAction}>
                {props.secondaryButtonText}
            </ButtonText>
        );
    }, [props.secondaryButtonText, props.secondaryButtonDisabled, props.secondaryButtonAction]);

    return (
        <Wrapper>
            {props.label && (
                <LabelText color="secondary" small>
                    {props.label}
                </LabelText>
            )}
            <Stack ml="auto" alignItems="center" gap="S">
                {renderSecondaryButton()}
                {renderPrimaryButton()}
            </Stack>
        </Wrapper>
    );
};

export default CommentsFooter;
