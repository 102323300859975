import { Box, Stack, Text } from "@secuis/ccp-react-components";
import { forwardRef } from "react";
import { CollapsableList } from "src/components/shared/CollapsableList/CollapsableList";

import { useSection } from "./Section.hooks";
import { RightHeaderPartStyled, WrapperStyled } from "./Section.styles";
import { SectionProps } from "./Section.types";

export const Section = forwardRef(
    (
        {
            label,
            children,
            className,
            collapsed,
            collapsedLabel,
            expandedLabel,
            maxHeight,
            onCollapse,
            onExpand,
            rightHeader,
            onResize,
            animationEnabled,
            listAttachment,
            ...restProps
        }: SectionProps,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const { height, contentWrapperRef } = useSection({ collapsed, maxHeight, onResize });

        return (
            <CollapsableList
                collapsable={!!maxHeight}
                collapsed={collapsed}
                collapsedLabel={collapsedLabel}
                expandedLabel={expandedLabel}
                onCollapse={onCollapse}
                onExpand={onExpand}
                attachment={listAttachment}
            >
                <Stack data-testid="template-section-label" flexWrap="wrap" mt={["M", "M", "S"]} direction="row" justifyContent="space-between" gap="S" mb="S">
                    <Text micro uppercase color="secondary">
                        {label}
                    </Text>
                    <RightHeaderPartStyled>{rightHeader}</RightHeaderPartStyled>
                </Stack>
                <WrapperStyled
                    {...restProps}
                    animationEnabled={animationEnabled}
                    collapsable={!!maxHeight}
                    elMaxHeight={height}
                    className={className}
                    ref={ref}
                >
                    <Box ref={contentWrapperRef}>{children}</Box>
                </WrapperStyled>
            </CollapsableList>
        );
    },
);

Section.displayName = "Section";
