import { get } from "lodash";
import { MutableRefObject, PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { Line } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import {
    TooltipPlacementContextType,
    TooltipPlacementProvider,
    useTooltipPlacementContext,
} from "src/pages/Insights/shared/components/CustomizedGraphTooltip/TooltipPlacementProvider";

import { createContext } from "../../../../../helpers/createContext";

type LineChartTooltipPlacementContextType = Pick<TooltipPlacementContextType, "tooltipPosition"> & {
    chartRef: MutableRefObject<Line & SVGPathElement>;
    recalculateTooltipPlacement: (chartState: CategoricalChartState) => void;
};

const [LineChartTooltipPlacement, useLineChartTooltipPlacement] = createContext<LineChartTooltipPlacementContextType>({
    defaultValue: {
        chartRef: null,
        recalculateTooltipPlacement: () => {},
        tooltipPosition: null,
    },
    providerName: "LineChartTooltipPlacement",
});

export const LineChartTooltipPlacementProvider = ({ children }: PropsWithChildren) => {
    return (
        <TooltipPlacementProvider>
            <Wrapper>{children}</Wrapper>
        </TooltipPlacementProvider>
    );
};

const Wrapper = ({ children }: PropsWithChildren) => {
    const tooltipPlacementUtils = useTooltipPlacementContext();
    const chartRef = useRef<Line & SVGPathElement>(null);

    const recalculateTooltipPlacement = useCallback(
        // NOTE: Handle tooltip placement for multiline chart
        (chartState: CategoricalChartState) => {
            const { tooltipHeight, tooltipWidth, setTooltipPosition, setPlacementSide } = tooltipPlacementUtils;
            const OFFSET = 16;
            const activeIndex = chartState.activeTooltipIndex;
            const allPoints = get(chartRef, "current.props.points") ?? [];

            const { width, left } = chartRef.current.props ?? {};
            const maxX = width + left;

            const activePoint = allPoints[activeIndex];

            if (activePoint) {
                const isOverRightBound = activePoint.x + tooltipWidth / 2 > maxX;
                const isBelowLeftBound = activePoint.x - tooltipWidth / 2 < 0;

                let positionX = 0;
                let positionY = 0;

                if (isOverRightBound) {
                    setPlacementSide("left");
                    positionX = activePoint.x - tooltipWidth - OFFSET;
                    positionY = activePoint.y - tooltipHeight / 2;
                } else if (isBelowLeftBound) {
                    setPlacementSide("right");
                    positionX = activePoint.x + OFFSET;
                    positionY = activePoint.y - tooltipHeight / 2;
                } else {
                    setPlacementSide("top");
                    positionX = activePoint.x - tooltipWidth / 2;
                    positionY = activePoint.y - tooltipHeight - OFFSET;
                }

                setTooltipPosition({
                    x: positionX,
                    y: positionY,
                });
            } else {
                setTooltipPosition(null);
            }
        },
        [chartRef, tooltipPlacementUtils],
    );
    const providerValue: LineChartTooltipPlacementContextType = useMemo(
        () => ({
            chartRef,
            recalculateTooltipPlacement,
            ...tooltipPlacementUtils,
        }),
        [tooltipPlacementUtils, chartRef, recalculateTooltipPlacement],
    );

    return <LineChartTooltipPlacement.Provider value={providerValue}>{children}</LineChartTooltipPlacement.Provider>;
};
export { useLineChartTooltipPlacement };
