import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../styles/templates/Flex";

export const DropdownListHeader = styled.div`
    ${flexColumn}
    max-height: calc(min(240px, 20vh));
    padding-top: ${Spacing.S}px;
    padding-left: ${Spacing.S}px;
    padding-bottom: ${Spacing.XS}px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

    :empty {
        margin-bottom: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        max-height: calc(min(300px, 30vh));
    }
`;

export const SelectedItemsHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${Spacing.XS}px;
    overflow-y: auto;
    scrollbar-color: ${Palette.Navy500} transparent;

    @media (max-width: ${Breakpoints.XS}) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;
