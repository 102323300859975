import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../../styles/templates/Flex";

export const ReportListDetailsContainer = styled.section<{ flex: string }>`
    ${flexColumn};
    flex: ${(props) => props.flex};
    transition: flex-grow 500ms ease-in-out;
    overflow-y: auto;
    background-color: ${Palette.Navy750};

    @media (max-width: ${Breakpoints.S}) {
        flex: ${(props) => props.flex};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.S}) {
        margin-left: ${Spacing.M}px;
        width: calc(100vw - ${Spacing.XXXL + Spacing.M}px);
    }
`;
