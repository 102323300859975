import { Stack } from "@secuis/ccp-react-components";

import { TranslatableEmptyInboxComponent } from "../../../components/shared/NoContent/EmptyInboxContent";
import { useFilteredSites } from "../../../store/insights/FilterHooks";
import { CompareLocations } from "./CompareLocations/CompareLocations";
import { ExceptionTrendsTile } from "./ExceptionTrends/ExceptionTrendsTile";
import { PatrolDeviations } from "./PatrolDeviations/PatrolDeviations";
import { usePatrolTasks } from "./PatrolTasks.hooks";
import { TotalPerformanceSection } from "./TotalPerformance/TotalPerformanceSection";

export const PatrolTasks = () => {
    const { siteIds } = useFilteredSites();
    const {
        isEmptyPage,
        isLoadingPage,
        exceptionTrends: { exceptions, previousExceptions, ...otherExceptionTrendsProps },
        patrolDeviations,
        tourSessionsSummaryByLocation,
        tourSessionsSummary: { toursCount, exceptionsCount, ...otherTourSessionsProps },
    } = usePatrolTasks(siteIds);

    if (!isLoadingPage && isEmptyPage) {
        return <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} />;
    }

    return (
        <Stack gap="S" direction="column">
            <TotalPerformanceSection exceptionsCount={exceptionsCount} isLoading={isLoadingPage} toursCount={toursCount} {...otherTourSessionsProps} />
            <CompareLocations
                isEmpty={!toursCount && !exceptionsCount && !otherTourSessionsProps.historicalExceptionsCount && !otherTourSessionsProps.historicalToursCount}
                tourSessionsSummaryByLocation={tourSessionsSummaryByLocation}
                isLoading={isLoadingPage || !tourSessionsSummaryByLocation}
            />
            <PatrolDeviations patrolDeviations={patrolDeviations} isLoading={isLoadingPage} siteIds={siteIds} />
            <ExceptionTrendsTile previousExceptions={previousExceptions} exceptions={exceptions} {...otherExceptionTrendsProps} />
        </Stack>
    );
};
