import { useTranslation } from "react-i18next";

import { Column } from "../../../../components/shared/Table/Table";
import { TourHistoricalCompareSummary } from "../../../../store/insights/Tours/ToursTypes";
import { deviationStyles, StyledTable, tourNameCellStyles, tourNameHeaderStyles } from "./ToursTable.styles";
import { CheckpointsCell, CompletedCell, DeviationsCell, TourNameCell, ToursDataHeader } from "./ToursTableCells";

type Props = {
    tours: TourHistoricalCompareSummary[];
};

export const ToursTable = ({ tours }: Props) => {
    const { t } = useTranslation();

    const columns: Column[] = [
        {
            id: "name",
            key: "name",
            header: t("insights.toursTable.header.name"),
            Cell: TourNameCell,
            headerStyle: tourNameHeaderStyles,
            style: tourNameCellStyles,
        },
        {
            id: "completed",
            key: "completed",
            header: ToursDataHeader("completed"),
            Cell: CompletedCell,
        },
        {
            id: "deviations",
            key: "deviations",
            header: ToursDataHeader("deviations"),
            Cell: DeviationsCell,
            headerStyle: deviationStyles,
            style: deviationStyles,
        },
        {
            id: "checkpoints",
            key: "checkpoints",
            header: ToursDataHeader("checkpoints"),
            Cell: CheckpointsCell,
        },
    ];
    return <StyledTable data={tours} columns={columns} />;
};
