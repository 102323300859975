export const REPORT_IMAGES = {
    cas_abduction_confirmed: "CAS-001-001",
    cas_abduction_suspected: "CAS-001-002",
    cas_domestic_violence: "CAS-002-003",
    cas_verbal: "CAS-002-004",
    cas_attempted: "CAS-003-006",
    cas_with_damage: "CAS-003-007",
    cas_without_damage: "CAS-003-008",
    cas_chemical_biological_attack: "CAS-004-009",
    cas_shooting: "CAS-004-010",
    cas_intoxication: "CAS-005-013",
    cas_discrimination: "CAS-006-014",
    cas_sexual_harassment: "CAS-006-015",
    cas_bribery: "CAS-008-018",
    cas_illegal_disposal_of_waste: "CAS-008-019",
    cas_armed_assault_melee_weapon: "CAS-009-022",
    cas_armed_assault_with_firearm: "CAS-009-023",
    cas_armed_robbery: "CAS-010-031",
    cas_attempted_armed_robbery: "CAS-010-033",
    cas_attempted_shoplifting: "CAS-011-036",
    cas_blackmailing: "CAS-012-041",
    cas_bomb_threat: "CAS-012-042",
    cas_attempted_unauthorized_access: "CAS-013-049",
    cas_soliciting: "CAS-013-050",
    cas_loitering: "CAS-014-052",
    cas_radical_behaviour: "CAS-014-053",
    cas_suspicious_behavior: "CAS-014-054",
    cas_attempted_graffiti: "CAS-015-056",
    cas_attempted_equipment_damage: "CAS-015-057",
    fac_access_control_systems_breached: "FAC-016-065",
    fac_access_control_systems_error_fault_alert: "FAC-016-066",
    fac_appliances_damaged: "FAC-017-069",
    fac_appliances_malfunctioning: "FAC-017-069",
    fac_doors_windows_damaged: "FAC-018-072",
    fac_doors_windows_malfunctioning: "FAC-018-072",
    fac_doors_windows_obstructed: "FAC-018-073",
    fac_doors_windows_found_closed: "FAC-018-077",
    fac_doors_windows_found_opened: "FAC-018-078",
    fac_electric_malfunctioning: "FAC-019-079",
    fac_power_failure: "FAC-019-080",
    fac_elevator_stairs_escalators_damaged: "FAC-020-081",
    fac_fire_systems_breached: "FAC-021-087",
    fac_fire_systems_error_fault_alert: "FAC-021-088",
    fac_gates_fences_damaged: "FAC-022-091",
    fac_gates_fences_malfunctioning: "FAC-022-092",
    fac_heating_ventilation_air_condition_damaged: "FAC-023-099",
    fac_heating_ventilation_air_condition_malfunctioning: "FAC-023-100",
    fac_lighting_damaged: "FAC-024-105",
    fac_lighting_malfunctioning: "FAC-024-106",
    fac_lighting_found_on: "FAC-024-108",
    fac_security_systems_armed: "FAC-026-110",
    fac_signage_damaged: "FAC-027-116",
    fac_signage_malfunctioning: "FAC-027-117",
    fac_structures_buildings_damaged: "FAC-028-121",
    fac_water_and_sanitary_damaged: "FAC-029-123",
    has_accident_contact_heat_chemical_electricity: "HAS-030-128",
    has_explosion: "HAS-031-136",
    ise_guard_uniform_damaged: "ISE-040-186",
    ise_guard_uniform_lost_missing: "ISE-040-187",
    ise_guard_uniform_stolen: "ISE-040-188",
    ise_improper_use: "ISE-041-189",
    ise_incomplete_asset_equipment: "ISE-041-190",
    ise_incomplete_uniform: "ISE-041-191",
    ise_violation: "ISE-041-192",
    ise_internal_inspections_audits_disciplinary: "ISE-041-193",
    ise_securitas_guard_assets_equipment_damaged: "ISE-042-194",
    ise_lost_missing: "ISE-042-195",
    ise_malfunctioning: "ISE-042-196",
    ise_stolen: "ISE-042-197",
    ise_authorities_on_site: "ISE-042-198",
    paa_access_denied: "PAS-043-198",
    paa_badge_violation: "PAS-043-199",
    paa_forbidden_item: "PAS-043-200",
    paa_access_control_ticket_violation: "PAS-043-201",
    paa_unauthorized_access: "PAS-043-202",
    paa_access_control_visitation_policy_violation: "PAS-043-203",
    paa_access_control_other: "PAS-043-999",
    paa_asset_management_damaged: "PAS-044-204",
    paa_intellectual_property_at_risk: "PAS-044-205",
    paa_asset_management_missing: "PAS-044-206",
    paa_not_returned: "PAS-044-207",
    paa_unsecured: "PAS-044-208",
    paa_asset_management_other: "PAS-044-999",
    paa_communication_complaint: "PAS-045-209",
    paa_dispatched_incident_unknown: "PAS-046-210",
    paa_dispatched_no_incident: "PAS-046-211",
    paa_emergency_services_triggered: "PAS-047-212",
    paa_goods_and_products_abandoned: "PAS-048-213",
    paa_broken_missing_seal: "PAS-048-214",
    paa_goods_and_products_damaged: "PAS-048-215",
    paa_delayed_delivery: "PAS-048-216",
    paa_denied_delivery: "PAS-048-217",
    paa_improper_handling: "PAS-048-218",
    paa_incomplete_documentation: "PAS-048-219",
    paa_goods_and_products_missing: "PAS-048-220",
    paa_suspicious: "PAS-048-221",
    paa_temperature_deviation: "PAS-048-222",
    paa_goods_and_products_other: "PAS-048-999",
    paa_littering: "PAS-049-223",
    paa_found_property: "PAS-050-224",
    paa_lost_property: "PAS-050-225",
    paa_other_people_and_assets_media_presence: "PAS-051-226",
    paa_security_assistance_call_for_assistance: "PAS-052-227",
    paa_vehicle_and_parking_abandoned: "PAS-053-228",
    paa_break_down: "PAS-053-229",
    paa_vehicle_and_parking_damaged: "PAS-053-230",
    paa_locked_out: "PAS-053-231",
    paa_malfunctioning: "PAS-053-232",
    paa_not_compliant: "PAS-053-233",
    paa_vehicle_and_parking_on_site_after_Hours: "PAS-053-234",
    paa_parking_violation: "PAS-053-235",
    paa_speeding_violation: "PAS-053-236",
    paa_vehicle_and_parking_stop_sign_violation: "PAS-053-237",
    paa_vehicle_and_parking_ticket_violation: "PAS-053-238",
    paa_unauthorized: "PAS-053-239",
    paa_vehicle_and_parking_unsafe_reckless_driving: "PAS-053-240",
    paa_vehicle_and_parking_hit_and_run: "PAS-053-241",
    paa_vehicle_alarm: "PAS-053-242",
    paa_vehicle_and_parking_other: "PAS-053-999",
};
