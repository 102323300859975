import { Icon, Separator, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { contactInfoByCountry } from "src/data/contacts";

import { ReactComponent as PostalAddressIcon } from "../../../../assets/icons/postal_address.svg";
import { DefaultItemFieldWrapper } from "../../DefaultItemField";
import { PostalAddressIconWrapper, SeparatorStyled } from "./ContactInfo.styles";

type Props = {
    countryCode: string;
};

export const ContactInfo = ({ countryCode = "" }: Props) => {
    const { t } = useTranslation();
    const contactInfo = contactInfoByCountry[countryCode?.toLowerCase()];

    if (!contactInfo) {
        return null;
    }

    return (
        <>
            <Stack data-testid="contact-info-section-label" flexWrap="wrap" mt="XS" direction="row" justifyContent="space-between" gap="S" mb="S">
                <Text micro uppercase color="secondary">
                    {t("reportdetails.contactInfo.header")}
                </Text>
            </Stack>
            <DefaultItemFieldWrapper>
                <Stack marginBottom="XS" justifyContent="space-between" alignItems="center">
                    <Text small bold>
                        {contactInfo.companyName}
                    </Text>
                    <Text color="neutral" micro>
                        {t("reportdetails.contactInfo.businessID")}: {contactInfo.businessId}
                    </Text>
                </Stack>
                <Separator />
                <Stack marginTop="XS" gap="XS" alignItems="center" flexWrap="wrap">
                    <Stack gap="XS" alignItems="center">
                        <Icon size="S" variant="Location" />
                        <Text micro bold>
                            {contactInfo.address}
                        </Text>
                    </Stack>
                    <SeparatorStyled variant="vertical" marginHorizontal="XS" />
                    <Stack gap="XS" alignItems="center">
                        <Icon size="S" variant="Phone" />
                        <Text micro bold>
                            {contactInfo.phone}
                        </Text>
                    </Stack>
                    <SeparatorStyled variant="vertical" marginHorizontal="XS" />
                    <Stack gap="XS" alignItems="center">
                        <PostalAddressIconWrapper>
                            <PostalAddressIcon />
                        </PostalAddressIconWrapper>
                        <Text micro bold>
                            {contactInfo.postalAddress}
                        </Text>
                    </Stack>
                </Stack>
            </DefaultItemFieldWrapper>
        </>
    );
};
