import { Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { formatDateTime, formatDateTimeWithTimezone } from "src/helpers/date";
import { DynamicReportType } from "src/models/ReportModel";
import { ISiteObject } from "src/models/SiteObjectModel";
import styled from "styled-components";

import { featureFlags } from "../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../hooks/featureFlags";
import { ViewersSummary } from "../ViewersSummary";
import { ReportedBy } from "./ReportedBy";

type Props = {
    siteObject: ISiteObject;
    report: DynamicReportType;
    isShared?: boolean;
};

const StackStyled = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

export const ReportDetailsHeaderLine1 = ({ report, siteObject, isShared }: Props) => {
    const { t } = useTranslation();
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    return (
        <StackStyled direction="row">
            <Stack gap="XXXS">
                {areSitesTimezonesEnabled && (
                    <Text micro color="secondary">
                        {t("reportlist.yourTime")}:
                    </Text>
                )}
                <Text data-testid="detail-view-date-container" color="secondary" micro>
                    {formatDateTime(report.reportDateTime)}
                </Text>
            </Stack>
            <Separator variant="vertical" mh="S" />

            {areSitesTimezonesEnabled && (
                <>
                    <Stack gap="XXXS">
                        <Text micro color="secondary">
                            {t("reportlist.siteTime")}:
                        </Text>
                        <Text data-testid="detail-view-site-date-container" color="secondary" micro>
                            {formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone)}
                        </Text>
                    </Stack>
                    <Separator variant="vertical" mh="S" />
                </>
            )}

            {!isShared && <ReportedBy reportedBy={report.reportedBy} countryCode={siteObject.countryCode} />}
            <ViewersSummary visitorIds={report.visitorIds} visitorsCount={report.visitorsCount} isShared={isShared} />
        </StackStyled>
    );
};
