import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SymbolsUsedTooltipContent } from "../SymbolsUsedTooltipContent";

type Props = {
    hasSingleSite?: boolean;
};

export const TooltipContent = ({ hasSingleSite }: Props) => {
    const { t } = useTranslation();
    const content = (hasSingleSite ? t("insights.siteTasks.tooltip.totalSectionTextSingleSite") : t("insights.siteTasks.tooltip.totalSectionText"))
        .split("<br>")
        .filter(Boolean);

    return (
        <Stack direction="column" gap="XS">
            {content.map((text) => (
                <span key={text.slice(0, 5)}>{text}</span>
            ))}
            <SymbolsUsedTooltipContent />
        </Stack>
    );
};
