import { Badge, SignalColor, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { TaskScheduleStatus } from "src/store/schedule/types";

type Props = {
    cell: TaskScheduleStatus;
};

const colorMap: { [k in TaskScheduleStatus]: SignalColor } = {
    [TaskScheduleStatus.cancelled]: "archived",
    [TaskScheduleStatus.completed]: "success",
    [TaskScheduleStatus.inProgress]: "neutral",
    [TaskScheduleStatus.missed]: "high",
    [TaskScheduleStatus.scheduled]: "neutral",
};

export const StatusCell = ({ cell }: Props) => {
    const { t } = useTranslation();

    if (!cell) {
        return <Text small>-</Text>;
    }
    const mode: "outlined" | "contained" = [TaskScheduleStatus.cancelled, TaskScheduleStatus.scheduled].includes(cell) ? "outlined" : "contained";

    return <Badge uppercase label={t(`schedule.taskScheduleStatus.${cell}`)} color={colorMap[cell]} mode={mode} />;
};
