import { Box, Breakpoints, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const IncidentColorScaleStyled = styled.div`
    width: 200px;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
    }
`;

export const ColorScaleStyled = styled(Box)`
    display: block;
    background: linear-gradient(
        to right,
        ${Palette.Purple100},
        ${Palette.Purple200},
        ${Palette.Purple300},
        ${Palette.Purple400},
        ${Palette.Purple500},
        ${Palette.Purple600} 95%
    );
    width: 100%;
    height: 12px;
`;
