import { Breakpoints, IconType, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import * as DOMPurify from "dompurify";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDateTime } from "src/helpers/date";
import styled from "styled-components";

import { extractCommentParts } from "../../../helpers/CommentHelper";
import { getUserLabel, IUser } from "../../../models/UserModel";
import { ICommentModel, IUpsertCommentDto } from "../../../store/reportComments/models";
import { RequestStatus } from "../../../store/RequestStatus";
import UserSelectors from "../../../store/user/UserSelectors";
import { actionText, mediumText, smallText } from "../../../styles/templates/Typography";
import ContextMenu from "../../shared/ContextMenu/ContextMenu";
import { EditCommentForm } from "./CommentForms";

const CommentPartStyled = styled.span<{ isMention?: boolean }>`
    word-break: break-word;
    ${(props) => props.isMention && `font-weight: bold; color: ${Palette.Purple400}`};
`;

export interface ICommentContentPart {
    isMention: boolean;
    text: string;
    fitsInShort: boolean;
}

const CommentContainerStyled = styled.span`
    ${mediumText}
    white-space: pre-wrap;

    @media (max-width: ${Breakpoints.XS}) {
        ${smallText};
    }
`;

interface IReadMoreCommentProps {
    comment: ICommentModel;
    records: IUser[];
    allowedLength?: number;
}

const ReadMoreComment = ({ comment, records, allowedLength = 300 }: IReadMoreCommentProps) => {
    const { t } = useTranslation();
    const [showFull, setShowFull] = useState(false);

    const textPartsToRender = useMemo(() => {
        return extractCommentParts(comment.content, comment.mentionedUserIds, records ?? [], allowedLength, t("common.deletedUser"));
    }, [allowedLength, comment.content, comment.mentionedUserIds, records, t]);
    const isTooLong = textPartsToRender.some((e) => !e.fitsInShort);

    return (
        <CommentContainerStyled>
            {textPartsToRender.map((part, index) => {
                return (
                    (showFull || part.fitsInShort) && (
                        <CommentPartStyled key={index} isMention={part.isMention} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part.text) }} />
                    )
                );
            })}

            {!!isTooLong && (
                <>
                    {showFull ? " " : "... "}
                    <ActionTextStyled onClick={() => setShowFull((prev) => !prev)}>
                        {showFull ? t("report.comments.showLessComment") : t("report.comments.readMoreComment")}
                    </ActionTextStyled>
                </>
            )}
        </CommentContainerStyled>
    );
};

type CommentListItemPropsType = {
    active: boolean;
    comment: ICommentModel;
    onEdit: (body: IUpsertCommentDto) => void;
    onEditStart: () => void;
    onEditCancel: () => void;
    onDelete: () => void;
    usersToMention: IUser[];
    allUsers: IUser[];
};

const CommentWrapperStyled = styled(Stack)`
    flex: 1;
    flex-direction: column;
    margin-top: ${Spacing.XS}px;

    :first-of-type {
        margin-top: 0;
    }
`;

const ActionTextStyled = styled.span`
    ${actionText}
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    color: ${Palette.Purple300};
`;

const CommentListItem = ({ active, comment, onEditStart, onEditCancel, onEdit, onDelete, usersToMention, allUsers }: CommentListItemPropsType) => {
    const { t } = useTranslation();

    const creationDate = formatDateTime(comment.createdTime);
    const currentUserId = useSelector(UserSelectors.getUserInfo)?.info?.id;

    const [showEditForm, setShowEditForm] = useState(active);

    useEffect(() => {
        setShowEditForm(active);
    }, [active]);

    const cancelEdit = () => {
        setShowEditForm(false);
        onEditCancel();
    };
    const startEdit = () => {
        setShowEditForm(true);
        onEditStart();
    };

    const createdByUser = allUsers.find((c) => c.id === comment.creatorId);
    const createdByLabel = (createdByUser && getUserLabel(createdByUser)) ?? comment.creatorId;
    const isCreatedByCurrentUser = comment.creatorId === currentUserId;
    const wasEdited = comment.lastModifiedTime && comment.lastModifiedTime !== comment.createdTime;
    const subtitle = `${creationDate} ${wasEdited ? "(" + t("report.comments.editedLabel") + ")" : ""}`;
    const contextOptions = [
        {
            label: t("report.comments.editActionLabel"),
            iconVariant: "Edit" as IconType,
            onClick: startEdit,
            dataTestId: "edit-button-comment",
        },
        {
            label: t("report.comments.deleteActionLabel"),
            iconVariant: "Delete" as IconType,
            onClick: onDelete,
            dataTestId: "delete-button-comment",
        },
    ];

    useEffect(() => {
        if (comment.updateStatus === RequestStatus.success) {
            setShowEditForm(false);
        }
    }, [comment.updateStatus]);

    return (
        <CommentWrapperStyled data-testid="comment-item">
            <Stack justifyContent="space-between" alignItems="center">
                <Stack flexWrap="wrap">
                    <Text bold small>
                        {createdByLabel}
                    </Text>
                    <Stack flexWrap="wrap">
                        <Text small>&nbsp;•&nbsp;</Text>
                        <Text small color="secondary">
                            {subtitle}
                        </Text>
                    </Stack>
                </Stack>
                {isCreatedByCurrentUser && !showEditForm && <ContextMenu label={t("report.comments.editActionLabel")} options={contextOptions} />}
            </Stack>
            {showEditForm ? (
                <EditCommentForm onPost={onEdit} onCancel={cancelEdit} comment={comment} usersToMention={usersToMention} />
            ) : (
                <ReadMoreComment comment={comment} records={allUsers} />
            )}
        </CommentWrapperStyled>
    );
};

export default CommentListItem;
