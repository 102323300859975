import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { SiteInspectionFields } from "../../../../models/reportTemplates/SiteInspection";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { ImageSummaryFieldPdf, TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { getPdfImagesSection } from "./imageConfigPdf";

export const getSiteInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    conditionalRender(getFieldValueByFieldName(SiteInspectionFields.observationsByTheInspector, report), () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.siteInspection.inspectorObservations",
            fullyTranslated: true,
            marginTop: "S",
        },
        children: [
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.observationsByTheInspector, report),
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    conditionalRender(getFieldValueByFieldName(SiteInspectionFields.actionsTaken, report), () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.siteInspection.actionTaken",
            fullyTranslated: true,
            marginTop: "S",
        },
        children: [
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.actionsTaken, report),
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    conditionalRender(images.length > 0, () => getPdfImagesSection("reportdetails.pictures", images)),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.siteInspection.summary.title",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.officersUniformsAndAppearance",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.officersUniformsAndAppearance, report),
                    isRequired: true,
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.regulatoryCompliant",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.regulatoryCompliant, report),
                    isRequired: true,
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.postOrderAcknowledgment",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.postOrderAcknowledgment, report),
                    isRequired: true,
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.postOrdersLastRevisionDate",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.postOrdersLastRevisionDate, report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.inspectionDateTime",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.inspectionDate, report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: ImageSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.SiteInspectionFields.officersSignature",
                    fieldValue: images?.find((i) => i.reportFieldName === SiteInspectionFields.officerSignature),
                    isRequired: true,
                },
            },
            {
                component: ImageSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.SiteInspectionFields.inspectorsSignature",
                    fieldValue: images?.find((i) => i.reportFieldName === SiteInspectionFields.inspectorSignature),
                    isRequired: true,
                },
            },
            ...getPdfUnknownFields(report, Object.values(SiteInspectionFields), images),
        ],
    },
];
