export enum TourMultiExceptionFields {
    comments = "comments",
    checkpoint = "checkpoint",
    exceptionQuestions = "exceptionQuestions",
    tourName = "tourName",
    tourSessionID = "tourSessionID",
    checkpointID = "checkpointID",
    trueExceptionQuestions = "trueExceptionQuestions",
    questionsAndAnswers = "questionsAndAnswers",
}
