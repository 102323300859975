import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SummaryGridLayoutStyled = styled.div`
    width: 100%;
    display: grid;
    gap: ${Spacing.S}px;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-template-rows: auto;

    @media (min-width: ${Breakpoints.XL}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${Breakpoints.XS}) {
        display: flex;
        flex-direction: column;
    }
`;

export const SummaryGridStyled = styled(SummaryGridLayoutStyled)``;
