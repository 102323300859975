import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../models/ReportModel";
import { RequestStatus } from "../RequestStatus";
import { IState } from "./../../Store";
import CommentActions from "./CommentActions";
import CommentSelectors from "./CommentSelectors";
import { APP_ID, ICommentModel, IUpsertCommentDto } from "./models";

export const useReportComments = (report: DynamicReportType | PatrolTourType) => {
    const dispatch = useDispatch();

    const selector = useMemo(() => CommentSelectors.makeGetReportComments(), []);
    const reportComments = useSelector((state) => selector(state as IState, null, report.id));

    const isTourReport = useMemo(() => {
        return isPatrolTour(report);
    }, [report]);

    const deleteComment = useCallback(
        (comment: ICommentModel) => {
            const deleteBody = {
                id: comment.id,
                creatorId: comment.creatorId,
                appId: APP_ID,
            };
            dispatch(CommentActions.deleteComment(report.locationId, report.id, deleteBody, isTourReport));
        },
        [report, dispatch, isTourReport],
    );

    const fetchComments = useCallback(() => {
        dispatch(CommentActions.getReportComments(report.locationId, report.id, isTourReport));
    }, [dispatch, report, isTourReport]);

    const createComment = useCallback(
        (body: IUpsertCommentDto) => {
            dispatch(CommentActions.upsertComment(report.locationId, report.id, body, isTourReport));
        },
        [dispatch, report, isTourReport],
    );

    const editComment = useCallback(
        (body: IUpsertCommentDto) => {
            dispatch(CommentActions.upsertComment(report.locationId, report.id, body, isTourReport));
        },
        [dispatch, report, isTourReport],
    );

    useEffect(() => {
        if (report.commentsCount > 0) {
            if (reportComments.fetchStatus !== RequestStatus.loading && (!reportComments || reportComments.fetchStatus === RequestStatus.undefined)) {
                fetchComments();
            }
        }
    }, [fetchComments, report, reportComments]);

    return { commentState: reportComments, fetchComments, deleteComment, editComment, createComment };
};
