import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapReportCategories = ({ reportCategories }: IState) => reportCategories;
const mapLevel3Categories = ({ reportCategories }: IState) =>
    reportCategories.reportCategoriesLevel1.flatMap((level1) => level1.level2Categories.flatMap((level2) => level2.level3Categories));

const getReportCategories = createSelector([mapReportCategories], (reportCategories) => reportCategories);
const getPreventedCategories = createSelector([mapLevel3Categories], (level3) => {
    return level3.filter((cat) => cat.key.includes("attempted")).map((cat) => cat.key);
});

export default {
    getPreventedCategories,
    getReportCategories,
};
