import { Breakpoints, LoadingIndicator, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DynamicReportType, PatrolTourType } from "../../../models/ReportModel";
import { APP_ROLES } from "../../../models/RoleModel";
import { useReportComments } from "../../../store/reportComments/CommentHooks";
import { IUpsertCommentDto } from "../../../store/reportComments/models";
import { RequestStatus } from "../../../store/RequestStatus";
import { useUsersOfLocation } from "../../../store/siteObjects/SiteObjectsHooks";
import { flexColumn } from "../../../styles/templates/Flex";
import { Section } from "../../shared/Section/Section";
import { CreateCommentForm } from "./CommentForms";
import CommentList from "./CommentList";

const CommentsContainer = styled(Stack)<{ isSinglePageReport: boolean }>`
    flex-direction: column;
    flex-grow: 0;
    padding: 0 40px;
    position: relative;

    @media (min-width: ${Breakpoints.S}) and (max-width: ${Breakpoints.M}) {
        padding: ${(props) => (props.isSinglePageReport ? `0 ${Spacing.M}px` : `0 ${Spacing.S}px`)};
    }

    @media (max-width: ${Breakpoints.S}) {
        padding: 0 ${Spacing.L}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: 0 ${Spacing.S}px;
    }
`;

const ContentContainer = styled.div`
    @media (max-width: ${Breakpoints.XS}) {
        ${flexColumn}
        flex-grow: 1;
    }
`;

const CreateCommentContainer = styled.div`
    z-index: 1;
    background: ${Palette.Navy750};
`;

interface ICommentsProps {
    report: DynamicReportType | PatrolTourType;
    reportUrl: string;
    isSinglePageReport?: boolean;
}

const Comments = forwardRef(({ report, reportUrl, isSinglePageReport }: ICommentsProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    const { commentState, deleteComment, createComment, editComment } = useReportComments(report);
    const { users, fetchStatus } = useUsersOfLocation(report.locationId, APP_ROLES, true);
    const [activeEditor, setActiveEditor] = useState(null);

    const cancelEditor = useCallback(() => {
        setActiveEditor(null);
    }, []);

    const addActiveEditor = useCallback((commentId: string) => {
        setActiveEditor(commentId);
    }, []);

    const onEdit = useCallback(
        (body: IUpsertCommentDto) => {
            cancelEditor();
            editComment(body);
        },
        [cancelEditor, editComment],
    );

    return (
        <CommentsContainer data-testid="comment-container" isSinglePageReport={isSinglePageReport}>
            <Section label={t("report.comments.title")} className="comments-container">
                <ContentContainer ref={ref}>
                    {commentState.fetchStatus === RequestStatus.loading || fetchStatus === RequestStatus.loading ? (
                        <LoadingIndicator size="M" />
                    ) : (
                        <>
                            <CommentList
                                activeEditorId={activeEditor}
                                comments={commentState?.comments}
                                onEdit={onEdit}
                                onEditStart={addActiveEditor}
                                onEditCancel={cancelEditor}
                                onDelete={deleteComment}
                                usersToMention={users}
                            />
                            <CreateCommentContainer data-testid="create-comment-container">
                                <CreateCommentForm
                                    onFocus={cancelEditor}
                                    onPost={createComment}
                                    status={commentState.createState.status}
                                    usersToMention={users}
                                    appContext={reportUrl}
                                />
                            </CreateCommentContainer>
                        </>
                    )}
                </ContentContainer>
            </Section>
        </CommentsContainer>
    );
});

Comments.displayName = "Comments";

export default Comments;
