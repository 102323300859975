import { Box, ButtonText, EmptyState, GraphIcon, Headline, Icon, Palette, Stack, Text } from "@secuis/ccp-react-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoryKeys, ChartCategoryType } from "src/components/Insights/common/ChartConfig";
import { ChipFilter } from "src/components/shared/ChipFilter/ChipFilter";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { Tooltip } from "src/components/shared/Tooltip";
import { LegendStyled } from "src/pages/Insights/Common.styled";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import { ExceptionType } from "src/store/insights/InsightsModel";

import { TileContent } from "../../../../../components/Insights/common/Tile.styled";
import { TrendIndicator } from "../../../../../components/Insights/common/TrendIndicator";
import { Loading } from "../../../../../components/shared/Loading";
import { TextCheckboxDropdown } from "../../../shared/components/TextCheckboxDropdown";
import { EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS } from "../../PatrolTasks.constants";
import { countTotal, formatCount } from "../../PatrolTasks.helpers";
import { TitleStyled } from "../ExceptionTrends.styled";
import { ExceptionTrendsChart } from "../ExceptionTrendsChart";
import { EmptyStateContainerStyled } from "./ExceptionTrendsTile.styles";
type Props = {
    isLoading: boolean;
    activeCategories: ChartCategoryType[];
    toggleCategory: (cat: string) => void;
    redirectUrl: string;
    exceptions: ExceptionType[];
    previousExceptions: ExceptionType[];
    dropdownItems: {
        value: CategoryKeys;
        key: CategoryKeys;
        isActive: boolean;
        categoryUrlParameterKey?: string;
        label: string;
    }[];
};

export const ExceptionTrendsTile = ({ isLoading, activeCategories, toggleCategory, redirectUrl, exceptions, previousExceptions, dropdownItems }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isFilterActive, isMobileLayout } = useInsightsPageContext();

    const renderTooltip = useCallback(() => {
        return (
            <Stack alignItems="center" gap="XS">
                <Headline bold>{t("insights.exceptionTrend.totalDeviationsHeader", { count: EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS })}</Headline>
                <Tooltip
                    message={t("insights.exceptionTrend.totalDeviationsTooltip", { count: EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS })}
                    id="exeptions-trends-chart-tooltip"
                >
                    <Icon variant="Info" />
                </Tooltip>
            </Stack>
        );
    }, [t]);

    const chartCategoriesLegendConfig = useMemo(() => {
        return activeCategories.map((activeCateogry) => {
            return {
                color: activeCateogry.color,
                iconVariant: activeCateogry.iconVariant,
                key: activeCateogry.key,
                title: activeCateogry.label,
            };
        });
    }, [activeCategories]);

    const handleToggleCategory = useCallback(
        (categoryKey: string) => {
            toggleCategory(categoryKey);
        },
        [toggleCategory],
    );

    const selectedItems = useMemo(() => activeCategories.map((c) => c.key).filter((c) => c !== "total_count"), [activeCategories]);

    return (
        <Stack data-testid="total-deviations-trends" direction="column" id="exception-trends-tile">
            {!isLoading && !exceptions.length && !previousExceptions.length && (
                <EmptyStateContainerStyled direction="column" flex={1} p={["L", "L", "S"]}>
                    {renderTooltip()}
                    <EmptyState icon="GraphIndicator" title={t("insights.exceptionTrend.noData.title")} />
                    <Stack alignItems="center" justifyContent="center">
                        <Text>{t("insights.exceptionTrend.noData.subtitle")}</Text>
                    </Stack>
                </EmptyStateContainerStyled>
            )}
            {isLoading && <Loading />}
            {!isLoading && !!exceptions.length && (
                <TileContent isFilterActive={isFilterActive}>
                    <Stack justifyContent="space-between" direction={isMobileLayout ? "column" : "row"}>
                        {renderTooltip()}
                        {!isMobileLayout && <TextCheckboxDropdown items={dropdownItems} selectedItems={selectedItems} onChange={toggleCategory} />}
                    </Stack>
                    <Stack
                        mb={["L", "L", "S"]}
                        marginTop="S"
                        alignItems={isMobileLayout ? "flex-start" : "flex-end"}
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        <Stack marginTop="XXS" gap={["M", "0", "0"]} direction={isMobileLayout ? "column" : "row"}>
                            <TitleStyled>{formatCount(countTotal(exceptions))}</TitleStyled>
                            <TrendIndicator
                                startValue={countTotal(previousExceptions)}
                                endValue={countTotal(exceptions.slice(0, 6))}
                                description={t("insights.exceptionTrend.sinceLastPeriod")}
                            />
                        </Stack>
                        {isMobileLayout && <TextCheckboxDropdown items={dropdownItems} selectedItems={selectedItems} onChange={toggleCategory} />}
                    </Stack>
                    <Box mb="S">
                        <HorizontalLine color={Palette.Navy600} />
                    </Box>
                    <Stack marginVertical="XS" gap="XS" flexWrap="wrap" mb={["0", "0", "S"]}>
                        {activeCategories.map((cat) => {
                            const isActive = cat.key !== CategoryKeys.total;
                            return (
                                !isMobileLayout && (
                                    <ChipFilter
                                        data-testid="total-deviations-chip-filter"
                                        key={cat.key}
                                        label={cat.label}
                                        onRemove={isActive ? () => handleToggleCategory(cat.key) : null}
                                    />
                                )
                            );
                        })}
                    </Stack>
                    <ExceptionTrendsChart chartCategories={activeCategories} chartData={exceptions} chartLegendConfig={chartCategoriesLegendConfig} />
                    <Stack direction={isMobileLayout ? "column" : "row"} mt="L" justifyContent="space-between" gap={["0", "0", "S"]}>
                        <Stack direction="column">
                            <Stack mb="XS">
                                <Text small bold>
                                    {t("insights.trendingEvents.legend")}
                                </Text>
                            </Stack>
                            <LegendStyled gap={["M", "M", "XS"]} flexWrap="wrap">
                                {chartCategoriesLegendConfig.map((cat) => {
                                    return (
                                        <Stack key={cat.key} gap="XXS" alignItems="center">
                                            <GraphIcon color={cat.color} variant={cat.iconVariant} />
                                            <Text data-testid="legend-item-category" micro>
                                                {cat.title}
                                            </Text>
                                        </Stack>
                                    );
                                })}
                            </LegendStyled>
                        </Stack>
                        <Stack alignItems="center" mt={["0", "0", "S"]} justifyContent={isMobileLayout ? "flex-end" : null}>
                            <ButtonText onClick={() => navigate(redirectUrl)} direction="row-reverse" icon="ArrowForward">
                                {t("insights.highlights.viewReports")}
                            </ButtonText>
                        </Stack>
                    </Stack>
                </TileContent>
            )}
        </Stack>
    );
};
