import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useState } from "react";

import { Loading } from "../../../../components/shared/Loading";
import { useWithFlexTransition } from "../../../../hooks/CommonHooks";
import { ReportTemplateEnum } from "../../../../models/ReportModel";
import { useSingleReport } from "../../../../store/reports/ReportsHooks";
import { NotFound } from "../../../Error/NotFound";
import Generic from "./Generic";
import { ReportListDetailsContainer } from "./ReportListDetails.styles";

type Props = {
    isAlreadyOpen: boolean;
    reportId: string;
    locationId: string;
    reportTemplateName: ReportTemplateEnum;
    onClose: () => void;
};

export const ReportListDetails = ({ isAlreadyOpen, reportId, locationId, reportTemplateName, onClose }: Props) => {
    const { report, siteObject, loading, error } = useSingleReport(reportId, locationId, reportTemplateName === ReportTemplateEnum.patrolTour);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [showFullPanel, setShowFullPanel] = useState(true);
    const { animate, flex } = useWithFlexTransition(isAlreadyOpen);
    const isLoading = loading !== false || !siteObject || !report;

    const onCloseHandler = useCallback(() => {
        setShowFullPanel(false);
        if (isMobile) {
            onClose();
            return;
        }
        animate(onClose);
    }, [isMobile, onClose, animate]);

    if (error) {
        return <NotFound />;
    }

    return (
        <ReportListDetailsContainer
            data-testid="report-list-details"
            id="report-list-details"
            key={"report-scrollable-container"}
            flex={isAlreadyOpen && showFullPanel ? `1` : flex}
        >
            {isLoading ? <Loading /> : <Generic report={report} siteObject={siteObject} onClose={onCloseHandler} isReportListDetails />}
        </ReportListDetailsContainer>
    );
};
