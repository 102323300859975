import { Breakpoints, ButtonIcon, CountIndicator, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FilterIconWrapper } from "./FilterIcon.styles";

type PropsType = {
    isActive?: boolean;
    activeFiltersCount: number;
    onClick: () => void;
};

export const FilterIcon = ({ isActive, activeFiltersCount, onClick }: PropsType) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const filtersCount = useMemo(() => {
        if (!activeFiltersCount) {
            return null;
        }
        return activeFiltersCount > 99 ? "99+" : activeFiltersCount;
    }, [activeFiltersCount]);

    const countIndicatorRightPos = useMemo(() => {
        if (activeFiltersCount < 10) {
            return isMobile ? -8 : 13;
        }
        if (activeFiltersCount < 100) {
            return isMobile ? -12 : 8;
        }
        return isMobile ? -18 : 6;
    }, [activeFiltersCount, isMobile]);

    return (
        <FilterIconWrapper>
            <>
                {!!filtersCount && !isActive && (
                    <CountIndicator
                        position="absolute"
                        data-testid="count-indicator"
                        count={activeFiltersCount}
                        right={countIndicatorRightPos}
                        top={-6}
                        color="accent"
                        onClick={onClick}
                    />
                )}
                <ButtonIcon data-testid="more-filters-icon" icon="Filter" mode="stateless" iconSize={"L"} onClick={onClick} aria-label={t("filters.open")} />
            </>
        </FilterIconWrapper>
    );
};
