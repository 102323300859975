import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DynamicReportType } from "../../../../models/ReportModel";
import { SeverityBadge } from "../Badges";

type Props = {
    report: DynamicReportType;
};

const CategoriesWrapper = styled(Stack)`
    align-items: center;
    flex-wrap: wrap;
    gap: ${Spacing.S}px;

    @media (max-width: calc(${Breakpoints.S})) {
        gap: ${Spacing.XS}px;
    }
`;

const Dot = styled.span`
    color: ${Palette.Navy300};
    margin: 0 ${Spacing.XS}px;
`;

export const Categories = ({ report }: Props) => {
    const { t } = useTranslation();

    return (
        <CategoriesWrapper>
            <SeverityBadge severityLevel={report?.severityLevel} />
            <Stack data-testid="category-detail-view" direction="row" alignItems="center" gap="XXXS" flexWrap="wrap">
                <Text data-testid="category-level-one" small bold>
                    {t(`incident.category.${report.categoryLevel1}`)}
                </Text>
                <Dot>•</Dot>
                <Text small bold>
                    {t(`incident.category.${report.categoryLevel2}`)}
                </Text>
                <Dot>•</Dot>
                <Text small bold>
                    {t(`incident.category.${report.categoryLevel3}`)}
                </Text>
            </Stack>
        </CategoriesWrapper>
    );
};
