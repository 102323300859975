import { Breakpoints, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TrendDescription = styled(Text)`
    min-height: 18px;

    @media (max-width: ${Breakpoints.L}) {
        min-height: 16.5px;
    }
`;
