import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import { TooltipIconsList } from "../../shared/components/TooltipIconsList";
import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";

type Props = {
    hasSingleSite?: boolean;
};

export const TooltipContent = ({ hasSingleSite }: Props) => {
    const { t } = useTranslation();
    const terms = [
        hasSingleSite ? "insights.deviationsTooltip.firstRowSingleSite" : "insights.deviationsTooltip.firstRow",
        hasSingleSite ? "insights.deviationsTooltip.secondRowSingleSite" : "insights.deviationsTooltip.secondRow",
        "insights.deviationsTooltip.thirdRow",
        "insights.deviationsTooltip.fourthRow",
    ];

    return (
        <Stack direction="column" gap="S">
            <Stack direction="column" gap="XS">
                {terms.map((term) => (
                    <span key={term}>{t(term)}</span>
                ))}
            </Stack>

            <TooltipIconsList title={t("insights.deviationsTooltip.deviationCategories")} items={CATEGORIES_DESCRIPTIONS} />

            <Stack gap="S">
                <Icon variant="Info" />
                <Text micro style={{ fontWeight: 400 }}>
                    {t("insights.deviationsTooltip.bottomRow")}
                </Text>
            </Stack>
        </Stack>
    );
};

const CATEGORIES_DESCRIPTIONS: { icon: JSX.Element; termKey: string }[] = [
    CategoryLevel1Keys.criminalActOrSuspiciousBehavior,
    CategoryLevel1Keys.facility,
    CategoryLevel1Keys.healthAndSafety,
    CategoryLevel1Keys.peopleAndAssets,
    CategoryLevel1Keys.internalSecuritas,
    "noCategory",
].map((categoryKey) => ({
    icon: <Icon variant={REPORT_CATEGORY_ICON_MAP[categoryKey]} />,
    termKey: `incident.category.${categoryKey}`,
}));
