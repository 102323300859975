import { ButtonIcon } from "@secuis/ccp-react-components";
import { PropsWithChildren, useContext } from "react";

import { DragAndDropContext } from "../../../DragAndDropProvider";
import { ContentStyled, OverlayStyled, ToolBarStyled, WrapperStyled } from "./DraggableWidgetWrapper.styles";

export const DraggableWidgetWrapper = ({ children, ...otherProps }: PropsWithChildren) => {
    const { editMode, isDisabled } = useContext(DragAndDropContext);

    return (
        <WrapperStyled editMode={editMode} {...otherProps}>
            {editMode && (
                <ToolBarStyled>
                    <ButtonIcon icon={isDisabled ? "Plus" : "Close"} iconSize="S" mode="stateless" />
                </ToolBarStyled>
            )}
            {isDisabled && editMode && <OverlayStyled />}
            <ContentStyled>{children}</ContentStyled>
        </WrapperStyled>
    );
};
