import { Sort } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

export const SortStyled = styled(Sort)`
    div[role="list"],
    div[role="listitem"] {
        overflow: visible;
    }

    div[role="listitem"]:focus-visible {
        color: inherit;
        outline: ${FocusStyles.Outline};
        border-radius: ${FocusStyles.BorderRadius}px;
        outline-offset: ${FocusStyles.OutlineOffsetNegative}px;
    }
`;
