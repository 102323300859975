import { ConfigFuncType } from "../../../models/DynamicTemplate";
import { TranslatableEmptyInboxComponent } from "../../shared/NoContent/EmptyInboxContent";
import { getBaseReportConfig } from "./baseReportConfig";

export const getUnknownReportConfig: ConfigFuncType = ({ report, siteObject, sharedReportId }) => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: TranslatableEmptyInboxComponent,
        props: {
            title: "reportdetails.unknown.message.title",
            subtitle: "reportdetails.unknown.message.subTitle",
        },
    },
];
