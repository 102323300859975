import { useState } from "react";

import { sortByField } from "../../../../../../helpers/ArrayHelper";
import { CheckpointScanType } from "../../../../../../models/ReportModel";

interface SortFieldDirection {
    fieldName: string;
    desc: boolean;
}

export interface CheckpointSortConfig {
    sortLabel: string;
    isDefault: boolean;
    mainSort: SortFieldDirection;
    preSort: SortFieldDirection;
}

export const CheckpointSortConfigs = {
    defaultSortConfig: {
        sortLabel: "reportdetails.patrolTour.sortCheckpoints",
        isDefault: true,
        mainSort: { fieldName: "scanDateTime", desc: true },
        preSort: null,
    },
    oldestFirstSortConfig: {
        sortLabel: "reportdetails.patrolTour.oldestToNewest",
        isDefault: false,
        mainSort: { fieldName: "scanDateTime", desc: false },
        preSort: null,
    },
    newestFirstSortConfig: {
        sortLabel: "reportdetails.patrolTour.newestToOldest",
        isDefault: false,
        mainSort: { fieldName: "scanDateTime", desc: true },
        preSort: null,
    },
};

export const useSortedCheckpoints = (checkpointScans: CheckpointScanType[]) => {
    const [currentSort, setCurrentSort] = useState(CheckpointSortConfigs.defaultSortConfig);

    const sortCheckpoints = (selectedSortConfig) => {
        setCurrentSort(selectedSortConfig);
        if (selectedSortConfig.preSort) checkpointScans.sort(sortByField(selectedSortConfig.preSort.fieldName, selectedSortConfig.preSort.desc));

        return checkpointScans.sort(sortByField(selectedSortConfig.mainSort.fieldName, selectedSortConfig.mainSort.desc));
    };

    return { sortCheckpoints, currentSort };
};
