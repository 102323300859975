import { RSAA } from "redux-api-middleware";

import {
    CREATE_COMMENT_FAILURE,
    CREATE_COMMENT_REQUEST,
    CREATE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    DELETE_COMMENT_REQUEST,
    DELETE_COMMENT_SUCCESS,
    FETCH_REPORT_COMMENTS_FAILURE,
    FETCH_REPORT_COMMENTS_REQUEST,
    FETCH_REPORT_COMMENTS_SUCCESS,
    SET_ENTRY_DEFAULT_STATE,
    UPDATE_COMMENT_FAILURE,
    UPDATE_COMMENT_REQUEST,
    UPDATE_COMMENT_SUCCESS,
} from "./CommentReducer";
import { IDeleteCommentDto, IUpsertCommentDto } from "./models";

const getBaseUrl = (locationId: string, reportId: string, isTour: boolean) => {
    if (isTour) {
        return `/v1/global/sites/${locationId}/tour-sessions/${reportId}/comments`;
    }
    return `/v2/global/sites/${locationId}/reports/${reportId}/comments`;
};

const getReportComments = (locationId: string, reportId: string, isTour = false) => {
    const meta = { key: reportId };
    return {
        [RSAA]: {
            auth: true,
            endpoint: getBaseUrl(locationId, reportId, isTour),
            method: "GET",
            types: [
                {
                    type: FETCH_REPORT_COMMENTS_REQUEST,
                    meta,
                },
                {
                    type: FETCH_REPORT_COMMENTS_SUCCESS,
                    meta,
                },
                {
                    type: FETCH_REPORT_COMMENTS_FAILURE,
                    meta,
                },
            ],
        },
    };
};

const createActions = [CREATE_COMMENT_REQUEST, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAILURE];
const updateActions = [UPDATE_COMMENT_REQUEST, UPDATE_COMMENT_SUCCESS, UPDATE_COMMENT_FAILURE];

const upsertComment = (locationId: string, reportId: string, body: IUpsertCommentDto, isTour = false) => {
    const meta = { key: reportId };
    if ("id" in body) {
        meta["commentId"] = body.id;
    }
    if ("comment" in body) {
        meta["content"] = body.comment;
    }
    if ("mentionedUserIds" in body) {
        meta["mentionedUsersCount"] = body.mentionedUserIds.length;
    }
    const actions = body.id ? updateActions : createActions;

    return {
        [RSAA]: {
            auth: true,
            endpoint: getBaseUrl(locationId, reportId, isTour),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body,
            types: [
                {
                    type: actions[0],
                    meta,
                },
                {
                    type: actions[1],
                    meta,
                },
                {
                    type: actions[2],
                    meta,
                },
            ],
        },
    };
};
const deleteComment = (locationId: string, reportId: string, body: IDeleteCommentDto, isTour = false) => {
    const meta = { key: reportId };
    if ("id" in body) {
        meta["commentId"] = body.id;
    }

    return {
        [RSAA]: {
            auth: true,
            endpoint: getBaseUrl(locationId, reportId, isTour),
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body,
            types: [
                {
                    type: DELETE_COMMENT_REQUEST,
                    meta,
                },
                {
                    type: DELETE_COMMENT_SUCCESS,
                    meta,
                },
                {
                    type: DELETE_COMMENT_FAILURE,
                    meta,
                },
            ],
        },
    };
};

const setEntryDefaultState = (): {
    type: string;
} => ({
    type: SET_ENTRY_DEFAULT_STATE,
});

export default {
    getReportComments,
    setEntryDefaultState,
    upsertComment,
    deleteComment,
};
