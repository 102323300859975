import { ButtonText, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import { findKey } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { QueryTimeRange } from "../../../models";
import { CardBaseContainer, CardBody, CardFooter, PaddedContainer, SummaryCardHeaderText } from "./Cards.styled";
import { SummaryCardTrend } from "./SummaryCardTrend";

const StyledCardFooter = styled(CardFooter)`
    margin-top: ${Spacing.M}px;
`;

interface SummaryCardsProps {
    headerText: string;
    subheaderText: string;
    url: string;
    cardWidth: number;
    dateRange: QueryTimeRange;
    count: number;
    prevPeriodCount?: number;
    secondarySubheaderText?: string;
}

export const SummaryCard = ({ count, headerText, subheaderText, dateRange, url, cardWidth, prevPeriodCount, secondarySubheaderText }: SummaryCardsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const redirectToDetails = () => navigate(url);
    const timeRangeLabel = t(`insights.highlights.dateRange.${findKey(QueryTimeRange, (range) => range === dateRange)}`);

    return (
        <CardBaseContainer data-testid="summary-card" cardWidth={cardWidth} cardType="summary_card">
            <PaddedContainer>
                <SummaryCardHeaderText data-testid="summary-card-category-level-one" small bold>
                    {headerText}
                </SummaryCardHeaderText>
                <SummaryCardHeaderText data-testid="summary-card-category-level-two" micro bold color="neutral">
                    {subheaderText}
                </SummaryCardHeaderText>
                <CardBody>
                    <SummaryCardTrend count={count} prevPeriodCount={prevPeriodCount} />
                    <Text>{secondarySubheaderText || subheaderText}</Text>
                    <Text color="secondary">
                        {t("insights.highlights.relatedIncidentsOverLast")}
                        <span style={{ color: `${Palette.Gray100}` }}> {timeRangeLabel}</span>
                    </Text>
                </CardBody>
                <StyledCardFooter>
                    <ButtonText onClick={redirectToDetails} direction="row-reverse" icon="DoubleArrowForward">
                        <Text micro uppercase>
                            {t("insights.highlights.viewReports")}
                        </Text>
                    </ButtonText>
                </StyledCardFooter>
            </PaddedContainer>
        </CardBaseContainer>
    );
};
