import { subDays, subMonths } from "date-fns";
import { useMemo } from "react";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "src/helpers/date";

import { SummaryViewMode } from "../../../../../store/insights/InsightsModel";
import { SummaryPeriod } from "../types";
import { useSummaryViewMode } from "./useSummaryViewMode";

export const useSummaryPeriod = () => {
    const viewMode = useSummaryViewMode();

    return useMemo(() => calculatePeriods(viewMode), [viewMode]);
};

const calculatePeriods = (
    mode: SummaryViewMode,
): {
    currentPeriod: SummaryPeriod;
    previousPeriod: SummaryPeriod;
} => {
    const now = new Date();
    const [subtractPeriod, startOf, endOf] = mode === "month" ? [subMonths, startOfMonth, endOfMonth] : [subDays, startOfDay, endOfDay];
    const current = subtractPeriod(now, 1);
    const previous = subtractPeriod(now, 2);

    return {
        currentPeriod: {
            start: startOf(current),
            end: endOf(current),
        },
        previousPeriod: {
            start: startOf(previous),
            end: endOf(previous),
        },
    };
};
