import { Text, View } from "@react-pdf/renderer";

import { getFullAddressOrCoordinates, ISiteObject } from "../../../models/SiteObjectModel";
import { styles } from "../pdfStyles";
import { LocationIconPdf } from "./Icons";

type Props = {
    siteObject: ISiteObject;
};

export const HeaderLocationLine = ({ siteObject }: Props) => {
    const fullAddress = getFullAddressOrCoordinates(siteObject);
    return (
        <>
            <View style={styles.sectionLineBreak}></View>
            <View style={styles.detailsLine}>
                <View style={styles.detailsWrappableWrapper}>
                    <View style={styles.detailsIconContainer}>
                        <LocationIconPdf />
                        <Text style={styles.locationBoldText}>{siteObject.displayName}</Text>
                    </View>
                    <View style={styles.verticalDivider} />
                    <View>
                        <Text style={styles.locationText}>{fullAddress}</Text>
                    </View>
                </View>
            </View>
        </>
    );
};
