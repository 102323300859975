import { Text } from "@secuis/ccp-react-components";
import _isArray from "lodash/isArray";
import { useEffect, useRef } from "react";

import { StyledButton, TooltipStyled } from "./CustomizedGraphTooltip.styles";
import { useTooltipPlacementContext } from "./TooltipPlacementProvider";

interface GraphTooltipProps {
    content: React.ReactNode | React.ReactNode[];
    active: boolean;
    buttonLabel?: string;
    redirectElementId?: string;
    widthHandler?: (width: number) => void;
    heightHandler?: (height: number) => void;
    onClick?: () => void;
}

export const CustomizedGraphTooltip = ({ content, active, buttonLabel, redirectElementId, onClick, ...props }: GraphTooltipProps) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const refClientWidth = tooltipRef.current?.clientWidth;
    const refClientHeight = tooltipRef.current?.clientHeight;
    const isButtonVisible = onClick && redirectElementId && buttonLabel;
    const isContentArray = _isArray(content);
    const { saveTooltipWidth, saveTooltipHeight, placementSide } = useTooltipPlacementContext();
    //NOTE: prop handlers might be removed after completed migration to context
    const widthHandler = props.widthHandler ?? saveTooltipWidth;
    const heightHandler = props.heightHandler ?? saveTooltipHeight;

    useEffect(() => {
        if (widthHandler) {
            widthHandler(refClientWidth);
        }
    }, [refClientWidth, widthHandler]);

    useEffect(() => {
        if (heightHandler) {
            heightHandler(refClientHeight);
        }
    }, [refClientHeight, heightHandler]);

    if (!content) {
        return null;
    }

    const contentArray = _isArray(content) ? content : [content];

    return (
        <TooltipStyled
            data-testid="bar-chart-tooltip"
            placementSide={placementSide}
            ref={tooltipRef}
            visible={active}
            isAlignLeft={isContentArray}
            onMouseDown={(e) => {
                e.stopPropagation();
            }}
        >
            {contentArray.map((item, index) => (
                <Text key={index} micro bold>
                    {item}
                </Text>
            ))}
            {isButtonVisible && (
                <StyledButton id={redirectElementId} onClick={onClick}>
                    <Text micro bold>
                        {buttonLabel}
                    </Text>
                </StyledButton>
            )}
        </TooltipStyled>
    );
};
