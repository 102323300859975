import { ButtonText, Stack } from "@secuis/ccp-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Accordion from "../Accordion/Accordion";
import { ItemCheckbox, ItemContainer } from "./CheckboxListItem.styles";
import { IMultiListItem } from "./CheckboxListItem.types";

type Props = {
    item: IMultiListItem;
    selectedItems: string[];
    displayChildren?: boolean;
    showAllThreshold?: number;
    onItemClickHandler: (item: IMultiListItem) => void;
    useCustomContextWrapper?: boolean;
    disabledItems?: string[];
};

export const CheckboxListItem = ({
    item,
    selectedItems,
    displayChildren = true,
    showAllThreshold = null,
    onItemClickHandler,
    useCustomContextWrapper = false,
    disabledItems = [],
}: Props) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    const showChildren = item?.children?.length > 0 && displayChildren;

    const hasDescendantSelected = (every: boolean) => (descendant) => {
        if (descendant.children?.length) {
            return every ? descendant.children.every(hasDescendantSelected(every)) : descendant.children.some(hasDescendantSelected(every));
        } else return selectedItems.includes(descendant.value);
    };

    const isSomeSelected = !!item?.children?.some(hasDescendantSelected(false));
    const isAllSelected = !!item?.children?.every(hasDescendantSelected(true));

    const isSelected = useMemo(() => {
        if (item?.children?.length) {
            return selectedItems.includes(item.value) && (isSomeSelected || isAllSelected);
        }
        return selectedItems.includes(item.value);
    }, [selectedItems, item, isSomeSelected, isAllSelected]);

    const childrenToDisplay = useMemo(() => {
        if (!showChildren || !item?.children?.length) {
            return [];
        }
        if (showAll || !showAllThreshold) {
            return item.children;
        }
        return item.children.slice(0, showAllThreshold);
    }, [item, showAll, showAllThreshold, showChildren]);

    const showShowMoreButton = showChildren && showAllThreshold && item?.children?.length > showAllThreshold;

    const showMoreButtonText = showAll ? t("filters.showless") : `${t("filters.showAllButton")} (${item.children?.length})`;

    const AccordionItem = () => (
        <Accordion.Item id={item.value}>
            <Accordion.Header hasContent={showChildren}>
                <ItemContainer key={item.label}>
                    <ItemCheckbox
                        key={item.value}
                        id={item.value}
                        label={`${item.label} ${item.children?.length ? `(${item.children.length})` : ""}`}
                        checked={isSelected || isSomeSelected}
                        nonStandard={isSomeSelected && !isAllSelected}
                        onChange={() => onItemClickHandler(item)}
                        alignItems="flex-start"
                        disabled={disabledItems.includes(item.value)}
                    />
                </ItemContainer>
            </Accordion.Header>
            <Accordion.Content>
                <Stack direction="column" pl="M" mb="XS">
                    {childrenToDisplay.map((i) => (
                        <CheckboxListItem
                            key={i.value}
                            item={i}
                            displayChildren
                            selectedItems={selectedItems}
                            showAllThreshold={showAllThreshold}
                            onItemClickHandler={onItemClickHandler}
                            useCustomContextWrapper={useCustomContextWrapper}
                            disabledItems={disabledItems}
                        />
                    ))}
                </Stack>
                {showShowMoreButton && (
                    <ButtonText micro onClick={() => setShowAll(!showAll)}>
                        {showMoreButtonText}
                    </ButtonText>
                )}
            </Accordion.Content>
        </Accordion.Item>
    );

    return useCustomContextWrapper ? (
        <AccordionItem />
    ) : (
        <Accordion>
            <AccordionItem />
        </Accordion>
    );
};
