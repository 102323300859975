import { Stack, Text } from "@secuis/ccp-react-components";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { ANCHOR_CLASSNAME } from "./SiteZoneInfoTooltip.constants";
import { useSiteZoneInfoTooltip } from "./SiteZoneInfoTooltip.hooks";
import { TooltipStyled } from "./SiteZoneInfoTooltip.styles";

type Props = PropsWithChildren;

export const SiteZoneInfoTooltip = ({ children }: Props) => {
    const { t } = useTranslation();
    const { tooltipWidth, isOpen, containerRef, triggerProps, closeTooltip, confirmNoteAsRead } = useSiteZoneInfoTooltip();

    return (
        <>
            <Stack ref={containerRef} {...triggerProps} className={ANCHOR_CLASSNAME}>
                {children}
            </Stack>
            {/*
        NOTE: It is required to render it explicitly
        as the isOpen flag on Tooltip is not working correctly
        */}
            {isOpen && (
                <TooltipStyled
                    title={t("datepicker.siteZoneNote.title")}
                    anchorSelect={`.${ANCHOR_CLASSNAME}`}
                    width={tooltipWidth}
                    buttonText={t("datepicker.siteZoneNote.confirmButton")}
                    onClick={confirmNoteAsRead}
                    isOpen={true}
                    onClose={closeTooltip}
                >
                    <Text>{t("datepicker.siteZoneNote.text")}</Text>
                </TooltipStyled>
            )}
        </>
    );
};
