import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { RightAlignText } from "../SharedStyles";

export const ListItemDateText = styled(RightAlignText)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: ${Breakpoints.XS}) {
        line-height: 21px;
    }
`;

export const InfoContainerStyled = styled(Stack).attrs({
    alignItems: "flex-end",
    flexWrap: "wrap",
    justifyContent: "flex-end",
})`
    column-gap: ${Spacing.XXS}px;
`;
