import { Breakpoints, Button, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { IAvailableClient } from "../../../store/clientSwitcher/types";
import { ButtonsStyled, FooterWrapperStyled } from "./Footer.styles";

type Props = {
    selectedClient?: IAvailableClient;
    onCancel?: (e: React.MouseEvent<Element, MouseEvent>) => void;
    onConfirm?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

export const Footer = ({ selectedClient, onConfirm, onCancel }: Props) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <FooterWrapperStyled data-testid="footer-wrapper">
            <Stack direction="column">
                <Text micro uppercase color="secondary">
                    {t("switcher.confirmation.yourChoice")}
                </Text>
                <Stack mv={isMobile ? "S" : "0"}>
                    <Text data-testid="selected-client" bold>
                        {selectedClient.name}
                    </Text>
                </Stack>
            </Stack>
            <ButtonsStyled>
                <Button data-testid="cancel-button" mode="outlined" onClick={onCancel}>
                    {t("common.cancel")}
                </Button>
                <Button data-testid="go-to-app-button" mode="contained" onClick={onConfirm}>
                    {t("switcher.confirmation.goToApp")}
                </Button>
            </ButtonsStyled>
        </FooterWrapperStyled>
    );
};
