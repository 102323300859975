import { getTimezoneOffset, isAfter, IsoStringDate, subDays, zonedTimeToUtc } from "src/helpers/date";

const createTimeLiteral = (hours: string, minutes: string) => `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;

const trimTimeTz = (timeInput: string) => {
    const match = RegExp(/^(\d{1,2}:\d{1,2})\s*([ap]m)?\s*\w*$/i).exec(timeInput);

    if (!match) {
        return timeInput;
    }

    const time = match[1];
    const time12hAbbr = match[2];

    return time12hAbbr ? `${time} ${time12hAbbr.toLowerCase()}` : time;
};

const parseTimeFromManualInput = (timeInput: string): string => {
    const trimmedTime = trimTimeTz(timeInput);
    const isAM = trimmedTime.toLowerCase().endsWith("am");
    const isPM = trimmedTime.toLowerCase().endsWith("pm");

    if ((isAM || isPM) && /(\d{1,2}):(\d{1,2})/.test(trimmedTime)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, hours, minutes] = RegExp(/(\d{1,2}):(\d{1,2})/).exec(trimmedTime);
        let formattedHours = hours;

        if (isPM) {
            const numHours = Number(hours);
            if (numHours === 12) {
                formattedHours = "00";
            } else {
                formattedHours = `${Number(hours) + 12}`;
            }
        }

        if (isAM && hours === "12") {
            formattedHours = "00";
        }

        return createTimeLiteral(formattedHours, minutes);
    }

    const time24h = trimmedTime.split(":");

    if (time24h[0] && time24h[1]) {
        return createTimeLiteral(time24h[0], time24h[1]);
    }

    return trimmedTime;
};

const createDateFromParts = (date: string, time: string): Date => new Date(`${date}T${parseTimeFromManualInput(time)}`);

export const createExplicitDateFromReference = (localDateLiteral: string, localTimeLiteral: string, referenceDate: IsoStringDate): Date | null => {
    if (!localDateLiteral || !localTimeLiteral || !referenceDate) {
        return null;
    }

    const [timeZone] = /(.)(\d{2}:\d{2})$/.exec(referenceDate) || [null];
    const timezoneOffsetMillis = getTimezoneOffset(timeZone);
    const parsedDateTime = createDateFromParts(localDateLiteral, localTimeLiteral);
    const dateTimeByTimezoneOffset = zonedTimeToUtc(parsedDateTime, "UTC").getTime() - timezoneOffsetMillis;

    return new Date(dateTimeByTimezoneOffset);
};

export const getStartDateByEndTimeAndReferenceDate = (startTimeInput: string, endTimeInput: string, referenceDate: IsoStringDate): Date | null => {
    if (!startTimeInput || !endTimeInput || !referenceDate) {
        return null;
    }

    const [date] = referenceDate.split("T");
    const endDate = createExplicitDateFromReference(date, endTimeInput, referenceDate);
    let startDate = createExplicitDateFromReference(date, startTimeInput, referenceDate);

    if (isAfter(startDate, endDate)) {
        startDate = subDays(startDate, 1);
    }
    return startDate;
};
