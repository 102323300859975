import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { ButtonText } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useWebHubGlobalUrl } from "src/hooks/WebHubHooks";
import ReportsSelectors from "src/store/reports/ReportsSelectors";

import { getReportTemplateShortTranslation, parseReportExportFilename } from "../../../helpers/ReportHelper";
import { DynamicReportType, isPatrolTour, PatrolTourType, ReportTemplateEnum } from "../../../models/ReportModel";
import { ISiteObject } from "../../../models/SiteObjectModel";
import AppSelectors from "../../../store/app/AppSelectors";
import { usePdfComponents, usePdfReportImages } from "../../../store/reports/ReportPdfHooks";
import { useRelatedReportsReady } from "../../../store/reports/ReportsHooks";
import { PdfReportContainer } from "../../PdfReports/PdfReportContainer";
import { ReportProvider } from "../ReportProvider";

type DownloadButtonProps = {
    text: string;
    loading?: boolean;
    onClick?: () => void;
};

const DownloadButton = ({ text, loading = false, onClick = null }: DownloadButtonProps) => {
    return (
        <ButtonText
            data-testid="downloadPdf-report-detail-view"
            color="primary"
            icon="Download"
            direction="row"
            disabled={loading}
            onClick={onClick}
            tabIndex={onClick && !loading ? 0 : -1}
        >
            {text}
        </ButtonText>
    );
};

const sendFileToDevice = (file?: { base64: string; fileName: string; type: string }) => {
    const targetOrigin = "/";
    const topic = "FILE_SAVE";
    const message = null;
    const payload = {
        topic,
        message,
        file,
        timestamp: new Date(),
    };
    window.parent.postMessage(payload, targetOrigin);
};

type DownloadPdfButtonProps = {
    report: DynamicReportType | PatrolTourType;
    sharedReportId?: string;
    siteObject: ISiteObject;
};

export const DownloadPdfButton = ({ report, sharedReportId, siteObject }: DownloadPdfButtonProps) => {
    const { t } = useTranslation();
    const { isLoading } = usePdfReportImages(report, sharedReportId, false);
    const isInNativeHubEmbedded = useSelector(AppSelectors.getIsEmbeddedInNativeHub);
    const relatedReportReady = useRelatedReportsReady(report);
    const [isReady, setIsReady] = useState(false);

    const areDynamicTranslationsLoading = useSelector(ReportsSelectors.areDynamicTranslationsLoading);
    const translationLoadingPending = useSelector((state) => ReportsSelectors.getTranslationLoadingPending(state, report.id));

    const pdfComponents = usePdfComponents(report, sharedReportId, siteObject);
    const webHubGlobalUrl = useWebHubGlobalUrl();
    const pdfFooterLinkUrl = `${webHubGlobalUrl}/global`;

    const [areTranslationsLoading, setAreTranslationsLoading] = useState(true);

    const pdfReport = useMemo(
        () =>
            pdfComponents?.length > 0 ? (
                <ReportProvider report={report}>
                    <PdfReportContainer countryCode={siteObject.countryCode} footerLinkUrl={pdfFooterLinkUrl}>
                        {pdfComponents}
                    </PdfReportContainer>
                </ReportProvider>
            ) : null,
        [pdfComponents, report, sharedReportId, siteObject.countryCode, pdfFooterLinkUrl],
    );

    const getFileName = useCallback((report: DynamicReportType | PatrolTourType) => {
        const reportTemplateLabel = isPatrolTour(report)
            ? getReportTemplateShortTranslation(ReportTemplateEnum.patrolTour)
            : getReportTemplateShortTranslation(report.template);
        return parseReportExportFilename(reportTemplateLabel, new Date(isPatrolTour(report) ? report.endDateTime : report.reportDateTime));
    }, []);

    const downloadCallback = useCallback(async () => {
        const fileName = getFileName(report);
        const reader = new FileReader();
        const blob = await pdf(pdfReport).toBlob();
        reader.readAsDataURL(blob);
        let base64;
        reader.onload = () => {
            const result = reader.result as string;
            base64 = result.split(",")[1];

            const file = {
                base64,
                fileName,
                type: "application/pdf",
            };
            sendFileToDevice(file);
        };
    }, [getFileName, report, pdfReport]);

    // copy-paste from Generic.tsx, doubled delay (the one from Generic.tsx was not enough)
    // this is not a good solution but the right one would require solid rewrite of the whole logic
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const isAnyTranslationLoading = areDynamicTranslationsLoading && translationLoadingPending;
            if (isAnyTranslationLoading !== areTranslationsLoading) setAreTranslationsLoading(isAnyTranslationLoading);
        }, 400);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [areDynamicTranslationsLoading, translationLoadingPending, areTranslationsLoading]);

    useEffect(() => {
        setIsReady(!isLoading && pdfReport && !areTranslationsLoading && (isPatrolTour(report) ? relatedReportReady || !!sharedReportId : true));
    }, [isLoading, pdfReport, relatedReportReady, report, sharedReportId, areTranslationsLoading]);

    if (isInNativeHubEmbedded) {
        return isReady ? (
            <DownloadButton text={t("common.downloadPdf")} onClick={downloadCallback} />
        ) : (
            <DownloadButton text={t("common.downloadPdf")} loading={true} />
        );
    }

    return isReady && !isInNativeHubEmbedded ? (
        <PDFDownloadLink document={pdfReport} fileName={getFileName(report)} style={{ textDecoration: "none", display: "flex" }}>
            <DownloadButton text={t("common.downloadPdf")} />
        </PDFDownloadLink>
    ) : (
        <DownloadButton text={t("common.downloadPdf")} loading={true} />
    );
};
