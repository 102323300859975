import { getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { TourExceptionFields } from "../../../../models/reportTemplates/TourException";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getTourExceptionReportConfigPdf = ({
    report,
    siteObject,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.tourException.comments",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description: getFieldValueByFieldName(TourExceptionFields.comments, report),
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.detailedInfo.tourExceptionSummary",
        },
        children: [
            conditionalRender(getFieldValueByFieldName(TourExceptionFields.exceptionQuestion, report), () => ({
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: getFieldValueByFieldName(TourExceptionFields.exceptionQuestion, report),
                    fieldValue: getFieldValueByFieldName(TourExceptionFields.exceptionAnswer, report),
                    isRequired: true,
                    shouldAutoTranslate: true,
                },
            })),
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.checkpoint",
                    fieldValue: getFieldValueByFieldName(TourExceptionFields.checkpoint, report),
                    isRequired: true,
                    fullyTranslated: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(TourExceptionFields), []),
        ],
    },
];
