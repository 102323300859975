import { Text, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { Container, Wrapper } from "./Error.styled";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Wrapper>
                <Title color="accent">{t("common.pagenotfound")}</Title>
                <Text>{t("common.missingpage")}</Text>
            </Wrapper>
        </Container>
    );
};
