import { useSelector } from "react-redux";
import { useSqlQuery } from "src/sql/hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { getIncidentsBarchartQuery, parseIncidentsBarchartQueryResult } from "../Barchart.queries";

export const useFetchIncidents = (siteId: string, categories: string[], level: number, parentCategory: string) => {
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const queryParams = { startDate, endDate, siteId, categories, level, parentCategory };

    const { isLoading, queryResult } = useSqlQuery(getIncidentsBarchartQuery, parseIncidentsBarchartQueryResult, queryParams);

    return { queryResult, isLoading };
};
