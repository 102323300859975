import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import InsightsActions from "src/store/insights/InsightsActions";

const useSelectDateRangeFromLocation = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const locationState = location?.state;

        if (!locationState) {
            return;
        }

        const { startDate, endDate } = locationState;

        if (startDate && endDate) {
            dispatch(InsightsActions.selectDateRange(startDate, endDate));
        }
    }, [dispatch, location]);
};

export default useSelectDateRangeFromLocation;
