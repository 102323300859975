import { Palette } from "@secuis/ccp-react-components";

export const EventsCountTick = (props?: any) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} fontSize="11px" letterSpacing="0.2px" textAnchor="end" fill={Palette.Gray100}>
                {payload.value}
            </text>
        </g>
    );
};
