import { DataItem, IncidentData } from "../IncidentMatrix.types";

export const calculateAndSortCategories = (data: DataItem[]): string[] => {
    const excludedKeys = ["site", "other"];

    const categorySums: Record<string, number> = {};

    data.forEach((row) => {
        Object.keys(row).forEach((key) => {
            if (!excludedKeys.includes(key) && "categoryIncidentCount" in row[key]) {
                const incidentData = row[key] as IncidentData;
                if (categorySums[key]) {
                    categorySums[key] += incidentData.categoryIncidentCount;
                } else {
                    categorySums[key] = incidentData.categoryIncidentCount;
                }
            }
        });
    });

    return Object.keys(categorySums).sort((a, b) => categorySums[b] - categorySums[a]);
};
