import { Box, ButtonText, ContextMenu, ContextMenuOption } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    isRead: boolean;
    onMarkRead?: () => void;
    onMarkUnread?: () => void;
};

export const ListItemContextMenu = ({ isRead, onMarkRead, onMarkUnread }: Props) => {
    const { t } = useTranslation();

    const contextMenuOptions: ContextMenuOption[] = useMemo(() => {
        const markReadUnreadOption: ContextMenuOption = isRead
            ? {
                  label: t("reports.status.markAs.unread"),
                  icon: "Envelope",
                  onClick: onMarkUnread,
                  disabled: !isRead,
              }
            : {
                  label: t("reports.status.markAs.read"),
                  icon: "EnvelopeOpened",
                  onClick: onMarkRead,
                  disabled: isRead,
              };
        return [markReadUnreadOption];
    }, [isRead, onMarkRead, onMarkUnread, t]);

    return (
        <Box>
            <ContextMenu options={contextMenuOptions} sheetCancelLabel={t("common.cancel")}>
                <ButtonText data-testid="mark-read-unread-options" icon="Options" iconSize="L" color="primary" />
            </ContextMenu>
        </Box>
    );
};
