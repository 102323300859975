import { Box, Icon, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { Tooltip } from "../../../../components/shared/Tooltip";

export const AdditionalInfoTooltip = () => {
    const { t } = useTranslation();

    return (
        <Box ml="XS">
            <Tooltip
                message={
                    <Stack gap="XS" direction="column">
                        <span>{t("insights.trendingEvents.infoTooltip.defaultDesc")}</span>
                        <span>{t("insights.trendingEvents.infoTooltip.customDesc")}</span>
                    </Stack>
                }
                place="top"
            >
                <Icon variant="Info" />
            </Tooltip>
        </Box>
    );
};
