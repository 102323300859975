import { Box, Skeleton, Stack } from "@secuis/ccp-react-components";
import { ForwardedRef, forwardRef } from "react";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { TextSkeletonWrapper } from "./Map.styles";
import { CardsIndicator, SiteDetails } from "./MapFooterComponents";

type Props = {
    inProgress: boolean;
    incidents: {
        location_id: string;
        count: number;
    }[];
    activeSites: ISiteObject[];
    incidentsAmount: number;
};

export const MapFooterDetails = forwardRef(({ inProgress, incidents, activeSites, incidentsAmount }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    if (inProgress) {
        return (
            <TextSkeletonWrapper>
                <Skeleton mode="text" typography="body" />
            </TextSkeletonWrapper>
        );
    }

    return (
        <Stack flexWrap="wrap" data-testid="map-footer-details">
            {activeSites.length > 0 ? (
                activeSites.map((activeSite, index) => {
                    // ref to first el
                    const restProps = index === 0 ? { ref } : {};
                    return (
                        <Box key={activeSite.id} paddingBottom="S" {...restProps}>
                            <SiteDetails indicatorAlign={activeSites.length > 1 ? "vertical" : "horizontal"} incidents={incidents} site={activeSite} />
                        </Box>
                    );
                })
            ) : (
                <Box paddingBottom="S">
                    <CardsIndicator isSite={false} incidentsAmount={incidentsAmount} />
                </Box>
            )}
        </Stack>
    );
});

MapFooterDetails.displayName = "MapFooterDetails";
