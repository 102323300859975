import { Palette } from "@secuis/ccp-react-components";

import { clusterIcon, counterBadgeLarge, counterBadgeMedium, counterBadgeSmall, sitePin, sitePinFilled } from "./Markers";

export const LOCATIONS_SOURCE = "locations";

enum ClusterSize {
    Small = 6,
    Medium = 13,
}

// 27 pixels for small cluster, 32 pixels for medium, default 42 pixels
const expandedClusterMobileRadius = [27, ClusterSize.Small, 32, ClusterSize.Medium, 42];
const expandedClusterDesktopRadius = [32, ClusterSize.Small, 42, ClusterSize.Medium, 62];
const clusterMobileRadius = [23, ClusterSize.Small, 28, ClusterSize.Medium, 38];
const clusterDesktopRadius = [28, ClusterSize.Small, 38, ClusterSize.Medium, 58];

export const getClusterCircleRadius = (isMobile: boolean, isExpanded: boolean) => {
    if (isExpanded) {
        return isMobile ? expandedClusterMobileRadius : expandedClusterDesktopRadius;
    }
    return isMobile ? clusterMobileRadius : clusterDesktopRadius;
};

export const getClustersPaint = (isMobile: boolean) => ({
    "circle-color": [
        "case",
        ["boolean", ["feature-state", "pressed"], false],
        `${Palette.Navy850}`,
        ["boolean", ["feature-state", "hover"], false],
        `${Palette.Navy800}`,
        `${Palette.Navy750}`,
    ],
    "circle-radius": [
        "case",
        ["boolean", ["feature-state", "pressed"], false],
        ["step", ["get", "point_count"], ...getClusterCircleRadius(isMobile, true)],
        ["boolean", ["feature-state", "hover"], false],
        ["step", ["get", "point_count"], ...getClusterCircleRadius(isMobile, true)],
        ["step", ["get", "point_count"], ...getClusterCircleRadius(isMobile, false)],
    ],
    "circle-stroke-color": `${Palette.Navy300}`,
    "circle-stroke-width": 1,
    "circle-opacity": 0.8,
});

export const getIncidentCountStyles = (id?: string) =>
    id
        ? {
              "text-offset": ["match", ["string", ["get", "id"]], id, ["literal", [1.2, -1.8]], ["literal", [1, -1.6]]],
              "icon-offset": ["match", ["string", ["get", "id"]], id, ["literal", [12, -18]], ["literal", [10, -16]]],
          }
        : { "text-offset": ["literal", [1, -1.6]], "icon-offset": ["literal", [10, -16]] };

export const getClusterCountDefaultStyles = (isMobile: boolean) => ({
    "text-offset": [
        // text-offset for small, medium clustes and default
        "step",
        ["coalesce", ["get", "point_count_abbreviated"], 0],
        ["literal", isMobile ? [1.9, -1.6] : [2.1, -1.8]],
        ClusterSize.Small,
        ["literal", isMobile ? [2.3, -2] : [2.9, -2.6]],
        ClusterSize.Medium,
        ["literal", isMobile ? [2.7, -2.4] : [4.3, -4]],
    ],
    "icon-offset": [
        "step",
        ["coalesce", ["get", "point_count_abbreviated"], 0],
        ["literal", isMobile ? [19, -16] : [21, -18]],
        ClusterSize.Small,
        ["literal", isMobile ? [23, -20] : [29, -26]],
        ClusterSize.Medium,
        ["literal", isMobile ? [27, -24] : [43, -40]],
    ],
});

export const getCountClusterActiveStyles = (isMobile: boolean) => ({
    "text-offset": [
        [
            "step",
            ["coalesce", ["get", "point_count_abbreviated"], 0],
            ["literal", isMobile ? [2.2, -1.9] : [2.4, -2.1]],
            ClusterSize.Small,
            ["literal", isMobile ? [3, -2.7] : [3.2, -2.9]],
            ClusterSize.Medium,
            ["literal", isMobile ? [4.4, -4.1] : [4.6, -4.3]],
        ],
        getClusterCountDefaultStyles(isMobile)["text-offset"],
    ],
    "icon-offset": [
        [
            "step",
            ["coalesce", ["get", "point_count_abbreviated"], 0],
            ["literal", isMobile ? [22, -19] : [24, -21]],
            ClusterSize.Small,
            ["literal", isMobile ? [30, -27] : [32, -29]],
            ClusterSize.Medium,
            ["literal", isMobile ? [44, -41] : [46, -43]],
        ],
        getClusterCountDefaultStyles(isMobile)["icon-offset"],
    ],
});

export const mapConfig = (isMobile: boolean) => ({
    styleUrl: "mapbox://styles/guarding-map-feature/cliit6t6q00au01qv7jg1hhyz",
    styles: {
        bounds: isMobile ? { padding: { top: 50, bottom: 15, left: 15, right: 15 } } : { padding: { top: 125, bottom: 75, left: 75, right: 75 } },
        projection: "mercator",
    },
    source: {
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 50,
        clusterProperties: {
            markersIds: ["concat", ["concat", ["get", "id"], ","]],
            clusterIncidentCount: ["+", ["get", "incidentCount"]],
        },
    },
    navigation: {
        showCompass: false,
    },
    layers: {
        markers: {
            id: "markers",
            type: "symbol",
            source: LOCATIONS_SOURCE,
            filter: ["!=", "cluster", true],
            layout: {
                "icon-image": "sitePin",
                "icon-allow-overlap": true,
            },
        },
        incidentCountBadge: {
            id: "incidentCountBadge",
            type: "symbol",
            source: "locations",
            filter: ["all", ["!=", "incidentCount", 0], ["!=", "cluster", true]],
            layout: {
                "icon-image": ["step", ["get", "incidentCount"], "counterBadgeSmall", 10, "counterBadgeMedium", 100, "counterBadgeLarge"],
                "icon-allow-overlap": true,
                "text-field": ["step", ["get", "incidentCount"], ["get", "incidentCount"], 100, "99+"],
                "text-size": 10,
                ...getIncidentCountStyles(),
            },
        },
        clusters: {
            id: "clusters",
            type: "circle",
            source: LOCATIONS_SOURCE,
            filter: ["has", "point_count"],
            paint: getClustersPaint(isMobile),
        },
        clusterIncidentCountBadge: {
            id: "clusterIncidentCountBadge",
            type: "symbol",
            source: "locations",
            filter: ["all", ["!=", "clusterIncidentCount", 0], ["has", "point_count"]],
            layout: {
                "icon-image": ["step", ["get", "clusterIncidentCount"], "counterBadgeSmall", 10, "counterBadgeMedium", 100, "counterBadgeLarge"],
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-field": ["step", ["get", "clusterIncidentCount"], ["get", "clusterIncidentCount"], 100, "99+"],
                "text-size": 10,
                ...getClusterCountDefaultStyles(isMobile),
            },
        },
        clusterCount: {
            id: "cluster-count",
            type: "symbol",
            source: LOCATIONS_SOURCE,
            filter: ["has", "point_count"],
            layout: {
                "text-field": ["step", ["get", "point_count_abbreviated"], ["get", "point_count_abbreviated"], 1000, "999+"],
                "text-size": isMobile ? 12 : 14,
                "text-offset": ["step", ["get", "point_count_abbreviated"], ["literal", [0.7, 0]], 100, ["literal", [1, 0]], 1000, ["literal", [1, 0]]],
                "icon-optional": true,
                "icon-image": "cluster",
                "icon-offset": [
                    "step",
                    ["get", "point_count_abbreviated"],
                    ["literal", [-5, 0]],
                    10,
                    ["literal", [-8, 0]],
                    100,
                    ["literal", [-10, 0]],
                    1000,
                    ["literal", [-5, 0]],
                ],
            },
            paint: {
                "text-color": `${Palette.Gray100}`,
            },
        },
    },
    images: [
        { name: "sitePin", el: sitePin, style: { width: 40, height: 40 } },
        { name: "activeSitePin", el: sitePinFilled, style: { width: 40, height: 40 } },
        { name: "cluster", el: clusterIcon, style: { width: 40, height: 40 } },
        { name: "counterBadgeLarge", el: counterBadgeLarge, style: { width: 29, height: 20 } },
        { name: "counterBadgeMedium", el: counterBadgeMedium, style: { width: 24, height: 20 } },
        { name: "counterBadgeSmall", el: counterBadgeSmall, style: { width: 20, height: 20 } },
    ],
});
