import { Palette } from "@secuis/ccp-react-components";

type Props = {
    highlightNames?: string[];
    // recharts dot data props are typed as any
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    [prop: string]: any;
};

export const ActiveDot = ({ highlightNames, ...props }: Props) => {
    const { cx, cy, r, fill } = props;

    const color = highlightNames?.includes(props.payload?.name) ? Palette.Purple300 : fill;

    return <circle cx={cx} cy={cy} r={1.4 * r} fill={color} strokeOpacity={0.3} stroke={color} strokeWidth={2 * r} />;
};
