import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const DateFilterWrapper = styled(Stack)`
    @media (min-width: ${Breakpoints.XS}) {
        position: absolute;
        top: ${Spacing.M}px;
        right: 40px;
    }
`;
