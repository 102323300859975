export const gdprCompliantCountries = [
    "at",
    "cz",
    "de",
    "dk",
    "ee",
    "es",
    "fi",
    "fr",
    "gb",
    "gr",
    "hr",
    "hu",
    "ie",
    "it",
    "lu",
    "lv",
    "nl",
    "no",
    "pl",
    "pt",
    "ro",
    "se",
    "sk",
    "sl",
];

export const countriesByRegions = [
    {
        name: "asia",
        countries: ["cn", "ae", "hk", "jo", "in", "il", "tr", "id", "sa", "sg", "kr", "kw", "th", "vn", "bh", "jp", "my", "ph", "lk", "tw", "qa"],
    },
    {
        name: "europe",
        countries: [
            "be",
            "at",
            "ba",
            "hr",
            "cz",
            "dk",
            "ro",
            "pt",
            "pl",
            "no",
            "nl",
            "lu",
            "ie",
            "hu",
            "de",
            "fr",
            "fi",
            "rs",
            "sk",
            "es",
            "se",
            "ch",
            "gb",
            "gr",
            "it",
            "lv",
            "ee",
            "sl",
            "me",
        ],
    },
    {
        name: "africa",
        countries: ["za", "eg"],
    },
    { name: "oceania", countries: ["au", "nz"] },
    {
        name: "americas",
        countries: ["ar", "ca", "us", "co", "cl", "cr", "ec", "mx", "ma", "pe", "uy", "br", "pa"],
    },
];

export const regionsByCountry = countriesByRegions.reduce((countries, countriesByRegion) => {
    countriesByRegion.countries.forEach((c) => {
        if (!countries[c]) {
            countries[c] = countriesByRegion.name;
        }
    });
    return countries;
}, {});
