import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { DraggableWidgetWrapper } from "../shared/components/DraggableWidgetWrapper";
import { useSummaryGrid } from "./SummaryGrid.hooks";
import { SummaryGridStyled } from "./SummaryGrid.styles";

export const SummaryGrid = () => {
    const { widgetsConfig } = useSummaryGrid();
    const isPersonalizeKpiSummaryEnabled = useFeatureFlag(featureFlags.personalizeKpiSummary);

    return (
        <SummaryGridStyled data-testid="widget-grid">
            {widgetsConfig.map(({ key, Widget, style }) =>
                isPersonalizeKpiSummaryEnabled ? (
                    <DraggableWidgetWrapper key={key}>
                        <Widget data-testid={`${key}-widget`} style={style} />
                    </DraggableWidgetWrapper>
                ) : (
                    <Widget key={key} data-testid={`${key}-widget`} style={style} />
                ),
            )}
        </SummaryGridStyled>
    );
};
