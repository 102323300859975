import { Breakpoints, Graph, GraphIcon, Palette, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCategoryType } from "src/components/Insights/common/ChartConfig";

import { EventsCountTick } from "../../../../components/Insights/EventsCountTick";
import { MonthsTick } from "../../../../components/Insights/MonthsTick";
import { ExceptionType } from "../../../../store/insights/InsightsModel";
import { ChartLegendConfig } from "../../shared/components/TrendingEventsChart/TrendingEventsChart";
import { ChartWrapperStyled } from "./ExceptionTrends.styled";
import { ExceptionTrendsChartTooltip } from "./ExceptionTrendsChartTooltip";

type Props = {
    chartData: ExceptionType[];
    chartCategories: ChartCategoryType[];
    chartLegendConfig: ChartLegendConfig[];
};

const CustomIcon = (props) => {
    const { cx, cy, variant, color } = props;

    const iconSizeProps = {
        x: cx - 4,
        y: cy - 4,
    };
    return <GraphIcon {...iconSizeProps} color={color} variant={variant} />;
};

export const ExceptionTrendsChart = ({ chartData, chartCategories, chartLegendConfig }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <ChartWrapperStyled>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    data={chartData}
                    margin={{
                        top: 8,
                        bottom: 10,
                        left: isMobile ? -30 : -10,
                        right: 10,
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        axisLine={false}
                        dataKey="month"
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<MonthsTick shouldAddYear />}
                        padding={{ left: Spacing.S, right: Spacing.S }}
                    />
                    <YAxis
                        axisLine={false}
                        interval={0}
                        padding={{ top: Spacing.S, bottom: Spacing.M }}
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<EventsCountTick />}
                        tickCount={5}
                    />
                    <Tooltip
                        cursor={{ stroke: Palette.Navy300, strokeWidth: 1, strokeDasharray: "4 4" }}
                        content={<ExceptionTrendsChartTooltip chartLegendConfig={chartLegendConfig} />}
                    />
                    {chartCategories.map((cat) => (
                        <Line
                            key={cat.key}
                            type="linear"
                            strokeDasharray={cat.strokeDasharray}
                            dot={<CustomIcon color={cat.color} variant={cat.iconVariant} />}
                            activeDot={<CustomIcon scale color={cat.color} variant={cat.iconVariant} />}
                            dataKey={cat.key}
                            stroke={Graph[cat.color] as string}
                        />
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        </ChartWrapperStyled>
    );
};
