import { REPORT_CATEGORIES } from "../data";

export const getSubcategories = (category: string): string[] => {
    for (const level1Category of REPORT_CATEGORIES) {
        if (level1Category.key === category) {
            return level1Category.level2Categories.flatMap((level2) => level2.level3Categories.map((level3) => level3.key));
        }

        for (const level2Category of level1Category.level2Categories) {
            if (level2Category.key === category) {
                return level2Category.level3Categories.map((level3) => level3.key);
            }
        }
    }

    return [];
};
