import { mapValues } from "lodash";

import { parseSqliteResult } from "../../../../helpers/parseSqliteResult";
import { WorkerExecResult } from "../../../../models/DbModel";
import {
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "../../../../sql/scripts/queryUtilities";

export const getReportsCountQuery = ({
    siteIds,
    startDate,
    endDate,
    withSiteZone = false,
}: {
    siteIds: string[];
    startDate: Date;
    endDate: Date;
} & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT location_id as locationId, count(*) as reportsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseReportsCountResult = ({ results }: WorkerExecResult) => {
    const resultData = results[0];

    return mapValues(parseSqliteResult(resultData, true), "reportsCount");
};
