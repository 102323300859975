import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CellStyled } from "../../IncidentMatrix.styles";

export const SiteHeaderCellStyled = styled(CellStyled)`
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;

    &::before {
        content: none;
    }

    padding: ${Spacing.M} ${Spacing.S} 0;

    @media (max-width: ${Breakpoints.L}) {
        padding-left: 0;
        padding-top: 0;
    }
`;
