import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { useResizeObserver } from "../../../../../../hooks/CommonHooks";
import { flexColumn } from "../../../../../../styles/templates/Flex";
import Generic from "../../Generic";

const Wrapper = styled.div<{ width: number; isSingleReport?: boolean }>`
    position: absolute;
    overflow: hidden;
    top: 0;
    height: 100%;
    width: ${({ width, isSingleReport }) => (isSingleReport ? `calc(100% - ${Spacing.XXXL}px)` : width ? `${width}px` : "50%")};
    z-index: 10;
    ${flexColumn}
    background-color: ${Palette.Navy700};
    right: 0;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        width: ${({ width, isSingleReport }) => (isSingleReport ? `calc(100% - ${Spacing.XXXL}px - ${Spacing.M}px)` : width ? `${width}px` : "50%")};
    }
`;

export const ReportDisplayModal = ({ isOpen, report, siteObject, onClose, isReportListDetails }) => {
    const reportDetailsRightSideContainer = document.getElementById("report-list-details");
    const [width] = useResizeObserver(reportDetailsRightSideContainer);

    if (!isOpen) return null;

    return createPortal(
        <Wrapper width={width} isSingleReport={!isReportListDetails}>
            {report && <Generic report={report} siteObject={siteObject} onClose={onClose} isReportListDetails={isReportListDetails} showBackButton={true} />}
        </Wrapper>,
        document.body,
    );
};
