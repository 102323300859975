import { HttpResponse } from "msw";

import { ICommentDto } from "../../../store/reportComments/models";
import { db } from "../../db";

export const deleteCommentResolver = (id: string) => {
    const deletedComment = db.comments.update({
        where: {
            id: {
                equals: id,
            },
        },
        data: {
            isDeleted: true,
        },
    }) as ICommentDto;

    const reportId = deletedComment.referredEntityId;
    const commentsCount = getCommentsCount(reportId);

    updateReportCommentsCount(reportId, commentsCount);

    return HttpResponse.json(deletedComment);
};

const getCommentsCount = (reportId: string): number => {
    const count: number = db.comments.count({
        where: {
            referredEntityId: {
                equals: reportId,
            },
            isDeleted: {
                equals: false,
            },
        },
    });

    return count;
};

const updateReportCommentsCount = (reportId: string, commentsCount: number) => {
    try {
        db.reports.update({
            where: {
                id: {
                    equals: reportId,
                },
            },
            data: {
                commentsCount: commentsCount,
            },
        });
    } catch {
        throw new Error("Comments count update failed");
    }
};

export default deleteCommentResolver;
