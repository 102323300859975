import { ButtonText, Stack, Text } from "@secuis/ccp-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Accordion, { AccordionContext } from "../Accordion/Accordion";
import { CheckboxListItem } from "../CheckboxList/CheckboxListItem";
import { DropdownList } from "../CheckboxList/CheckboxListItem.styles";
import { IMultiListItem } from "../CheckboxList/CheckboxListItem.types";
import { Loading } from "../Loading";

export interface IFilterObject {
    title: string;
    items: IMultiListItem[];
    isLoading: boolean;
    selectedIds: string[];
    cleanFilter: () => void;
    handleCheckChange: (item: IMultiListItem) => void;
    selectAll?: () => void;
}

export const FilterObject = ({ items, isLoading, title, selectedIds, cleanFilter, selectAll, handleCheckChange }: IFilterObject) => {
    const { t } = useTranslation();
    const { activeItems } = useContext(AccordionContext);

    const allItemsSelected = items.every((i) => selectedIds.includes(i.value));

    const buttonLabel = selectedIds.length > 0 ? t("filters.clearAll") : t("filters.selectAll");

    const filterWithChildren = useMemo(() => {
        if (activeItems.length === 0) return items;
        let filteredItems = items;

        activeItems.forEach((element, index) => {
            if (index + 1 === activeItems.length) {
                filteredItems = [filteredItems.find((x) => x.value === element)];
            } else {
                filteredItems = filteredItems.find((x) => x.value === element).children;
            }
        });

        return filteredItems;
    }, [activeItems, items]);

    const onButtonClick = allItemsSelected || selectedIds.length > 0 ? cleanFilter : selectAll;

    return (
        <Stack direction="column" flex="0 0 auto" data-testid="filter-section">
            <Stack justifyContent="space-between" alignItems="baseline" flexWrap="wrap" data-testid="filter-title-section">
                <Text color="primary" small bold>
                    {title}
                </Text>
                {activeItems.length === 0 && (
                    <ButtonText onClick={onButtonClick} color="primary" micro>
                        {buttonLabel}
                    </ButtonText>
                )}
            </Stack>
            {isLoading ? (
                <Loading />
            ) : (
                <DropdownList data-testid="dropdown-list">
                    <Accordion.Back filterActiveItems={activeItems} />
                    {filterWithChildren.map((item) => (
                        <CheckboxListItem
                            key={item.value}
                            item={item}
                            selectedItems={selectedIds}
                            showAllThreshold={5}
                            onItemClickHandler={handleCheckChange}
                            useCustomContextWrapper={true}
                        />
                    ))}
                </DropdownList>
            )}
        </Stack>
    );
};
