import { useTranslation } from "react-i18next";
import { TableCellProps } from "src/components/shared/Table/Table.types";
import { TourStatus } from "src/components/shared/TourStatus";
import { PatrolTourType } from "src/models/ReportModel";

type Props = TableCellProps<number, PatrolTourType>;

export const MissedCheckpointsCell = ({ cell }: Props) => {
    const { t } = useTranslation();

    return <TourStatus color="high">{`${cell} ${t("reportdetails.patrolTour.missed")}`}</TourStatus>;
};
