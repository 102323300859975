import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const HeaderContainer = styled(Stack)`
    color: ${Palette.Gray100};
    margin-bottom: ${Spacing.M}px;
    justify-content: space-between;

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        margin-bottom: ${Spacing.S}px;
    }
`;

export const TileHeaderSection = styled(Stack).attrs({
    mr: "0",
    direction: "column",
})`
    margin-right: 0px;
`;
