import { ActiveDot } from "./ActiveDot";

type Props = {
    highlightNames?: string[];
    // recharts dot data props are typed as any
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    [prop: string]: any;
};

export const Dot = ({ highlightNames, ...props }: Props) => {
    const shouldHighlight = highlightNames?.includes(props.payload?.name);

    if (shouldHighlight) {
        return <ActiveDot {...props} highlightNames={highlightNames} />;
    }

    return <circle cx={props.cx} cy={props.cy} r={props.r} fill={props.fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />;
};
