import { IsoStringDate, RawDateString } from "../../helpers/date";

export const TABLE_TOTAL_KEY = "total";

export type GetByDateRange = {
    fromDateTime: Date;
    toDateTime: Date;
};

export type GetByDateRangeAndSiteIds = GetByDateRange & {
    siteIds: string[];
};

export type ToursRequestBody =
    | { siteIds: GetByDateRangeAndSiteIds["siteIds"]; fromDate: RawDateString; toDate: RawDateString }
    | { siteIds: GetByDateRangeAndSiteIds["siteIds"]; fromDateTime: IsoStringDate; toDateTime: IsoStringDate };

export interface IHighlightsCard {
    id: string;
    siteId: string;
    categoryLevel1: string;
    categoryLevel2: string;
    categoryLevel3: string;
    reportDate: Date;
    severity: string | number;
    isRead?: boolean;
}

export type CardCountItem = {
    prev: number;
    current: number;
};

export interface IBlueCards {
    accessControlCount: CardCountItem;
    accessControlSystemsCount: CardCountItem;
    medicalAssistanceCount: CardCountItem;
    confrontationCount: CardCountItem;
    equipmentAccidentCount: CardCountItem;
    reportedAuditsCount: CardCountItem;
}

export interface IHighlights {
    criticalCards: IHighlightsCard[];
    preventionCards: IHighlightsCard[];
}

export interface IKeyValuePair<Y, T> {
    name: Y;
    value: T;
}

export interface IGraphTile {
    category: string;
    top5: IKeyValuePair<string, number>[];
    bySeverityLevel: Record<string, number>;
}

export interface ITrendingEvent {
    month: number;
    [key: string]: number;
}

export type DamageReportType = {
    [siteId: string]: {
        [category: string]: number;
    };
};

export type IncidentsLocationsType = {
    name: string;
    value: number;
};

export type ExceptionType = {
    [k: string]: number;
};

export type SummaryViewMode = "day" | "month";
