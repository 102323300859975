import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const FilterIconWrapper = styled(Stack)`
    position: relative;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
    @media (max-width: ${Breakpoints.XS}) {
        margin-right: ${Spacing.XS}px;
    }
`;
