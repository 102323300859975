import { format } from "date-fns";
import { DeviationsAPIData } from "src/pages/Insights/Insights.types";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

export const parseDeviationsResponse = (deviations: DeviationsAPIData, categories: string[], monthsBack: number): ITrendingEvent[] => {
    if (!deviations.length) {
        return [];
    }

    const deviationsMap = deviations.reduce((map, { yearMonth, category, count }) => {
        if (!map[yearMonth]) {
            map[yearMonth] = {};
        }
        map[yearMonth][category] = (map[yearMonth][category] || 0) + count;
        return map;
    }, {});

    const result = [];

    const currentDate = new Date();

    for (let i = 0; i <= monthsBack; i++) {
        const yearMonth = format(currentDate, "yyyy-MM");
        const month = currentDate.getMonth() + 1;
        const monthData = { month };

        categories.forEach((category) => {
            monthData[category] = deviationsMap[yearMonth]?.[category] || 0;
        });

        result.push(monthData);
        currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return result.reverse();
};
