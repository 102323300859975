import { Text, View } from "@react-pdf/renderer";
import { Palette } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getSeverityLevelTranslationKey } from "../../../helpers/SeverityLevelHelper";
import { DynamicReportType, reportsWithCategories } from "../../../models/ReportModel";
import { styles } from "../pdfStyles";
import { Dot } from "./Icons";

type Props = {
    report: DynamicReportType;
};

export const PdfCategories = ({ report }: Props) => {
    const { t } = useTranslation();

    const hasCategory = useMemo(() => {
        return reportsWithCategories.includes(report.template) && !!report.categoryLevel1;
    }, [report]);

    const getDotStyle = (severityLevel) => {
        switch (severityLevel) {
            case 0:
            case "Info":
            case 1:
            case "Low":
                return Palette.Navy100;
            case 2:
            case "Moderate":
                return Palette.Yellow300;
            case 3:
            case "High":
                return Palette.Orange300;
            case 4:
            case "Severe":
                return Palette.Red300;
            default:
                return null;
        }
    };
    return (
        hasCategory && (
            <>
                <View style={styles.sectionLineBreak}></View>
                <View style={styles.categoriesWrapper}>
                    <View style={{ backgroundColor: getDotStyle(report.severityLevel), ...styles.severityBadge }}>
                        <Text style={styles.severityText}>{t(getSeverityLevelTranslationKey(report.severityLevel))}</Text>
                    </View>
                    <View style={styles.categoryWrapper}>
                        <Text style={styles.categoryText}>{t(`incident.category.${report.categoryLevel1}`)}</Text>
                        <Dot />
                        <Text style={styles.categoryText}>{t(`incident.category.${report.categoryLevel2}`)}</Text>
                        <Dot />
                        <Text style={styles.categoryText}>{t(`incident.category.${report.categoryLevel3}`)}</Text>
                    </View>
                </View>
            </>
        )
    );
};
