import { useTranslation } from "react-i18next";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { TextCheckboxDropdown } from "../../../../shared/components/TextCheckboxDropdown";
import { useCategoriesDropdown } from "./CategoriesDropdown.hooks";

type Props = {
    onChange?: (categories: CategoryLevel1[]) => void;
    initValue?: CategoryLevel1[];
};

export const CategoriesDropdown = ({ onChange, initValue }: Props) => {
    const { dropdownItems, selectedItems, onItemSelect } = useCategoriesDropdown(onChange, initValue);
    const { t } = useTranslation();

    return (
        <TextCheckboxDropdown items={dropdownItems} selectedItems={selectedItems} onChange={onItemSelect}>
            {t("insights.summary.categoriesSelector.title")}
        </TextCheckboxDropdown>
    );
};
