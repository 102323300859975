import { Breakpoints, ButtonText, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexRowAlignCenter } from "../../../styles/templates/Flex";

export const NavbarContainer = styled.div<{ isSticky: boolean }>`
    ${flexRowAlignCenter}
    position: relative;
    background-color: ${Palette.Navy750};
    z-index: 3;
    padding: ${Spacing.XS}px 40px;

    ${({ isSticky }) =>
        isSticky &&
        `position: sticky;
        box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.25);
        top: 0;
    `};

    @media (max-width: ${Breakpoints.M}) {
        padding-inline: ${Spacing.S}px;
    }

    @media (max-width: ${Breakpoints.S}) {
        padding-inline: ${Spacing.L}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding-inline: ${Spacing.S}px;
    }
`;

export const NavbarStickySpacing = styled.div`
    &::before {
        content: "";
        display: block;
        height: ${Spacing.S + Spacing.XS}px;

        @media (max-width: ${Breakpoints.M}) {
            height: ${Spacing.XS}px;
        }
    }
`;

export const ShareIcon = styled(ButtonText)`
    margin-left: ${Spacing.M}px;
    max-width: fit-content;
`;

export const DownloadPdfButtonWrapper = styled.div`
    min-width: max-content;
`;
