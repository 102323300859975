import { useCallback, useEffect } from "react";
import { VirtuosoHandle } from "react-virtuoso";

export const useScrollToTop = (parentRef: React.RefObject<HTMLDivElement>, virtuosoRef: React.RefObject<VirtuosoHandle>) => {
    const scrollToTop = useCallback(() => {
        virtuosoRef.current.scrollTo({ top: 0, behavior: "instant" });
    }, [virtuosoRef]);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            const virtualListContainerElement = parentRef?.current?.querySelector<HTMLDivElement>("#virtual-list-container");

            if (e.key === "Escape" && virtualListContainerElement?.contains(document.activeElement)) {
                scrollToTop();

                // first interactive element above the list
                const targetElement = parentRef?.current?.querySelector<HTMLButtonElement>("#export-to-excel button");

                if (targetElement) {
                    targetElement.focus();
                }
            }
        },
        [parentRef, scrollToTop],
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return scrollToTop;
};
