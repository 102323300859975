import { LoadingIndicator, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

const LoadingContainer = styled(Stack)`
    width: 100%;
    height: 100%;
`;

type Props = {
    size?: "S" | "M" | "L";
};

export const Loading = ({ size = "M" }: Props) => {
    return (
        <LoadingContainer justifyContent="center" alignItems="center">
            <LoadingIndicator size={size} />
        </LoadingContainer>
    );
};
