import { Box } from "@secuis/ccp-react-components";
import { Components } from "react-virtuoso";

import { SwitcherBanner } from "../../../components/ClientSwitcher/SwitcherBanner";
import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";
import { SearchAndFilter } from "./SearchAndFilter/SearchAndFilter";

export const VirtualListHeader: Components<ReportsListItemType, VirtualListContext>["Header"] = ({
    context: {
        isClientSwitcherVisible,
        isMobile,
        selectedFiltersCount,
        isLoading,
        totalCount,
        toggleFilterToolbar,
        isFilterToolbarVisible,
        closeAllAdditionalViews,
    },
}) => (
    <>
        {isClientSwitcherVisible && (
            <Box mh={isMobile ? "XS" : "0"}>
                <SwitcherBanner />
            </Box>
        )}
        <SearchAndFilter
            activeFiltersCount={selectedFiltersCount}
            reportsCount={totalCount}
            isLoading={isLoading}
            onFilterClick={toggleFilterToolbar}
            closeAllHandler={closeAllAdditionalViews}
            showFilterToolbar={isFilterToolbarVisible}
        />
    </>
);
