import { RSAA } from "redux-api-middleware";

import { USERS_GET_BY_IDS_FAILURE, USERS_GET_BY_IDS_REQUEST, USERS_GET_BY_IDS_SUCCESS } from "./types";

const getUsersByIds = (userIds: string[]) => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/users/byIds/${userIds.join(",")}`,
        method: "GET",
        types: [
            {
                meta: { userIds },
                type: USERS_GET_BY_IDS_REQUEST,
            },
            {
                meta: { userIds },
                type: USERS_GET_BY_IDS_SUCCESS,
            },
            {
                meta: { userIds },
                type: USERS_GET_BY_IDS_FAILURE,
            },
        ],
    },
});

const actions = {
    getUsersByIds,
};

export default actions;
