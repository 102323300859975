import { IReportImage } from "../../../../models/ReportImagesModels";
import { PdfImagesCarousel, PdfImagesSection } from "../../../PdfReports/shared/PdfImageSection";

export const getPdfImagesCarousel = (images: IReportImage[]) => ({
    component: PdfImagesCarousel,
    props: {
        images: images.filter((i) => i.reportFieldName?.startsWith("picture")),
    },
});

export const getPdfImagesSection = (label: string, images: IReportImage[]) => ({
    component: PdfImagesSection,
    props: {
        label,
        images: images.filter((i) => i.reportFieldName.startsWith("picture")),
    },
});
