import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { ChildrenProps } from "../../../../models/ChildrenPropModel";
import { TabContentWrapper } from "./TabContent.styles";

export const TabContent = ({ children }: ChildrenProps) => {
    const { isFilterActive } = useInsightsPageContext();

    const preventTouchPropagation = (e) => {
        // we need to prevent default SCL tab behavior
        e.stopPropagation();
    };
    return (
        <TabContentWrapper isFilterActive={isFilterActive} onTouchStart={preventTouchPropagation} onTouchEnd={preventTouchPropagation}>
            {children}
        </TabContentWrapper>
    );
};
