import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { formatDateTime, formatDateTimeWithTimezone } from "src/helpers/date";
import { DynamicReportType } from "src/models/ReportModel";
import { IUser } from "src/models/UserModel";

import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { styles } from "../pdfStyles";
import { PdfViewers } from "./PdfViewers";

type Props = {
    report: DynamicReportType;
    isShared: boolean;
    user: IUser;
};

export const PdfReportDetailsHeaderLine1 = ({ report, isShared, user }: Props) => {
    const { t } = useTranslation();
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    return (
        <>
            <View style={styles.sectionLineBreak}></View>
            <View style={styles.detailsLine}>
                <View style={styles.detailsWrappableWrapper}>
                    <Text style={styles.headerDate}>
                        {areSitesTimezonesEnabled && <>{t("reportlist.yourTime")}: </>}
                        {formatDateTime(report.reportDateTime)}
                    </Text>
                    <View style={styles.verticalDivider} />

                    {areSitesTimezonesEnabled && (
                        <>
                            <Text style={styles.headerDate}>
                                {t("reportlist.siteTime")}: {formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone)}
                            </Text>
                            <View style={styles.verticalDivider} />
                        </>
                    )}

                    <PdfViewers report={report} isShared={isShared} user={user} />
                </View>
            </View>
        </>
    );
};
