import { ITrendingEvent } from "src/store/insights/InsightsModel";

export const consolidateEvents = (events: ITrendingEvent[], deviations: ITrendingEvent[]): ITrendingEvent[] => {
    const categorySums: { [category: string]: number } = {};

    events.forEach((eventMonth) => {
        const deviationMonth = deviations.find((dev) => dev.month === eventMonth.month) || {};

        Object.keys(eventMonth).forEach((category) => {
            if (category !== "month") {
                const eventValue = eventMonth[category] || 0;
                const deviationValue = deviationMonth[category] || 0;
                const totalValue = eventValue + deviationValue;

                categorySums[category] = (categorySums[category] || 0) + totalValue;
            }
        });
    });

    const top5Categories = Object.entries(categorySums)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 5)
        .map(([category]) => category);

    const consolidatedData: ITrendingEvent[] = events.map((eventMonth) => {
        const deviationMonth = deviations.find((dev) => dev.month === eventMonth.month) || {};
        const consolidated: ITrendingEvent = { month: eventMonth.month };

        top5Categories.forEach((category) => {
            const eventValue = eventMonth[category] || 0;
            const deviationValue = deviationMonth[category] || 0;
            consolidated[category] = eventValue + deviationValue;
        });

        return consolidated;
    });

    return consolidatedData;
};
