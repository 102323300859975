import { IconType } from "@secuis/ccp-react-components";

import { HYPHEN } from "./constants";

export const getTrendingInfo = (
    previousValue: number = 0,
    currentValue: number = 0,
): {
    trendResult: string;
    iconVariant: IconType | null;
} => {
    if (previousValue + currentValue === 0) {
        return {
            trendResult: "0%",
            iconVariant: "TrendMiddle",
        };
    }
    if (previousValue === 0 && currentValue > 0) {
        return {
            trendResult: HYPHEN,
            iconVariant: "TrendGoingUp",
        };
    }
    const percentage = ((currentValue - previousValue) / previousValue) * 100;
    const trendIconVariant: IconType = percentage > 0 ? "TrendGoingUp" : percentage < 0 ? "TrendGoingDown" : "TrendMiddle";

    return {
        trendResult: `${Math.abs(Math.round(percentage))}%`,
        iconVariant: trendIconVariant,
    };
};
