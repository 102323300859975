import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const PaginationStyled = styled(Stack)`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.M}) {
        padding: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;

export const ButtonStyled = styled.button`
    height: 32px;
`;
