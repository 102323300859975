import { TouchEvent, useCallback, useRef, useState } from "react";

import { SWIPE_COEFFICIENT, SWIPE_UNCERTAINTY_THRESHOLD } from "./Swipeable.constants";
import { getTransformMatrix, getTxFromTransform } from "./Swipeable.helpers";

export const useSwipeable = (onSwipeStart?: () => void, onSwipeEnd?: () => void) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isSwipeStarted, setIsSwipeStarted] = useState<boolean>(false);
    const [contentRightWidth, setContentRightWidth] = useState<number>(0);
    const [styleTxStart, setStyleTxStart] = useState<number | null>(null);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [touchStartY, setTouchStartY] = useState<number | null>(null);
    const swipableRef = useRef<HTMLDivElement>(null);
    const containerRightRef = useRef<HTMLDivElement>(null);
    const hasRequiredRefs = Boolean(swipableRef?.current && containerRightRef?.current);

    const resetPositionState = useCallback(() => {
        setTouchStartX(null);
        setTouchStartY(null);
        setStyleTxStart(null);
        setContentRightWidth(0);
    }, [setTouchStartX, setTouchStartY, setStyleTxStart, setContentRightWidth]);

    const updateStyleTransform = useCallback(
        (tx: number) => {
            if (hasRequiredRefs) {
                swipableRef.current.style.transform = getTransformMatrix(tx);
            }
        },
        [hasRequiredRefs],
    );

    const handleClose = useCallback(() => {
        if (isOpened) {
            setIsOpened(false);
            updateStyleTransform(0);
            resetPositionState();
        }
    }, [isOpened, resetPositionState, updateStyleTransform]);

    const handleTouchStart = useCallback(
        (e: TouchEvent<HTMLDivElement>) => {
            if (!hasRequiredRefs) {
                return;
            }

            const currentTransformX = getTxFromTransform(swipableRef.current.style.transform);
            const targetTouch = e.targetTouches[0];

            setStyleTxStart(currentTransformX);
            setTouchStartX(targetTouch.clientX);
            setTouchStartY(targetTouch.clientY);
            setContentRightWidth(containerRightRef.current.clientWidth);
            setIsSwipeStarted(false);
        },
        [hasRequiredRefs],
    );

    const handleTouchMove = useCallback(
        (e: TouchEvent<HTMLDivElement>) => {
            const targetTouch = e.targetTouches[0];
            const touchEndX = targetTouch.clientX;
            const touchEndY = targetTouch.clientY;

            if (!touchStartX || !touchEndX || !contentRightWidth) {
                return;
            }

            const distance = touchStartX - touchEndX;
            const newTransformX = Math.min(styleTxStart - distance, 0);
            const isUncertainSwipe = Math.abs(distance) < SWIPE_UNCERTAINTY_THRESHOLD;
            const isSwipeCompleted = Math.abs(newTransformX) > contentRightWidth;
            const isHorizontalScroll = Math.abs(touchEndX - touchStartX) > Math.abs(touchEndY - touchStartY);

            if (isUncertainSwipe || isSwipeCompleted || !isHorizontalScroll) {
                return;
            }

            if (!isSwipeStarted) {
                setIsSwipeStarted(true);
                onSwipeStart?.();
            }

            updateStyleTransform(newTransformX);
        },
        [touchStartX, contentRightWidth, styleTxStart, touchStartY, isSwipeStarted, updateStyleTransform, onSwipeStart],
    );

    const handleTouchEnd = useCallback(() => {
        if (!touchStartX || !contentRightWidth) {
            return;
        }

        const currentTransformX = getTxFromTransform(swipableRef.current.style.transform);
        const isSwipeCompleted = Math.abs(currentTransformX) > contentRightWidth * SWIPE_COEFFICIENT;

        setIsOpened(isSwipeCompleted);
        updateStyleTransform(isSwipeCompleted ? -contentRightWidth : 0);
        resetPositionState();
        setIsSwipeStarted(false);
        onSwipeEnd?.();
    }, [touchStartX, contentRightWidth, updateStyleTransform, resetPositionState, onSwipeEnd]);

    return {
        isOpened,
        swipableRef,
        containerRightRef,
        handleClose,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
    };
};
