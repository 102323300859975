import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { ChildrenProps } from "src/models/ChildrenPropModel";

type Props = ChildrenProps & {
    featureName: (typeof featureFlags)[keyof typeof featureFlags];
    renderWhenOff?: true;
};

export const FeatureFlag = ({ featureName, children, renderWhenOff }: Props) => {
    const isFeatureEnabled = useFeatureFlag(featureName);

    return isFeatureEnabled !== !!renderWhenOff ? children : null;
};
