import { Icon, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const IconStyled = styled(Icon)`
    margin-right: ${Spacing.XS}px;
`;

export const StackStyled = styled(Stack)`
    span:not(:last-child)::after {
        content: "•";
        margin: 0 ${Spacing.XS}px;
    }
`;
