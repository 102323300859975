import { Icon } from "@secuis/ccp-react-components";

import { ButtonStyled } from "./Pagination.styles";

type NextButtonProps = {
    currentPage: number;
    totalPages: number;
    nextPage: () => void;
};

export const NextButton = ({ currentPage, totalPages, nextPage }: NextButtonProps) => {
    return currentPage < totalPages ? (
        <ButtonStyled onClick={nextPage} data-testid="matrix-pagination-next">
            <Icon variant="ArrowForward" size="S" />
        </ButtonStyled>
    ) : (
        <ButtonStyled disabled>
            <Icon variant="ArrowForward" size="S" color="disabled" data-testid="matrix-pagination-next" />
        </ButtonStyled>
    );
};
