import { ChipFilterProps } from "@secuis/ccp-react-components/dist/types/components/chipFilter/ChipFilter";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { ChipFilterStyled } from "./ChipFilter.styles";

type Props = ChipFilterProps & {
    onRemove?: (e?: MouseEvent) => void | null;
};

export const ChipFilter = ({ onRemove, ...rest }: Props) => {
    const handleKeyDown = useOnEnterSpaceKeyDown(onRemove);

    return <ChipFilterStyled onRemove={onRemove} onKeyDown={handleKeyDown} tabIndex={onRemove ? 0 : -1} role="button" {...rest} />;
};
