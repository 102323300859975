import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useMemoizedDependency } from "src/hooks/useMemoizedDependency";

type PaginationProps<T> = {
    items: T[];
    itemsPerPage: number;
};

export const usePagination = <T>({ items, itemsPerPage }: PaginationProps<T>) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const totalPages = Math.ceil(items.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState<T[]>([]);

    const memoizedItems = useMemoizedDependency(items);

    useEffect(() => {
        const { start, end } = getItemsRange(itemsPerPage);
        setCurrentItems(items.slice(start, end));
    }, [currentPage]);

    useEffect(() => {
        const { start, end } = getItemsRange(itemsPerPage);
        setCurrentItems(items.slice(start, end));
        setCurrentPage(1);
    }, [memoizedItems, itemsPerPage, isMobile]);

    const showPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const showNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const getItemsRange = (itemsPerPage: number): { start: number; end: number } => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = isMobile ? start + itemsPerPage + 1 : start + itemsPerPage;

        return {
            start,
            end,
        };
    };

    const hiddenItems = items.filter((item) => !currentItems.includes(item));

    return {
        totalPages,
        currentItems,
        hiddenItems,
        currentPage,
        showPreviousPage,
        showNextPage,
    };
};
