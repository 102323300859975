import { useSelector } from "react-redux";
import { formatToRawDate } from "src/helpers/date";
import { useApiRequest } from "src/hooks/accessApi";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { CategoryIncidentCountPerSite, DeviationsAPIData, FetchDeviationsAPIResponse } from "../types";

type UseFetchDeviationsResult = {
    deviations: CategoryIncidentCountPerSite;
    isLoading: boolean;
};

export const useFetchDeviations = (siteIds: string[], categories: string[], level: number): UseFetchDeviationsResult => {
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate).toISOString();
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate).toISOString();

    const { data, isLoading } = <FetchDeviationsAPIResponse>useApiRequest("/v2/global/insights/tour-exc/monthly-location-category-summary", "POST", {
        locationIds: siteIds,
        categories: categories.map((category) => {
            return {
                category,
                level,
            };
        }),
        fromDate: formatToRawDate(startDate),
        toDate: formatToRawDate(endDate),
    });

    const deviations: CategoryIncidentCountPerSite = Array.isArray(data) ? groupDeviationsByLocationAndCategory(data) : {};

    return {
        deviations,
        isLoading,
    };
};

const groupDeviationsByLocationAndCategory = (deviations: DeviationsAPIData): CategoryIncidentCountPerSite => {
    return deviations.reduce((acc, incident) => {
        const { locationId, category, count } = incident;

        if (!acc[locationId]) {
            acc[locationId] = {};
        }

        if (!acc[locationId][category]) {
            acc[locationId][category] = 0;
        }

        acc[locationId][category] += count;

        return acc;
    }, {});
};
