import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

export const ContainerStyled = styled.div`
    padding-inline: 40px;

    @media only screen and (max-width: ${Breakpoints.M}) {
        padding-inline: ${Spacing.M}px;
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        padding-inline: ${Spacing.S}px;
    }
`;

export const FooterStyled = styled.div`
    padding-bottom: ${Spacing.M}px;

    @media only screen and (max-width: ${Breakpoints.M}) {
        padding-bottom: ${Spacing.S}px;
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        padding-bottom: ${Spacing.XS}px;
    }
`;

export const InfoBarStyled = styled.div`
    margin-top: ${Spacing.M}px;
    padding: ${Spacing.S}px ${Spacing.M}px;
    width: 100%;
    background-color: ${Palette.Navy750};
`;

export const RowStyled = styled.div`
    padding: 0 40px ${Spacing.S}px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${Spacing.S}px;
    grid-row-gap: ${Spacing.S}px;

    @media only screen and (max-width: ${Breakpoints.L}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: ${Breakpoints.M}) {
        padding-inline: ${Spacing.M}px;
    }

    @media only screen and (max-width: ${Breakpoints.S}) {
        padding-bottom: ${Spacing.XS}px;
        grid-column-gap: ${Spacing.XS}px;
        grid-row-gap: ${Spacing.XS}px;
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        padding-inline: ${Spacing.S}px;
        grid-template-columns: repeat(1, 1fr);
    }
`;
