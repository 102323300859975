import { Stack, Text } from "@secuis/ccp-react-components";

import { getTimeValueProps } from "../../Timeline.helpers";
import { TimeValue } from "../TimeValue";
import { StackStyled } from "./LegendItem.styles";

type Props = {
    label: string;
    value: {
        hours: string;
        minutes: string;
    };
};

export const LegendItem = ({ label, value }: Props) => {
    const { hours, minutes } = value;

    return (
        <StackStyled data-testid="timeline-legend-item">
            <Text color="secondary" bold>
                {label}
            </Text>
            <Stack gap="XXS" flexWrap="wrap">
                {hours ? <TimeValue {...getTimeValueProps(hours)} insertComma /> : null}
                <TimeValue {...getTimeValueProps(minutes)} />
            </Stack>
        </StackStyled>
    );
};
