import { useLayoutEffect, useRef, useState } from "react";

export const useMaxBreadcrumbsItems = (breadcrumbsLength) => {
    const boxRef = useRef(null);
    const bcRef = useRef(null);
    const [maxItems, setMaxItems] = useState<number>(undefined);

    useLayoutEffect(() => {
        if (boxRef.current && bcRef.current && boxRef.current.clientWidth < bcRef.current.scrollWidth) {
            if (maxItems <= 0) {
                setMaxItems(undefined);
                return;
            }
            if (maxItems !== undefined) {
                setMaxItems(maxItems - 1);
                return;
            }
            setMaxItems(breadcrumbsLength - 1);
        }
    }, [setMaxItems, breadcrumbsLength, maxItems]);

    return {
        wrapperRef: boxRef,
        breadcrumbRef: bcRef,
        maxItems: maxItems,
    };
};
