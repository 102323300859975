import i18next from "i18next";
import { JSX } from "react";
import { isInDateRange } from "src/helpers/date";

import { TextCard } from "./TextCard";

export const GenerateImprovementCards = (date: Date, cardWidth: number): JSX.Element[] => {
    const improvementCards = [];
    const showImprovementCard = isInDateRange(date, 25, 7);

    if (showImprovementCard) {
        improvementCards.push(
            <TextCard
                id="25thImprovementCard"
                headerText={i18next.t("insights.improvements.25th.header")}
                descriptionText={i18next.t("insights.improvements.25th.description")}
                tooltipText={i18next.t("insights.improvements.25th.tooltip")}
                cardWidth={cardWidth}
                key="improvement_25th_quick_card"
            />,
        );
    }

    return improvementCards;
};
