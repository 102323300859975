import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Spacing } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { CalloutLog } from "src/models/ReportModel";

import { useTimeline } from "../Timeline/Timeline.hooks";
import { PdfLegendItem, PdfTimelineEvent } from "./components";

const styles = StyleSheet.create({
    sectionWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flex: "1",
    },
    timelineWrapper: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
    },
    legend: {
        display: "flex",
        flexDirection: "column",
        rowGap: Spacing.XS,
    },
});

type Props = {
    fieldValue: { logs: CalloutLog[] };
    timeZone?: string;
    shouldAutoTranslate?: boolean;
};

export const PdfTimeline = ({ fieldValue, shouldAutoTranslate, timeZone }: Props) => {
    const { t } = useTranslation();
    const { timeOneSite, responseTime, timelineItems } = useTimeline(fieldValue, shouldAutoTranslate, timeZone, true);

    return (
        <View wrap={false} style={styles.sectionWrapper}>
            {timelineItems.length ? (
                <>
                    <View style={styles.timelineWrapper}>
                        {timelineItems.map(({ dateTime, enableAutoTranslate, iconType, key, lineColor, status }) => (
                            <PdfTimelineEvent
                                key={key}
                                status={status}
                                lineColor={lineColor}
                                shouldAutoTranslate={enableAutoTranslate}
                                dateTime={dateTime}
                                iconType={iconType}
                            />
                        ))}
                    </View>
                    <View style={styles.legend}>
                        {responseTime && timeOneSite && <PdfLegendItem label={t("reportdetails.callout.responseTime")} value={responseTime} />}
                        {timeOneSite && <PdfLegendItem label={t("reportdetails.callout.timeOneSite")} value={timeOneSite} />}
                    </View>
                </>
            ) : (
                <Text>{t("common.noDataFound")}</Text>
            )}
        </View>
    );
};
