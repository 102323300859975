import { getExceptionQuestionsAnswers } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { TextSummaryField } from "./TextSummaryField";

export const ExceptionQuestionsFields = ({ fieldValue, shouldAutoTranslate = true }) => {
    const exceptionQuestions = fieldValue ? getExceptionQuestionsAnswers(fieldValue ?? "") : [];

    if (!fieldValue) return <></>;

    return (
        <>
            {exceptionQuestions.map(([question, answer]) => {
                if (question)
                    return (
                        <TextSummaryField
                            key={question}
                            fieldName={question}
                            fieldValue={answer}
                            shouldAutoTranslate={shouldAutoTranslate}
                            desktopDirection="row"
                            mobileDirection="column"
                            mobileValueAlignment="end"
                        />
                    );
            })}
        </>
    );
};
