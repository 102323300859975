/**
 * Turns body object into FormData for an RSAA request
 */

import { RSAA } from "redux-api-middleware";

const apiAccessMiddleware = () => (next) => async (action) => {
    const callAPI = action[RSAA];

    if (callAPI && typeof callAPI.body === "object" && Object.keys(callAPI.body).length !== 0) {
        return next({
            [RSAA]: {
                ...callAPI,
                body: JSON.stringify(callAPI.body),
            },
        });
    }

    return next(action);
};
export default apiAccessMiddleware;
