import { Text } from "@secuis/ccp-react-components";

type Props = {
    cell: string;
};

export const DescriptionCell = ({ cell }: Props) => {
    return (
        <Text small truncate>
            {cell || "-"}
        </Text>
    );
};
