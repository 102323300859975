import { nullable, primaryKey } from "@mswjs/data";

export const Report = {
    categoryLevel1: nullable(String),
    categoryLevel2: nullable(String),
    categoryLevel3: nullable(String),
    checkpointScans: Array,
    checkpointsMissedTotal: Number,
    checkpointsScannedTotal: Number,
    checkpointsTotal: Number,
    childTemplate: String,
    commentsCount: Number,
    countryCode: String,
    createDateTime: Number,
    siteTimestamp: Number,
    durationMinutes: Number,
    endDateTime: Date,
    exceptionReportIds: Array,
    id: primaryKey(String),
    imageCount: Number,
    images: Array,
    locationId: String,
    performedBy: String,
    properties: Array,
    reportDateTime: Date,
    reportedBy: String,
    severityLevel: nullable(Number),
    siteLocation: String,
    siteIanaTimezone: String,
    siteLocationSubLevels: String,
    sourceId: String,
    startDateTime: Date,
    submitDateTime: Date,
    subType: String,
    template: String,
    tourName: String,
    tourSourceId: String,
    type: String,
    visitorIds: Array,
    isRead: Boolean,
};

export default Report;
