import { Box, Text } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWebHubGlobalUrl } from "src/hooks/WebHubHooks";

import { NotificationType } from "../../../models/NotificationModel";
import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../../models/ReportModel";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { LoginContainer, LoginDetailsUnderline, LoginWrapper, NativeLockedIcon } from "./Login.styles";

type Props = {
    report: DynamicReportType | PatrolTourType;
    siteObject: ISiteObject;
};

export const Login = ({ report, siteObject }: Props) => {
    const { t } = useTranslation();
    const webHubGlobalUrl = useWebHubGlobalUrl();

    const getContext = useCallback(() => {
        const isTour = isPatrolTour(report);
        if (isTour) {
            return {
                tourSessionId: report?.id,
                locationId: siteObject?.id,
                type: NotificationType.TourSessionShareRedirect,
            };
        }
        return {
            reportId: report?.id,
            locationId: siteObject?.id,
            type: NotificationType.ReportShareRedirect,
        };
    }, [report, siteObject]);

    const redirectToHub = useCallback(() => {
        const contextObj = getContext();
        const context = window.btoa(JSON.stringify(contextObj));
        const targetUrl = `${webHubGlobalUrl}/global?c=${context}`;
        window.location.href = targetUrl;
    }, [getContext, webHubGlobalUrl]);

    return (
        <LoginContainer>
            <Box mv="S" ml="XS">
                <Text color="secondary" micro uppercase>
                    {t("common.login.title")}
                </Text>
            </Box>
            <LoginWrapper>
                <Text small>
                    {t("common.login.title")}&nbsp;
                    <LoginDetailsUnderline onClick={redirectToHub}>{t("common.here")}</LoginDetailsUnderline>
                    &nbsp;{t("common.login.viewthefullreport")}
                </Text>
                <NativeLockedIcon variant="Locked" />
            </LoginWrapper>
        </LoginContainer>
    );
};
