import { RSAA } from "redux-api-middleware";

import {
    ACCESS_TOKEN_FAILURE,
    ACCESS_TOKEN_REFRESH_FAILURE,
    ACCESS_TOKEN_REFRESH_REQUEST,
    ACCESS_TOKEN_REFRESH_SUCCESS,
    ACCESS_TOKEN_REQUEST,
    ACCESS_TOKEN_SUCCESS,
    AUTH_PROMOTE_SESSION_TOKEN_FAILURE,
    AUTH_PROMOTE_SESSION_TOKEN_REQUEST,
    AUTH_PROMOTE_SESSION_TOKEN_SUCCESS,
} from "./types";

const getAccessToken = (idToken: string) => {
    return {
        [RSAA]: {
            accessless: true,
            endpoint: `${process.env.REACT_APP_API_ENDPOINT}/v1/authenticationToken`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            types: [ACCESS_TOKEN_REQUEST, ACCESS_TOKEN_SUCCESS, ACCESS_TOKEN_FAILURE],
        },
    };
};

const refreshAccessToken = (oldAccessToken: string) => ({
    [RSAA]: {
        refresh: true,
        endpoint: `/v1/authenticationTokenByToken`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${oldAccessToken}`,
        },
        types: [ACCESS_TOKEN_REFRESH_REQUEST, ACCESS_TOKEN_REFRESH_SUCCESS, ACCESS_TOKEN_REFRESH_FAILURE],
    },
});

const promoteSessionToken = (deviceToken: string) => {
    return {
        [RSAA]: {
            endpoint: `/v1/promoteSessionTokenByDeviceToken`,
            auth: true,
            silent: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: { deviceToken },
            method: "POST",
            types: [AUTH_PROMOTE_SESSION_TOKEN_REQUEST, AUTH_PROMOTE_SESSION_TOKEN_SUCCESS, AUTH_PROMOTE_SESSION_TOKEN_FAILURE],
        },
    };
};

const getAccessTokenBySignOnToken = (token: string) => {
    return {
        [RSAA]: {
            endpoint: `/v1/authenticationTokenBySignOnToken`,
            auth: true,
            silent: true,
            body: { token },
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            types: [ACCESS_TOKEN_REQUEST, ACCESS_TOKEN_SUCCESS, ACCESS_TOKEN_FAILURE],
        },
    };
};

export default {
    getAccessToken,
    refreshAccessToken,
    promoteSessionToken,
    getAccessTokenBySignOnToken,
};
