import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RequestStatus } from "../RequestStatus";
import ReportCategoriesActions from "./ReportCategoriesActions";
import ReportCategoriesSelectors from "./ReportCategoriesSelectors";
import { IReportCategoryLevel1 } from "./types";

export const useReportCategories = (): {
    reportCategories: IReportCategoryLevel1[];
    fetchStatus: RequestStatus;
    loadReportCategories: () => void;
} => {
    const dispatch = useDispatch();
    const reportCategoriesState = useSelector(ReportCategoriesSelectors.getReportCategories);

    const loadReportCategories = useCallback(() => {
        if (reportCategoriesState.status === RequestStatus.undefined) {
            dispatch(ReportCategoriesActions.fetchReportCategories());
        }
    }, [dispatch, reportCategoriesState.status]);

    return {
        loadReportCategories,
        reportCategories: reportCategoriesState?.reportCategoriesLevel1 ?? [],
        fetchStatus: reportCategoriesState?.status ?? RequestStatus.undefined,
    };
};
