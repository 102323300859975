import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoTranslatedContentFooter, AutoTranslationProvider } from "src/helpers/autoTranslations";

import { getNodesHeight } from "../../../helpers/ReportHelper";
import { Section, type SectionProps } from "./Section";

const DEFAULT_ITEMS_COUNT = 3;

type Props = SectionProps & {
    itemsToDisplay?: number;
    rightHeader?: JSX.Element;
    setLeftOffsetHandler?: (offset: number) => void;
    fullyTranslated?: boolean;
};

export const DynamicTemplateSection: React.FC<Props> = ({
    label,
    children,
    collapsedLabel,
    expandedLabel,
    itemsToDisplay = DEFAULT_ITEMS_COUNT,
    rightHeader,
    fullyTranslated = false,
    setLeftOffsetHandler,
}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [maxHeight, setMaxHeight] = useState<string>();
    const [animationEnabled, setAnimationEnabled] = useState<boolean>(false);
    const [sectionHeight, setSectionHeight] = useState<number>();
    const sectionRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();

    const expand = useCallback(() => {
        setIsCollapsed(false);
        setAnimationEnabled(true);
    }, []);
    const collapse = useCallback(() => {
        setIsCollapsed(true);
    }, []);

    const handleResize = useCallback((height: number) => {
        setSectionHeight(height);
    }, []);

    useLayoutEffect(() => {
        let sectionItemsCount = 0;
        let visibleContentHeight;
        let nodes: ChildNode[];
        if (sectionRef.current) {
            const sectionItems = sectionRef.current.childNodes[0].childNodes;
            nodes = [...sectionItems].slice(0, itemsToDisplay);
            sectionItemsCount = sectionItems.length;
        }
        if (sectionItemsCount > itemsToDisplay) {
            visibleContentHeight = getNodesHeight(nodes);
            setMaxHeight(`${visibleContentHeight}px`);
        }
    }, [itemsToDisplay, sectionHeight]);

    useEffect(() => {
        if (setLeftOffsetHandler) setLeftOffsetHandler(sectionRef.current?.offsetLeft);
    }, [setLeftOffsetHandler]);

    const renderFooter = () => <AutoTranslatedContentFooter partiallyTranslated={!fullyTranslated} />;

    return (
        <AutoTranslationProvider id={label}>
            <Section
                data-testid="template-section"
                collapsed={isCollapsed}
                label={t(label)}
                collapsedLabel={t(collapsedLabel)}
                expandedLabel={t(expandedLabel)}
                onCollapse={collapse}
                onExpand={expand}
                maxHeight={maxHeight}
                ref={sectionRef}
                rightHeader={rightHeader}
                listAttachment={renderFooter()}
                onResize={handleResize}
                animationEnabled={animationEnabled}
            >
                {children}
            </Section>
        </AutoTranslationProvider>
    );
};
