import { Breakpoints, ButtonText, Icon, Palette, Spacing } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

import { Tile } from "../../../../components/Insights/common/Tile.styled";

export const TileWrapper = styled.div<{ isCollapsed: boolean; maxHeight: number }>`
    max-height: ${({ maxHeight, isCollapsed }): string => (isCollapsed ? `${maxHeight}px` : null)};
    overflow-y: hidden;
    border-radius: ${({ isCollapsed }): string => (isCollapsed ? `${Spacing.XXS}px` : `${Spacing.XXS}px ${Spacing.XXS}px 0 0`)};
    position: relative;
`;

export const StyledTile = styled(Tile)`
    width: auto;
    padding-bottom: ${Spacing.S}px;
    overflow: hidden;
    border-radius: 0;

    @media (max-width: ${Breakpoints.XS}) {
        padding-bottom: ${Spacing.M}px;
    }
`;

export const StyledTileHeader = styled.div`
    & > div,
    span,
    svg {
        display: inline;
        vertical-align: middle;
    }
    & > button {
        float: right;
        height: 20px;
        align-items: end;
    }
    padding-left: ${Spacing.XXS}px;
    padding-bottom: 28px;

    @media (max-width: ${Breakpoints.XS}) {
        padding-top: ${Spacing.XS}px;
        padding-bottom: ${Spacing.XS + Spacing.XXS}px;
    }
`;

export const Dot = styled.span`
    margin: 0 ${Spacing.XS}px;
`;

export const SiteCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        gap: ${Spacing.XS}px;
    }
`;

export const ShowMoreWrapper = styled.div<{ isCollapsed: boolean }>`
    position: ${({ isCollapsed }): string => (isCollapsed ? "absolute" : null)};
    bottom: 0;
    padding: ${({ isCollapsed }) => (isCollapsed ? Spacing.M : Spacing.XS)}px 0 ${Spacing.S}px;
    width: 100%;
    z-index: 1;
    border-radius: 0 0 ${Spacing.XXS}px ${Spacing.XXS}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ isCollapsed }): string =>
        isCollapsed ? `linear-gradient(0deg, ${Palette.Navy750} 0%, ${Palette.Navy750} 60%, transparent 100%)` : `${Palette.Navy750}`};

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        padding: 2px 0 ${Spacing.S}px;
    }
`;

export const StyledButton = styled(ButtonText)`
    width: auto;

    &:focus-visible {
        outline-offset: ${FocusStyles.OutlineOffset}px;
        border-radius: ${FocusStyles.BorderRadius}px;
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 12px;
`;

export const StyledSection = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
`;
