import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";

import { ChevronLeftContainer, ChevronRightContainer, ChevronStyled } from "./CarouselChevron.styles";

type Props = {
    direction: "left" | "right";
};

const Chevron = ({ direction }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    return (
        <>
            {direction === "left" && (
                <ChevronLeftContainer>
                    <ChevronStyled data-testid="arrow-back" variant="ArrowBack" size={isMobile ? "S" : "XL"} />
                </ChevronLeftContainer>
            )}
            {direction === "right" && (
                <ChevronRightContainer>
                    <ChevronStyled data-testid="arrow-forward" variant="ArrowForward" size={isMobile ? "S" : "XL"} />
                </ChevronRightContainer>
            )}
        </>
    );
};

export default Chevron;
