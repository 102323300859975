import { useDispatch, useSelector } from "react-redux";
import { Switcher } from "src/components/shared/Switcher";
import InsightsActions from "src/store/insights/InsightsActions";
import type { SummaryViewMode } from "src/store/insights/InsightsModel";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { SUMMARY_VIEW_MODE_OPTIONS } from "../Summary.constants";

export const ViewModeSwitcher = () => {
    const dispatch = useDispatch();
    const summaryViewMode = useSelector(InsightsSelectors.getSummaryViewMode);

    const handleChange = (value: SummaryViewMode) => {
        dispatch(InsightsActions.setSummaryViewMode(value));
    };

    return <Switcher data-testid="summary-viewmode-switch" value={summaryViewMode} options={SUMMARY_VIEW_MODE_OPTIONS} onChange={handleChange} />;
};
