import { ButtonText, Stack } from "@secuis/ccp-react-components";
import { styled } from "styled-components";

export const ButtonTextStyled = styled(ButtonText).attrs({
    color: "primary",
    iconSize: "L",
    direction: "column",
    small: true,
})``;

export const WrapperStyled = styled(Stack).attrs({
    justifyContent: "center",
    alignItems: "center",
})`
    height: 100%;
    min-width: 72px;
`;
