import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { featureFlags } from "../../../data/featureFlags";
import { endOfDay, formatToRawDate, startOfDay, subDays, subMonths } from "../../../helpers/date";
import { useFetchApi } from "../../../hooks/accessApi";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { ISearchReportsRequestBody, ISearchReportsRequestBodyOld } from "../../../models/ReportFilterModels";
import { ReportTemplateEnum } from "../../../models/ReportModel";
import { IState } from "../../../Store";
import AccessSelectors from "../../../store/access/AccessSelectors";
import InsightsActions from "../../../store/insights/InsightsActions";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";

export const useIsSummaryMainTab = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isOpeningInsights = ["/", "/insights"].includes(pathname);
    const isClientVerified = useSelector(({ insights }: IState) => insights.summaryState.isClientVerified);
    const isSummaryEnabled = useFeatureFlag(featureFlags.kpiSummary);
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const clientSites = useSelector(LocationsSelectors.getSiteObjects);
    const today = new Date();
    const threeMonthsAgo = startOfDay(subMonths(today, 3));
    const yesterday = endOfDay(subDays(today, 1));

    const userId = useSelector(AccessSelectors.getUserId);
    const fetch = useFetchApi();

    let reportsApiVersion: "v1" | "v2";
    let dateParams:
        | Pick<ISearchReportsRequestBody, "from" | "to">
        | Pick<ISearchReportsRequestBodyOld, "reportDateTimeStart" | "reportDateTimeEnd">
        | undefined;

    if (areSiteZonesEnabled) {
        reportsApiVersion = "v2";
        dateParams = {
            from: formatToRawDate(threeMonthsAgo),
            to: formatToRawDate(yesterday),
        };
    } else {
        reportsApiVersion = "v1";
        dateParams = {
            reportDateTimeStart: threeMonthsAgo.toISOString(),
            reportDateTimeEnd: yesterday.toISOString(),
        };
    }

    useEffect(() => {
        const checkClient = async () => {
            const response = await fetch(`/${reportsApiVersion}/global/reports/search?size=0`, "POST", {
                userId,
                locationIds: clientSites.map(({ id }) => id),
                excludedReportTypes: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
                ...dateParams,
            });
            const clientData = (await response.json()) as { totalCount: number };

            if (isSummaryEnabled && clientData.totalCount === 0) {
                dispatch(InsightsActions.setSelectedTab(1));
            }

            dispatch(InsightsActions.confirmSummaryClientVerification(clientData.totalCount > 0));
        };

        if (clientSites.length && isOpeningInsights && !isClientVerified) {
            checkClient();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientSites]);
};
