import { faker } from "@faker-js/faker";
import { add, isToday, subDays } from "date-fns";
import { sample, shuffle } from "lodash";

import { CheckpointScanResult, CheckpointScanType, ReportTemplateEnum } from "../../models/ReportModel";
import { ISiteObject } from "../../models/SiteObjectModel";
import { EXCEPTION_QUESTIONS, SITE_LOCATIONS, TOUR_CHECKPOINTS } from "../data";
import db from "../db";
import { getDaysListForPastMonths, getFakeSiteTimestamp, getSiteTimezone, pickRandomNthElement } from "../helpers";
import { Report, SiteObjects } from "../types";
import createException from "./createException";

const CHECKPOINT_SCAN_DURATION_IN_MINUTES = 5;

type ToursConfig = {
    exceptionFreq: number;
    maxExceptionCount: number;
};

const createTours = (toursConfig: ToursConfig, sites: SiteObjects, howManyMonths: number) => {
    if (howManyMonths <= 0) {
        return;
    }

    const tours: Report[] = [];
    const dates = getDaysListForPastMonths(howManyMonths);
    const hasManySites = sites.length > 1;

    for (const date of dates) {
        sites.forEach((site) => {
            const siteName = hasManySites ? `| ${site.displayName}` : "";

            // morning patrol
            tours.push(createTour(`Morning Patrol ${siteName}`, site, generateTourDateAndTime(date, 5, 10)));

            // office patrol - randomly generated 50/50
            if (Math.random() > 0.5) {
                tours.push(createTour(`Office Patrol ${siteName}`, site, generateTourDateAndTime(date, 16, 20)));
            }

            // night patrol
            tours.push(createTour(`Night Patrol ${siteName}`, site, generateTourDateAndTime(date, 0, 3)));
        });
    }

    const randomNthTours = pickRandomNthElement(tours, toursConfig.exceptionFreq);

    tours.forEach((patrol, index) => {
        const patrolExceptions: string[] = [];

        if (randomNthTours.includes(index) && toursConfig.exceptionFreq !== 0) {
            const exceptionType = Math.random() > 0.3 ? ReportTemplateEnum.tourException : ReportTemplateEnum.tourMultiException;

            if (exceptionType === ReportTemplateEnum.tourMultiException) {
                const exceptionQuestions = getExceptionQuestions(faker.number.int({ min: 2, max: toursConfig.maxExceptionCount }));

                patrolExceptions.push(generateTourException(tours[index], exceptionType, exceptionQuestions));
            } else if (exceptionType === ReportTemplateEnum.tourException) {
                const exceptionQuestions = getExceptionQuestions(faker.number.int({ min: 1, max: toursConfig.maxExceptionCount }));
                const exceptionCount = exceptionQuestions.length;

                for (let i = 0; i < exceptionCount; i++) {
                    patrolExceptions.push(generateTourException(tours[index], exceptionType, [exceptionQuestions[i]]));
                }
            }
        }
        // connect tour with exceptions
        patrol.properties.push({
            key: "exceptionIds",
            value: JSON.stringify(patrolExceptions),
        });

        patrol.exceptionReportIds = patrolExceptions;

        try {
            db.reports.create(patrol);
        } catch (error) {
            throw new Error(`Failed to create the tour: ${error}`);
        }
    });
};

export const createTour = (name: string, siteObject: ISiteObject, datetime: number): Report => {
    const dateISOString = new Date(datetime).toISOString();
    const checkpoints = getCheckpoints(datetime, TOUR_CHECKPOINTS, CHECKPOINT_SCAN_DURATION_IN_MINUTES);
    const checkpointsMissedTotal = faker.number.int({ min: 0, max: 2 });
    const checkpointsTotal = checkpoints.length + checkpointsMissedTotal;
    const duration = checkpoints.length * CHECKPOINT_SCAN_DURATION_IN_MINUTES;
    const siteLocation = sample(SITE_LOCATIONS);

    const properties = [
        {
            key: "name",
            value: name,
        },
        {
            key: "tourStartDate",
            value: dateISOString,
        },
        {
            key: "missedCheckpointsTotal",
            value: checkpointsMissedTotal.toString(),
        },
        {
            key: "checkpointsTotal",
            value: checkpointsTotal.toString(),
        },
    ];

    const siteIanaTimezone = getSiteTimezone(siteObject);

    return {
        id: faker.string.uuid(),
        locationId: siteObject.id,
        siteLocation: siteLocation,
        siteIanaTimezone,
        commentsCount: 0,
        imageCount: 0,
        createDateTime: datetime,
        siteTimestamp: getFakeSiteTimestamp(datetime, siteIanaTimezone),
        reportDateTime: dateISOString,
        submitDateTime: dateISOString,
        countryCode: siteObject.countryCode,
        type: ReportTemplateEnum.patrolTour,
        subType: "",
        severityLevel: null,
        properties,
        sourceId: name,
        tourSourceId: `tour_${name}`,
        tourName: name,
        exceptionReportIds: [],
        visitorIds: [],
        startDateTime: dateISOString,
        endDateTime: add(new Date(datetime), { minutes: duration }).toISOString(),
        durationMinutes: duration,
        checkpointScans: checkpoints,
        checkpointsTotal: checkpoints.length + checkpointsMissedTotal,
        checkpointsScannedTotal: checkpoints.length,
        checkpointsMissedTotal: checkpointsMissedTotal,
        performedBy: faker.person.firstName() + " " + faker.person.lastName(),
        isRead: false,
    };
};

/**
 *
 * @param tour tour object that we want to generate an exception for
 * @returns created exception id as a string
 */
export const generateTourException = (
    tour: Report,
    type: ReportTemplateEnum.tourException | ReportTemplateEnum.tourMultiException,
    questions: string[],
): string => {
    const patrolName = tour.properties.find((property) => property.key === "name")?.value;
    const siteObject = { id: tour.locationId, countryCode: tour.countryCode };
    const checkpointName = sample(tour.checkpointScans);
    const createdAt = tour.createDateTime;

    return createException(type, patrolName, checkpointName, questions, siteObject, createdAt);
};

export const generateTourDateAndTime = (timestamp: number, minHour: number, maxHour: number): number => {
    if (minHour < 0 || minHour > 23 || maxHour < 0 || maxHour > 23 || minHour > maxHour) {
        throw new Error("Invalid hour range: hours must be between 0 and 23 and minHour must be less than maxHour.");
    }
    // do not generate future tours and exceptions
    if (isToday(timestamp)) {
        if (new Date().getHours() < 2) {
            timestamp = subDays(timestamp, 1).getTime();
        } else {
            maxHour = new Date().getHours() - 1;
            minHour = Math.min(minHour, maxHour);
        }
    }

    return new Date(timestamp).setHours(faker.number.int({ min: minHour, max: maxHour }), sample([0, 15, 30, 45]));
};

/**
 *
 * @param datetime date and time of the tour
 * @param names array of available checkpoint names
 * @param scanDuration duration of the checkpoint scan in minutes
 * @returns array of checkpoint scans
 */
export const getCheckpoints = (datetime: number, names: string[], singleScanDuration: number): CheckpointScanType[] => {
    if (names.length === 0) {
        return [];
    }

    const checkpointNames = shuffle(names).slice(0, faker.number.int({ min: 1, max: names.length }));

    return checkpointNames.map((name, index) => {
        return {
            checkpointId: faker.string.uuid(),
            checkpointScanId: faker.string.uuid(),
            checkpointSortOrder: index + 1,
            scannedBy: faker.person.firstName() + " " + faker.person.lastName(),
            scanDateTime: add(new Date(datetime), { minutes: index * singleScanDuration }).toISOString(),
            checkpointName: name,
            result: CheckpointScanResult.scanned,
        };
    });
};

export const getExceptionQuestions = (count: number): string[] => {
    if (count === 0) {
        return [];
    }

    const availableQuestions = shuffle(EXCEPTION_QUESTIONS);
    const maxNumber = count > availableQuestions.length ? availableQuestions.length : count;

    return availableQuestions.slice(0, maxNumber);
};

export default createTours;
