import { Box } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { LabelTextStyled, StackStyled } from "./SitesNumbers.styles";
import type { ISiteGroup } from "./types";

export type ISiteGroupAncestor = Pick<ISiteGroup, "id" | "descendantSitesCount">;

type Props = {
    selectedGroupAncestors: ISiteGroupAncestor[];
};

export const SitesNumbers = ({ selectedGroupAncestors }: Props) => {
    const { t } = useTranslation();

    const renderItem = (group: ISiteGroupAncestor, index: number) => {
        return (
            <LabelTextStyled data-testid="sites-numbers-item" key={group.id} color={index > 0 ? "tertiary" : "secondary"} isDotVisible={index > 0} small bold>
                {group.descendantSitesCount} {group.descendantSitesCount === 1 ? t("sites.site") : t("sites.sites")}
            </LabelTextStyled>
        );
    };

    return (
        <Box data-testid="sites-numbers">
            <StackStyled data-testid="sites-number" alignItems="center" gap="S" flexWrap="wrap">
                {selectedGroupAncestors.map((group, index) => renderItem(group, index))}
            </StackStyled>
        </Box>
    );
};
