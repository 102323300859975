import styled from "styled-components";

export const ModalFixedWrapperStyled = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
`;
