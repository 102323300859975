import { ISiteObject } from "../models/SiteObjectModel";

export const getSitesObjectWithUniqueNames = (sites: ISiteObject[]): ISiteObject[] => {
    return sites.reduce((sitesAcc, site) => {
        if (sitesAcc.find((s) => s.id === site.id)) {
            return sitesAcc;
        }
        if (sites.some((s) => s.id !== site.id && s.name === site.name)) {
            sitesAcc.push({ ...site, displayName: `${site.city} ${site.name}` });
        } else {
            sitesAcc.push(site);
        }
        return sitesAcc;
    }, [] as ISiteObject[]);
};

export const getGroupsTree = (groups, locations: ISiteObject[], parentId?) => {
    const parents = groups
        .filter((obj) => (parentId ? obj.parentId === parentId : groups.some((x) => x.parentId === obj.id) || !groups.some((x) => obj.parentId === x.id)))
        .filter((value, _, self) => !self.some((x) => value.parentId === x.id));

    return parents
        .map((parent) => {
            const children = groups.filter((obj) => obj.parentId === parent.id);
            const filteredLocations = parent.locationIds
                .map((loc) => (locations.some((x) => x.id === loc) ? { label: locations.find((x) => x.id === loc).displayName, value: loc } : null))
                .filter((x) => x);
            const childrenTree = children.length > 0 ? getGroupsTree(groups, locations, parent.id) : [];
            const combinedChildrenTree = [...filteredLocations, ...childrenTree];

            if (!combinedChildrenTree.length) return null;

            const sortedChildren = combinedChildrenTree.sort((a, b) => a.label.localeCompare(b.label));
            const parentWithChildren = { label: parent.name, value: parent.id, children: sortedChildren };
            const childrenWithParent = sortedChildren.map((child) => ({ ...child, parent: parentWithChildren }));

            return { label: parent.name, value: parent.id, children: childrenWithParent };
        })
        .filter((x) => x)
        .sort((a, b) => a.label.localeCompare(b.label));
};
