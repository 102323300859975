import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SymbolsUsedTooltipContent } from "../SymbolsUsedTooltipContent";

export const TooltipContent = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" gap="XS">
            <span>{t("insights.siteTasks.tooltip.toursSectionTextTop")}</span>
            <span>{t("insights.siteTasks.tooltip.toursSectionTextBottom")}</span>
            <SymbolsUsedTooltipContent />
        </Stack>
    );
};
