import styled from "styled-components";

export const WrapperStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: transform 0.25s cubic-bezier(0.15, 0.3, 0.25, 1);
    will-change: transform;
    transform: translate(0, 0);
`;

export const SwipeableElementStyled = styled.div`
    width: 100%;
    flex-shrink: 0;
`;

export const SwipeableContainerRightStyled = styled.div`
    flex-shrink: 0;
`;
