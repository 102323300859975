import { Palette } from "@secuis/ccp-react-components";

import { ISiteObject } from "../../../models/SiteObjectModel";
import {
    getClusterCircleRadius,
    getClusterCountDefaultStyles,
    getClustersPaint,
    getCountClusterActiveStyles,
    getIncidentCountStyles,
    LOCATIONS_SOURCE,
} from "./mapConfig";

export const highlightMarkerHandler = (map: any, id: string, isMobile: boolean) => {
    if (id) {
        map.setPaintProperty("clusters", "circle-radius", [
            "case",
            ["in", id, ["string", ["get", "markersIds"]]],
            ["step", ["get", "point_count"], ...getClusterCircleRadius(isMobile, true)],
            ["step", ["get", "point_count"], ...getClusterCircleRadius(isMobile, false)],
        ]);
        map.setPaintProperty("clusters", "circle-color", ["case", ["in", id, ["string", ["get", "markersIds"]]], `${Palette.Navy800}`, `${Palette.Navy750}`]);
        map.setLayoutProperty("markers", "icon-size", ["match", ["string", ["get", "id"]], id, 1.2, 1]);
        map.setLayoutProperty("clusterIncidentCountBadge", "icon-offset", [
            "case",
            ["in", id, ["string", ["get", "markersIds"]]],
            ...getCountClusterActiveStyles(isMobile)["icon-offset"],
        ]);
        map.setLayoutProperty("clusterIncidentCountBadge", "text-offset", [
            "case",
            ["in", id, ["string", ["get", "markersIds"]]],
            ...getCountClusterActiveStyles(isMobile)["text-offset"],
        ]);
        map.setLayoutProperty("incidentCountBadge", "text-offset", getIncidentCountStyles(id)["text-offset"]);
        map.setLayoutProperty("incidentCountBadge", "icon-offset", getIncidentCountStyles(id)["icon-offset"]);
    } else {
        map.setPaintProperty("clusters", "circle-radius", getClustersPaint(isMobile)["circle-radius"]);
        map.setPaintProperty("clusters", "circle-color", getClustersPaint(isMobile)["circle-color"]);
        map.setLayoutProperty("markers", "icon-size", 1);
        map.setLayoutProperty("clusterIncidentCountBadge", "text-offset", getClusterCountDefaultStyles(isMobile)["text-offset"]);
        map.setLayoutProperty("clusterIncidentCountBadge", "icon-offset", getClusterCountDefaultStyles(isMobile)["icon-offset"]);
        map.setLayoutProperty("incidentCountBadge", "text-offset", getIncidentCountStyles()["text-offset"]);
        map.setLayoutProperty("incidentCountBadge", "icon-offset", getIncidentCountStyles()["icon-offset"]);
    }
};

export const registerMapHoverHandlers = (map: any, hoveredCluster: string, isMobile: boolean) => {
    if (isMobile) {
        return;
    }
    map.on("mouseenter", "markers", (e) => {
        map.getCanvas().style.cursor = "pointer";
        map.setLayoutProperty("markers", "icon-size", ["match", ["string", ["get", "id"]], e.features[0].properties.id, 1.2, 1]);
        map.setLayoutProperty("incidentCountBadge", "text-offset", getIncidentCountStyles(e.features[0].properties.id)["text-offset"]);
        map.setLayoutProperty("incidentCountBadge", "icon-offset", getIncidentCountStyles(e.features[0].properties.id)["icon-offset"]);
    });
    map.on("mouseleave", "markers", () => {
        map.getCanvas().style.cursor = "";
        map.setLayoutProperty("incidentCountBadge", "text-offset", getIncidentCountStyles()["text-offset"]);
        map.setLayoutProperty("incidentCountBadge", "icon-offset", getIncidentCountStyles()["icon-offset"]);
        map.setLayoutProperty("markers", "icon-size", 1);
    });
    map.on("mouseenter", "clusters", (e) => {
        map.getCanvas().style.cursor = "pointer";
        if (e.features.length > 0) {
            if (hoveredCluster !== null) {
                map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { hover: false });
            }
            hoveredCluster = e.features[0].id;
            map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { hover: true });

            map.setLayoutProperty("clusterIncidentCountBadge", "text-offset", [
                "match",
                ["coalesce", ["get", "cluster_id"], 0],
                hoveredCluster,
                ...getCountClusterActiveStyles(isMobile)["text-offset"],
            ]);
            map.setLayoutProperty("clusterIncidentCountBadge", "icon-offset", [
                "match",
                ["coalesce", ["get", "cluster_id"], 0],
                hoveredCluster,
                ...getCountClusterActiveStyles(isMobile)["icon-offset"],
            ]);
        }
    });
    map.on("mouseleave", "clusters", () => {
        if (hoveredCluster !== null) {
            map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { hover: false });
            if (!isMobile) {
                map.setLayoutProperty("clusterIncidentCountBadge", "text-offset", getClusterCountDefaultStyles(isMobile)["text-offset"]);
                map.setLayoutProperty("clusterIncidentCountBadge", "icon-offset", getClusterCountDefaultStyles(isMobile)["icon-offset"]);
            }
        }
        hoveredCluster = null;
        map.getCanvas().style.cursor = "";
    });
    map.on("mousedown", "clusters", (e) => {
        if (e.features.length > 0) {
            if (hoveredCluster !== null) {
                map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { pressed: false });
            }
            hoveredCluster = e.features[0].id;
            map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { pressed: true });
        }
    });
    map.on("mouseup", "clusters", () => {
        if (hoveredCluster !== null) {
            map.setFeatureState({ source: LOCATIONS_SOURCE, id: hoveredCluster }, { pressed: false });
        }
        hoveredCluster = null;
        map.getCanvas().style.cursor = "";
    });
};

export const formatSiteObjectToGeoJson = (siteObjects: ISiteObject[]) =>
    siteObjects.map((obj) => {
        const { coordinates } = obj;
        return {
            type: "Feature",
            id: obj.id,
            properties: {
                ...obj,
            },
            geometry: {
                type: "Point",
                coordinates: [Number(coordinates[1] || 0), Number(coordinates[0] || 0)],
            },
        };
    });
