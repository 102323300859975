import { ReportInsight } from "../types";

export const createInsightsDbQuery = (reports: ReportInsight[]) => {
    return `BEGIN TRANSACTION;
        CREATE TABLE IF NOT EXISTS "reports" (
            "id" INTEGER NOT NULL,
            "mys_id" TEXT NOT NULL,
            "location_id" TEXT NOT NULL,
            "template" TEXT NOT NULL,
            "child_template" TEXT,
            "root_site_location" TEXT,
            "category_level1" TEXT,
            "category_level2" TEXT,
            "category_level3" TEXT,
            "category_key" TEXT,
            "severity_level" INTEGER,
            "report_date_time" TEXT NOT NULL,
            "report_date_time_local" TEXT NOT NULL,
            "last_updated_ts" TEXT NOT NULL,
            "is_read" INTEGER NOT NULL,
            CONSTRAINT "PK_reports" PRIMARY KEY("id" AUTOINCREMENT)
        );
        INSERT INTO "reports" VALUES ${buildInsertValuesQueryPart(reports)};
        COMMIT;`;
};

/**
 * It build part of sql query for inserting reports
 */
export const buildInsertValuesQueryPart = (reports: ReportInsight[]): string => {
    if (reports.length === 0) {
        return "";
    }

    return reports
        .map((report) => {
            const {
                id,
                mys_id,
                location_id,
                template,
                child_template,
                root_site_location,
                category_level1,
                category_level2,
                category_level3,
                category_key,
                severity_level,
                report_date_time,
                report_date_time_local = "0001-01-01 00:00:00",
                last_updated_ts,
                is_read,
            } = report;

            return `(${id},'${mys_id}','${location_id}','${template}','${child_template}','${root_site_location}','${category_level1}','${category_level2}','${category_level3}','${category_key}',${severity_level},'${report_date_time}','${report_date_time_local}','${last_updated_ts}',${is_read})`;
        })
        .join(",\n");
};

export default createInsightsDbQuery;
