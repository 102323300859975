import { createSelector } from "reselect";

import { IState } from "../../Store";
import { RequestStatus } from "../RequestStatus";
import { sortByField } from "./../../helpers/ArrayHelper";

const mapAllReportsComments = ({ reportComments: { byReportKey } }: IState) => byReportKey;

//see https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const makeGetReportComments = () => {
    //returns a new copy of the getReportComments selector each time it is called:
    return createSelector([mapAllReportsComments, (_, p, reportId) => reportId], (comments, key) => {
        const commentStateOfReport = comments[key] || {
            fetchStatus: RequestStatus.undefined,
            comments: [],
            createState: { status: RequestStatus.undefined },
        };
        const commentsOfReport = commentStateOfReport.comments.filter((c) => !c.isDeleted).sort((a, b) => sortByField("createdTime", true)(a, b));
        return { fetchStatus: commentStateOfReport.fetchStatus, createState: commentStateOfReport.createState, comments: commentsOfReport };
    });
};

export default {
    makeGetReportComments,
};
