import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapSelectedGroupIdState = ({ sites: { selectedGroupId } }: IState) => selectedGroupId;

const getSelectedGroupId = createSelector([mapSelectedGroupIdState], (selectedGroupId) => selectedGroupId);

export default {
    getSelectedGroupId,
};
