import { factory } from "@mswjs/data";

import { Comment, Report } from "./models";

export const db = factory({
    comments: Comment,
    reports: Report,
});

export default db;
