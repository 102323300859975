import { DateRangeChipModel } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { addDays, getYear, startOfYear, subDays } from "src/helpers/date";

import { featureFlags } from "../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../hooks/featureFlags";

export const useDateRangeChips = (): DateRangeChipModel[] => {
    const { t } = useTranslation();
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const today = new Date();
    const tomorrow = addDays(today, 1);

    return [
        ...(areSiteZonesEnabled
            ? [
                  {
                      label: t("common.datepicker.todayLocal"),
                      from: today,
                      to: today,
                  },
                  {
                      label: t("common.datepicker.tomorrowSiteTime"),
                      from: tomorrow,
                      to: tomorrow,
                  },
              ]
            : [
                  {
                      label: t("common.date.today"),
                      from: today,
                  },
              ]),
        {
            label: t("common.date.yesterday"),
            to: subDays(today, 1),
            from: subDays(today, 1),
        },
        {
            label: t("common.datepicker.last7days"),
            from: subDays(today, 6),
        },
        {
            label: t("common.datepicker.last30days"),
            from: subDays(today, 29),
        },
        {
            label: t("common.datepicker.last6months"),
            from: subDays(today, 179),
        },
        {
            // current year
            label: `${getYear(today)}`,
            from: startOfYear(today),
        },
    ];
};
