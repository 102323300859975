import { RSAA } from "redux-api-middleware";

import { hasTokenExpired, hasTokenHalftimePassed } from "../helpers/TokenHelper";
import AccessActions from "../store/access/AccessActions";
import { IState } from "./../Store";

const apiRefreshMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        const callAPI = action[RSAA];

        // if it's a refresh action then do not validate token expiry
        if (callAPI && callAPI.refresh) {
            delete callAPI.refresh;
            return next(action);
        }

        const state: IState = getState();
        const {
            access: { accessToken, accessTokenDecoded },
        } = state;

        if (accessToken) {
            if (hasTokenHalftimePassed(accessTokenDecoded) && !hasTokenExpired(accessToken)) {
                dispatch(AccessActions.refreshAccessToken(accessToken));
            }
        }

        return next(action);
    };

export default apiRefreshMiddleware;
