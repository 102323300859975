import { useCategoryInfo } from "src/pages/Insights/shared/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { useDatePeriod } from "./useDatePeriod";
import { useDeviationCount } from "./useDeviationCount";
import { useIncidentCount } from "./useIncidentCount";

type UseCountIncidentsAndDeviationsResult = {
    isLoading: boolean;
    currentPeriodCount: number;
    previousPeriodCount: number;
};

export const useCountIncidentsAndDeviations = (categoryKey: string, siteId?: string): UseCountIncidentsAndDeviationsResult => {
    const { currentPeriod, previousPeriod } = useDatePeriod();
    const { categories, level } = useCategoryInfo(categoryKey);
    const { siteIds } = useFilteredSites();

    const {
        isLoading: isIncidentCountingLoading,
        currentPeriodIncidentCount,
        previousPeriodIncidentCount,
    } = useIncidentCount({ categories, level, siteIds: siteId ? [siteId] : siteIds, currentPeriod, previousPeriod });
    const {
        isLoading: isDeviationCountingLoading,
        currentPeriodDeviationsCount,
        previousPeriodDeviationsCount,
    } = useDeviationCount({ categories, level, siteIds: siteId ? [siteId] : siteIds, currentPeriod, previousPeriod });

    const isLoading = isIncidentCountingLoading || isDeviationCountingLoading;

    return {
        isLoading,
        currentPeriodCount: currentPeriodIncidentCount + currentPeriodDeviationsCount,
        previousPeriodCount: previousPeriodIncidentCount + previousPeriodDeviationsCount,
    };
};
