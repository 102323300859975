import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { regionsByCountry } from "../../data/regions";
import AccessSelectors from "../access/AccessSelectors";
import { useLazyGetClientAuthorizationsQuery, useLazyGetClientDescendantsByLabelQuery } from "../authorisation/authorizationApi";
import ClientSwitcherSelectors from "../clientSwitcher/ClientSwitcherSelectors";
import { RequestStatus } from "../RequestStatus";
import { useSiteObjectItemsByRegions } from "../siteObjects/SiteObjectsHooks";
import LocationsSelectors from "./LocationsSelectors";

export const useAuthorizedLocations = () => {
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const clientAuthorizationsFetchStatus = useSelector(ClientSwitcherSelectors.getClientAuthorizationsStatus);
    const userId = useSelector(AccessSelectors.getUserId);
    const { siteObjects, descendantsFetchStatus, groups } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const [getClientAuthorizations] = useLazyGetClientAuthorizationsQuery();
    const [getClientDescendantsByLabel] = useLazyGetClientDescendantsByLabelQuery();

    const loadDescendants = useCallback(
        (clientId: string) => {
            getClientDescendantsByLabel({ userId, clientId });
        },
        [userId, getClientDescendantsByLabel],
    );

    const loadClientAuthorizations = useCallback(
        (clientId: string) => {
            getClientAuthorizations({ clientId });
        },
        [getClientAuthorizations],
    );

    useEffect(() => {
        if (selectedClient?.id && descendantsFetchStatus === RequestStatus.undefined) {
            loadDescendants(selectedClient.id);
        }
    }, [selectedClient, loadDescendants, descendantsFetchStatus]);

    useEffect(() => {
        if (selectedClient?.id && clientAuthorizationsFetchStatus === RequestStatus.undefined) {
            loadClientAuthorizations(selectedClient.id);
        }
    }, [selectedClient, clientAuthorizationsFetchStatus, loadClientAuthorizations]);

    return { siteObjects, status: descendantsFetchStatus, groups };
};

export const useAuthorizedSiteObjectIds = () => {
    const { siteObjects, status } = useAuthorizedLocations();
    const locationIds = useMemo(() => {
        if (!siteObjects || status !== RequestStatus.success) {
            return [];
        }
        return siteObjects.map((l) => l.id);
    }, [siteObjects, status]);

    return { locationIds, status };
};

export const useRegionalisedAuthorizedLocations = () => {
    const { siteObjects } = useAuthorizedLocations();

    const groupedLocations = siteObjects.reduce((locationsByRegion, location) => {
        if (!locationsByRegion.some((r) => r.countryCode === location.countryCode))
            locationsByRegion.push({ region: regionsByCountry[location.countryCode], countryCode: location.countryCode });

        return locationsByRegion;
    }, []);

    return { siteObjects, groupedLocations };
};

export const useUngroupedSites = () => {
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);

    const { sitesByRegions } = useSiteObjectItemsByRegions();

    const availableSites = useMemo(() => Object.keys(sitesByRegions.itemsLookup).map((i) => i), [sitesByRegions]);

    const allLocationIdsUsedInGroups = locations.groups.flatMap((x) => x.locationIds);

    const ungroupedSites = availableSites.filter((site) => !allLocationIdsUsedInGroups.some((x) => x === site));

    return locations.siteObjects.filter((x) => ungroupedSites.some((us) => us === x.id)).map((x) => ({ value: x.id, label: x.displayName }));
};
