import { ToastProps } from "@secuis/ccp-react-components";

export const STORE_QUERY_PARMAS = "@@app/STORE_QUERY_PARMAS";
export const APP_API_ERROR = "@@app/APP_API_ERROR";
export const APP_DB_ERROR = "@@app/APP_DB_ERROR";
export const ADD_IN_APP_NOTIFICATION = "@app/ADD_IN_APP_NOTIFICATION";
export const REMOVE_LAST_IN_APP_NOTIFICATION = "@app/REMOVE_LAST_IN_APP_NOTIFICATION";
export const REMOVE_IN_APP_NOTIFICATION = "@app/REMOVE_IN_APP_NOTIFICATION";
export const SET_IS_EMBEDDED_IN_NATIVE_HUB = "@app/SET_IS_EMBEDDED__IN_NATIVE_HUB";

export interface IErrorInfo {
    name: string;
    message: string;
    silent?: boolean;
    status: number;
    redirect?: string;
}

export interface IAppState {
    errorInfo?: IErrorInfo[];
    inAppNotifications: ToastProps[];
    isEmbeddedInNativeHub: boolean;
    queryParams: Record<string, string>;
}
