import { ICommentContentPart } from "../components/Reports/Comments/CommentListItem";
import { getUserFullName, getUserPrimaryEmail, IUser } from "../models/UserModel";
import { makeUserMentionInComment } from "../store/reportComments/models";

export const extractCommentParts = (
    content: string,
    mentionedUserIds: string[],
    users: IUser[],
    allowedLength: number | null,
    deletedUserText?: string,
    maxNewLinesCount = 6,
): ICommentContentPart[] => {
    try {
        const mentionedUsers = users.filter((c) => mentionedUserIds.includes(c.id));
        const userIdsRegex = mentionedUsers.map((el) => `(${makeUserMentionInComment(el.id)})`).join("|");
        const textSplitWithMentions = mentionedUsers.length ? content.split(new RegExp(userIdsRegex, "gi")) : [content];

        let currentLength = 0;
        let currentNewLinesCount = 0;
        const processedParts: ICommentContentPart[] = [];

        for (const part of textSplitWithMentions) {
            if (!part) {
                continue;
            }
            const mention = getMentionFromText(part, mentionedUsers, deletedUserText);
            const partElement: ICommentContentPart = {
                isMention: !!mention,
                text: mention ?? part,
                fitsInShort: !allowedLength || (currentLength + (mention ?? part).length <= allowedLength && currentNewLinesCount < maxNewLinesCount),
            };

            //don't break down mentions -> they're atomic, or parts that won't fit into short version either way
            const canBeBrokenDown = !partElement.isMention && currentNewLinesCount < maxNewLinesCount && currentLength < allowedLength;
            let commentTooLong = false;
            if (!canBeBrokenDown) {
                processedParts.push(partElement);
                const lines = part.split(new RegExp("\\n", "g"));
                currentNewLinesCount += lines.length;
                currentLength += partElement.text.length;
            } else {
                //break down by lines and length
                const singleLines = part.split(new RegExp("\\n", "g"));
                for (const [index, line] of singleLines.entries()) {
                    let prefix = "";
                    if (index > 0) {
                        //first element is in the same line it was, so only indexes > 1 are treated as new lines
                        currentNewLinesCount++;
                        prefix = "\n";
                    }
                    const lineAllowed = !commentTooLong && currentNewLinesCount < maxNewLinesCount;
                    const lengthAllowed = !commentTooLong && (!allowedLength || currentLength + line.length <= allowedLength);
                    if (lineAllowed && !lengthAllowed) {
                        const allowed = line.substr(0, allowedLength - currentLength);
                        const notAllowed = line.substr(allowedLength - currentLength);
                        processedParts.push({ ...partElement, fitsInShort: lineAllowed, text: prefix + allowed });
                        processedParts.push({ ...partElement, fitsInShort: false, text: notAllowed });
                    } else {
                        processedParts.push({ ...partElement, fitsInShort: lineAllowed && lengthAllowed, text: prefix + line });
                    }
                    if (!lineAllowed || !lengthAllowed) {
                        commentTooLong = true;
                    }
                    currentLength += line.length;
                }
            }
        }
        return processedParts;
    } catch (ex) {
        console.warn(`An error occured while processing a comment ${content}`);
        const unProcessed: ICommentContentPart[] = [
            {
                text: content,
                fitsInShort: true,
                isMention: false,
            },
        ];
        return unProcessed;
    }
};

const getMentionFromText = (part: string, mentionedUsers: IUser[], deletedUserText = "") => {
    for (const u of mentionedUsers) {
        let replaced;
        const mentionStr = makeUserMentionInComment(u.id);
        if (u.status === "Deleted") {
            replaced = part.replace(mentionStr, deletedUserText);
        } else {
            replaced = part.replace(mentionStr, getUserFullName(u.name) || getUserPrimaryEmail(u.emails));
        }
        const isMention = part !== replaced;
        if (isMention) {
            return replaced;
        }
    }
};
