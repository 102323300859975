import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Column } from "../shared/Table/Table";
import { ScheduleTableSortKey } from "./ScheduleTable.types";
import {
    CheckpointsCell,
    DescriptionCell,
    DurationCell,
    ExceptionsCell,
    MissedCheckpointsCell,
    PositionCell,
    renderHaderCell,
    ScheduleTypeCell,
    StartTimeCell,
    StatusCell,
    TaskCell,
    TourNameCell,
} from "./scheduleTableCells";

export const useTableColumns = (
    getSortKeyFunc: (sortKey: ScheduleTableSortKey) => () => void,
    startTimeTooltip: string,
    sortKey: string,
): { columns: Column[]; childrenColumns: Column[] } => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                header: `${t("schedule.table.task")}`,
                key: "taskType",
                id: "taskType",
                Cell: TaskCell,
            },
            {
                header: `${t("schedule.table.description")}`,
                key: "description",
                id: "task",
                Cell: DescriptionCell,
            },
            {
                header: `${t("schedule.table.status")}`,
                key: "status",
                id: "status",
                Cell: StatusCell,
                HeaderCell: (props) => renderHaderCell({ ...props, sortItemsByColumn: getSortKeyFunc("status"), sortKey }),
            },
            {
                header: `${t("schedule.table.startTime")}`,
                key: "StartDateTime",
                id: "StartDateTime",
                Cell: StartTimeCell,
                HeaderCell: (props) =>
                    renderHaderCell({ ...props, sortItemsByColumn: getSortKeyFunc("StartDateTime"), sortKey, tooltipText: startTimeTooltip }),
            },
            {
                header: `${t("schedule.table.expectedDuration")}`,
                key: "expectedDuration",
                id: "expectedDuration",
                Cell: DurationCell,
                HeaderCell: (props) => renderHaderCell({ ...props, sortItemsByColumn: getSortKeyFunc("expectedDuration"), sortKey }),
            },
            {
                header: `${t("schedule.table.position")}`,
                key: "position",
                id: "position",
                Cell: PositionCell,
                HeaderCell: (props) => renderHaderCell({ ...props, sortItemsByColumn: getSortKeyFunc("position"), sortKey }),
            },
            {
                header: `${t("schedule.table.scheduleType")}`,
                key: "scheduleType",
                id: "scheduleType",
                Cell: ScheduleTypeCell,
                HeaderCell: (props) => renderHaderCell({ ...props, sortItemsByColumn: getSortKeyFunc("scheduleType"), sortKey }),
            },
        ],
        [getSortKeyFunc, sortKey, startTimeTooltip, t],
    );
    const childrenColumns = [
        {
            key: "tourName",
            id: "tourName",
            Cell: TourNameCell,
        },
        {
            key: "checkpointsTotal",
            id: "checkpointsTotal",
            Cell: CheckpointsCell,
        },
        {
            key: "exceptionReportIds",
            id: "exceptionReportIds",
            Cell: ExceptionsCell,
        },
        {
            key: "checkpointsMissedTotal",
            id: "checkpointsMissedTotal",
            Cell: MissedCheckpointsCell,
        },
        {
            key: "tourName",
            id: "emptyCell1",
            Cell: () => null,
        },
        {
            key: "tourName",
            id: "emptyCell2",
            Cell: () => null,
        },
        {
            key: "tourName",
            id: "emptyCell3",
            Cell: () => null,
        },
    ];
    return { columns, childrenColumns };
};
