import { Icon } from "@secuis/ccp-react-components";

import { ButtonStyled, PaginationStyled } from "./Pagination.styles";

type PaginationProps = {
    totalPages: number;
    currentPage: number;
    showPreviousPage: () => void;
    showNextPage: () => void;
};

const renderPrevButton = (currentPage: number, previousPage: () => void) => {
    return currentPage > 1 ? (
        <ButtonStyled onClick={previousPage}>
            <Icon variant="ArrowBack" size="XL" data-testid="matrix-pagination-prev" />
        </ButtonStyled>
    ) : (
        <ButtonStyled disabled>
            <Icon variant="ArrowBack" size="XL" color="disabled" data-testid="matrix-pagination-prev" />
        </ButtonStyled>
    );
};

const renderNextButton = (currentPage: number, totalPages: number, nextPage: () => void) => {
    return currentPage < totalPages ? (
        <ButtonStyled onClick={nextPage} data-testid="matrix-pagination-next">
            <Icon variant="ArrowForward" size="XL" />
        </ButtonStyled>
    ) : (
        <ButtonStyled disabled>
            <Icon variant="ArrowForward" size="XL" color="disabled" data-testid="matrix-pagination-next" />
        </ButtonStyled>
    );
};

export const Pagination = ({ totalPages, currentPage, showPreviousPage, showNextPage }: PaginationProps) => {
    return (
        <PaginationStyled justifyContent="flex-end" data-testid="matrix-pagination">
            {renderPrevButton(currentPage, showPreviousPage)}
            {renderNextButton(currentPage, totalPages, showNextPage)}
        </PaginationStyled>
    );
};
