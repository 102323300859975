import { createReducer } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import { IFeatureFlagsState, SET_FEATURE_FLAGS } from "./types";

const defaultState: IFeatureFlagsState = {
    flags: {},
    status: RequestStatus.undefined,
};

const featureFlagsReducer = createReducer(defaultState, {
    [SET_FEATURE_FLAGS]: (state, action: { payload: Record<string, boolean> }) => {
        state.flags = action.payload;
        state.status = RequestStatus.success;
    },
});

export default featureFlagsReducer;
