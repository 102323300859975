import { View } from "@react-pdf/renderer";
import { Children, isValidElement, JSX, ReactElement, ReactNode } from "react";

import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { styles } from "../pdfStyles";
import { DynamicTemplateSectionPdf } from "./PdfDynamicTemplateSection";

type Props = ChildrenProps & {
    label: string;
    rightHeader?: JSX.Element;
};

export const SummarySectionPdf = ({ label, children, rightHeader }: Props) => {
    function shouldRenderSummaryItem(child: ReactNode) {
        if (isValidElement(child)) {
            const elementChild = child as ReactElement;
            const { isRequired = true, fieldValue } = elementChild.props;
            return isRequired || !!fieldValue;
        }
        return true;
    }

    return (
        <DynamicTemplateSectionPdf label={label} rightHeader={rightHeader} renderLineBreak={false} marginTop="S">
            {Children.map(children, (child) => {
                if (shouldRenderSummaryItem(child)) {
                    return (
                        <>
                            {child}
                            <View style={styles.lineBreak} />
                        </>
                    );
                }
                return null;
            })}
        </DynamicTemplateSectionPdf>
    );
};
