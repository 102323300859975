import { createReducer } from "@reduxjs/toolkit";

import { mapToUser } from "../../models/UserModel";
import { RequestStatus } from "../RequestStatus";
import { IUsersState, USERS_GET_BY_IDS_FAILURE, USERS_GET_BY_IDS_REQUEST, USERS_GET_BY_IDS_SUCCESS } from "./types";

export const defaultState: IUsersState = {
    byIds: {
        status: RequestStatus.undefined,
        users: {},
    },
};

const userReducer = createReducer(defaultState, {
    [USERS_GET_BY_IDS_REQUEST]: (state) => {
        state.byIds.status = RequestStatus.loading;
    },
    [USERS_GET_BY_IDS_FAILURE]: (state) => {
        state.byIds.status = RequestStatus.error;
    },
    [USERS_GET_BY_IDS_SUCCESS]: (state, action) => {
        state.byIds.status = RequestStatus.success;
        action.payload.forEach((user) => {
            state.byIds.users[user.id] = mapToUser(user);
        });
    },
});

export default userReducer;
