import { getExceptionQuestionsAnswers, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { TourMultiExceptionFields } from "../../../../models/reportTemplates/TourMultiException";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getTourMultiExceptionReportConfigPdf = ({
    report,
    siteObject,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.tourMultiException.comments",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description: getFieldValueByFieldName(TourMultiExceptionFields.comments, report),
                },
            },
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.detailedInfo.tourMultiExceptionSummary",
        },
        children: [
            ...getExceptionQuestionsAnswers(getFieldValueByFieldName(TourMultiExceptionFields.exceptionQuestions, report) ?? "")
                .filter((questionAndAnswer) => questionAndAnswer[0])
                .map((questionAndAnswer) => ({
                    component: TextSummaryFieldPdf,
                    props: {
                        key: questionAndAnswer[0],
                        fieldName: questionAndAnswer[0],
                        fieldValue: questionAndAnswer[1],
                    },
                })),
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.checkpoint",
                    fieldValue: getFieldValueByFieldName(TourMultiExceptionFields.checkpoint, report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(TourMultiExceptionFields), []),
        ],
    },
];
