export function getSeverityLevelTranslationKey(severityLevel: number | string): string {
    switch (severityLevel) {
        case 0:
        case "Info":
            return "common.severity.warning.info";
        case 1:
        case "Low":
            return "common.severity.warning.low";
        case 2:
        case "Moderate":
            return "common.severity.warning.moderate";
        case 3:
        case "High":
            return "common.severity.warning.high";
        case 4:
        case "Severe":
            return "common.severity.warning.severe";
        default:
            return "";
    }
}
