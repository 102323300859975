import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Spacing } from "@secuis/ccp-react-components";

const styles = StyleSheet.create({
    totalTimeText: {
        display: "flex",
        flexDirection: "row",
        columnGap: Spacing.XXS,
        alignItems: "flex-end",
    },
    count: {
        fontSize: 24,
        fontWeight: 700,
    },
    units: {
        fontSize: 12,
        marginBottom: 3,
    },
});

type Props = {
    count: string;
    label: string;
    insertComma?: boolean;
};

export const PdfTimeValue = ({ count, label, insertComma = false }: Props) => (
    <View style={styles.totalTimeText}>
        <Text style={styles.count}>{count}</Text>
        <Text style={styles.units}>
            {label}
            {insertComma ? "," : null}
        </Text>
    </View>
);
