import { GraphPalette, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const CIRCLE_RADIUS = Spacing.L;

export const Circle = styled(Stack).attrs({
    justifyContent: "center",
    alignItems: "center",
})`
    width: ${CIRCLE_RADIUS}px;
    height: ${CIRCLE_RADIUS}px;
    border-radius: 50%;
    background-color: ${Palette.Navy750};
    border: 1px solid ${Palette.Navy200};
    z-index: 1;
    position: relative;
`;

export const Dash = styled.div`
    height: 2px;
    width: 20px;
    border: 2px solid ${Palette.Navy200};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const Line = styled.div<{ background: GraphPalette | Palette }>`
    width: ${Spacing.XS}px;
    height: ${Spacing.XXL}px;
    background-color: ${({ background }) => background};
    margin-top: -${Spacing.S}px;
    margin-bottom: -${Spacing.S}px;
`;

export const LineWrapper = styled(Stack).attrs({
    justifyContent: "center",
})`
    width: ${CIRCLE_RADIUS}px;
`;

export const StackStyled = styled(Stack).attrs({
    flexWrap: "wrap",
})`
    column-gap: ${Spacing.XS}px;
`;
