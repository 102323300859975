const link = `
    cursor: pointer;
`;

export const mediumText = `
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
`;

export const mediumTextBold = `
    ${mediumText};
    font-weight: 700;
`;

export const smallText = `
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
`;

export const microText = `
    font-size: 12px;
    line-height: 18px;
    font-style: normal;
    letter-spacing: 0.3px;
`;

export const microTextBold = `
    ${microText};
    font-weight: 700;
    letter-spacing: 0.4px;
`;

export const actionText = `
    ${link}
    ${mediumTextBold}
`;
