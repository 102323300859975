import { Box, ButtonText, Icon, Stack, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "../shared/Tooltip";
import { HeaderWrapperStyled } from "./Schedule.styles";

type Props = {
    siteName: string;
};

export const ScheduleHeader = ({ siteName }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <HeaderWrapperStyled>
            <Box paddingVertical="XS" marginBottom="XS">
                <ButtonText iconSize="L" color="primary" icon="BackNavigation" direction="row" onClick={() => navigate(-1)}>
                    {siteName}
                </ButtonText>
            </Box>
            <Stack paddingTop="XS" alignItems="center" gap="XS">
                <Icon variant="Calendar" />
                <Title color="primary">{t("schedule.title")}</Title>
                <Tooltip message={t("schedule.titleTooltip")}>
                    <Icon variant="Info" />
                </Tooltip>
            </Stack>
        </HeaderWrapperStyled>
    );
};
