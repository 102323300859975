import { Breakpoints, Overlay, useHasMaxWidth } from "@secuis/ccp-react-components";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
    breakpoint: Breakpoints;
};
export const ResponsiveOverlay = ({ breakpoint, children }: Props) => {
    const matchMaxWidth = useHasMaxWidth(breakpoint);
    return matchMaxWidth ? <Overlay>{children}</Overlay> : children;
};
