export const flexColumn = `
    display: flex;
    flex-direction: column;
`;

export const flexColumnCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const flexColumnAlignCenter = `
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const flexColumnJustifyCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const flexColumnJustifySpaceBetween = `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const flexColumnJustifyCenterAlignCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const flexRow = `
    display: flex;
    flex-direction: row;
`;

export const flexRowJustifySpaceBetweenAlignCenter = `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const flexRowJustifySpaceBetween = `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const flexRowAlignCenter = `
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const flexRowCenter = `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const flexColumnJustifyEnd = `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
