import styled from "styled-components";

export const WrapperStyled = styled.div`
    width: 100%;

    .recharts-wrapper {
        .recharts-cartesian-grid {
            line:nth-last-child(2),
            line:last-child {
                stroke-opacity: 0;
            }
        }
    }
`;
