import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { AutoTranslationProvider } from "../../../helpers/autoTranslations";
import { AutoTranslatedContentFooterPdf } from "../../../helpers/autoTranslations/AutoTranslatedContentFooterPdf";
import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { styles } from "../pdfStyles";

type Props = ChildrenProps & {
    sectionTitle: string;
};

export const DescriptionSectionPdf = ({ sectionTitle, children }: Props) => {
    const { t } = useTranslation();

    return (
        <AutoTranslationProvider id={sectionTitle}>
            <View style={styles.section}>
                <Text style={styles.sectionTitle}>{t(sectionTitle).toUpperCase()}</Text>
                {children}
                <AutoTranslatedContentFooterPdf partiallyTranslated={false} style={{ marginTop: 4 }} />
                <View style={styles.sectionLineBreak}></View>
            </View>
        </AutoTranslationProvider>
    );
};
