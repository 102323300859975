import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";
import { APP_ROLES } from "src/models/RoleModel";

export const authorizationApi = createApi({
    reducerPath: "authorizationApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUserAuthorizeStatus: builder.query<any, { userId: string; locationId: string }>({
            query: ({ userId, locationId }) => ({
                url: `/v1/authorization/users/isAuthorizedAt`,
                method: "POST",
                body: JSON.stringify({
                    userId,
                    locationIds: [locationId],
                    verb: "global.reports.view",
                }),
                headers: {
                    "Cache-Control": "no-cache",
                },
            }),
        }),
        getUsersAtLocation: builder.query<any, { locationId: string }>({
            query: ({ locationId }) => ({
                url: `/v1/authorization/records/inheritedUsersByLocation/${locationId}/client/site`,
            }),
        }),
        getClientAuthorizations: builder.query<any, { clientId: string }>({
            query: ({ clientId }) => ({
                url: `/v1/authorization/records/byLocationId/${clientId}`,
            }),
        }),
        searchAvailableClients: builder.query<any, { userId: string; searchQuery?: string; skip: number }>({
            query: ({ userId, searchQuery, skip = 0 }) => ({
                url: `/v1/authorizationgraph/userorganization/${userId}/clients`,
                method: "POST",
                body: {
                    paging: {
                        pageSize: 20,
                        skip,
                    },
                    searchQuery,
                    labels: ["client"],
                    rolesFilter: APP_ROLES,
                },
                headers: {
                    "Cache-Control": "no-cache",
                },
            }),
        }),
        getInitialClientsInfo: builder.query<any, { userId: string }>({
            query: ({ userId }) => ({
                url: `/v1/authorizationgraph/userorganization/${userId}/clients`,
                method: "POST",
                body: {
                    paging: {
                        pageSize: 10,
                    },
                    labels: ["client"],
                    rolesFilter: APP_ROLES,
                },
                headers: {
                    "Cache-Control": "no-cache",
                },
            }),
        }),
        getClientDescendantsByLabel: builder.query<any, { userId: string; clientId: string }>({
            query: ({ userId, clientId }) => {
                const rolesFilter = APP_ROLES.join(",");
                return {
                    url: `/v1/authorizationGraph/userOrganization/${userId}/location/${clientId}/descendants?targetLabels=siteObject,group&rolesFilterString=${rolesFilter}`,
                    method: "GET",
                    headers: {
                        "Cache-Control": "no-cache",
                    },
                };
            },
        }),
    }),
});

export const {
    useLazyGetUsersAtLocationQuery,
    useLazyGetUserAuthorizeStatusQuery,
    useLazyGetClientAuthorizationsQuery,
    useLazySearchAvailableClientsQuery,
    useLazyGetInitialClientsInfoQuery,
    useLazyGetClientDescendantsByLabelQuery,
} = authorizationApi;
