import { Breakpoints, Button, Text } from "@secuis/ccp-react-components";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import { useIsDevice } from "../../../hooks/CommonHooks";
import { MobileBreakpoints } from "../../../styles/Constants";
import {
    ContextMenuBody,
    ContextMenuButtonWrapper,
    ContextMenuHeader,
    ContextMenuListStyled,
    ContextMenuModal,
    ContextMenuOverlay,
} from "./ContextMenu.styles";
import { ContextMenuItem, ContextMenuItemMobile, IContextMenuOption } from "./ContextMenuItem";

type Props = {
    label?: string;
    options: IContextMenuOption[];
    onClick: (args: unknown) => void;
    onCancel?: () => void;
};

const ContextMenuListMobile = ({ label, options, onClick, onCancel }: Props) => {
    const { t } = useTranslation();
    return ReactDOM.createPortal(
        <ContextMenuOverlay onClick={onCancel}>
            <ContextMenuModal onClick={(e) => e.stopPropagation()}>
                <ContextMenuHeader>
                    <Text small bold color="secondary">
                        {label}
                    </Text>
                </ContextMenuHeader>
                <ContextMenuBody>
                    {options.map((option) => (
                        <ContextMenuItemMobile key={option.label} option={option} onClick={onClick} />
                    ))}
                </ContextMenuBody>
                <ContextMenuButtonWrapper>
                    <Button mode="outlined" onClick={onCancel}>
                        {t("common.cancel")}
                    </Button>
                </ContextMenuButtonWrapper>
            </ContextMenuModal>
        </ContextMenuOverlay>,
        document.body,
    );
};

const ContextMenuList = ({ label, options, onClick, onCancel }: Props) => {
    const isMobile = useIsDevice(Breakpoints.XS, MobileBreakpoints.LandscapeHeight);
    if (isMobile) {
        return <ContextMenuListMobile label={label} options={options} onClick={onClick} onCancel={onCancel} />;
    }
    return (
        <ContextMenuListStyled float="left">
            {options.map((option) => (
                <ContextMenuItem key={option.label} option={option} onClick={onClick} />
            ))}
        </ContextMenuListStyled>
    );
};

export default ContextMenuList;
