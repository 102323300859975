import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack)`
    padding: ${Spacing.S}px 40px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px ${Spacing.S}px;
    }
`;
