import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStoreQueryParams } from "src/hooks/ParamsHooks";

import { clearUserData, loadValueFromState, resetState, saveValue, SessionStorageKey } from "../../helpers/SessionStorageHelper";
import { useHubContext } from "../../hooks/CommonHooks";
import { NotificationContextModel } from "../../models/NotificationModel";
import AccessSelectors from "../access/AccessSelectors";
import { useSharedRedirection } from "../app/AppHooks";
import { useLazyGetInitialClientsInfoQuery, useLazyGetUserAuthorizeStatusQuery, useLazySearchAvailableClientsQuery } from "../authorisation/authorizationApi";
import ClientSwitcherSelectors from "../clientSwitcher/ClientSwitcherSelectors";
import { isFinished, RequestStatus } from "../RequestStatus";
import { useAuthorizationApi } from "../user/UserHooks";
import { clientSwitcherSlice } from "./clientSwitcherSlice";
import { IAvailableClient, mapToAvailableClient } from "./types";

export const useClientSwitcher = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hubContext } = useHubContext();
    const userId = useSelector(AccessSelectors.getUserId);
    const { results, totalCount, skip, status: availableClientsFetchStatus } = useSelector(ClientSwitcherSelectors.getAvailableClients);
    const { initialStatus: initialClientsFetchStatus, initialClientCount } = useSelector(ClientSwitcherSelectors.getClientTotalCount);
    const userClientAuthorizationStatus = useSelector(ClientSwitcherSelectors.getUserClientAuthorizationStatus);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const { getAuthorizedLocationClient } = useAuthorizationApi();
    const { canHandleRedirect, handleRedirect } = useSharedRedirection();
    const [searchAvailableClients] = useLazySearchAvailableClientsQuery();
    const [getInitialClientsInfo] = useLazyGetInitialClientsInfoQuery();
    const [getUserAuthorizeStatus] = useLazyGetUserAuthorizeStatusQuery();

    useStoreQueryParams(["analyticsEnabled", "b"]);

    const rejectClient = useCallback(() => {
        dispatch(clientSwitcherSlice.actions.selectClient({ client: null }));
        setStoredSelectedClient(null);
        navigate("/");
    }, [dispatch, navigate]);

    const notificationContext = useMemo(() => {
        const context: NotificationContextModel = hubContext ? JSON.parse(window.atob(hubContext)) : null;
        if (canHandleRedirect(context)) {
            return context;
        }
        return null;
    }, [hubContext, canHandleRedirect]);

    const loadClientFromSessionStorage = useCallback(() => {
        const storedClient = getStoredClient();
        if (!storedClient?.name || selectedClient?.id === storedClient.id) {
            return;
        }
        if (availableClientsFetchStatus === RequestStatus.undefined) {
            searchAvailableClients({ userId, searchQuery: storedClient.name, skip: 0 });
            return;
        }
        if (isFinished(availableClientsFetchStatus)) {
            const clientFromResults = results.find((r) => r.id === storedClient?.id);
            if (clientFromResults) {
                dispatch(clientSwitcherSlice.actions.selectClient({ client: clientFromResults }));
                getUserAuthorizeStatus({ userId, locationId: clientFromResults.id });
                setStoredSelectedClient(clientFromResults);
            } else {
                getInitialClientsInfo({ userId });
                rejectClient();
            }
        }
    }, [
        dispatch,
        selectedClient,
        rejectClient,
        results,
        availableClientsFetchStatus,
        userId,
        getInitialClientsInfo,
        searchAvailableClients,
        getUserAuthorizeStatus,
    ]);

    const nonRedirectClientAssignmentFlow = useCallback(() => {
        if (initialClientsFetchStatus === RequestStatus.undefined) {
            getInitialClientsInfo({ userId });
            return;
        }
        loadClientFromSessionStorage();
        if (selectedClient && userClientAuthorizationStatus === RequestStatus.undefined) {
            getUserAuthorizeStatus({ userId, locationId: selectedClient.id });
        }
    }, [
        initialClientsFetchStatus,
        selectedClient,
        userClientAuthorizationStatus,
        userId,
        loadClientFromSessionStorage,
        getInitialClientsInfo,
        getUserAuthorizeStatus,
    ]);

    const assignClient = useCallback(
        (client) => {
            if (!client) {
                return;
            }
            const availableClient = mapToAvailableClient(client);
            getInitialClientsInfo({ userId });
            dispatch(clientSwitcherSlice.actions.selectClient({ client: availableClient }));
            getUserAuthorizeStatus({ userId, locationId: availableClient.id });
            setStoredSelectedClient(availableClient);
        },
        [dispatch, getInitialClientsInfo, userId, getUserAuthorizeStatus],
    );

    useEffect(() => {
        if (!userId) {
            return;
        }

        if (userId !== loadValueFromState(SessionStorageKey.UserId)) {
            resetState();
            saveValue(userId, SessionStorageKey.UserId);
            dispatch(clientSwitcherSlice.actions.selectClient({ client: null }));
        }
        if (notificationContext) {
            const assignClientFromHubContextAsync = async () => {
                const client = await getAuthorizedLocationClient(notificationContext.locationId);
                if (!client) {
                    rejectClient();
                    return;
                }
                assignClient(client);
                handleRedirect(notificationContext);
            };
            if (
                availableClientsFetchStatus !== RequestStatus.undefined ||
                initialClientsFetchStatus !== RequestStatus.undefined ||
                userClientAuthorizationStatus !== RequestStatus.undefined
            ) {
                return;
            }
            assignClientFromHubContextAsync();
        } else {
            nonRedirectClientAssignmentFlow();
        }
    }, [
        assignClient,
        dispatch,
        getAuthorizedLocationClient,
        handleRedirect,
        nonRedirectClientAssignmentFlow,
        rejectClient,
        initialClientsFetchStatus,
        notificationContext,
        userClientAuthorizationStatus,
        availableClientsFetchStatus,
        userId,
    ]);

    const isReady = useMemo(() => {
        const storedClient = getStoredClient();
        const clientsMatching = storedClient?.id === selectedClient?.id;
        if (storedClient) {
            return clientsMatching && userClientAuthorizationStatus === RequestStatus.success;
        }
        return initialClientsFetchStatus === RequestStatus.success;
    }, [initialClientsFetchStatus, selectedClient, userClientAuthorizationStatus]);

    return { results, status: availableClientsFetchStatus, initialStatus: initialClientsFetchStatus, isReady, initialClientCount, skip, totalCount };
};

export const useDisplaySwitcherBanner = () => {
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const { initialClientCount } = useSelector(ClientSwitcherSelectors.getClientTotalCount);
    return selectedClient && initialClientCount > 1;
};

export const setStoredSelectedClient = (selectClient: IAvailableClient) => {
    if (!selectClient) {
        clearUserData();
        return;
    }
    saveValue(selectClient.id, SessionStorageKey.ClientId);
    saveValue(selectClient.label, SessionStorageKey.ClientLabel);
    saveValue(selectClient.name, SessionStorageKey.ClientName);
};

export const useGetUserClientRoleNames = (): string[] => {
    const userId = useSelector(AccessSelectors.getUserId);
    const clientAuthorizationsData = useSelector(ClientSwitcherSelectors.getClientAuthorizationsData);

    const roleNames = useMemo(() => {
        if (!userId || !clientAuthorizationsData?.length) {
            return [];
        }
        const userClientAuthorizations = clientAuthorizationsData.filter((x) => x.userId === userId);
        return userClientAuthorizations.map((x) => x.roleName);
    }, [userId, clientAuthorizationsData]);

    return roleNames;
};

const getStoredClient = () => {
    const clientId = loadValueFromState(SessionStorageKey.ClientId);
    const clientLabel = loadValueFromState(SessionStorageKey.ClientLabel);
    const clientName = loadValueFromState(SessionStorageKey.ClientName);
    if (!clientId || !clientLabel || !clientName) {
        return null;
    }
    return { id: clientId, name: clientName, label: clientLabel } as IAvailableClient;
};
