import React from "react";
import { useParams } from "react-router-dom";

import { useSharedReport } from "../../../../store/reports/ReportsHooks";
import Generic from "./Generic";
import { SharedEntityPage } from "./SharedEntityPage";

export const SharedTourSessionPage: React.FC = () => {
    const { sharedTourSessionId } = useParams<{ sharedTourSessionId?: string }>();
    const { report, siteObject, loading, error, expirationTimestamp } = useSharedReport(sharedTourSessionId, true);
    return (
        <SharedEntityPage isLoading={loading} expirationTimestampStr={expirationTimestamp} error={error} shareId={sharedTourSessionId}>
            <Generic report={report} siteObject={siteObject} sharedReportId={sharedTourSessionId} />
        </SharedEntityPage>
    );
};
