import { Box, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { useAutoTranslatedTexts } from "../../../helpers/autoTranslations";
import { IImageData } from "../../../models/ReportImagesModels";
import ReportImagesCarousel from "../ReportImagesCarousel";
import { StyledText } from "./Description.styles";
import { StandaloneSection } from "./Shared.styles";

type PropsType = {
    title: string;
    description: string;
    imagesData: IImageData[];
    sharedReportId: string;
    isRequired?: boolean;
    subtitle?: string;
    shouldAutoTranslate?: boolean;
};

export const Description = ({ title, description, imagesData = [], sharedReportId, isRequired = true, subtitle, shouldAutoTranslate = true }: PropsType) => {
    const { t } = useTranslation();
    const {
        translations: [translatedTitle, translatedDescription],
    } = useAutoTranslatedTexts([title, description], shouldAutoTranslate);

    if (!title && !description && imagesData.length === 0) {
        return isRequired ? (
            <StandaloneSection>
                <StyledText>{t("common.noDataFound")}</StyledText>
            </StandaloneSection>
        ) : null;
    }

    return (
        <StandaloneSection data-testid="details-standalone-section">
            {title && (
                <Box mb="XS">
                    <Text bold>{translatedTitle}</Text>
                </Box>
            )}
            {description && <StyledText>{translatedDescription}</StyledText>}
            {subtitle && (
                <Text micro color="secondary">
                    {subtitle}
                </Text>
            )}
            {imagesData.length > 0 && (
                <Stack mt={title || description || subtitle ? "S" : "0"}>
                    <ReportImagesCarousel imagesData={imagesData} sharingId={sharedReportId} />
                </Stack>
            )}
        </StandaloneSection>
    );
};
