import { Loading } from "../../../../components/shared/Loading";
import { getSharedLinkExpirationMessage } from "../../../../helpers/SharedReportHelper";
import { ChildrenProps } from "../../../../models/ChildrenPropModel";
import { PageNames } from "../../../../models/tracking/pageView";
import { useTrackPageVisit } from "../../../../store/tracking/TrackingHooks";
import { NotFound } from "../../../Error/NotFound";
import { LinkExpiredBanner, SharedReportWrapper } from "./SharedReportPage.styles";

type Props = ChildrenProps & {
    shareId: string;
    isLoading: boolean;
    error: unknown;
    expirationTimestampStr: string;
};
export const SharedEntityPage = ({ shareId, error, isLoading, expirationTimestampStr, children }: Props) => {
    const expirationMessage = getSharedLinkExpirationMessage(expirationTimestampStr);
    useTrackPageVisit(PageNames.ReportDetails);

    if (!shareId || error) {
        return <NotFound />;
    }
    return (
        <SharedReportWrapper data-testid="shared-report-view">
            {!!expirationMessage && !isLoading && <LinkExpiredBanner icon="Info" message={expirationMessage} color="accent" />}
            {isLoading ? <Loading /> : children}
        </SharedReportWrapper>
    );
};
