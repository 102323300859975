import { Text } from "@secuis/ccp-react-components";
import { formatDuration } from "src/helpers/date";
import styled from "styled-components";

type Props = {
    cell: string;
};

const TextStyled = styled(Text)`
    text-wrap: nowrap;
`;

export const DurationCell = ({ cell }: Props) => {
    return <TextStyled small>{cell ? formatDuration({ minutes: parseInt(cell) }, ["minutes"]) : "-"}</TextStyled>;
};
