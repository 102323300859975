import { Text } from "@secuis/ccp-react-components";
import { formatTime } from "src/helpers/date";

type Props = {
    cell: string;
};

export const StartTimeCell = ({ cell }: Props) => {
    if (!cell) {
        return <Text small>-</Text>;
    }
    const dateObj = new Date(cell);

    return <Text small>{formatTime(dateObj)}</Text>;
};
