import { Stack, Text } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

const LabelTextDotStyles = css`
    display: inline-flex;
    align-items: center;
    gap: 12px;

    &::before {
        content: "";
        width: 3px;
        height: 3px;
        background-color: currentColor;
        display: inline-block;
        border-radius: 3px;
    }
`;

export const LabelTextStyled = styled(Text)<{ isDotVisible?: boolean }>`
    text-transform: capitalize;
    white-space: nowrap;

    ${({ isDotVisible }) => (isDotVisible ? LabelTextDotStyles : "")}
`;

export const StackStyled = styled(Stack)`
    gap: 12px;
`;
