import { PersistedState } from "redux-persist";

import { getValueFromProperty } from "../../helpers/PropertiesValueHelper";
import { RequestStatus } from "../RequestStatus";

export interface IAvailableClient {
    id: string;
    name: string;
    label: string;
}

export interface IClientAuthorization {
    id: string;
    userId: string;
    locationId: string;
    roleName: string;
    createdTime: string;
    isDeleted: boolean;
}

export interface IClientAuthorizations {
    data: IClientAuthorization[];
    status: RequestStatus;
}

export interface IClientSwitcherState extends PersistedState {
    initialClientCount: number;
    initialStatus: RequestStatus;
    availableClients: {
        status: RequestStatus;
        totalCount: number;
        results: IAvailableClient[];
        skip: number;
    };
    selectedClient: IAvailableClient;
    userAuthorizations: Record<string, { authorized: boolean; status: RequestStatus }>;
    clientAuthorizations: IClientAuthorizations;
}

export function mapToAvailableClient(dto): IAvailableClient {
    return {
        id: dto.id,
        label: dto.label,
        name: getValueFromProperty(dto, "name"),
    };
}
