import { createReducer } from "@reduxjs/toolkit";
import JWT from "jwt-decode";

import { ITokenDecoded } from "../../models/Token";
import { RequestStatus } from "../RequestStatus";
import {
    ACCESS_TOKEN_FAILURE,
    ACCESS_TOKEN_REFRESH_FAILURE,
    ACCESS_TOKEN_REFRESH_REQUEST,
    ACCESS_TOKEN_REFRESH_SUCCESS,
    ACCESS_TOKEN_REQUEST,
    ACCESS_TOKEN_SUCCESS,
    AUTH_PROMOTE_SESSION_TOKEN_FAILURE,
    AUTH_PROMOTE_SESSION_TOKEN_REQUEST,
    AUTH_PROMOTE_SESSION_TOKEN_SUCCESS,
    IAccessState,
} from "./types";

export const defaultState: IAccessState = {
    status: RequestStatus.undefined,
    accessToken: null,
    accessTokenDecoded: null,
};

const accessReducer = createReducer(defaultState, {
    [ACCESS_TOKEN_REQUEST]: (state) => {
        state.status = RequestStatus.loading;
    },
    [ACCESS_TOKEN_SUCCESS]: (state, action) => {
        if (!action.payload.token) {
            state.status = RequestStatus.invalid;
        } else {
            state.accessToken = action.payload.token;
            state.accessTokenDecoded = JWT<ITokenDecoded>(action.payload.token);
            state.status = RequestStatus.success;
        }
    },
    [ACCESS_TOKEN_FAILURE]: (state) => {
        state.status = RequestStatus.error;
    },
    [ACCESS_TOKEN_REFRESH_REQUEST]: (state) => {
        state.status = RequestStatus.loading;
    },
    [ACCESS_TOKEN_REFRESH_SUCCESS]: (state, action) => {
        state.accessToken = action.payload.token;
        state.accessTokenDecoded = JWT<ITokenDecoded>(action.payload.token);
        state.status = RequestStatus.success;
    },
    [ACCESS_TOKEN_REFRESH_FAILURE]: (state) => {
        state.status = RequestStatus.error;
    },
    [AUTH_PROMOTE_SESSION_TOKEN_REQUEST]: (state) => {
        state.authTokenPromotionRequestedTimestamp = Date.now();
        state.authTokenPromotedStatus = RequestStatus.loading;
    },
    [AUTH_PROMOTE_SESSION_TOKEN_SUCCESS]: (state, action) => {
        state.authTokenPromotedStatus = RequestStatus.success;
        state.accessToken = action.payload.token;
        state.accessTokenDecoded = JWT<ITokenDecoded>(action.payload.token);
    },
    [AUTH_PROMOTE_SESSION_TOKEN_FAILURE]: (state) => {
        state.authTokenPromotedStatus = RequestStatus.error;
    },
});

export default accessReducer;
