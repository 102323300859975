import { get } from "lodash";

export const sortByField = (fieldName: string, desc = false): ((a, b) => 0 | 1 | -1) => {
    const compare = (a: string | number, b: string | number) => {
        if (desc) {
            return b < a ? -1 : 1;
        }
        return a < b ? -1 : 1;
    };
    return (a, b) => {
        const fieldValueA = get(a, fieldName);
        const fieldValueB = get(b, fieldName);
        const aValue = typeof fieldValueA === "string" ? fieldValueA.toLowerCase() : fieldValueA;
        const bValue = typeof fieldValueB === "string" ? fieldValueB.toLowerCase() : fieldValueB;

        return aValue === bValue ? 0 : compare(aValue, bValue);
    };
};

export const joinedSorted = (someArray: string[]): string => [...someArray].sort((a, b) => a.localeCompare(b)).join(",");

export const getArrayFromString = (value: string): string[] => {
    if (value) return Array.isArray(value) ? [...value] : [...value.split(",")];

    return [];
};

export const batchArray = <T>(baseArray: T[], batchSize = 50) => {
    const arrayLength = baseArray.length;
    const batches = [];

    for (let i = 0; i < arrayLength; i += batchSize) {
        const batch = baseArray.slice(i, i + batchSize);
        batches.push(batch);
    }

    return batches;
};

export function sortByOrder<T>(arr: T[], order: string[], key: string): T[] {
    return order.reduce((acc, value) => {
        const newValues = arr.filter((item) => item[key] === value);
        if (!newValues.length) {
            return acc;
        }
        return [...acc, ...newValues];
    }, []);
}
