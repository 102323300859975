import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IUserPreferences, UserPreference } from "../../models/UserModel";
import AccessSelectors from "../access/AccessSelectors";
import { useLazyGetUserAuthorizeStatusQuery } from "../authorisation/authorizationApi";
import { useLazyGetLocationClientQuery } from "../organization/organizationApi";
import UserActions from "./UserActions";
import UserSelectors from "./UserSelectors";

export const useUserInfo = () => {
    const dispatch = useDispatch();
    const userId = useSelector(AccessSelectors.getUserId);
    const { info, status, invalidated } = useSelector(UserSelectors.getUserInfo);

    const loadUser = useCallback(() => {
        dispatch(UserActions.getUserInfo(userId));
    }, [userId, dispatch]);

    useEffect(() => {
        if (userId && invalidated) {
            loadUser();
        }
    }, [userId, loadUser, invalidated]);

    return { info, status, invalidated, loadUser };
};

export const useAuthorizationApi = () => {
    const userId = useSelector(AccessSelectors.getUserId);
    const [getUserAuthorizeStatus] = useLazyGetUserAuthorizeStatusQuery();
    const [getLocationClient] = useLazyGetLocationClientQuery();

    const isUserAuthorizedAtLocation = useCallback(
        async (locationId: string): Promise<boolean> => {
            const isAuthorizedAt = async (locationId): Promise<boolean> => {
                const client = await getUserAuthorizeStatus({ userId, locationId });
                return client?.data?.length > 0;
            };
            return isAuthorizedAt(locationId);
        },
        [getUserAuthorizeStatus, userId],
    );

    const getAuthorizedLocationClient = useCallback(
        async (locationId: string) => {
            const getAuthRecord = async (locationId) => {
                const isAuthorized = await isUserAuthorizedAtLocation(locationId);
                if (!isAuthorized) {
                    return null;
                }
                const client = await getLocationClient({ locationId });
                return client?.data?.[0];
            };
            return getAuthRecord(locationId);
        },
        [getLocationClient, isUserAuthorizedAtLocation],
    );

    return { getAuthorizedLocationClient };
};

export const useSetUserPreference = () => {
    const dispatch = useDispatch();
    const userId = useSelector(AccessSelectors.getUserId);

    const setUserPreference = useCallback(
        (key: UserPreference, value: unknown) => {
            dispatch(UserActions.setUserPreference(userId, key, value));
        },
        [dispatch, userId],
    );

    return setUserPreference;
};

export const useUserPreference = <K extends keyof IUserPreferences>(preferenceKey: K): IUserPreferences[K][] => {
    const { info } = useSelector(UserSelectors.getUserInfo);
    const value = info?.preferences?.[preferenceKey];

    return [value];
};

export const useValidLanguagePreference = (): string => {
    const [preferredLanguage] = useUserPreference(UserPreference.Language);

    const validLanguage = useMemo(() => {
        if (!preferredLanguage) {
            return;
        }
        return preferredLanguage.toLowerCase();
    }, [preferredLanguage]);

    return validLanguage;
};

export const useHandleAppLanguage = (): void => {
    const { i18n } = useTranslation();
    const expectedLanguage = useValidLanguagePreference();

    useEffect(() => {
        if (expectedLanguage && expectedLanguage != i18n.language) {
            i18n.changeLanguage(expectedLanguage);
        }
    }, [expectedLanguage, i18n]);
};
