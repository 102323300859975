import { primaryKey } from "@mswjs/data";

export const Comment = {
    id: primaryKey(String),
    referredEntityType: String,
    referredEntityId: String,
    appId: String,
    appContext: String,
    creatorId: String,
    createdTime: String,
    lastModifiedTime: String,
    content: String,
    isDeleted: Boolean,
    mentionedUserIds: Array,
};

export default Comment;
