import _sortBy from "lodash/sortBy";
import { useMemo } from "react";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { useAuthorizedLocations } from "../../../store/locations/LocationsHooks";
import { useAuthorizedSiteGroups } from "../hooks/SitesGroupsHooks";

export const useFilteredSiteObjects = (
    selectedGroupId: string,
): {
    filteredSiteObjects: ISiteObject[];
} => {
    const siteGroups = useAuthorizedSiteGroups();
    const { siteObjects } = useAuthorizedLocations();

    const sortedSiteObjects = useMemo(() => {
        return _sortBy(siteObjects, [(x) => x.city?.toLowerCase(), (x) => x.name?.toLowerCase()]);
    }, [siteObjects]);

    const filteredSiteObjects = useMemo(() => {
        const visibleSiteIds = siteGroups.find((x) => x.id === selectedGroupId)?.locationIds || [];
        return sortedSiteObjects.filter((x) => visibleSiteIds.includes(x.id));
    }, [selectedGroupId, siteGroups, sortedSiteObjects]);

    return { filteredSiteObjects };
};
