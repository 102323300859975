import { Breakpoints, Color, Icon, IconSizeType, IconType, Stack, Text } from "@secuis/ccp-react-components";
import { PropsWithChildren } from "react";
import { Tooltip } from "src/components/shared/Tooltip";
import styled from "styled-components";

type Props = PropsWithChildren & {
    id?: string;
    color: Color;
    iconSize: IconSizeType;
    variant: IconType;
    bold?: boolean;
    tooltip?: string;
    dataTestId?: string;
};

const IconStyled = styled(Icon)`
    margin-top: 1px;
    flex-shrink: 0;
    align-self: flex-start;

    @media (max-width: ${Breakpoints.L}) {
        margin-top: 0px;
    }
`;

const TooltipIcon = styled(IconStyled)`
    margin-right: 0px;
`;

export const TextWithIcon = ({ bold, children, color, iconSize, variant, tooltip, dataTestId }: Props) => {
    return (
        <Stack gap="XXS" alignItems="center" data-testid={dataTestId}>
            <IconStyled color={color} variant={variant} size={iconSize} />
            <Text bold={bold} micro color={color}>
                {children}
            </Text>
            {tooltip && (
                <Tooltip message={tooltip}>
                    <TooltipIcon color={color} variant="Info" size={iconSize} />
                </Tooltip>
            )}
        </Stack>
    );
};
