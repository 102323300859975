import { Button, ButtonText, Headline, Stack } from "@secuis/ccp-react-components";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addDays, formatExplicit, formatToRawDate, subDays } from "src/helpers/date";

import { DateInput } from "../shared/filters";

type Props = {
    locationId: string;
    date: Date;
    setDate: (d: Date) => void;
    getTasks: (args: { date: string; locationId: string }) => void;
};

export const ScheduleFilters = ({ locationId, getTasks, date, setDate }: Props) => {
    const { t } = useTranslation();
    useEffect(() => {
        getTasks({ date: formatToRawDate(date), locationId });
    }, [locationId, getTasks, date]);

    const handleDateChange = useCallback((startDate: Date) => {
        setDate(startDate);
    }, []);

    const setTodaysDate = useCallback(() => {
        setDate(new Date());
    }, []);

    const setPreviousDate = useCallback(() => {
        setDate(subDays(date, 1));
    }, [date]);

    const setNextDate = useCallback(() => {
        setDate(addDays(date, 1));
    }, [date]);

    return (
        <Stack marginVertical="S" flexWrap="wrap" gap={["M", "S", "XS"]}>
            <Button mode="outlined" fitContent onClick={setTodaysDate}>
                {t("common.date.today")}
            </Button>
            <Stack flex="1" alignItems="center" gap={["S", "S", "XS"]}>
                <ButtonText onClick={setPreviousDate} icon="ArrowBack" iconSize="XL" />
                <ButtonText onClick={setNextDate} icon="ArrowForward" iconSize="XL" />
                <Headline bold>{formatExplicit(date, "eee, PPP")}</Headline>
            </Stack>
            <Stack marginTop={["0", "0", "XS"]}>
                <DateInput
                    placeholder={t("datepicker.placeholder")}
                    onDateChange={handleDateChange}
                    selectedStartDate={date}
                    selectedEndDate={date}
                    mode="singleMonth"
                    isDaySelection
                />
            </Stack>
        </Stack>
    );
};
