import { createSlice } from "@reduxjs/toolkit";

import { EXPORT_REPORTS_INITIAL_STATE } from "./constants";
import { reportsApi } from "./reportsApi";
import { ExportStateEnum } from "./types";

export const exportReportsToExcelSlice = createSlice({
    name: "exportReportsToExcelSlice",
    initialState: EXPORT_REPORTS_INITIAL_STATE,
    reducers: {
        resetExportReports: () => EXPORT_REPORTS_INITIAL_STATE,
        fileCreated: (state) => {
            state.exportState = ExportStateEnum.fileCreated;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(reportsApi.endpoints.prefetchReportsToExport.matchPending, (state) => {
                state.exportState = ExportStateEnum.loading;
            })
            .addMatcher(reportsApi.endpoints.prefetchReportsToExport.matchFulfilled, (state, action) => {
                state.exportState = ExportStateEnum.prefetchSucceded;
                state.totalCount = action.payload.totalCount;
            })
            .addMatcher(reportsApi.endpoints.prefetchReportsToExport.matchRejected, (state) => {
                state.exportState = ExportStateEnum.prefetchFailed;
            })
            .addMatcher(reportsApi.endpoints.fetchReportsToExport.matchPending, (state) => {
                state.exportState = ExportStateEnum.loading;
            })
            .addMatcher(reportsApi.endpoints.fetchReportsToExport.matchFulfilled, (state, action) => {
                state.exportState = ExportStateEnum.fetchSucceeded;
                state.reports = action.payload.reports;
            })
            .addMatcher(reportsApi.endpoints.fetchReportsToExport.matchRejected, (state) => {
                state.exportState = ExportStateEnum.fetchFailed;
            });
    },
});
