import { createReducer } from "@reduxjs/toolkit";

import { ISitesState, SITES_SET_SELECTED_GROUP_ID } from "./types";

export const defaultState: ISitesState = {
    selectedGroupId: "",
};

const SitesReducer = createReducer(defaultState, {
    [SITES_SET_SELECTED_GROUP_ID]: (state, action) => {
        state.selectedGroupId = action.payload;
    },
});

export default SitesReducer;
