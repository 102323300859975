import { Palette, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumnJustifyCenterAlignCenter } from "../../styles/templates/Flex";

export const Container = styled.div`
    ${flexColumnJustifyCenterAlignCenter}
    width: 100vw;
    height: 100vh;
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${Palette.Navy850};
`;

export const Wrapper = styled(Stack).attrs({
    mh: "M",
    direction: "column",
})`
    max-width: 530px;
`;
