import { ReactNode } from "react";
import { ConfigFuncType, DynamicComponentConfigType, DynamicConfigPropsType, PatrolConfigFuncType } from "src/models/DynamicTemplate";
import { IReportImage } from "src/models/ReportImagesModels";
import { DynamicReportPropertyTypeEnum, DynamicReportType, PatrolTourType } from "src/models/ReportModel";

import { getUniqueReportProperties, getUnknownFieldLabel, getUnknownFieldValue } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { ImageSummaryFieldPdf, TextSummaryFieldPdf } from "../../PdfReports/shared/PdfSummarySections";
import { ImageField } from "../SharedComponents/ImageField";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";

const renderComponentsFromConfig = (config: DynamicComponentConfigType[], props: DynamicConfigPropsType<DynamicReportType | PatrolTourType>): ReactNode[] =>
    config.map((item, index) => {
        const Component = item.component;
        const componentProps = item.props ?? null;
        const children = item.children?.length ? renderComponentsFromConfig(item.children, props) : null;

        if (item.private && props.sharedReportId) {
            return null;
        }

        // dont render if no data or component
        if (!item.component || (!componentProps && !children)) {
            return null;
        }

        if (children) {
            return (
                <Component key={index} {...componentProps}>
                    {children}
                </Component>
            );
        }
        return <Component key={index} {...componentProps} />;
    });

export const generateReportComponents = (
    props: DynamicConfigPropsType<DynamicReportType | PatrolTourType | any>,
    configFn: ConfigFuncType | PatrolConfigFuncType,
): ReactNode[] => {
    const {
        report: { template, tourSourceId },
    } = props;

    if (!template && !tourSourceId) {
        return null;
    }
    const config = configFn(props);

    if (!config.length) {
        return [];
    }

    return renderComponentsFromConfig(config, props);
};

export const getUnknownFieldsComponents = (
    report: DynamicReportType,
    sharingId: string,
    fields?: string[],
    hasValue?: boolean,
): DynamicComponentConfigType[] => {
    const uniqueProperties = getUniqueReportProperties(report);
    const fieldComponents: DynamicComponentConfigType[] = uniqueProperties.map((prop) => {
        const isUnknownField = !fields.map((f) => f.toLowerCase()).includes(prop.reportFieldName.toLowerCase());
        if (!isUnknownField || (hasValue && !prop.value)) {
            return null;
        }
        if (prop.type === DynamicReportPropertyTypeEnum.image) {
            return {
                component: ImageField,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: prop.value,
                    isRequired: false,
                    sharingId,
                },
            };
        }
        if (prop.type !== DynamicReportPropertyTypeEnum.resource) {
            return {
                component: TextSummaryField,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: getUnknownFieldValue(prop.value, prop.type),
                    isRequired: false,
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            };
        }
    });
    return fieldComponents.filter((item) => item);
};

export const getPdfUnknownFields = (report: DynamicReportType, fields: string[], images: IReportImage[], hasValue?: boolean): DynamicComponentConfigType[] => {
    const uniqueProperties = getUniqueReportProperties(report);
    const fieldComponents: DynamicComponentConfigType[] = uniqueProperties.map((prop) => {
        const isUnknownField = !fields.map((f) => f.toLowerCase()).includes(prop.reportFieldName.toLowerCase());
        if (!isUnknownField) {
            return null;
        }
        if (prop.type === DynamicReportPropertyTypeEnum.image) {
            const image = images.find((i) => i.reportFieldName === prop.reportFieldName);
            if (!image || image.error || image.loading) {
                return null;
            }
            return {
                component: ImageSummaryFieldPdf,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: image,
                    isRequired: false,
                },
            };
        }
        if (hasValue && !prop.value) {
            return null;
        }
        if (prop.type !== DynamicReportPropertyTypeEnum.resource) {
            return {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: getUnknownFieldValue(prop.value, prop.type),
                    isRequired: false,
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            };
        }
    });
    return fieldComponents.filter((item) => item);
};

export const conditionalRender = (value, renderFn) => {
    return value ? renderFn() : [];
};
