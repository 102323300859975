import { Box, Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

const groupsListItemInteractionStyles = css`
    cursor: pointer;

    @media only screen and (min-width: ${Breakpoints.XS}) {
        &:hover {
            background-color: ${Palette.Navy800};
        }
    }

    &:active {
        background-color: ${Palette.Navy850};
    }
`;

export const GroupsListItemStyled = styled(Box)<{ isDisabled: boolean }>`
    padding: 18px 26px;
    margin-bottom: ${Spacing.XS}px;
    background-color: ${Palette.Navy750};
    outline: none;

    @media only screen and (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }

    ${({ isDisabled }) => (!isDisabled ? groupsListItemInteractionStyles : "")}
`;
