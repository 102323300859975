import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { SafetyInspectionFields } from "../../../../models/reportTemplates/SafetyInspection";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getSafetyInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    conditionalRender(getFieldValueByFieldName(SafetyInspectionFields.description, report), () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.safetyInspection.description",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName("description", report),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        };
    }),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.safetyInspection.summary.title",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.safetyInspection.summary.typeOfInspection",
                    fieldValue: getFieldValueByFieldName(SafetyInspectionFields.typeOfInspection, report),
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.safetyInspection.summary.wasEverythingInOrder",
                    fieldValue: getFieldValueByFieldName(SafetyInspectionFields.wasEverythingInOrder, report),
                    shouldAutoTranslate: true,
                },
            },
            ...getPdfUnknownFields(report, Object.values(SafetyInspectionFields), images),
        ],
    },
];
