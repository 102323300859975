import { RSAA } from "redux-api-middleware";

import {
    USER_GET_INFO_FAILURE,
    USER_GET_INFO_REQUEST,
    USER_GET_INFO_SUCCESS,
    USER_SET_PREFERENCE_FAILURE,
    USER_SET_PREFERENCE_REQUEST,
    USER_SET_PREFERENCE_SUCCESS,
} from "../user/types";

const setUserPreference = (userId: string, key: string, value: any) => ({
    [RSAA]: {
        auth: true,
        body: JSON.stringify({
            preferences: [
                {
                    key,
                    value,
                },
            ],
        }),
        endpoint: `/v1/users/${userId}/preferences`,
        method: "POST",
        types: [
            USER_SET_PREFERENCE_REQUEST,
            {
                payload: { key, value },
                type: USER_SET_PREFERENCE_SUCCESS,
            },
            USER_SET_PREFERENCE_FAILURE,
        ],
    },
});

const getUserInfo = (userId: string) => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/users/byId/${userId}`,
        method: "GET",
        types: [USER_GET_INFO_REQUEST, USER_GET_INFO_SUCCESS, USER_GET_INFO_FAILURE],
    },
});

const actions = {
    getUserInfo,
    setUserPreference,
};

export default actions;
