import styled from "styled-components";

import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { ImageSizePaths } from "../../../models/ReportImagesModels";
import { DynamicReportType } from "../../../models/ReportModel";
import { VehicleInspectionFields } from "../../../models/reportTemplates/VehicleInspection";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { PicturesSection } from "../SharedComponents/Pictures";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { TextTextAreaSummaryField } from "../SharedComponents/TextTextAreaSummaryField";
import { VehicleInspectionDetailsHeader } from "../VehicleInspectionDetailsHeader/VehicleInspectionDetailsHeader";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getVehicleInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    getHeader(report, siteObject, sharedReportId),
    getVehicleInspectionDescriptionSection(report, sharedReportId),
    getIndicateTheLocationsOfDamageToTheVehicleSection(report, sharedReportId),
    getVehicleInspectionSummarySection(report, sharedReportId),
    getVehicleStatusSection(report),
];

function getHeader(report: DynamicReportType, siteObject: ISiteObject, sharedReportId: string): DynamicComponentConfigType {
    return {
        component: VehicleInspectionDetailsHeader,
        props: {
            report: report,
            siteObject: siteObject,
            isShared: !!sharedReportId,
        },
    };
}

function getVehicleInspectionDescriptionSection(report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType {
    return conditionalRender(!!getFieldValueByFieldName(VehicleInspectionFields.comments, report) || report.images.length, () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.vehicleInspection.vehicleInspectionDescription",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(VehicleInspectionFields.comments, report),
                        sharedReportId: sharedReportId,
                        imagesData: report.images,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    });
}

// this is the height of the sample image we received
export const VEHICLE_IMAGE_HEIGHT_IN_PX = 191;

const StyledPicturesSection = styled(PicturesSection)`
    img {
        max-width: 100%;
    }
    max-height: ${VEHICLE_IMAGE_HEIGHT_IN_PX}px;
`;

function getIndicateTheLocationsOfDamageToTheVehicleSection(report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType {
    const vehicleImage = report.properties.find((p) => p.reportFieldName === VehicleInspectionFields.indicateTheLocationsOfDamageToTheVehicle)?.value;
    return conditionalRender(vehicleImage, () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.vehicleInspection.indicateTheLocationsOfDamageToTheVehicle",
            },
            children: [
                {
                    component: StyledPicturesSection,
                    props: {
                        sharingId: sharedReportId,
                        imagesData: [vehicleImage],
                        imageSize: ImageSizePaths.Medium,
                    },
                },
            ],
        };
    });
}

function getVehicleInspectionSummarySection(report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType {
    return {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.vehicleInspection.vehicleInspectionSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.exteriorDemage",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.exteriorDamage, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.interiorDamage",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.interiorDamage, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.brokenCrackedWindows",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.brokenCrackedWindows, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextTextAreaSummaryField,
                props: {
                    textSummaryFieldProps: {
                        fieldName: "reportdetails.vehicleInspection.isAllEmergencyEquipmentAvailable",
                        fieldValue: getFieldValueByFieldName(VehicleInspectionFields.isAllEmergencyEquipmentAvailable, report),
                        mobileDirection: "column",
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                    textAreaSummaryFieldProps: {
                        title: "reportdetails.vehicleInspection.missingEquipment",
                        content: getFieldValueByFieldName(VehicleInspectionFields.missingEquipment, report),
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(VehicleInspectionFields)),
        ],
    };
}

function getVehicleStatusSection(report: DynamicReportType): DynamicComponentConfigType {
    return conditionalRender(shouldRenderVehicleStatusSection(report), () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.vehicleInspection.vehicleStatus",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.mileageStart",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.mileageStart, report),
                    mobileDirection: "column",
                    isRequired: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.mileageEnd",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.mileageEnd, report),
                    mobileDirection: "column",
                    isRequired: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.fuelLevel",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.fuelLevel, report),
                    mobileDirection: "column",
                    isRequired: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.vehicleInspection.oilLevel",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.oilLevel, report),
                    mobileDirection: "column",
                    isRequired: false,
                },
            },
        ],
    }));
}

export const shouldRenderVehicleStatusSection = (report: DynamicReportType) => {
    return (
        getFieldValueByFieldName(VehicleInspectionFields.mileageStart, report) ||
        getFieldValueByFieldName(VehicleInspectionFields.mileageEnd, report) ||
        getFieldValueByFieldName(VehicleInspectionFields.fuelLevel, report) ||
        getFieldValueByFieldName(VehicleInspectionFields.oilLevel, report)
    );
};
