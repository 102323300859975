import { RSAA } from "redux-api-middleware";

import { getApiVersion, parseDateRangeRequestBody } from "../helpers";
import { GetByDateRangeAndSiteIds } from "../InsightsModel";
import {
    INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_FAILURE,
    INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_REQUEST,
    INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_SUCCESS,
    INSIGHTS_PATROL_DEVIATIONS_LEVEL2_REQUEST,
    INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS,
    INSIGHTS_PATROL_DEVIATIONS_REQUEST,
    INSIGHTS_PATROL_DEVIATIONS_SUCCESS,
} from "../types";
import { PatrolDeviationData } from "./PatrolDeviationsTypes";

export const requestPatrolDeviationsComparePeriodHourly = (body: GetByDateRangeAndSiteIds, withSiteZones = false) => {
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/${getApiVersion(withSiteZones)}/global/insights/tour-exc/summary/hourly`,
            method: "POST",
            body: parseDateRangeRequestBody(body, withSiteZones),
            types: [
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_REQUEST },
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_SUCCESS },
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_FAILURE },
            ],
        },
    };
};
export const requestPatrolDeviationsComparePeriodDaily = (body: GetByDateRangeAndSiteIds, categoryLevel1?: string, withSiteZones = false) => {
    const summaryEndpoint = `/${getApiVersion(withSiteZones)}/global/insights/tour-exc/summary/daily`;
    const endpoint = categoryLevel1 ? `${summaryEndpoint}/${categoryLevel1}` : summaryEndpoint;

    return {
        [RSAA]: {
            auth: true,
            endpoint,
            method: "POST",
            body: parseDateRangeRequestBody(body, withSiteZones),
            types: [
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_REQUEST },
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_SUCCESS },
                { type: INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_FAILURE },
            ],
        },
    };
};

export const requestPatrolDeviations = (): {
    type: string;
} => ({
    type: INSIGHTS_PATROL_DEVIATIONS_REQUEST,
});

export const patrolDeviationsSuccess = (
    result: PatrolDeviationData[],
    queryKey: string,
): {
    type: string;
    payload: PatrolDeviationData[];
    queryKey: string;
} => ({
    type: INSIGHTS_PATROL_DEVIATIONS_SUCCESS,
    payload: result,
    queryKey,
});

export const requestPatrolDeviationsLevel2 = (): {
    type: string;
} => ({
    type: INSIGHTS_PATROL_DEVIATIONS_LEVEL2_REQUEST,
});

export const patrolDeviationsLevel2Success = (
    result: PatrolDeviationData[],
    categoryLevel1: string,
): {
    type: string;
    payload: PatrolDeviationData[];
    categoryLevel1: string;
} => ({
    type: INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS,
    payload: result,
    categoryLevel1: categoryLevel1,
});
