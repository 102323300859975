import { HttpResponse } from "msw";

import { formatExplicit, formatExplicitDateTimeWithTimezone } from "../../helpers/date";
import { ReportTemplateEnum } from "../../models/ReportModel";
import { db } from "../db";
import { ReportInsight } from "../types";

export const getReportsInsightsResolver = async (minDateTime: string, siteIds: string[]) => {
    const reports = db.reports.findMany({
        where: {
            locationId: {
                in: siteIds,
            },
            template: {
                notIn: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException, ReportTemplateEnum.patrolTour],
            },
            categoryLevel1: {
                notEquals: "",
            },
            siteTimestamp: {
                gte: new Date(minDateTime).getTime(),
            },
        },
    });

    const result: ReportInsight[] = reports.map((report, index) => {
        return {
            id: index,
            mys_id: report.id,
            location_id: report.locationId,
            template: report.template,
            child_template: report.childTemplate,
            root_site_location: report.siteLocation,
            category_level1: report.categoryLevel1,
            category_level2: report.categoryLevel2,
            category_level3: report.categoryLevel3,
            category_key: `${report.categoryLevel2}_${report.categoryLevel3}`,
            severity_level: report.severityLevel,
            report_date_time: formatExplicit(report.createDateTime, "yyyy-MM-dd HH:mm:ss.SSS"),
            report_date_time_local: formatExplicitDateTimeWithTimezone(report.createDateTime, report.siteIanaTimezone, "yyyy-MM-dd HH:mm:ss.SSS"),
            last_updated_ts: report.reportDateTime,
            is_read: 0,
        };
    });

    return HttpResponse.json(result);
};

export default getReportsInsightsResolver;
