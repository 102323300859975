import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Databases, IDbContext, QueryResult } from "../models/DbModel";
import { SqlWorkerType, useSqlWorker } from "../sql/hooks/useSqlWorker";
import InsightsActions from "../store/insights/InsightsActions";
import InsightsSelectors from "../store/insights/InsightsSelectors";
import { RequestStatus } from "../store/RequestStatus";
import { InsightsLoader } from "./Insights/InsightsLoader";

export const DbsContext = createContext<Record<string, IDbContext>>({
    reportsDb: { dbReadyToQuery: false },
    exceptionsAggDb: { dbReadyToQuery: false },
    toursAggDb: { dbReadyToQuery: false },
});

type DatabaseProviderProps = {
    children?: ReactNode;
};
const DatabaseProvider: React.FC<DatabaseProviderProps> = ({ children }) => {
    const dispatch = useDispatch();
    const reportsDb = useSqlWorker();
    const reportsDbRef = useRef(reportsDb);
    const databasesState = useSelector(InsightsSelectors.getAllDatabasesState);

    const dbMapping: Record<string, SqlWorkerType> = useMemo(() => {
        return {
            [Databases.Reports]: reportsDb,
        };
    }, [reportsDb]);

    const loadDatabase = useCallback(
        async (id, binaryData) => {
            const db = dbMapping[id];
            await db?.open(binaryData);
        },
        [dbMapping],
    );

    useEffect(() => {
        const reportsWorker = reportsDbRef?.current;
        return () => {
            if (reportsWorker) {
                reportsWorker.terminate();
            }
            dispatch(InsightsActions.resetDatabases());
        };
    }, [dispatch]);

    const query = useCallback(
        (id: string, sql: string, params = {}): Promise<QueryResult[]> => {
            const db = dbMapping[id];
            const executePromise = db.execute(sql, params);
            return executePromise.then((e) => e?.results);
        },
        [dbMapping],
    );

    const getDbContext = useCallback(
        (id: string) => {
            const db = dbMapping[id];
            return {
                ...db,
                dbReadyToQuery: databasesState[id].loadStatus === RequestStatus.success,
                query: (sql: string, params) => query(id, sql, params),
                open: (binaryData) => loadDatabase(id, binaryData),
            };
        },
        [dbMapping, loadDatabase, query, databasesState],
    );

    const dbsContext: Record<string, IDbContext> = useMemo(() => {
        return {
            [Databases.Reports]: getDbContext(Databases.Reports),
        };
    }, [getDbContext]);

    return (
        <DbsContext.Provider value={dbsContext}>
            <InsightsLoader />
            {children}
        </DbsContext.Provider>
    );
};

export default DatabaseProvider;
