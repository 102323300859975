type PreviousMonth = {
    month: number;
    year: number;
};

export const getPreviousMonths = (monthsNumber: number): PreviousMonth[] => {
    const months: PreviousMonth[] = [];
    const currentDate = new Date();

    for (let i = 0; i < monthsNumber; i++) {
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() - i;

        if (month < 0) {
            month += 12;
            year -= 1;
        }

        months.push({
            month: month + 1,
            year: year,
        });
    }

    return months;
};

export default getPreviousMonths;
