const CORRECTION_ANGLE = 10;

type PropsType = {
    cx: number;
    cy: number;
    midAngle: number;
    outerRadius: number;
    payload: { value: number; fill: string };
    direction?: "up" | "down";
};

export const ChartLine = ({ cx, cy, midAngle, outerRadius, direction, payload }: PropsType): JSX.Element => {
    const correction = direction === "down" ? CORRECTION_ANGLE : -CORRECTION_ANGLE;
    const angle = direction ? midAngle + correction : midAngle;
    const RADIAN = Math.PI / 180;
    const sx = cx + (outerRadius - 3) * Math.cos(-RADIAN * midAngle);
    const sy = cy + (outerRadius - 3) * Math.sin(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 20) * Math.cos(-RADIAN * angle);
    const my = cy + (outerRadius + 20) * Math.sin(-RADIAN * angle);

    return <path d={`M${sx},${sy}L${mx},${my}`} strokeWidth={3} stroke={payload.fill} fill={payload.fill} />;
};
