import { ContactInfoType } from "src/models/ReportModel";

const SECURITAS_CONTACT_INFO_FINLAND: ContactInfoType = {
    companyName: "Securitas Oy",
    businessId: "1773518-5",
    address: "Elimäenkatu 28, 00520 Helsinki",
    phone: "020 4911",
    postalAddress: "PL 93, 00521 Helsinki",
};

export const contactInfoByCountry = {
    fi: SECURITAS_CONTACT_INFO_FINLAND,
};
