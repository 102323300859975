import findTimezone from "@photostructure/tz-lookup";

import { formatDateTimeWithTimezone } from "../../helpers/date";
import { ISiteObject } from "../../models/SiteObjectModel";

export { getDaysListForPastMonths } from "./getDaysListForPastMonths";
export { getPreviousMonths } from "./getPreviousMonths";
export { getRandomCategory } from "./getRandomCategory";
export { getRandomDates } from "./getRandomDates";
export { getReportProperties } from "./getReportProperties";
export { pickRandomNthElement } from "./pickRandomNthElement";
export { getSubcategories } from "./getSubcategories";
export { createDateTimeFilter } from "./createDateTimeFilter";

export const getSiteTimezone = (siteObject: ISiteObject): string => {
    const [lat, lng] = siteObject.coordinates;

    return [lat, lng].includes(undefined) ? "Universal" : findTimezone(lat, lng);
};

export const getFakeSiteTimestamp = (localTimestamp: number, timezone: string) => {
    const siteDateTime = formatDateTimeWithTimezone(localTimestamp, timezone);
    return new Date(siteDateTime).getTime();
};
