import { ButtonText, Palette, Spacing } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled, { css } from "styled-components";

import { BoxShadow } from "../../../styles/templates/BoxShadow";
import { flexRowAlignCenter } from "../../../styles/templates/Flex";
import { mediumText } from "../../../styles/templates/Typography";

interface ContextMenuProps {
    float?: "left" | "right";
}

interface ContextMenuItemProps {
    color?: string;
}

const expandMenuOnLeft = css`
    right: 0;
`;

export const ContextMenuWrapper = styled.div`
    ${mediumText};
    position: relative;
    display: inline-block;
`;

export const ContextMenuListStyled = styled.div<ContextMenuProps>`
    z-index: 2;
    position: absolute;
    top: 0;
    ${(props) => props.float === "left" && expandMenuOnLeft}

    min-width: 176px;
    background-color: ${Palette.Navy650};
    box-shadow: ${BoxShadow.M};
    border-radius: 8px;

    button {
        padding: ${Spacing.S - Spacing.XXS}px ${Spacing.S - Spacing.XXS}px;
    }

    button:last-child {
        margin-bottom: 0px;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const ContextMenuItemStyled = styled.button<ContextMenuItemProps>`
    width: 100%;
    letter-spacing: 0.1px;
    cursor: pointer;
    ${flexRowAlignCenter};
    gap: ${Spacing.XS}px;

    &:hover {
        background-color: ${Palette.Purple400};
    }
    &:active {
        background-color: ${Palette.Purple500};
    }
    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &:focus-visible {
        outline-offset: ${FocusStyles.OutlineOffsetNegative}px !important;
    }
`;

export const ContextMenuOverlay = styled.div`
    z-index: 1000;
    position: fixed;
    overflow-y: hidden;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
`;

export const ContextMenuModal = styled.div`
    position: absolute;
    overflow-y: hidden;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${Spacing.S}px 0 ${Spacing.XS}px 0;
    background-color: ${Palette.Navy700};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: ${Spacing.S}px ${Spacing.S}px 0px 0px;
`;

export const ContextMenuHeader = styled.div`
    align-self: center;
`;

export const ContextMenuBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${Spacing.XS}px;
    padding-top: ${Spacing.XS}px;
    padding-bottom: ${Spacing.M}px;
`;

export const ContextMenuRow = styled.div`
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    padding: 16px 26px;

    svg {
        margin-right: ${Spacing.XS}px;
    }

    &:active {
        background-color: ${Palette.Purple500};
    }
`;

export const ContextMenuButtonWrapper = styled.div`
    padding: 0 ${Spacing.M}px;
`;

export const ContextMenuButtonTextStyled = styled(ButtonText)`
    button:focus-visible {
        outline-offset: -4px;
    }
`;
