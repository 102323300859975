import { formatDateTimeWithTimezone, formatFriendly, IsoStringDate, isThisYear } from "../../../../../../helpers/date";

export const useListItemDate = ({ reportDateTime, siteTimezone }: { reportDateTime: IsoStringDate; siteTimezone?: string }) => {
    const userLocalDate = formatFriendly(reportDateTime);
    const siteLocalDateFull = formatDateTimeWithTimezone(reportDateTime, siteTimezone, "midDateTime");
    const isSiteDateInCurrentYear = isThisYear(new Date(siteLocalDateFull));

    const siteLocalDate = isSiteDateInCurrentYear ? formatDateTimeWithTimezone(reportDateTime, siteTimezone, "dayMonthDateTime") : siteLocalDateFull;

    return {
        userLocalDate,
        siteLocalDate,
    };
};
