import { Breakpoints, Button, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";

import onboarding1 from "../../assets/images/onboarding/onboarding_1.jpg";
import onboarding1GraphicsDesktop from "../../assets/images/onboarding/onboarding_1_graphics_desktop.png";
import onboarding1GraphicsMobile from "../../assets/images/onboarding/onboarding_1_graphics_mobile.png";
import onboarding2 from "../../assets/images/onboarding/onboarding_2.jpg";
import onboarding2GraphicsDesktop from "../../assets/images/onboarding/onboarding_2_graphics_desktop.png";
import onboarding2GraphicsMobile from "../../assets/images/onboarding/onboarding_2_graphics_mobile.png";
import onboarding3 from "../../assets/images/onboarding/onboarding_3.jpg";
import onboarding3GraphicsDesktop from "../../assets/images/onboarding/onboarding_3_graphics_desktop.png";
import onboarding3GraphicsMobile from "../../assets/images/onboarding/onboarding_3_graphics_mobile.png";
import onboarding4 from "../../assets/images/onboarding/onboarding_4.jpg";
import onboarding4GraphicsDesktop from "../../assets/images/onboarding/onboarding_4_graphics_desktop.png";
import onboarding4GraphicsMobile from "../../assets/images/onboarding/onboarding_4_graphics_mobile.png";
import {
    Bottom,
    ButtonStyled,
    GetStartedButton,
    ImageTop,
    InsideGradient,
    ModalContainer,
    ModalContent,
    Navigation,
    NavigationButtonsContainer,
    Slide,
    StepIndicator,
    StepsIndicatorContainer,
    SubtitlesTextStyed,
    TitleTextStyled,
} from "./Onboarding.styled";

const slides = [
    {
        backgroundImageSrc: onboarding1,
        graphicsSrc: onboarding1GraphicsDesktop,
        graphicsSrcMobile: onboarding1GraphicsMobile,
        graphicsWidth: "75%",
        header: "onboarding.slides.1.header",
        subheader: "onboarding.slides.1.subheader",
    },
    {
        backgroundImageSrc: onboarding2,
        graphicsSrc: onboarding2GraphicsDesktop,
        graphicsSrcMobile: onboarding2GraphicsMobile,
        graphicsWidth: "95%",
        header: "onboarding.slides.2.header",
        backgroundPosition: "bottom",
    },
    {
        backgroundImageSrc: onboarding3,
        graphicsSrc: onboarding3GraphicsDesktop,
        graphicsSrcMobile: onboarding3GraphicsMobile,
        graphicsWidth: "77%",
        header: "onboarding.slides.3.header",
        backgroundPosition: "bottom",
    },
    {
        backgroundImageSrc: onboarding4,
        graphicsSrc: onboarding4GraphicsDesktop,
        graphicsSrcMobile: onboarding4GraphicsMobile,
        graphicsWidth: "75%",
        header: "onboarding.slides.4.header",
        backgroundPosition: "top",
    },
];

interface OnboardingModalProps {
    onClose: () => void;
}

export const OnbardingModal = (props: OnboardingModalProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const { t } = useTranslation();

    const NextSlide = () => {
        setSelectedItem((prev) => prev + 1);
    };

    return (
        <ModalContainer role="modal-wrapper">
            <ModalContent data-testid="modal-content">
                <Carousel showIndicators={false} showStatus={false} showThumbs={false} swipeable={false} showArrows={false} selectedItem={selectedItem}>
                    {slides.map((slide, index) => (
                        <Slide background={slide.backgroundImageSrc} backgroundPosition={slide.backgroundPosition} key={"slide" + index}>
                            <InsideGradient>
                                <ImageTop width={slide.graphicsWidth} src={isMobile ? slide.graphicsSrcMobile : slide.graphicsSrc} />
                            </InsideGradient>
                        </Slide>
                    ))}
                </Carousel>
                <Bottom data-testid="welcome-card-bottom">
                    <SubtitlesTextStyed>{t(slides[selectedItem].subheader)}</SubtitlesTextStyed>
                    <TitleTextStyled>{t(slides[selectedItem].header)}</TitleTextStyled>
                    <Navigation>
                        <StepsIndicatorContainer data-testid="steps-indicator-container">
                            {slides.map((_, index) => (
                                <StepIndicator
                                    data-testid="step-indicator"
                                    selected={selectedItem === index}
                                    key={"indicator" + index}
                                    onClick={() => setSelectedItem(index)}
                                />
                            ))}
                        </StepsIndicatorContainer>
                        <NavigationButtonsContainer data-testid="navigation-buttons-container">
                            {selectedItem < 3 ? (
                                <>
                                    <Button data-testid="next-button" onClick={NextSlide} mode="contained">
                                        {t("onboarding.next")}
                                    </Button>
                                    <ButtonStyled data-testid="skip-button" color="primary" onClick={props.onClose}>
                                        {t("onboarding.skip")}
                                    </ButtonStyled>
                                </>
                            ) : (
                                <GetStartedButton data-testid="get-started-button" mode="contained" onClick={props.onClose}>
                                    {t("onboarding.getStarted")}
                                </GetStartedButton>
                            )}
                        </NavigationButtonsContainer>
                    </Navigation>
                </Bottom>
            </ModalContent>
        </ModalContainer>
    );
};
