import { Breakpoints } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { SummaryViewMode } from "../../../../store/insights/InsightsModel";
import { DeviantDayWidget } from "../DeviantDayWidget";
import { DeviantHourWidget } from "../DeviantHourWidget/DeviantHourWidget";
import { IncidentCategoriesWidget } from "../IncidentCategoriesWidget";
import { IncidentDistributionWidget } from "../IncidentDistributionWidget";
import { PerformanceIndicatorsWidget } from "../PerformanceIdicatorsWidget";
import { ReportsCountWidget } from "../ReportsCountWidget/ReportsCountWidget";
import { SiteEventsWidget } from "../SiteEventsWidget";
import { SiteReportsWidget } from "../SiteReportsWidget";
import { GridWidgetConfig } from "./SummaryGrid.types";

const PERFORMANCE_INDICATORS: GridWidgetConfig = {
    key: "performance-indicators",
    Widget: PerformanceIndicatorsWidget,
    style: { gridColumn: "1 / -1" },
};
const REPORTS_COUNT: GridWidgetConfig = {
    key: "reports-count",
    Widget: ReportsCountWidget,
};
const INCIDENT_CATEGORIES: GridWidgetConfig = {
    key: "incident-categories",
    Widget: IncidentCategoriesWidget,
};
const SITE_REPORTS: GridWidgetConfig = {
    key: "site-reports",
    Widget: SiteReportsWidget,
};
const SITE_EVENTS: GridWidgetConfig = {
    key: "site-events",
    Widget: SiteEventsWidget,
};
const EVENT_DISTRIBUTION: GridWidgetConfig = {
    key: "event-distribution",
    Widget: IncidentDistributionWidget,
};
const DEVIANT_DAY: GridWidgetConfig = {
    key: "deviant-day",
    Widget: DeviantDayWidget,
};
const DEVIANT_HOUR: GridWidgetConfig = {
    key: "deviant-hour",
    Widget: styled(DeviantHourWidget)`
        grid-column: 1 / 3;

        @media (max-width: ${Breakpoints.L}) {
            grid-column: 1 / -1;
        }
    `,
};

const MULTI_SITE_MONTHLY_CONFIG: GridWidgetConfig[] = [
    PERFORMANCE_INDICATORS,
    REPORTS_COUNT,
    SITE_REPORTS,
    SITE_EVENTS,
    INCIDENT_CATEGORIES,
    EVENT_DISTRIBUTION,
    DEVIANT_DAY,
    DEVIANT_HOUR,
];

const SINGLE_SITE_MONTHLY_CONFIG: GridWidgetConfig[] = [
    PERFORMANCE_INDICATORS,
    REPORTS_COUNT,
    INCIDENT_CATEGORIES,
    EVENT_DISTRIBUTION,
    DEVIANT_DAY,
    {
        key: DEVIANT_HOUR.key,
        Widget: styled(DEVIANT_HOUR.Widget)`
            @container (min-width: ${Breakpoints.L}) {
                grid-column: 2 / -1;
            }
        `,
    },
];

const MULTI_SITE_DAILY_CONFIG: GridWidgetConfig[] = [
    PERFORMANCE_INDICATORS,
    REPORTS_COUNT,
    SITE_REPORTS,
    SITE_EVENTS,
    INCIDENT_CATEGORIES,
    EVENT_DISTRIBUTION,
    DEVIANT_HOUR,
];

const SINGLE_SITE_DAILY_CONFIG: GridWidgetConfig[] = [PERFORMANCE_INDICATORS, REPORTS_COUNT, INCIDENT_CATEGORIES, EVENT_DISTRIBUTION, DEVIANT_HOUR];

export const GRID_CONFIGS_MAP: Record<SummaryViewMode, Record<"singleSite" | "multiSite", GridWidgetConfig[]>> = {
    day: {
        singleSite: SINGLE_SITE_DAILY_CONFIG,
        multiSite: MULTI_SITE_DAILY_CONFIG,
    },
    month: {
        singleSite: SINGLE_SITE_MONTHLY_CONFIG,
        multiSite: MULTI_SITE_MONTHLY_CONFIG,
    },
};
