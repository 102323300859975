import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PatrolTourType } from "../../../models/ReportModel";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { IUser } from "../../../models/UserModel";
import { HeaderLocationLine } from "./HeaderLocationLine.tsx";
import { PdfIndentSection } from "./PdfIndentSection";
import { PdfPatrolDetailsHeaderLine1 } from "./PdfPatrolDetailsHeaderLine1";
import { PdfTemplateHeadline } from "./PdfTemplateHeadline";

type Props = {
    report: PatrolTourType;
    siteObject: ISiteObject;
    isShared: boolean;
    user: IUser;
};

export const PdfPatrolDetailsHeader = ({ report, siteObject, isShared, user }: Props) => {
    const { t } = useTranslation();
    const headerText = useMemo(() => {
        if (report.exceptionReportIds.length) {
            return `${report.tourName} • ${t("reportdetails.patrolTour.exceptions", { count: report.exceptionReportIds.length })}`;
        }
        return report.tourName;
    }, [report, t]);
    return (
        <PdfIndentSection>
            <PdfTemplateHeadline header={headerText} subheader={t("reportdetails.header.patrolTour")} />
            <PdfPatrolDetailsHeaderLine1 report={report} isShared={isShared} user={user} />
            <HeaderLocationLine siteObject={siteObject} />
        </PdfIndentSection>
    );
};
