import Accordion from "../Accordion/Accordion";
import { IMultiListItem } from "../CheckboxList/CheckboxListItem.types";
import { FilterObject } from "./FilterObject";

export interface IFilterObject {
    title: string;
    items: IMultiListItem[];
    isLoading: boolean;
    selectedIds: string[];
    cleanFilter: () => void;
    handleCheckChange: (item: IMultiListItem) => void;
    selectAll?: () => void;
}

export const FilterObjectWrapper = (props: IFilterObject) => {
    return (
        <Accordion>
            <FilterObject {...props} />
        </Accordion>
    );
};
