import { useEffect, useState } from "react";
import { useMemoizedDependency } from "src/hooks/useMemoizedDependency";

type ToggleVisibleItemsProps<T> = {
    items: T[];
    maxVisibleItems: number;
};

export const useToggleVisibleItems = <T>({ items, maxVisibleItems }: ToggleVisibleItemsProps<T>) => {
    const [visibleItems, setVisibleItems] = useState<T[]>(items.slice(0, maxVisibleItems));
    const [isExpanded, setIsExpanded] = useState(false);
    const memoizedItems = useMemoizedDependency(items);

    useEffect(() => {
        if (memoizedItems.length > 0 && memoizedItems.length <= maxVisibleItems) {
            setIsExpanded(false);
        }
    }, [memoizedItems, maxVisibleItems]);

    useEffect(() => {
        setVisibleItems(isExpanded ? memoizedItems : memoizedItems.slice(0, maxVisibleItems));
    }, [memoizedItems, maxVisibleItems, isExpanded]);

    const showMoreItems = () => setIsExpanded(true);
    const showLessItems = () => setIsExpanded(false);

    const collapseVisibleItems = () => {
        setIsExpanded(false);
    };

    return {
        visibleItems,
        showMoreItems,
        showLessItems,
        collapseVisibleItems,
    };
};
