import { createHourLabelForDate, set } from "src/helpers/date";

const createLocalizedHoursRanges = (hours: number[]): { value: string; label: string }[] => {
    return hours
        .map((rangeEndHours, index, array) => {
            if (index) {
                const rangeStartHours = array[index - 1];
                const rangeStartDate = set(Date.now(), { hours: rangeStartHours, minutes: 0, milliseconds: 0 });
                const rangeEndDate = set(rangeStartDate, { hours: rangeEndHours });

                return {
                    value: `${padWithZero(`${rangeStartHours}`)}-${padWithZero(`${rangeEndHours}`)}`,
                    label: `${createHourLabelForDate(rangeStartDate)}-${createHourLabelForDate(rangeEndDate)}`,
                };
            }
        })
        .filter(Boolean);
};

const padWithZero = (text: string) => text.padStart(2, "0");

export const HOURS_RANGES = createLocalizedHoursRanges([0, 6, 12, 18, 24]);
