import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ReportsFilter } from "../../components/Reports/ReportsFilter";
import { FilterIcon } from "../../components/shared/filters";
import { CollapsedFilterWrapper } from "../../components/shared/filters/FilterToolbar.styles";
import { useQueryString, useSetFiltersByParams, useUrlSearchUpdate } from "../../hooks/CommonHooks";
import { ReportTemplateEnum } from "../../models/ReportModel";
import { MixpanelEvent } from "../../models/tracking/mixpanelEvents";
import { PageNames } from "../../models/tracking/pageView";
import { useDisplaySwitcherBanner } from "../../store/clientSwitcher/ClientSwitcherHooks";
import { useReportListFiltersCount, useReportSearch } from "../../store/reports/ReportsHooks";
import TrackingActions from "../../store/tracking/TrackingActions";
import { useTrackPageVisit } from "../../store/tracking/TrackingHooks";
import { ReportListWrapper } from "./Reports.styles";
import { ReportsList } from "./ReportsList";
import { ReportListDetails } from "./ReportsList/ReportDetails/ReportListDetails";

export const Reports = () => {
    const dispatch = useDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showFilterToolbar, setShowFilterToolbar] = useState(false);
    const [showLocationFilterMenu, setShowLocationFilterMenu] = useState(false);
    const [areDetailsOpen, setAreDetailsOpen] = useState(false);

    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { reportId, locationId, type } = useQueryString();
    const [updateQueryString] = useUrlSearchUpdate();
    const { reports, refreshing, totalCount, loadMoreReports } = useReportSearch();
    const displayBanner = useDisplaySwitcherBanner();
    const selectedFiltersCount = useReportListFiltersCount();
    useTrackPageVisit(PageNames.ReportList);

    const displaySwitcherBanner = displayBanner && (!isMobile || !showFilterToolbar);

    const requestReportDetails = useCallback(
        (id, siteId, type) => {
            setShowFilterToolbar(false);
            updateQueryString({
                reportId: id,
                locationId: siteId,
                type,
            });
        },
        [updateQueryString],
    );

    const closeDetails = useCallback(() => {
        updateQueryString({
            reportId: null,
            locationId: null,
        });
        setAreDetailsOpen(false);
    }, [setAreDetailsOpen, updateQueryString]);

    const showReportDetails = useMemo(() => {
        return reportId && !showFilterToolbar && reports.length > 0 && reports.some((r) => r.id === reportId);
    }, [reports, reportId, showFilterToolbar]);

    const closeAllAdditionalViews = useCallback(() => {
        setShowFilterToolbar(false);
        closeDetails();
    }, [closeDetails, setShowFilterToolbar]);

    useEffect(() => {
        if (showLocationFilterMenu) {
            setShowFilterToolbar(false);
        }
    }, [showLocationFilterMenu]);

    useEffect(() => {
        if (showFilterToolbar) {
            setShowLocationFilterMenu(false);
        }
    }, [showFilterToolbar]);

    useEffect(() => {
        if (showFilterToolbar && !isMobile) {
            dispatch(TrackingActions.trackEvent(MixpanelEvent.ReportListFilterOpened));
        }
    }, [dispatch, isMobile, showFilterToolbar]);

    useSetFiltersByParams();

    useEffect(() => {
        if (reportId && !areDetailsOpen) {
            setAreDetailsOpen(true);
        }
    }, [areDetailsOpen, reportId]);

    useEffect(() => {
        const openFilterToolbarIfNoReports = setTimeout(() => {
            if (reports.length === 0 && !refreshing && !isMobile) {
                setShowFilterToolbar(true);
            }
        }, 100);

        return () => clearTimeout(openFilterToolbarIfNoReports);
    }, [isMobile, refreshing, reports.length]);

    return (
        <ReportListWrapper ref={wrapperRef}>
            {showReportDetails && (
                <ReportListDetails
                    key={reportId}
                    reportId={reportId}
                    locationId={locationId}
                    onClose={closeDetails}
                    isAlreadyOpen={areDetailsOpen}
                    reportTemplateName={type as ReportTemplateEnum}
                />
            )}
            {showFilterToolbar && !isMobile && <ReportsFilter onClose={closeAllAdditionalViews} />}
            {!showFilterToolbar && !showReportDetails && !isMobile && (
                <CollapsedFilterWrapper data-testid="filter-modal">
                    <FilterIcon activeFiltersCount={selectedFiltersCount} onClick={() => setShowFilterToolbar(true)} />
                </CollapsedFilterWrapper>
            )}
            <ReportsList
                parentRef={wrapperRef}
                reports={reports}
                activeReportId={reportId}
                onReportClick={requestReportDetails}
                loadMore={loadMoreReports}
                context={{
                    isClientSwitcherVisible: displaySwitcherBanner,
                    isMobile,
                    selectedFiltersCount,
                    isLoading: refreshing,
                    totalCount,
                    toggleFilterToolbar: () => setShowFilterToolbar((isVisible) => !isVisible),
                    isFilterToolbarVisible: showFilterToolbar,
                    closeAllAdditionalViews,
                }}
            />
        </ReportListWrapper>
    );
};
