import { Breakpoints, ButtonText, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInsightsTabs } from "src/pages/Insights/InsightsTabs/InsightsTabs.hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { TextStyled } from "./BackButton.styles";

type BackButtonProps = {
    categoryKey: string;
};

export const BackButton = ({ categoryKey }: BackButtonProps) => {
    const [buttonText, setButtonText] = useState<string>("");
    const [navigateTo, setNavigateTo] = useState<string>("");
    const [navigateToActiveTab, setNavigateToActiveTab] = useState<number | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const { setActiveTab } = useInsightsTabs();

    const iconSize = isMobile ? "L" : undefined;
    const categoryNameTranslation = t(`incident.category.${categoryKey}`);
    const matrixCategoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);

    useEffect(() => {
        const locationState = location?.state;

        if (locationState) {
            const { originUrl, originTitle, originActiveTab } = locationState;

            if (originUrl && originTitle) {
                setButtonText(locationState.originTitle);
                setNavigateTo(locationState.originUrl);
            }

            if (originActiveTab) {
                setNavigateToActiveTab(locationState.originActiveTab);
            }

            return;
        }

        if (matrixCategoryLevel === 2) {
            setButtonText(t("incidentMatrix.table.backButton.allEvents"));
            setNavigateTo(`/insights`);
            setNavigateToActiveTab(2);
            return;
        }

        setButtonText(`${categoryNameTranslation} ${t("incidentMatrix.table.backButton.events")}`);
        setNavigateTo(`/insights/overview-events/${categoryKey}`);
    }, [categoryKey, categoryNameTranslation, matrixCategoryLevel, location?.state, t]);

    const handleClick = () => {
        navigate(navigateTo);

        if (navigateToActiveTab) {
            setActiveTab(navigateToActiveTab);
        }
    };

    return (
        <Stack mb={["XL", "L", "S"]}>
            <ButtonText data-testid="back-navigation-button" icon="BackNavigation" iconSize={iconSize} onClick={handleClick}>
                <TextStyled truncate>{buttonText}</TextStyled>
            </ButtonText>
        </Stack>
    );
};
