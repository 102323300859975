import { Breakpoints, ButtonText, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ButtonStyled = styled(ButtonText)`
    width: auto;
`;

export const NoWrapButtonTextStyled = styled(ButtonText)`
    @media (max-width: ${Breakpoints.M}) {
        margin-top: ${Spacing.XXS}px;
    }
    @media (min-width: ${Breakpoints.M}) {
        white-space: nowrap;
    }
`;
