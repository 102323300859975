import "keen-slider/keen-slider.min.css";

import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { KeenSliderInstance, useKeenSlider } from "keen-slider/react";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useResizeObserver } from "../../../../hooks/CommonHooks";
import { CardBaseContainer, cardSpacing } from "../Cards.styled";
import { CarouselStyled } from "./CardsCarousel.styles";

const SlideItem = styled.div<{ sliderItemWidth?: number }>`
    ${CardBaseContainer} {
        width: ${({ sliderItemWidth }) => `${sliderItemWidth}px`};
    }
`;

type Props = {
    cardWidth: number;
    onDragEnded?: (slider: KeenSliderInstance<any>) => void;
} & {
    children: JSX.Element[];
};

export const CardsCarousel = ({ children, cardWidth, onDragEnded }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const sectionRef = useRef<HTMLDivElement>();
    const [perView, setPerView] = useState<"auto" | number>("auto");
    const [width, setWidth] = useState<number>(cardWidth);

    const [sliderRef, instance] = useKeenSlider<HTMLDivElement>({
        slides: { perView: perView, spacing: cardSpacing },
        dragEnded: onDragEnded ? onDragEnded : () => {},
    });

    const calculatePerView = useCallback(() => {
        const cardsAmountPerView = Number((sectionRef.current.clientWidth + cardSpacing) / (width + cardSpacing));
        setPerView(cardsAmountPerView);
    }, [width]);

    useEffect(() => {
        window.addEventListener("resize", calculatePerView);
        return () => {
            window.removeEventListener("resize", calculatePerView);
        };
    }, [calculatePerView]);

    const hasSingleCardOnMobile = isMobile && children.length === 1;

    useEffect(() => {
        if (hasSingleCardOnMobile && sectionRef.current) {
            setWidth(sectionRef.current.clientWidth);
        } else {
            setWidth(cardWidth);
        }
        calculatePerView();
    }, [hasSingleCardOnMobile, calculatePerView, cardWidth]);

    useEffect(() => {
        // repaint slider when amount of children changes
        instance.current.update();
    }, [children.length]);

    const [sectionWidth] = useResizeObserver(sectionRef.current);

    useLayoutEffect(() => {
        calculatePerView();
    }, [sectionWidth, calculatePerView]);

    if (!children?.length) {
        return <></>;
    }

    return (
        <div ref={sectionRef}>
            <CarouselStyled ref={sliderRef}>
                {children.map((item) => (
                    <SlideItem key={`${item.key}-slide`} sliderItemWidth={hasSingleCardOnMobile && width} className="keen-slider__slide">
                        {item}
                    </SlideItem>
                ))}
            </CarouselStyled>
        </div>
    );
};
