export const getDaysListForPastMonths = (n: number) => {
    const daysList = [];
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    for (let i = 0; i < n; i++) {
        const monthStartDate = new Date(today.getFullYear(), today.getMonth() - i, 1); // Set date to the first day of the month n months ago
        const lastDayOfPreviousMonth = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0).getDate(); // Get the number of days in the previous month
        for (let j = 0; j < lastDayOfPreviousMonth; j++) {
            const nextDay = new Date(monthStartDate.getTime() + j * 24 * 60 * 60 * 1000);
            if (nextDay <= today) {
                daysList.push(nextDay.getTime());
            }
        }
    }

    return daysList.sort((a, b) => b - a).reverse();
};

export default getDaysListForPastMonths;
