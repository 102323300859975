import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { UserPreference } from "../../../models/UserModel";
import { useSetUserPreference, useUserPreference } from "../../../store/user/UserHooks";

export const useSiteTimezoneInfo = () => {
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const [hasReadTheInfo] = useUserPreference(UserPreference.ReadSiteTimezoneInfo);
    const setUserPreference = useSetUserPreference();

    const markInfoAsRead = () => {
        setUserPreference(UserPreference.ReadSiteTimezoneInfo, true);
    };

    return {
        showInfo: areSiteZonesEnabled && !hasReadTheInfo,
        markInfoAsRead,
    };
};
