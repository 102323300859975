import { Breakpoints, Icon, Palette, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Tooltip } from "../../shared/Tooltip";
import { CardBaseContainer, CardDescription, CardHeaderText, CardHeaderTextWrapper } from "./Cards.styled";

interface TextCardProps {
    id: string;
    headerText: string;
    descriptionText: string;
    tooltipText: string;
    cardWidth: number;
}

const DescriptionText = styled(Text)`
    color: ${Palette.Navy800};
    white-space: pre-line;
`;

const HeaderText = styled(CardHeaderText)`
    color: ${Palette.Navy800};
`;

const NavyIcon = styled(Icon)`
    color: ${Palette.Navy800};
`;

export const TextCard = ({ id, headerText, descriptionText, tooltipText, cardWidth }: TextCardProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    return (
        <CardBaseContainer cardWidth={cardWidth} cardType="text_card">
            <Stack flex={1} direction="column" justifyContent="space-between" p="S">
                <Stack>
                    <NavyIcon variant="Message" size="M" />
                    <CardHeaderTextWrapper>
                        <HeaderText micro bold uppercase>
                            {headerText}
                        </HeaderText>
                    </CardHeaderTextWrapper>
                    <Tooltip place={isMobile ? "bottom" : "right-start"} message={tooltipText} id={id}>
                        <Icon color="dark" variant="Info" size="M" />
                    </Tooltip>
                </Stack>
                <CardDescription>
                    <DescriptionText small>{descriptionText}</DescriptionText>
                </CardDescription>
            </Stack>
        </CardBaseContainer>
    );
};
