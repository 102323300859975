import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportsSlice } from "src/store/reports/reportsSlice";

import { useReport } from "../../components/Reports/ReportProvider";
import { featureFlags } from "../../data/featureFlags";
import ReportsSelectors from "../../store/reports/ReportsSelectors";
import { createContext } from "../createContext";

type AutoTranslationState = {
    isOn: boolean;
    isAnythingTranslated?: boolean;
};

type AutoTranslationActions = {
    toggle: () => void;
    markTranslation: () => void;
};

const [AutoTranslationStateContext, useAutoTranslationsState] = createContext<AutoTranslationState>({
    defaultValue: { isOn: false },
    providerName: "AutoTranslation",
});

const [AutoTranslationActionsContext, useAutoTranslationsActions] = createContext<AutoTranslationActions>({
    defaultValue: {
        toggle: () => {},
        markTranslation: () => {},
    },
    providerName: "AutoTranslationActions",
});

type Props = PropsWithChildren & {
    id?: string;
};

export const AutoTranslationProvider = ({ children, id }: Props) => {
    const translationsEnabled = useBooleanFlagValue(featureFlags.autotranslations, false);
    const dispatch = useDispatch();
    const report = useReport();
    const scopeId = useMemo(() => `${report.id}_${id?.toLowerCase() || ""}`.replace(" ", "_"), [report, id]);
    const translatedSectionState = useSelector((state) => ReportsSelectors.getSectionsAutotranslation(state, scopeId));
    const { isOn, isAnythingTranslated } = translatedSectionState ?? {};
    const isSectionOn = useMemo(() => isOn !== false, [isOn]);
    const [isUsed, setIsUsed] = useState(!!isAnythingTranslated);
    const state = useMemo(
        () => ({
            isOn: translationsEnabled && isSectionOn,
            isAnythingTranslated: isUsed,
        }),
        [translationsEnabled, isSectionOn, isUsed],
    );

    useEffect(() => {
        if (isUsed) {
            dispatch(reportsSlice.actions.setReportSectionAutotranslate({ sectionId: scopeId, isAnythingTranslated: true }));
        }
    }, [isUsed, scopeId, dispatch]);

    const markTranslation = useCallback(() => setIsUsed(true), [setIsUsed]);

    const toggle = useCallback(() => {
        dispatch(reportsSlice.actions.setReportSectionAutotranslate({ sectionId: scopeId, isOn: !isSectionOn }));
    }, [scopeId, dispatch, isSectionOn]);

    const actions = useMemo(
        (): AutoTranslationActions => ({
            toggle,
            markTranslation,
        }),
        [toggle, markTranslation],
    );

    return (
        <AutoTranslationStateContext.Provider value={state}>
            <AutoTranslationActionsContext.Provider value={actions}>{children}</AutoTranslationActionsContext.Provider>
        </AutoTranslationStateContext.Provider>
    );
};

export { useAutoTranslationsState, useAutoTranslationsActions };
