import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import { convertFromRaw, EditorState, Modifier, SelectionState } from "draft-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { sortByField } from "../../../../helpers/ArrayHelper";
import { IUser } from "../../../../models/UserModel";
import { ICommentModel } from "../../../../store/reportComments/models";
import { getContentAndMentions, recreateEditorFromComment } from "./CommentEditor.helpers";
import { CommentEditorArea, CommentEditorWrapper } from "./CommentEditor.styles";
import { IUserMention } from "./CommentEditor.types";
import { SuggestionEntry } from "./SuggestionEntry";

type Props = {
    getContentsRef: any; // todo useref for function typing
    comment?: ICommentModel;
    usersToMention: IUser[];
    onChange: (state: EditorState) => void;
};

export const CommentEditor = ({ getContentsRef, comment, onChange, usersToMention }: Props) => {
    const { t } = useTranslation();

    const usersForMentions: IUserMention[] = useMemo(() => {
        return usersToMention
            .map((r) => ({
                email: r.emailPrimary,
                fullName: r.fullName,
                name: r.fullName || r.emailPrimary,
                id: r.id,
            }))
            .sort(sortByField("name"));
    }, [usersToMention]);

    const editorRef = useRef<Editor>(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [isOpen, setIsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: {
                mention: "mention",
                mentionSuggestions: "suggestionsContainer",
            },
            supportWhitespace: true,
            entityMutability: "IMMUTABLE",
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const focusEditor = useCallback(() => {
        editorRef.current.focus();
    }, []);

    useEffect(() => {
        onChange(editorState);
    }, [onChange, editorState]);

    useEffect(() => {
        if (!comment) {
            return;
        }
        if (!comment.content) {
            clearInput();
            return;
        }
        const rawContent = recreateEditorFromComment(comment, usersForMentions);
        const convertedState = convertFromRaw(rawContent);
        setEditorState((state) => EditorState.push(state, convertedState, "apply-entity"));
    }, [comment, usersForMentions]);

    useEffect(() => {
        getContentsRef.current = () => {
            return getContentAndMentions(editorState);
        };
    }, [editorState, getContentsRef]);

    const clearInput = () => {
        let editorStateCopy = editorState;
        let contentState = editorStateCopy.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: true,
        });
        contentState = Modifier.removeRange(contentState, allSelected, "backward");
        editorStateCopy = EditorState.push(editorStateCopy, contentState, "remove-range");
        editorStateCopy = EditorState.forceSelection(editorStateCopy, contentState.getSelectionAfter());
        setEditorState(editorStateCopy);
    };

    const onSearchChange = useCallback(
        ({ value }: { value: string }) => {
            const filterSuggestions = (value: string, mentions: IUserMention[]) => {
                if (!value) {
                    return [];
                }
                return mentions.filter((u) => u.name?.toLowerCase()?.includes(value.toLowerCase()) || u.email?.toLowerCase()?.includes(value.toLowerCase()));
            };
            const filtered = filterSuggestions(value, usersForMentions);
            setSuggestions(filtered);
        },
        [usersForMentions],
    );

    return (
        <CommentEditorWrapper>
            <CommentEditorArea onClick={focusEditor}>
                <Editor
                    placeholder={t("report.comments.inputPlaceholder")}
                    editorKey={"editor"}
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={plugins}
                    ref={editorRef}
                    stripPastedStyles={true}
                />
            </CommentEditorArea>
            <MentionSuggestions
                open={isOpen}
                onOpenChange={setIsOpen}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                entryComponent={SuggestionEntry}
            />
        </CommentEditorWrapper>
    );
};

export default CommentEditor;
