import { Box, Breakpoints, Spacing, Stack, Subtitle } from "@secuis/ccp-react-components";
import styled from "styled-components";

const SentInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: ${Spacing.XL}px;
    padding-left: ${Spacing.XL}px;
    text-align: center;

    @media (max-width: ${Breakpoints.XS}) {
        padding-right: ${Spacing.M}px;
        padding-left: ${Spacing.M}px;
    }
`;

const EmailSentText = styled(Subtitle)`
    display: inline;
`;

type Props = {
    message: string;
    imgSrc: string;
    emails?: any[];
};

const EmailSentInfo = ({ message, imgSrc, emails = [] }: Props) => {
    const emailsToDisplay = [...emails];
    const lastEmail = emailsToDisplay.pop();
    return (
        <Stack direction="column" alignItems="center" data-testid="share-report-modal-email-sent-info">
            <Box mt="L" mb="S">
                <img src={imgSrc} />
            </Box>
            <SentInfo>
                <Subtitle>{message + " "}</Subtitle>
                <EmailSentText>{emailsToDisplay.join(", ")}</EmailSentText>
                <EmailSentText>{emailsToDisplay.length >= 1 ? " and " : " "}</EmailSentText>
                <EmailSentText>{lastEmail}</EmailSentText>
            </SentInfo>
        </Stack>
    );
};

export default EmailSentInfo;
