import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapErrors = ({ app: { errorInfo } }: IState) => errorInfo;

const getErrors = createSelector([mapErrors], (errorInfo) => ({ errorInfo }));

const mapInAppNotifications = ({ app: { inAppNotifications } }: IState) => inAppNotifications;
const getInAppNotifications = createSelector(mapInAppNotifications, (inAppNotifications) => inAppNotifications);

const getIsEmbeddedInNativeHub = createSelector(
    [({ app: { isEmbeddedInNativeHub } }: IState) => isEmbeddedInNativeHub],
    (isEmbeddedInNativeHub) => isEmbeddedInNativeHub,
);

const mapQueryParams = ({ app: { queryParams } }: IState) => queryParams;
const selectQueryParams = createSelector(mapQueryParams, (params) => params);

export default {
    getErrors,
    getInAppNotifications,
    getIsEmbeddedInNativeHub,
    selectQueryParams,
};
