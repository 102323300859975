import i18next from "i18next";
import { formatExplicit, isBefore, subMonths } from "src/helpers/date";

import { DynamicReportPropertyTypeEnum, DynamicReportType, ReportTemplateEnum } from "../models/ReportModel";

export const parseReportExportFilename = (reportType: string, reportStartTime: Date): string => {
    return `${reportType}-report-${formatExplicit(reportStartTime, "yyyy-MM-dd-HH-mm")}.pdf`;
};

export const hasReportBeenViewedByUser = (report, user) => {
    const read = report?.visitorIds?.includes(user.id);
    if (read) {
        return read;
    }
    const unreadThresholdDate = user.preferences?.termsOfUseDateAcceptedV3 ?? null;
    if (unreadThresholdDate) {
        return isBefore(new Date(report.reportDateTime), subMonths(new Date(unreadThresholdDate), 6));
    }
    return false;
};

export const getNodesHeight = (nodes: ChildNode[]): number =>
    nodes.reduce((acc, item, i) => {
        const clientHeight = (item as Element).clientHeight;
        // dont need last margin except when one node
        if (i !== nodes.length - 1 || nodes.length === 1) {
            const { marginTop, marginBottom } = getComputedStyle(item as Element);
            acc = acc + clientHeight + parseInt(marginTop) + parseInt(marginBottom);
        } else {
            acc = acc + clientHeight;
        }
        return acc;
    }, 0);

export const checkIfUnknown = (reportType: string): boolean => {
    return !Object.values(ReportTemplateEnum).includes(reportType as ReportTemplateEnum);
};

export const getReportTemplateShortTranslation = (template: string): string => {
    switch (template) {
        case ReportTemplateEnum.incident:
            return i18next.t("common.report.plannedTourShort");
        case ReportTemplateEnum.alarmResponse:
            return i18next.t("common.report.alarmDispatchShort");
        case ReportTemplateEnum.tourException:
            return i18next.t("reportdetails.report.tourExceptionShort");
        case ReportTemplateEnum.tourMultiException:
            return i18next.t("reportdetails.report.tourMultiExceptionShort");
        case ReportTemplateEnum.amazonGeneral:
            return i18next.t("reportdetails.amazon.generalReport");
        case ReportTemplateEnum.amazonZone6:
            return i18next.t("reportdetails.amazon.zone6Report");
        case ReportTemplateEnum.maintenance:
            return i18next.t("reportdetails.report.maintenanceShort");
        case ReportTemplateEnum.patrolTour:
            return i18next.t("reportdetails.report.patrolTour");
        case ReportTemplateEnum.siteInspection:
            return i18next.t("reportdetails.report.siteInspectionShort");
        case ReportTemplateEnum.safetyInspection:
            return i18next.t("reportdetails.report.safetyInspectionShort");
        case ReportTemplateEnum.taskReport:
            return i18next.t("reportdetails.report.taskReport");
        case ReportTemplateEnum.activityLog:
            return i18next.t("reportdetails.report.activityLog");
        case ReportTemplateEnum.assignment:
            return i18next.t("reportdetails.report.assignment");
        case ReportTemplateEnum.vehicleInspection:
            return i18next.t("reportdetails.report.vehicleInspection");
        case ReportTemplateEnum.callout:
            return i18next.t("reportdetails.report.callout");
        default:
            return `${template}`;
    }
};

export const getCombinedExceptionAndTourName = (template: string, tourName: string): string => {
    return `${getReportTemplateShortTranslation(template)} • ${tourName ?? i18next.t("reportdetails.header.patrolTour")}`;
};

export const mapDbReport = (report: DynamicReportType): DynamicReportType => {
    if (!report) {
        return null;
    }
    const [picturesToKeep, imageProperties] = (report.images ?? []).reduce(
        (acc, image) => {
            if (image.reportFieldName.startsWith("picture")) {
                acc[0].push(image);
            } else {
                acc[1].push(image);
            }
            return acc;
        },
        [[], []],
    );
    imageProperties.forEach((property) => {
        report.properties.push({
            reportFieldName: property.reportFieldName,
            type: DynamicReportPropertyTypeEnum.image,
            value: property,
        });
    });
    report.images = picturesToKeep;
    return report;
};
