import { MapSection } from "../../../components/Insights/Map/MapSection";
import { Events } from "../Events";
import { PatrolTasks } from "../PatrolTasks/PatrolTasks";
import { ReportedDamages } from "../ReportedDamages/ReportedDamages";
import { Summary } from "../Summary";
import { SubpageTab } from "./InsightsTabs.types";

export const STATIC_INSIGHTS_TABS: SubpageTab[] = [
    {
        labelKey: "insights.tabs.map",
        component: MapSection,
    },
    {
        labelKey: "insights.tabs.incidents",
        component: Events,
    },
    {
        labelKey: "insights.tabs.tasks",
        component: PatrolTasks,
    },
    {
        labelKey: "insights.tabs.damages",
        component: ReportedDamages,
    },
];

export const SUMMARY_TAB: SubpageTab = {
    labelKey: "insights.tabs.summary",
    component: Summary,
};
