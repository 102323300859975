import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { AssignmentFields } from "../../../../models/reportTemplates/Assignment";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getAssignmentReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    conditionalRender(!!getFieldValueByFieldName(AssignmentFields.comments, report) || images.length, () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.assignment.assignmentDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName(AssignmentFields.comments, report),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        };
    }),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.assignment.assignmentSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.assignment.didAnIncidentOccurDuringTheAssignment",
                    fieldValue: getFieldValueByFieldName(AssignmentFields.didAnIncidentOccurDuringTheAssignment, report),
                    isRequired: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: false,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.assignment.assignmentTaskComplete",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AssignmentFields.assignmentTaskComplete, report)),
                    isRequired: true,
                },
            },
            ...getPdfUnknownFields(report, Object.values(AssignmentFields), images),
        ],
    },
];
