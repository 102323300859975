import { Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StyledList = styled.ul`
    margin: 0;
    padding-inline-start: ${Spacing.M}px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 16px;
`;
