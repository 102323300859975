import React from "react";
import { Components } from "react-virtuoso";

import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";

export const VirtualListContainer: Components<ReportsListItemType, VirtualListContext>["List"] = React.forwardRef((props, ref) => {
    return <div {...props} ref={ref} id="virtual-list-container" data-testid="list-container"></div>;
});

VirtualListContainer.displayName = "VirtualListContainer";
