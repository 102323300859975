import { Box } from "@secuis/ccp-react-components";
import { JSX } from "react";
import { useTranslation } from "react-i18next";
import { useAutoTranslatedText } from "src/helpers/autoTranslations";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import { DefaultItemRow, StackField, TextValueDisplay } from "../DefaultItemField";
import { StandaloneSection } from "./Shared.styles";

export type TextAreaSummaryFieldProps = {
    title?: string;
    content?: string;
    isRequired?: boolean;
    shouldAutoTranslate?: boolean;
};

const TextAreaContent = ({ content }: Pick<TextAreaSummaryFieldProps, "content">) => {
    const { t } = useTranslation();
    return <TextValueDisplay value={content || t("common.noDataFound")} />;
};

export const TextAreaSummaryField = ({ title, content, isRequired = true, shouldAutoTranslate = true }: TextAreaSummaryFieldProps): JSX.Element => {
    const { translatedText: autoTranslatedTitle } = useAutoTranslationWhenNotTranslated(title, shouldAutoTranslate);
    const { translatedText: autoTranslatedContent } = useAutoTranslatedText(content, shouldAutoTranslate);

    if (!content && !isRequired) {
        return null;
    }

    return (
        <StackField>
            <DefaultItemRow label={autoTranslatedTitle} />
            <Box mt={title ? ["0", "XS", "XS"] : "0"} mb="0">
                <TextAreaContent content={autoTranslatedContent} />
            </Box>
        </StackField>
    );
};

export const TextAreaSection = ({ content, isRequired = true, shouldAutoTranslate = true }: TextAreaSummaryFieldProps): JSX.Element => {
    const { translatedText } = useAutoTranslatedText(content);

    if (!content && !isRequired) {
        return null;
    }

    return (
        <StandaloneSection>
            <TextAreaContent content={shouldAutoTranslate && translatedText ? translatedText : content} />
        </StandaloneSection>
    );
};
