import { useSelector } from "react-redux";

import { UNGROUPED_ITEMS_VALUE } from "../../components/Reports/ReportsFilter";
import { countriesByRegions, regionsByCountry } from "../../data/regions";
import LocationsSelectors from "../locations/LocationsSelectors";
import { useSelectedSites } from "../siteObjects/SiteObjectsHooks";
import FilterSelectors from "./FilterSelectors";
import InsightsSelectors from "./InsightsSelectors";

const allCountries = Object.keys(regionsByCountry);
const allRegions = countriesByRegions.map((r) => r.name);

export const useFiltersCount = (): number => {
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);

    return selectedRegions.filter(
        (item) => !(allCountries.includes(item) || allRegions.includes(item) || locations.groups.some((x) => x.id === item) || item === UNGROUPED_ITEMS_VALUE),
    ).length;
};

export const useFilteredSites = (): { siteIds: string[] } => {
    const selectedLocations = useSelector(InsightsSelectors.getSelectedFilterLocations);

    return useSelectedSites(selectedLocations);
};
