import { Text, View } from "@react-pdf/renderer";
import { Spacing } from "@secuis/ccp-react-components";
import i18next from "i18next";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { featureFlags } from "../../data/featureFlags";
import { formatDateTime, formatDateTimeWithTimezone } from "../../helpers/date";
import { useFeatureFlag } from "../../hooks/featureFlags";
import { CheckpointScanResult, CheckpointScanType, PatrolTourType } from "../../models/ReportModel";
import {
    CheckpointSortConfigs,
    useSortedCheckpoints,
} from "../../pages/Reports/ReportsList/ReportDetails/Components/CheckpointSortComponent/CheckpointDataHook";
import { styles } from "./pdfStyles";
import { SortDescendingIconPdf, SuccessIconPdf } from "./shared/Icons";
import { DynamicTemplateSectionPdf } from "./shared/PdfDynamicTemplateSection";

type Props = {
    report: PatrolTourType;
};

const RightHeader = () => {
    return (
        <View style={styles.inline}>
            <SortDescendingIconPdf />
            <Text style={styles.boldText}>{`${i18next.t("reportdetails.patrolTour.result")}`}</Text>
        </View>
    );
};

export const CheckpointDataPdf = ({ report }: Props) => {
    const { sortCheckpoints } = useSortedCheckpoints([...report.checkpointScans]);
    const { t } = useTranslation();
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const checkpointsData = useMemo(() => sortCheckpoints(CheckpointSortConfigs.defaultSortConfig), []);

    const scannedCheckpoints = useMemo(() => report.checkpointScans.filter((c) => c.result === CheckpointScanResult.scanned), [report]);

    if (!checkpointsData.length || !scannedCheckpoints.length) {
        return null;
    }

    return (
        <DynamicTemplateSectionPdf label="reportdetails.patrolTour.checkpointDetails" renderLineBreak={false} marginTop="S" rightHeader={<RightHeader />}>
            {checkpointsData
                .filter((c) => c.result === CheckpointScanResult.scanned)
                .map((checkpoint: CheckpointScanType, index) => {
                    return (
                        <React.Fragment key={`${checkpoint.checkpointId}${checkpoint.checkpointSortOrder}pdf`}>
                            <View style={{ ...styles.inlineFullWidth, fontSize: 11 }} wrap={false}>
                                <Text style={styles.checkpointNameLabel}>{checkpoint.checkpointName}</Text>
                                <View style={styles.inline}>
                                    <Text style={{ marginRight: Spacing.XL }}>
                                        {areSiteZonesEnabled
                                            ? formatDateTimeWithTimezone(checkpoint.scanDateTime, report.siteIanaTimezone)
                                            : formatDateTime(checkpoint.scanDateTime)}
                                    </Text>
                                    <View style={styles.inline}>
                                        <Text style={{ marginRight: Spacing.XXS }}>{t(`reportdetails.patrolTour.${checkpoint?.result?.toLowerCase()}`)}</Text>
                                        <SuccessIconPdf />
                                    </View>
                                </View>
                            </View>
                            <View style={index == checkpointsData.length - 1 ? { ...styles.lineBreak, marginBottom: 0 } : styles.lineBreak} />
                        </React.Fragment>
                    );
                })}
        </DynamicTemplateSectionPdf>
    );
};
