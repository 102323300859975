import { DatepickerInput, Palette, Stack } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

export const DatepickerInputWrapperStyled = styled(Stack)`
    flex: 1 1 auto;
    min-width: 300px;

    // NOTE: Workaround to style DatepickerInput's Overlay
    & > div > div:last-child:not(:first-child) {
        position: fixed;
    }
`;

export const DatepickerInputStyled = styled(DatepickerInput)`
    &:focus-visible {
        outline: none;
    }
`;

export const DatepickerWrapperStyled = styled.div<{ offsetRight: number; offsetTop: number }>`
    position: fixed;
    right: ${({ offsetRight }) => `${offsetRight}px`};
    top: ${({ offsetTop }) => `${offsetTop}px`};
    z-index: 10001;
    overflow-y: auto;
    background-color: ${Palette.Navy700};

    [tabindex="0"]:focus-visible {
        outline: ${FocusStyles.Outline};
        border-radius: ${FocusStyles.BorderRadius}px;
    }
`;
