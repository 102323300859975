import { useSelector } from "react-redux";
import { getIsProtectasUrl } from "src/helpers/ProtectasHelpers";
import AppSelectors from "src/store/app/AppSelectors";

export const useIsProtectasHook = () => {
    const storedQueryParams = useSelector(AppSelectors.selectQueryParams);
    if (storedQueryParams.b) {
        return storedQueryParams.b === "protectas";
    }
    return getIsProtectasUrl();
};
