import { Text, View } from "@react-pdf/renderer";

import { getVehicleInspectionReportProperties } from "../../../../helpers/reportTemplates/vehicleInspectionHelper";
import { DynamicReportType } from "../../../../models/ReportModel";
import { styles } from "../../pdfStyles";
import { PdfCarInpsectionIcon } from "../../shared/Icons";

type Props = {
    report: DynamicReportType;
};

export const PdfCarHeaderLine = ({ report }: Props) => {
    const { licensePlate, vehicleModel, inspectionCount } = getVehicleInspectionReportProperties(report.properties);

    return (
        <>
            <View style={styles.sectionLineBreak}></View>
            <View style={styles.detailsLine}>
                <View style={styles.detailsWrappableWrapper}>
                    <View>
                        <Text style={styles.locationBoldText}>{licensePlate}</Text>
                    </View>
                    <View style={styles.verticalDivider} />
                    <View>
                        <Text style={styles.locationText}>{vehicleModel}</Text>
                    </View>
                    <View style={{ ...styles.detailsIconContainer, gap: 4, marginLeft: 8 }}>
                        <PdfCarInpsectionIcon />
                        <Text style={styles.locationText}>{inspectionCount}</Text>
                    </View>
                </View>
            </View>
        </>
    );
};
