import { Breakpoints, Text, Title } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { HeadlineWrapper } from "./TemplateHeadline";

export type Props = {
    header: string;
    subheader?: string;
};

const PatrolWrapper = styled(HeadlineWrapper)`
    justify-content: flex-start;
    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
    }
`;

export const PatrolHeadline = ({ header, subheader }: Props) => {
    return (
        <PatrolWrapper>
            <Title>{header}</Title>
            {subheader && (
                <Text color="secondary" small uppercase>
                    {subheader}
                </Text>
            )}
        </PatrolWrapper>
    );
};
