import { Icon, Stack, Text } from "@secuis/ccp-react-components";

import { getTrendingInfo } from "../../../helpers/trending/getTrendingInfo";
import { TrendDescription } from "./TrendIndicator.styled";

type Props = {
    startValue: number;
    endValue: number;
    description?: string;
};

export const TrendIndicator = ({ startValue, endValue, description }: Props) => {
    if (!startValue) {
        return null;
    }
    const { trendResult, iconVariant } = getTrendingInfo(startValue, endValue);

    return (
        <Stack direction="column">
            <Stack gap="XXS" alignItems="center">
                <Icon size="S" color="secondary" variant={iconVariant} />
                <Text micro color="secondary">
                    {trendResult}
                </Text>
            </Stack>
            <TrendDescription micro color="secondary">
                {description}
            </TrendDescription>
        </Stack>
    );
};
