import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addDays, addMilliseconds, differenceInDays } from "src/helpers/date";
import { getScannedCheckpointsPercentage } from "src/pages/Insights/PatrolTasks/PatrolTasks.helpers";

import LocationsSelectors from "../locations/LocationsSelectors";
import { RequestStatus } from "../RequestStatus";
import { useFilteredSites } from "./FilterHooks";
import { GetByDateRangeAndSiteIds, TABLE_TOTAL_KEY } from "./InsightsModel";
import { CompareTableParent } from "./InsightsPatrolTasksModel";
import InsightsSelectors from "./InsightsSelectors";
import { generateQueryKey } from "./keys";
import PatrolsActions from "./PatrolsActions";
import { ITourSessionsSummaryState } from "./types";
import { useFeatureFlag } from "../../hooks/featureFlags";
import { featureFlags } from "../../data/featureFlags";

export const useAggregatedTourSessionsSummary = (): {
    exceptionsCount: number;
    toursCount: number;
    checkpointsScanned: number;
    checkpointsMissed: number;
    scannedCheckpointsPercent: number;
    status: RequestStatus;
    historicalExceptionsCount: number;
    historicalToursCount: number;
    historicalCheckpointsScanned: number;
    historicalCheckpointsMissed: number;
    historicalScannedCheckpointsPercent: number;
    historicalStatus: RequestStatus;
    isLoading: boolean;
} => {
    const dispatch = useDispatch();
    const { siteId } = useParams<{ siteId?: string }>();
    const { siteIds: filteredSiteIds } = useFilteredSites();
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const LOADING_STATUSES = [RequestStatus.loading, RequestStatus.undefined];
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);

    const histDateRange = useMemo(() => {
        const dateDifference = differenceInDays(addMilliseconds(selectedEndDate, 1), selectedStartDate);
        return [addDays(selectedStartDate, -dateDifference), addMilliseconds(selectedStartDate, -1)];
    }, [selectedStartDate, selectedEndDate]);

    const tourSessionsSummary = useSelector(InsightsSelectors.getTourSessionsSummary);
    const historicalTourSessionsSummary = useSelector(InsightsSelectors.getHistoricalTourSessionsSummary);

    useEffect(() => {
        const siteIds = siteId ? [siteId] : filteredSiteIds;
        const key = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });

        if (key !== tourSessionsSummary.queryKey && siteIds.length) {
            dispatch(
                PatrolsActions.getTourSessionsSummary(
                    {
                        siteIds,
                        fromDateTime: selectedStartDate,
                        toDateTime: selectedEndDate,
                    },
                    key,
                    areSiteZonesEnabled,
                ),
            );
            dispatch(
                PatrolsActions.getHistoricalTourSessionsSummary(
                    {
                        siteIds,
                        fromDateTime: histDateRange[0],
                        toDateTime: histDateRange[1],
                    },
                    areSiteZonesEnabled,
                ),
            );
        }
    }, [dispatch, selectedStartDate, selectedEndDate, histDateRange, siteId, filteredSiteIds, tourSessionsSummary.queryKey]);

    const reduceSummary = (tourSession: ITourSessionsSummaryState, fieldName: string) => {
        return tourSession.tourSessionsSummary?.reduce((sum, details) => sum + details[fieldName], 0);
    };

    const exceptionsCount: number = reduceSummary(tourSessionsSummary, "exceptionsCount");
    const toursCount: number = reduceSummary(tourSessionsSummary, "count");
    const checkpointsScanned: number = reduceSummary(tourSessionsSummary, "checkpointsScanned");
    const checkpointsMissed: number = reduceSummary(tourSessionsSummary, "checkpointsMissed");

    const historicalExceptionsCount: number = reduceSummary(historicalTourSessionsSummary, "exceptionsCount");
    const historicalToursCount: number = reduceSummary(historicalTourSessionsSummary, "count");
    const historicalCheckpointsScanned: number = reduceSummary(historicalTourSessionsSummary, "checkpointsScanned");
    const historicalCheckpointsMissed: number = reduceSummary(historicalTourSessionsSummary, "checkpointsMissed");

    const scannedCheckpointsPercent = getScannedCheckpointsPercentage(checkpointsMissed, checkpointsScanned);
    const historicalScannedCheckpointsPercent = getScannedCheckpointsPercentage(historicalCheckpointsMissed, historicalCheckpointsScanned);

    return {
        exceptionsCount,
        toursCount,
        checkpointsScanned,
        checkpointsMissed,
        scannedCheckpointsPercent,
        status: tourSessionsSummary?.queryStatus,
        historicalExceptionsCount,
        historicalToursCount,
        historicalCheckpointsScanned,
        historicalCheckpointsMissed,
        historicalScannedCheckpointsPercent,
        historicalStatus: historicalTourSessionsSummary?.queryStatus,
        isLoading: LOADING_STATUSES.includes(tourSessionsSummary?.queryStatus) && LOADING_STATUSES.includes(historicalTourSessionsSummary?.queryStatus),
    };
};

export const useTourSessionsSummaryByLocations = (siteIds: string[]) => {
    const tourSessionsSummary = useSelector(InsightsSelectors.getTourSessionsSummary);
    const historicalTourSessionsSummary = useSelector(InsightsSelectors.getHistoricalTourSessionsSummary);
    const { t } = useTranslation();
    const {
        exceptionsCount,
        toursCount,
        checkpointsScanned,
        checkpointsMissed,
        historicalExceptionsCount,
        historicalToursCount,
        historicalCheckpointsScanned,
        historicalCheckpointsMissed,
    } = useAggregatedTourSessionsSummary();
    const authorizedLocations = useSelector(LocationsSelectors.getAuthorizedLocations);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);

    const result = useMemo(() => {
        const locationSessionSummary = [];
        const allLocations = tourSessionsSummary.tourSessionsSummary
            .map((obj) => obj.locationId)
            .concat(historicalTourSessionsSummary.tourSessionsSummary.map((obj) => obj.locationId));

        [...new Set(allLocations)].forEach((locationId, index) => {
            const locationSummary = tourSessionsSummary.tourSessionsSummary.find((obj) => obj.locationId === locationId);
            const historicalLocationSummary = historicalTourSessionsSummary.tourSessionsSummary.find((obj) => obj.locationId === locationId);
            const locationDetails = authorizedLocations.siteObjects.find((obj) => obj.id === locationId);

            locationSessionSummary.push({
                id: locationDetails.displayName,
                siteId: locationId,
                group: t(`country.${locationDetails.countryCode}`),
            });
            locationSessionSummary[index]["tours"] = {
                count: locationSummary?.count || 0,
                histCount: historicalLocationSummary?.count || 0,
            };
            locationSessionSummary[index]["deviations"] = {
                count: locationSummary?.exceptionsCount || 0,
                histCount: historicalLocationSummary?.exceptionsCount || 0,
            };
            locationSessionSummary[index]["checkpoints"] = {
                count: {
                    scanned: locationSummary?.checkpointsScanned || 0,
                    missed: locationSummary?.checkpointsMissed || 0,
                },
                histCount: {
                    scanned: historicalLocationSummary?.checkpointsScanned || 0,
                    missed: historicalLocationSummary?.checkpointsMissed || 0,
                },
            };
        });
        locationSessionSummary.sort((a, b) => a.id.localeCompare(b.id));
        const aggregatedData = locationSessionSummary.reduce((result, item) => {
            if (!result[item.group]) {
                result[item.group] = {
                    id: item.group,
                    children: [],
                    tours: { count: 0, histCount: 0 },
                    deviations: { count: 0, histCount: 0 },
                    checkpoints: {
                        count: {
                            missed: 0,
                            scanned: 0,
                        },
                        histCount: {
                            missed: 0,
                            scanned: 0,
                        },
                    },
                };
            }
            result[item.group].children.push(item);
            result[item.group].tours.count += item.tours.count;
            result[item.group].tours.histCount += item.tours.histCount;
            result[item.group].deviations.count += item.deviations.count;
            result[item.group].deviations.histCount += item.deviations.histCount;
            result[item.group].checkpoints.count.scanned += item.checkpoints.count.scanned;
            result[item.group].checkpoints.histCount.scanned += item.checkpoints.histCount.scanned;
            result[item.group].checkpoints.count.missed += item.checkpoints.count.missed;
            result[item.group].checkpoints.histCount.missed += item.checkpoints.histCount.missed;
            return result;
        }, {});
        const aggregatedArray: any[] = Object.values(aggregatedData);
        aggregatedArray.sort((a, b) => a.id.localeCompare(b.id));

        const locationsWithTotal: CompareTableParent[] = [
            ...aggregatedArray,
            {
                id: TABLE_TOTAL_KEY,
                tours: {
                    count: toursCount,
                    histCount: historicalToursCount,
                },
                deviations: {
                    count: exceptionsCount,
                    histCount: historicalExceptionsCount,
                },
                checkpoints: {
                    count: {
                        missed: checkpointsMissed,
                        scanned: checkpointsScanned,
                    },
                    histCount: {
                        missed: historicalCheckpointsMissed,
                        scanned: historicalCheckpointsScanned,
                    },
                },
            },
        ];

        return locationsWithTotal;
    }, [siteIds, selectedStartDate, selectedEndDate, tourSessionsSummary, historicalTourSessionsSummary]);
    return { tourSessionsSummaryByLocation: result };
};
