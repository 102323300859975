import { CSSProperties } from "react";

export type StyleProps = {
    style?: CSSProperties;
    className?: string;
};

export type QaProps = {
    "data-testid"?: string;
};

// ----------------
// NOTE: Moved here temporarily while refactoring
// we should find more meaningful place for them
export interface HighlightsCategories {
    categoryLevel2: string;
    categoriesLevel3: string[];
}

export enum QueryTimeRange {
    LastWeek = "-6 day",
    Last2Weeks = "-13 day",
    Last30Days = "-29 day",
    Last3Months = `-89 day`,
}

export type HighlightsType =
    | "critical_categories"
    | "prevention_categories"
    | "access_control_categories"
    | "access_control_systems_categories"
    | "medical_assistance_categories"
    | "confrontation_categories"
    | "equipment_accident_categories"
    | "reported_audits_categories"
    | "damaged_reports_categories";
// ----------------
