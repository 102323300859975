import { Stack } from "@secuis/ccp-react-components";
import { Components } from "react-virtuoso";

import { Loading } from "../../../components/shared/Loading";
import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";

export const VirtualListFooter: Components<ReportsListItemType, VirtualListContext>["Footer"] = ({ context }) => (
    <Stack paddingBottom="XL">{context.isLoading && <Loading />}</Stack>
);
