import { Headline, Icon, Stack } from "@secuis/ccp-react-components";
import { useSelector } from "react-redux";
import { Tooltip } from "src/components/shared/Tooltip";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { TooltipContent } from "./TooltipContent";

type MatrixHeaderProps = {
    title: string;
};

export const MatrixHeader = ({ title }: MatrixHeaderProps) => {
    const categoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);

    return (
        <Stack>
            <Stack mb="S" mr="XS" gap="XS" alignItems="center">
                <Headline bold={true}>{title}</Headline>
                <Tooltip message={<TooltipContent categoryLevel={categoryLevel} />}>
                    <Icon variant="Info" />
                </Tooltip>
            </Stack>
        </Stack>
    );
};
