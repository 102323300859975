import { createSlice } from "@reduxjs/toolkit";

import { mapToUser } from "../../models/UserModel";
import { authorizationApi } from "../authorisation/authorizationApi";
import { RequestStatus } from "../RequestStatus";
import { ISiteObjectsState } from "./types";

const SITE_OBJECTS_INITIAL_STATE: ISiteObjectsState = {
    usersBySiteObjectId: {},
};
const DELETED_USER_STATUS = "Deleted";

export const siteObjectsSlice = createSlice({
    name: "siteObjects",
    initialState: SITE_OBJECTS_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(authorizationApi.endpoints.getUsersAtLocation.matchPending, (state, action) => {
                state.usersBySiteObjectId[action.meta.arg.originalArgs.locationId] = {
                    status: RequestStatus.loading,
                    users: [],
                };
            })
            .addMatcher(authorizationApi.endpoints.getUsersAtLocation.matchFulfilled, (state, action) => {
                state.usersBySiteObjectId[action.meta.arg.originalArgs.locationId] = {
                    status: RequestStatus.success,
                    users: action.payload
                        .filter((e) => !!e.user && e.user.status !== DELETED_USER_STATUS)
                        .map((e) => ({ ...mapToUser(e.user), roles: e.roleName })),
                };
            })
            .addMatcher(authorizationApi.endpoints.getUsersAtLocation.matchRejected, (state, action) => {
                state.usersBySiteObjectId[action.meta.arg.originalArgs.locationId] = {
                    status: RequestStatus.error,
                    users: [],
                };
            });
    },
});
