import { useMemo } from "react";
import { WorkerExecResult } from "src/models/DbModel";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { useSqlQuery } from "src/sql/hooks";
import {
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "src/sql/scripts/queryUtilities";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { SUMMARY_EVENT_CATEGORIES } from "../constants";
import { combineNumericMaps } from "../helpers";
import { useDeviationsSiteSummary } from "./useDeviationsSiteSummary";

const getIncidentCategoriesCountQuery = ({
    siteIds,
    startDate,
    endDate,
    withSiteZone = false,
}: {
    siteIds: string[];
    startDate: Date;
    endDate: Date;
} & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT category_level1 as category, count(*) as incidentsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY category_level1
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

const parseIncidentCategoriesCountResult = ({ results }: WorkerExecResult): Partial<Record<CategoryLevel1, number>> => {
    const output: Partial<Record<CategoryLevel1, number>> = {};
    results[0]?.values?.forEach(([category, value]: [CategoryLevel1, number]) => (output[category] = value));

    return output;
};

const useIncidentsCategoriesCount = (siteIds: string[], startDate: Date, endDate: Date) => {
    const params = useMemo(() => ({ startDate, endDate, siteIds }), [startDate, endDate, siteIds]);

    return useSqlQuery(getIncidentCategoriesCountQuery, parseIncidentCategoriesCountResult, params);
};

const useDeviationsCountPerCategory = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { sitesDeviationsCountMap, isLoading } = useDeviationsSiteSummary(siteIds, startDate, endDate, SUMMARY_EVENT_CATEGORIES);

    const categoryDeviationsMap: Partial<Record<CategoryLevel1Keys, number>> = useMemo(
        () =>
            Object.values(sitesDeviationsCountMap).reduce((acc, categoriesMap) => {
                Object.keys(categoriesMap).forEach((category) => {
                    acc[category] = (acc[category] ?? 0) + categoriesMap[category];
                });

                return acc;
            }, {}),
        [sitesDeviationsCountMap],
    );

    return { categoryDeviationsMap, isLoading };
};

export const useIncidentAndDeviationCategoriesCount = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const { queryResult: categoriesIncidents, isLoading: isLoadingIncidents } = useIncidentsCategoriesCount(siteIds, startDate, endDate);
    const { categoryDeviationsMap, isLoading: isLoadingDeviations } = useDeviationsCountPerCategory(siteIds, startDate, endDate);

    const queryResult = combineNumericMaps(categoriesIncidents ?? {}, categoryDeviationsMap ?? {});

    return {
        queryResult,
        isLoading: isLoadingIncidents || isLoadingDeviations,
    };
};
