import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { InsightsSitePage } from "./InsightsSitePage";
import { InsightsSitePageV2 } from "./InsightsSitePageV2";

export const InsightsSitePageSelector = () => {
    const isV2SitePageEnabled = useFeatureFlag(featureFlags.sitePageTabsBarchart);

    return isV2SitePageEnabled ? <InsightsSitePageV2 /> : <InsightsSitePage />;
};
