import { union } from "lodash";

export const combineNumericMaps = <K extends string = string>(mapA: Partial<Record<K, number>>, mapB: Partial<Record<K, number>>) => {
    return union(Object.keys(mapA), Object.keys(mapB)).reduce(
        (countMap, key) => {
            countMap[key] = (mapA?.[key] ?? 0) + (mapB?.[key] ?? 0);

            return countMap;
        },
        {} satisfies Partial<Record<K, number>>,
    );
};
