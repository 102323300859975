import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CategoryKeys, ChartCategoryKeyType, ChartCategoryType, chartConfig, exceptionTrendsChartCategories } from "src/components/Insights/common/ChartConfig";
import InsightsSelectors from "src/store/insights/InsightsSelectors";
import { useTotalDeviations } from "src/store/insights/PatrolDeviations/PatrolDeviationsHooks";

import { UNGROUPED_ITEMS_VALUE } from "../../../components/Reports/ReportsFilter";
import { countriesByRegions, regionsByCountry } from "../../../data/regions";
import { startOfMonth, subMonths } from "../../../helpers/date";
import * as PatrolDeviationsSelectors from "../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { useAggregatedTourSessionsSummary, useTourSessionsSummaryByLocations } from "../../../store/insights/PatrolsHooks";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { RequestStatus } from "../../../store/RequestStatus";
import { EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS } from "./PatrolTasks.constants";
import { countTotal, formatDeviations, generateRedirectUrl, getMonths } from "./PatrolTasks.helpers";

const allCountries = Object.keys(regionsByCountry);
const allRegions = countriesByRegions.map((r) => r.name);

const useSelectedCategories = ({ siteIds, currentDateRange }: { siteIds: string[]; currentDateRange: Date[] }) => {
    const { t } = useTranslation();
    const [selectedCategories, setSelectedCategories] = useState<(ChartCategoryKeyType & { label: string })[]>([]);
    const translatedChartCategories = useMemo(
        () =>
            exceptionTrendsChartCategories.map((c) => ({
                ...c,
                label: t(`insights.exceptionTrend.${c.key}`),
            })),
        [t],
    );
    const { totalDeviations, isLoading } = useTotalDeviations(siteIds, currentDateRange, EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS);
    const currentMonths = useMemo(() => getMonths(currentDateRange[0], currentDateRange[1]), [currentDateRange]);
    // for prev period we dont need all months, just those from data
    const prevMonths = Object.keys(totalDeviations[1] || {});

    const exceptions = useMemo(() => formatDeviations(totalDeviations[0], currentMonths), [totalDeviations, currentMonths]);
    const previousExceptions = useMemo(() => formatDeviations(totalDeviations[1], prevMonths), [totalDeviations, prevMonths]);

    useEffect(() => {
        setSelectedCategories(
            [
                translatedChartCategories.at(0),
                ...translatedChartCategories.slice(1, -1).sort((a, b) => a.label.localeCompare(b.label)),
                translatedChartCategories.at(-1),
            ].filter((cat) => countTotal(exceptions, cat.key) > 0),
        );
    }, [exceptions, translatedChartCategories]);

    const dropdownItems = useMemo(
        () =>
            selectedCategories
                .filter((c) => c.key !== CategoryKeys.total)
                .map((c) => ({
                    ...c,
                    value: c.key,
                })),
        [selectedCategories],
    );

    const activeCategories = useMemo(
        () => selectedCategories.filter((c) => c.isActive).map((value, index) => ({ ...value, ...chartConfig[index] }) as ChartCategoryType),
        [selectedCategories],
    );

    const toggleCategory = (cat: string) => {
        setSelectedCategories(selectedCategories.map((c) => (c.key === cat ? { ...c, isActive: !c.isActive } : c)));
    };

    return {
        activeCategories,
        selectedCategories,
        dropdownItems,
        toggleCategory,
        isLoading,
        exceptions,
        previousExceptions,
    };
};

export const usePatrolTasks = (siteIds: string[]) => {
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { isLoading: areToursLoading, toursCount, exceptionsCount, ...rest } = useAggregatedTourSessionsSummary();
    const { tourSessionsSummaryByLocation } = useTourSessionsSummaryByLocations(siteIds);
    const filteredSiteIds = useMemo(() => {
        return siteIds.filter(
            (item) =>
                !(allCountries.includes(item) || allRegions.includes(item) || locations.groups.some((x) => x.id === item) || item === UNGROUPED_ITEMS_VALUE),
        );
    }, [siteIds, locations.groups]);
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const deviationsQueryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const currentDateRange = useMemo(() => {
        const startDate = startOfMonth(subMonths(new Date(), EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS));
        return [startDate, new Date()];
    }, []);

    const { exceptions, previousExceptions, activeCategories, ...otherProps } = useSelectedCategories({ siteIds: filteredSiteIds, currentDateRange });

    const isEmptyPage =
        !siteIds?.length ||
        (!toursCount &&
            !exceptionsCount &&
            !rest.historicalExceptionsCount &&
            !rest.historicalToursCount &&
            deviationsQueryStatus === RequestStatus.success &&
            !exceptions.length &&
            !previousExceptions.length);
    const isLoadingPage = siteIds.length && (areToursLoading || deviationsQueryStatus === RequestStatus.loading);

    return {
        isEmptyPage,
        isLoadingPage,
        exceptionTrends: {
            exceptions,
            previousExceptions,
            redirectUrl: generateRedirectUrl(
                currentDateRange,
                selectedRegions,
                activeCategories?.map((categoryParamKey) => categoryParamKey.categoryUrlParameterKey).filter(Boolean),
            ),
            activeCategories,
            ...otherProps,
        },
        patrolDeviations,
        tourSessionsSummaryByLocation,
        tourSessionsSummary: {
            toursCount,
            exceptionsCount,
            ...rest,
        },
    };
};
