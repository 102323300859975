import { ButtonText, Palette, Stack, Text } from "@secuis/ccp-react-components";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChipFilter } from "src/components/shared/ChipFilter/ChipFilter";
import { HorizontalLine } from "src/components/shared/HorizontalLine";

import Accordion, { AccordionContext } from "../../../../../../components/shared/Accordion/Accordion";
import { CheckboxListItem } from "../../../../../../components/shared/CheckboxList/CheckboxListItem";
import { IMultiListItem } from "../../../../../../components/shared/CheckboxList/CheckboxListItem.types";
import { Loading } from "../../../../../../components/shared/Loading";
import { DropdownListStyled, FilterChipsContainerStyled } from "./ModalFilters.styles";

interface ModalFiltersProps {
    items: IMultiListItem[];
    isLoading: boolean;
    selectedIds: string[];
    handleCheckChange: (category: IMultiListItem, activeItemsLength: number) => void;
    disabledIds: string[];
    selectedItems: IMultiListItem[];
    handleChipRemove: (item: IMultiListItem) => void;
    handleClearAll: () => void;
}

export const ModalFilters = ({
    items,
    isLoading,
    selectedIds,
    handleCheckChange,
    disabledIds,
    selectedItems,
    handleChipRemove,
    handleClearAll,
}: ModalFiltersProps) => {
    const { activeItems } = useContext(AccordionContext);
    const { t } = useTranslation();

    const filterWithChildren = useMemo(() => {
        if (activeItems.length === 0) return items;
        let filteredItems = items;

        filteredItems = activeItems.reduce((acc, element, index) => {
            const item = acc.find((x) => x.value === element);
            return index + 1 === activeItems.length ? [item] : item.children;
        }, filteredItems);

        return filteredItems;
    }, [activeItems, items]);

    const handleCheckChangeLocal = useCallback(
        (category: IMultiListItem) => {
            handleCheckChange(category, activeItems.length);
        },
        [handleCheckChange, activeItems.length],
    );

    return (
        <Stack direction="column" flex="0 0 auto" data-testid="filter-section">
            <HorizontalLine color={Palette.Navy600} />
            <Stack direction="row" flexWrap="nowrap" gap="S" justifyContent="space-between" mt="S">
                {selectedItems.length > 0 && (
                    <FilterChipsContainerStyled flexWrap="wrap" gap="XS" alignItems="center">
                        {selectedItems.map((item) => (
                            <ChipFilter
                                data-test-id="filter-chip-categories"
                                key={item.value}
                                label={`${item.parent?.value && item.children.length === 0 ? `${t(`incident.category.${item.parent.value}`)} • ` : ""}${t(
                                    `incident.category.${item.value}`,
                                )}`}
                                onRemove={() => handleChipRemove(item)}
                            />
                        ))}
                        {selectedItems.length > 0 && <ButtonText onClick={handleClearAll}>{t("filters.clearAll")}</ButtonText>}
                    </FilterChipsContainerStyled>
                )}
                <Stack mt="XXS" justifyContent="space-between" flex={selectedItems.length === 0 ? 1 : 0}>
                    {selectedItems.length === 0 && <Text>{t("insights.trendingEvents.modal.selectionInfo")}</Text>}
                    <Text>{selectedItems.length + "/5"}</Text>
                </Stack>
            </Stack>
            {isLoading ? (
                <Loading />
            ) : (
                <DropdownListStyled data-testid="dropdown-list">
                    <Accordion.Back filterActiveItems={activeItems} />
                    {filterWithChildren.map((item) => (
                        <CheckboxListItem
                            key={item.value}
                            item={item}
                            selectedItems={selectedIds}
                            showAllThreshold={5}
                            onItemClickHandler={handleCheckChangeLocal}
                            useCustomContextWrapper={true}
                            disabledItems={disabledIds}
                        />
                    ))}
                </DropdownListStyled>
            )}
        </Stack>
    );
};
