import { PersistedState } from "redux-persist";

import { IUser } from "../../models/UserModel";
import { RequestStatus } from "./../RequestStatus";

export const USERS_GET_BY_IDS_REQUEST = "@@user/USERS_GET_BY_IDS_REQUEST";
export const USERS_GET_BY_IDS_SUCCESS = "@@user/USERS_GET_BY_IDS_SUCCESS";
export const USERS_GET_BY_IDS_FAILURE = "@@user/USERS_GET_BY_IDS_FAILURE";

export interface IUsersState extends PersistedState {
    byIds: {
        status: RequestStatus;
        users: Record<string, IUser>;
    };
}
