export const POST_REPORTS_SEARCH_URL_REGEX = /(.)*\/api\/(v1|v2)\/global\/reports\/search([a-z0-9-])*/;
export const GET_REPORT_DETAILS_URL_REGEX = /(.)*\/api\/v2\/global\/sites\/(([a-z0-9-])*)\/reports\/(([a-z0-9-])*)(?!\/comments)$/;
export const GET_TOUR_DETAILS_URL_REGEX = /(.)*\/api\/v1\/global\/sites\/(([a-z0-9-])*)\/tour-sessions\/(([a-z0-9-])*)(?!\/comments)$/;
export const GET_COMMENTS_URL_REGEX = /(.)*\/api\/(v1|v2)\/global\/sites\/[a-z0-9-]*\/(reports|tour-sessions)\/([a-z0-9-]*)\/comments/;
export const COMMENT_URL_REGEX = /(.)*\/api\/(v1|v2)\/global\/sites\/([a-z0-9-])*\/(reports|tour-sessions)\/([a-z0-9-])*\/comments/;
export const VISIT_URL_REGEX = /(?:.*\/api\/(v1|v2)\/global\/sites\/[a-z0-9-]*\/(?:reports|tour-sessions)\/([a-z0-9-]*)\/visit\/([a-z0-9-]*))/;
export const GET_USERS_URL_REGEX = /(.)*\/api\/v1\/users\/byIds\//;
export const GET_VISITORS_URL_REGEX = /(.)*\/api\/v1\/users\/byIds\/[\w-,]*/;
export const SHARE_REPORT_URL = /(.)*\/api\/v2\/global\/(reports|tour-sessions)\/shared/;
export const POST_EXCEPTIONS_INSIGHTS_AGG_URL_REGEX = /(.)*\/api\/v1\/global\/insights\/(exceptions-agg|tour-exceptions-agg)/;
export const POST_REPORTS_INSIGHTS_URL_REGEX = /(.)*\/api\/(v1|v2)\/global\/insights\/reports/;
export const PUT_REPORT_URL_REGEX = /(.)*\/api\/v2\/global\/sites\/(.)*\/reports\/(.)*/;
export const PUT_TOUR_URL_REGEX = /(.)*\/api\/v1\/global\/sites\/(.)*\/tour-sessions\/(.)*/;
export const POST_TOUR_SESSIONS_FILTERED_REGEX = /(.)*\/api\/(v1|v2)\/global\/insights\/tour-sessions\/filter/;
export const POST_TOUR_SESSIONS_BY_LOCATION_ID_FILTERED_REGEX = /(.)*\/api\/v1\/global\/insights\/tour-sessions\/(([\w-])*)\/filter/;
export const POST_DAILY_TOUR_EXC_SUMMARY_REGEX = /(.)*\/api\/(v1|v2)\/global\/insights\/tour-exc\/summary\/daily/;
export const POST_DAILY_TOUR_EXC_SUMMARY_HOURLY = /(.)*\/api\/(v1|v2)\/global\/insights\/tour-exc\/summary\/hourly/;
export const POST_DAILY_TOUR_EXC_SUMMARY_CATEGORY_LEVEL_2_REGEX = /(.)*\/api\/(v1|v2)\/global\/insights\/tour-exc\/summary\/daily\/(.*)/;
export const GET_REPORT_IMAGE = /(.)*\/api\/v2\/global\/reports\/attachments\/([\w.-]*)/;
