import { Icon, Signal, Spacing, Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import styled from "styled-components";

const ReportsCount = styled(Stack)<{ reportCountSize: number; color: string }>`
    font-family: SecuritasPro-Light;
    font-size: ${(props) => props.reportCountSize}px;
    font-weight: 300;
    line-height: 72px;
    margin-bottom: ${Spacing.S}px;
    color: ${(props) => props.color};
`;

const StyledIcon = styled(Icon)`
    margin-top: ${Spacing.XXS}px;
`;

export const SummaryCardTrend = ({ count, prevPeriodCount }) => {
    const reportCountFontSize = useMemo(() => {
        if (count < 1000) return 78;
        if (count < 10000) return 58;
        if (count < 100000) return 48;
        if (count < 10000000) return 38;
        if (count >= 10000000) return 28;
    }, [count]);

    const trendIconVariant = useMemo(() => {
        if (prevPeriodCount === count) {
            return "TrendMiddle";
        }
        if (prevPeriodCount > count) {
            return "TrendGoingDown";
        }
        if (prevPeriodCount < count) {
            return "TrendGoingUp";
        }
    }, [prevPeriodCount, count]);
    const trendColor = useMemo(() => {
        if (prevPeriodCount < count) {
            return "high";
        }
        return "primary";
    }, [prevPeriodCount, count]);

    return (
        <Stack gap="XXS">
            <ReportsCount data-testid="summary-card-count" color={Signal[trendColor]} reportCountSize={reportCountFontSize}>
                {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </ReportsCount>
            <StyledIcon variant={trendIconVariant} size="XL" color={trendColor} />
        </Stack>
    );
};
