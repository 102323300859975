import { Headline, Modal, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { UlStyled } from "./SiteTimezoneInfoModal.styles";

type Props = {
    isOpen: boolean;
    onClose?: () => void;
};

export const SiteTimezoneInfoModal = ({ isOpen = false, onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="L"
            isOpen={isOpen}
            title={t("userPrompts.siteTimezone.title")}
            subtitle={t("userPrompts.siteTimezone.subtitle")}
            onClose={onClose}
            actions={[
                {
                    children: t("userPrompts.closeButton"),
                    onClick: onClose,
                },
            ]}
        >
            <Stack direction="column" gap="S">
                <Text>{t("userPrompts.siteTimezone.paragraphOne")}</Text>
                <Text>{t("userPrompts.siteTimezone.paragraphTwo")}</Text>
                <Headline>{t("userPrompts.siteTimezone.listTitle")}</Headline>
                <UlStyled>
                    <li>
                        <Text>{t("userPrompts.siteTimezone.listPointOne")}</Text>
                    </li>
                    <li>
                        <Text>{t("userPrompts.siteTimezone.listPointTwo")}</Text>
                    </li>
                    <li>
                        <Text>{t("userPrompts.siteTimezone.listPointThree")}</Text>
                    </li>
                    <li>
                        <Text>{t("userPrompts.siteTimezone.listPointFour")}</Text>
                    </li>
                </UlStyled>
                <Text>{t("userPrompts.siteTimezone.paragraphThree")}</Text>
                <Text>{t("userPrompts.siteTimezone.summary")}</Text>
            </Stack>
        </Modal>
    );
};
