export interface IDbContext {
    dbReadyToQuery: boolean;
    error?: unknown;
    open?: (dbFile: ArrayBuffer) => Promise<unknown>;
    execute?: (sql: string, params?: unknown, messageId?: string | number) => Promise<WorkerExecResult>;
    query?: (sql: string, params?: unknown, messageId?: string | number) => Promise<QueryResult[]>;
    exportDb?: (messageId?: string | number) => Promise<unknown>;
    sqlJsWorker?: Worker;
}

export interface QueryResult {
    columns: any[];
    values: any[];
}

export interface WorkerExecResult {
    id: string;
    results: QueryResult[];
}

export const Databases = {
    Reports: "reports",
};
