export const REPORT_CATEGORIES = [
    {
        key: "criminal_act_or_suspicious_behavior",
        level2Categories: [
            {
                key: "cas_abduction",
                level3Categories: [
                    {
                        key: "cas_abduction_confirmed",
                    },
                    {
                        key: "cas_abduction_suspected",
                    },
                ],
            },
            {
                key: "cas_abuse",
                level3Categories: [
                    {
                        key: "cas_domestic_violence",
                    },
                    {
                        key: "cas_verbal",
                    },
                    {
                        key: "cas_abuse_physical",
                    },
                ],
            },
            {
                key: "cas_burglary",
                level3Categories: [
                    {
                        key: "cas_attempted",
                    },
                    {
                        key: "cas_with_damage",
                    },
                    {
                        key: "cas_without_damage",
                    },
                ],
            },
            {
                key: "cas_disastrous_event",
                level3Categories: [
                    {
                        key: "cas_chemical_biological_attack",
                    },
                    {
                        key: "cas_shooting",
                    },
                    {
                        key: "cas_suspected_terrorism",
                    },
                ],
            },
            {
                key: "cas_drugs_and_alcohol",
                level3Categories: [
                    {
                        key: "cas_found_recovered",
                    },
                    {
                        key: "cas_intoxication",
                    },
                ],
            },
            {
                key: "cas_harassment",
                level3Categories: [
                    {
                        key: "cas_discrimination",
                    },
                    {
                        key: "cas_sexual_harassment",
                    },
                    {
                        key: "cas_harassment_other",
                    },
                ],
            },
            {
                key: "cas_illegal_possession",
                level3Categories: [
                    {
                        key: "cas_confirmed_possession",
                    },
                    {
                        key: "cas_suspected_posession",
                    },
                ],
            },
            {
                key: "cas_other",
                level3Categories: [
                    {
                        key: "cas_bribery",
                    },
                    {
                        key: "cas_illegal_disposal_of_waste",
                    },
                ],
            },
            {
                key: "cas_physical_violence",
                level3Categories: [
                    {
                        key: "cas_armed_assault_melee_weapon",
                    },
                    {
                        key: "cas_armed_assault_with_firearm",
                    },
                ],
            },
            {
                key: "cas_robbery",
                level3Categories: [
                    {
                        key: "cas_armed_robbery",
                    },
                    {
                        key: "cas_attempted_armed_robbery",
                    },
                ],
            },
            {
                key: "cas_theft",
                level3Categories: [
                    {
                        key: "cas_attempted_fraud",
                    },
                    {
                        key: "cas_attempted_shoplifting",
                    },
                ],
            },
            {
                key: "cas_threat",
                level3Categories: [
                    {
                        key: "cas_blackmailing",
                    },
                    {
                        key: "cas_bomb_threat",
                    },
                ],
            },
            {
                key: "cas_tresspassing",
                level3Categories: [
                    {
                        key: "cas_attempted_unauthorized_access",
                    },
                    {
                        key: "cas_soliciting",
                    },
                ],
            },
            {
                key: "cas_unusual_behavior",
                level3Categories: [
                    {
                        key: "cas_loitering",
                    },
                    {
                        key: "cas_radical_behaviour",
                    },
                    {
                        key: "cas_suspicious_behavior",
                    },
                ],
            },
            {
                key: "cas_vandalism",
                level3Categories: [
                    {
                        key: "cas_attempted_equipment_damage",
                    },
                    {
                        key: "cas_attempted_graffiti",
                    },
                ],
            },
        ],
    },
    {
        key: "facility",
        level2Categories: [
            {
                key: "fac_access_control_systems",
                level3Categories: [
                    {
                        key: "fac_access_control_systems_breached",
                    },
                    {
                        key: "fac_access_control_systems_error_fault_alert",
                    },
                ],
            },
            {
                key: "fac_appliances",
                level3Categories: [
                    {
                        key: "fac_appliances_damaged",
                    },
                    {
                        key: "fac_appliances_malfunctioning",
                    },
                ],
            },
            {
                key: "fac_doors_windows",
                level3Categories: [
                    {
                        key: "fac_doors_windows_damaged",
                    },
                    {
                        key: "fac_doors_windows_malfunctioning",
                    },
                    {
                        key: "fac_doors_windows_obstructed",
                    },
                    {
                        key: "fac_doors_windows_found_closed",
                    },
                    {
                        key: "fac_doors_windows_found_opened",
                    },
                ],
            },
            {
                key: "fac_electrical",
                level3Categories: [
                    {
                        key: "fac_electric_malfunctioning",
                    },
                    {
                        key: "fac_power_failure",
                    },
                ],
            },
            {
                key: "fac_elevator_stairs_escalators",
                level3Categories: [
                    {
                        key: "fac_elevator_stairs_escalators_damaged",
                    },
                ],
            },
            {
                key: "fac_fire_systems",
                level3Categories: [
                    {
                        key: "fac_fire_systems_breached",
                    },
                    {
                        key: "fac_fire_systems_error_fault_alert",
                    },
                ],
            },
            {
                key: "fac_gates_fences",
                level3Categories: [
                    {
                        key: "fac_gates_fences_damaged",
                    },
                    {
                        key: "fac_gates_fences_malfunctioning",
                    },
                ],
            },
            {
                key: "fac_heating_ventilation_air_condition",
                level3Categories: [
                    {
                        key: "fac_heating_ventilation_air_condition_damaged",
                    },
                    {
                        key: "fac_heating_ventilation_air_condition_malfunctioning",
                    },
                ],
            },
            {
                key: "fac_lighting",
                level3Categories: [
                    {
                        key: "fac_lighting_damaged",
                    },
                    {
                        key: "fac_lighting_malfunctioning",
                    },
                    {
                        key: "fac_lighting_found_on",
                    },
                ],
            },
            {
                key: "fac_security_systems",
                level3Categories: [
                    {
                        key: "fac_security_systems_armed",
                    },
                ],
            },
            {
                key: "fac_signage",
                level3Categories: [
                    {
                        key: "fac_signage_damaged",
                    },
                    {
                        key: "fac_signage_malfunctioning",
                    },
                ],
            },
            {
                key: "fac_structures_buildings",
                level3Categories: [
                    {
                        key: "fac_structures_buildings_damaged",
                    },
                ],
            },
            {
                key: "fac_water_and_sanitary",
                level3Categories: [
                    {
                        key: "fac_water_and_sanitary_damaged",
                    },
                ],
            },
        ],
    },
    {
        key: "health_and_safety",
        level2Categories: [
            {
                key: "has_accident",
                level3Categories: [
                    {
                        key: "has_accident_contact_heat_chemical_electricity",
                    },
                ],
            },
            {
                key: "has_active_hazard",
                level3Categories: [
                    {
                        key: "has_explosion",
                    },
                ],
            },
            {
                key: "has_inspections_audits",
                level3Categories: [
                    {
                        key: "has_fire_audit_violation",
                    },
                    {
                        key: "has_safety_audit_violation",
                    },
                ],
            },
            {
                key: "has_potential_hazard",
                level3Categories: [
                    {
                        key: "has_electrical",
                    },
                    {
                        key: "has_potential_hazard_fire",
                    },
                ],
            },
            {
                key: "has_public_disturbance",
                level3Categories: [
                    {
                        key: "has_disturbing_indecent_behaviour",
                    },
                    {
                        key: "has_loud_noice",
                    },
                    {
                        key: "has_protest_demonstration",
                    },
                ],
            },
            {
                key: "has_workplace_violation",
                level3Categories: [
                    {
                        key: "has_improper_safety_equipment",
                    },
                    {
                        key: "has_intoxication",
                    },
                ],
            },
        ],
    },
    {
        key: "internal_securitas",
        level2Categories: [
            {
                key: "ise_guard_uniform",
                level3Categories: [
                    {
                        key: "ise_guard_uniform_damaged",
                    },
                    {
                        key: "ise_guard_uniform_lost_missing",
                    },
                    {
                        key: "ise_guard_uniform_stolen",
                    },
                ],
            },
            {
                key: "ise_securitas_guard_assets_equipment",
                level3Categories: [
                    {
                        key: "ise_securitas_guard_assets_equipment_damaged",
                    },
                    {
                        key: "ise_lost_missing",
                    },
                ],
            },
        ],
    },
    {
        key: "people_and_assets",
        level2Categories: [
            {
                key: "paa_access_control",
                level3Categories: [
                    {
                        key: "paa_access_denied",
                    },
                    {
                        key: "paa_badge_violation",
                    },
                ],
            },
            {
                key: "paa_asset_management",
                level3Categories: [
                    {
                        key: "paa_asset_management_damaged",
                    },
                    {
                        key: "paa_intellectual_property_at_risk",
                    },
                ],
            },
            {
                key: "paa_goods_and_products",
                level3Categories: [
                    {
                        key: "paa_goods_and_products_abandoned",
                    },
                    {
                        key: "paa_broken_missing_seal",
                    },
                ],
            },
            {
                key: "paa_improper_disposal",
                level3Categories: [
                    {
                        key: "paa_littering",
                    },
                ],
            },
            {
                key: "paa_lost_and_found",
                level3Categories: [
                    {
                        key: "paa_found_property",
                    },
                    {
                        key: "paa_lost_property",
                    },
                ],
            },
            {
                key: "paa_other_people_and_assets",
                level3Categories: [
                    {
                        key: "paa_other_people_and_assets_media_presence",
                    },
                ],
            },
            {
                key: "paa_security_assistance",
                level3Categories: [
                    {
                        key: "paa_security_assistance_call_for_assistance",
                    },
                ],
            },
            {
                key: "paa_vehicle_and_parking",
                level3Categories: [
                    {
                        key: "paa_vehicle_and_parking_abandoned",
                    },
                    {
                        key: "paa_break_down",
                    },
                ],
            },
        ],
    },
];
