import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import { CarouselContext } from "pure-react-carousel";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

const CounterStyled = styled.div`
    position: absolute;
    left: 50%;
    bottom: ${Spacing.L}px;

    span {
        position: relative;
        left: -50%;
    }

    @media (max-width: ${Breakpoints.XS}) {
        top: ${Spacing.XL}px;
    }

    @media only screen and (max-height: 1112px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        left: auto;
        top: auto;
        bottom: ${Spacing.XS}px;
        right: ${Spacing.L}px;

        span {
            left: auto;
        }
    }
`;

const CarouselSlideCounter = () => {
    const carouselContext = useContext(CarouselContext);
    const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
    const [totalSlides] = useState(carouselContext.state.totalSlides);

    useEffect(() => {
        function onChange() {
            setCurrentSlide(carouselContext.state.currentSlide);
        }
        carouselContext.subscribe(onChange);
        return () => carouselContext.unsubscribe(onChange);
    }, [carouselContext]);

    return (
        <CounterStyled data-testid="image-counter">
            <span>
                {currentSlide + 1}/{totalSlides}
            </span>
        </CounterStyled>
    );
};

export default CarouselSlideCounter;
