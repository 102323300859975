import { Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReportsListItemType } from "../../../../../models/ReportModel";

type Props = {
    report: ReportsListItemType;
};

const WrappedText = styled(Text)`
    white-space: pre-wrap;

    &:not(:last-child)::after {
        content: "•";
        color: ${Palette.Navy100};
        margin-left: ${Spacing.XXS}px;
    }
`;

export const ListItemCategory = ({ report }: Props) => {
    const { t } = useTranslation();
    const { categoryLevel1, categoryLevel2, categoryLevel3 } = report;

    return (
        <Stack data-testid="report-category" flexWrap="wrap" gap="XXS">
            <WrappedText data-testid="report-list-item-category-lvl-one" bold uppercase micro>
                {t(`incident.category.${categoryLevel1}`)}
            </WrappedText>
            <WrappedText data-testid="report-list-item-category-lvl-two" bold uppercase micro>
                {t(`incident.category.${categoryLevel2}`)}
            </WrappedText>
            <WrappedText bold uppercase micro>
                {t(`incident.category.${categoryLevel3}`)}
            </WrappedText>
        </Stack>
    );
};
