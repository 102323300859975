import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { ComponentType } from "react";
import { RuleSet } from "styled-components";

import { CellStyled, RowStyled } from "./Table.styles";
import { TableRowVariant } from "./Table.types";

type Props = {
    data: { [k: string]: any };
    columns: {
        id: string;
        header?: string | JSX.Element;
        key: string;
        Cell?: ComponentType<any>;
        style?: RuleSet<object>;
    }[];
    index: number;
    isParent: boolean;
    isExpanded?: boolean;
    isExpandable?: boolean;
    toggleRow?: (index: number) => void;
    getRowVariant?: (data: unknown) => TableRowVariant;
};

const defaultGetRowVariant = () => TableRowVariant.default;

export const TableRow = ({ data, index, isParent, isExpanded, columns, isExpandable, toggleRow, getRowVariant = defaultGetRowVariant }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return isParent ? (
        <RowStyled role="row" variant={getRowVariant(data)}>
            {columns.map(({ id, key, Cell, style, ...rest }) => (
                <CellStyled role="cell" data-testid={`${key}-${index}`} styles={style} key={`cell-${id}`}>
                    {Cell ? (
                        <Cell
                            onClick={() => {
                                if (isExpandable) {
                                    toggleRow(index);
                                }
                            }}
                            index={index}
                            isExpanded={isExpanded}
                            row={data}
                            cell={data[key]}
                            column={{ id, key, Cell, style, ...rest }}
                        />
                    ) : (
                        <Text truncate={isMobile} key={`cell-${id}`}>
                            {data[key]}
                        </Text>
                    )}
                </CellStyled>
            ))}
        </RowStyled>
    ) : (
        <RowStyled role="row" variant={TableRowVariant.child}>
            {columns.map(({ id, key, Cell, style, ...rest }) => (
                <CellStyled role="cell" styles={style} key={`cell-${id}`}>
                    {Cell ? <Cell row={data} cell={data[key]} column={{ id, key, Cell, style, ...rest }} /> : <Text truncate={isMobile}>{data[key]}</Text>}
                </CellStyled>
            ))}
        </RowStyled>
    );
};
