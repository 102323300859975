import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SymbolsUsedTooltipContent } from "../SymbolsUsedTooltipContent";

export const TooltipContent = () => {
    const { t } = useTranslation();
    const content = t("insights.patrolTours.compareTooltipContent").split("<br>").filter(Boolean);

    return (
        <Stack direction="column" gap="XS">
            {content.map((paragraph) => (
                <span key={paragraph.slice(0, 5)}>{paragraph}</span>
            ))}

            <SymbolsUsedTooltipContent />
        </Stack>
    );
};
