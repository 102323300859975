import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

const TabContentWrapperMobileStyles = `
    gap: ${Spacing.S}px;
    padding: ${Spacing.S}px;
`;

export const TabContentWrapper = styled(Stack)<{ isFilterActive: boolean }>`
    flex: 1;
    min-width: 0;
    flex-direction: column;
    padding: ${Spacing.M}px 40px;

    @media (max-width: ${Breakpoints.S}) {
        ${(props) => (props.isFilterActive ? TabContentWrapperMobileStyles : "")}
    }

    @media (max-width: ${Breakpoints.XS}) {
        ${TabContentWrapperMobileStyles}
    }
`;
