import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import AppActions from "../store/app/AppActions";
import AppSelectors from "../store/app/AppSelectors";

export const useStoreQueryParams = (paramKeys: string[]) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const paramsInState = useSelector(AppSelectors.selectQueryParams);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries([...searchParams]);
        const toStore = paramKeys.reduce((acc, key) => {
            if (!Object.keys(paramsInState).includes(key)) {
                acc[key] = searchParamsObject[key];
            }
            return acc;
        }, {});

        if (Object.keys(toStore).length > 0) {
            dispatch(AppActions.storeQueryParams(toStore));
        }
    }, [dispatch, paramKeys, paramsInState, searchParams]);
};
