import { EmptyState, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

export const EmptyStateBarchart = () => {
    const { t } = useTranslation();
    return (
        <EmptyState icon="GraphIndicator" title={t("common.noDataToDisplay")}>
            <Text>{t("incidentMatrix.barchart.emptyState.subtitle")}</Text>
        </EmptyState>
    );
};
