import { createSelector } from "reselect";
import { isSameDay } from "src/helpers/date";

import { IState } from "../../Store";
import { defaultFilterState } from "./InsightsReducer";

const mapFilterState = ({ insights: { filterState } }: IState) => filterState;
const getSelectedRegions = createSelector([mapFilterState], (filterState) => filterState.selectedRegions);
const getSelectedStartDate = createSelector([mapFilterState], (filterState) => filterState.selectedStartDate);
const getSelectedEndDate = createSelector([mapFilterState], (filterState) => filterState.selectedEndDate);
const getAllFiltersToSave = createSelector([mapFilterState], (filterState) => {
    const isDefaultDateRange =
        isSameDay(filterState.selectedStartDate, defaultFilterState.selectedStartDate) &&
        isSameDay(filterState.selectedEndDate, defaultFilterState.selectedEndDate);
    return {
        selectedRegions: filterState.selectedRegions,
        selectedStartDate: isDefaultDateRange ? null : filterState.selectedStartDate,
        selectedEndDate: isDefaultDateRange ? null : filterState.selectedEndDate,
    };
});

export default {
    getAllFiltersToSave,
    getSelectedRegions,
    getSelectedStartDate,
    getSelectedEndDate,
};
