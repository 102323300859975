import { formatExplicit, RAW_DATE_FORMAT, subDays } from "src/helpers/date";

import { DAMAGED_CATEGORIES } from "../../../constants";
import { HighlightsCategories, HighlightsType, QueryTimeRange } from "../../../models";
import { QueryResult } from "../../../models/DbModel";
import { IHighlightsCard } from "../../../store/insights/InsightsModel";
import { generateStringForQuery, getDateTimeFieldName, getReportQueryTimeRangeCondition } from "../queryUtilities";
import {
    accessControlCategories,
    accessControlSystemsCategories,
    confrontationCategories,
    criticalCategories,
    equipmentAccidentCategories,
    medicalAssistanceCategories,
    preventionCategories,
    reportedAuditsCategories,
} from "./highlightsCategories";

export const CARDS_PAGE_LIMIT = 20;

const generateQuery = (categoriesArray: HighlightsCategories[]): string[] => {
    return categoriesArray.flatMap((x) => x.categoriesLevel3.map((cat3) => x.categoryLevel2 + "_" + cat3));
};

export const mapToHighlights = (cards: QueryResult[]): IHighlightsCard[] => {
    if (!cards?.length) return [];
    return cards[0].values.reduce((mappedArray: IHighlightsCard[], entry) => {
        mappedArray.push({
            categoryLevel2: entry[0],
            categoryLevel3: entry[1],
            reportDate: new Date(entry[2]),
            id: entry[3].toString(),
            siteId: entry[4],
            categoryLevel1: entry[5],
            severity: entry[6],
            isRead: !!entry[7],
        });

        return mappedArray;
    }, []);
};

const generateHighlightsCategoriesInsert = (highlightsType: HighlightsType): string =>
    generateQuery(getCategories(highlightsType))
        .reduce((insertString, categoryKey) => {
            insertString += ` ("${categoryKey}"),`;
            return insertString;
        }, "")
        .slice(0, -1) + ";";

const getCategoriesString = (categories: string[]) => categories.map((item) => `"${item}"`).join(",");

export const getHighlightsCategoriesQuery = (
    page: number,
    siteIds: string[],
    selectedStartDate: Date,
    selectedEndDate: Date,
    categories: string[],
    withSiteZone = false,
) => {
    return {
        sql: `SELECT r.category_level2, r.category_level3, strftime('%Y-%m-%dT%H:%M:%SZ', r.report_date_time), r.mys_id, r.location_id, r.category_level1, r.severity_level, r.is_read
                FROM reports r
                WHERE ${getReportQueryTimeRangeCondition(selectedStartDate, selectedEndDate, withSiteZone, "r")}
                AND r.location_id IN (${generateStringForQuery(siteIds)})
                AND (r.severity_level IN (4) OR r.category_level3 IN (${getCategoriesString(categories)}))
                ORDER BY r.is_read ASC, r.report_date_time DESC
                LIMIT ${CARDS_PAGE_LIMIT}
                OFFSET (${page} * ${CARDS_PAGE_LIMIT});`,
        params: {},
    };
};

export const getCriticalIncidentsBySiteQuery = (
    siteIds: string[],
    selectedStartDate: Date,
    selectedEndDate: Date,
    categories: string[],
    withSiteZone = false,
) => {
    return {
        sql: `SELECT r.location_id,COUNT(*) as count
                FROM reports r
                WHERE ${getReportQueryTimeRangeCondition(selectedStartDate, selectedEndDate, withSiteZone, "r")}
                AND r.location_id IN (${generateStringForQuery(siteIds)})
                AND r.is_read == 0
                AND (r.severity_level IN (4) OR r.category_level3 IN (${getCategoriesString(categories)}))
                GROUP BY r.location_id`,
        params: {},
    };
};

export const updateReportReadFlagQuery = (reportId: string, isRead: boolean) => {
    return `UPDATE reports
    SET is_read = ${isRead ? 1 : 0}
    WHERE mys_id = '${reportId}'
    `;
};

const getCategories = (highlightsType: HighlightsType) => {
    switch (highlightsType) {
        case "critical_categories":
            return criticalCategories;
        case "prevention_categories":
            return preventionCategories;
        case "access_control_categories":
            return accessControlCategories;
        case "access_control_systems_categories":
            return accessControlSystemsCategories;
        case "medical_assistance_categories":
            return medicalAssistanceCategories;
        case "confrontation_categories":
            return confrontationCategories;
        case "equipment_accident_categories":
            return equipmentAccidentCategories;
        case "reported_audits_categories":
            return reportedAuditsCategories;
        case "damaged_reports_categories":
            return DAMAGED_CATEGORIES;
    }
};

export const insertAllHighlightsCategories = (): string => {
    return insertHighlightsCategories("damaged_reports_categories")
        .concat(insertHighlightsCategories("critical_categories"))
        .concat(insertHighlightsCategories("prevention_categories"))
        .concat(insertHighlightsCategories("access_control_categories"))
        .concat(insertHighlightsCategories("access_control_systems_categories"))
        .concat(insertHighlightsCategories("medical_assistance_categories"))
        .concat(insertHighlightsCategories("confrontation_categories"))
        .concat(insertHighlightsCategories("equipment_accident_categories"))
        .concat(insertHighlightsCategories("reported_audits_categories"));
};

const insertHighlightsCategories = (highlightsType: HighlightsType): string => {
    return `INSERT INTO ${highlightsType} (category_key)
    VALUES ${generateHighlightsCategoriesInsert(highlightsType)}`;
};

export const getReportsCountQuery = (highlightsType: HighlightsType, siteIds: string[], timeRange: QueryTimeRange, withSiteZone = false): string => {
    const now = new Date();
    const dateFieldName = getDateTimeFieldName(withSiteZone);

    let prevTimeRangeEnd;
    switch (timeRange) {
        case QueryTimeRange.LastWeek:
            prevTimeRangeEnd = subDays(now, 7);
            break;
        case QueryTimeRange.Last2Weeks:
            prevTimeRangeEnd = subDays(now, 14);
            break;
        case QueryTimeRange.Last30Days:
            prevTimeRangeEnd = subDays(now, 30);
            break;
        case QueryTimeRange.Last3Months:
            prevTimeRangeEnd = subDays(now, 90);
    }

    return `SELECT COUNT(r.id) as count1, 1 as order_index
                FROM reports r
                INNER JOIN ${highlightsType} c ON r.category_key=c.category_key
                WHERE DATE(r.${dateFieldName}, 'localtime') >= DATE('now', '${timeRange}', 'localtime')
                AND r.location_id IN (${generateStringForQuery(siteIds)})
                UNION ALL
                SELECT COUNT(r.id) as count2, 2 as order_index
                FROM reports r
                INNER JOIN ${highlightsType} c ON r.category_key=c.category_key
                WHERE DATE(r.${dateFieldName}, 'localtime') >= DATE('${formatExplicit(
                    prevTimeRangeEnd,
                    RAW_DATE_FORMAT,
                )}', '${timeRange}', 'localtime') and DATE(r.${dateFieldName}, 'localtime') < DATE('now', '${timeRange}', 'localtime')
                AND r.location_id IN (${generateStringForQuery(siteIds)})
                ORDER BY order_index;
            `;
};
