import { InMemoryProvider, OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";

import { ChildrenProps } from "../models/ChildrenPropModel";

const inMemoryClientName = "in-memory-ld-client";

type Flag = {
    variants: Record<string, boolean>;
    defaultVariant: string;
    disabled: boolean;
};

export type FlagConfiguration = Record<string, Flag>;

type Props = ChildrenProps & {
    flags?: FlagConfiguration;
};

export const InMemoryFeatureFlagProvider = ({ flags, children }: Props) => {
    const featureFlagInMemoryProvider = new InMemoryProvider(flags);
    OpenFeature.setProvider(inMemoryClientName, featureFlagInMemoryProvider);
    return <OpenFeatureProvider clientName={inMemoryClientName}>{children}</OpenFeatureProvider>;
};
