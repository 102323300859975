export enum VehicleInspectionFields {
    licensePlate = "licensePlate",
    vehicleModel = "vehicleModel",
    modelCar = "modelCar",
    comments = "comments",
    exteriorDamage = "exteriorDamage?",
    interiorDamage = "interiorDamage?",
    brokenCrackedWindows = "broken/crackedWindows?",
    isAllEmergencyEquipmentAvailable = "isAllEmergencyEquipmentAvailable?",
    missingEquipment = "whatEquipmentIsMissing?",
    mileageStart = "mileageStart",
    mileageEnd = "mileageEnd",
    fuelLevel = "fuelLevel",
    oilLevel = "oilLevel",
    indicateTheLocationsOfDamageToTheVehicle = "indicateTheLocation(s)OfDamageToTheVehicle?",
}
