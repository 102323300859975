import { Box, Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { TableComponent } from "../shared/Table/Table";

export const HeaderWrapperStyled = styled(Box)`
    padding: 0 ${Spacing.XS}px;
    margin-bottom: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.M}) {
        margin-bottom: ${Spacing.S}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        margin-bottom: ${Spacing.XS}px;
        padding: 0;
    }
`;

export const SkeletonWrapperStyled = styled(Stack)`
    height: 100%;
`;

export const TableWrapperStyled = styled(Box)`
    overflow-y: auto;
`;

export const ScheduleTableStyled = styled(TableComponent)`
    grid-template-columns: auto minmax(0, auto) repeat(5, auto);
`;

export const SortButtonEmptyPlaceholderStyled = styled.div`
    width: 20px;
`;
