export const getOperatingSystem = () => {
    if (navigator?.["userAgentData"]?.["platform"]) {
        return navigator["userAgentData"]["platform"];
    }
    const lowerCasePlatform = navigator.platform.toLowerCase();
    if (lowerCasePlatform.includes("win")) {
        return "Windows";
    }
    if (lowerCasePlatform.includes("mac")) {
        return "macOS";
    }
    if (lowerCasePlatform.includes("linux")) {
        return "Linux";
    }
    if (lowerCasePlatform.includes("iphone") || lowerCasePlatform.includes("ipad")) {
        return "iOS";
    }
    if (lowerCasePlatform.includes("android")) {
        return "Android";
    }
    return "unknown";
};
