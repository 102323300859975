import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { maxBy, sumBy } from "lodash";
import { useMemo } from "react";

import { featureFlags } from "../../../../data/featureFlags";
import { formatTime, set } from "../../../../helpers/date";
import { useApiRequest } from "../../../../hooks/accessApi";
import { useFeatureFlag } from "../../../../hooks/featureFlags";
import { useSqlQuery } from "../../../../sql/hooks";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { getApiVersion, parseDateRangeRequestBody } from "../../../../store/insights/helpers";
import { useSummaryPeriod } from "../shared/hooks";
import { getDeviantHoursQuery, parseDeviantHours } from "./DeviantHourWidget.queries";
import { ExceptionStat, HourItem } from "./DeviantHourWidget.types";
import { LineChartDataset } from "./LineChart";

export const useDeviantHourWidget = () => {
    const { currentPeriod } = useSummaryPeriod();
    const isMobile = useHasMaxWidth(Breakpoints.S);
    const { siteIds } = useFilteredSites();
    const { hourEvents, isLoading } = useEventsPerHour(siteIds, currentPeriod.start, currentPeriod.end);
    const deviantHours = useMemo(() => getTopHours(hourEvents), [hourEvents]);
    const topHourLabel = useMemo(() => getTopHourLabel(deviantHours), [deviantHours]);
    const totalEvents = sumBy(hourEvents, "value") ?? 0;
    const chartData: LineChartDataset[] = useMemo(
        () => [
            {
                id: "totalEvents",
                values: hourEvents ?? [],
                highlights: deviantHours.length <= 3 ? deviantHours : [],
            },
        ],
        [hourEvents, deviantHours],
    );
    const hoursChartTicks = useMemo(() => chartData[0].values.map(({ key }) => key), [chartData]);

    return {
        isLoading,
        isEmpty: !totalEvents,
        chartData,
        topHourLabel,
        xAxisTicks: isMobile ? hoursChartTicks.filter((_, index) => [0, 6, 12, 18, 23].includes(index)) : hoursChartTicks,
    };
};

const useEventsPerHour = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { queryResult, isLoading: isLoadingReports } = useSqlQuery(getDeviantHoursQuery, parseDeviantHours, { siteIds, startDate, endDate });
    const { exceptionsPerHour, isLoading: isLoadingExceptions } = useExceptionsPerHour(siteIds, startDate, endDate);

    const hourEventsCount: HourItem[] = [...new Array(24)].map((_, hour) => {
        const hourKey = hour.toString().padStart(2, "0");

        return {
            key: hourKey,
            value: (exceptionsPerHour[hourKey] ?? 0) + (queryResult?.[hourKey]?.totalEvents ?? 0),
        };
    });

    return {
        hourEvents: hourEventsCount,
        isLoading: isLoadingReports || isLoadingExceptions,
    };
};

const useExceptionsPerHour = (siteIds: string[], startDate: Date, endDate: Date) => {
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const { isLoading, data } = useApiRequest<ExceptionStat[] | undefined>(
        `/${getApiVersion(areSiteZonesEnabled)}/global/insights/tour-exc/summary/hourly`,
        "POST",
        parseDateRangeRequestBody(
            {
                siteIds,
                fromDateTime: startDate,
                toDateTime: endDate,
                groupHours: false,
            },
            areSiteZonesEnabled,
        ),
    );

    const exceptionsCountPerHour: Record<string, number> = (data ?? []).reduce((acc, { count, hours }) => {
        const [hour] = hours.split(":");

        acc[hour] = (acc[hour] ?? 0) + count;
        return acc;
    }, {});

    return {
        exceptionsPerHour: exceptionsCountPerHour,
        isLoading,
    };
};

const getTopHourLabel = (deviantHours: string[]): string => {
    return deviantHours.length === 1
        ? formatTime(
              set(new Date(), {
                  hours: parseInt(deviantHours[0]),
                  minutes: 0,
              }),
          )
        : undefined;
};

const getTopHours = (hourEvents: HourItem[]) => {
    const highestEventsCount = maxBy(hourEvents, "value")?.value;
    const topHours = hourEvents.filter(({ value }) => highestEventsCount && value === highestEventsCount).map(({ key }) => key);

    return topHours ?? [];
};
