import { useCallback, useEffect } from "react";

export const useOnEscapeEventListener = (
    callback: () => void,
    activeElementContainerRef?: React.RefObject<HTMLElement> | null,
    isContainerVisible = true,
    isStopPropagation?: boolean,
) => {
    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                if (activeElementContainerRef && !activeElementContainerRef.current?.contains(document.activeElement)) {
                    return;
                }

                if (isStopPropagation) {
                    e.stopImmediatePropagation();
                }

                callback();
            }
        },
        [activeElementContainerRef, isStopPropagation, callback],
    );

    useEffect(() => {
        if (isContainerVisible) {
            window.addEventListener("keydown", handleKeyDown);
        } else {
            window.removeEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isContainerVisible, handleKeyDown]);
};

export const useOnKeyDown = <T>(key: string | string[], callback?: (props?: T) => void, isPreventDefault?: boolean) => {
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent, props?: T) => {
            if (e.key === key || (Array.isArray(key) && key.includes(e.key))) {
                if (isPreventDefault) {
                    e.preventDefault();
                }

                callback?.(props);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isPreventDefault, callback],
    );

    return handleKeyDown;
};

export const useOnEnterSpaceKeyDown = <T>(callback?: (props?: T) => void, isPreventDefault?: boolean) => {
    return useOnKeyDown<T>(["Enter", " "], callback, isPreventDefault);
};
