import { Spacing, Stack } from "@secuis/ccp-react-components";
import { Fragment } from "react";
import { HorizontalLine } from "src/components/shared/HorizontalLine";

import { SEPARATOR_COLOR } from "../../Summary.constants";
import { StatisticData } from "../types";
import { SummaryValueTile } from "./SummaryValueTile";

type Props = {
    stats: StatisticData[];
    isLoading?: boolean;
    spacing?: Spacing | number;
};

export const StatsList = ({ stats, isLoading, spacing = Spacing.M }: Props) => {
    return (
        <Stack direction="column" flex={1} data-testid="stats-list">
            {stats.map(({ id, title, label, icon, value, previousValue, onClick }, index) => (
                <Fragment key={id}>
                    {!!index && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={spacing} />}
                    <SummaryValueTile
                        data-testid={`stats-list-item-${id}`}
                        title={title}
                        icon={icon}
                        value={value}
                        previousValue={previousValue}
                        label={label}
                        loading={isLoading}
                        onClick={onClick}
                    />
                </Fragment>
            ))}
        </Stack>
    );
};
