import { faker } from "@faker-js/faker";
import { HttpResponse } from "msw";
import db from "src/mocks/db";

import { ICommentDto, IUpsertCommentDto } from "../../../store/reportComments/models";

export const upsertCommentResolver = (payload: IUpsertCommentDto) => {
    const comment = payload.id ? updateComment(payload) : createComment(payload);

    return HttpResponse.json(comment);
};

const createComment = (payload: IUpsertCommentDto): ICommentDto => {
    const { appId, appContext, creatorId, mentionedUserIds, comment } = payload;
    const reportId = getReportIdFromAppContext(appContext);

    const createdAt = new Date().toISOString();

    const newComment: ICommentDto = {
        id: faker.string.uuid(),
        referredEntityId: reportId,
        appId: appId,
        appContext: "Global",
        creatorId: creatorId,
        createdTime: createdAt,
        lastModifiedTime: createdAt,
        content: comment,
        isDeleted: false,
        mentionedUserIds,
    };

    try {
        db.comments.create(newComment);
    } catch (error) {
        throw new Error(`Failed to create new comment: ${error}`);
    }

    const commentsCount = db.comments.count({
        where: {
            referredEntityId: {
                equals: reportId,
            },
            isDeleted: {
                equals: false,
            },
        },
    });

    db.reports.update({
        where: {
            id: {
                equals: reportId,
            },
        },
        data: {
            commentsCount: commentsCount,
        },
    });

    return newComment;
};

const updateComment = (payload: IUpsertCommentDto): ICommentDto => {
    const { id } = payload;

    const updatedComment = db.comments.update({
        where: {
            id: {
                equals: id,
            },
        },
        data: {
            content: payload.comment,
        },
    }) as ICommentDto;

    return updatedComment;
};

const getReportIdFromAppContext = (appContext: string): string => {
    const REPORT_ID_REGEX = /global\/reports\/details\/[a-z0-9-]*\/([a-z0-9-]*)/;
    const match = appContext.match(REPORT_ID_REGEX);

    return match ? match[1] : "";
};

export default upsertCommentResolver;
