import { sum, union } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSqlQuery } from "../../../../sql/hooks";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useAuthorizedLocations } from "../../../../store/locations/LocationsHooks";
import { useSummaryPeriod } from "../shared/hooks";
import { useDeviationsSiteSummary } from "../shared/hooks/useDeviationsSiteSummary";
import { StatisticData } from "../shared/types";
import { getReportsCountQuery, parseReportsCountResult } from "./ReportsCountWidget.queries";
import { ReportsCountData } from "./ReportsCountWidget.types";

export const useReportsCountWidget = () => {
    const { t } = useTranslation();
    const { siteObjects } = useAuthorizedLocations();
    const { siteIds } = useFilteredSites();
    const selectedSitesCount = siteIds.length ?? siteObjects?.length ?? 0;
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { reportsCountData: currentPeriodValues, isLoading: isLoadingCurrent } = useCombinedReportsAndDeviations(currentPeriod.start, currentPeriod.end);
    const { reportsCountData: previousPeriodValues, isLoading: isLoadingPrevious } = useCombinedReportsAndDeviations(previousPeriod.start, previousPeriod.end);

    const isLoading = isLoadingCurrent || isLoadingPrevious;

    const sitesWithoutReportsCount = {
        current: selectedSitesCount < currentPeriodValues.sitesCount ? 0 : selectedSitesCount - currentPeriodValues.sitesCount,
        previous: selectedSitesCount < previousPeriodValues.sitesCount ? 0 : selectedSitesCount - previousPeriodValues.sitesCount,
    };

    const countStats: StatisticData[] = [
        {
            id: "reports-count",
            title: t("insights.summary.reportsCountWidget.reportsCount"),
            label: t("common.reports", { count: currentPeriodValues.reportsCount }),
            value: currentPeriodValues.reportsCount,
            previousValue: previousPeriodValues.reportsCount,
        },
        {
            id: "sites-with-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithReports"),
            label: t("common.sites", { count: currentPeriodValues.sitesCount }),
            value: currentPeriodValues.sitesCount,
            previousValue: previousPeriodValues.sitesCount,
        },
        {
            id: "sites-without-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithoutReports"),
            label: t("common.sites", { count: sitesWithoutReportsCount.current }),
            value: sitesWithoutReportsCount.current,
            previousValue: sitesWithoutReportsCount.previous,
        },
    ];

    return { isLoading, countStats };
};

const useReportsCountQuery = (siteIds: string[], startDate: Date, endDate: Date) => {
    const queryParams = useMemo(() => ({ siteIds, startDate, endDate }), [siteIds, startDate, endDate]);
    const { isLoading, queryResult } = useSqlQuery(getReportsCountQuery, parseReportsCountResult, queryParams);

    return { siteReportsCountMap: queryResult, isLoading };
};

const useCombinedReportsAndDeviations = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const { siteReportsCountMap, isLoading: isLoadingReports } = useReportsCountQuery(siteIds, startDate, endDate);
    const { sitesDeviationsCountMap = {}, isLoading: isLoadingDeviations } = useDeviationsSiteSummary(siteIds, startDate, endDate);

    const totalReports = sum(Object.values(siteReportsCountMap || {}));
    const totalDeviations = Object.values(sitesDeviationsCountMap || {})?.reduce((total, categoriesMap) => total + sum(Object.values(categoriesMap)), 0);
    const sitesCount = union(Object.keys(siteReportsCountMap || {}), Object.keys(sitesDeviationsCountMap || {})).length;

    return {
        isLoading: isLoadingReports || isLoadingDeviations,
        reportsCountData: {
            sitesCount,
            reportsCount: totalReports + totalDeviations,
        } satisfies ReportsCountData,
    };
};
