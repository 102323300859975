import { createSelector } from "reselect";

import { IState } from "../../../Store";

const mapPatrolDeviationsState = ({ insights: { patrolDeviationsState } }: IState) => patrolDeviationsState;

const getPatrolDeviations = createSelector([mapPatrolDeviationsState], (patrolDeviationsState) => patrolDeviationsState.patrolDeviations);
const getPatrolDeviationsQueryStatus = createSelector([mapPatrolDeviationsState], (patrolDeviationsState) => patrolDeviationsState.queryStatus);
const getPatrolDeviationsQueryKey = createSelector([mapPatrolDeviationsState], (patrolDeviationsState) => patrolDeviationsState.queryKey);

export { getPatrolDeviations, getPatrolDeviationsQueryStatus, getPatrolDeviationsQueryKey };
