import { ToastProps } from "@secuis/ccp-react-components";

import {
    ADD_IN_APP_NOTIFICATION,
    APP_API_ERROR,
    APP_DB_ERROR,
    IErrorInfo,
    REMOVE_IN_APP_NOTIFICATION,
    REMOVE_LAST_IN_APP_NOTIFICATION,
    SET_IS_EMBEDDED_IN_NATIVE_HUB,
    STORE_QUERY_PARMAS,
} from "./types";

const storeQueryParams = (params: Record<string, string>) => ({
    payload: params,
    type: STORE_QUERY_PARMAS,
});

const setApiError = (error: IErrorInfo) => ({
    error,
    type: APP_API_ERROR,
});
const setDbError = (error: IErrorInfo) => ({
    error,
    type: APP_DB_ERROR,
});

const addInAppNotification = (notification: ToastProps) => ({
    type: ADD_IN_APP_NOTIFICATION,
    payload: {
        notification,
    },
});
const removeLastInAppNotification = () => ({
    type: REMOVE_LAST_IN_APP_NOTIFICATION,
});
const removeAppNotification = (id: string) => ({
    type: REMOVE_IN_APP_NOTIFICATION,
    payload: id,
});

const setIsEmbeddedInNativeHub = (isHubNative: boolean) => ({
    type: SET_IS_EMBEDDED_IN_NATIVE_HUB,
    payload: isHubNative,
});

export default {
    setDbError,
    setApiError,
    storeQueryParams,
    addInAppNotification,
    removeLastInAppNotification,
    removeAppNotification,
    setIsEmbeddedInNativeHub,
};
