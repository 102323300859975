import { ButtonText } from "@secuis/ccp-react-components";
import styled from "styled-components";

const DropdownFilterButtonStyled = styled(ButtonText)`
    width: auto;
    margin-left: auto;
`;

type Props = {
    className?: string;
    label: string;
    onClick;
};

export const ListButton = ({ className, label, onClick }: Props) => {
    const onFilterButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
    };

    return (
        <DropdownFilterButtonStyled className={className} onClick={onFilterButtonClick} color={"accent"}>
            {label}
        </DropdownFilterButtonStyled>
    );
};
