import { useLayoutEffect, useRef, useState } from "react";
import { useResizeObserver } from "src/hooks/CommonHooks";

import { SectionProps } from "./Section.types";

export const useSection = ({ onResize, maxHeight, collapsed }: Pick<SectionProps, "onResize" | "maxHeight" | "collapsed">) => {
    const contentWrapperRef = useRef<HTMLDivElement>();
    const [height, setHeight] = useState<string>("");
    const [width, contentHeight] = useResizeObserver(contentWrapperRef.current);

    useLayoutEffect(() => {
        if (onResize) {
            onResize(contentHeight);
        }
    }, [contentHeight, onResize]);

    useLayoutEffect(
        () => {
            if (maxHeight && collapsed) {
                setHeight(maxHeight);
            } else if (maxHeight) {
                setHeight(contentWrapperRef.current ? `${contentWrapperRef.current.getBoundingClientRect()?.height ?? 0}px` : "0px");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [width, contentWrapperRef.current, maxHeight, collapsed],
    );

    return { height, contentWrapperRef };
};
