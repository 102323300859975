import { Breakpoints, Palette, Stack } from "@secuis/ccp-react-components";
import { BarChart } from "recharts";
import styled from "styled-components";

export const BarchartStyled = styled(BarChart)`
    .recharts-cartesian-grid-vertical > line {
        stroke: ${Palette.Navy300};
    }
`;

export const LegendContainerStyled = styled(Stack)`
    white-space: nowrap;

    @media (max-width: ${Breakpoints.XS}) {
        max-width: 20%;
        white-space: break-spaces;
    }
`;
