import { formatToRawDate } from "../../helpers/date";
import { GetByDateRange } from "./InsightsModel";

//NOTE: Remove when fully migrated to reports api v2
export const parseDateRangeRequestBody = (body: GetByDateRange & Record<string, unknown>, useV2Api: boolean = false) => {
    const { fromDateTime, toDateTime, ...payload } = body;

    if (useV2Api) {
        return {
            ...payload,
            fromDate: formatToRawDate(fromDateTime),
            toDate: formatToRawDate(toDateTime),
        };
    } else {
        return {
            ...payload,
            fromDateTime: fromDateTime.toISOString(),
            toDateTime: toDateTime.toISOString(),
        };
    }
};

export const getApiVersion = (withSiteZones = false) => {
    return withSiteZones ? "v2" : "v1";
};
