const createHighlightsTables = `
        CREATE TABLE IF NOT EXISTS critical_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_critical ON critical_categories (category_key);

        CREATE TABLE IF NOT EXISTS prevention_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_prevention ON prevention_categories (category_key);

        CREATE TABLE IF NOT EXISTS access_control_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_access_control ON access_control_categories (category_key);

        CREATE TABLE IF NOT EXISTS access_control_systems_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_access_control_systems ON access_control_systems_categories (category_key);

        CREATE TABLE IF NOT EXISTS medical_assistance_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_medical_assistance ON medical_assistance_categories (category_key);

        CREATE TABLE IF NOT EXISTS confrontation_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_confrontation ON confrontation_categories (category_key);

        CREATE TABLE IF NOT EXISTS equipment_accident_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_equipment_accident ON equipment_accident_categories (category_key);

        CREATE TABLE IF NOT EXISTS reported_audits_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_reported_audits ON reported_audits_categories (category_key);

        CREATE TABLE IF NOT EXISTS damaged_reports_categories (
                category_key TEXT PRIMARY KEY
        ); 
        CREATE INDEX ix_category_key_damaged_reports ON damaged_reports_categories (category_key);
`;

const addMissingIndexes = `
        CREATE INDEX ix_category_key_severity_level_location_id_report_date_time ON reports (category_key, severity_level, location_id, report_date_time);
`;

export const initReportsDbScript = `
        BEGIN TRANSACTION;
        ${addMissingIndexes}
        ${createHighlightsTables}
        END TRANSACTION;
`;
