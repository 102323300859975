import { ButtonText, Separator, Stack } from "@secuis/ccp-react-components";
import { ReactNode } from "react";
import styled from "styled-components";

const AttachmentWrapperStyled = styled(Stack)`
    flex: 1;
    align-self: stretch;
`;

type CollapsableListPropsType = {
    collapsable: boolean;
    collapsed: boolean;
    collapsedLabel: string;
    expandedLabel: string;
    onCollapse: () => void;
    onExpand: () => void;
    attachment?: ReactNode;
    children: ReactNode | ReactNode[];
};

export const CollapsableList = ({
    children,
    collapsable,
    collapsed,
    collapsedLabel,
    expandedLabel,
    onCollapse,
    onExpand,
    attachment,
}: CollapsableListPropsType) => {
    return (
        <>
            {children}
            {collapsable && (
                <Stack direction="column" alignItems="center">
                    <AttachmentWrapperStyled>{attachment}</AttachmentWrapperStyled>
                    <Separator marginTop={"S"} marginBottom={"XS"} />
                    {collapsed ? (
                        <ButtonText data-testid="collapsed-label" onClick={onExpand} icon="ArrowDown" color="primary">
                            {collapsedLabel}
                        </ButtonText>
                    ) : (
                        <ButtonText data-testid="expanded-label" onClick={onCollapse} icon="ArrowUp" color="primary">
                            {expandedLabel}
                        </ButtonText>
                    )}
                </Stack>
            )}
            {!collapsable && attachment}
        </>
    );
};
