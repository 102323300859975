import { Box, Breakpoints, Button, Headline, Separator, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SectionHeadingStyled } from "./InsightsSitePage.styled";

export interface InsightsSitePageViewScheduleProps {
    handleScheduleButtonClick: () => void;
}

export const InsightsSitePageViewSchedule = ({ handleScheduleButtonClick }: InsightsSitePageViewScheduleProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <>
            <Separator />
            <SectionHeadingStyled>
                <Box paddingVertical="XS">
                    <Headline bold>{t("insights.tabs.tasks")}</Headline>
                </Box>
                <Button onClick={handleScheduleButtonClick} mode="contained" fitContent={!isMobile}>
                    {t("schedule.title")}
                </Button>
            </SectionHeadingStyled>
        </>
    );
};
