import styled from "styled-components";

export const CarouselStyled = styled.div.attrs({
    className: "keen-slider",
})`
    .keen-slider__slide {
        overflow: visible !important;

        &:first-child {
            // NOTE: Workaround for cards overlapping first card's tooltip
            z-index: 2;
        }
    }
`;
