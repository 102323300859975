import { Text } from "@secuis/ccp-react-components";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { CustomizedGraphTooltip } from "src/pages/Insights/shared/components/CustomizedGraphTooltip";

// NOTE: For future cases, it would be nice to implement generating label based on 'name'

type Props = Partial<{
    label: string;
    payload: Payload<number, string>[];
    onWidthChange?: (width: number) => void;
    onHeightChange?: (height: number) => void;
    active: boolean;
}>;

export const TooltipContent = (props: Props) => {
    const { active, label, payload } = props;

    if (active && payload?.length) {
        return (
            <CustomizedGraphTooltip
                content={[
                    <Text key="title">{label}</Text>,
                    ...payload.map(({ name, value }) => (
                        <Text key={name}>
                            {name}: {value}
                        </Text>
                    )),
                ]}
                active={active}
            />
        );
    }
};
