import { Box, Breakpoints, ButtonText, Checkbox, Stack } from "@secuis/ccp-react-components";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ChipFilter } from "src/components/shared/ChipFilter/ChipFilter";
import { Modal } from "src/components/shared/Modal/Modal";
import { useShareReportMutation, useShareTourSessionMutation } from "src/store/reports/reportsApi";
import styled from "styled-components";

import emailSentImg from "../../../assets/images/email-sent.svg";
import emailErrorImg from "../../../assets/images/email-sent-error.svg";
import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../../models/ReportModel";
import UserSelectors from "../../../store/user/UserSelectors";
import { Loading } from "../../shared/Loading";
import EmailSentInfo from "./EmailSentInfo";
import { InputStyled } from "./ShareReportModal.styles";

// fix for huge amount of chips, before update ccp
const StyledStack = styled(Stack)`
    overflow-y: auto;
    max-height: 340px;
    @media (max-width: ${Breakpoints.L}) {
        max-height: 280px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        max-height: 260px;
    }
`;

type Props = {
    report: DynamicReportType | PatrolTourType;
    onClose: () => void;
    isOpen: boolean;
};

const validateEmailFormat = (email: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase(),
    );
};

const validateEmailDuplication = (value: string, items: string[]) => !!items.find((email) => email === value);

const ShareReportModal = ({ report, onClose, isOpen }: Props) => {
    const { t, i18n } = useTranslation();
    const [shareReport, { isSuccess: isReportSuccess, isError: isReportError, isLoading: isReportLoading }] = useShareReportMutation();
    const [shareTourSession, { isSuccess: isTourSessionSuccess, isError: isTourSessionError, isLoading: isTourSessionLoading }] = useShareTourSessionMutation();
    const [emails, setEmails] = useState<string[]>([]);
    const [error, setError] = useState("");
    const [sendToSharer, setSendToSharer] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const isSuccess = useMemo(() => isReportSuccess || isTourSessionSuccess, [isReportSuccess, isTourSessionSuccess]);
    const isError = useMemo(() => isReportError || isTourSessionError, [isReportError, isTourSessionError]);
    const isLoading = useMemo(() => isReportLoading || isTourSessionLoading, [isReportLoading, isTourSessionLoading]);

    const {
        info: { id: userId, emailPrimary: currentUserEmail },
    } = useSelector(UserSelectors.getUserInfo);

    const validateEmail = useCallback(
        (email: string) => {
            const isValid = validateEmailFormat(email);
            if (!isValid) {
                setError(t("reports.share.modalContent.isInvalidEmailFormat"));
                return false;
            }
            const isDuplicated = validateEmailDuplication(email, emails);
            if (isDuplicated) {
                setError(t("reports.share.modalContent.isDuplicated"));
                return false;
            }
            return true;
        },
        [emails, t],
    );

    const handleSubmit = useCallback(
        (emails: string[]) => {
            const emailsToSend = [...emails];
            if (inputValue || (!emails.length && !sendToSharer)) {
                if (!validateEmail(inputValue)) {
                    return;
                }
                emailsToSend.push(inputValue);
                setInputValue("");
            }
            if (sendToSharer && !emailsToSend.find((e) => e === currentUserEmail)) {
                emailsToSend.push(currentUserEmail);
            }

            if (!emailsToSend?.length) {
                return;
            }
            setEmails(emailsToSend);
            if (isPatrolTour(report)) {
                shareTourSession({
                    tourSessionId: report.id,
                    locationId: report.locationId,
                    senderId: userId,
                    recipientEmails: emailsToSend,
                    languageCode: i18n.language,
                });
                return;
            }
            shareReport({ reportId: report.id, locationId: report.locationId, senderId: userId, recipientEmails: emailsToSend, languageCode: i18n.language });
        },
        [inputValue, i18n.language, validateEmail, sendToSharer, report, userId, currentUserEmail],
    );

    const { primaryButtonLabel, info } = useMemo(() => {
        if (isSuccess) {
            return {
                primaryButtonLabel: t("common.done"),
                info: t(`reports.share.modalContent.success.message`),
            };
        }

        if (isError) {
            return {
                primaryButtonLabel: t("common.tryAgain"),
                info: t(`reports.share.modalContent.failure.message`),
            };
        }
        return {
            primaryButtonLabel: t("common.share"),
        };
    }, [isSuccess, isError, t]);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setInputValue(event.target.value);
        setError(null);
    }, []);

    const handleEnter = useCallback(
        (e) => {
            if (e.key === "Enter") {
                const value = e.target.value;
                if (!validateEmail(value)) {
                    return;
                }
                setEmails([...emails, value]);
                setInputValue("");
            }
        },
        [validateEmail, emails],
    );

    const clearEmails = () => {
        setEmails([]);
    };

    const removeEmail = useCallback(
        (index: number) => {
            const newEmails = emails.filter((_, i) => i !== index);
            setEmails(newEmails);
        },
        [emails],
    );

    const onModalButtonClick = useCallback(() => {
        if (isSuccess) {
            onClose();
            return;
        }
        handleSubmit(emails);
    }, [emails, isSuccess, handleSubmit, onClose]);

    const getModalContent = useCallback(() => {
        if (isLoading) {
            return <Loading />;
        }

        if (isSuccess) {
            return <EmailSentInfo emails={emails} message={info} imgSrc={emailSentImg} />;
        }

        if (isError) {
            return <EmailSentInfo message={info} imgSrc={emailErrorImg} />;
        }
        return (
            <Box data-testid="box-share-modal" marginTop="S">
                <InputStyled
                    data-testid="input-share-modal"
                    onKeyPress={handleEnter}
                    onChange={handleOnChange}
                    value={inputValue}
                    placeholder={t("reports.share.modalContent.default.emailsInputPlaceholder")}
                    error={error}
                    invalid={!!error}
                    label={t("common.email")}
                    aria-label={t("common.email")}
                />
                <StyledStack marginTop="XS" marginBottom="XS" gap="XS" flexWrap="wrap" alignItems="center">
                    {
                        <>
                            {emails.map((email, index) => (
                                <ChipFilter
                                    data-testid="email-chip"
                                    key={index}
                                    label={email}
                                    color={"neutral"}
                                    onRemove={() => {
                                        removeEmail(index);
                                    }}
                                />
                            ))}

                            {emails.length ? (
                                <ButtonText data-testid="clear-all-emails" color="accent" onClick={clearEmails}>
                                    {t("filters.clearAll")}
                                </ButtonText>
                            ) : null}
                        </>
                    }
                </StyledStack>
                <Stack justifyContent="center" marginTop="S">
                    <Checkbox
                        id="share-report-modal-checkbox"
                        data-testid="share-report-modal-checkbox"
                        onChange={(e) => setSendToSharer(e.target.checked)}
                        checked={sendToSharer}
                        label={t("reports.share.modalContent.default.sendCopyCheckboxLabel")}
                    />
                </Stack>
            </Box>
        );
    }, [error, inputValue, isSuccess, isError, isLoading, emails, t, handleEnter, handleOnChange, removeEmail, sendToSharer, info]);

    return (
        <Modal
            title={t("reports.share.modalContent.default.title")}
            size="S"
            isOpen={isOpen}
            actions={[
                {
                    onClick: onModalButtonClick,
                    children: primaryButtonLabel,
                },
            ]}
            onClose={onClose}
        >
            {getModalContent()}
        </Modal>
    );
};

export default ShareReportModal;
