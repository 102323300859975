import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AccessSelectors from "../store/access/AccessSelectors";
import { useMemoizedDependency } from "./useMemoizedDependency";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

async function fetchAsync(url: string, accessToken: string, requestInit: RequestInit): Promise<Response> {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    url = url[0].startsWith("/") ? `${baseUrl}${url}` : url;

    return await fetch(url, {
        ...requestInit,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            ...requestInit.headers,
        },
    });
}

export const useFetchApi = () => {
    const { accessToken } = useSelector(AccessSelectors.getAccessToken);

    return useCallback(
        async <P>(url: string, method: RequestMethod, params: P, customRequestParams?: Partial<RequestInit>) => {
            return await fetchAsync(url, accessToken, {
                ...customRequestParams,
                method,
                body: params !== undefined ? JSON.stringify(params) : undefined,
            });
        },
        [accessToken],
    );
};

export const useApiRequest = <T, P = unknown>(url: string, method: RequestMethod, params: P, customRequestParams?: Partial<RequestInit>) => {
    const [responseData, setResponseData] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const fetch = useFetchApi();

    const memoizedParams = useMemoizedDependency(params);
    const memoizedCustomRequest = useMemoizedDependency(customRequestParams);

    useEffect(() => {
        setIsLoading(true);
        const sendRequest = async () => {
            try {
                const response = await fetch(url, method, memoizedParams, memoizedCustomRequest);

                setResponseData((await response.json()) as T);
            } catch (e) {
                throw new Error(`API request to ${url} failed`, e);
            } finally {
                setIsLoading(false);
            }
        };

        sendRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedParams, method, url, memoizedCustomRequest]);

    return { data: responseData, isLoading };
};
