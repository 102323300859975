import { Box, EmptyState, LoadingIndicator, RadioButton, Stack, Subtitle, Text, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { Loading } from "../../components/shared/Loading";
import { useClientSwitcherPage } from "./ClientSwitcherPage.hooks";
import { ClientRowStyled, FieldsetStyled, GridWrapper, SearchStyled } from "./ClientSwitcherPage.styles";
import { Footer } from "./Footer";

const ClientSwitcherPage = () => {
    const { t } = useTranslation();
    const {
        selectClient,
        resetClient,
        clearSearchBox,
        handleOnSearchChange,
        setSelectedClient,
        counterText,
        selectedClient,
        availableClients,
        inputValue,
        initialClientCount,
        listRef,
        canDisplayList,
        isLoadingMore,
        isInputTooShort,
        isMobile,
        isRequestLoading,
        isRequestSuccess,
    } = useClientSwitcherPage();

    return (
        <>
            <GridWrapper onSubmit={(e) => e.preventDefault()}>
                <Stack direction="column" ph="S">
                    <Box mv={isMobile ? "S" : "XL"}>
                        <Title>{t("switcher.header.title")}</Title>
                        <Subtitle>
                            {initialClientCount > 10 ? t("switcher.header.subtitle.searchSubtitle") : t("switcher.header.subtitle.chooseClient")}
                        </Subtitle>
                    </Box>
                    {initialClientCount > 10 && (
                        <Box mb="M" ph="S">
                            <SearchStyled
                                value={inputValue}
                                onChange={handleOnSearchChange}
                                onClear={clearSearchBox}
                                placeholder={t("common.search")}
                                loading={isRequestLoading}
                                aria-label={t("common.search")}
                            />
                        </Box>
                    )}
                </Stack>
                <Box pv="XS" ph="S">
                    <Text micro uppercase color="secondary">
                        {counterText}
                    </Text>
                </Box>
                <FieldsetStyled>
                    <Stack ref={listRef} flex={1} direction="column" gap="XS" ph="S" pb={isMobile ? "S" : "0"}>
                        {isRequestLoading && !isLoadingMore && !isInputTooShort ? (
                            <Loading size="M" />
                        ) : canDisplayList ? (
                            <>
                                {availableClients.map((client, index) => (
                                    <ClientRowStyled
                                        data-testid="client-row"
                                        key={index}
                                        onClick={() => setSelectedClient(client)}
                                        selected={client.id === selectedClient?.id}
                                    >
                                        <Text bold>{client.name}</Text>
                                        <RadioButton
                                            aria-label={client.name}
                                            id={client.id}
                                            onChange={() => setSelectedClient(client)}
                                            checked={client.id === selectedClient?.id}
                                        />
                                    </ClientRowStyled>
                                ))}
                                {isRequestLoading && isLoadingMore && <LoadingIndicator size="M" />}
                            </>
                        ) : (
                            <>
                                {!isInputTooShort && isRequestSuccess && (
                                    <EmptyState icon={"Search"} title={t("switcher.noResultsFound.title")} subtitle={t("switcher.noResultsFound.subtitle")} />
                                )}
                                {isInputTooShort && (
                                    <EmptyState icon={"Locked"} title={t("switcher.dataProtection.title")} subtitle={t("switcher.dataProtection.subtitle")} />
                                )}
                            </>
                        )}
                    </Stack>
                </FieldsetStyled>
            </GridWrapper>
            {selectedClient && <Footer selectedClient={selectedClient} onCancel={resetClient} onConfirm={selectClient} />}
        </>
    );
};

export default ClientSwitcherPage;
