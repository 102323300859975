import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { SafetyInspectionFields } from "../../../models/reportTemplates/SafetyInspection";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getSafetyInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    conditionalRender(getFieldValueByFieldName(SafetyInspectionFields.description, report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.safetyInspection.description",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(SafetyInspectionFields.description, report),
                        sharedReportId,
                        imagesData: report.images,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.safetyInspection.summary.title",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.safetyInspection.summary.typeOfInspection",
                    fieldValue: getFieldValueByFieldName(SafetyInspectionFields.typeOfInspection, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.safetyInspection.summary.wasEverythingInOrder",
                    fieldValue: getFieldValueByFieldName(SafetyInspectionFields.wasEverythingInOrder, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(SafetyInspectionFields)),
        ],
    },
];
