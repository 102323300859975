import { Stack } from "@secuis/ccp-react-components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IUser } from "../../../models/UserModel";
import { ICommentModel, IUpsertCommentDto } from "../../../store/reportComments/models";
import { RequestStatus } from "../../../store/RequestStatus";
import { useUsers } from "../../../store/users/UsersHooks";
import { CollapsableList } from "../../shared/CollapsableList/CollapsableList";
import CommentListItem from "./CommentListItem";

type CommentListPropsType = {
    activeEditorId: string;
    comments: ICommentModel[];
    usersToMention: IUser[];
    onDelete: (comment: ICommentModel) => void;
    onEdit: (body: IUpsertCommentDto) => void;
    onEditStart: (commentId: string) => void;
    onEditCancel: (commentId: string) => void;
};

const COMMENTS_COLLAPSE_COUNT = 3;

const CommentList = ({ activeEditorId, comments, onEdit, onEditStart, onEditCancel, onDelete, usersToMention }: CommentListPropsType) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const commentsToShow = showAll ? comments : comments?.slice(0, COMMENTS_COLLAPSE_COUNT);
    const hasManyItemsToDisplay = comments?.length > COMMENTS_COLLAPSE_COUNT;
    const creatorIds = comments.map((c) => c.creatorId);
    const mentionedIds = comments.map((c) => c.mentionedUserIds);
    const idsToFetch = creatorIds.concat(mentionedIds.flat());
    const { users, fetchStatus } = useUsers(idsToFetch);

    const expand = useCallback(() => {
        setShowAll(true);
    }, []);
    const collapse = useCallback(() => {
        setShowAll(false);
    }, []);

    const onCommentEditStart = useCallback(
        (id: string) => {
            onEditStart(id);
        },
        [onEditStart],
    );

    if (!commentsToShow?.length) {
        return null;
    }

    return (
        <Stack flex="1" mb="M" direction="column">
            <CollapsableList
                collapsable={hasManyItemsToDisplay}
                collapsed={!showAll}
                collapsedLabel={t("report.comments.commentListShowAll")}
                expandedLabel={t("report.comments.commentListShowLess")}
                onCollapse={collapse}
                onExpand={expand}
            >
                {fetchStatus === RequestStatus.success &&
                    commentsToShow?.map((c) => (
                        <CommentListItem
                            key={c.id}
                            active={c.id === activeEditorId}
                            comment={c}
                            onDelete={() => onDelete(c)}
                            onEdit={onEdit}
                            onEditCancel={() => onEditCancel(c.id)}
                            onEditStart={() => onCommentEditStart(c.id)}
                            usersToMention={usersToMention}
                            allUsers={users}
                        />
                    ))}
            </CollapsableList>
        </Stack>
    );
};

export default CommentList;
