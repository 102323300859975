import { HttpResponse } from "msw";

import { db } from "../../db";

export const getCommentsResolver = (reportId: string) => {
    const comments = db.comments.findMany({
        where: {
            referredEntityId: {
                equals: reportId,
            },
            isDeleted: {
                equals: false,
            },
        },
    });

    return HttpResponse.json(comments);
};

export default getCommentsResolver;
