import { RSAA } from "redux-api-middleware";

import { getApiVersion, parseDateRangeRequestBody } from "./helpers";
import { GetByDateRangeAndSiteIds } from "./InsightsModel";
import {
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE,
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST,
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS,
} from "./types";

const getTourSessionsSummary = (body: GetByDateRangeAndSiteIds, queryKey: string, withSiteZones = false) => {
    const meta = { queryKey };

    return {
        [RSAA]: {
            auth: true,
            endpoint: `/${getApiVersion(withSiteZones)}/global/insights/tour-sessions/filter`,
            method: "POST",
            body: parseDateRangeRequestBody(body, withSiteZones),
            types: [
                { type: INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST },
                { type: INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS, meta },
                { type: INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE },
            ],
        },
    };
};

const getHistoricalTourSessionsSummary = (body: GetByDateRangeAndSiteIds, withSiteZones = false) => {
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/${getApiVersion(withSiteZones)}/global/insights/tour-sessions/filter`,
            method: "POST",
            body: parseDateRangeRequestBody(body, withSiteZones),
            types: [
                { type: INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST },
                { type: INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS },
                { type: INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE },
            ],
        },
    };
};

export default {
    getTourSessionsSummary,
    getHistoricalTourSessionsSummary,
};
