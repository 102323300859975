import { Icon, IconType, Stack, Text } from "@secuis/ccp-react-components";
import { useMemo } from "react";

import { checkIfUnknown } from "../../../../../helpers/ReportHelper";
import { ReportsListItemType, ReportTemplateEnum } from "../../../../../models/ReportModel";

type PropsType = {
    report: ReportsListItemType;
};

export const ListItemCounters = ({ report }: PropsType) => {
    const { id, imageCount, commentsCount, type } = report;

    const renderData = useMemo(() => {
        const isUnknown = checkIfUnknown(type);
        const counters = [
            {
                count: Number(commentsCount),
                translationKey: "report.commentsCount",
                icon: "Message",
            },
        ];
        if (
            report.type !== ReportTemplateEnum.tourException &&
            report.type !== ReportTemplateEnum.tourMultiException &&
            report.type !== ReportTemplateEnum.patrolTour
        ) {
            counters.unshift({
                count: Number(!isUnknown ? imageCount : 0),
                translationKey: "report.imagesCount",
                icon: "Image",
            });
        }
        return counters.filter(({ count }) => count);
    }, [imageCount, commentsCount, report, type]);

    if (!renderData.length) {
        return null;
    }

    return (
        <Stack gap="XS" alignItems="center">
            {renderData.map(({ count, translationKey, icon }) => {
                const key = `${id}-${translationKey}`;
                return (
                    <Stack alignItems="center" gap="XXS" key={key}>
                        <Icon variant={icon as IconType} size="S" color="tertiary" />
                        <Text color="neutral" small>
                            {count}
                        </Text>
                    </Stack>
                );
            })}
        </Stack>
    );
};
