import { PersistedState } from "redux-persist";

import { RequestStatus } from "../RequestStatus";
import { DamageReportType, IHighlightsCard, IncidentsLocationsType, SummaryViewMode } from "./InsightsModel";
import { TourSessions } from "./InsightsPatrolTasksModel";
import { PatrolDeviationData } from "./PatrolDeviations/PatrolDeviationsTypes";
import { TourHistoricalCompareSummary } from "./Tours/ToursTypes";

export const INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER = "@@insights/INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER";
export const INSIGHTS_FILTER_SELECT_DATE_RANGE = "@@insights/INSIGHTS_FILTER_SELECT_DATE_RANGE";
export const INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS = "@@insights/INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS";

export const INSIGHTS_FILTER_SELECT_GROUPS_FILTER = "@@insights/INSIGHTS_FILTER_SELECT_GROUPS_FILTER";

export const INSIGHTS_TRENDING_EVENTS_REQUEST = "@@insights/TRENDING_EVENTS_REQUEST";
export const INSIGHTS_TRENDING_EVENTS_SUCCESS = "@@insights/TRENDING_EVENTS_SUCCESS";
export const INSIGHTS_TRENDING_EVENTS_FAILURE = "@@insights/TRENDING_EVENTS_FAILURE";

export const INSIGHTS_DAMAGE_REPORTS_REQUEST = "@@insights/INSIGHTS_DAMAGE_REPORTS_REQUEST";
export const INSIGHTS_DAMAGE_REPORTS_SUCCESS = "@@insights/INSIGHTS_DAMAGE_REPORTS_SUCCESS";

export const INCIDENTS_LOCATIONS_REQUEST = "@@insights/INCIDENTS_LOCATIONS_REQUEST";
export const INCIDENTS_LOCATIONS_SUCCESS = "@@insights/INCIDENTS_LOCATIONS_SUCCESS";

export const INSIGHTS_DB_REQUEST = "@@insights/INSIGHTS_DB_REQUEST";
export const INSIGHTS_DB_SUCCESS = "@@insights/INSIGHTS_DB_SUCCESS";
export const INSIGHTS_DB_FAILURE = "@@insights/INSIGHTS_DB_FAILURE";
export const INSIGHTS_DBS_RESET = "@@insights/INSIGHTS_DBS_RESET";

export const INSIGHTS_CRITICAL_CARDS_REQUEST = "@@insights/INSIGHTS_CRITICAL_CARDS_REQUEST";
export const INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE = "@@insights/INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE";
export const INSIGHTS_CRITICAL_CARDS_SUCCESS = "@@insights/INSIGHTS_CRITICAL_CARDS_SUCCESS";
export const INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS = "@@insights/INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS";
export const INSIGHTS_CRITICAL_CARDS_RESET = "@@insights/INSIGHTS_CRITICAL_CARDS_RESET";

export const INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST = "@@insights/INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST";
export const INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS = "@@insights/INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS";
export const INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE = "@@insights/INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE";

export const INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST = "@@insights/INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST";
export const INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS = "@@insights/INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS";
export const INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE = "@@insights/INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE";

export const INSIGHTS_PATROL_DEVIATIONS_REQUEST = "@@insights/INSIGHTS_PATROL_DEVIATIONS_REQUEST";
export const INSIGHTS_PATROL_DEVIATIONS_SUCCESS = "@@insights/INSIGHTS_PATROL_DEVIATIONS_SUCCESS";

export const INSIGHTS_PATROL_DEVIATIONS_LEVEL2_REQUEST = "@@insights/INSIGHTS_PATROL_DEVIATIONS_LEVEL2_REQUEST";
export const INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS = "@@insights/INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS";

export const INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_REQUEST = "@@insights/INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_REQUEST";
export const INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_SUCCESS = "@@insights/INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_SUCCESS";
export const INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_FAILURE = "@@insights/INSIGHTS_PATROL_DEVIATIONS_COMPARE_PERIOD_FAILURE";

export const INSIGHTS_LOCATION_TOURS_REQUEST = "@@insights/INSIGHTS_LOCATION_TOURS_REQUEST";
export const INSIGHTS_LOCATION_TOURS_SUCCESS = "@@insights/INSIGHTS_LOCATION_TOURS_SUCCESS";
export const INSIGHTS_LOCATION_TOURS_FAILURE = "@@insights/INSIGHTS_LOCATION_TOURS_FAILURE";
export const INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_REQUEST = "@@insights/INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_REQUEST";
export const INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_SUCCESS = "@@insights/INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_SUCCESS";
export const INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_FAILURE = "@@insights/INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_FAILURE";

export const INSIGHTS_FILTER_CLEAR_ALL = "@@insights/INSIGHTS_FILTER_CLEAR_ALL";
export const INSIGHTS_SET_SELECTED_TAB = "@@insights/SET_SELECTED_TAB";
export const INSIGHTS_SET_SUMMARY_VIEW_MODE = "@@insights/SET_SUMMARY_VIEW_MODE";
export const INSIGHTS_MARK_CLIENT_VERIFIED = "@@insights/MARK_CLIENT_VERIFIED";

export const INSIGHTS_SET_INCIDENT_MATRIX_CATEGORY = "@@insights/SET_INCIDENT_MATRIX_CATEGORY";
export const INSIGHTS_SET_INCIDENT_MATRIX_LEVEL = "@@insights/SET_INCIDENT_MATRIX_LEVEL";

export interface IFilterState {
    selectedRegions: string[];
    selectedGroups: string[];
    selectedStartDate: Date;
    selectedEndDate: Date;
}

export interface IDamageReportsState {
    damageReports: DamageReportType;
    queryStatus: RequestStatus;
    error: string;
    key: string;
}

export interface IIncidentsLocationsState {
    incidentsLocations: IncidentsLocationsType[];
    queryStatus: RequestStatus;
    error: string;
}

export interface IPerformedPatrolsState {
    queryStatus: RequestStatus;
}

export interface ITourSessionsSummaryState {
    queryStatus?: RequestStatus;
    tourSessionsSummary: TourSessions[];
    queryKey?: string;
}

export interface IDbState {
    loadStatus: RequestStatus;
    error: string;
}

export interface ICriticalCardsState {
    cards: IHighlightsCard[];
    queryStatus: RequestStatus;
    page: number;
    queryKey: string;
}

export interface IPatrolDeviationsState {
    patrolDeviations: PatrolDeviationData[];
    queryStatus: RequestStatus;
    queryKey: string;
    error: string;
}

export interface ILocationToursState {
    tours: TourHistoricalCompareSummary[];
    queryStatus: RequestStatus;
    queryKey: string;
    error: string;
}

export interface ISummaryState {
    viewMode: SummaryViewMode;
    isClientVerified: boolean;
    hasData: boolean;
}

export interface IIncidentMatrixState {
    category: string;
    level: number;
}

export interface IInsightsState extends PersistedState {
    filterState: IFilterState;
    damageReportsState: IDamageReportsState;
    incidentsLocationsState: IIncidentsLocationsState;
    performedPatrolsState: IPerformedPatrolsState;
    databasesState: Record<string, IDbState>;
    criticalCardsState: ICriticalCardsState;
    tourSessionsSummary: ITourSessionsSummaryState;
    historicalTourSessionsSummary: ITourSessionsSummaryState;
    patrolDeviationsState: IPatrolDeviationsState;
    selectedTab: number;
    locationToursState: ILocationToursState;
    summaryState: ISummaryState;
    incidentMatrixState: IIncidentMatrixState;
}
