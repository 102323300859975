import { DynamicReportType } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { IUser } from "../../../../models/UserModel";
import { base64Assets } from "../../../PdfReports/Base64Assets";
import { PdfReportDetailsHeader } from "../../../PdfReports/shared/PdfReportDetailsHeader";
import { PdfLogoHeader } from "../../../PdfReports/shared/PdfTemplateHeadline";

export const getBaseReportConfigPdf = (report: DynamicReportType, siteObject: ISiteObject, sharedReportId?: string, user?: IUser) => [
    {
        component: PdfLogoHeader,
        props: {
            logo: `data:image/png;base64,${base64Assets.securitasLogo}`,
        },
    },
    {
        component: PdfReportDetailsHeader,
        props: { report, siteObject, isShared: !!sharedReportId, user },
    },
];
