import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { GroupsListItemStyled } from "./SitesGroupsListItem.styles";

type Props = {
    title: string;
    sitesCount: number;
    onClick: () => void;
};

export const SitesGroupsListItem = ({ title, sitesCount, onClick }: Props) => {
    const { t } = useTranslation();
    const isDisabled = sitesCount <= 0;

    const handleClick = () => {
        if (isDisabled) {
            return;
        }
        onClick();
    };

    const handleKeyDown = useOnEnterSpaceKeyDown(handleClick);

    return (
        <GroupsListItemStyled
            isDisabled={isDisabled}
            data-testid="sites-list-item"
            tabIndex={isDisabled ? -1 : 0}
            role="button"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            <Stack justifyContent="space-between" alignItems="center" gap="S">
                <Stack direction="column" gap="XXS">
                    <Text bold>{title}</Text>
                    <Text data-testid="sites-number-on-list-item" color="secondary" small bold>{`${sitesCount} ${
                        sitesCount === 1 ? t("sites.site") : t("sites.sites")
                    }`}</Text>
                </Stack>
                <Stack>{!isDisabled && <Icon variant="ArrowForward" size="L" />}</Stack>
            </Stack>
        </GroupsListItemStyled>
    );
};
