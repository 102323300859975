import { type SVGProps } from "react";

export const CarInpsectionIcon = ({ width, height, ...rest }: SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.66675 10.3328H5.66675C5.39041 10.3328 5.16675 10.1091 5.16675 9.83276C5.16675 9.55643 5.39041 9.33276 5.66675 9.33276H7.66675C7.94308 9.33276 8.16675 9.55643 8.16675 9.83276C8.16675 10.1091 7.94308 10.3328 7.66675 10.3328Z"
            fill="#ACC2DD"
        />
        <path
            d="M3.66675 10.3328H3.16675C2.89041 10.3328 2.66675 10.1091 2.66675 9.83276C2.66675 9.55643 2.89041 9.33276 3.16675 9.33276H3.66675C3.94308 9.33276 4.16675 9.55643 4.16675 9.83276C4.16675 10.1091 3.94308 10.3328 3.66675 10.3328Z"
            fill="#ACC2DD"
        />
        <path
            d="M10.1667 10.3328H9.66675C9.39041 10.3328 9.16675 10.1091 9.16675 9.83276C9.16675 9.55643 9.39041 9.33276 9.66675 9.33276H10.1667C10.4431 9.33276 10.6667 9.55643 10.6667 9.83276C10.6667 10.1091 10.4431 10.3328 10.1667 10.3328Z"
            fill="#ACC2DD"
        />
        <path
            d="M12.6237 9.1296L12.0587 7.8596C11.9464 7.6076 11.6511 7.4931 11.3986 7.60593C11.1461 7.71793 11.0329 8.0136 11.1447 8.26576L11.6667 9.43876V11.3328H1.66675V9.43876L2.15808 8.33276H8.23641C8.51275 8.33276 8.73641 8.10893 8.73641 7.83276C8.73641 7.5566 8.51275 7.33276 8.23641 7.33276H2.60258L3.35875 5.6311C3.43925 5.44993 3.61908 5.33276 3.81675 5.33276H6.19658C6.47292 5.33276 6.69658 5.10893 6.69658 4.83276C6.69658 4.5566 6.47292 4.33276 6.19658 4.33276H3.81658C3.22425 4.33276 2.68575 4.68293 2.44458 5.22493L1.95241 6.33276H1.16675C0.890415 6.33276 0.666748 6.5566 0.666748 6.83276C0.666748 7.10893 0.890415 7.33276 1.16675 7.33276H1.50808L0.709748 9.1296C0.681415 9.1936 0.666748 9.26293 0.666748 9.33276V13.3328C0.666748 13.8841 1.11541 14.3328 1.66675 14.3328H3.16675C3.71808 14.3328 4.16675 13.8841 4.16675 13.3328V12.3328H9.16675V13.3328C9.16675 13.8841 9.61541 14.3328 10.1667 14.3328H11.6667C12.2181 14.3328 12.6667 13.8841 12.6667 13.3328V9.33276C12.6667 9.26293 12.6521 9.1936 12.6237 9.1296ZM3.16775 13.3328H1.66675V12.3328H3.16725L3.16775 13.3328ZM10.1667 13.3328V12.3328H11.6672L11.6677 13.3328H10.1667Z"
            fill="#ACC2DD"
        />
        <path
            d="M10.1667 0.666748C8.23675 0.666748 6.66675 2.23675 6.66675 4.16675C6.66675 6.09675 8.23675 7.66675 10.1667 7.66675C12.0967 7.66675 13.6667 6.09675 13.6667 4.16675C13.6667 2.23675 12.0967 0.666748 10.1667 0.666748ZM10.1667 6.66675C8.78675 6.66675 7.66675 5.54675 7.66675 4.16675C7.66675 2.78675 8.78675 1.66675 10.1667 1.66675C11.5467 1.66675 12.6667 2.78675 12.6667 4.16675C12.6667 5.54675 11.5467 6.66675 10.1667 6.66675Z"
            fill="#ACC2DD"
        />
        <path
            d="M14.0219 7.31178C13.8269 7.11678 13.5119 7.11678 13.3169 7.31178C13.1219 7.50678 13.1219 7.82178 13.3169 8.01678L14.8019 9.51178C14.9019 9.61178 15.0269 9.65678 15.1569 9.65678C15.2819 9.65678 15.4119 9.60678 15.5119 9.51178C15.7069 9.31678 15.7069 9.00178 15.5119 8.80678L14.0219 7.31178Z"
            fill="#ACC2DD"
        />
    </svg>
);
