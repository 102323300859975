import { Text } from "@secuis/ccp-react-components";
import { GraphDataItem } from "src/helpers/graphs";

import { DdStyled, DlStyled, DtStyled, StackStyled, TextStyled } from "./GraphLegend.styles";

type Props = {
    title: string;
    data: Pick<GraphDataItem, "name" | "label">[];
};

export const GraphLegend = ({ title, data }: Props) => {
    if (!data.length) {
        return null;
    }

    return (
        <StackStyled direction="column" gap="S">
            <Text small bold data-testid="graph-legend-title">
                {title}
            </Text>
            <StackStyled direction="row" justifyContent="flex-start" flexWrap="wrap" gap="S">
                {data.map((item) => (
                    <DlStyled key={item.name} data-testid="graph-legend-item">
                        <DtStyled data-testid="graph-legend-item-term">
                            <TextStyled>{item.name}</TextStyled>
                        </DtStyled>
                        <DdStyled data-testid="graph-legend-item-details">
                            <TextStyled>{item.label}</TextStyled>
                        </DdStyled>
                    </DlStyled>
                ))}
            </StackStyled>
        </StackStyled>
    );
};
