import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapUsers = ({ users: { byIds } }: IState) => byIds;
const getUsers = createSelector([mapUsers], (byIds) => byIds);

const selectors = {
    getUsers,
};
export default selectors;
