import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { WorkerExecResult } from "../../models/DbModel";
import AppActions from "../../store/app/AppActions";
import { RequestStatus } from "../../store/RequestStatus";

type WorkerMessage = {
    id: string;
    action: string;
    params?: unknown;
    sql?: string;
    buffer?: ArrayBuffer;
};
export type SqlWorkerType = {
    execute: (sql: string, params?: unknown, messageId?: string | number) => Promise<WorkerExecResult>;
    exportDb: (messageId?: string | number) => Promise<unknown>;
    open: (dbFile: ArrayBuffer) => Promise<unknown>;
    terminate: () => void;
};

export function useSqlWorker(): SqlWorkerType {
    const sqlJsWorker = useMemo(() => getWorkerInstance(), []);

    const dispatch = useDispatch();
    const [openStatus, setOpenStatus] = useState(RequestStatus.undefined);
    const execQueue = useRef({});

    const getHandlerPromise = useCallback(
        <T>(msg: WorkerMessage): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                execQueue.current[msg.id] = { resolve, reject };
                sqlJsWorker.postMessage(msg);
            });
        },
        [sqlJsWorker],
    );

    useEffect(() => {
        return () => {
            sqlJsWorker.terminate();
        };
    }, [sqlJsWorker]);

    const setError = (message: string) => {
        dispatch(AppActions.setDbError({ message, name: "DB Error", status: null, redirect: "", silent: false }));
    };

    const open = useCallback(
        (dbFile: ArrayBuffer) => {
            if (openStatus !== RequestStatus.undefined) {
                return;
            }
            if (!dbFile) {
                setOpenStatus(() => RequestStatus.error);
                return;
            }
            setOpenStatus(() => RequestStatus.loading);
            return getHandlerPromise<WorkerExecResult>({ id: "open", action: "open", buffer: dbFile });
        },
        [getHandlerPromise, openStatus],
    );

    const execute = useCallback(
        (sql: string, params = {}, messageId = uuidv4()) => {
            if (openStatus === RequestStatus.error) {
                return Promise.resolve(null);
            }
            return getHandlerPromise<WorkerExecResult>({ id: messageId, action: "exec", sql, params });
        },
        [getHandlerPromise, openStatus],
    );

    const exportDb = useCallback(
        (messageId = uuidv4()) => {
            return getHandlerPromise({ id: messageId, action: "export" });
        },
        [getHandlerPromise],
    );

    const terminate = useCallback(() => {
        sqlJsWorker.terminate();
    }, [sqlJsWorker]);

    const rejectFromQueue = (id: string) => {
        if (execQueue.current?.[id]?.reject) {
            execQueue.current[id].resolve({ results: [] });
        }
    };

    useEffect(() => {
        sqlJsWorker.onmessage = (event) => {
            const messageId: string = event.data.id;
            if (event.data.error) {
                setError(event.data.error);
                rejectFromQueue(messageId);
                return;
            }
            try {
                if (execQueue.current?.[messageId]?.resolve) {
                    execQueue.current[messageId].resolve(event.data);
                }
            } catch (err) {
                setError(err);
                rejectFromQueue(messageId);
            } finally {
                delete execQueue[messageId];
            }
        };

        sqlJsWorker.onerror = (e: ErrorEvent) => {
            setError(e?.error);
        };
    }, [sqlJsWorker]);

    return { open, execute, exportDb, terminate };
}

function getWorkerInstance() {
    return new Worker("/lib/sqljs/worker.sql-wasm.js");
}
