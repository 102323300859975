import { Column } from "./Table";

export type TableCellProps<T, K> = {
    cell: T;
    row: K;
    index: number;
    isExpanded: boolean;
    column: Column;
    onClick: () => void;
};

export enum TableRowVariant {
    default,
    child,
    active,
    inactive,
}
