import { Breakpoints, Icon, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn, flexColumnJustifyCenterAlignCenter } from "../../../styles/templates/Flex";

export const LoginContainer = styled.div`
    ${flexColumn}
    flex: 1;
    padding-right: ${Spacing.XL}px;
    padding-left: ${Spacing.XL}px;

    @media (max-width: ${Breakpoints.M}) {
        flex: 0;
        padding: 0;
        padding-bottom: ${Spacing.L}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding-bottom: ${Spacing.S}px;
    }
`;

export const LoginWrapper = styled.div`
    ${flexColumnJustifyCenterAlignCenter}
    background-color: ${Palette.Navy650};
    padding-top: ${Spacing.L + Spacing.XXS}px;
    padding-bottom: ${Spacing.L + Spacing.XXS}px;
    border-radius: ${Spacing.M}px;
`;

export const LoginDetailsUnderline = styled.a`
    text-decoration: underline;
    cursor: pointer;
`;

export const NativeLockedIcon = styled(Icon)`
    margin-top: ${Spacing.XS + Spacing.XXS}px;
`;
