import { Breakpoints, Modal, Spacing, Text, Title } from "@secuis/ccp-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { IntercomContext } from "../../components/Intercom";
import { IntercomTopic } from "../../models/IntercomModel";
import { flexColumn } from "../../styles/templates/Flex";
import { WrapperCenter } from "./common.styled";

interface INoAccessProps {
    authorizationExpired?: boolean;
}
const MessageCenter = styled.div`
    text-align: center;
    ${flexColumn};
    max-width: 575px;
    padding: ${Spacing.S}px;
    gap: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        gap: ${Spacing.XS}px;
    }
`;

const NoAccessPage = ({ authorizationExpired }: INoAccessProps) => {
    const { t } = useTranslation();
    const { postMessage } = useContext(IntercomContext);

    const onClickHandler = () => {
        //for local development purposes only
        if (process.env.REACT_APP_RUN_STANDALONE === "true") {
            window.location.reload();
            return;
        }
        postMessage(IntercomTopic.EMBEDDED_APP_ACCESS_EXPIRED, "");
    };

    return (
        <WrapperCenter>
            <MessageCenter>
                <Title>{t("noAccessPage.title")}</Title>
                <Text>{t("noAccessPage.messagesPerApp.default")}</Text>
            </MessageCenter>
            {authorizationExpired && (
                <Modal
                    title={t("noAccessPage.timedOutModal.title")}
                    isOpen={authorizationExpired}
                    size="S"
                    onClose={onClickHandler}
                    actions={[{ children: t("noAccessPage.timedOutModal.reloadButton"), onClick: onClickHandler }]}
                >
                    <Text>{t("noAccessPage.timedOutModal.subtitle")}</Text>
                </Modal>
            )}
        </WrapperCenter>
    );
};

export default NoAccessPage;
