import { IconType } from "@secuis/ccp-react-components";
import i18next from "i18next";
import { Step } from "react-joyride";

import { AppGuideColor } from "./AppGuideTooltip";

export interface AppGuideStep extends Step {
    icon?: IconType;
    theme?: AppGuideColor;
    closeLabel?: string;
    navbarFix?: boolean;
}

export const InsightsSteps = (target: string): AppGuideStep[] => {
    return [
        {
            title: `${i18next.t("appGuide.insights.severityGraph.title")}`,
            target: `.${target}`,
            content: `${i18next.t("appGuide.insights.severityGraph.content")}`,
            icon: "QuestionAskHelp",
            disableBeacon: true,
            placement: "top",
            theme: "light",
            closeLabel: "appGuide.closeButton",
            navbarFix: true,
        },
    ];
};

export const ReportDetailsSteps = (): AppGuideStep[] => {
    return [
        {
            title: `${i18next.t("appGuide.reportDetails.comments.title")}`,
            target: ".comments-container",
            content: `${i18next.t("appGuide.reportDetails.comments.content")}`,
            disableBeacon: true,
            icon: "QuestionAskHelp",
            placement: "top-start",
            theme: "light",
            closeLabel: "appGuide.closeButton",
        },
    ];
};
