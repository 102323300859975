import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ToggleStyled, UnreadReportsWrapperStyled } from "./UnreadReportsFilter.styled";

export interface UnreadReportFilterProps {
    showOnlyUnread: boolean;
    handleUnreadChange: () => void;
}

const UnreadReportsFilter = ({ showOnlyUnread, handleUnreadChange }: UnreadReportFilterProps) => {
    const { t } = useTranslation();

    const isMobile = useHasMaxWidth(Breakpoints.XS);
    return (
        <UnreadReportsWrapperStyled mb="XS">
            <ToggleStyled
                id="unread-toggle"
                label={isMobile ? t("filters.showOnlyUnread.labelShort") : t("filters.showOnlyUnread.labelLong")}
                checked={showOnlyUnread}
                onChange={handleUnreadChange}
                direction="row-reverse"
            />
        </UnreadReportsWrapperStyled>
    );
};

export default UnreadReportsFilter;
