import { useIsProtectasHook } from "./ProtectasHooks";

export const useWebHubGlobalUrl = () => {
    const isProtectas = useIsProtectasHook();

    if (isProtectas) {
        return process.env.REACT_APP_WEB_HUB_GLOBAL_URL_PROTECTAS;
    }

    return process.env.REACT_APP_WEB_HUB_GLOBAL_URL;
};
