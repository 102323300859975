import { createReducer } from "@reduxjs/toolkit";

import { mapToUser } from "../../models/UserModel";
import { RequestStatus } from "../RequestStatus";
import {
    IUserState,
    USER_GET_INFO_FAILURE,
    USER_GET_INFO_REQUEST,
    USER_GET_INFO_SUCCESS,
    USER_SET_PREFERENCE_FAILURE,
    USER_SET_PREFERENCE_REQUEST,
    USER_SET_PREFERENCE_SUCCESS,
} from "./types";

export const defaultState: IUserState = {
    info: null,
    invalidated: true,
    status: RequestStatus.undefined,
};

const userReducer = createReducer(defaultState, {
    [USER_SET_PREFERENCE_REQUEST]: (state) => {
        state.status = RequestStatus.loading;
    },
    [USER_SET_PREFERENCE_SUCCESS]: (state, action) => {
        state.status = RequestStatus.success;
        state.info.preferences[action.payload.key] = action.payload.value;
    },
    [USER_SET_PREFERENCE_FAILURE]: (state) => {
        state.status = RequestStatus.error;
    },
    [USER_GET_INFO_REQUEST]: (state) => {
        state.status = RequestStatus.loading;
    },
    [USER_GET_INFO_SUCCESS]: (state, action) => {
        state.invalidated = false;
        state.status = RequestStatus.success;
        state.info = mapToUser(action.payload);
    },
    [USER_GET_INFO_FAILURE]: (state) => {
        state.invalidated = false;
        state.status = RequestStatus.error;
    },
});

export default userReducer;
