import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { TileHeaderSection } from "../../../../../components/Insights/common/InsightHeader.styled";
import { InsightTileHeader } from "../../../../../components/Insights/common/InsightTileHeader";
import { Header } from "../../../../../components/Insights/common/InsightTileHeader.styled";

export const SiteCardWrapperStyled = styled.div<{ hasWholeWidth?: boolean }>`
    flex: 1 1 max(40%, 370px);
    grid-column: ${({ hasWholeWidth }): string => (hasWholeWidth ? "span 2" : null)};
    background-color: ${Palette.Navy650};
    border-radius: ${Spacing.XXS}px;
    padding: ${Spacing.S}px;
    overflow: hidden;

    & > div:first-child {
        margin: ${Spacing.XS}px 0;
        height: auto;
    }

    @media (max-width: ${Breakpoints.XS}) {
        flex: 1 1 1;
    }
`;
export const SiteCardItemStyled = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${Spacing.XS}px 0;
    box-shadow: inset 0px 1px 0px ${Palette.Navy600};
`;
export const InsightTileHeaderStyled = styled(InsightTileHeader)`
    width: 100%;

    ${Header} {
        > div:first-child {
            margin-left: 0;
            @media (max-width: ${Breakpoints.M}) {
                align-self: center;
            }
        }
    }

    ${TileHeaderSection} {
        @media (max-width: ${Breakpoints.M}) {
            align-self: flex-start;
        }
    }

    @media (max-width: ${Breakpoints.M}) {
        align-items: flex-start;
        flex-direction: column;
    }
`;
