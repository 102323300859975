import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { CheckboxListItem } from "../CheckboxList/CheckboxListItem";
import { DropdownList } from "../CheckboxList/CheckboxListItem.styles";
import { IMultiListItem } from "../CheckboxList/CheckboxListItem.types";
import { ListButton } from "./ListButton";
import { DropdownListHeader, SelectedItemsHeader } from "./MultiDropdownSelectedSection.styled";

type Props = {
    label: string;
    selectedItems: IMultiListItem[];
    onChange;
    onClearButtonClick;
};

export const MultiDropdownSelectedSection = ({ label, selectedItems, onChange, onClearButtonClick }: Props) => {
    const { t } = useTranslation();
    return selectedItems.length > 0 ? (
        <DropdownListHeader>
            <Stack alignItems="baseline" pr="S" pb="S">
                <Text micro bold uppercase color="secondary">
                    {label}
                </Text>
                <ListButton label={t("filters.clear")} onClick={onClearButtonClick} />
            </Stack>
            <SelectedItemsHeader>
                <DropdownList data-testid="dropdown-list">
                    {selectedItems.map((item) => (
                        <CheckboxListItem
                            key={item.value}
                            item={item}
                            displayChildren={false}
                            selectedItems={selectedItems.map((i) => i.value)}
                            onItemClickHandler={(e) => {
                                onChange(e);
                            }}
                        />
                    ))}
                </DropdownList>
            </SelectedItemsHeader>
        </DropdownListHeader>
    ) : null;
};
