export enum IncidentFields {
    title = "title",
    description = "description",
    policeInvolved = "policeInvolved",
    medicalServicesInvolved = "medicalServicesInvolved",
    fireDepartmentInvolved = "fireDepartmentInvolved",
    siteSupervisorNotified = "siteSupervisorNotified",
    personalData = "enterPersonalData",
    altDescription = "pleaseDescribeTheIncident(excludePersonalData)",
    eventDescription = "pleaseDescribeTheEvent(excludePersonalData)",
    incidentDiscoveryTime = "incidentDiscoveryTimeAndDate",
    eventDiscoveryTime = "eventDiscoveryTimeAndDate",
}
