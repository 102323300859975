import { createReducer } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import { mapComment } from "./models";
import { ICommentRecordState, ICommentState } from "./types";

export const FETCH_REPORT_COMMENTS_REQUEST = "@@report/FETCH_REPORT_COMMENTS_REQUEST";
export const FETCH_REPORT_COMMENTS_SUCCESS = "@@report/FETCH_REPORT_COMMENTS_SUCCESS";
export const FETCH_REPORT_COMMENTS_FAILURE = "@@report/FETCH_REPORT_COMMENTS_FAILURE";

export const CREATE_COMMENT_REQUEST = "@@report/CREATE_COMMENT_REQUEST";
export const CREATE_COMMENT_SUCCESS = "@@report/CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "@@report/CREATE_COMMENT_FAILURE";

export const UPDATE_COMMENT_REQUEST = "@@report/UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "@@report/UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "@@report/UPDATE_COMMENT_FAILURE";

export const DELETE_COMMENT_REQUEST = "@@report/DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "@@report/DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "@@report/DELETE_COMMENT_FAILURE";

export const SET_ENTRY_DEFAULT_STATE = "@@report/SET_ENTRY_DEFAULT_STATE";

const entryDefaultState: ICommentRecordState = {
    comments: [],
    createState: { status: RequestStatus.undefined },
    fetchStatus: RequestStatus.undefined,
};

export const defaultState: ICommentState = {
    byReportKey: {},
};

const commentReducer = createReducer(defaultState, {
    [SET_ENTRY_DEFAULT_STATE]: (state, action) => {
        const key = action?.meta?.key;
        if (key) {
            state.byReportKey[key] = entryDefaultState;
        }
    },
    [FETCH_REPORT_COMMENTS_REQUEST]: (state, action) => {
        const key = action.meta.key;
        const entry = state.byReportKey[key] || { ...entryDefaultState };
        entry.fetchStatus = RequestStatus.loading;
        state.byReportKey[key] = entry;
    },
    [FETCH_REPORT_COMMENTS_SUCCESS]: (state, action) => {
        const key = action.meta.key;
        const entry = state.byReportKey[key] || { ...entryDefaultState };
        try {
            entry.comments = action.payload.map((c) => mapComment(c));
            entry.fetchStatus = RequestStatus.success;
        } catch {
            entry.comments = [];
            entry.fetchStatus = RequestStatus.invalid;
        }
        state.byReportKey[key] = entry;
    },
    [FETCH_REPORT_COMMENTS_FAILURE]: (state, action) => {
        const key = action.meta.key;
        const entry = state.byReportKey[key] || { ...entryDefaultState };
        entry.fetchStatus = RequestStatus.error;
        entry.comments = [];
        state.byReportKey[key] = entry;
    },
    [CREATE_COMMENT_REQUEST]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key] || { ...entryDefaultState };
        reportComments.createState = { status: RequestStatus.loading };
        state.byReportKey[key] = reportComments;
    },
    [CREATE_COMMENT_SUCCESS]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key] || { ...entryDefaultState };
        reportComments.createState = { status: RequestStatus.success, id: action.payload.id };
        const commentContent = action.meta.content; //the BE returns escaped content by default and we don't want it
        reportComments.comments = [...reportComments.comments, { ...mapComment(action.payload), content: commentContent }];
        state.byReportKey[key] = reportComments;
    },
    [CREATE_COMMENT_FAILURE]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key] || { ...entryDefaultState };
        reportComments.createState = { status: RequestStatus.error, id: action.payload.id };
        state.byReportKey[key] = reportComments;
    },
    [UPDATE_COMMENT_REQUEST]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key];
        if (!reportComments || !reportComments?.comments?.length) {
            //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
            return;
        }
        const comment = reportComments?.comments?.find((c) => c.id === action.meta.commentId);
        if (!comment) {
            return;
        }
        comment.updateStatus = RequestStatus.loading;
    },
    [UPDATE_COMMENT_SUCCESS]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key];
        if (!reportComments || !reportComments?.comments?.length) {
            //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
            return;
        }
        const comment = reportComments.comments.find((c) => c.id === action.meta.commentId);
        if (!comment) {
            return;
        }
        const commentContent = action.meta.content; //the BE returns escaped content by default and we don't want it
        const updated = mapComment(action.payload);
        updated.content = commentContent;
        updated.updateStatus = RequestStatus.success;
        Object.keys(updated).forEach(function (key) {
            comment[key] = updated[key];
        });
    },
    [UPDATE_COMMENT_FAILURE]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key] || { ...entryDefaultState };
        if (!reportComments || !reportComments?.comments?.length) {
            //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
            return;
        }
        const comment = reportComments?.comments?.find((c) => c.id === action.meta.commentId);
        if (!comment) {
            return;
        }
        comment.updateStatus = RequestStatus.error;
    },
    [DELETE_COMMENT_SUCCESS]: (state, action) => {
        const key = action.meta.key;
        const reportComments = state.byReportKey[key] || { ...entryDefaultState };
        if (!reportComments || !reportComments?.comments?.length) {
            //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
            return;
        }
        const comments = reportComments.comments.filter((c) => c.id !== action.meta.commentId);
        reportComments.comments = comments;
    },
});

export default commentReducer;
