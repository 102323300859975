import _sortBy from "lodash/sortBy";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { useAuthorizedLocations } from "../../../store/locations/LocationsHooks";
import type { ISiteGroup } from "../types";

const UNGROUPED_SITES_GROUP_ID = "ungrouped";

export const useAuthorizedSiteGroups = (): ISiteGroup[] => {
    const { siteObjects, groups } = useAuthorizedLocations();
    const { t } = useTranslation();

    const updateParentLocationsCount = useCallback((groups: ISiteGroup[]) => {
        groups.forEach((group) => {
            let parentId = group.parentId;
            while (parentId) {
                const parentGroup = groups.find((x) => x.id === parentId);
                parentGroup.descendantSitesCount += group.locationIds.length;
                parentId = parentGroup.parentId;
            }
        });
    }, []);

    const getLocationGroups = useCallback(
        (siteObjects: ISiteObject[]) => {
            const locationGroups = siteObjects.reduce((result, site) => {
                if (!result.some((x) => x.id === site.region)) {
                    result.push({
                        id: site.region,
                        name: t(`region.${site.region}`),
                        parentId: "",
                        locationIds: [],
                        descendantSitesCount: 0,
                    });
                }

                if (!result.some((x) => x.id === site.countryCode)) {
                    result.push({
                        id: site.countryCode,
                        name: t(`country.${site.countryCode}`),
                        parentId: site.region,
                        locationIds: [site.id],
                        descendantSitesCount: 1,
                    });
                    return result;
                }

                const group = result.find((x) => x.id === site.countryCode);
                group.locationIds.push(site.id);
                group.descendantSitesCount++;

                return result;
            }, []);

            updateParentLocationsCount(locationGroups);

            return _sortBy(locationGroups, ["name"]);
        },
        [t, updateParentLocationsCount],
    );

    const siteGroups = useMemo(() => {
        if (!groups.length) {
            return getLocationGroups(siteObjects);
        }

        const groupedSiteIds = groups.reduce((result, group) => {
            result.push(...group.locationIds);
            return result;
        }, []);

        const ungroupedSiteIds = siteObjects.reduce((result, site) => {
            if (!groupedSiteIds.some((x) => x === site.id)) {
                result.push(site.id);
            }
            return result;
        }, []);

        const ungroupedSiteGroups = [];

        if (ungroupedSiteIds.length > 0) {
            ungroupedSiteGroups.push({
                id: UNGROUPED_SITES_GROUP_ID,
                name: t("sites.list.ungroupedSites"),
                parentId: "",
                locationIds: ungroupedSiteIds,
                descendantSitesCount: ungroupedSiteIds.length,
            });
        }

        const clientGroups = groups.map((group) => {
            const isTopLevelGroup = !groups.some((x) => x.id === group.parentId);
            const isParentGroupWithSites = groups.some((x) => x.parentId === group.id) && group.locationIds.length > 0;

            if (isParentGroupWithSites) {
                ungroupedSiteGroups.push({
                    id: `${UNGROUPED_SITES_GROUP_ID}-${group.id}`,
                    name: t("sites.list.ungroupedSites"),
                    parentId: group.id,
                    locationIds: group.locationIds,
                    descendantSitesCount: group.locationIds.length,
                });
            }

            return {
                ...group,
                parentId: isTopLevelGroup ? "" : group.parentId,
                locationIds: !isParentGroupWithSites ? group.locationIds : [],
                descendantSitesCount: !isParentGroupWithSites ? group.locationIds.length : 0,
            };
        });

        clientGroups.push(...ungroupedSiteGroups);

        updateParentLocationsCount(clientGroups);

        return clientGroups;
    }, [groups, siteObjects, updateParentLocationsCount, getLocationGroups, t]);

    return siteGroups;
};

export const useAncestorSiteGroups = (groupId: string): ISiteGroup[] => {
    const siteGroups = useAuthorizedSiteGroups();
    const { siteObjects } = useAuthorizedLocations();
    const { t } = useTranslation();

    const ancestorSiteGroups = useMemo(() => {
        const ancestorGroups: ISiteGroup[] = [];
        let currentGroup = siteGroups.find((x) => x.id === groupId);

        while (currentGroup) {
            ancestorGroups.unshift(currentGroup);
            currentGroup = siteGroups.find((x) => x.id === currentGroup.parentId);
        }

        ancestorGroups.unshift({
            id: "",
            name: t("sites.list.all"),
            parentId: "",
            locationIds: [],
            descendantSitesCount: siteObjects.length,
        });

        return ancestorGroups;
    }, [siteGroups, groupId, siteObjects.length, t]);

    return ancestorSiteGroups;
};
