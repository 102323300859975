import { Breakpoints, ButtonText, Icon, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import { flexRowAlignCenter } from "src/styles/templates/Flex";
import styled from "styled-components";

export const SiteTileContainerStyled = styled.div`
    width: 100%;
    min-width: 220px;
    min-height: 90px;
    background-color: ${Palette.Navy750};
    border-radius: 4px;
    padding: ${Spacing.S}px;
    cursor: default;

    @media only screen and (min-width: ${Breakpoints.XS}) {
        &:hover {
            background-color: ${Palette.Navy650};
        }
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        width: 100%;
        max-width: initial;
    }
`;

export const AddressStyled = styled.div`
    color: ${Palette.Navy100};
    margin-top: ${Spacing.XS}px;
    width: 100%;

    & > div {
        max-width: 100%;
    }
`;

export const AddressContainerStyled = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${flexRowAlignCenter}
`;

export const AddressTextStyled = styled(Text)`
    margin-left: ${Spacing.XXS};
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const TextContainerStyled = styled(Text)`
    display: flex;
    flex-direction: column;
    & > div {
        width: fit-content;
        max-width: 100%;
        & > div > p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
`;

export const HoverGroupStyled = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${Spacing.S}px;

    &:last-child {
        margin-right: 0;
    }
`;

export const ButtonTextStyled = styled(ButtonText)`
    &:focus-visible {
        border-radius: ${FocusStyles.BorderRadius}px;
        outline-offset: ${FocusStyles.OutlineOffset}px;
    }
`;

export const IconStyled = styled(Icon)`
    margin-right: ${Spacing.XXS}px;
    flex-shrink: 0;
`;

export const TextStyled = styled(Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
