import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { TableComponent } from "../../../../components/shared/Table/Table";

export const tourNameHeaderStyles = css`
    @media (max-width: ${Breakpoints.XS}) {
        display: none;
    }
`;

export const tourNameCellStyles = css`
    @media (max-width: ${Breakpoints.XS}) {
        grid-column: 1/-1;
        padding-bottom: ${Spacing.XS}px;
    }
`;

export const deviationStyles = css`
    @media (max-width: ${Breakpoints.XS}) {
        margin-left: ${Spacing.XS}px;
        margin-right: ${Spacing.XS}px;
    }
`;

export const StyledTourNameCell = styled(Stack)`
    max-width: 100%;
    padding-right: ${Spacing.XS}px;
    @media (max-width: ${Breakpoints.XS}) {
        max-width: calc(60vw - ${Spacing.L}px);
    }

    path {
        stroke-width: 2;
    }
`;

export const StyledTable = styled(TableComponent)`
    grid-template-columns: 400px repeat(3, 1fr);
    @media (max-width: ${Breakpoints.M}) {
        grid-template-columns: 280px repeat(3, 1fr);
    }
    @media (max-width: ${Breakpoints.S}) {
        grid-template-columns: 280px repeat(3, 1fr);
    }
    @media (max-width: ${Breakpoints.XS}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const IconWrapper = styled.div`
    svg path {
        stroke: ${Palette.Gray100};
    }
`;
