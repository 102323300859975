import { GraphColor } from "@secuis/ccp-react-components";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";

export const LABELS_MAP: Record<Exclude<CategoryLevel1, CategoryLevel1Keys.internalSecuritas>, string> = {
    criminal_act_or_suspicious_behavior: "A",
    facility: "B",
    health_and_safety: "C",
    people_and_assets: "D",
};

export const COLORS_MAP: Record<Exclude<CategoryLevel1, CategoryLevel1Keys.internalSecuritas>, GraphColor> = {
    criminal_act_or_suspicious_behavior: "dataSeries1",
    facility: "dataSeries2",
    health_and_safety: "dataSeries3",
    people_and_assets: "dataSeries4",
};

export const LABEL_HEIGHT_INDEX = 15;
