import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StandaloneSection = styled.div`
    padding: ${Spacing.M}px;
    background-color: ${Palette.Navy650};
    border-radius: ${Spacing.XXS}px;

    @media (max-width: ${Breakpoints.M}) {
        padding: ${Spacing.S}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        padding-bottom: ${Spacing.M}px;
    }
`;
