import { View } from "@react-pdf/renderer";
import { Spacing } from "@secuis/ccp-react-components";

import { TextAreaSummaryFieldPdf, TextAreaSummaryFieldPdfType, TextSummaryFieldPdf, TextSummaryFieldPdfType } from "./PdfSummarySections";

type Props = {
    isRequired: boolean;
    fieldValue?: {
        textSummaryFieldProps: TextSummaryFieldPdfType;
        textAreaSummaryFieldProps: TextAreaSummaryFieldPdfType;
    };
};

export const TextTextAreaSummaryFieldPdf = ({ fieldValue }: Props): JSX.Element => {
    const textSummaryFieldProps = fieldValue?.textSummaryFieldProps;
    const textAreaSummaryFieldProps = fieldValue?.textAreaSummaryFieldProps;

    const textAreaSummaryFieldPdfViewStyle = textSummaryFieldProps.isRequired || textSummaryFieldProps.fieldValue ? { marginTop: Spacing.XS } : {};

    return (
        <>
            {textSummaryFieldProps && <TextSummaryFieldPdf {...textSummaryFieldProps} />}
            {textAreaSummaryFieldProps && (
                <View style={textAreaSummaryFieldPdfViewStyle} wrap={false}>
                    <TextAreaSummaryFieldPdf {...textAreaSummaryFieldProps} />
                </View>
            )}
        </>
    );
};
