import { Image, Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { useTranslation } from "react-i18next";

import { IReportImage } from "../../../models/ReportImagesModels";
import { styles } from "../pdfStyles";

type Props = {
    label: string;
    images: IReportImage[];
};

export const PdfImagesSection = ({ label, images }: Props) => {
    const { t } = useTranslation();
    if (!images || images.length === 0) return null;
    return (
        <View style={styles.imagesSection}>
            <Text style={styles.sectionTitle}>{t(label).toUpperCase()}</Text>
            <PdfImagesCarousel images={images} />
            <View style={styles.sectionLineBreak}></View>
        </View>
    );
};

type PdfImagesCarouselProps = {
    images: IReportImage[];
    imageStyle?: Style | Style[];
};

export const PdfImagesCarousel = ({ images, imageStyle = styles.reportImage }: PdfImagesCarouselProps) => {
    if (!images || images.length === 0) return null;
    return (
        <View style={styles.imagesCarousel}>
            {images
                .filter((img) => img?.image)
                .map((img) => (
                    <Image key={img.reportFieldName} source={img.image} style={imageStyle} />
                ))}
        </View>
    );
};
