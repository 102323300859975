import isPropValid from "@emotion/is-prop-valid";
import { PropsWithChildren } from "react";
import { StyleSheetManager } from "styled-components";

export const StyledComponentsManager = ({ children }: PropsWithChildren) => {
    return <StyleSheetManager shouldForwardProp={shouldForwardProp}>{children}</StyleSheetManager>;
};

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName: string, target: unknown) => {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};
