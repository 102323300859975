import { Spacing, Stack, Text, Title } from "@secuis/ccp-react-components";
import styled from "styled-components";

type Props = {
    header: string;
    subheader?: string;
    sideText?: string;
};

export const HeadlineWrapper = styled(Stack)`
    align-items: baseline;
    column-gap: ${Spacing.M}px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${Spacing.XS}px;
    min-width: fit-content;
    width: 100%;
`;

const SpaceBetweenWrapper = styled(Stack)`
    align-items: baseline;
    flex: 1;
    flex-wrap: wrap;
    gap: ${Spacing.XS}px;
    justify-content: space-between;
    max-width: 100%;
    min-width: fit-content;
`;

export const Subheader = ({ subheader, sideText }: Omit<Props, "header">) => {
    if (subheader && sideText)
        return (
            <SpaceBetweenWrapper>
                {subheader && (
                    <Text color="secondary" small uppercase>
                        {subheader}
                    </Text>
                )}
                {sideText && (
                    <Text color="secondary" micro>
                        {sideText}
                    </Text>
                )}
            </SpaceBetweenWrapper>
        );
    if (subheader) {
        return (
            <Text color="secondary" small uppercase>
                {subheader}
            </Text>
        );
    }
    if (sideText) {
        return (
            <Text color="secondary" micro>
                {sideText}
            </Text>
        );
    }
    return null;
};

export const TemplateHeadline = ({ header, subheader, sideText }: Props) => {
    return (
        <HeadlineWrapper>
            <Title>{header}</Title>
            <Subheader subheader={subheader} sideText={sideText} />
        </HeadlineWrapper>
    );
};
