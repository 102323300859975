import { Icon, IconType, Text } from "@secuis/ccp-react-components";
import { HTMLAttributes, useCallback } from "react";

import { ContextMenuItemStyled, ContextMenuRow } from "./ContextMenu.styles";

export interface IContextMenuOption {
    label: string;
    onClick: () => void;
    iconVariant?: IconType;
    dataTestId?: string;
}

type Props = {
    option: IContextMenuOption;
    onClick: (option: IContextMenuOption) => void;
};

export const ContextMenuItemMobile = ({ option, onClick }: Props) => {
    const onItemClick = useCallback(() => {
        onClick(option);
    }, [option, onClick]);

    return (
        <ContextMenuRow key={option.label} onClick={onItemClick}>
            <Icon variant={option.iconVariant} size={"M"} />
            <Text>{option.label}</Text>
        </ContextMenuRow>
    );
};

export const ContextMenuItem = ({ className, option, onClick }: Props & HTMLAttributes<HTMLDivElement>) => {
    const onItemClick = useCallback(() => {
        onClick(option);
    }, [option, onClick]);

    return (
        <ContextMenuItemStyled className={className} role="item" onClick={onItemClick} data-testid={option.dataTestId}>
            {option.iconVariant && <Icon variant={option.iconVariant} size={"M"} />} {option.label}
        </ContextMenuItemStyled>
    );
};
