import { isRejectedWithValue } from "@reduxjs/toolkit";
import i18next from "i18next";
import { isRSAA, RSAA } from "redux-api-middleware";

import AppActions from "../store/app/AppActions";

const apiErrorMiddleware =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        const isRSAAAction = isRSAA(action);
        const isRTKQueryAction = isRejectedWithValue(action) && !isRSAAAction;

        if (!isRSAAAction && !isRTKQueryAction) {
            return next(action);
        }

        let silent = false;

        if (isRSAAAction) {
            silent = action[RSAA].silent;
        }

        if (silent) {
            delete action[RSAA].silent;
        }

        const result = await next(action);

        if ((!result?.error && !result?.payload?.error) || silent) {
            return result;
        }

        // ignore logging azure translator actions
        // that's because when developing locally the calls won't work and make a mess in the console
        if (process.env.NODE_ENV === "development" && result.type.includes("@@translate")) {
            return result;
        }

        dispatch(
            AppActions.setApiError({
                name: i18next.t("error.api.general.title"),
                message: i18next.t("error.api.general.message"),
                silent,
                status: result.payload.status,
            }),
        );

        return result;
    };

export default apiErrorMiddleware;
