import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authorizationApi } from "../authorisation/authorizationApi";
import { RequestStatus } from "../RequestStatus";
import { IAvailableClient, IClientSwitcherState } from "./types";

export const CLIENT_SWITCHER_INTIAL_STATE: IClientSwitcherState = {
    initialClientCount: 0,
    initialStatus: RequestStatus.undefined,
    availableClients: {
        status: RequestStatus.undefined,
        totalCount: 0,
        results: [],
        skip: 0,
    },
    selectedClient: null,
    userAuthorizations: {},
    clientAuthorizations: {
        data: [],
        status: RequestStatus.undefined,
    },
};

export const clientSwitcherSlice = createSlice({
    name: "clientSwitcher",
    initialState: CLIENT_SWITCHER_INTIAL_STATE,
    reducers: {
        selectClient: (state, action: PayloadAction<{ client: IAvailableClient | undefined }>) => {
            state.selectedClient = action.payload.client;
            state.userAuthorizations = {};
        },
        clearFilteredClients: (state) => {
            state.availableClients.results = [];
            state.availableClients.skip = 0;
            state.availableClients.status = RequestStatus.undefined;
            state.availableClients.totalCount = 0;
        },
        resetClients: () => {},
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authorizationApi.endpoints.searchAvailableClients.matchPending, (state) => {
                state.availableClients.status = RequestStatus.loading;
            })
            .addMatcher(authorizationApi.endpoints.searchAvailableClients.matchFulfilled, (state, action) => {
                state.availableClients.status = RequestStatus.success;
                state.availableClients.totalCount = action.payload.totalCount;
                state.availableClients.results = action.meta.arg.originalArgs.skip
                    ? [...state.availableClients.results, ...action.payload.results]
                    : action.payload.results;
                state.availableClients.skip = action.meta.arg.originalArgs.skip;
            })
            .addMatcher(authorizationApi.endpoints.searchAvailableClients.matchRejected, (state) => {
                state.availableClients.status = RequestStatus.error;
            })
            .addMatcher(authorizationApi.endpoints.getInitialClientsInfo.matchPending, (state) => {
                state.initialStatus = RequestStatus.loading;
            })
            .addMatcher(authorizationApi.endpoints.getInitialClientsInfo.matchFulfilled, (state, action) => {
                state.initialStatus = RequestStatus.success;
                state.availableClients.results = action.payload.results;
                state.initialClientCount = action.payload.totalCount;
                if (action.payload.totalCount === 1) {
                    state.selectedClient = action.payload.results[0];
                }
            })
            .addMatcher(authorizationApi.endpoints.getInitialClientsInfo.matchRejected, (state) => {
                state.initialStatus = RequestStatus.error;
            })
            .addMatcher(authorizationApi.endpoints.getUserAuthorizeStatus.matchPending, (state, action) => {
                state.userAuthorizations[action.meta.arg.originalArgs.locationId] = {
                    authorized: false,
                    status: RequestStatus.loading,
                };
            })
            .addMatcher(authorizationApi.endpoints.getUserAuthorizeStatus.matchFulfilled, (state, action) => {
                state.userAuthorizations[action.meta.arg.originalArgs.locationId] = {
                    authorized: action.payload.length > 0,
                    status: RequestStatus.success,
                };
            })
            .addMatcher(authorizationApi.endpoints.getUserAuthorizeStatus.matchRejected, (state, action) => {
                state.userAuthorizations[action.meta.arg.originalArgs.locationId] = {
                    authorized: false,
                    status: RequestStatus.error,
                };
            })
            .addMatcher(authorizationApi.endpoints.getClientAuthorizations.matchPending, (state) => {
                state.clientAuthorizations = { data: [], status: RequestStatus.loading };
            })
            .addMatcher(authorizationApi.endpoints.getClientAuthorizations.matchFulfilled, (state, action) => {
                state.clientAuthorizations = { data: action.payload ?? [], status: RequestStatus.success };
            })
            .addMatcher(authorizationApi.endpoints.getClientAuthorizations.matchRejected, (state) => {
                state.clientAuthorizations = { data: [], status: RequestStatus.error };
            });
    },
});
