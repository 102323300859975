import { CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { EVENTS_DEFAULT_CATEGORIES } from "src/pages/Insights/Events/Events.constants";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";
import { IReportCategoryLevel1 } from "src/store/reportCategories/types";

import { CustomizedCategories, ReportCategoryWithLevel } from "../TrendingEvents.types";

export const useCategoriesWithLevel = (categories: CustomizedCategories): ReportCategoryWithLevel[] => {
    const { reportCategories } = useReportCategories();

    if (!categories || Object.values(categories).every((catList) => !catList.length)) {
        const level2Categories = getLevel2Categories(reportCategories, EVENTS_DEFAULT_CATEGORIES);
        return level2Categories.map((category) => ({
            category,
            level: 2,
        }));
    }

    const categoryLevels = [
        { categories: categories.level1Categories, level: 1 },
        { categories: categories.level2Categories, level: 2 },
        { categories: categories.level3Categories, level: 3 },
    ];

    return categoryLevels.flatMap(({ categories = [], level }) =>
        categories.map((category) => ({
            category: category.value,
            level,
        })),
    );
};

const getLevel2Categories = (categories: IReportCategoryLevel1[], defaultCategories: CategoryLevel1Keys[]): string[] => {
    return categories
        .filter((category) => defaultCategories.includes(category.key as CategoryLevel1Keys))
        .flatMap((category) => category.level2Categories.map((level2) => level2.key));
};
