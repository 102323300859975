import { faker } from "@faker-js/faker";
import { sample } from "lodash";

import { add, formatExplicit } from "../../helpers/date";
import { DynamicReportPropertyType, DynamicReportPropertyTypeEnum, ReportTemplateEnum } from "../../models/ReportModel";
import {
    ActivityLogFields,
    AlarmResponseFields,
    CalloutFields,
    IncidentFields,
    MaintenanceFields,
    SafetyInspectionFields,
    SiteInspectionFields,
    TaskReportFields,
} from "../../models/reportTemplates";
import { Report } from "../types";

type ReportProperties = DynamicReportPropertyType[];

export const getReportProperties = (report: Report): ReportProperties => {
    switch (report.type) {
        case ReportTemplateEnum.incident:
            return getIncidentProperties(report);
        case ReportTemplateEnum.alarmResponse:
            return getAlarmResponseProperties(report);
        case ReportTemplateEnum.taskReport:
            return getTaskReportProperties();
        case ReportTemplateEnum.maintenance:
            return getMaintananceProperties();
        case ReportTemplateEnum.safetyInspection:
            return getSafetyInspectionProperties();
        case ReportTemplateEnum.siteInspection:
            return getSiteInspectionProperties(report);
        case ReportTemplateEnum.activityLog:
            return getActivityLogProperties();
        case ReportTemplateEnum.callout:
            return getCalloutProperties();
        default:
            return [];
    }
};

export const getIncidentProperties = (report: Report): ReportProperties => {
    const descriptions = [
        "In basement, water detection panel shows power fault and general fault no zone and beeping.",
        "Park Bike Rack Door is so difficult to open.",
        `Today we found ${faker.commerce.product()} in the office.`,
        `We found ${faker.commerce.productName()} in the basement.`,
        `Broken glass in office room #${faker.location.buildingNumber()}`,
    ];

    return [
        {
            value: new Date(report.createDateTime).toLocaleString(),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: IncidentFields.incidentDiscoveryTime,
        },
        {
            value: sample(descriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: IncidentFields.altDescription,
        },
        {
            value: `Reporting officer: ${faker.person.firstName()} ${faker.person.lastName()}`,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: IncidentFields.personalData,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.boolean,
            reportFieldName: IncidentFields.policeInvolved,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.boolean,
            reportFieldName: IncidentFields.siteSupervisorNotified,
        },
    ];
};

export const getAlarmResponseProperties = (report: Report): ReportProperties => {
    const startTime = new Date(report.createDateTime).toLocaleTimeString();
    const endTime = add(new Date(report.createDateTime), { minutes: 30 }).toLocaleTimeString();

    const descriptions = [
        {
            shortDescription: "Fire Panel",
            longDescription: "Alarm at fire panel.",
        },
        {
            shortDescription: "Broken glass",
            longDescription: "Broken glass outside of elevator on the 2nd floor.",
        },
        {
            shortDescription: "Water leak",
            longDescription: "Water leak from ceiling.",
        },
    ];

    const { shortDescription, longDescription } = sample(descriptions);

    return [
        {
            value: shortDescription,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: AlarmResponseFields.title,
        },
        {
            value: longDescription,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: AlarmResponseFields.description,
        },
        {
            value: faker.person.firstName() + " " + faker.person.lastName(),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: AlarmResponseFields.onSiteContact,
        },
        {
            value: endTime,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: AlarmResponseFields.timeDepartedFromSite,
        },
        {
            value: startTime,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: AlarmResponseFields.timeArrivedOnSite,
        },
    ];
};

export const getTaskReportProperties = (): ReportProperties => {
    const descriptions = ["Check if the fire extinguisher is in place.", "Check if the emergency exit is clear.", "Check if the fire alarm is working."];

    return [
        {
            value: sample(descriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: TaskReportFields.taskDescription,
        },
    ];
};

export const getMaintananceProperties = (): ReportProperties => {
    const descriptions = ["Multiple leaks are emanating from the same pipe.", "The light bulb in the hallway is flickering.", "The door handle is loose."];

    return [
        {
            value: sample(descriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: MaintenanceFields.description,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.boolean,
            reportFieldName: MaintenanceFields.wereYouAbleToPerformTheMaintenance,
        },
    ];
};

export const getSafetyInspectionProperties = (): ReportProperties => {
    const typesOfInspection = ["Fire extinguisher", "Emergency doors", "Safety pictograms", "Life support equipment (HLR)"];
    const desriptions = ["All correct", "All good", "Everything in order", "No issues found", "No problems detected"];

    return [
        {
            value: JSON.stringify(typesOfInspection),
            type: DynamicReportPropertyTypeEnum.array,
            reportFieldName: SafetyInspectionFields.typeOfInspection,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SafetyInspectionFields.wasEverythingInOrder,
        },
        {
            value: sample(desriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SafetyInspectionFields.description,
        },
    ];
};

export const getSiteInspectionProperties = (report: Report): ReportProperties => {
    const uniformAppearance = ["Satisfactory", "Unsatisfactory", "Not applicable"];

    return [
        {
            value: formatExplicit(new Date(report.createDateTime), "dd.MM.yyyy"),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SiteInspectionFields.postOrdersLastRevisionDate,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SiteInspectionFields.postOrderAcknowledgment,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SiteInspectionFields.regulatoryCompliant,
        },
        {
            value: sample(uniformAppearance),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SiteInspectionFields.officersUniformsAndAppearance,
        },
        {
            value: formatExplicit(new Date(report.createDateTime), "dd.MM.yyyy HH:mm"),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: SiteInspectionFields.inspectionDate,
        },
    ];
};

export const getActivityLogProperties = (): ReportProperties => {
    const comments = [
        "Patroled around the property, there are no damages.",
        "Zone #1 exterior patrol.",
        "Patrol conducted interior exterior #1242 check.",
        `Uniform inspection done by ${faker.person.firstName()} ${faker.person.lastName()}.`,
        "Exterior patrols at building 1, all clear.",
    ];

    return [
        {
            value: sample(comments),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: ActivityLogFields.comments,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: ActivityLogFields.commanderPresenceOnSite,
        },
        {
            value: Math.random() > 0.5 ? "Yes" : "No",
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: ActivityLogFields.passDownReportCompleted,
        },
    ];
};

export const getCalloutProperties = (): ReportProperties => {
    const descriptions = [
        "An unauthorized individual was found inside the restricted area of the company’s premises",
        "A fire alarm was activated on the second floor",
        "A visitor experienced a medical emergency in the lobby.",
    ];
    const additionalDescriptions = [
        "The person was escorted out by security without incident",
        "The area was evacuated, and no fire was found.",
        "Security called for an ambulance, and the individual was taken to the hospital.",
    ];

    return [
        {
            value: sample(descriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: CalloutFields.describeTheIncident,
        },
        {
            value: `Alarm ${faker.number.int(40)}`,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: CalloutFields.alarmCallType,
        },
        {
            value: sample(additionalDescriptions),
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: CalloutFields.additionalInformation,
        },
        {
            value: Math.random() > 0.5 ? "true" : "false",
            type: DynamicReportPropertyTypeEnum.boolean,
            reportFieldName: CalloutFields.didAnIncidentOccurOnSite,
        },
        {
            value: `Full name: ${faker.person.fullName()}`,
            type: DynamicReportPropertyTypeEnum.text,
            reportFieldName: CalloutFields.personalData,
        },
    ];
};

export default getReportProperties;
