import { IMultiListItem } from "../../../../../components/shared/CheckboxList/CheckboxListItem.types";
import { TopDamageCategoryType } from "./SiteCard.types";

export const getTopDamagesCategories = (
    reports: { [k: string]: number },
    categoriesList: IMultiListItem[],
    otherLabel: string,
    topIndex = 5,
): TopDamageCategoryType[] => {
    const allLevel2Categories = categoriesList.map((c) => c.children).flat();
    const getLabel = (category: string) => allLevel2Categories.find((c) => c.value === category)?.label;
    const formatDamageReports = (report: [string, number]) => {
        const [category, count] = report;
        return {
            label: getLabel(category),
            category: [category],
            count,
        };
    };
    const reportsList = Object.entries(reports).sort((a, b) => {
        const [, countA] = a;
        const [, countB] = b;
        return countB - countA;
    });
    if (reportsList.length <= topIndex) {
        return reportsList.slice(0, topIndex).map(formatDamageReports);
    } else {
        const otherDamages = reportsList.slice(topIndex - 1).reduce((acc, report) => {
            const [category, count] = report;
            const { category: accCategory = [], count: accCount = 0 } = acc;

            return {
                label: otherLabel,
                category: [...accCategory, category],
                count: accCount + count,
            };
        }, {} as TopDamageCategoryType);
        return reportsList
            .slice(0, topIndex - 1)
            .map(formatDamageReports)
            .concat(otherDamages);
    }
};
