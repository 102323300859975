import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack)`
    margin-top: 40px;
    margin-bottom: ${Spacing.M}px;

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        margin-top: ${Spacing.M}px;
        margin-bottom: ${Spacing.XS}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        margin-top: ${Spacing.S}px;
        margin-bottom: ${Spacing.S}px;
        padding-left: ${Spacing.S}px;
        padding-right: ${Spacing.S}px;
    }
`;

export const FilterWrapper = styled(Stack)`
    margin-left: ${Spacing.M}px;

    @media (max-width: ${Breakpoints.XS}) {
        margin-right: 0px;
    }
`;
