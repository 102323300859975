import { isEqual } from "lodash";
import { useEffect, useState } from "react";

export const useMemoizedDependency = <T>(dependencyValue: T) => {
    const [memoizedValue, setMemoizedValue] = useState<T>(dependencyValue);

    useEffect(() => {
        if (!isEqual(dependencyValue, memoizedValue)) {
            setMemoizedValue(dependencyValue);
        }
    }, [dependencyValue, memoizedValue]);

    return memoizedValue;
};
