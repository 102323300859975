import { Stack } from "@secuis/ccp-react-components";
import { EditorState } from "draft-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IUser } from "../../../models/UserModel";
import { APP_ID, ICommentModel, IUpsertCommentDto } from "../../../store/reportComments/models";
import { RequestStatus } from "../../../store/RequestStatus";
import UserSelectors from "../../../store/user/UserSelectors";
import CommentEditor from "./CommentEditor/CommentEditor";
import CommentsFooter from "./CommentsFooter";

type EditCommentFormPropsType = {
    onPost: (body: IUpsertCommentDto) => void;
    onCancel?: () => void;
    comment: ICommentModel;
    usersToMention: IUser[];
};

type CreateCommentFormPropsType = {
    onFocus: () => void;
    onPost: (body: IUpsertCommentDto) => void;
    status: RequestStatus;
    usersToMention: IUser[];
    appContext: string;
};

export const CreateCommentForm = ({ onFocus, onPost, status, usersToMention, appContext }: CreateCommentFormPropsType) => {
    const { t } = useTranslation();
    const currentUserId = useSelector(UserSelectors.getUserInfo)?.info?.id;

    const [editorComment, setEditorComment] = useState(null);
    const [canPost, setCanPost] = useState(false);

    const getDataFunctionRef = useRef(null);
    const previousStatusRef = useRef(status);

    const onEditorChange = useCallback((state: EditorState) => {
        setCanPost(state.getCurrentContent().hasText());
    }, []);

    const onSubmit = useCallback(() => {
        if (status === RequestStatus.loading) {
            return;
        }
        const data = getDataFunctionRef.current();
        if (!data.textFinal.trim()) {
            return;
        }

        const body: IUpsertCommentDto = {
            appId: APP_ID,
            appContext: appContext,
            creatorId: currentUserId,
            mentionedUserIds: data.mentionedUsers,
            comment: data.textFinal,
        };
        onPost(body);
    }, [appContext, currentUserId, onPost, status]);

    useEffect(() => {
        if (previousStatusRef.current !== status && status === RequestStatus.success) {
            const emptyComment: ICommentModel = {
                id: "",
                content: "",
                isDeleted: false,
                updateStatus: RequestStatus.undefined,
                createdTime: "",
                mentionedUserIds: [],
                creatorId: "",
                referredEntityId: "",
            };
            setEditorComment(emptyComment);
        }
        previousStatusRef.current = status;
    }, [status]);

    return (
        <Stack direction="column" onClick={onFocus}>
            <CommentEditor getContentsRef={getDataFunctionRef} comment={editorComment} onChange={onEditorChange} usersToMention={usersToMention} />
            <CommentsFooter
                label={t("report.comments.info")}
                primaryButtonDisabled={!canPost || status === RequestStatus.loading}
                primaryButtonText={t("report.comments.submitButtonLabel")}
                primaryButtonAction={onSubmit}
            />
        </Stack>
    );
};

export const EditCommentForm = ({ onPost, comment, onCancel, usersToMention }: EditCommentFormPropsType) => {
    const { t } = useTranslation();
    const getDataFunctionRef = useRef(null);
    const [editorComment, setEditorComment] = useState(comment);
    const [canEdit, setCanEdit] = useState(comment?.content?.length > 0);

    useEffect(() => {
        if (comment.updateStatus !== RequestStatus.loading) {
            setEditorComment(comment);
        }
    }, [comment]);

    const onSubmit = useCallback(() => {
        const data = getDataFunctionRef.current();
        if (!data.textFinal.trim()) {
            return;
        }

        const body: IUpsertCommentDto = {
            id: comment.id,
            appId: APP_ID,
            appContext: comment.appContext,
            creatorId: comment.creatorId,
            mentionedUserIds: data.mentionedUsers,
            comment: data.textFinal,
        };
        onPost(body);
    }, [comment, onPost]);

    const onEditorChange = useCallback(() => {
        setTimeout(() => {
            if (getDataFunctionRef?.current) {
                const newText = getDataFunctionRef.current().textFinal.trim();
                setCanEdit(newText && newText !== comment.content);
            }
        });
    }, [comment, getDataFunctionRef]);

    return (
        <Stack direction="column" mt="M">
            <CommentEditor getContentsRef={getDataFunctionRef} comment={editorComment} onChange={onEditorChange} usersToMention={usersToMention} />
            <CommentsFooter
                primaryButtonText={t("report.comments.editButtonLabel")}
                primaryButtonDisabled={!canEdit || comment.updateStatus === RequestStatus.loading}
                primaryButtonAction={onSubmit}
                secondaryButtonText={t("common.cancel")}
                secondaryButtonDisabled={comment.updateStatus === RequestStatus.loading}
                secondaryButtonAction={onCancel}
            />
        </Stack>
    );
};
