import { Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { getFullAddressOrCoordinates, ISiteObject } from "../../../../models/SiteObjectModel";
import { TextWithIcon } from "../TextWithIcon";

type Props = {
    siteObject: ISiteObject;
};

const StackStyled = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

export const LocationHeaderLine = ({ siteObject }: Props) => {
    const fullAddress = getFullAddressOrCoordinates(siteObject);
    return (
        <StackStyled direction="row" data-testid="detail-view-site-section">
            <TextWithIcon id="location-header__site-name" bold color="primary" iconSize="S" variant="Location">
                {siteObject.displayName}
            </TextWithIcon>
            <Separator variant="vertical" ml="S" mr="S" />
            <Text micro color="secondary">
                {fullAddress}
            </Text>
        </StackStyled>
    );
};
