import { Image } from "pure-react-carousel";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

import { IImageData, ImageSizePaths } from "../../models/ReportImagesModels";
import { useReportImages } from "../../store/reports/ReportsHooks";
import { Loading } from "../shared/Loading";
import Chevron from "./CarouselChevron";
import { CarouselProviderStyled, CloseIconStyled, ModalContainer, ModalContent, SliderStyled, SlideStyled } from "./CarouselFullScreen.styles";
import CarouselSlideCounter from "./CarouselSlideCounter";

interface CarouselFullScreenProps {
    imagesData: IImageData[];
    imageIndex: number;
    onClose: () => void;
    sharingId?: string;
}

const CarouselFullScreen = ({ imagesData, imageIndex, onClose, sharingId }: CarouselFullScreenProps) => {
    const { reportImages, loadingMultiple } = useReportImages(imagesData, ImageSizePaths.Large, sharingId, true);
    const isCarouselVisible = !loadingMultiple && reportImages.length > 0;

    useOnEscapeEventListener(onClose, null, isCarouselVisible, true);

    return (
        <ModalContainer>
            <CloseIconStyled data-testid="close-icon" mode="stateless" icon="Close" iconSize={"L"} onClick={onClose} />
            <ModalContent>
                {loadingMultiple ? (
                    <Loading />
                ) : (
                    <>
                        {reportImages.length > 0 && (
                            <CarouselProviderStyled
                                naturalSlideWidth={1000}
                                naturalSlideHeight={1000}
                                totalSlides={reportImages.length}
                                visibleSlides={1}
                                dragEnabled={false}
                                isIntrinsicHeight={true}
                                currentSlide={imageIndex}
                                infinite
                            >
                                {reportImages.length > 1 && <Chevron direction="left" />}
                                <SliderStyled>
                                    {reportImages.map((item, index) => (
                                        <SlideStyled data-index={index} index={index} key={index}>
                                            <Image data-testid="image" hasMasterSpinner={false} src={item.image} />
                                        </SlideStyled>
                                    ))}
                                </SliderStyled>
                                {reportImages.length > 1 && <Chevron direction="right" />}
                                <CarouselSlideCounter />
                            </CarouselProviderStyled>
                        )}
                    </>
                )}
            </ModalContent>
        </ModalContainer>
    );
};

export default CarouselFullScreen;
