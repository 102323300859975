import { Separator } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { gdprCompliantCountries } from "../../../../data/regions";
import { TextWithIcon } from "../TextWithIcon";

type Props = {
    countryCode: string;
    reportedBy: string;
    isTour?: boolean;
};

export const ReportedBy = ({ countryCode, reportedBy, isTour = false }: Props) => {
    const { t } = useTranslation();

    const tooltip = useMemo(() => {
        const isCountryGdprCompliant = gdprCompliantCountries.includes(countryCode.toLowerCase());
        if (isCountryGdprCompliant) {
            return t("reportdetails.report.missingGuardNameGpdr");
        }
        if (!reportedBy) {
            return t("reportdetails.report.missingGuardName");
        }
        return "";
    }, [reportedBy, countryCode, t]);

    const tooltipLabel = isTour ? t("reportdetails.performedby") : t("reportdetails.reportedby");
    return (
        <>
            <TextWithIcon dataTestId="detail-view-reported-by-container" id="reported-by__text" color="secondary" variant="User" iconSize="S" tooltip={tooltip}>
                {tooltip ? tooltipLabel : reportedBy}
            </TextWithIcon>
            <Separator variant="vertical" mh="S" />
        </>
    );
};
