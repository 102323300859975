import { RSAA } from "redux-api-middleware";

import { REPORT_CATEGORIES_FAILURE, REPORT_CATEGORIES_REQUEST, REPORT_CATEGORIES_SUCCESS } from "./types";

const fetchReportCategories = () => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/global/reportCategories`,
        method: "GET",
        types: [
            {
                type: REPORT_CATEGORIES_REQUEST,
            },
            {
                type: REPORT_CATEGORIES_SUCCESS,
            },
            {
                type: REPORT_CATEGORIES_FAILURE,
            },
        ],
    },
});

export default {
    fetchReportCategories,
};
