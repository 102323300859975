import { Badge, SignalColor } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { isNullOrUndefined } from "../../../helpers/PropertiesValueHelper";
import { getSeverityLevelTranslationKey } from "../../../helpers/SeverityLevelHelper";
import { ReportsListItemType } from "../../../models/ReportModel";

export const getSeverityColor = (severityLevel: number | string): SignalColor => {
    switch (severityLevel) {
        case 0:
        case "Info":
        case 1:
        case "Low":
            return "neutral";
        case 2:
        case "Moderate":
            return "low";
        case 3:
        case "High":
            return "medium";
        case 4:
        case "Severe":
            return "high";
    }
};

const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    align-self: flex-start;
`;

type SeverityBadgeProps = {
    severityLevel: ReportsListItemType["severityLevel"];
};

export const SeverityBadge = ({ severityLevel }: SeverityBadgeProps) => {
    const { t } = useTranslation();
    const isInfo = severityLevel === 0 || severityLevel === "Info";

    if (isNullOrUndefined(severityLevel)) {
        return null;
    }
    return (
        <StyledBadge
            data-testid="badge-severity-level"
            label={t(`${getSeverityLevelTranslationKey(severityLevel)}`)}
            mode={isInfo ? "contained" : "outlined"}
            color={getSeverityColor(severityLevel)}
        />
    );
};
