import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";
import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { ReactElement } from "react";
import styled from "styled-components";

import { smallText } from "../../../../styles/templates/Typography";

const SuggestionEntryStyled = styled.div<{ isFocused?: boolean }>`
    padding: ${Spacing.XXS}px ${Spacing.S}px ${Spacing.XXS}px ${Spacing.S}px;
    background: ${(props) => (props.isFocused ? `${Palette.Navy400}` : `${Palette.Navy600}`)};
    cursor: pointer;

    @media (max-width: ${Breakpoints.XS}) {
        ${smallText}
        padding: ${Spacing.XS}px ${Spacing.S}px ${Spacing.XS}px ${Spacing.S}px;
    }
`;

export const SuggestionEntry = (props: EntryComponentProps): ReactElement => {
    const { mention, searchValue, isFocused, ...parentProps } = props;
    const splittedEmail = mention.email.split(new RegExp(`(${searchValue})`, "gi"));
    const splittedFullName = mention.fullName ? mention.fullName.split(new RegExp(`(${searchValue})`, "gi")) : [];
    return (
        <SuggestionEntryStyled {...parentProps} isFocused={isFocused}>
            {splittedEmail.map((e, i) => (
                <span key={i} style={{ fontWeight: e.toLowerCase() === searchValue.toLowerCase() ? "bold" : "normal" }}>
                    {e}
                </span>
            ))}
            {splittedFullName.length > 0 ? (
                <span>
                    {" "}
                    (
                    {splittedFullName.map((e, i) => (
                        <span key={i} style={{ fontWeight: e.toLowerCase() === searchValue.toLowerCase() ? "bold" : "normal" }}>
                            {e}
                        </span>
                    ))}
                    )
                </span>
            ) : null}
        </SuggestionEntryStyled>
    );
};
