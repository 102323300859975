import { Skeleton, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

const SkeletonRowStyled = styled(Skeleton)`
    margin-bottom: ${Spacing.XS}px;
`;

export const Loader = () => {
    return (
        <>
            <SkeletonRowStyled mode="rectangular" height={48} />
            <SkeletonRowStyled mode="rectangular" height={64} />
            <SkeletonRowStyled mode="rectangular" height={64} />
            <SkeletonRowStyled mode="rectangular" height={64} />
            <SkeletonRowStyled mode="rectangular" height={64} />
            <SkeletonRowStyled mode="rectangular" height={48} />
        </>
    );
};
