import { Breakpoints, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const AuthAppContainer = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
    background-color: ${Palette.Navy900};

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        position: fixed;
        flex-direction: column-reverse;
    }
`;

export const Main = styled.div`
    display: flex;
    height: 100%;
    flex: 1 1 0%;
    min-width: 0;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        flex-grow: 1;
        overflow-y: auto;
    }
`;

export const UnauthenticatedMain = styled(Main)`
    width: 100%;
`;
