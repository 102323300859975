import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

import { consolidateEvents } from "../helpers";
import { CustomizedCategories } from "../TrendingEvents.types";
import { useCategoriesWithLevel, useFetchDbEvents, useFetchDeviations } from "./";

export const useTrendingEvents = (
    selectedCategories: CustomizedCategories,
    monthsBack: number,
): {
    isLoading: boolean;
    events: ITrendingEvent[];
} => {
    const { siteIds } = useFilteredSites();
    const [trendingEvents, setTrendingEvents] = useState<ITrendingEvent[]>([]);

    const categoriesWithLevel = useCategoriesWithLevel(selectedCategories);

    const { events: eventsFromDb, isLoading: isFetchDbEventsLoading } = useFetchDbEvents(siteIds, selectedCategories, monthsBack);
    const { deviations, isLoading: isFetchDeviationsLoading } = useFetchDeviations(siteIds, categoriesWithLevel, monthsBack);

    const consolidatedEvents = useMemo(() => {
        if (!isFetchDbEventsLoading && !isFetchDeviationsLoading) {
            return consolidateEvents(eventsFromDb, deviations);
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsFromDb, deviations]);

    useEffect(() => {
        if (!isFetchDbEventsLoading && !isFetchDeviationsLoading && !isEqual(trendingEvents, consolidatedEvents)) {
            setTrendingEvents(consolidatedEvents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consolidatedEvents, isFetchDbEventsLoading, isFetchDeviationsLoading, trendingEvents]);

    const isLoading = isFetchDbEventsLoading || isFetchDeviationsLoading;

    return { isLoading, events: trendingEvents };
};
