import { Graph, Palette } from "@secuis/ccp-react-components";
import i18next from "i18next";
import { differenceInHours, differenceInMilliseconds, differenceInMinutes } from "src/helpers/date";
import { CalloutLog, CalloutStatus } from "src/models/ReportModel";

import { HIDDEN_STATUSES } from "./Timeline.constants";

export const findTimeByStatusTag = (statusTag: CalloutStatus, logs: CalloutLog[]): string => logs.find((val) => val.status.tag === statusTag)?.createdOn;

export const formatTimelineDuration = (startStatusTag: CalloutStatus, endStatusTag: CalloutStatus, logs: CalloutLog[]) => {
    const start = findTimeByStatusTag(startStatusTag, logs);
    const end = findTimeByStatusTag(endStatusTag, logs);
    if (start && end) {
        const hoursCount = differenceInHours(new Date(end), new Date(start));
        const minutesCount = differenceInMinutes(new Date(end), new Date(start));
        const minutes = i18next.t("common.minutes", { count: minutesCount % 60 });

        return {
            hours: hoursCount ? i18next.t("common.hours", { count: hoursCount }) : null,
            minutes,
        };
    }
    return null;
};

export const getColorByStatusTag = (statusTag: CalloutStatus, logs: CalloutLog[], isPdf: boolean = false) => {
    let arrivalTag;
    if (logs.some((log) => log.status.tag === CalloutStatus.arrivedOnSite)) {
        arrivalTag = CalloutStatus.arrivedOnSite;
    } else if (logs.some((log) => log.status.tag === CalloutStatus.onRoute)) {
        arrivalTag = CalloutStatus.onRoute;
    } else if (logs.some((log) => log.status.tag === CalloutStatus.accepted)) {
        arrivalTag = CalloutStatus.accepted;
    } else {
        arrivalTag = null;
    }
    const departureTag = CalloutStatus.departed;
    const arrivalTime = findTimeByStatusTag(arrivalTag, logs);
    const departureTime = findTimeByStatusTag(departureTag, logs);
    const hasArrivalAndDeparture = arrivalTime && departureTime;
    if (!hasArrivalAndDeparture) {
        return isPdf ? Graph.pressed.dataSeries1 : Graph.dataSeries1;
    }
    const time = findTimeByStatusTag(statusTag, logs);
    const isAfterArrival = differenceInMilliseconds(new Date(time), new Date(arrivalTime)) >= 0;
    const isBeforeDeparture = differenceInMilliseconds(new Date(time), new Date(departureTime)) < 0;
    const isAfterDeparture = differenceInMilliseconds(new Date(time), new Date(departureTime)) >= 0;
    if (isAfterDeparture) {
        return Palette.Navy300;
    }
    if (isAfterArrival && isBeforeDeparture) {
        return isPdf ? Graph.pressed.dataSeries2 : Graph.dataSeries2;
    }
    return isPdf ? Graph.pressed.dataSeries1 : Graph.dataSeries1;
};

export const getTimeValueProps = (timeStr: string) => {
    const [count, label] = timeStr.split(" ");
    return {
        count,
        label,
    };
};

// Currently we receive formats AR_000 and AR-000
// proper format is AR_000
export const normalizeLogTags = (logs: (Omit<CalloutLog, "status"> & { status: { name: string; tag: string | CalloutStatus } })[]): CalloutLog[] => {
    return logs.reduce((acc, log) => {
        const normalizedLog = {
            ...log,
            status: {
                ...log.status,
                tag: log.status?.tag?.replace("-", "_") as CalloutStatus,
            },
        };
        // Business requirement: Hidden statuses
        if (HIDDEN_STATUSES.includes(normalizedLog.status.tag)) {
            return acc;
        }
        return [...acc, normalizedLog];
    }, []);
};
