import { Palette } from "@secuis/ccp-react-components";

const TEXT_MARGIN = 20;
const CORRECTION_ANGLE = 10;

type PropsType = {
    cx: number;
    cy: number;
    midAngle: number;
    outerRadius: number;
    fill: string;
    value: number;
    label: string;
    direction?: "up" | "down";
};

export const ChartLabel = ({ cx, cy, midAngle, outerRadius, fill, value, label, direction }: PropsType): JSX.Element => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + TEXT_MARGIN;
    const correction = direction === "down" ? CORRECTION_ANGLE : -CORRECTION_ANGLE;
    const angle = direction ? midAngle + correction : midAngle;
    const sin = Math.sin(-RADIAN * angle);
    const cos = Math.cos(-RADIAN * angle);
    const x = cx + radius * cos * 1.2;
    const y = cy + radius * sin;

    return (
        <>
            <rect x={x - 30} y={y - 10} width="59" height="20" rx="10" fill={fill} />
            <text
                data-testid="incident-distribution-chart-label"
                fontSize="12px"
                x={x}
                y={y + 1}
                fontWeight={700}
                dominantBaseline="middle"
                textAnchor="middle"
                fill={Palette.Navy750}
            >
                {label} {value} %
            </text>
        </>
    );
};
