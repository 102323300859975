import { IconType } from "@secuis/ccp-react-components";
import { ReportTemplateEnum } from "src/models/ReportModel";

import { CategoryLevel1Keys } from "../../../models/ReportCategoryModel";

export const REPORT_CATEGORY_ICON_MAP: Record<string, IconType> = {
    [CategoryLevel1Keys.criminalActOrSuspiciousBehavior]: "CriminalAct",
    [CategoryLevel1Keys.facility]: "Facility",
    [CategoryLevel1Keys.healthAndSafety]: "HealthAndSafety",
    [CategoryLevel1Keys.peopleAndAssets]: "PeopleAndAssets",
    [CategoryLevel1Keys.internalSecuritas]: "MobileGuarding",
    noCategory: "NoCategory",
};

export const EVENTS_OVERVIEW_REPORTS_TYPES = [
    ReportTemplateEnum.incident,
    ReportTemplateEnum.alarmResponse,
    ReportTemplateEnum.callout,
    ReportTemplateEnum.assignment,
    ReportTemplateEnum.tourException,
    ReportTemplateEnum.tourMultiException,
];
