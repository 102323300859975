import { Spacing, Text, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { flexColumnJustifyCenterAlignCenter } from "../../styles/templates/Flex";
import { WrapperCenter } from "./common.styled";

const WrapperInfo = styled.div`
    width: 295px;
    ${flexColumnJustifyCenterAlignCenter};
    padding: ${Spacing.XS}px;
    gap: ${Spacing.XL}px;
    text-align: center;
`;

const FailurePage = () => {
    const { t } = useTranslation();

    return (
        <WrapperCenter>
            <Title>{t("failure.title")}</Title>
            <WrapperInfo>
                <Text>{t("failure.subtitle")}</Text>
            </WrapperInfo>
        </WrapperCenter>
    );
};

export default FailurePage;
