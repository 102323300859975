import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { TaskReportFields } from "../../../models/reportTemplates/Task";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getTaskReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getUnknownFieldsComponents(report, sharedReportId, Object.values(TaskReportFields), true);
    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(getFieldValueByFieldName(TaskReportFields.taskDescription, report) || report.images.length > 0, () => ({
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.detailedInfo.taskReportDescription",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(TaskReportFields.taskDescription, report),
                        sharedReportId,
                        imagesData: report.images,
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(unknownFields.length, () => {
            return {
                component: DynamicTemplateSection,
                props: {
                    label: "reportdetails.detailedInfo.taskReportSummary",
                    collapsedLabel: "reportdetails.summary.moreDetails",
                    expandedLabel: "reportdetails.summary.lessDetails",
                },
                children: unknownFields,
            };
        }),
    ];
};
