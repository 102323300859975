export const trimCamelCaseString = (camelCaseString: string): string => {
    return camelCaseString.replace(/[^a-zA-Z0-9]/g, "");
};

export const splitCamelCaseToSentence = (camelCaseString: string): string => {
    const sentence = camelCaseString
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
        .replace(/([,])([A-Z])/g, "$1 $2")
        .replace(/([(])([A-Z])/g, " $1$2");
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const nameof = <T>(name: keyof T) => name;

export const splitByLastChar = (str: string, char: string): string[] => {
    const lastIndex = str.lastIndexOf(char);

    if (lastIndex === -1) {
        return [str];
    } else {
        const part1 = str.substring(0, lastIndex);
        const part2 = str.substring(lastIndex + 1);
        return [part1, part2];
    }
};
