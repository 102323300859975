import { Color, Icon, Palette, SignalColor, Stack, Text } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppGuideStep } from "./AppGuideSteps";
import { ButtonsContainer, CloseButtonWrapper, TooltipContainer } from "./AppGuideTooltip.styles";

export type AppGuideColor = "light" | "dark" | "purple";

interface ColorScheme {
    backgroundColor: string;
    backgroundColorHover: string;
    textColor: Color | SignalColor;
    borderColor: string;
}

const lightVariant: ColorScheme = {
    backgroundColor: Palette.Navy100,
    backgroundColorHover: Palette.Navy200,
    textColor: "dark",
    borderColor: Palette.Navy800,
};

const darkVariant: ColorScheme = {
    backgroundColor: Palette.Navy750,
    backgroundColorHover: Palette.Navy800,
    textColor: "primary",
    borderColor: Palette.Navy300,
};

const purpleVariant: ColorScheme = {
    backgroundColor: Palette.Purple300,
    backgroundColorHover: Palette.Purple400,
    textColor: "dark",
    borderColor: Palette.Navy800,
};

const getColorScheme = (color: AppGuideColor): ColorScheme => {
    switch (color) {
        case "light":
            return lightVariant;
        case "dark":
            return darkVariant;
        case "purple":
            return purpleVariant;
    }
};

type Props = {
    step: AppGuideStep;
    closeProps: any;
    tooltipProps: any;
};

export const AppGuideTooltip = ({ step, closeProps, tooltipProps }: Props) => {
    const [colorScheme, setColorScheme] = useState<ColorScheme>(lightVariant);
    const { t } = useTranslation();

    useEffect(() => {
        // popup target item from blur
        const targetElement = document.querySelector(step.target.toString()) as HTMLElement;
        const navbarElement = document.getElementById("navbar-container") as HTMLElement;

        if (targetElement) {
            targetElement.classList.add("spotlightAppGuide");
        }
        if (step.navbarFix && navbarElement) {
            navbarElement.style.zIndex = "105";
        }

        const arrow = document.querySelector(".__floater__arrow").getElementsByTagName("polygon")[0];

        if (arrow) {
            arrow.style.fill = colorScheme.backgroundColor;
        }

        return () => {
            if (targetElement) {
                targetElement.classList.remove("spotlightAppGuide");
            }
            if (step.navbarFix && navbarElement) {
                navbarElement.style.zIndex = "1";
            }
        };
    }, [colorScheme.backgroundColor, step.navbarFix, step.target]);

    useEffect(() => {
        setColorScheme(getColorScheme(step.theme));
    }, [step.theme]);

    return (
        <TooltipContainer {...tooltipProps} backgroundColor={colorScheme.backgroundColor}>
            <Stack direction="column" p="S" gap="XXXS">
                <Stack gap="XS">
                    <Icon variant={step.icon} size="L" color={colorScheme.textColor} />
                    <Text color={colorScheme.textColor} bold>
                        {step.title}
                    </Text>
                </Stack>
                <Text color={colorScheme.textColor} small>
                    {step.content}
                </Text>
            </Stack>
            <ButtonsContainer borderColor={colorScheme.borderColor}>
                <CloseButtonWrapper {...closeProps} backgroundColorHover={colorScheme.backgroundColorHover}>
                    <Text small bold centered color={colorScheme.textColor}>
                        {t(step.closeLabel ?? "appGuide.closeButton")}
                    </Text>
                </CloseButtonWrapper>
            </ButtonsContainer>
        </TooltipContainer>
    );
};
