import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { Main } from "./Authentication.styles";
import { AppGuideProvider } from "./components/AppGuide/AppGuideProvider";
import { useAuthContext } from "./components/AuthGate";
import DatabaseProvider from "./components/DatabaseProvider";
import { FeatureUserPrompts } from "./components/FeatureUserPrompts";
import { Navbar } from "./components/Navbar/Navbar";
import { OnboardingContext } from "./components/Onboarding/OnboardingModalProvider";
import { Loading } from "./components/shared/Loading";
import { DemoResolver } from "./DemoResolver";
import { useApplicationData } from "./store/app/AppHooks";
import ClientSwitcherSelectors from "./store/clientSwitcher/ClientSwitcherSelectors";
import LocationsSelectors from "./store/locations/LocationsSelectors";
import { isFinished } from "./store/RequestStatus";

export const AuthenticatedApp = () => {
    const { user } = useAuthContext();
    const onboardingContext = useContext(OnboardingContext);
    const { loaded: appDataLoaded, loadApplicationData } = useApplicationData();
    const { descendantsFetchStatus: authLocationsRequestStatus } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const userClientAuthorizationStatus = useSelector(ClientSwitcherSelectors.getUserClientAuthorizationStatus);
    const isLoading = !appDataLoaded || !isFinished(authLocationsRequestStatus);
    const initializedRef = useRef(false);

    useEffect(() => {
        if (user && isFinished(authLocationsRequestStatus) && isFinished(userClientAuthorizationStatus)) {
            loadApplicationData();
            initializedRef.current = true;
        }
    }, [user, authLocationsRequestStatus, loadApplicationData, userClientAuthorizationStatus]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <DemoResolver>
                    <DatabaseProvider>
                        <Navbar />
                        <AppGuideProvider disabled={onboardingContext.onboardingVisible}>
                            <Main>
                                <Outlet />
                            </Main>
                        </AppGuideProvider>
                        <FeatureUserPrompts />
                    </DatabaseProvider>
                </DemoResolver>
            )}
        </>
    );
};
