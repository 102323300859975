import i18next from "i18next";

import { DynamicReportPropertyType } from "../../models/ReportModel";
import { VehicleInspectionFields } from "../../models/reportTemplates/VehicleInspection";
import { getFieldValueByFieldName } from "./dynamicTemplatePropertiesHelper";

const YES = "yes";
const NO = "no";

export function getVehicleInspectionReportProperties(properties: DynamicReportPropertyType[]) {
    const getVehicleModel = () => {
        let vehicleModel = getFieldValueByFieldName(VehicleInspectionFields.vehicleModel, { properties });
        if (!vehicleModel) {
            vehicleModel = getFieldValueByFieldName(VehicleInspectionFields.modelCar, { properties });
        }
        return vehicleModel || i18next.t("common.noDataFound");
    };
    return {
        licensePlate: getFieldValueByFieldName(VehicleInspectionFields.licensePlate, { properties }) || i18next.t("common.noDataFound"),
        vehicleModel: getVehicleModel(),
        inspectionCount: getVehicleInspectionCount({ properties }),
    };
}

function getVehicleInspectionCount(report: { properties: DynamicReportPropertyType[] }): string {
    const inspection_fail_QA: Record<string, string> = {
        [VehicleInspectionFields.exteriorDamage]: YES,
        [VehicleInspectionFields.interiorDamage]: YES,
        [VehicleInspectionFields.brokenCrackedWindows]: YES,
        [VehicleInspectionFields.isAllEmergencyEquipmentAvailable]: NO,
    };

    if (!getFieldValueByFieldName(VehicleInspectionFields.isAllEmergencyEquipmentAvailable, report)) {
        delete inspection_fail_QA[VehicleInspectionFields.isAllEmergencyEquipmentAvailable];
    }

    let failedInspectionsCount = 0;
    for (const [inspectionFieldName, inspectionFailFieldValue] of Object.entries(inspection_fail_QA)) {
        const inspectionFieldValue = getFieldValueByFieldName(inspectionFieldName, report);
        if (inspectionFieldValue && inspectionFieldValue.toLowerCase().trim() === inspectionFailFieldValue) {
            failedInspectionsCount++;
        }
    }

    const totalInspectionsCount = Object.keys(inspection_fail_QA).length;
    return `${failedInspectionsCount}/${totalInspectionsCount}`;
}
