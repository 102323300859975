import { createReducer } from "@reduxjs/toolkit";
import { subDays } from "src/helpers/date";

import {
    FILTER_APPLY_FILTERS,
    FILTER_APPLY_PERSISTED_STATE,
    FILTER_CLEAR_CATEGORIES,
    FILTER_CLEAR_REGIONS,
    FILTER_SELECT_REGIONS,
    FILTER_SELECT_REPORT_TYPE,
    FILTER_SELECT_SEVERITY_LEVELS,
    FILTER_SELECT_SITE_OBJECTS,
    FILTER_SELECT_TO_DATE,
    IFilterState,
    SHOW_ONLY_UNREAD_CHANGE,
} from "./types";

const DEFAULT_FILTER_START_DATE_OFFSET_IN_DAYS = 29;

export const defaultState: IFilterState = {
    selectedRegions: [],
    selectedSeverityLevels: [],
    selectedCategories1: [],
    selectedCategories2: [],
    selectedCategories3: [],
    selectedReportTypes: [],
    selectedSiteLocations: [],
    selectedStartDate: subDays(new Date(new Date().setHours(0, 0, 0, 0)), DEFAULT_FILTER_START_DATE_OFFSET_IN_DAYS),
    selectedEndDate: new Date(new Date().setHours(23, 59, 59, 999)),
    showOnlyUnread: null,
};

const FilterReducer = createReducer(defaultState, {
    [FILTER_SELECT_REGIONS]: (state, action) => {
        state.selectedRegions = action.payload;
    },
    [FILTER_APPLY_FILTERS]: (state, action) => {
        state.selectedSeverityLevels = action.payload.selectedSeverityLevels;
        state.selectedCategories1 = action.payload.selectedCategoriesOne;
        state.selectedCategories2 = action.payload.selectedCategoriesTwo;
        state.selectedCategories3 = action.payload.selectedCategoriesThree;
        state.selectedReportTypes = action.payload.selectedReportTypes;
        state.selectedSiteLocations = action.payload.selectedSiteLocations;
        state.showOnlyUnread = action.payload.showOnlyUnread;
        state.selectedRegions = action.payload.selectedRegions;
    },
    [FILTER_APPLY_PERSISTED_STATE]: (state, action) => {
        state.selectedRegions = action.payload.selectedRegions;
        state.selectedSeverityLevels = action.payload.selectedSeverityLevels || [];
        state.selectedCategories1 = action.payload.selectedCategory1 || [];
        state.selectedCategories2 = action.payload.selectedCategory2 || [];
        state.selectedCategories3 = action.payload.selectedCategory3 || [];
        state.selectedReportTypes = action.payload.selectedReportTypes || [];
        state.selectedSiteLocations = action.payload.selectedSiteLocations || [];
        state.showOnlyUnread = action.payload.showOnlyUnread;

        if (action.payload.selectedStartDate) {
            state.selectedStartDate = action.payload.selectedStartDate;
        }

        if (action.payload.selectedEndDate) {
            state.selectedEndDate = action.payload.selectedEndDate;
        }
    },
    [SHOW_ONLY_UNREAD_CHANGE]: (state, action) => {
        state.showOnlyUnread = action.payload;
    },
    [FILTER_SELECT_TO_DATE]: (
        state,
        action: {
            payload: { selectedStartDate: Date; selectedEndDate: Date };
        },
    ) => {
        state.selectedStartDate = action.payload.selectedStartDate;
        state.selectedEndDate = action.payload.selectedEndDate;
    },
    [FILTER_SELECT_SEVERITY_LEVELS]: (state, action) => {
        state.selectedSeverityLevels = action.payload;
    },
    [FILTER_SELECT_SITE_OBJECTS]: (state, action) => {
        state.selectedSiteLocations = action.payload;
    },
    [FILTER_SELECT_REPORT_TYPE]: (state, action) => {
        state.selectedReportTypes = action.payload;
    },
    [FILTER_CLEAR_CATEGORIES]: (state) => {
        state.selectedCategories1 = [];
        state.selectedCategories2 = [];
        state.selectedCategories3 = [];
    },
    [FILTER_CLEAR_REGIONS]: (state) => {
        state.selectedRegions = [];
    },
});

export default FilterReducer;
