import "pure-react-carousel/dist/react-carousel.es.css";

import { Breakpoints, ButtonIcon, Palette, Spacing } from "@secuis/ccp-react-components";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import styled from "styled-components";

import { flexColumnCenter } from "../../styles/templates/Flex";

export const ModalContainer = styled.div`
    z-index: 9999;
    position: fixed;
    overflow-y: hidden;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${Palette.Navy900};

    @media (hover: none) and (pointer: coarse) {
        background-color: ${Palette.Navy750};
    }
`;

export const ModalContent = styled.div`
    ${flexColumnCenter}
    max-height: 100%;
    width: 100%;
`;

export const CarouselProviderStyled = styled(CarouselProvider)`
    height: 100%;

    @media (max-width: ${Breakpoints.XS}) {
        span {
            position: relative;
            bottom: ${Spacing.S}px;
        }
    }
`;

export const SliderStyled = styled(Slider)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .carousel__slider-tray-wrapper {
        max-height: 100%;
    }
    .carousel__slider-tray {
        max-height: 100%;
        padding-top: ${Spacing.XL}px;
        padding-bottom: ${Spacing.XXXL}px;

        @media (max-height: 1112px) {
            padding-top: ${Spacing.XXXL}px;
            padding-bottom: ${Spacing.XXXL}px;
        }

        @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        @media (max-height: 1112px) and (min-aspect-ratio: 7/6) and (max-aspect-ratio: 12/7) and (hover: none) and (pointer: coarse) {
            padding-top: ${Spacing.XL}px;
            padding-bottom: ${Spacing.XL}px;
        }
    }
    outline: none;
    & > ul,
    div {
        outline: none;
    }
`;

export const SlideStyled = styled(Slide)`
    img {
        object-fit: scale-down;
    }

    @media (max-width: ${Breakpoints.XS}) {
        img {
            max-height: ${Spacing.XL * 10}px;
        }
    }

    @media (max-width: ${Breakpoints.XS}) {
        path {
            fill: ${Palette.Navy300};
        }
    }

    .carousel__inner-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const CloseIconStyled = styled(ButtonIcon)`
    position: absolute;
    top: ${Spacing.M}px;
    right: ${Spacing.M}px;
    path {
        fill: ${Palette.White};
    }
    cursor: pointer;
    z-index: 2;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            path {
                fill: ${Palette.Navy300};
            }
        }
    }

    @media (max-width: ${Breakpoints.XS}) {
        path {
            fill: ${Palette.Navy300};
        }
    }
`;
