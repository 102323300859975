import { Stack, Text } from "@secuis/ccp-react-components";

import { TrendIndicator } from "../TrendIndicator";

type TotalSummaryProps = {
    icon?: JSX.Element;
    title: string;
    historicalCount: number;
    currentCount: number;
    isAbsoluteValue?: boolean;
};

export const TotalPerformanceTemplate = ({ icon, title, historicalCount, currentCount, isAbsoluteValue }: TotalSummaryProps) => {
    return (
        <Stack display="flex" direction="column">
            <Stack display="flex" direction="row" alignItems="center" gap="XXS">
                {icon}
                <Text small color="neutral">
                    {title}
                </Text>
            </Stack>
            <Stack display="flex" direction="row" alignItems="center" mv="XS">
                <TrendIndicator startValue={historicalCount} endValue={currentCount} isAbsoluteValue={isAbsoluteValue} />
            </Stack>
        </Stack>
    );
};
