import { EmptyState as EmptyStateDefault, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

export const EmptyState = () => {
    const { t } = useTranslation();

    return (
        <EmptyStateDefault icon="GraphIndicator" title={t("common.noDataToDisplay")} data-testid="matrix-empty-state">
            <Text>{t("incidentMatrix.matrix.emptyState.subtitle")}</Text>
        </EmptyStateDefault>
    );
};
