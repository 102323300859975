export const getValueFromProperty = (dto, propertyName) => {
    if (dto.properties[propertyName] && dto.properties[propertyName].length) {
        return dto.properties[propertyName][0].value;
    }
    return null;
};

export const isNullOrUndefined = (value) => {
    return value === undefined || value === null;
};
