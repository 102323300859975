import { useEffect } from "react";
import { useDispatch } from "react-redux";

import type { IMixpanelPageViewAdditionalProperties, PageName } from "../../models/tracking/pageView";
import TrackingActions from "./TrackingActions";

export const useTrackPageVisit = (pageName: PageName, additionalData?: IMixpanelPageViewAdditionalProperties) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TrackingActions.trackPageVisit(pageName, additionalData));
    }, [additionalData, dispatch, pageName]);
};
