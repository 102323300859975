import { keyBy } from "lodash";

import { featureFlags } from "../../../../../data/featureFlags";
import { useApiRequest } from "../../../../../hooks/accessApi";
import { useFeatureFlag } from "../../../../../hooks/featureFlags";
import { getApiVersion, parseDateRangeRequestBody } from "../../../../../store/insights/helpers";
import { TourSessions } from "../../../../../store/insights/InsightsPatrolTasksModel";

export const useTourSessionsPerSite = (siteIds: string[], startDate: Date, endDate: Date) => {
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const { data: tourData, isLoading } = useApiRequest<TourSessions[]>(
        `/${getApiVersion(areSiteZonesEnabled)}/global/insights/tour-sessions/filter`,
        "POST",
        parseDateRangeRequestBody(
            {
                siteIds,
                fromDateTime: startDate,
                toDateTime: endDate,
            },
            areSiteZonesEnabled,
        ),
    );

    const tourSessionsMap = keyBy(tourData, "locationId");

    return { tourSessionsMap, isLoading };
};
