import { formatToRawDate } from "../../helpers/date";
import { ISearchReportsRequestBody, ISearchReportsRequestBodyOld, SearchReportsRequestParams } from "../../models/ReportFilterModels";

//NOTE: Remove when fully migrated to reports api v2
export const parseReportsRequestBody = (
    body: SearchReportsRequestParams,
    useV2Api: boolean = false,
): ISearchReportsRequestBody | ISearchReportsRequestBodyOld => {
    const { from, to, ...payload } = body;

    if (useV2Api) {
        return { ...payload, from: formatToRawDate(from), to: formatToRawDate(to) };
    } else {
        return {
            ...payload,
            reportDateTimeStart: from.toISOString(),
            reportDateTimeEnd: to.toISOString(),
        };
    }
};
