import { Palette, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BannerWrapper = styled(Stack)`
    flex: 1;
    background-color: ${Palette.Navy100};
    justify-content: space-between;
    border-radius: 4px;
    &:active {
        background-color: ${Palette.Navy300};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${Palette.Navy200};
        }
    }
`;
