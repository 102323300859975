import { ButtonText, Graph, GraphColor, GraphIcon, GraphIconType, Palette, Spacing, Stack, Text, Theme } from "@secuis/ccp-react-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { formatToRawDate, startOfMonth, subMonths } from "src/helpers/date";
import { useFilterableReportTypes } from "src/hooks/ReportTypeHooks";
import { ReportTemplateEnum } from "src/models/ReportModel";
import { CustomizedCategories } from "src/pages/Insights/Events/TrendingEvents/TrendingEvents.types";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { ChartWrapper } from "../../../../../components/Insights/ChartWrapper";
import { EventsCountTick } from "../../../../../components/Insights/EventsCountTick";
import { MonthsTick } from "../../../../../components/Insights/MonthsTick";
import { ITrendingEvent } from "../../../../../store/insights/InsightsModel";
import InsightsSelectors from "../../../../../store/insights/InsightsSelectors";
import { LegendStyled } from "../../../Common.styled";
import { CustomIcon } from "./CustomIcon";
import { EventsCountTooltip } from "./EventsCountTooltip";
import { ChartCategoryType } from "./TrendingEventChart.types";
import { CHART_CATEGORIES } from "./TrendingEventsChart.constants";

type Props = {
    events: ITrendingEvent[];
    selectedCategories?: CustomizedCategories;
};

export interface ChartLegendConfig {
    color: GraphColor;
    iconVariant: GraphIconType;
    key: string;
    title: string;
}

export const TrendingEventsChart = ({ events, selectedCategories }: Props) => {
    const { isMobileLayout } = useInsightsPageContext();
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const { t } = useTranslation();
    const { defaultInsightsReportTypes } = useFilterableReportTypes();
    const trendingEventsReportTypes = useMemo(
        () => [...defaultInsightsReportTypes, ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
        [defaultInsightsReportTypes],
    );
    const startDate = startOfMonth(subMonths(new Date(), 6));
    const navigate = useNavigate();

    const redirectUrl = useMemo(() => {
        const selectedCategoriesLevel1 =
            selectedCategories?.level1Categories?.length > 0 ? `selectedCategoryOne=${selectedCategories.level1Categories.map((x) => x.value).join(",")}` : "";
        let selectedCategoriesLevel2 =
            selectedCategories?.level2Categories?.length > 0 ? `selectedCategoryTwo=${selectedCategories.level2Categories.map((x) => x.value).join(",")}` : "";
        const selectedCategoriesLevel3 =
            selectedCategories?.level3Categories?.length > 0
                ? `selectedCategoryThree=${selectedCategories.level3Categories.map((x) => x.value).join(",")}`
                : "";
        if (
            !selectedCategories ||
            [...selectedCategories.level1Categories, ...selectedCategories.level2Categories, ...selectedCategories.level3Categories].length === 0
        ) {
            const fieldNames = Object.keys(events[0]).filter((k) => k !== "month");
            selectedCategoriesLevel2 = `selectedCategoryTwo=${fieldNames.join(",")}`;
        }

        return [
            `/reports?selectedRegions=${selectedRegions.toString()}`,
            `selectedReportTypes=${trendingEventsReportTypes.join(",")}`,
            `selectedStartDate=${formatToRawDate(startDate)}`,
            `selectedEndDate=${formatToRawDate(new Date())}`,
            selectedCategoriesLevel1,
            selectedCategoriesLevel2,
            selectedCategoriesLevel3,
        ].join("&");
    }, [events, selectedCategories, selectedRegions, startDate, trendingEventsReportTypes]);

    const domain = useMemo(() => {
        if (!events?.length) {
            return [0, 0];
        }
        const boundaries = events.reduce(
            (minMax, next) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { month, ...dataPoints } = next;
                Object.keys(dataPoints).forEach((key) => {
                    if (minMax[0] === null || dataPoints[key] < minMax[0]) {
                        minMax[0] = dataPoints[key];
                    }
                    if (minMax[1] === null || dataPoints[key] > minMax[1]) {
                        minMax[1] = dataPoints[key];
                    }
                });
                return minMax;
            },
            [null, null],
        );

        if (boundaries[1] - boundaries[0] < 60) {
            // round min and max to prev/next 10
            return [Math.floor(boundaries[0] / 10) * 10, Math.ceil(boundaries[1] / 10) * 10];
        }

        // round min and max to prev/next 100
        const minDomain = Math.floor(boundaries[0] / 100) * 100;
        const maxDomain = Math.ceil(boundaries[1] / 100) * 100;

        return [minDomain, maxDomain];
    }, [events]);

    const yWidth = useMemo(() => {
        // approx y axis width is count of upper domain characters x 7
        return domain[1]?.toString()?.length * 7;
    }, [domain]);

    const getLines = useCallback(() => {
        if (!events?.length) {
            return [];
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { month, ...dataPoints } = events[0];
        return Object.keys(dataPoints).map((p, i) => {
            return (
                <Line
                    key={`line${p}`}
                    type="linear"
                    dataKey={p}
                    dot={<CustomIcon color={CHART_CATEGORIES[i].color} variant={CHART_CATEGORIES[i].iconVariant as any} />}
                    activeDot={<CustomIcon scale color={CHART_CATEGORIES[i].color} variant={CHART_CATEGORIES[i].iconVariant as any} />}
                    legendType="circle"
                    height={2}
                    stroke={{ ...Theme, ...Graph }[CHART_CATEGORIES[i].color] as string}
                    strokeWidth={i === 0 ? 2 : 1}
                    strokeDasharray={CHART_CATEGORIES[i].strokeDashArray}
                    strokeLinecap="round"
                />
            );
        });
    }, [events]);

    const legendCategories = Object.keys(events[0]).slice(1);

    const getCategoryTitle = useCallback(
        (cat: ChartCategoryType, index: number) => {
            if (cat.title) return t(cat.title);

            const level3 = selectedCategories?.level3Categories?.find((x) => x.value === legendCategories[index])?.parent.value;
            if (level3) return `${t(`incident.category.${level3}`)} • ${t(`incident.category.${legendCategories[index]}`)}`;

            return t(`incident.category.${legendCategories[index]}`);
        },
        [legendCategories, selectedCategories?.level3Categories, t],
    );

    const chartCategoriesLegendConfig = useMemo(() => {
        return legendCategories.map((key, i) => {
            return {
                color: CHART_CATEGORIES[i].color,
                iconVariant: CHART_CATEGORIES[i].iconVariant,
                key: key,
                title: getCategoryTitle(CHART_CATEGORIES[i], i),
            };
        });
    }, [getCategoryTitle, legendCategories]);

    return (
        <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%" minHeight={310}>
                <LineChart
                    data={events}
                    margin={{
                        bottom: 10,
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        axisLine={false}
                        dataKey="month"
                        padding={{ left: Spacing.S, right: Spacing.S }}
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<MonthsTick shouldAddYear />}
                    />
                    <YAxis
                        axisLine={false}
                        domain={domain}
                        interval={0}
                        padding={{ top: Spacing.S, bottom: Spacing.M }}
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<EventsCountTick />}
                        tickCount={5}
                        width={yWidth + 10}
                    />
                    <Tooltip
                        cursor={{ stroke: Palette.Navy300, strokeWidth: 1, strokeDasharray: "4 4" }}
                        content={<EventsCountTooltip chartCategoriesLegendConfig={chartCategoriesLegendConfig} />}
                    />
                    {getLines()}
                </LineChart>
            </ResponsiveContainer>
            <Stack direction={isMobileLayout ? "column" : "row"} mt="L" justifyContent="space-between">
                <Stack direction="column">
                    <Stack mb="XS">
                        <Text small bold>
                            {t("insights.trendingEvents.legend")}
                        </Text>
                    </Stack>
                    <LegendStyled gap={["M", "M", "XS"]} flexWrap="wrap">
                        {chartCategoriesLegendConfig.map((cat) => {
                            return (
                                <Stack key={`legendItem${cat.title}`} gap={"XXS"} alignItems="center">
                                    <GraphIcon color={cat.color} variant={cat.iconVariant} />
                                    <Text data-testid="legend-item-category" micro>
                                        {cat.title}
                                    </Text>
                                </Stack>
                            );
                        })}
                    </LegendStyled>
                </Stack>
                <Stack alignItems="center" mt={["0", "0", "S"]} justifyContent={isMobileLayout ? "flex-end" : null}>
                    <ButtonText onClick={() => navigate(redirectUrl)} direction="row-reverse" icon="ArrowForward">
                        {t("insights.highlights.viewReports")}
                    </ButtonText>
                </Stack>
            </Stack>
        </ChartWrapper>
    );
};
