import { createReducer } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import { IReportCategoriesState, REPORT_CATEGORIES_FAILURE, REPORT_CATEGORIES_REQUEST, REPORT_CATEGORIES_SUCCESS } from "./types";

const defaultState: IReportCategoriesState = {
    status: RequestStatus.undefined,
    reportCategoriesLevel1: [],
};

const reportCategoriesReducer = createReducer(defaultState, {
    [REPORT_CATEGORIES_REQUEST]: (state) => {
        state.status = RequestStatus.loading;
        state.reportCategoriesLevel1 = [];
    },
    [REPORT_CATEGORIES_SUCCESS]: (state, action) => {
        state.status = RequestStatus.success;
        state.reportCategoriesLevel1 = action.payload;
    },
    [REPORT_CATEGORIES_FAILURE]: (state) => {
        state.status = RequestStatus.error;
        state.reportCategoriesLevel1 = [];
    },
});

export default reportCategoriesReducer;
