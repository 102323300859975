import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { UNGROUPED_ITEMS_VALUE } from "../../components/Reports/ReportsFilter";
import { IMultiListItem } from "../../components/shared/CheckboxList/CheckboxListItem.types";
import { countriesByRegions, regionsByCountry } from "../../data/regions";
import { sortByField } from "../../helpers/ArrayHelper";
import { useFilterableReportTypes } from "../../hooks/ReportTypeHooks";
import { customReportFilterOption, reportTypeTranslation } from "../../models/ReportModel";
import LocationsSelectors from "../locations/LocationsSelectors";
import { useReportCategories } from "../reportCategories/ReportCategoriesHooks";
import { RequestStatus } from "../RequestStatus";
import { useSelectedSites } from "../siteObjects/SiteObjectsHooks";
import FilterSelectors from "./FilterSelectors";

const allCountries = Object.keys(regionsByCountry);
const allRegions = countriesByRegions.map((r) => r.name);

export const useReportCategoryItems = (): {
    reportCategories: IMultiListItem[];
    categoriesFetchStatus: RequestStatus;
    selectedCategories: string[];
} => {
    const { t } = useTranslation();
    const { reportCategories, fetchStatus: categoriesFetchStatus } = useReportCategories();
    const appliedCategoryOneFilters = useSelector(FilterSelectors.getSelectedCategoryOneIds);
    const appliedCategoryTwoFilters = useSelector(FilterSelectors.getSelectedCategoryTwoIds);
    const appliedCategoryThreeFilters = useSelector(FilterSelectors.getSelectedCategoryThreeIds);

    const reportCategoryItems: IMultiListItem[] = useMemo(() => {
        const fillCategoryChildren = (parentCategort, childCategories) => {
            const categories = getChildCategories(parentCategort, childCategories);
            parentCategort.children = categories;
            parentCategort.children.sort(sortByField("label"));
        };
        const getChildCategories = (parentCategory, childCategories) => {
            if (!childCategories?.length) {
                return [];
            }
            const categoriesWithChildren = childCategories.reduce(
                (categories, next) => {
                    if (categories.lookup[next.key]) {
                        return categories;
                    }
                    const level2 = { label: t(`incident.category.${next.key}`), value: next.key, parent: parentCategory, children: [] };
                    categories.categoriesArray.push(level2);
                    categories.lookup[next.key] = level2;
                    fillCategoryChildren(level2, next.level3Categories);
                    return categories;
                },
                { categoriesArray: [], lookup: {} },
            );
            return categoriesWithChildren.categoriesArray;
        };
        return reportCategories
            ?.map((c) => {
                const categoryLevel1 = { label: t(`incident.category.${c.key}`), value: c.key, children: [] };
                fillCategoryChildren(categoryLevel1, c.level2Categories);
                return categoryLevel1;
            })
            .sort(sortByField("label"));
    }, [t, reportCategories]);

    const selectedCategories: string[] = useMemo(() => {
        if (!reportCategoryItems?.length) {
            return [];
        }

        const selectedCategories2 = [...appliedCategoryTwoFilters];
        const selectedCategories3 = [...appliedCategoryThreeFilters];

        reportCategoryItems.forEach((category1) => {
            const isLv1Selected = appliedCategoryOneFilters.includes(category1.value);
            category1.children.forEach((lv2) => {
                if (isLv1Selected) {
                    selectedCategories2.push(lv2.value);
                    selectedCategories3.push(...lv2.children.map((c) => c.value));
                    return;
                }
                if (appliedCategoryTwoFilters.includes(lv2.value)) {
                    selectedCategories3.push(...lv2.children.map((c) => c.value));
                }
            });
        });

        return [...appliedCategoryOneFilters, ...selectedCategories2, ...selectedCategories3];
    }, [reportCategoryItems, appliedCategoryOneFilters, appliedCategoryTwoFilters, appliedCategoryThreeFilters]);
    return { reportCategories: reportCategoryItems, categoriesFetchStatus, selectedCategories };
};

export const useFilteredSites = (implicitSelection = true): { siteIds: string[] } => {
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    return useSelectedSites(selectedRegions, implicitSelection);
};

export const useFiltersSelection = (
    selectedSeverities: string[],
    selectedReportTypes: string[],
    selectedCategories: string[],
    selectedSitelocations: string[],
    selectedRegions: string[],
    showOnlyUnread: boolean,
): {
    selectedFiltersCount: number;
    regionsLength: number;
    categoriesLength: number;
    getFinalCategorySelection: () => {
        selectedCategories1: string[];
        selectedCategories2: string[];
        selectedCategories3: string[];
    };
} => {
    const { reportCategories } = useReportCategoryItems();
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);

    const getFinalCategorySelection = useCallback(() => {
        const selectedCategory1Items = reportCategories.filter((c) => selectedCategories.includes(c.value));
        const categoriesLevel2 = reportCategories.reduce((acc: IMultiListItem[], n: IMultiListItem) => {
            acc.push(...n.children);
            return acc;
        }, []);
        const selectedCategory2Items = categoriesLevel2.filter((c) => selectedCategories.includes(c.value));
        const selectedCategories2 = selectedCategory2Items.filter((v) => selectedCategory1Items.every((c) => c.children.every((ch) => ch.value !== v.value)));
        const selectedCategories3 = categoriesLevel2.reduce((selection: IMultiListItem[], lv2: IMultiListItem) => {
            const selectedLevel3 = lv2.children.filter((c) => selectedCategories.includes(c.value));
            if (selectedLevel3.length === lv2.children.length) {
                return selection;
            }
            selection.push(...selectedLevel3);
            return selection;
        }, []);

        return {
            selectedCategories1: selectedCategory1Items.map((c) => c.value),
            selectedCategories2: selectedCategories2.map((c) => c.value),
            selectedCategories3: selectedCategories3.map((c) => c.value),
        };
    }, [reportCategories, selectedCategories]);

    const regionsLength = selectedRegions.filter(
        (item) => !(allCountries.includes(item) || allRegions.includes(item) || locations.groups.some((x) => x.id === item) || item === UNGROUPED_ITEMS_VALUE),
    ).length;

    const finalCategorySelection = getFinalCategorySelection();
    const category1ChildSelectionCount = finalCategorySelection.selectedCategories1.reduce(
        (count, next) => count + reportCategories.find((c) => c.value === next).children.reduce((lv3Count, nextLv2) => lv3Count + nextLv2.children.length, 0),
        0,
    );
    const category2ChildSelectionCount = finalCategorySelection.selectedCategories2.reduce(
        (count, next) => count + [].concat(...reportCategories.map((c) => c.children)).find((c) => c.value === next).children.length,
        0,
    );

    const categoriesLength = category1ChildSelectionCount + category2ChildSelectionCount + finalCategorySelection.selectedCategories3.length;
    const selectedFiltersCount = useMemo(() => {
        return (
            selectedReportTypes.length +
            selectedSitelocations.length +
            selectedSeverities.length +
            category1ChildSelectionCount +
            category2ChildSelectionCount +
            finalCategorySelection.selectedCategories3.length +
            regionsLength +
            showOnlyUnread
        );
    }, [
        selectedReportTypes.length,
        selectedSitelocations.length,
        selectedSeverities.length,
        category1ChildSelectionCount,
        category2ChildSelectionCount,
        finalCategorySelection.selectedCategories3.length,
        regionsLength,
        showOnlyUnread,
    ]);

    return { selectedFiltersCount, regionsLength, categoriesLength, getFinalCategorySelection };
};

export const useReportTypeItems = () => {
    const { t } = useTranslation();
    const { disabledReportTypes, filterableReportTypes, defaultReportListReportTypes } = useFilterableReportTypes();

    const reportTypeItems: IMultiListItem[] = useMemo(
        () => [
            ...filterableReportTypes.map((r) => ({ value: r, label: t(reportTypeTranslation[r]) }) as IMultiListItem),
            { value: customReportFilterOption, label: t(reportTypeTranslation[customReportFilterOption]) } as IMultiListItem,
        ],
        [t, filterableReportTypes],
    );

    const getReportTypeFilterSelection = (selectedReportTypes: string[]): { selectedReportTypes: string[]; excludedReportTypes: string[] } => {
        if (selectedReportTypes.includes(customReportFilterOption)) {
            if (selectedReportTypes.length - 1 === filterableReportTypes.length) {
                return { selectedReportTypes: [], excludedReportTypes: [] };
            }
            return {
                selectedReportTypes: [],
                excludedReportTypes: filterableReportTypes.filter((el) => !selectedReportTypes.includes(el)).concat(disabledReportTypes),
            };
        }
        return {
            selectedReportTypes,
            excludedReportTypes: selectedReportTypes.length ? [] : disabledReportTypes,
        };
    };
    return { reportTypeItems, defaultReportListReportTypes, getReportTypeFilterSelection };
};
