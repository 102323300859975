import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { SiteInspectionFields } from "../../../models/reportTemplates/SiteInspection";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { ImageField } from "../SharedComponents/ImageField";
import { PicturesSection } from "../SharedComponents/Pictures";
import { TextAreaSection } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getSiteInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    conditionalRender(getFieldValueByFieldName(SiteInspectionFields.observationsByTheInspector, report), () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.siteInspection.inspectorObservations",
            fullyTranslated: true,
        },
        children: [
            {
                component: TextAreaSection,
                props: {
                    content: getFieldValueByFieldName(SiteInspectionFields.observationsByTheInspector, report),
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    conditionalRender(getFieldValueByFieldName(SiteInspectionFields.actionsTaken, report), () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.siteInspection.actionTaken",
            fullyTranslated: true,
        },
        children: [
            {
                component: TextAreaSection,
                props: {
                    content: getFieldValueByFieldName(SiteInspectionFields.actionsTaken, report),
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    conditionalRender(report.images.length > 0, () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.pictures",
        },
        children: [
            {
                component: PicturesSection,
                props: {
                    sharingId: sharedReportId,
                    imagesData: report.images,
                },
            },
        ],
    })),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.siteInspection.summary.title",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.officersUniformsAndAppearance",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.officersUniformsAndAppearance, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.regulatoryCompliant",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.regulatoryCompliant, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.postOrderAcknowledgment",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.postOrderAcknowledgment, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.postOrdersLastRevisionDate",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.postOrdersLastRevisionDate, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.siteInspection.summary.inspectionDateTime",
                    fieldValue: getFieldValueByFieldName(SiteInspectionFields.inspectionDate, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: ImageField,
                props: {
                    fieldName: "reportdetails.fields.officersSignature",
                    fieldValue: report.properties.find((p) => p.reportFieldName === SiteInspectionFields.officerSignature)?.value,
                    sharingId: sharedReportId,
                },
            },
            {
                component: ImageField,
                props: {
                    fieldName: "reportdetails.fields.inspectorsSignature",
                    fieldValue: report.properties.find((p) => p.reportFieldName === SiteInspectionFields.inspectorSignature)?.value,
                    sharingId: sharedReportId,
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(SiteInspectionFields)),
        ],
    },
];
