import { ReportTemplateEnum } from "../models/ReportModel";

export default {
    howManyMonths: 18,
    reports: [
        {
            type: ReportTemplateEnum.incident,
            categories: [
                {
                    category: "criminal_act_or_suspicious_behavior",
                    maxCount: 5,
                },
                {
                    category: "facility",
                    maxCount: 5,
                },
                {
                    category: "health_and_safety",
                    maxCount: 5,
                },
                {
                    category: "people_and_assets",
                    maxCount: 5,
                },
                {
                    category: "internal_securitas",
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.alarmResponse,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.taskReport,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.maintenance,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.safetyInspection,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.siteInspection,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.activityLog,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
        {
            type: ReportTemplateEnum.callout,
            categories: [
                {
                    maxCount: 5,
                },
            ],
        },
    ],
    patrols: {
        exceptionFreq: 4, // every 4th patrol will have an exception
        maxExceptionCount: 3, // max number of exceptions per patrol
    },
};
