export function isTransformMatrix(transform: string): boolean {
    return transform.startsWith("matrix");
}

export function getTxFromMatrix(matrix: string): number | null {
    const isMatrix = isTransformMatrix(matrix);

    if (!isMatrix) {
        return null;
    }

    return parseInt(matrix.split(",")[4]);
}

export function getTransformMatrix(tx = 0): string {
    return `matrix(1, 0, 0, 1, ${tx}, 0)`;
}

export function getTxFromTransform(transform?: string): number | null {
    if (!transform) {
        return null;
    }

    return getTxFromMatrix(transform);
}
