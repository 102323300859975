import { Breakpoints, ButtonText, Headline, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InsightsDateFilter } from "src/components/Insights/Filter/InsightsDateFilter";
import { TextWithIcon } from "src/components/Reports/SharedComponents/TextWithIcon";
import LocationsSelectors from "src/store/locations/LocationsSelectors";

import { StyledButtonText, TitleWrapper } from "../Common.styled";
import { BarchartBreadcrumbs } from "./Barchart/BarchartBreadcrumbs";
import { SelectedCategories } from "./InsightsSitePage.types";

interface InsightsSitePageHeaderProps {
    selectedCategories: SelectedCategories;
    activeTab: number;
    levelChangeHandler: (selectedCategories: SelectedCategories) => void;
    siteId: string;
}

export const InsightsSitePageHeader = ({ selectedCategories, activeTab, levelChangeHandler, siteId }: InsightsSitePageHeaderProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));

    return (
        <Stack direction="column" pt={isMobile ? "XS" : "M"} pb={isMobile ? "S" : "M"} ph="XS" style={{ width: "inherit" }}>
            {isMobile ? (
                <Stack direction="column" gap="XS">
                    {((!selectedCategories.level1 && activeTab === 0) || activeTab !== 0) && (
                        <StyledButtonText iconSize="L" color="primary" icon="BackNavigation" direction="row" onClick={() => navigate(-1)}>
                            {t("insights.title")}
                        </StyledButtonText>
                    )}
                    {selectedCategories.level1 && activeTab === 0 && (
                        <BarchartBreadcrumbs
                            categoryLevel1={selectedCategories.level1}
                            categoryLevel2={selectedCategories.level2}
                            setBarchart={levelChangeHandler}
                        />
                    )}
                    {!selectedCategories.level1 && activeTab === 0 && (
                        <>
                            <Stack gap={"XXS"} alignItems="center" pt="XS">
                                <Icon variant="BuildingFilled" />
                                <Headline bold>{locationDetails.name}</Headline>
                            </Stack>
                            <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                                {locationDetails.address}
                            </TextWithIcon>
                        </>
                    )}
                    <InsightsDateFilter />
                </Stack>
            ) : (
                <>
                    <TitleWrapper>
                        <ButtonText color="primary" icon="BackNavigation" direction="row" onClick={() => navigate(-1)}>
                            {t("insights.title")}
                        </ButtonText>
                        <InsightsDateFilter />
                    </TitleWrapper>
                    <Stack gap="S" alignItems="center" pt="XS">
                        <Stack gap={"XXS"} alignItems="center">
                            <Icon variant="BuildingFilled" />
                            <Headline bold>{locationDetails.name}</Headline>
                        </Stack>
                        <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                            {locationDetails.address}
                        </TextWithIcon>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
