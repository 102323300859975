import { GraphIcon } from "@secuis/ccp-react-components";

export const CustomIcon = (props) => {
    const { cx, cy, variant, color } = props;

    const iconSizeProps = {
        x: cx - 4,
        y: cy - 4,
    };
    return <GraphIcon {...iconSizeProps} variant={variant} color={color} />;
};
