import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../data/featureFlags";
import { customReportFilterOption, ReportTemplateEnum } from "../models/ReportModel";
import FeatureFlagsSelectors from "../store/featureFlags/FeatureFlagsSelectors";
import FilterActions from "../store/filter/FilterActions";

export const useFilterableReportTypes = () => {
    const dispatch = useDispatch();
    const isAssignmentReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.assignmentReports));
    const isVehicleInspectionReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.vehicleInspectionReports));
    const isCalloutReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.calloutReports));

    const filterableReportTypes = useMemo(() => {
        let reportTypes = [
            ReportTemplateEnum.alarmResponse,
            ReportTemplateEnum.incident,
            ReportTemplateEnum.patrolTour,
            ReportTemplateEnum.taskReport,
            ReportTemplateEnum.callout,
            ReportTemplateEnum.assignment,
            ReportTemplateEnum.vehicleInspection,
            ReportTemplateEnum.maintenance,
            ReportTemplateEnum.safetyInspection,
            ReportTemplateEnum.siteInspection,
            ReportTemplateEnum.tourException,
            ReportTemplateEnum.tourMultiException,
            ReportTemplateEnum.activityLog,
        ];

        if (!isAssignmentReportTypeEnabled) {
            reportTypes = reportTypes.filter((type) => type !== ReportTemplateEnum.assignment);
        }

        if (!isVehicleInspectionReportTypeEnabled) {
            reportTypes = reportTypes.filter((type) => type !== ReportTemplateEnum.vehicleInspection);
        }
        if (!isCalloutReportTypeEnabled) {
            reportTypes = reportTypes.filter((type) => type !== ReportTemplateEnum.callout);
        }

        return reportTypes;
    }, [isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled, isVehicleInspectionReportTypeEnabled]);

    const defaultInsightsReportTypes = useMemo(() => {
        const defaultTypeFilterSelectionFromInsights = [
            ReportTemplateEnum.alarmResponse,
            ReportTemplateEnum.incident,
            customReportFilterOption,
            ReportTemplateEnum.maintenance,
            ReportTemplateEnum.siteInspection,
            ReportTemplateEnum.safetyInspection,
            ReportTemplateEnum.taskReport,
            ReportTemplateEnum.activityLog,
        ];

        if (isAssignmentReportTypeEnabled) {
            defaultTypeFilterSelectionFromInsights.push(ReportTemplateEnum.assignment);
        }

        if (isCalloutReportTypeEnabled) {
            defaultTypeFilterSelectionFromInsights.push(ReportTemplateEnum.callout);
        }

        if (isVehicleInspectionReportTypeEnabled) {
            defaultTypeFilterSelectionFromInsights.push(ReportTemplateEnum.vehicleInspection);
        }

        return defaultTypeFilterSelectionFromInsights;
    }, [isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled, isVehicleInspectionReportTypeEnabled]);

    const defaultReportListReportTypes = useMemo(() => {
        return [...defaultInsightsReportTypes, ReportTemplateEnum.patrolTour];
    }, [defaultInsightsReportTypes]);

    const disabledReportTypes = useMemo(() => {
        const typesToDisable = [];
        if (!isAssignmentReportTypeEnabled) {
            typesToDisable.push(ReportTemplateEnum.assignment);
        }
        if (!isCalloutReportTypeEnabled) {
            typesToDisable.push(ReportTemplateEnum.callout);
        }

        if (!isVehicleInspectionReportTypeEnabled) {
            typesToDisable.push(ReportTemplateEnum.vehicleInspection);
        }
        return typesToDisable;
    }, [isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled, isVehicleInspectionReportTypeEnabled]);

    const setDefaultReportListReportTypeSelection = useCallback(() => {
        dispatch(FilterActions.selectReportTypes(defaultReportListReportTypes));
    }, [dispatch, defaultReportListReportTypes]);

    return {
        filterableReportTypes,
        defaultInsightsReportTypes,
        defaultReportListReportTypes,
        disabledReportTypes,
        setDefaultReportListReportTypeSelection,
    };
};
