import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Components } from "react-virtuoso";

import { EmptyInboxContent } from "../../../components/shared/NoContent/EmptyInboxContent";
import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";

export const VirtualListEmptyPlaceholder: Components<ReportsListItemType, VirtualListContext>["EmptyPlaceholder"] = ({ context: { isLoading } }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" flex={1} m="XL">
            {!isLoading && <EmptyInboxContent title={t("reportlist.noreports")} subtitle={t("reportlist.noreportstext")} />}
        </Stack>
    );
};
