import { Breakpoints, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Tile } from "../common/Tile.styled";

type Props = {
    $width?: string;
    $height: string;
};

export const TileLoaderStyled = styled(Tile)<Props>`
    min-height: ${(props) => props.$height};
    height: ${(props) => props.$height};
    width: ${(props) => props.$width || "100%"};
    justify-content: center;

    div[class*="container"] {
        div[class*="spinner"] {
            border-color: #fff ${Palette.Navy300} ${Palette.Navy300};
        }
    }
    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
    }
`;
