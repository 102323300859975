import i18next from "i18next";

import {
    getBooleanValue,
    getFieldValueByFieldName,
    getObjectFieldValueByFieldName,
    getSiteLocation,
} from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { CalloutLog, DynamicReportType } from "../../../models/ReportModel";
import { CalloutFields } from "../../../models/reportTemplates/Callout";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { Timeline } from "../Timeline";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getCalloutReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.callout.description",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description:
                        getFieldValueByFieldName(CalloutFields.describeTheIncident, report) || getFieldValueByFieldName(CalloutFields.describeTheEvent, report),
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },

    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.callout.timeline.title",
        },
        children: [
            {
                component: Timeline,
                props: {
                    fieldValue: getObjectFieldValueByFieldName<CalloutLog[]>(CalloutFields.dispatchWorkflow, report),
                    timeZone: report.siteIanaTimezone,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },

    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.callout.calloutDetails",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.alarmCallType",
                    fieldValue: getFieldValueByFieldName(CalloutFields.alarmCallType, report),
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.additionalInformation",
                    fieldValue: getFieldValueByFieldName(CalloutFields.additionalInformation, report),
                    desktopDirection: "column",
                    mobileDirection: "column",
                    isRequired: false,
                },
            },
        ],
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.callout.responseDetails",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.didAnIncidentOccurOnSite",
                    fieldValue: getBooleanValue(
                        getFieldValueByFieldName(CalloutFields.didAnIncidentOccurOnSite, report) ||
                            getBooleanValue(getFieldValueByFieldName(CalloutFields.didAnEventOccurOnSite, report)),
                    ),
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    isRequired: false,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.job",
                    fieldValue: getFieldValueByFieldName(CalloutFields.dispatchJob, report),
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.jobId",
                    fieldValue: getFieldValueByFieldName(CalloutFields.dispatchJobId, report),
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.callout.alarmOrganization",
                    fieldValue: getFieldValueByFieldName(CalloutFields.alarmOrganization, report),
                    mobileDirection: "column",
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(CalloutFields)),
        ],
    },
    conditionalRender(getFieldValueByFieldName(CalloutFields.personalData, report), () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.incident.personalData",
        },
        private: true,
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(CalloutFields.personalData, report),
                    subtitle: i18next.t("reportdetails.incident.personalDataGdpr"),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
        ],
    })),
];
