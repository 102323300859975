import { Breakpoints, ButtonText, Palette, Spacing, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DefaultItemFieldWrapper } from "../../../../../../components/Reports/DefaultItemField";
import { flexColumn } from "../../../../../../styles/templates/Flex";

const ViewExceptionButton = ({ disabled, onClick }) => (
    <ButtonText color="accent" disabled={disabled} onClick={onClick}>
        {`${i18next.t("reportdetails.patrolTour.viewException")}`}
    </ButtonText>
);

const TourExceptionFieldWrapper = styled.div<{ isFirst: boolean; isLast: boolean }>`
    ${flexColumn}
    padding-left: ${Spacing.M}px;
    padding-right: ${Spacing.M}px;
    background-color: ${Palette.Navy650};

    ${(props) =>
        props.isFirst &&
        `border-top-right-radius: ${Spacing.XXS}px; 
        border-top-left-radius: ${Spacing.XXS}px; 
        padding-top: ${Spacing.S}px;`}

    ${(props) =>
        props.isLast &&
        `border-bottom-right-radius: ${Spacing.XXS}px; 
        border-bottom-left-radius: ${Spacing.XXS}px; 
        margin-bottom: ${Spacing.S}px;`}

    @media (max-width: ${Breakpoints.M}) {
        ${(props) => props.isFirst && `padding-top: ${Spacing.XS}px;`}
        padding-left: ${Spacing.S}px;
        padding-right: ${Spacing.S}px;
    }
`;

export const TourExceptionField = ({ onClick, label, value, disabled = false }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <DefaultItemFieldWrapper>
            <Stack direction="column">
                <Stack justifyContent="space-between">
                    {label}
                    {!isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </DefaultItemFieldWrapper>
    );
};

export const TourMultiExceptionField = ({ onClick, label, value, disabled = false, isFirst, isLast }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <TourExceptionFieldWrapper isFirst={isFirst} isLast={isLast}>
            {" "}
            <Stack direction="column" mb={"S"}>
                <Stack justifyContent="space-between">
                    <Text>{label}</Text>
                    {isFirst && !isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isLast && isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </TourExceptionFieldWrapper>
    );
};
