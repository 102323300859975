import styled from "styled-components";

import { flexColumnJustifySpaceBetween, flexRowAlignCenter } from "../../styles/templates/Flex";

export const CloseButtonWrapper = styled.div<{ backgroundColorHover: string }>`
    height: 100%;
    width: 100%;
    ${flexRowAlignCenter}
    justify-content: center;
    @media (hover: hover) {
        &:hover {
            ${(props) => `background-color: ${props.backgroundColorHover};`}
        }
    }
`;

export const TooltipContainer = styled.div<{ backgroundColor: string }>`
    width: 360px;
    border-radius: 4px;
    ${flexColumnJustifySpaceBetween};
    overflow: hidden;
    ${(props) => `background-color: ${props.backgroundColor};`}
`;

export const ButtonsContainer = styled.div<{ borderColor: string }>`
    ${(props) => `border-top: solid 1px ${props.borderColor};`}
    height: 40px;
    ${flexRowAlignCenter}
    justify-content: center;
    cursor: pointer;
`;
