import { Palette, Spacing } from "@secuis/ccp-react-components";

export const Navy500Scrollbar = `
    /* scrollbar */
    /* Firefox */
    * {
        scrollbar-color: ${Palette.Navy500} ${Palette.Navy700};
        scrollbar-width: thin;
    }
    /* scrollbar */
    /* Chrome/Edge/Safari */
    *::-webkit-scrollbar {
        width: ${Spacing.XXS}px;
        height: ${Spacing.XXS}px;
    }
    *::-webkit-scrollbar-track {
        background: ${Palette.Navy700};
    }
    *::-webkit-scrollbar-thumb {
        background-color: ${Palette.Navy500};
        border-radius: 0px;
    }
    /* scrollbar end */
`;

export const InvisibleScrollbar = `
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    
    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;
