import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../../styles/templates/Flex";

export const MainReportDetailsContainer = styled(Stack)`
    flex-direction: column;
    flex: 1;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${Palette.Navy750};
    padding-bottom: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.L}) {
        padding-bottom: ${Spacing.S}px;
    }
`;

export const LoadingContainer = styled.div`
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${Palette.Navy750};

    @media (max-width: ${Breakpoints.XS}) {
        width: auto;
    }
`;

export const ReportDetailsContainer = styled(Stack)<{ isSharedReport?: boolean }>`
    width: 100%;
    margin: ${({ isSharedReport }) => isSharedReport && `${Spacing.XXXL}px`} 0;
    padding: 0 40px;

    @media (max-width: ${Breakpoints.M}) {
        padding: 0 ${Spacing.S}px;
        margin-bottom: ${({ isSharedReport }) => (isSharedReport ? `${Spacing.XS}px` : 0)};
    }
    @media (max-width: ${Breakpoints.S}) {
        padding: 0 ${Spacing.L}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 ${Spacing.S}px;
    }
`;

export const SplitContainer = styled(Stack)`
    flex: 1;
    background-color: ${Palette.Navy750};
`;

export const VerticalReportDetailsLine = styled.div`
    display: flex;
    height: 100%;
    width: 1px;
    background-color: ${Palette.Navy550};
`;

export const CommentReportDetailsContainer = styled.div`
    ${flexColumn}
    flex: 1;
    overflow-y: auto;

    @media (max-width: ${Breakpoints.M}) {
        padding: 0;
        padding-bottom: ${Spacing.S}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        padding: 0;
        padding-bottom: ${Spacing.S}px;
    }
`;
