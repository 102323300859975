import { Breakpoints, Input, Palette, Spacing } from "@secuis/ccp-react-components";
import { DropdownListWrapper } from "src/components/shared/MultiDropdown/MultiDropdown.styled";
import { flexRowJustifySpaceBetweenAlignCenter } from "src/styles/templates/Flex";
import { Navy500Scrollbar } from "src/styles/templates/Scrollbar";
import styled from "styled-components";

export const DropdownInput = styled(Input)<{ $hasActiveSelection: boolean }>`
    &[class*="inputContainer"] {
        cursor: pointer;
        input {
            text-overflow: ellipsis;
            cursor: inherit;
            pointer-events: none;
        }
        ${(props) =>
            props.$hasActiveSelection
                ? ` 
            input {
                color: ${Palette.Gray100};
            }`
                : ``}
    }
`;

export const Dropdown = styled.div<{ active: boolean }>`
    ${flexRowJustifySpaceBetweenAlignCenter}
    position: relative;
    width: 100%;
    outline: none;
    cursor: default;

    & > div[direction="column"] {
        width: 100%;
    }

    @media (max-width: ${Breakpoints.XS}) {
        height: 100%;
        flex-direction: column;
    }

    ${Navy500Scrollbar}

    ${(props) =>
        props.active
            ? `
        ${DropdownListWrapper} {
            position: absolute;
            background-color: ${Palette.Navy750};
            top: 46px;
            right: 0px;
            width: 100%;
            margin: 0px;
            overflow: auto;
            list-style-type: none;
            cursor: default;
            z-index: 9;
            overscroll-behavior: none;
            border: 2px solid ${Palette.Purple300};
            border-bottom-left-radius: ${Spacing.M}px;
            border-bottom-right-radius: ${Spacing.M}px;

            @media (max-width: ${Breakpoints.XS}) {
                position: static;
                display: flex;
                flex-direction: column;
                border: 0px;
                height:100%;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }

        > div[direction="column"] > div {
            border-radius: 0px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            border-color: ${Palette.Purple300};
            color: ${Palette.Gray100};
            
            label {
                color: ${Palette.Navy750} !important;
                background-color: ${Palette.Purple300} !important;
            }
            label:hover {
                color: ${Palette.Navy750} !important;
            }
        }

   
        `
            : ``}
    &:active {
        ul,
        .dropDownInput {
            border-color: ${Palette.Purple200};
        }
    }

    &.disabled {
        background-color: transparent;

        cursor: default;

        .inputReadOnly {
            cursor: default;

            input {
                cursor: default;
            }
        }
    }

    @media (min-width: ${Breakpoints.XS}) {
        &:hover,
        &:focus {
            ul,
            ${DropdownInput} {
                border-color: ${Palette.Purple300};
            }

            svg {
                color: ${Palette.Purple300};
            }
        }
    }
`;

export const ApplyFilterButton = styled.div`
    width: 100%;
    background-color: ${Palette.Navy700};
    padding: ${Spacing.S}px;
    z-index: 999;
`;
