import { Box, Breakpoints, Separator, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getItemsFromSelectedIds } from "../../../helpers/MultiDropdownHelper";
import { CheckboxListItem } from "../CheckboxList/CheckboxListItem";
import { CheckboxListStyled, DropdownListWrapper, ListButtonStyled } from "./MultiDropdown.styled";
import { MultiDropdownProps } from "./MultiDropdown.types";
import { MultiDropdownSelectedSection } from "./MultiDropdownSelectedSection";

export function MultiDropdown({ items, selectedItems = [], onSelectionChange, onClearAll, onSelectAll, showSelected = true }: MultiDropdownProps) {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const allItemsAreSelected = useMemo(() => {
        return items.every((i) => selectedItems.includes(i.value));
    }, [items, selectedItems]);

    const onSelectAllClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelectAll();
    };

    const onClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClearAll();
    };
    const { items: selectedItemsToDisplay } = getItemsFromSelectedIds(selectedItems, items);

    return (
        <DropdownListWrapper data-testid="filter-dropdown-list" onClick={(e) => e.stopPropagation()}>
            {showSelected && !allItemsAreSelected && (
                <MultiDropdownSelectedSection
                    label={t("filters.selected")}
                    selectedItems={selectedItemsToDisplay}
                    onChange={onSelectionChange}
                    onClearButtonClick={onClearClick}
                />
            )}
            {onSelectAll && (
                <>
                    <Stack justifyContent="space-between" alignItems="baseline" mt="S" mh="S">
                        {isMobile && (
                            <Text micro bold uppercase color="secondary">
                                {t("filters.locations.select")}
                            </Text>
                        )}
                        {!allItemsAreSelected && <ListButtonStyled label={t("filters.selectAll")} onClick={onSelectAllClick} />}
                        {allItemsAreSelected && <ListButtonStyled label={t("filters.clearAll")} onClick={onClearClick} />}
                    </Stack>
                    <Box mh="S" mt="S">
                        <Separator />
                    </Box>
                </>
            )}
            <CheckboxListStyled>
                {items.map((item) => (
                    <CheckboxListItem
                        key={item.value}
                        item={item}
                        showAllThreshold={5}
                        selectedItems={selectedItems}
                        onItemClickHandler={(e) => {
                            onSelectionChange(e);
                        }}
                    />
                ))}
            </CheckboxListStyled>
        </DropdownListWrapper>
    );
}
