import { sum } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GraphDataItem } from "src/helpers/graphs";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";

import { useIncidentAndDeviationCategoriesCount, useSummaryPeriod } from "../shared/hooks";
import { COLORS_MAP, LABELS_MAP } from "./IncidentDistributionWidget.constants";

const parseToPieChartData = (data: Partial<Record<CategoryLevel1, number>>, total: number, onItemClick?: (categotyKey: string) => void): GraphDataItem[] => {
    if (!total) {
        return [];
    }

    return Object.values(CategoryLevel1Keys)
        .filter((k) => k !== CategoryLevel1Keys.internalSecuritas)
        .map((categoryKey) => {
            const dataValue = data[categoryKey] || 0;

            return {
                value: parseFloat(((dataValue / total) * 100).toFixed(1)),
                label: LABELS_MAP[categoryKey],
                color: COLORS_MAP[categoryKey],
                name: categoryKey,
                key: categoryKey,
                ...(onItemClick && {
                    onItemClick: () => onItemClick(categoryKey),
                }),
            };
        });
};

export const useIncidentDistributionWidget = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentAndDeviationCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentAndDeviationCategoriesCount(previousPeriod.start, previousPeriod.end);

    const incidentCount = useMemo(() => {
        return {
            previousValue: oldValues ? sum(Object.values(oldValues)) : 0,
            value: currentValues ? sum(Object.values(currentValues)) : 0,
        };
    }, [oldValues, currentValues]);

    const chartData = useMemo(() => {
        const handleItemClick = (categoryKey: string) => {
            navigate(`/insights/overview-events/${categoryKey}`, {
                state: {
                    startDate: currentPeriod.start,
                    endDate: currentPeriod.end,
                    originUrl: "/insights",
                    originTitle: t("insights.tabs.summary"),
                    originActiveTab: 0,
                },
            });
        };

        return parseToPieChartData(currentValues, incidentCount.value, handleItemClick);
    }, [currentValues, incidentCount.value, navigate, currentPeriod.start, currentPeriod.end, t]);

    return {
        incidentCount,
        chartData,
        isLoading: isLoadingCurrent || isLoadingPrevious,
    };
};
