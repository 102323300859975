import { HttpResponse } from "msw";

export const getReportImageResolver = async (imageId: string, sasToken) => {
    const imageFetchUrl = `https://ogdemosa.blob.core.windows.net/images/Demo/reports/${imageId}/${imageId}_large.jpg?${sasToken}`;

    const image = await fetch(imageFetchUrl, {}).then((response) => {
        return response.arrayBuffer();
    });

    return HttpResponse.arrayBuffer(image, {
        headers: {
            "Content-Type": "image/jpeg",
            "Content-Length": image.byteLength.toString(),
        },
    });
};

export default getReportImageResolver;
