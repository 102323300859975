import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { IncidentFields } from "../../../../models/reportTemplates/Incident";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

const V1IncidentFields = (report: DynamicReportType) => [
    {
        component: TextSummaryFieldPdf,
        props: {
            fieldName: "common.policeInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.policeInvolved, report)),
        },
    },
    {
        component: TextSummaryFieldPdf,
        props: {
            fieldName: "common.medicalsInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.medicalServicesInvolved, report)),
        },
    },
    {
        component: TextSummaryFieldPdf,
        props: {
            fieldName: "common.fireDeptInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.fireDepartmentInvolved, report)),
        },
    },
    {
        component: TextSummaryFieldPdf,
        props: {
            fieldName: "common.supervisorNotified",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.siteSupervisorNotified, report)),
        },
    },
];

const V2IncidentFields = (report: DynamicReportType) => [
    {
        component: TextSummaryFieldPdf,
        props: {
            fieldName: "reportdetails.incident.timeOfEvent",
            fieldValue:
                getFieldValueByFieldName(IncidentFields.incidentDiscoveryTime, report) ?? getFieldValueByFieldName(IncidentFields.eventDiscoveryTime, report),
            isRequired: true,
            shouldAutoTranslate: false,
        },
    },
];

export const getIncidentReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.incidentReportDescription",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    title: getFieldValueByFieldName("title", report),
                    description:
                        getFieldValueByFieldName(IncidentFields.description, report) ??
                        getFieldValueByFieldName(IncidentFields.altDescription, report) ??
                        getFieldValueByFieldName(IncidentFields.eventDescription, report),
                    shouldAutoTranslate: true,
                    images,
                },
            },
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.incidentReportSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            ...conditionalRender(
                report.properties.some((prop) => prop.reportFieldName === IncidentFields.description),
                () => V1IncidentFields(report),
            ),
            ...conditionalRender(
                report.properties.some(
                    (prop) => prop.reportFieldName === IncidentFields.incidentDiscoveryTime || prop.reportFieldName === IncidentFields.eventDiscoveryTime,
                ),
                () => V2IncidentFields(report),
            ),
            ...getPdfUnknownFields(report, Object.values(IncidentFields), images),
        ],
    },
];
