import { Breakpoints, ButtonIcon, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { useIncidentsCountBySite } from "../../../store/insights/CardsHooks";
import FilterSelectors from "../../../store/insights/FilterSelectors";
import { IHighlightsCard } from "../../../store/insights/InsightsModel";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { MapWrapper, StyledHeader } from "./Map.styles";
import { MapComponent } from "./MapComponent";
import { MapFooter } from "./MapFooter";

export const MapSection = () => {
    const allSites = useSelector(LocationsSelectors.getSiteObjects);
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const [isMobileMapExpanded, setIsMobileMapExpanded] = useState(false);
    const [activeSites, setActiveSites] = useState<ISiteObject[]>([]);
    const [visibleSiteIds, setVisibleSiteIds] = useState<string[]>(null);
    const [hoveredCardSite, setHoveredCardSite] = useState<string>(null);
    const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(!isMobile);
    const [isMapInProgress, setIsMapInProgress] = useState<boolean>(false);
    const siteObjects = useMemo(() => {
        if (selectedRegions.length) {
            const filteredSiteObjects = allSites.filter((obj) => selectedRegions.includes(obj.id));
            return filteredSiteObjects;
        }
        return allSites;
    }, [allSites, selectedRegions]);
    const handleMarkerClick = (siteIds: string[]) => {
        setActiveSites(siteObjects.filter((obj) => siteIds.includes(obj.id)));
    };
    const siteIds = useMemo((): string[] => {
        if (!allSites.length) {
            return [];
        }

        if (activeSites.length) {
            return activeSites.map((s) => s.id);
        }

        return visibleSiteIds;
    }, [allSites, activeSites, visibleSiteIds]);
    const { incidents, isLoading: isIncidentsCountLoading } = useIncidentsCountBySite(siteObjects.map((obj) => obj.id));

    useEffect(() => {
        setActiveSites([]);
    }, [selectedRegions]);

    useEffect(() => {
        if (activeSites.length) {
            setIsDetailsExpanded(true);
            if (isMobile) {
                setIsMobileMapExpanded(true);
            }
        }
    }, [activeSites, isMobile]);

    const handleExpandDetails = useCallback(() => {
        if (isMobile && !isDetailsExpanded) {
            setIsMobileMapExpanded(true);
        }
        setIsDetailsExpanded(!isDetailsExpanded);
    }, [isDetailsExpanded, isMobile]);

    const handleExpandMobileMap = useCallback(() => {
        if (isMobileMapExpanded) {
            setIsDetailsExpanded(false);
        }
        setIsMobileMapExpanded(!isMobileMapExpanded);
    }, [isMobileMapExpanded]);

    const handleMapMove = useCallback((ids: string[]) => {
        setVisibleSiteIds(ids);
    }, []);

    const [handleCardHover] = useDebouncedCallback((card: IHighlightsCard) => {
        if (card) {
            setHoveredCardSite(card.siteId);
        } else {
            setHoveredCardSite(null);
        }
    }, 50);

    const footerHeight = document.querySelector('nav[role="navigation"]')?.clientHeight || 0;
    const headerHeight = document.querySelector("header")?.clientHeight || 0;

    const siteObjectsWithIncidents = useMemo(() => {
        return siteObjects?.map((siteObject) => ({
            ...siteObject,
            incidentCount: incidents?.find((incident) => incident.location_id === siteObject.id)?.count ?? 0,
        }));
    }, [siteObjects, incidents]);

    return (
        <MapWrapper data-testid="map-wrapper" navHeight={footerHeight + headerHeight} isMapExpanded={isMobileMapExpanded}>
            <StyledHeader justifyContent="space-between">
                <Text data-testid="map-yourSites-text" bold>
                    {t("insights.map.yourSites")} ({allSites.length})
                </Text>
                {isMobile && <ButtonIcon mode="outlined" icon={isMobileMapExpanded ? "FullscreenExit" : "Fullscreen"} onClick={handleExpandMobileMap} />}
            </StyledHeader>
            <MapComponent
                initialPosition={{
                    lng: 0,
                    lat: 0,
                    zoom: 4,
                }}
                isMapExpanded={isMobileMapExpanded}
                siteObjects={siteObjectsWithIncidents}
                handleMarkerClick={handleMarkerClick}
                activeSiteObjectIds={activeSites.map((s) => s.id)}
                setVisibleMarkers={handleMapMove}
                highlightedObject={hoveredCardSite}
                updateMapProgress={setIsMapInProgress}
            />
            <MapFooter
                isIncidentsCountLoading={isIncidentsCountLoading}
                isMapInProgress={isMapInProgress}
                activeSites={activeSites}
                siteIds={siteIds}
                incidents={incidents.filter((item) => siteIds?.includes(item.location_id))}
                handleCardHover={handleCardHover}
                handleExpandDetails={handleExpandDetails}
                isDetailsExpanded={isDetailsExpanded}
                isMobileMapExpanded={isMobileMapExpanded}
            />
        </MapWrapper>
    );
};
