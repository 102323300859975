import { Breakpoints, ButtonText, LoadingIndicator, Stack, ToasterContext, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { exportReportsToExcelSlice } from "src/store/reports/exportReportsToExcelSlice";

import { useExport } from "../../hooks/ExportExcelHooks";
import ReportsSelectors from "../../store/reports/ReportsSelectors";
import { ExportStateEnum } from "../../store/reports/types";
import { ExceedingExportLimitsModal } from "./ExceedingExportLimitsModal";
import { ExcelExportModal } from "./ExcelExportModal";

const MAX_REPORT_COUNT = 100000;

export const ExcelExportButton = () => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const dispatch = useDispatch();
    const { addToast } = useContext(ToasterContext);
    const totalCount = useSelector(ReportsSelectors.getTotalReportsCount);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isExceedModalOpen, setIsExceedModalOpen] = useState<boolean>(false);
    const { isExceeding18Months, prefetchReportsToExport, exportState, saveFile } = useExport();

    const onOpenExportModal = useCallback(() => {
        if (!isExceeding18Months) {
            prefetchReportsToExport();
        } else {
            setIsExceedModalOpen(true);
        }
    }, [isExceeding18Months, prefetchReportsToExport]);

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleExceedModalClose = () => {
        setIsExceedModalOpen(false);
    };

    useEffect(() => {
        switch (exportState) {
            case ExportStateEnum.prefetchSucceded: {
                if (totalCount > MAX_REPORT_COUNT) {
                    setIsExceedModalOpen(true);
                    dispatch(exportReportsToExcelSlice.actions.resetExportReports());
                    return;
                }
                setIsModalOpen(true);
                break;
            }
            case ExportStateEnum.fetchSucceeded: {
                handleClose();
                saveFile();
                break;
            }
            case ExportStateEnum.fetchFailed: {
                handleClose();
                addToast({
                    title: t("reports.export.failed"),
                    type: "high",
                    actions: [
                        {
                            label: t("common.tryAgain"),
                            onClick: onOpenExportModal,
                        },
                    ],
                });
                break;
            }
            case ExportStateEnum.fileCreated: {
                dispatch(exportReportsToExcelSlice.actions.resetExportReports());
                break;
            }
        }
        // addToast from SCL is recreated on each render making this effect stuck in infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, exportState, totalCount, onOpenExportModal, saveFile, t]);

    return (
        <>
            <Stack id="export-to-excel" data-testid="export-to-excel" gap="XS" ml="auto">
                <ButtonText disabled={!totalCount} onClick={onOpenExportModal} icon="Export" small={isMobile}>
                    {t("excelExport.exportButton")}
                </ButtonText>
                {exportState === ExportStateEnum.loading ? <LoadingIndicator size="S" /> : null}
            </Stack>
            <ExcelExportModal onClose={handleClose} isOpen={isModalOpen} />
            <ExceedingExportLimitsModal isOpen={isExceedModalOpen} onClose={handleExceedModalClose} />
        </>
    );
};
