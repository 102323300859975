import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CheckpointScanType } from "../../../../../../models/ReportModel";
import { CheckpointSortConfig, CheckpointSortConfigs, useSortedCheckpoints } from "./CheckpointDataHook";
import { SortStyled } from "./CheckpointSortComponent.styled";

interface SortComponentProps {
    checkpointScans: CheckpointScanType[];
    isSortButtonHidden?: boolean;
    onSortChange: (sortConfig: CheckpointScanType[]) => void;
}

export const CheckpointSortComponent = ({ checkpointScans, isSortButtonHidden, onSortChange }: SortComponentProps) => {
    const { t } = useTranslation();
    const { sortCheckpoints, currentSort } = useSortedCheckpoints(checkpointScans);

    const getOption = (config: CheckpointSortConfig) => {
        return { label: t(config.sortLabel), onClick: () => onSortChange(sortCheckpoints(config)) };
    };

    useEffect(() => {
        onSortChange(sortCheckpoints(CheckpointSortConfigs.defaultSortConfig));
    }, []);

    if (isSortButtonHidden) {
        return null;
    }

    return (
        <SortStyled
            label={t(currentSort.sortLabel)}
            onClear={() => onSortChange(sortCheckpoints(CheckpointSortConfigs.defaultSortConfig))}
            options={[getOption(CheckpointSortConfigs.oldestFirstSortConfig), getOption(CheckpointSortConfigs.newestFirstSortConfig)]}
            sheetCancelLabel={t("common.cancel")}
            sheetTitle={t("reportdetails.patrolTour.sortCheckpoints")}
            sortDirection={currentSort.mainSort.desc ? "desc" : "asc"}
            isActive={!currentSort.isDefault}
        />
    );
};
