import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const GraphWrapperStyled = styled(Stack).attrs({
    flex: 1,
    direction: "column",
    alignItems: "flex-start",
})`
    max-width: 100%;
`;
