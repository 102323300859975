import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DateInput } from "../DateInput";
import { useDateRangeChips } from "./DateFilter.hooks";
import { SiteZoneInfoTooltip } from "./SiteZoneInfoTooltip";

type Props = {
    startDate: Date;
    endDate: Date;
    onDateChange: (startDate: Date, endDate: Date) => void;
    onCalendarOpen?: () => void;
};

export const DateFilter = ({ onCalendarOpen, startDate, endDate, ...props }: Props) => {
    const { t } = useTranslation();
    const chips = useDateRangeChips();
    const customOnDateChangeHandler = props.onDateChange;
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(startDate));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(endDate));

    const onDateChange = useCallback(
        (startDate: Date, endDate: Date) => {
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            customOnDateChangeHandler(startDate, endDate);
        },
        [customOnDateChangeHandler, setSelectedStartDate, setSelectedEndDate],
    );

    useEffect(() => {
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    }, [startDate, endDate]);

    return (
        <SiteZoneInfoTooltip>
            <DateInput
                placeholder={t("datepicker.placeholder")}
                onDateChange={onDateChange}
                onCalendarOpen={onCalendarOpen}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                mode="doubleMonth"
                dateRangeChips={chips}
            />
        </SiteZoneInfoTooltip>
    );
};
