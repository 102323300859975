import { capitalize } from "lodash";

import { LineChartTooltip } from "../../../../../components/Insights/common/LineChartTooltip";
import { sortByField } from "../../../../../helpers/ArrayHelper";
import { getMonthName, setMonth } from "../../../../../helpers/date";
import { ChartLegendConfig } from "./TrendingEventsChart";

type Props = {
    label?: number;
    payload?: { dataKey: string; value: number; stroke: string }[];
    chartCategoriesLegendConfig: ChartLegendConfig[];
};

export const EventsCountTooltip = ({ label: monthNumber, payload, chartCategoriesLegendConfig }: Props) => {
    if (!payload?.length) {
        return null;
    }
    const monthOffset = setMonth(new Date(), monthNumber - 1);
    const year = monthOffset.getFullYear();
    const month = capitalize(getMonthName(monthNumber));

    const xdata = [...payload].sort(sortByField("value", true)).map((d) => {
        const item = chartCategoriesLegendConfig.find((x) => x.key === d.dataKey);
        return {
            label: d.value.toString(),
            value: item.title,
            icon: item.iconVariant,
            color: item.color,
        };
    });

    return <LineChartTooltip title={`${month}, ${year}`} data={xdata} />;
};
