import { formatToRawDate } from "../../helpers/date";

export const getParameterizedArray = (array: string[]) => {
    return array.reduce((keys, siteId, i) => {
        keys[`$k${i}`] = siteId;
        return keys;
    }, {});
};

export const getSqlStringifiedArrayFromObject = (obj: Record<string, unknown>): string => {
    return Object.keys(obj)
        .map((k) => k)
        .join(",");
};

export const generateStringForQuery = (siteIds: string[]): string => {
    return siteIds
        .reduce((siteIdsString, siteId) => {
            siteIdsString += `"${siteId}",`;
            return siteIdsString;
        }, "")
        .slice(0, -1);
};

export type WithSiteZoneQueryParams = { withSiteZone?: boolean };

export const getReportQueryTimeRangeCondition = (startDate: Date, endDate: Date, withSiteZone = false, fieldPrefix = ""): string => {
    const fieldName = `${fieldPrefix ? `${fieldPrefix}.` : ""}${getDateTimeFieldName(withSiteZone)}`;
    let startCondition = "";
    let endCondition = "";

    if (withSiteZone) {
        startCondition = `'${formatToRawDate(startDate)} 00:00:00'`;
        endCondition = `'${formatToRawDate(endDate)} 23:59:59'`;
    } else {
        startCondition = createStrFTime(startDate);
        endCondition = createStrFTime(endDate);
    }

    return `${fieldName} >= ${startCondition}
            AND ${fieldName} <= ${endCondition}`;
};

export const getDateTimeFieldName = (withSiteZone = false): string => {
    return withSiteZone ? "report_date_time_local" : "report_date_time";
};

const createStrFTime = (date: Date): string => {
    return `STRFTIME('%Y-%m-%d %H:%M:%S', '${date.toISOString()}')`;
};
