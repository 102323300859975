import type { SwipeActionItem } from "../types";
import { WrapperStyled } from "./SwipeActionsMenu.styles";
import { SwipeActionsMenuItem } from "./SwipeActionsMenuItem/SwipeActionsMenuItem";

type Props = {
    actions?: SwipeActionItem[];
};

export const SwipeActionsMenu = ({ actions }: Props) => {
    if (!actions?.length) return null;

    const isDisabled = actions.every((action) => action.disabled);

    return (
        <WrapperStyled isDisabled={isDisabled}>
            {actions.map((action) => (
                <SwipeActionsMenuItem key={action.label} action={action} />
            ))}
        </WrapperStyled>
    );
};
