import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { createContext, useCallback, useMemo, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, Step } from "react-joyride";
import styled from "styled-components";

import { ChildrenProps } from "../../models/ChildrenPropModel";
import { AppGuideStep } from "./AppGuideSteps";
import { AppGuideTooltip } from "./AppGuideTooltip";

interface AppGuideContextModel {
    run: boolean;
    steps: Step[];
    startTour: (steps: AppGuideStep[], disableScrolling?: boolean) => void;
    stopTour: () => void;
}

// prevents from scrolling and clicking highlighted element
const SubOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 103;
`;

export const AppGuideContext = createContext<AppGuideContextModel>({
    run: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startTour: (_steps, _disableScrolling) => undefined,
    steps: [],
    stopTour: () => undefined,
});

type Props = ChildrenProps & {
    disabled: boolean;
};

export const AppGuideProvider = ({ disabled, children }: Props) => {
    const [run, setRun] = useState(false);
    const [steps, setSteps] = useState<AppGuideStep[]>([]);
    const [disableScrolling, setDisableScrolling] = useState<boolean>(false);
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const startTourHandler = useCallback((stepsValues: AppGuideStep[], disableScrolling = false) => {
        setTimeout(() => {
            setSteps(stepsValues);
            setDisableScrolling(disableScrolling);
            setRun(true);
        }, 1500);
    }, []);

    const stopTourHandler = useCallback(() => {
        setSteps([]);
        setRun(false);
    }, []);

    const callbackHandler = (data: CallBackProps) => {
        if (data.action === ACTIONS.CLOSE) {
            setRun(false);
        }
    };

    const contextValues = useMemo(
        () => ({ run: run, startTour: startTourHandler, steps: steps, stopTour: stopTourHandler }),
        [run, startTourHandler, steps, stopTourHandler],
    );

    return (
        <AppGuideContext.Provider value={contextValues}>
            {run && !disabled && <SubOverlay onClick={stopTourHandler} />}
            <Joyride
                steps={steps}
                callback={callbackHandler}
                run={!disabled && run}
                disableScrolling={disableScrolling}
                tooltipComponent={AppGuideTooltip}
                disableScrollParentFix={true}
                styles={{
                    overlay: {
                        backdropFilter: !isMobile && "blur(2px)",
                    },
                    overlayLegacy: {
                        backdropFilter: !isMobile && "blur(2px)",
                    },
                }}
                floaterProps={{
                    styles: {
                        arrow: {
                            length: 12,
                            spread: 23,
                        },
                    },
                }}
            />
            {children}
        </AppGuideContext.Provider>
    );
};
