import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { TaskReportFields } from "../../../../models/reportTemplates/Task";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getTaskReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getPdfUnknownFields(report, Object.values(TaskReportFields), images, true);
    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(getFieldValueByFieldName("taskDescription", report) || images.length > 0, () => ({
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.detailedInfo.taskReportDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName("taskDescription", report),
                        isRequired: false,
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        })),
        conditionalRender(unknownFields.length, () => {
            return {
                component: SummarySectionPdf,
                props: {
                    label: "reportdetails.detailedInfo.taskReportSummary",
                },
                children: unknownFields,
            };
        }),
    ];
};
