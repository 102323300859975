import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getLocationClient: builder.query<any, { locationId: string }>({
            query: ({ locationId }) => ({
                url: `/v1/organization/ancestorVerticesUntilTargetVertexLabel/${locationId}/client/site,parentOf`,
                method: "GET",
                headers: {
                    "Cache-Control": "no-cache",
                },
            }),
        }),
    }),
});

export const { useLazyGetLocationClientQuery } = organizationApi;
