import i18next from "i18next";
import { getCombinedExceptionAndTourName, getReportTemplateShortTranslation } from "src/helpers/ReportHelper";
import { splitByLastChar, splitCamelCaseToSentence, trimCamelCaseString } from "src/helpers/StringHelper";
import {
    DynamicReportPropertyType,
    DynamicReportPropertyTypeEnum,
    DynamicReportType,
    EventReportTemplateName,
    ReportTemplateEnum,
} from "src/models/ReportModel";

export const getBooleanValue = (value: string): string => {
    if (value === "true") {
        return "common.yes";
    }
    if (value === "false") {
        return "common.no";
    }
    return null;
};

const getArrayValue = (value: string): string => {
    try {
        const array = JSON.parse(value);
        return array?.join(", ") ?? "";
    } catch {
        return "";
    }
};

export const getExceptionQuestionsAnswers = (fieldValue: string): string[][] => {
    return fieldValue?.split("\n")?.map((item) => splitByLastChar(item, ":").map((x) => x.trim())) || [];
};

export const getReportTemplateName = (report: DynamicReportType): string => {
    if (report.template === ReportTemplateEnum.tourException || report.template === ReportTemplateEnum.tourMultiException) {
        const tourName = getFieldValueByFieldName("tourName", report);
        return getCombinedExceptionAndTourName(report.template, tourName);
    }
    return getReportTemplateShortTranslation(report.template);
};

export const resolveReportSubheader = (rootTemplate: string | null, childTemplate: string | null): string | null => {
    if (!rootTemplate || !childTemplate) {
        return null;
    }

    const lowerCasedRootTemplate = rootTemplate.toLowerCase();
    const lowerCasedChildTemplate = childTemplate.toLowerCase();
    if (lowerCasedRootTemplate === lowerCasedChildTemplate) {
        return null;
    }

    const REPORT_SUFFIX = " report";
    const lowerCasedNoReportPostfixRootTemplate = lowerCasedRootTemplate.endsWith(REPORT_SUFFIX)
        ? lowerCasedRootTemplate.slice(0, -REPORT_SUFFIX.length)
        : lowerCasedRootTemplate;

    if (lowerCasedNoReportPostfixRootTemplate === lowerCasedChildTemplate) {
        return null;
    }

    if (rootTemplate === ReportTemplateEnum.incident && resolveReportSubheader(EventReportTemplateName, childTemplate) === null) {
        return null;
    }

    return childTemplate;
};

export const getSiteLocation = (report: DynamicReportType): string => {
    if (report.rootSiteLocation && report.siteLocationSubLevels) {
        return `${report.rootSiteLocation} / ${report.siteLocationSubLevels}`;
    }
    return `${report.rootSiteLocation ?? ""} ${report.siteLocationSubLevels ?? ""}`.trim();
};

export const getNormalizedString = (text: string): string => {
    if (!text?.replace) {
        return text;
    }
    return text.replace(/\r\n/g, "\n").replace(/\r/g, "\n").replace("\t", " ");
};

export const getFieldValueByFieldName = (
    fieldName: string,
    {
        properties,
    }: {
        properties: DynamicReportPropertyType[];
    },
): string => {
    const property = properties.find((p) => p.reportFieldName === fieldName);
    if (property?.type === DynamicReportPropertyTypeEnum.array) {
        return getArrayValue(property?.value);
    }
    return getNormalizedString(property?.value);
};

export function getObjectFieldValueByFieldName<T>(
    fieldName: string,
    {
        properties,
    }: {
        properties: DynamicReportPropertyType[];
    },
): T {
    const property = properties.find((p) => p.reportFieldName === fieldName);
    try {
        return JSON.parse(property?.value);
    } catch {
        return {} as T;
    }
}

export const getUnknownFieldLabel = (fieldName: string): string => {
    const translationKey = `reportdetails.fields.${trimCamelCaseString(fieldName)}`;

    return i18next.exists(translationKey) ? translationKey : splitCamelCaseToSentence(fieldName);
};

export const getUnknownFieldValue = (value: string, type: DynamicReportPropertyTypeEnum): string => {
    try {
        if (type === DynamicReportPropertyTypeEnum.array) {
            return getArrayValue(value);
        }
        const booleanValue = getBooleanValue(value);
        return booleanValue ?? getNormalizedString(value);
    } catch {
        return getNormalizedString(value);
    }
};

export const getUniqueReportProperties = (report: DynamicReportType): DynamicReportPropertyType[] => {
    const uniqueProperties = report.properties.reduce(
        (processedProperties, currentProperty) => {
            const lowerCaseFieldName = currentProperty.reportFieldName?.toLowerCase();
            if (!processedProperties.lowerCaseDict.has(lowerCaseFieldName)) {
                processedProperties.lowerCaseDict.add(lowerCaseFieldName);
                processedProperties.result.push(currentProperty);
                return processedProperties;
            }
            return processedProperties;
        },
        { result: [] as DynamicReportPropertyType[], lowerCaseDict: new Set() },
    );
    return uniqueProperties.result;
};
