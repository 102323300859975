import { Palette } from "@secuis/ccp-react-components";
import { capitalize } from "lodash";
import { getMonthName, subMonths } from "src/helpers/date";

export const MonthsTick = (props?: any) => {
    const { x, y, payload, shouldAddYear = false } = props;
    let year = "";
    if (shouldAddYear && (payload.value === 1 || payload.value === 12)) {
        const monthOffset = subMonths(new Date(), payload.value - 1);
        year = monthOffset.getFullYear().toString();
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fontSize="11px" letterSpacing="0.2px" textAnchor="middle" fill={Palette.Gray100}>
                {capitalize(getMonthName(payload.value, "mid"))}
            </text>
            {year && (
                <text x={0} y={0} dy={28} fontSize="11px" letterSpacing="0.2px" textAnchor="middle" fill={Palette.Gray100}>
                    {year}
                </text>
            )}
        </g>
    );
};
