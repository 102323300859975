import { SiteTimezoneInfo } from "./SiteTimezoneInfo";

// NOTE: Place to wrap all prompts that user
// should or might see to be notified about something

export const FeatureUserPrompts = () => {
    return (
        <>
            <SiteTimezoneInfo />
        </>
    );
};
