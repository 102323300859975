import { ResponsiveType, SpacingExtendedType, Stack, Text } from "@secuis/ccp-react-components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as EmptyInboxIcon } from "../../../assets/icons/empty_inbox_icon.svg";
import { RoundedCircleBackground } from "../SpeechBubble/SpeechBubble.styled";

interface IEmptyInboxContentProps {
    title: string;
    subtitle: string;
    icon?: ReactNode;
    mt?: ResponsiveType<SpacingExtendedType>;
    mb?: ResponsiveType<SpacingExtendedType>;
    ml?: ResponsiveType<SpacingExtendedType>;
    mr?: ResponsiveType<SpacingExtendedType>;
}

const TextWrapper = styled.div`
    text-align: center;
`;

export const EmptyInboxContent = ({ title, subtitle, icon, mt = "XL", mb = "0", ml = "0", mr = "0" }: IEmptyInboxContentProps) => (
    <Stack direction="column" justifyContent="center" alignItems="center" flex={1} mt={mt} mb={mb} ml={ml} mr={mr}>
        <RoundedCircleBackground>{icon || <EmptyInboxIcon />}</RoundedCircleBackground>
        <TextWrapper data-testid="empty-inbox-content">
            <Text bold>{title}</Text>
            <Text>{subtitle}</Text>
        </TextWrapper>
    </Stack>
);

export const TranslatableEmptyInboxComponent = ({ title, subtitle, ...rest }: IEmptyInboxContentProps): JSX.Element => {
    const { t } = useTranslation();

    return <EmptyInboxContent {...rest} title={t(title)} subtitle={t(subtitle)} />;
};
