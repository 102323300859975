import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { IsoStringDate } from "src/helpers/date/types";

import { featureFlags } from "../../../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../../../hooks/featureFlags";
import { useListItemDate } from "./ListItemDate.hooks";
import { InfoContainerStyled, ListItemDateText } from "./ListItemDate.styles";

type Props = {
    reportDateTime: IsoStringDate;
    siteTimezone?: string;
};

export const ListItemDate = (props: Props) => {
    const { t } = useTranslation();
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);
    const { userLocalDate, siteLocalDate } = useListItemDate(props);

    return (
        <Stack direction="column">
            <InfoContainerStyled>
                {areSitesTimezonesEnabled && (
                    <ListItemDateText micro color="neutral">
                        {t("reportlist.yourTime")}
                    </ListItemDateText>
                )}
                <ListItemDateText micro bold data-testid="item-local-date">
                    {userLocalDate}
                </ListItemDateText>
            </InfoContainerStyled>

            {areSitesTimezonesEnabled && (
                <InfoContainerStyled>
                    <ListItemDateText micro color="neutral">
                        {t("reportlist.siteTime")}
                    </ListItemDateText>
                    <ListItemDateText data-testid="item-site-date" micro bold>
                        {siteLocalDate}
                    </ListItemDateText>
                </InfoContainerStyled>
            )}
        </Stack>
    );
};
