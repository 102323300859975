import { sha256 } from "js-sha256";
import mixpanel from "mixpanel-browser";

import { MixpanelEvent } from "../models/tracking/mixpanelEvents";
import { IState } from "../Store";
import { APP_TRACKING, TRACK_EVENT } from "../store/tracking/types";

export default ({ getState }: { getState: () => IState }) =>
    (next) =>
    async (action) => {
        const state: IState = getState();
        const {
            app: { queryParams },
            user: { info },
        } = state;

        const trackingEnabled = queryParams["analyticsEnabled"];

        if (!trackingEnabled) {
            return next(action);
        }

        switch (action.type) {
            case APP_TRACKING: {
                const { selectedClient, userType } = action.payload;
                const userId = sha256(info.id);
                mixpanel.people.set({ Clients: selectedClient.name, UserType: userType });
                mixpanel.identify(userId);
                mixpanel.track(MixpanelEvent.AppVisit, {});
                break;
            }

            case TRACK_EVENT:
                mixpanel.track(action.payload.event, action.payload.properties);
                break;
        }

        return next(action);
    };
