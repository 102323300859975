import { Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CellStyled } from "../../IncidentMatrix.styles";

export const CellWrapperStyled = styled(CellStyled)`
    padding: 0;

    &::before {
        content: none;
    }
`;

export const TotalStyled = styled(Text)`
    margin-top: 2px;
`;
