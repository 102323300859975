import { Box, Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { TableComponent } from "../../../../components/shared/Table/Table";
import { DataTimeRange } from "../../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";

export const StyledTable = styled(TableComponent)<{ columnCount: number; range: DataTimeRange; hasMobileLayout: boolean }>`
    grid-template-columns: minmax(100px, 360px) repeat(${({ columnCount }) => columnCount - 2}, auto) 48px !important;
    column-gap: 12px;
    @media (max-width: ${Breakpoints.M}) {
        grid-template-columns: minmax(${Spacing.XL}px, 264px) repeat(${({ columnCount }) => columnCount - 2}, auto) 48px !important;
    }
    @media (max-width: ${Breakpoints.S}) {
        grid-template-columns: ${({ columnCount, range, hasMobileLayout }) => {
            if (hasMobileLayout) {
                return `repeat(${columnCount - 2}, minmax(${Spacing.XL}px, auto)) !important`;
            }
            if (range === DataTimeRange.day || range === DataTimeRange.month) {
                return `minmax(148px auto) repeat(${columnCount - 2}, auto) 48px !important`;
            }
            if (range === DataTimeRange.anyRange) {
                return `repeat(2, minmax(${Spacing.XL}px, auto)) !important`;
            }
            return `repeat(${columnCount - 2}, minmax(0, 1fr)) !important`;
        }};
        min-width: ${({ hasMobileLayout, range }) =>
            hasMobileLayout && (range === DataTimeRange.week || range === DataTimeRange.halfYear) ? "max-content" : "auto"};
    }
    @media (max-width: ${Breakpoints.XS}) {
        grid-template-columns: calc(1fr + ${Spacing.S}px) repeat(${({ columnCount }) => columnCount - 3}, minmax(0, 1fr)) !important;
        min-width: ${({ range }) => (range === DataTimeRange.week || range === DataTimeRange.halfYear ? "max-content" : "auto")};
    }

    // NOTE: Workaround for anyRange total row alignment (to keep everything in one line, instead of two)
    // Related to grid layout change on line 21
    // Potentially, we could define separate config/component for each table variant and render based on conditions
    ${({ range }) =>
        range === DataTimeRange.anyRange &&
        `
        @media (max-width: ${Breakpoints.S}) {
            & > *:last-child {
                & > * {
                    display: none;

                    &:first-child,
                    &:last-child {
                        display: block;
                    }
                }
            }
        }`}
`;

export const getCategoryCellStyles = (range: DataTimeRange) => {
    if (range === DataTimeRange.halfYear || range === DataTimeRange.week) {
        return css`
            grid-column: 1/7;
            padding-bottom: ${Spacing.XXS}px;
        `;
    }
    if (range === DataTimeRange.anyRange) {
        return css`
            grid-column: 1/2;
            padding-bottom: ${Spacing.XXS}px;
        `;
    }
    if (range === DataTimeRange.month) {
        return css`
            grid-column: 1/3;
            padding-bottom: ${Spacing.XXS}px;
        `;
    }
    if (range === DataTimeRange.day) {
        return css`
            grid-column: 1/4;
            padding-bottom: ${Spacing.XXS}px;
        `;
    }
};

export const getCategoryHeaderStyles = (hasMobileLayout: boolean) => {
    if (hasMobileLayout) {
        return css`
            display: none;
        `;
    }
};

export const getExpandButtonHeaderStyles = (hasMobileLayout: boolean) => {
    if (hasMobileLayout) {
        return css`
            display: none;
        `;
    }
};

export const getExpandButtonCellStyles = (range: DataTimeRange) => {
    if (range === DataTimeRange.week || range === DataTimeRange.halfYear) {
        return css`
            @media (max-width: ${Breakpoints.S}) {
                grid-column: 7;
                justify-content: flex-end;
            }
        `;
    }
    if (range === DataTimeRange.anyRange) {
        return css`
            @media (max-width: ${Breakpoints.S}) {
                grid-column: 2;
                justify-content: flex-end;
            }
        `;
    }
    return css`
        @media (max-width: ${Breakpoints.S}) {
            justify-content: flex-end;
        }
    `;
};

export const chartCellStyles = css`
    padding-right: ${Spacing.S}px;
    @media (max-width: ${Breakpoints.S}) {
        border-top: none;
        grid-column: 1/2;
    }
    @media (max-width: ${Breakpoints.XS}) {
        padding-right: ${Spacing.XS}px;
    }
`;

export const totalAmountCellStyles = css`
    @media (max-width: ${Breakpoints.S}) {
        border-top: none;
    }
`;

export const CategoryCellStyled = styled(Stack)`
    max-width: 100%;
    padding-right: ${Spacing.XS}px;
    @media (max-width: ${Breakpoints.XS}) {
        max-width: calc(60vw - ${Spacing.L}px);
    }
`;

export const dataHeaderStyles = css`
    @media (max-width: ${Breakpoints.S}) {
    }
    @media (max-width: ${Breakpoints.XS}) {
    }
`;

export const dataCellStyles = (index: number, range: DataTimeRange, hasMobileLayout: boolean) => {
    if (range === DataTimeRange.week || range === DataTimeRange.halfYear) {
        return css`
            @media (max-width: ${Breakpoints.S}) {
                grid-column: ${index} / ${index + 1};
                border-top: none;
            }
        `;
    }
    if (hasMobileLayout) {
        return css`
            grid-column: ${index} / ${index + 1};
            border-top: none;
        `;
    }
    return css`
        @media (max-width: ${Breakpoints.XS}) {
            grid-column: ${index} / ${index + 1};
            border-top: none;
        }
    `;
};

export const DeviationsTableWrapper = styled(Box)`
    overflow-x: auto;
`;
