import { SET_FEATURE_FLAGS } from "./types";

const setFeatureFlags = (featureFlags: Record<string, boolean>) => ({
    type: SET_FEATURE_FLAGS,
    payload: featureFlags,
});

export default {
    setFeatureFlags,
};
