import { ButtonText } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useInsightsTabs } from "src/pages/Insights/InsightsTabs/InsightsTabs.hooks";

export const BackButton = () => {
    const [buttonText, setButtonText] = useState<string | null>(null);
    const [navigateTo, setNavigateTo] = useState<string | null>(null);
    const [navigateToActiveTab, setNavigateToActiveTab] = useState<number | null>(null);
    const { t } = useTranslation();
    const { setActiveTab } = useInsightsTabs();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const locationState = location?.state;

        if (locationState) {
            const { originUrl, originTitle, originActiveTab } = locationState;

            if (originUrl && originTitle) {
                setButtonText(locationState.originTitle);
                setNavigateTo(locationState.originUrl);
            }

            if (originActiveTab) {
                setNavigateToActiveTab(locationState.originActiveTab);
            }

            return;
        }
    }, [location?.state, t]);

    const handleClick = () => {
        if (!navigateTo) {
            return navigate(-1);
        }

        navigate(navigateTo);

        if (navigateToActiveTab) {
            setActiveTab(navigateToActiveTab);
        }
    };

    return (
        <ButtonText data-testid="insights-tasks-sites-page-back-navigation" color="primary" icon="BackNavigation" direction="row" onClick={handleClick}>
            {buttonText || t("insights.title")}
        </ButtonText>
    );
};
