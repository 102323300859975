import { ChartDataType } from "./types";

function getNextValue<T>(data: { value: T }[], index: number) {
    const nextIndex = index === data.length - 1 ? 0 : index + 1;
    return data[nextIndex].value;
}

function getPrevValue<T>(data: { value: T }[], index: number) {
    const prevIndex = index === 0 ? data.length - 1 : index - 1;
    return data[prevIndex].value;
}

// we look for empty or minimal items and add correction info to them
// so we can draw custom line and label in first and last empty/minimal values
export const addLineDirectionToData = (
    chartData: ChartDataType[],
    heightIndex: number,
): (ChartDataType & {
    direction?: "up" | "down";
})[] => {
    const allValues = chartData.reduce((acc, d) => d.value + acc, 0);
    // values below heightIndex will not fit next to each other
    const minValue = (allValues / 360) * heightIndex;

    // // we dont need to change graph if only 1 value is empty
    if (chartData.filter((d) => d.value <= minValue).length > 1) {
        return chartData.map((d, i) => {
            // next item for last item will be first
            // previtem for first item will be last
            const nextValue = getNextValue(chartData, i);
            const prevValue = getPrevValue(chartData, i);
            if (d.value <= minValue && nextValue <= minValue && prevValue <= minValue) {
                return d;
            }
            if (d.value <= minValue && prevValue <= minValue) {
                return {
                    ...d,
                    direction: "up",
                };
            }
            if (d.value <= minValue && nextValue <= minValue) {
                return {
                    ...d,
                    direction: "down",
                };
            }
            return d;
        });
    }
    return chartData;
};
