import { HttpResponse } from "msw";

import db from "../../db";

export const updateVisitResolver = (reportId: string, userId: string) => {
    db.reports.update({
        where: {
            id: {
                equals: reportId,
            },
        },
        data: {
            visitorIds: [userId],
            isRead: true,
        },
    });

    return new HttpResponse("success: " + userId, { status: 200, statusText: "OK" });
};

export default updateVisitResolver;
