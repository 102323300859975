import { Breakpoints, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { InvisibleScrollbar, Navy500Scrollbar } from "../../styles/templates/Scrollbar";

export const ReportListWrapper = styled(Stack).attrs({})`
    flex: 1;
    flex-direction: row-reverse;
    position: relative;
    ${Navy500Scrollbar};

    @media only screen and (max-width: ${Breakpoints.XS}) {
        ${InvisibleScrollbar}
    }
`;
