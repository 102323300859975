import { Stack, Text, Title } from "@secuis/ccp-react-components";

type Props = {
    count: string;
    label: string;
    insertComma?: boolean;
};

export const TimeValue = ({ count, label, insertComma = false }: Props) => (
    <Stack alignItems="baseline" gap="XS">
        <Title>{count}</Title>
        <Text small>
            {label}
            {insertComma ? "," : null}
        </Text>
    </Stack>
);
