import { Icon, Palette, Spacing } from "@secuis/ccp-react-components";
import { ButtonBack, ButtonNext } from "pure-react-carousel";
import styled, { css } from "styled-components";

export const ChevronStyled = styled(Icon)`
    path {
        fill: ${Palette.White};
    }
`;

const buttonStyles = css`
    position: absolute;
    top: calc(50% - (${Spacing.S}px / 2));
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:active {
        path {
            fill: ${Palette.Navy300};
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            path {
                fill: ${Palette.Navy300};
            }
        }
    }
    @media (hover: none) and (pointer: coarse) {
        height: ${Spacing.M + Spacing.XXS}px;
        width: ${Spacing.M + Spacing.XXS}px;
        background: ${Palette.Navy550};
        border-radius: 50%;
    }
`;

export const ChevronLeftContainer = styled(ButtonBack)`
    ${buttonStyles}
    left: ${Spacing.XL}px;

    svg {
        margin-right: ${Spacing.XXS / 2}px;
    }

    @media (max-width: 1096px) {
        left: ${Spacing.XS}px;
    }
`;

export const ChevronRightContainer = styled(ButtonNext)`
    ${buttonStyles}
    right: ${Spacing.XL}px;

    @media (max-width: 1096px) {
        right: ${Spacing.XS}px;
    }
`;
