import { http, HttpResponse } from "msw";

import { IDeleteCommentDto, IUpsertCommentDto } from "../../../store/reportComments/models";
import {
    deleteCommentResolver,
    disabledRequestResolver,
    getCommentsResolver,
    getReportImageResolver,
    getReportResolver,
    getTourResolver,
    shareReportResolver,
    updateVisitResolver,
    upsertCommentResolver,
} from "../../resolvers";
import {
    COMMENT_URL_REGEX,
    GET_COMMENTS_URL_REGEX,
    GET_REPORT_DETAILS_URL_REGEX,
    GET_REPORT_IMAGE,
    GET_TOUR_DETAILS_URL_REGEX,
    GET_USERS_URL_REGEX,
    GET_VISITORS_URL_REGEX,
    SHARE_REPORT_URL,
    VISIT_URL_REGEX,
} from "../constants";

export default [
    http.get(GET_COMMENTS_URL_REGEX, ({ request }) => {
        const match = request.url.match(GET_COMMENTS_URL_REGEX);
        const reportId = match[4];

        return getCommentsResolver(reportId);
    }),
    http.get(GET_REPORT_DETAILS_URL_REGEX, ({ request }) => {
        const match = request.url.match(GET_REPORT_DETAILS_URL_REGEX);
        const locationId = match[2];
        const reportId = match[4];

        return getReportResolver(reportId, locationId);
    }),
    http.get(GET_TOUR_DETAILS_URL_REGEX, ({ request }) => {
        const match = request.url.match(GET_TOUR_DETAILS_URL_REGEX);
        const locationId = match[2];
        const tourId = match[4];

        return getTourResolver(tourId, locationId);
    }),
    http.get(GET_VISITORS_URL_REGEX, () => disabledRequestResolver()),
    http.get(GET_USERS_URL_REGEX, () => HttpResponse.json([])),
    http.post(SHARE_REPORT_URL, () => shareReportResolver()),
    http.put(COMMENT_URL_REGEX, async ({ request }) => {
        const payload = (await request.json()) as IUpsertCommentDto;

        return upsertCommentResolver(payload);
    }),
    http.delete(COMMENT_URL_REGEX, async ({ request }) => {
        const payload = (await request.json()) as IDeleteCommentDto;

        return deleteCommentResolver(payload.id);
    }),
    http.put(VISIT_URL_REGEX, ({ request }) => {
        const match = request.url.match(VISIT_URL_REGEX);

        const reportId = match[2];
        const userId = match[3];

        return updateVisitResolver(reportId, userId);
    }),
    http.get(GET_REPORT_IMAGE, async ({ request }) => {
        const sasToken = process.env.REACT_APP_DEMO_IMAGES_STORAGE_TOKEN;

        if (!sasToken) {
            throw new Error("SAS Token for Demo Images Storage is not available");
        }

        const imageId = request.url.match(/(.)*\/api\/v2\/global\/reports\/attachments\/([A-Z0-9-]*)/)[2];

        if (!imageId) {
            return new HttpResponse(null, {
                status: 404,
            });
        } else {
            return getReportImageResolver(imageId, sasToken);
        }
    }),
];
