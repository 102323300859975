import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { ReportTemplateEnum } from "src/models/ReportModel";

import { isTypeWithCategory } from "../creators/createReports";
import { REPORT_CATEGORIES } from "../data";

type Category = Subcategories & {
    level1: string;
    severityLevel: number;
};

type Options = {
    level1s: string[];
};

type Subcategories = {
    level2: string;
    level3: string;
};

export const getRandomCategory = (reportType: ReportTemplateEnum, options: Options = { level1s: [] }): Category => {
    if (!isTypeWithCategory(reportType)) {
        return null;
    }

    const rootCategories = options.level1s?.length
        ? REPORT_CATEGORIES.filter(({ key }) => options.level1s.includes(key)).map(({ key }) => key)
        : REPORT_CATEGORIES.map(({ key }) => key);
    const level1 = sample(rootCategories);

    if (!level1) {
        return null;
    }
    const { level2 = null, level3 = null } = getRandomSubcategories(level1) || {};

    return {
        level1,
        level2,
        level3,
        severityLevel: level1 ? faker.number.int({ min: 0, max: 4 }) : null,
    };
};

const getRandomSubcategories = (categoryName: string): Subcategories => {
    const categoriesLevel2 = REPORT_CATEGORIES.find(({ key }) => key === categoryName).level2Categories;
    const categoryLevel2 = sample(categoriesLevel2).key;

    const categoriesLevel3 = categoriesLevel2.find(({ key }) => key === categoryLevel2).level3Categories;
    const categoryLevel3 = sample(categoriesLevel3).key;

    return {
        level2: categoryLevel2,
        level3: categoryLevel3,
    };
};

export default getRandomCategory;
