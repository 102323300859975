import { Palette, Spacing, Theme } from "@secuis/ccp-react-components";
import { CartesianGrid, Line, LineChart as RechartLineChart, ResponsiveContainer, Tooltip, XAxis, XAxisProps, YAxis, YAxisProps } from "recharts";
import { TooltipProps } from "recharts/types/component/Tooltip";

import { QaProps, StyleProps } from "../../../../../models";
import { ActiveDot } from "./ActiveDot";
import { Dot } from "./Dot";
import { useLineChart } from "./LineChart.hooks";
import { WrapperStyled } from "./LineChart.styles";
import { LineChartDataset } from "./LineChart.types";
import { LineChartTooltipPlacementProvider } from "./LineChartTooltipPlacementProvider";
import { TooltipContent as DefaultTooltipContent } from "./TooltipContent";

type Props = QaProps &
    StyleProps & {
        data: LineChartDataset[];
        TooltipContent?: (
            props: TooltipProps<number, string> & {
                onWidthChange?: (width: number) => void;
                onHeightChange?: (height: number) => void;
            },
        ) => JSX.Element;
        xAxisProps?: XAxisProps;
        yAxisProps?: YAxisProps;
    };

const LineChartContent = (props: Omit<Props, keyof QaProps & keyof StyleProps>) => {
    const { chartData, yAxisDomain, yAxisTicks, yAxisWidth, recalculateTooltipPlacement, chartRef, tooltipPosition } = useLineChart(props.data);

    const TooltipContent = props.TooltipContent ?? DefaultTooltipContent;

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight={310}>
            <RechartLineChart
                data={chartData}
                margin={{
                    bottom: Spacing.S,
                    left: Spacing.S,
                }}
                onMouseMove={recalculateTooltipPlacement}
            >
                <CartesianGrid stroke={Palette.Navy550} />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    stroke={Theme.secondary}
                    tick={{ fontSize: 12 }}
                    interval={0}
                    padding={{ left: Spacing.S, right: Spacing.S }}
                    {...props.xAxisProps}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    stroke={Theme.secondary}
                    padding={{ top: Spacing.XL, bottom: Spacing.S }}
                    domain={yAxisDomain}
                    interval={0}
                    ticks={yAxisTicks}
                    tick={{ fontSize: 12 }}
                    width={yAxisWidth}
                    orientation={"left"}
                    {...props.yAxisProps}
                />
                <Tooltip cursor={{ stroke: Palette.Purple300, strokeWidth: 1 }} position={tooltipPosition} content={<TooltipContent />} />

                {props.data.map((dataset) => (
                    <Line
                        ref={chartRef}
                        key={dataset.id}
                        type="linear"
                        dataKey={dataset.id}
                        activeDot={<ActiveDot highlightNames={dataset.highlights} />}
                        dot={<Dot highlightNames={dataset.highlights} />}
                        legendType="circle"
                        height={2}
                        stroke={Theme.gray}
                        strokeWidth={1}
                        {...dataset.customLineProps}
                    />
                ))}
            </RechartLineChart>
        </ResponsiveContainer>
    );
};

export const LineChart = (props: Props) => {
    return (
        <WrapperStyled data-testid={props["data-testid"] ?? "line-chart"} style={props.style} className={props.className}>
            <LineChartTooltipPlacementProvider>
                <LineChartContent {...props} />
            </LineChartTooltipPlacementProvider>
        </WrapperStyled>
    );
};
