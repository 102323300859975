import { LoadingIndicator } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { getUserLabel } from "../../../models/UserModel";
import { RequestStatus } from "../../../store/RequestStatus";
import { useUserInfo } from "../../../store/user/UserHooks";
import { useUsers } from "../../../store/users/UsersHooks";
import { TextWithIcon } from "./TextWithIcon";

type Props = {
    isShared: boolean;
    visitorsCount?: number;
    visitorIds?: string[];
};

export const ViewersSummary = ({ visitorIds, visitorsCount, isShared }: Props) => {
    const { t } = useTranslation();
    const { info } = useUserInfo();
    const { users, fetchStatus: viewersFetchStatus } = useUsers(visitorIds ?? []);
    const viewers = users.filter((u) => visitorIds?.includes(u?.id) && u?.id !== info?.id);

    const getText = (): { text: string; tooltip?: string } => {
        if (isShared) {
            return { text: t("reportdetails.viewers.seenReportPdf", { count: visitorsCount }) };
        }

        if (!viewers.length) {
            return { text: t("reportdetails.viewers.firstViewer") };
        }

        const lastViewer = getUserLabel(viewers[viewers.length - 1]);
        if (viewers.length === 1) {
            return { text: `${lastViewer} ${t("reportdetails.viewers.sawReport")}` };
        }

        if (viewers.length === 2) {
            const secondToLastViewer = getUserLabel(viewers[viewers.length - 2]);
            return { text: `${lastViewer} & ${secondToLastViewer} ${t("reportdetails.viewers.sawReport")}` };
        }

        const tooltip = viewers
            .map((viewer) => getUserLabel(viewer))
            .reverse()
            .join(", ");
        return { text: `${lastViewer} & ${viewers.length - 1} ${t("reportdetails.viewers.otherUsers")}`, tooltip };
    };

    const viewersReady = viewersFetchStatus === RequestStatus.success;
    const { text, tooltip } = viewersReady && getText();

    return !viewersReady ? (
        <LoadingIndicator size={"S"} />
    ) : (
        <TextWithIcon id="viewers_summary_text" color="secondary" variant="Show" iconSize="S" tooltip={tooltip}>
            {text}
        </TextWithIcon>
    );
};
