export const pickRandomNthElement = <T>(array: T[], nthElement: number): number[] => {
    if (array.length === 0) {
        throw new Error("pickRandomNthElement requires a non-empty array of elements.");
    }

    if (nthElement <= 0) {
        return [];
    }

    const totalCount = array.length;
    const taken = new Set<number>();
    const numElements = Math.floor(totalCount / nthElement); // every nth (random) element in array

    while (taken.size < numElements) {
        const randIndex = Math.floor(Math.random() * totalCount);

        if (!taken.has(randIndex)) {
            taken.add(randIndex);
        }
    }

    return Array.from(taken);
};

export default pickRandomNthElement;
