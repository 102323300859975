import { Palette } from "@secuis/ccp-react-components";

export const DEFAUT_X_AXIS_WIDTH = 50;
export const DEFAUT_X_AXIS_WIDTH_VERTICAL = 40;

export const GRAPH_MIN_HEIGHT = 260;
export const GRAPH_MAX_HEIGHT_VERTICAL = 280;

export const STYLES = {
    barChartMargin: {
        top: 6,
        right: 24,
        left: -20,
        bottom: 0,
    },
    barChartMarginVertical: {
        top: 6,
        right: 80,
        left: -30,
        bottom: 0,
    },
    tooltipWrapperStyle: {
        outline: "none",
    },
    axisStyle: {
        fontSize: "12px",
    },
    labelStyle: {
        fontSize: "12px",
        letterSpacing: "0.3px",
    },
    axisTick: {
        fill: Palette.Navy100,
    },
    barColor: {
        default: {
            default: Palette.Navy100,
            hover: Palette.Navy200,
            active: Palette.Navy300,
        },
        highlight: {
            default: Palette.Purple300,
            hover: Palette.Purple400,
            active: Palette.Purple500,
        },
    },
};
