import { faker } from "@faker-js/faker";
import { sample } from "lodash";

import { CheckpointScanType, ReportTemplateEnum } from "../../models/ReportModel";
import { TourExceptionFields } from "../../models/reportTemplates/TourException";
import { TourMultiExceptionFields } from "../../models/reportTemplates/TourMultiException";
import { SITE_LOCATION_SUB_LEVELS, SITE_LOCATIONS } from "../data";
import db from "../db";
import { getRandomCategory } from "../helpers";

type SiteObject = {
    id: string;
    countryCode: string;
};

export const createException = (
    type: ReportTemplateEnum.tourException | ReportTemplateEnum.tourMultiException,
    tourName: string,
    checkpoint: CheckpointScanType,
    questions: string[],
    siteObject: SiteObject,
    datetime: number,
): string => {
    const dateISOString = new Date(datetime).toISOString();
    const { level1, level2, level3, severityLevel } = getRandomCategory(type, { level1s: ["facility", "health_and_safety", "people_and_assets"] });

    const properties = getExceptionProperties(type, tourName, checkpoint, questions);

    const exception = {
        id: faker.string.uuid(),
        locationId: siteObject.id,
        categoryLevel1: level1,
        categoryLevel2: level2,
        categoryLevel3: level3,
        siteLocation: sample(SITE_LOCATIONS),
        siteLocationSubLevels: sample(SITE_LOCATION_SUB_LEVELS),
        commentsCount: 0,
        imageCount: 0,
        createDateTime: datetime,
        reportDateTime: dateISOString,
        submitDateTime: dateISOString,
        countryCode: siteObject.countryCode,
        type: type,
        subType: type,
        template: type,
        childTemplate: type,
        severityLevel: severityLevel,
        visitorIds: [],
        properties,
        sourceId: faker.string.numeric(6),
        isRead: false,
    };

    try {
        db.reports.create(exception);

        return exception.id;
    } catch (error) {
        throw new Error(`Failed to create exception: ${error}`);
    }
};

const getExceptionProperties = (
    type: ReportTemplateEnum.tourException | ReportTemplateEnum.tourMultiException,
    tourName: string,
    checkpoint: CheckpointScanType,
    questions: string[],
) => {
    const defaultProperties = [
        {
            key: TourExceptionFields.tourName,
            type: "text",
            value: tourName,
        },
        {
            reportFieldName: TourExceptionFields.comments,
            type: "text",
            value: `${faker.person.firstName()} ${faker.person.firstName()} has been notified.`,
        },
        {
            reportFieldName: TourExceptionFields.checkpoint,
            type: "text",
            value: checkpoint.checkpointName,
        },
    ];

    const typeSpecificProperties = getTypeSpecificProperties(type, questions);

    return [...defaultProperties, ...typeSpecificProperties];
};

const getTypeSpecificProperties = (type: ReportTemplateEnum.tourException | ReportTemplateEnum.tourMultiException, questions: string[]) => {
    switch (type) {
        case ReportTemplateEnum.tourException: {
            const [question, answer] = questions[0].split(": ");

            return [
                {
                    reportFieldName: TourExceptionFields.exceptionAnswer,
                    type: "text",
                    value: answer,
                },
                {
                    reportFieldName: TourExceptionFields.exceptionQuestion,
                    type: "text",
                    value: question,
                },
            ];
        }
        case ReportTemplateEnum.tourMultiException:
            return [
                {
                    reportFieldName: TourMultiExceptionFields.exceptionQuestions,
                    type: "text",
                    value: questions.join("\n"),
                },
            ];
        default:
            return [];
    }
};

export default createException;
