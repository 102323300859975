import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatDateTimeWithTimezone, formatTime, isWithinInterval, subMonths } from "src/helpers/date";
import { getNormalizedString } from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { TourFields } from "src/models/reportTemplates/Tour";
import { exportReportsToExcelSlice } from "src/store/reports/exportReportsToExcelSlice";
import { useLazyFetchReportsToExportQuery, useLazyPrefetchReportsToExportQuery } from "src/store/reports/reportsApi";
import { utils, writeFileXLSX, writeXLSX } from "xlsx";

import { featureFlags } from "../data/featureFlags";
import { SearchReportsRequestParams } from "../models/ReportFilterModels";
import { reportDescriptionFieldsByReportType, ReportsListItemType, ReportTemplateEnum, reportTypeTranslation, SiteZoneEnum } from "../models/ReportModel";
import AccessSelectors from "../store/access/AccessSelectors";
import AppSelectors from "../store/app/AppSelectors";
import { useFilteredSites, useReportTypeItems } from "../store/filter/FilterHooks";
import FilterSelectors from "../store/filter/FilterSelectors";
import LocationsSelector from "../store/locations/LocationsSelectors";
import ReportsSelectors from "../store/reports/ReportsSelectors";
import { useFeatureFlag } from "./featureFlags";

export const useExport = () => {
    const { t } = useTranslation();
    const locations = useSelector(LocationsSelector.getAuthorizedLocations);
    const { exportState, totalCount, reports } = useSelector(ReportsSelectors.getExportReportsStore);
    const [prefetchReports] = useLazyPrefetchReportsToExportQuery();
    const [fetchReports] = useLazyFetchReportsToExportQuery();
    const dispatch = useDispatch();
    const userId = useSelector(AccessSelectors.getUserId);
    const { siteIds: selectedSitesIds } = useFilteredSites();
    const { getReportTypeFilterSelection } = useReportTypeItems();
    const reportListFilters = useSelector(FilterSelectors.getAllFilters);
    const isInNativeHubEmbedded = useSelector(AppSelectors.getIsEmbeddedInNativeHub);
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const searchParams = useMemo(() => {
        return {
            locationIds: selectedSitesIds,
            reportCategories1: reportListFilters.selectedCategories1,
            reportCategories2: reportListFilters.selectedCategories2,
            reportCategories3: reportListFilters.selectedCategories3,
            reportTypes: reportListFilters.selectedReportTypes,
            siteLocations: reportListFilters.selectedSiteLocations,
            severityLevels: reportListFilters.selectedSeverityLevels,
            fromDateTime: reportListFilters.selectedStartDate,
            toDateTime: reportListFilters.selectedEndDate,
            excludedReportTypes: [],
        };
    }, [selectedSitesIds, reportListFilters]);

    const body: SearchReportsRequestParams = useMemo(() => {
        const reportTypeFilter = getReportTypeFilterSelection(searchParams.reportTypes);
        return {
            locationIds: selectedSitesIds,
            userId,
            reportCategories1: searchParams.reportCategories1,
            reportCategories2: searchParams.reportCategories2,
            reportCategories3: searchParams.reportCategories3,
            reportTypes: reportTypeFilter.selectedReportTypes,
            excludedReportTypes: reportTypeFilter.excludedReportTypes,
            siteLocations: searchParams.siteLocations,
            severityLevels: searchParams.severityLevels,
            from: searchParams.fromDateTime,
            to: searchParams.toDateTime,
            isReadReport: null,
        };
    }, [getReportTypeFilterSelection, searchParams, userId, selectedSitesIds]);

    const reportColumnHeaders = useMemo(
        () => ({
            reportType: t("filters.title.reportType"),
            site: t("reports.export.site"),
            categoryLevel1: t("reports.export.category1"),
            categoryLevel2: t("reports.export.category2"),
            categoryLevel3: t("reports.export.category3"),
            siteLocation: t("filters.title.siteLocation"),
            country: t("filters.locations.label"),
            date: t("datepicker.label.date"),
            time: t("reports.export.time"),
            reportDescription: t("reports.export.reportDescription"),
        }),
        [t],
    );

    const tourColumnHeaders = useMemo(
        () => ({
            reportType: t("filters.title.reportType"),
            site: t("reports.export.site"),
            categoryLevel1: t("reports.export.category1"),
            categoryLevel2: t("reports.export.category2"),
            categoryLevel3: t("reports.export.category3"),
            siteLocation: t("filters.title.siteLocation"),
            country: t("filters.locations.label"),
            date: t("datepicker.label.date"),
            time: t("reports.export.time"),
            patrolTourName: t("reports.export.patrolTourName"),
            patrolStartTime: t("reports.export.patrolStartTime"),
            patrolScanned: t("reports.export.patrolScanned"),
            patrolMissed: t("reports.export.patrolMissed"),
        }),
        [t],
    );

    const getReportType = useCallback(
        (type: string) => {
            return reportTypeTranslation[type] ? t(reportTypeTranslation[type]) : type;
        },
        [t],
    );

    const getReportCategory = useCallback((category: string | null) => (category ? t(`incident.category.${category}`) : ""), [t]);
    const getSiteZone = useCallback((siteZone: string | null) => (SiteZoneEnum[siteZone] ? t(`siteLocations.${SiteZoneEnum[siteZone]}`) : siteZone), [t]);

    const isSiteTZEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const buildReportRow = useCallback(
        (report: ReportsListItemType) => {
            let description = "";
            if (reportDescriptionFieldsByReportType.has(report.type)) {
                for (const descriptionField of reportDescriptionFieldsByReportType.get(report.type) ?? []) {
                    const value = report.properties.find((property) => property.key === descriptionField)?.value;
                    if (value) {
                        description = getNormalizedString(value);
                        break;
                    }
                }
            }

            const date = isSiteTZEnabled
                ? formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone, "midDate")
                : formatDate(report.reportDateTime);
            const time = isSiteTZEnabled
                ? formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone, "shortTime")
                : formatTime(report.reportDateTime);

            return {
                [reportColumnHeaders.reportType]: getReportType(report.type),
                [reportColumnHeaders.site]: locations.siteObjects.find((l) => l.id === report.locationId).name,
                [reportColumnHeaders.categoryLevel1]: getReportCategory(report.categoryLevel1),
                [reportColumnHeaders.categoryLevel2]: getReportCategory(report.categoryLevel2),
                [reportColumnHeaders.categoryLevel3]: getReportCategory(report.categoryLevel3),
                [reportColumnHeaders.siteLocation]: getSiteZone(report.siteLocation),
                [reportColumnHeaders.country]: t(`country.${report.countryCode.toLowerCase()}`),
                [reportColumnHeaders.date]: date,
                [reportColumnHeaders.time]: time,
                [reportColumnHeaders.reportDescription]: description,
            };
        },
        [
            isSiteTZEnabled,
            reportColumnHeaders.reportType,
            reportColumnHeaders.site,
            reportColumnHeaders.categoryLevel1,
            reportColumnHeaders.categoryLevel2,
            reportColumnHeaders.categoryLevel3,
            reportColumnHeaders.siteLocation,
            reportColumnHeaders.country,
            reportColumnHeaders.date,
            reportColumnHeaders.time,
            reportColumnHeaders.reportDescription,
            getReportType,
            locations.siteObjects,
            getReportCategory,
            getSiteZone,
            t,
        ],
    );

    const buildTourRow = useCallback(
        (report: ReportsListItemType) => {
            const checkpointsTotal = +(report.properties.find((p) => p.key === TourFields.checkpointsTotal)?.value ?? 0);
            const missedCheckpointsTotal = +(report.properties.find((p) => p.key === TourFields.missedCheckpointsTotal)?.value ?? 0);
            const scannedCount = checkpointsTotal - missedCheckpointsTotal;

            const date = isSiteTZEnabled
                ? formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone, "midDate")
                : formatDate(report.reportDateTime);
            const time = isSiteTZEnabled
                ? formatDateTimeWithTimezone(report.reportDateTime, report.siteIanaTimezone, "shortTime")
                : formatTime(report.reportDateTime);

            const tourStartDateTime = report.properties.find((p) => p.key === TourFields.startDate)?.value;
            const tourStartTime = tourStartDateTime ? tryResolveTourStartTime(tourStartDateTime, isSiteTZEnabled, report) : "";

            return {
                [tourColumnHeaders.reportType]: getReportType(report.type),
                [tourColumnHeaders.site]: locations.siteObjects.find((l) => l.id === report.locationId).name,
                [tourColumnHeaders.categoryLevel1]: getReportCategory(report.categoryLevel1),
                [tourColumnHeaders.categoryLevel2]: getReportCategory(report.categoryLevel2),
                [tourColumnHeaders.categoryLevel3]: getReportCategory(report.categoryLevel3),
                [tourColumnHeaders.siteLocation]: getSiteZone(report.siteLocation),
                [tourColumnHeaders.country]: t(`country.${report.countryCode.toLowerCase()}`),
                [tourColumnHeaders.date]: date,
                [tourColumnHeaders.time]: time,
                [tourColumnHeaders.patrolTourName]: report.properties.find((p) => p.key === TourFields.name)?.value,
                [tourColumnHeaders.patrolStartTime]: tourStartTime,
                [tourColumnHeaders.patrolScanned]: scannedCount,
                [tourColumnHeaders.patrolMissed]: report.properties.find((p) => p.key === TourFields.missedCheckpointsTotal)?.value ?? 0,
            };
        },
        [
            isSiteTZEnabled,
            tourColumnHeaders.reportType,
            tourColumnHeaders.site,
            tourColumnHeaders.categoryLevel1,
            tourColumnHeaders.categoryLevel2,
            tourColumnHeaders.categoryLevel3,
            tourColumnHeaders.siteLocation,
            tourColumnHeaders.country,
            tourColumnHeaders.date,
            tourColumnHeaders.time,
            tourColumnHeaders.patrolTourName,
            tourColumnHeaders.patrolStartTime,
            tourColumnHeaders.patrolScanned,
            tourColumnHeaders.patrolMissed,
            getReportType,
            locations.siteObjects,
            getReportCategory,
            getSiteZone,
            t,
        ],
    );

    const saveFile = useCallback(() => {
        const workbook = utils.book_new();
        const fileName = `${t("reports.export.fileName")}.xlsx`;

        const reportsByReportType = groupBy(reports, (report) => report.type);
        Object.entries(reportsByReportType).forEach(([reportType, reports]) => {
            let sheetRows = [];
            if (reportType === ReportTemplateEnum.patrolTour) {
                sheetRows = reports.map(buildTourRow);
            } else {
                sheetRows = reports.map(buildReportRow);
            }
            utils.book_append_sheet(workbook, utils.json_to_sheet(sheetRows), getReportType(reportType));
        });

        if (isInNativeHubEmbedded) {
            const base64 = writeXLSX(workbook, { type: "base64" });
            const targetOrigin = "/";
            const topic = "FILE_SAVE";
            const message = null;
            const payload = {
                topic,
                message,
                file: { base64, fileName: fileName, type: "application/vnd.ms-excel" },
                timestamp: new Date(),
            };
            window.parent.postMessage(payload, targetOrigin);
        } else {
            writeFileXLSX(workbook, `${fileName}`);
        }
        dispatch(exportReportsToExcelSlice.actions.fileCreated());
    }, [t, reports, isInNativeHubEmbedded, dispatch, getReportType, buildTourRow, buildReportRow]);

    const prefetchReportsToExport = useCallback(() => {
        prefetchReports({ body, useSiteTimezone: areSitesTimezonesEnabled });
    }, [body, prefetchReports, areSitesTimezonesEnabled]);

    const requestReports = useCallback(() => {
        fetchReports({ pageSize: totalCount, body, useSiteTimezone: areSitesTimezonesEnabled });
    }, [body, totalCount, fetchReports, areSitesTimezonesEnabled]);

    const isExceeding18Months = useMemo(() => {
        return !isWithinInterval(reportListFilters.selectedStartDate, {
            start: subMonths(new Date(), 18),
            end: new Date(),
        });
    }, [reportListFilters.selectedStartDate]);

    return {
        requestReports,
        prefetchReportsToExport,
        isExceeding18Months,
        exportState,
        totalCount,
        saveFile,
    };
};
function tryResolveTourStartTime(tourStartDateTime: string, isSiteTZEnabled: boolean, report: ReportsListItemType) {
    let isoTourStartDate = "";
    try {
        // All incoming dates share the same format.
        // First, we convert them to ISO format to make them compatible with the Date() constructor.
        // Then, we extract the UTC time and format it with the correct timezone.
        const [datePart, timePart, offset] = tourStartDateTime.split(" ");
        const [month, day, year] = datePart.split("/");
        isoTourStartDate = new Date(`${year}-${month}-${day}T${timePart}${offset}`).toISOString();
    } catch (e) {
        return tourStartDateTime;
    }

    if (isSiteTZEnabled) {
        return formatDateTimeWithTimezone(isoTourStartDate, report.siteIanaTimezone, "shortTime");
    } else {
        return formatTime(isoTourStartDate);
    }
}
