import { Breakpoints, Spacing, Title } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SwitcherBannerWrapperStyled = styled.div`
    @media only screen and (max-width: ${Breakpoints.XS}) {
        margin-right: -${Spacing.XS}px;
        margin-left: -${Spacing.XS}px;
    }
`;

export const TitleStyled = styled(Title)`
    text-transform: capitalize;
`;
