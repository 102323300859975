import { IconType } from "@secuis/ccp-react-components";
import { CalloutStatus } from "src/models/ReportModel";

export const DEFAULT_ICON = "MobileGuarding";
export const HIDDEN_STATUSES = [CalloutStatus.finished, CalloutStatus.closed];
export const timelineEventsConfig: { [k: string]: { icon: IconType; text: string } } = {
    [CalloutStatus.received]: { icon: "WarningHigh", text: "reportdetails.callout.timeline.calloutReceived" },
    [CalloutStatus.assigned]: { icon: "File", text: "reportdetails.callout.timeline.calloutAssigned" },
    [CalloutStatus.rejected]: { icon: "Error", text: "reportdetails.callout.timeline.calloutRejected" },
    [CalloutStatus.accepted]: { icon: "File", text: "reportdetails.callout.timeline.calloutAccepted" },
    [CalloutStatus.onRoute]: { icon: DEFAULT_ICON, text: "reportdetails.callout.timeline.guardOnRoute" },
    [CalloutStatus.arrivedOnSite]: { icon: DEFAULT_ICON, text: "reportdetails.callout.timeline.guardArrived" },
    [CalloutStatus.finished]: { icon: "File", text: "reportdetails.callout.timeline.calloutFinished" },
    [CalloutStatus.departed]: { icon: "LeaveBuilding", text: "reportdetails.callout.timeline.guardDeparted" },
    [CalloutStatus.closed]: { icon: "File", text: "reportdetails.callout.timeline.calloutClosed" },
    [CalloutStatus.canceled]: { icon: "Error", text: "reportdetails.callout.timeline.calloutCanceled" },
};
export const TIMELINE_CONTAINER_WIDTH = "512px";
export const LEGEND_MOBILE_PADDING = "34px";
