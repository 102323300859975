export enum ActivityLogFields {
    comments = "comments",
    wasTheActivityLoggedIntoTheJournal = "wasTheActivityLoggedIntoTheJournal",
    policeInvolved = "policeInvolved",
    additionalDescription = "additionalDescription",
    anyOpenItemsToBeAddressed = "anyOpenItemsToBeAddressed",
    passDownReportCompleted = "pass-downReportCompleted",
    debriefOfColleagueHappened = "debriefOfColleagueHappened",
    commanderPresenceOnSite = "commanderPresenceOnSite?",
    nameOfCustomer = "nameOfCustomer/Supervisor/Commander",
}
