import { HttpResponse } from "msw";

import { db } from "../../db";

export const getTourResolver = (tourId: string, locationId: string) => {
    const result = db.reports.findFirst({
        where: {
            id: {
                equals: tourId,
            },
            locationId: {
                equals: locationId,
            },
        },
    });

    return HttpResponse.json(result);
};

export default getTourResolver;
