export enum MixpanelEvent {
    AppVisit = "App visited",
    PageView = "Page View",
    InsightsLocationFilterApply = "Insights Filter By Location Applied",
    ReportListFilterOpened = "Report List Filter Opened",
    ReportListFilterApply = "Report List Filter Applied",
    ShowUnreadFilterApply = "Show Unread Filter Applied",
    CustomizeTop5IncidentsOpen = "Customize Top 5 Incidents Opened",
    CustomizeTop5IncidentsApply = "Customize Top 5 Incidents Applied",
    CustomizeTop5IncidentsReset = "Customize Top 5 Incidents Reset",
}
