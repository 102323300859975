import { ButtonText, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAutoTranslationsActions, useAutoTranslationsState } from "./AutoTranslationProvider";
import { FULLY_TRANSLATED_LABEL, PARTIALLY_TRANSLATED_LABEL, SHOW_ORIGINAL_LABEL, SHOW_TRANSLATION_LABEL, TRANSLATION_TURNED_OFF_LABEL } from "./constants";

type Props = {
    partiallyTranslated?: boolean;
};

export const AutoTranslatedContentFooter = ({ partiallyTranslated = true }: Props) => {
    const { isOn, isAnythingTranslated: shouldDisplay } = useAutoTranslationsState();
    const { toggle } = useAutoTranslationsActions();
    const { t } = useTranslation();
    const [message, buttonLabel] = isOn
        ? [partiallyTranslated ? PARTIALLY_TRANSLATED_LABEL : FULLY_TRANSLATED_LABEL, SHOW_ORIGINAL_LABEL]
        : [TRANSLATION_TURNED_OFF_LABEL, SHOW_TRANSLATION_LABEL];

    return (
        shouldDisplay && (
            <FooterWrapperStyled>
                <Stack alignItems="center" gap="XS">
                    <Icon color="secondary" variant="Translate" />
                    <Text color="secondary" micro={true}>
                        {t(message)}
                    </Text>
                </Stack>
                <ButtonText color="secondary" micro={true} onClick={toggle}>
                    {t(buttonLabel)}
                </ButtonText>
            </FooterWrapperStyled>
        )
    );
};

const FooterWrapperStyled = styled(Stack).attrs({
    paddingTop: "S",
    gap: "M",
    justifyContent: "space-between",
})`
    width: 100%;
`;
