import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { DropdownList } from "../CheckboxList/CheckboxListItem.styles";
import { ListButton } from "./ListButton";

export const DropdownListWrapper = styled.div`
    width: 100%;
    padding-bottom: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding-bottom: 0px;
    }
`;

export const CheckboxListStyled = styled(DropdownList)`
    max-height: calc(min(400px, 60vh));
    overflow-y: auto;
    margin-top: ${Spacing.S}px;
    padding-left: ${Spacing.S}px;
    padding-right: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        max-height: 100%;
    }
`;

export const ListButtonStyled = styled(ListButton)`
    margin-left: auto;
`;
