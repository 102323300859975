import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Step } from "react-joyride";
import { useLocation } from "react-router-dom";

import { UserPreference } from "../../models/UserModel";
import { useSetUserPreference, useUserInfo } from "../../store/user/UserHooks";
import { AppGuideContext } from "./AppGuideProvider";
import { AppGuideStep } from "./AppGuideSteps";

export const useInAppGuide = (): {
    startAppGuide: (steps: AppGuideStep[], tourName: string, disableScrolling?: boolean) => void;
} => {
    const { startTour, run, stopTour } = useContext(AppGuideContext);
    const { info: userInfo } = useUserInfo();
    const setUserPreference = useSetUserPreference();
    const location = useLocation();
    const isFirstRender = useRef(true);
    const appGuideProgress = useMemo(
        () => (userInfo?.preferences?.appGuideProgress ? userInfo.preferences.appGuideProgress.split(",") : []),
        [userInfo?.preferences?.appGuideProgress],
    );

    const startAppGuide = useCallback(
        (steps: Step[], tourName: string, disableScrolling?: boolean) => {
            if (!appGuideProgress.includes(tourName) && !run) {
                startTour(steps, disableScrolling);
                setUserPreference(UserPreference.AppGuideProgress, (appGuideProgress ? [...appGuideProgress, tourName] : [tourName]).toString());
            }
        },
        [appGuideProgress, run, setUserPreference, startTour],
    );

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        stopTour();
    }, [location, stopTour]);

    return { startAppGuide };
};
