import { Icon, Theme } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as CheckpointIcon } from "../../../assets/icons/checkpoints_icon.svg";
import { TooltipIconsList } from "../shared/components/TooltipIconsList";

const WhiteCheckpointIcon = styled(CheckpointIcon)`
    path {
        fill: ${Theme.primary};
    }
`;

export const SymbolsUsedTooltipContent = () => {
    const { t } = useTranslation();

    const symbols = [
        { icon: <Icon variant="Route" />, termKey: "insights.siteTasks.category.tours" },
        { icon: <Icon variant="Deviations" />, termKey: "insights.siteTasks.category.deviations" },
        { icon: <WhiteCheckpointIcon />, termKey: "insights.siteTasks.tooltip.TourDescription" },
        { icon: <Icon variant="TrendMiddle" />, termKey: "insights.siteTasks.trend" },
    ];

    return <TooltipIconsList title={t("insights.siteTasks.tooltip.symbolsUsed")} items={symbols} />;
};
