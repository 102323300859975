import { addMinutes, isFuture, isPast } from "date-fns";
import { sortByField, sortByOrder } from "src/helpers/ArrayHelper";
import { ScheduleType, TaskSchedule } from "src/store/schedule/types";

import { TableRowVariant } from "../shared/Table/Table.types";
import { STATUS_SORTING_ORDER } from "./ScheduleTable.constants";

export const sortByStatus = (tasks: TaskSchedule[]): TaskSchedule[] => sortByOrder(tasks, STATUS_SORTING_ORDER, "status");
export const sortByPosition = (tasks: TaskSchedule[]): TaskSchedule[] => [...tasks].sort(sortByField("position.name"));
export const sortByStartTime = (tasks: TaskSchedule[]): TaskSchedule[] => [...tasks].sort(sortByField("StartDateTime"));
export const sortByExpectedDuration = (tasks: TaskSchedule[]): TaskSchedule[] =>
    [...tasks].sort((a: TaskSchedule, b: TaskSchedule) => {
        return parseInt(a.expectedDuration) < parseInt(b.expectedDuration) ? -1 : 1;
    });
export const sortByScheduleType = (tasks: TaskSchedule[]): TaskSchedule[] => sortByOrder(tasks, [ScheduleType.oneTime, ScheduleType.recurring], "scheduleType");

export const getTableRowVariant = (data: TaskSchedule): TableRowVariant => {
    const dateObj = new Date(data.StartDateTime);
    const isPastTask = isPast(addMinutes(dateObj, parseInt(data.expectedDuration)));
    const isUpcoming = isFuture(dateObj);
    const isCurrent = !(isUpcoming || isPastTask);

    switch (true) {
        case isCurrent: {
            return TableRowVariant.active;
        }
        case isUpcoming: {
            return TableRowVariant.default;
        }
        case isPastTask: {
            return TableRowVariant.inactive;
        }
        default: {
            return TableRowVariant.default;
        }
    }
};
