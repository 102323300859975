import { Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { DropdownList } from "../../../../../../components/shared/CheckboxList/CheckboxListItem.styles";

export const DropdownListStyled = styled(DropdownList)`
    max-height: 300px;
    padding-left: ${Spacing.S}px;
`;

export const FilterChipsContainerStyled = styled(Stack)`
    width: 100%;
`;
