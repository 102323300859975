import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { DynamicReportType, PatrolTourType } from "../../../models/ReportModel";
import { IUser } from "../../../models/UserModel";
import { styles } from "../pdfStyles";
import { EyeIconPdf } from "./Icons";

type Props = {
    report: DynamicReportType | PatrolTourType;
    isShared: boolean;
    user: IUser;
};

export const PdfViewers = ({ report, isShared, user }: Props) => {
    const { t } = useTranslation();
    let visitorsIds: string[] = report.visitorIds ?? [];
    if (user?.id && !visitorsIds.includes(user.id)) visitorsIds = [...visitorsIds, user.id];
    const visitorsCount = !isShared ? visitorsIds.length : report.visitorsCount;

    return (
        <View style={styles.detailsIconContainer}>
            <EyeIconPdf />
            <Text style={styles.locationText}>{t("reportdetails.viewers.seenReportPdf", { count: visitorsCount })}</Text>
        </View>
    );
};
