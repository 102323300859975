import i18next from "i18next";
import detector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import { getIsProtectasUrl } from "src/helpers/ProtectasHelpers";

const i18NextOptions = {
    appNameForLocalization: "globalclient",
    localizationStorage: window.localStorage,
    cachedLocalizationPrefix: "i18next_res_",
    timestampInCacheProp: "i18nStamp",
};

const i18nextInit = (lng = "en") => {
    const isProtectasUrl = getIsProtectasUrl();

    return i18next
        .use(detector)
        .use({
            type: "postProcessor",
            name: "useProtectas",
            process: function (value: string) {
                return isProtectasUrl ? value.replace(/\b(My)?Securitas\b/g, "$1Protectas") : value;
            },
        })
        .use(initReactI18next)
        .use(ChainedBackend)
        .init(
            {
                debug: process.env.NODE_ENV === "development",
                fallbackLng: lng,
                interpolation: {
                    escapeValue: false,
                },
                //Note: we're using ''(empty string) as default namespace
                defaultNS: "",
                ns: [""],
                backend: {
                    backends: [
                        LocalStorageBackend, // primary backend, local storage serving as a cache,
                        HttpBackend,
                    ],
                    backendOptions: [
                        {
                            prefix: i18NextOptions.cachedLocalizationPrefix,
                            expirationTime: 31 * 24 * 60 * 60 * 1000, //31 days of cache,
                            store: i18NextOptions.localizationStorage,
                        },
                        {
                            loadPath:
                                process.env.REACT_APP_API_ENDPOINT +
                                "/v1/localization/translations/" +
                                i18NextOptions.appNameForLocalization +
                                "/{{lng}}/{{ns}}?format=json",
                        },
                    ],
                },
                postProcess: ["useProtectas"],
            },
            (err) => {
                if (err) {
                    console.warn("Translation error:", err);
                }
            },
        );
};

export { i18nextInit, i18next, i18NextOptions };
