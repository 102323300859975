import i18next from "i18next";

import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { IncidentFields } from "../../../models/reportTemplates/Incident";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

const V1IncidentIncidentFields = (report: DynamicReportType) => [
    {
        component: TextSummaryField,
        props: {
            fieldName: "common.policeInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.policeInvolved, report)),
            mobileDirection: "column",
        },
    },
    {
        component: TextSummaryField,
        props: {
            fieldName: "common.medicalsInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.medicalServicesInvolved, report)),
            mobileDirection: "column",
        },
    },
    {
        component: TextSummaryField,
        props: {
            fieldName: "common.fireDeptInvolved",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.fireDepartmentInvolved, report)),
            mobileDirection: "column",
        },
    },
    {
        component: TextSummaryField,
        props: {
            fieldName: "common.supervisorNotified",
            fieldValue: getBooleanValue(getFieldValueByFieldName(IncidentFields.siteSupervisorNotified, report)),
            mobileDirection: "column",
        },
    },
];

const V2IncidentIncidentFields = (report: DynamicReportType) => [
    {
        component: TextSummaryField,
        props: {
            fieldName: "reportdetails.incident.timeOfEvent",
            fieldValue:
                getFieldValueByFieldName(IncidentFields.incidentDiscoveryTime, report) ?? getFieldValueByFieldName(IncidentFields.eventDiscoveryTime, report),
            mobileDirection: "column",
            shouldAutoTranslate: false,
        },
    },
];

export const getIncidentReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.incidentReportDescription",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    title: getFieldValueByFieldName(IncidentFields.title, report),
                    description:
                        getFieldValueByFieldName(IncidentFields.description, report) ??
                        getFieldValueByFieldName(IncidentFields.altDescription, report) ??
                        getFieldValueByFieldName(IncidentFields.eventDescription, report),
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.incidentReportSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            ...conditionalRender(
                report.properties.some((prop) => prop.reportFieldName === IncidentFields.description),
                () => V1IncidentIncidentFields(report),
            ),
            ...conditionalRender(
                report.properties.some(
                    (prop) => prop.reportFieldName === IncidentFields.incidentDiscoveryTime || prop.reportFieldName === IncidentFields.eventDiscoveryTime,
                ),
                () => V2IncidentIncidentFields(report),
            ),
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(IncidentFields)),
        ],
    },
    conditionalRender(checkIfIncidentHasPersonalData(report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.incident.personalData",
            },
            private: true,
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(IncidentFields.personalData, report),
                        subtitle: i18next.t("reportdetails.incident.personalDataGdpr"),
                        mobileDirection: "column",
                        shouldAutoTranslate: false,
                    },
                },
            ],
        };
    }),
];

const checkIfIncidentHasPersonalData = (report: DynamicReportType): boolean => {
    const personalDataField = report.properties.find(({ reportFieldName }) => reportFieldName === IncidentFields.personalData);
    const personalDataValue = personalDataField && getFieldValueByFieldName(IncidentFields.personalData, report);

    return !!personalDataValue;
};
