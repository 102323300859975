import { Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack)`
    max-width: 100%;
`;

export const DlStyled = styled.dl`
    margin: 0;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: ${Spacing.XS}px;
`;

export const DtStyled = styled.dt`
    color: ${Palette.Navy100};
    margin: 0;
    white-space: nowrap;
`;

export const DdStyled = styled.dd`
    margin: 0;
    min-width: 0;
    white-space: nowrap;
`;

export const TextStyled = styled(Text).attrs({
    micro: true,
    truncate: true,
})`
    line-height: 1.2;
`;
