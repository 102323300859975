import { createSelector } from "reselect";

import { IState } from "../../Store";
import { RequestStatus } from "../RequestStatus";

const mapUsersBySiteObjectIds = ({ siteObjects: { usersBySiteObjectId: usersBySiteObjectIds } }: IState) => usersBySiteObjectIds;

const getSiteObjectUsers = (siteObjectId) =>
    createSelector([mapUsersBySiteObjectIds], (usersBySiteObjectIds) => {
        return usersBySiteObjectIds[siteObjectId] || { status: RequestStatus.undefined, users: [] };
    });

export default {
    getSiteObjectUsers,
};
