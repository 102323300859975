import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapAccessState = ({ access: acessState }: IState) => acessState;

const getAccessToken = createSelector([mapAccessState], ({ accessToken, status, accessTokenDecoded }) => ({ accessToken, status, accessTokenDecoded }));

const mapAccessTokenDecoded = ({ access: { accessTokenDecoded } }: IState) => accessTokenDecoded;

const getUserId = createSelector([mapAccessTokenDecoded], (accessTokenDecoded) => accessTokenDecoded?.UserId);

const getAccessState = createSelector([mapAccessState], (state) => state);

export default {
    getAccessState,
    getAccessToken,
    getUserId,
};
