export enum NotificationType {
    CommentMention = "CommentMention",
    TourSessionCommentMention = "TourSessionCommentMention",
    ReportShareRedirect = "ReportShareRedirect",
    TourSessionShareRedirect = "TourSessionShareRedirect",
    DailyReportSummary = "DailyReportSummary",
    SevereIncidentNotification = "SevereIncidentNotification",
    HighIncidentNotification = "HighIncidentNotification",
    ModerateIncidentNotification = "ModerateIncidentNotification",
}

export interface NotificationContextModel {
    type: NotificationType;
    reportId?: string;
    reportType?: string;
    tourSessionId?: string;
    locationId?: string;
    locationIds?: string;
    startTime?: string;
    endTime?: string;
}
