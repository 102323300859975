import { Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TextStyled = styled(Text)`
    max-height: 300px;
`;

export const SpacerStyled = styled.div`
    height: 200px;
`;
