import { Palette } from "@secuis/ccp-react-components";

import { SummaryViewModeOption, WidgetViewModeOption } from "./Summary.types";

export const SEPARATOR_COLOR = `${Palette.Navy300}4d`;

export const SUMMARY_VIEW_MODE_OPTIONS: SummaryViewModeOption[] = [
    {
        labelTranslationKey: "insights.summary.viewMode.day",
        value: "day",
    },
    {
        labelTranslationKey: "insights.summary.viewMode.month",
        value: "month",
    },
];

export const WIDGET_VIEW_MODE_OPTIONS: WidgetViewModeOption[] = [
    {
        labelTranslationKey: "insights.summary.viewMode.most",
        value: "most",
    },
    {
        labelTranslationKey: "insights.summary.viewMode.least",
        value: "least",
    },
];
