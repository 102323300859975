import { Separator } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getReportTemplateName, resolveReportSubheader } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicReportType, reportsWithCategories } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { Categories } from "./Categories";
import { HeaderStackStyled } from "./Common.styled";
import { LocationHeaderLine } from "./LocationHeaderLine";
import { ReportDetailsHeaderLine1 } from "./ReportDetailsHeaderLine1";
import { TemplateHeadline } from "./TemplateHeadline";

type Props = {
    report: DynamicReportType;
    siteObject: ISiteObject;
    isShared: boolean;
};

export const ReportDetailsHeader = ({ report, siteObject, isShared }: Props) => {
    const { t } = useTranslation();
    const localizedTemplate = getReportTemplateName(report);
    const hasCategory = useMemo(() => {
        return reportsWithCategories.includes(report.template) && !!report.categoryLevel1;
    }, [report]);

    return (
        <HeaderStackStyled data-testid="name-detail-view">
            <TemplateHeadline
                header={localizedTemplate}
                subheader={resolveReportSubheader(report.template, report.childTemplate)}
                sideText={`${t("reportlist.subtitle.report")} # ${report.sourceId}`}
            />
            <Separator mb="XS" />
            {hasCategory && (
                <>
                    <Categories report={report} />
                    <Separator mv="XS" />
                </>
            )}
            <ReportDetailsHeaderLine1 report={report} siteObject={siteObject} isShared={isShared} />
            <Separator mv="XS" />
            <LocationHeaderLine siteObject={siteObject} />
            <Separator mt="XS" />
        </HeaderStackStyled>
    );
};
