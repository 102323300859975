import { Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ChipFilter } from "src/components/shared/ChipFilter/ChipFilter";

import { getLeafs } from "../../../helpers/MultiDropdownHelper";
import InsightsActions from "../../../store/insights/InsightsActions";
import { useSiteObjectItemsByRegions } from "../../../store/siteObjects/SiteObjectsHooks";

export const SummaryChips = ({ sitesCount }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { sitesByRegions } = useSiteObjectItemsByRegions();

    const allSitesSelected = useMemo(() => {
        return getLeafs(sitesByRegions.items).length === sitesCount;
    }, [sitesByRegions, sitesCount]);

    const isActive = sitesCount && !allSitesSelected;

    const handleSetAllSites = () => {
        dispatch(InsightsActions.selectLocations([]));
    };

    return (
        <Stack gap="XS" flexWrap="wrap">
            <ChipFilter
                data-test-id="filter-chip-locations"
                label={isActive ? `${t("filters.locations.labelPlural")} (${sitesCount})` : t("filters.allSites")}
                onRemove={isActive ? () => handleSetAllSites() : null}
            />
        </Stack>
    );
};
