import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { SharedReportWrapper } from "./SharedReportPage.styles";

export const SingleReportWrapper = styled(SharedReportWrapper)`
    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        margin-left: ${Spacing.M}px;
    }
`;
