import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Palette, Spacing } from "@secuis/ccp-react-components";

import { getTimeValueProps } from "../../Timeline/Timeline.helpers";
import { PdfTimeValue } from "./PdfTimeValue";

const styles = StyleSheet.create({
    legendItem: {
        display: "flex",
        flexDirection: "column",
        columnGap: Spacing.XXS,
        padding: `${Spacing.XS}px ${Spacing.XS}px  ${Spacing.XXXS}px ${Spacing.XS}px `,
        alignItems: "flex-start",
        borderRadius: 4,
        backgroundColor: Palette.Navy100,
    },
    legendLabel: {
        fontWeight: 700,
        fontSize: 14,
    },
    timeItems: {
        display: "flex",
        flexDirection: "row",
        columnGap: Spacing.XXS,
    },
});

type Props = {
    label: string;
    value: {
        hours: string;
        minutes: string;
    };
};

export const PdfLegendItem = ({ label, value }: Props) => {
    const { hours, minutes } = value;

    return (
        <View style={styles.legendItem}>
            <Text style={styles.legendLabel}>{label}</Text>
            <View style={styles.timeItems}>
                {hours ? <PdfTimeValue {...getTimeValueProps(hours)} insertComma /> : null}
                <PdfTimeValue {...getTimeValueProps(minutes)} />
            </View>
        </View>
    );
};
