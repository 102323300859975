import { Breakpoints, GraphColor, GraphIcon, GraphIconType, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

type TooltipEntry = {
    label: string;
    value: string | number;
    icon: GraphIconType;
    color: GraphColor;
};
type Props = {
    title: string;
    data: TooltipEntry[];
};

const Container = styled(Stack)`
    background-color: ${Palette.Navy750};
    border-radius: 4px;
    padding: ${Spacing.XS}px;
    max-width: 240px;

    @media (max-width: ${Breakpoints.XS}) {
        max-width: 160px;
    }
`;

export const LineChartTooltip = ({ title, data }: Props) => {
    return (
        <Container direction="column">
            <Stack mb="XXS">
                <Text micro>{title}</Text>
            </Stack>
            {data.map((d) => {
                return (
                    <Stack key={`${d.label}_${d.value}`} alignItems="center" gap="XS">
                        <GraphIcon color={d.color} variant={d.icon} />
                        <Text micro truncate>
                            {`${d.label}: ${d.value}`}
                        </Text>
                    </Stack>
                );
            })}
        </Container>
    );
};
