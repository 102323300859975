import { Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { Theme } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { styles } from "../../components/PdfReports/pdfStyles";
import { TranslationIconPdf } from "../../components/PdfReports/shared/Icons";
import { useAutoTranslationsState } from "./AutoTranslationProvider";
import { FULLY_TRANSLATED_LABEL, PARTIALLY_TRANSLATED_LABEL } from "./constants";

type Props = {
    partiallyTranslated?: boolean;
    style?: Style | Style[];
};

export const AutoTranslatedContentFooterPdf = ({ partiallyTranslated = true, style }: Props) => {
    const { isOn, isAnythingTranslated } = useAutoTranslationsState();
    const { t } = useTranslation();

    return (
        isOn &&
        isAnythingTranslated && (
            <View style={{ ...styles.flexRow, alignItems: "center", ...style }}>
                <TranslationIconPdf />
                <Text style={{ color: Theme.dark }}>{t(partiallyTranslated ? PARTIALLY_TRANSLATED_LABEL : FULLY_TRANSLATED_LABEL)}</Text>
            </View>
        )
    );
};
