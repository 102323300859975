import { Headline } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getGraphMaxValue } from "src/helpers/graphs";
import { QaProps } from "src/models";

import { Graph, GraphWrapperStyled, Widget } from "../shared/components";
import { useDeviantDayWidget } from "./DeviantDayWidget.hooks";

type Props = QaProps;

export const DeviantDayWidget = (props: Props) => {
    const { t } = useTranslation();
    const { chartData, isLoading, maxDataValue, shouldHighlightMaxDataValue } = useDeviantDayWidget();
    const graphMaxValue = getGraphMaxValue(maxDataValue);
    const extraInfo = useMemo(() => {
        if (!chartData.length) {
            return null;
        }
        const maxValues = chartData.filter((d) => d.value === maxDataValue);
        // display additional info only when 1 data item is peak
        if (maxValues.length !== 1) {
            return null;
        }
        return maxValues[0].label;
    }, [chartData, maxDataValue]);

    return (
        <Widget
            {...props}
            loading={isLoading}
            empty={!isLoading && maxDataValue === 0}
            title={t("insights.summary.deviantDay")}
            tooltip={t("insights.summary.deviantDayWidget.tooltip")}
            subtitle={
                <Headline bold color="secondary">
                    {extraInfo}
                </Headline>
            }
        >
            <GraphWrapperStyled mt="M">
                <Graph
                    tickCount={3}
                    highlightedValue={shouldHighlightMaxDataValue ? maxDataValue : null}
                    data={chartData}
                    maxValue={graphMaxValue}
                    barSize="sm"
                />
            </GraphWrapperStyled>
        </Widget>
    );
};
