import { Text } from "@secuis/ccp-react-components";

type Props = {
    cell: {
        id: string;
        name: string;
    };
};

export const PositionCell = ({ cell }: Props) => <Text small>{cell?.name || "-"}</Text>;
