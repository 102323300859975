import { max, maxBy } from "lodash";
import { useMemo } from "react";
import { getAxisTicks, getGraphMaxValue } from "src/helpers/graphs";

import { LineChartDataset } from "./LineChart.types";
import { useLineChartTooltipPlacement } from "./LineChartTooltipPlacementProvider";

export const useLineChart = (datasets: LineChartDataset[]) => {
    const chartData = useParsedDatasets(datasets);
    const { chartRef, tooltipPosition, recalculateTooltipPlacement } = useLineChartTooltipPlacement();

    const { yAxisDomain, yAxisTicks } = useMemo(() => {
        const maxValue = getBiggestValueFromDatasets(datasets);
        const graphMaxValue = getGraphMaxValue(maxValue);

        return {
            yAxisDomain: [0, graphMaxValue],
            yAxisTicks: getAxisTicks(graphMaxValue, 5),
        };
    }, [datasets]);

    const yAxisWidth = yAxisDomain[1].toString().length * 7 + 10;

    return {
        chartData,
        yAxisDomain,
        yAxisTicks,
        yAxisWidth,
        chartRef,
        tooltipPosition,
        recalculateTooltipPlacement,
    };
};

const useParsedDatasets = (datasets: LineChartDataset[]) => {
    if (!datasets.length) {
        return [];
    }

    const groupIndexMap = {};
    const parsedData = datasets[0].values.map(({ key }, index) => {
        groupIndexMap[key] = index;
        return { name: key };
    });

    datasets.map((dataset) => {
        const valueKey = dataset.id;

        dataset.values.forEach(({ key, value }) => {
            const groupIndex = groupIndexMap[key];
            if (groupIndex !== undefined) {
                parsedData[groupIndex] = { ...parsedData[groupIndex], [valueKey]: value };
            }
        });
    });

    return parsedData;
};

const getBiggestValueFromDatasets = (datasets: LineChartDataset[]): number => {
    return max(datasets.map(({ values }) => maxBy(values, "value")?.value ?? 0));
};
