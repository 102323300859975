import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapAuthorizedLocations = ({ locations }: IState) => locations;
const getAuthorizedLocations = createSelector([mapAuthorizedLocations], (locations) => locations);
const getSiteObjects = createSelector([mapAuthorizedLocations], ({ siteObjects }) => siteObjects);

const getAuthorizedLocationById = (id: string) =>
    createSelector([mapAuthorizedLocations], (locations) => {
        return !id ? null : locations.siteObjects.find((l) => l.id === id);
    });

const selectors = {
    getAuthorizedLocations,
    getAuthorizedLocationById,
    getSiteObjects,
};
export default selectors;
