import "pure-react-carousel/dist/react-carousel.es.css";

import { cloneElement, ReactChild, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Carousel as VendorCarousel } from "react-responsive-carousel";
import styled from "styled-components";

const CarouselWrapper = styled.div`
    max-width: 100%;
`;

type PropsType = {
    dataLength: number;
    itemWidth: number;
    nextButton: ReactElement;
    prevButton: ReactElement;
    onClickItem?: (index: number) => void;
    children: ReactNode[];
};

const Carousel = ({ dataLength, itemWidth, children, onClickItem, nextButton, prevButton }: PropsType) => {
    const [currentPercentage, setCurrentPercentage] = useState(20);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [carouselItemSkip, setCarouselItemSkip] = useState(3);
    const [carouselItemCount, setCarouselItemCount] = useState(0);
    const [carouselCapacity, setCarouselCapacity] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current?.clientWidth) {
            setCurrentPercentage((itemWidth / containerRef.current?.clientWidth) * 100);
            setCarouselCapacity(Math.floor(containerRef.current?.clientWidth / itemWidth));
            setCarouselItemSkip(Math.round(containerRef.current?.clientWidth / itemWidth / 2));
        }
    }, [containerRef.current?.clientWidth, itemWidth]);

    useEffect(() => {
        if (carouselItemCount !== dataLength) {
            setCarouselItemCount(dataLength);
        }
    }, [dataLength, carouselItemCount]);

    const onClickNext = useCallback(() => {
        if (currentIndex < carouselItemCount - carouselItemSkip) {
            setCurrentIndex((prev) => (prev === 0 ? carouselItemSkip : prev + 1));
        }
    }, [currentIndex, carouselItemCount, carouselItemSkip]);

    const onClickPrev = useCallback(() => {
        setCurrentIndex((prev) => (prev === carouselItemSkip ? 0 : prev - 1));
    }, [carouselItemSkip]);

    const renderArrowNext = useCallback(
        (_onClickHandler, _hasNext, label) => {
            if (currentIndex < carouselItemCount - carouselItemSkip && carouselItemCount > carouselCapacity) {
                return cloneElement(nextButton, { onClick: onClickNext, title: label });
            }
            return null;
        },
        [currentIndex, carouselItemCount, carouselItemSkip, carouselCapacity, onClickNext, nextButton],
    );

    const renderArrowPrev = useCallback(
        (_onClickHandler, _hasPrev, label) => {
            if (currentIndex > 0) {
                return cloneElement(prevButton, { onClick: onClickPrev, title: label });
            }
            return null;
        },
        [currentIndex, onClickPrev, prevButton],
    );

    return (
        <CarouselWrapper ref={containerRef}>
            <VendorCarousel
                showIndicators={false}
                showStatus={false}
                swipeable={false}
                centerMode
                showThumbs={false}
                infiniteLoop={false}
                onClickItem={onClickItem}
                centerSlidePercentage={currentPercentage}
                selectedItem={currentIndex}
                renderArrowNext={renderArrowNext}
                renderArrowPrev={renderArrowPrev}
            >
                {children as ReactChild[]}
            </VendorCarousel>
        </CarouselWrapper>
    );
};

export default Carousel;
