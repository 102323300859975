import { Palette } from "@secuis/ccp-react-components";

export const MobileBreakpoints = {
    LandscapeHeight: 480,
};

export const FocusStyles = {
    Outline: `4px solid ${Palette.Purple400}`,
    OutlineColor: Palette.Purple400,
    OutlineOffset: 1,
    OutlineOffsetNegative: -2,
    BorderRadius: 1,
};
