import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IImageData, ImageSizePaths } from "../../../models/ReportImagesModels";
import ReportImagesCarousel from "../ReportImagesCarousel";
import { StyledText } from "./Description.styles";
import { StandaloneSection } from "./Shared.styles";

type PropsType = {
    imagesData: IImageData[];
    sharingId: string;
    isRequired?: boolean;
    imageSize?: ImageSizePaths;
    className?: string;
};

export const PicturesSection = ({ imagesData = [], sharingId, isRequired = true, imageSize = ImageSizePaths.Small, className }: PropsType) => {
    const { t } = useTranslation();

    const Content = useMemo(() => {
        if (!imagesData.length) {
            return isRequired ? <StyledText>{t("common.noDataFound")}</StyledText> : null;
        }
        return <ReportImagesCarousel imagesData={imagesData} sharingId={sharingId} imageSize={imageSize} className={className} />;
    }, [className, imageSize, imagesData, isRequired, sharingId, t]);

    return <StandaloneSection>{Content}</StandaloneSection>;
};
