import { createSelector } from "reselect";

import { IState } from "../../../Store";

const mapLocationToursState = ({ insights: { locationToursState } }: IState) => locationToursState;

const getLocationTours = createSelector([mapLocationToursState], (locationToursState) => locationToursState.tours);
const getLocationToursQueryStatus = createSelector([mapLocationToursState], (locationToursState) => locationToursState.queryStatus);
const getLocationToursQueryKey = createSelector([mapLocationToursState], (locationToursState) => locationToursState.queryKey);

export { getLocationTours, getLocationToursQueryStatus, getLocationToursQueryKey };
