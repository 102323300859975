import { differenceInMilliseconds } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { endOfDay, subDays } from "src/helpers/date";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

type DatePeriod = {
    start: Date;
    end: Date;
};

type useDatePeriodResult = {
    currentPeriod: DatePeriod;
    previousPeriod: DatePeriod;
};

export const useDatePeriod = (): useDatePeriodResult => {
    const currentPeriodStart = useSelector(InsightsSelectors.getSelectedStartDate);
    const currentPeriodEnd = useSelector(InsightsSelectors.getSelectedEndDate);

    const { previousPeriodStart, previousPeriodEnd } = useMemo(() => {
        return calculatePreviousPeriod(currentPeriodStart, currentPeriodEnd);
    }, [currentPeriodStart, currentPeriodEnd]);

    return {
        currentPeriod: { start: currentPeriodStart, end: currentPeriodEnd },
        previousPeriod: { start: previousPeriodStart, end: previousPeriodEnd },
    };
};

const calculatePreviousPeriod = (start: Date, end: Date): { previousPeriodStart: Date; previousPeriodEnd: Date } => {
    const millisecondsDifference = differenceInMilliseconds(end, start);
    const previousPeriodEnd = endOfDay(subDays(start, 1));
    const previousPeriodStart = new Date(previousPeriodEnd.getTime() - millisecondsDifference);

    return { previousPeriodStart, previousPeriodEnd };
};
