import { Spacing, Stack, Toggle } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const UnreadReportsWrapperStyled = styled(Stack)`
    > div:first-child() {
        width: auto;
    }

    > div {
        justify-content: flex-end;
    }

    div[class*="checkboxContainer"] {
        label {
            white-space: normal;
        }
    }
`;

export const ToggleStyled = styled(Toggle)`
    &:focus-visible {
        border-radius: ${Spacing.M}px;
    }
`;
