import { Breakpoints, Palette, Search, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Navy500Scrollbar } from "../../styles/templates/Scrollbar";

export const GridWrapper = styled.form`
    display: flex;
    flex-direction: column;
    padding-bottom: ${Spacing.S}px;
    height: 100%;
    width: 50%;
    margin-left: 50%;
    transform: translateX(-50%);

    ${Navy500Scrollbar}

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        margin: 0;
        transform: unset;
    }
`;

export const FieldsetStyled = styled.fieldset`
    display: flex;
    justify-self: center;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    min-inline-size: min-content;
    border: none;
    overflow-y: auto;
`;

export const SectionContainer = styled(Stack)<{ isMobile: boolean }>`
    justify-self: center;
    ${(props) => (props.isMobile ? `width: 100vw` : `width: 50vw`)}
`;

export const ClientRowStyled = styled(Stack).attrs<{ selected?: boolean }>({
    p: "S",
    justifyContent: "space-between",
})<{ selected: boolean }>`
    background-color: ${Palette.Navy750};
    border-radius: 4px;
    border: solid 2px ${Palette.Navy750};

    &:hover {
        background-color: ${Palette.Navy800};
        border-color: ${Palette.Navy800};
    }
    &:active {
        background-color: ${Palette.Navy850};
        border-color: ${Palette.Navy850};
    }

    @media (max-width: ${Breakpoints.XS}) {
        &:hover,
        &:active {
            background-color: ${Palette.Navy750};
            border-color: ${Palette.Navy750};
        }
    }

    ${({ selected }) =>
        selected &&
        `
        border-color: ${Palette.Purple300};

        &:hover {
            border-color: ${Palette.Purple400};
        }
        &:active {
            border-color: ${Palette.Purple500};
        }
    `}
`;

export const SearchStyled = styled(Search)`
    &:focus-visible {
        outline: none;
    }
`;
