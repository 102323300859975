import { useTranslation } from "react-i18next";

import { getReportTemplateName, resolveReportSubheader } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicReportType } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { IUser } from "../../../../models/UserModel";
import { HeaderLocationLine } from "../../shared/HeaderLocationLine.tsx";
import { PdfIndentSection } from "../../shared/PdfIndentSection";
import { PdfReportDetailsHeaderLine1 } from "../../shared/PdfReportDetailsHeaderLine1";
import { PdfTemplateHeadline } from "../../shared/PdfTemplateHeadline";
import { PdfCarHeaderLine } from "./PdfCarHeaderLine";

type Props = {
    report: DynamicReportType;
    siteObject: ISiteObject;
    isShared: boolean;
    user: IUser;
};

export const PdfVehicleInspectionDetailsHeader = ({ report, siteObject, isShared, user }: Props) => {
    const { t } = useTranslation();

    const localizedTemplate = getReportTemplateName(report);

    return (
        <PdfIndentSection>
            <PdfTemplateHeadline
                header={localizedTemplate}
                subheader={resolveReportSubheader(report.template, report.childTemplate)}
                sideText={`${t("reportlist.subtitle.report")} # ${report.sourceId}`}
            />
            <PdfCarHeaderLine report={report} />
            <PdfReportDetailsHeaderLine1 report={report} isShared={isShared} user={user} />
            <HeaderLocationLine siteObject={siteObject} />
        </PdfIndentSection>
    );
};
