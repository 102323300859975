import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getConfigPatrolTourPdf, getConfigPdf } from "../../components/Reports/reportConfigs/DynamicTemplateConfig";
import { generateReportComponents } from "../../components/Reports/reportConfigs/DynamicTemplateUtils";
import { checkIfUnknown } from "../../helpers/ReportHelper";
import { IImageData, ImageSizePaths } from "../../models/ReportImagesModels";
import { DynamicReportPropertyTypeEnum, DynamicReportType, isPatrolTour, PatrolTourType, ReportTemplateEnum } from "../../models/ReportModel";
import UserSelectors from "../user/UserSelectors";
import { useLazyAsyncGeneratePieChartQuery } from "./reportsApi";
import { useReportImages } from "./ReportsHooks";
import ReportsSelectors from "./ReportsSelectors";

const DEFAULT_PROPERTY_IMAGE_SIZE = ImageSizePaths.Small;

export const usePdfReportImages = (report: DynamicReportType | PatrolTourType, sharingId?: string, forceFetch = true) => {
    const reportPropertyImages = useMemo(() => {
        if (isPatrolTour(report)) {
            return [];
        }

        return report?.properties?.filter((p) => p.type === DynamicReportPropertyTypeEnum.image).map((p) => p.value as unknown as IImageData) ?? [];
    }, [report]);

    const reportImages = report["images"] ?? [];
    const { reportImages: base64reportImages } = useReportImages(reportImages, ImageSizePaths.Large, sharingId, forceFetch);

    let propertyImageSize = DEFAULT_PROPERTY_IMAGE_SIZE;
    if (!isPatrolTour(report) && report.template == ReportTemplateEnum.vehicleInspection) {
        propertyImageSize = ImageSizePaths.Medium;
    }
    const { reportImages: base64reportPropertyImages } = useReportImages(reportPropertyImages, propertyImageSize, sharingId, forceFetch);

    const isLoading = useMemo(() => {
        const areReportImagesLoading = reportImages.length > 0 && (base64reportImages.length === 0 || base64reportImages.some((image) => image.loading));
        const arePropertyImagesLoading =
            reportPropertyImages.length > 0 && (base64reportPropertyImages.length === 0 || base64reportPropertyImages.some((image) => image.loading));

        return areReportImagesLoading || arePropertyImagesLoading;
    }, [reportImages.length, base64reportImages, reportPropertyImages.length, base64reportPropertyImages]);

    return {
        isLoading,
        images: [...base64reportPropertyImages, ...base64reportImages],
    };
};

const checkIfNotEmptyOrTransparentAsync = (base64String): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = base64String;
        setTimeout(() => {
            if (img.width === 0 || img.height === 0) resolve(false);
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            resolve(data.some((x) => x !== 0));
        });
    });
};

const useIsPieChartPdfReady = (report) => {
    const [isPieChartReady, setIsPieChartReady] = useState(!isPatrolTour(report));
    const pie = isPatrolTour(report) ? report.pdfPieChart : null;

    useEffect(() => {
        const checkPieChart = async () => {
            if (isPatrolTour(report) && report.pdfPieChart) {
                const result = await checkIfNotEmptyOrTransparentAsync(pie);
                setIsPieChartReady(result);
            }
        };

        checkPieChart();
    }, [report, pie]);

    return { isPieChartReady };
};

export const usePdfComponents = (report, sharedReportId, siteObject) => {
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const autoTranslationsState = useSelector(ReportsSelectors.getAutotranslationState);
    const { images } = usePdfReportImages(report, sharedReportId);
    const { isPieChartReady } = useIsPieChartPdfReady(report);
    const [getPieChart] = useLazyAsyncGeneratePieChartQuery();

    const patrolTourPieChart = isPatrolTour(report) ? report.pdfPieChart : "";

    const pdfReportComponents = useMemo(() => {
        if (!report) {
            return [];
        }
        return generateReportComponents(
            {
                report,
                siteObject,
                images,
                sharedReportId,
                user,
            },
            isPatrolTour(report) ? getConfigPatrolTourPdf : getConfigPdf,
        );
        // NOTE: Refresh pdf structure when autoTranslated sections state is changed
        // eslint-disable-next-line
    }, [report, siteObject, images, sharedReportId, user, autoTranslationsState]);

    const PDF = useMemo(() => {
        if (report && pdfReportComponents?.length !== 0 && (isPatrolTour(report) || !checkIfUnknown(report.template))) {
            return pdfReportComponents;
        }
        return null;
    }, [report, pdfReportComponents]);

    useEffect(() => {
        if (isPatrolTour(report)) {
            getPieChart();
        }
    }, [report, patrolTourPieChart]);

    return isPieChartReady ? PDF : null;
};
