import { useEffect, useState } from "react";
import { useMemoizedDependency } from "src/hooks/useMemoizedDependency";

type PaginationProps<T> = {
    items: T[];
    itemsPerPage: number;
};

export const usePagination = <T>({ items, itemsPerPage }: PaginationProps<T>) => {
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentItems, setCurrentItems] = useState<T[]>([]);

    const getItemsRange = (currentPage: number, itemsPerPage: number): { start: number; end: number } => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;

        return {
            start,
            end,
        };
    };

    const memoizedItems = useMemoizedDependency(items);

    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages);
        } else {
            const { start, end } = getItemsRange(currentPage, itemsPerPage);
            setCurrentItems(memoizedItems.slice(start, end));
        }
    }, [memoizedItems, currentPage, itemsPerPage, totalPages]);

    const showPreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const showNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    return {
        totalPages,
        currentPage,
        showPreviousPage,
        showNextPage,
        currentItems,
    };
};
