import { Icon, Stack, Text } from "@secuis/ccp-react-components";

type Props = {
    cell: string;
};

export const TaskCell = ({ cell }: Props) => {
    if (!cell) {
        return <Text>-</Text>;
    }
    return (
        <Stack gap="XXXS" alignItems="center">
            <Icon variant="OtherTasks" />
            <Text>{cell}</Text>
        </Stack>
    );
};
