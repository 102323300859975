import { Breakpoints, ButtonText, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

export const StyledStack = styled(Stack)`
    @media (min-width: ${Breakpoints.XS}) {
        align-self: flex-end;
    }
`;

export const EmptyStateContainer = styled(Stack)`
    background-color: ${Palette.Navy750};
    border-radius: 4px;
`;

export const ButtonTextStyled = styled(ButtonText)`
    &:focus-visible {
        outline-offset: ${FocusStyles.OutlineOffset}px;
        border-radius: ${FocusStyles.BorderRadius}px;
    }
`;

const HeaderContainerMobileStyles = `
    gap: ${Spacing.S}px;
    flex-direction: column;
`;

export const HeaderContainerStyled = styled(Stack).attrs({
    justifyContent: "space-between",
    mb: "M",
    mt: "S",
})<{ isFilterActive: boolean }>`
    gap: 0;
    flex-direction: row;

    @media (max-width: ${Breakpoints.S}) {
        ${(props) => (props.isFilterActive ? HeaderContainerMobileStyles : "")}
    }

    @media (max-width: ${Breakpoints.XS}) {
        ${HeaderContainerMobileStyles}
    }
`;
