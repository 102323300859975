import { Icon, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import { TooltipIconsList } from "../../shared/components/TooltipIconsList";
import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";

const CATEGORIES: { icon: JSX.Element; termKey: string }[] = [
    CategoryLevel1Keys.criminalActOrSuspiciousBehavior,
    CategoryLevel1Keys.facility,
    CategoryLevel1Keys.peopleAndAssets,
    CategoryLevel1Keys.healthAndSafety,
].map((categoryKey) => ({
    icon: <Icon variant={REPORT_CATEGORY_ICON_MAP[categoryKey]} />,
    termKey: `incident.category.${categoryKey}`,
}));

type Props = {
    categoryLevel?: 1 | 2;
};

export const TooltipContent = ({ categoryLevel }: Props) => {
    const { t } = useTranslation();

    if (categoryLevel === 2) {
        return t("insights.sitesPage.barchart.tooltip.level3.label");
    } else if (categoryLevel === 1) {
        return t("insights.sitesPage.barchart.tooltip.level2.label");
    } else {
        return (
            <Stack gap="XS" direction="column">
                <span>{t("insights.sitesPage.barchart.tooltip.level1.label")}</span>
                <TooltipIconsList items={CATEGORIES} title={t("insights.sitesPage.barchart.tooltip.categoriesHeader")} />
            </Stack>
        );
    }
};
