import { GraphColor, GraphIconType } from "@secuis/ccp-react-components";

export enum CategoryKeys {
    total = "total_count",
    criminalAct = "criminal_act_count",
    facility = "facility_count",
    healthAndSafety = "health_and_safety_count",
    internalSecuritas = "internal_securitas_count",
    peopleAndAssets = "people_and_assets_count",
    unspecified = "unspecified_count",
}

export type ChartCategoryKeyType = {
    key: CategoryKeys;
    isActive: boolean;
    categoryUrlParameterKey?: string;
};

export type ChartCategoryConfigType = {
    color: GraphColor;
    iconVariant: GraphIconType;
    strokeDasharray?: string;
};

export type ChartCategoryType = ChartCategoryKeyType &
    ChartCategoryConfigType & {
        label?: string;
    };

export const chartConfig: ChartCategoryConfigType[] = [
    {
        color: "total",
        iconVariant: "GraphTotal",
    },
    {
        color: "dataSeries1",
        iconVariant: "GraphDataSeries1",
        strokeDasharray: "8 4",
    },
    {
        color: "dataSeries2",
        iconVariant: "GraphDataSeries2",
        strokeDasharray: "3 4",
    },
    {
        color: "dataSeries3",
        iconVariant: "GraphDataSeries3",
        strokeDasharray: "1 4",
    },
    {
        color: "dataSeries4",
        iconVariant: "GraphDataSeries4",
        strokeDasharray: "3 2 1 2",
    },
    {
        color: "dataSeries5",
        iconVariant: "GraphDataSeries5",
        strokeDasharray: "5 4",
    },
    {
        color: "dataSeries6",
        iconVariant: "GraphDataSeries6",
    },
];

export const exceptionTrendsChartCategories: ChartCategoryKeyType[] = [
    {
        key: CategoryKeys.total,
        isActive: true,
    },
    {
        key: CategoryKeys.criminalAct,
        isActive: true,
        categoryUrlParameterKey: "criminal_act_or_suspicious_behavior",
    },
    {
        key: CategoryKeys.facility,
        isActive: true,
        categoryUrlParameterKey: "facility",
    },
    {
        key: CategoryKeys.healthAndSafety,
        isActive: true,
        categoryUrlParameterKey: "health_and_safety",
    },
    {
        key: CategoryKeys.internalSecuritas,
        isActive: true,
        categoryUrlParameterKey: "internal_securitas",
    },
    {
        key: CategoryKeys.peopleAndAssets,
        isActive: true,
        categoryUrlParameterKey: "people_and_assets",
    },
    {
        key: CategoryKeys.unspecified,
        isActive: true,
    },
];
