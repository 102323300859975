import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";
import { useDebouncedCallback } from "use-debounce/lib";

import { useClickOutsideNode } from "../../../hooks/CommonHooks";
import { ContextMenuButtonTextStyled, ContextMenuWrapper } from "./ContextMenu.styles";
import { IContextMenuOption } from "./ContextMenuItem";
import ContextMenuList from "./ContextMenuList";

type Props = {
    label: string;
    options: IContextMenuOption[];
};

const ContextMenu = ({ label, options }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const handleClose = useCallback(() => {
        setIsExpanded(false);
    }, []);

    const onClickOutsideHandler = useCallback(() => {
        if (isMobile) {
            return;
        }
        setIsExpanded(false);
    }, [isMobile]);

    const { nodeRef, handleClickOutsideNode } = useClickOutsideNode(onClickOutsideHandler);

    const [debounced] = useDebouncedCallback(() => {
        setIsExpanded(false);
    }, 50);

    useEffect(() => {
        window.addEventListener("scroll", debounced, true);
        return () => {
            window.removeEventListener("scroll", debounced);
        };
    }, [nodeRef, isExpanded, debounced]);

    useOnEscapeEventListener(handleClose, nodeRef, isExpanded, true);

    const handleClick = useCallback((option: IContextMenuOption) => {
        option.onClick();
        setIsExpanded(false);
    }, []);

    const onWrapperClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (isMobile) {
                return;
            }

            handleClickOutsideNode(event);
        },
        [isMobile, handleClickOutsideNode],
    );

    const onToggleClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded, setIsExpanded]);

    const onContextMenuCancel = useCallback(() => {
        if (isMobile) {
            setIsExpanded(!isExpanded);
            return;
        }
    }, [isExpanded, isMobile]);

    return (
        <ContextMenuWrapper ref={nodeRef} onClick={onWrapperClick} role="wrapper">
            <ContextMenuButtonTextStyled icon="Options" iconSize="L" onClick={onToggleClick} role="toggle" />
            {isExpanded && <ContextMenuList label={label} options={options} onClick={handleClick} onCancel={onContextMenuCancel} />}
        </ContextMenuWrapper>
    );
};

export default ContextMenu;
