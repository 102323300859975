import _orderBy from "lodash/orderBy";
import _pick from "lodash/pick";
import type { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { SiteEventsData } from "./SiteEventsWidget.types";

export const filterSiteEventsCountData = (data: SiteEventsData, categories: CategoryLevel1[]): SiteEventsData => {
    if (!data?.length || !categories?.length) {
        return [];
    }

    const result = data.reduce((acc, item) => {
        const sum = categories.reduce((acc, category) => {
            return acc + (item.categories[category] || 0);
        }, 0);

        if (sum) {
            acc.push({
                locationId: item.locationId,
                totalCount: sum,
                categories: _pick(item.categories, categories),
            });
        }

        return acc;
    }, []);

    return _orderBy(result, ["totalCount"], ["desc"]);
};
