import { ChipFilter, Spacing } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

export const ChipFilterStyled = styled(ChipFilter)`
    &:focus-visible {
        border-radius: ${Spacing.XXL}px;
        outline-offset: ${FocusStyles.OutlineOffsetNegative}px;
    }
`;
