import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const CategoriesStack = styled(Stack)`
    gap: ${Spacing.XS}px;

    @media (max-width: ${Breakpoints.XS}) {
        row-gap: 0;
    }
`;
