import { Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export type HorizontalLineProps = {
    color?: string;
    marginRight?: string | number;
    marginLeft?: string | number;
    marginTop?: string | number;
    marginBottom?: string | number;
    borderHeight?: string | number;
};

export const HorizontalLine = styled.div<HorizontalLineProps>`
    min-height: 1px;
    max-height: 1px;
    background-color: ${({ color }) => (color ? color : Palette.Navy500)};
    margin-top: ${({ marginTop }) => marginTop ?? Spacing.XS}px;
    margin-bottom: ${({ marginBottom }) => marginBottom ?? Spacing.XS}px;
    margin-left: ${({ marginLeft }) => marginLeft ?? "initial"}px;
    margin-right: ${({ marginRight }) => marginRight ?? "initial"}px;
`;
