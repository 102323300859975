import { createSlice } from "@reduxjs/toolkit";
import { getSitesObjectWithUniqueNames } from "src/helpers/LocationsHelper";
import { BaseLocationLabel, mapToGraphNode } from "src/models/BaseLocationModel";

import { authorizationApi } from "../authorisation/authorizationApi";
import { RequestStatus } from "../RequestStatus";
import { ILocationsState } from "./types";

export const LOCATIONS_INITIAL_STATE: ILocationsState = {
    descendantsFetchStatus: RequestStatus.undefined,
    groups: [],
    siteObjects: [],
};

export const locationsSlice = createSlice({
    name: "locations",
    initialState: LOCATIONS_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(authorizationApi.endpoints.getClientDescendantsByLabel.matchPending, (state) => {
                state.descendantsFetchStatus = RequestStatus.loading;
            })
            .addMatcher(authorizationApi.endpoints.getClientDescendantsByLabel.matchFulfilled, (state, action) => {
                state.descendantsFetchStatus = RequestStatus.success;
                const groupLocations: Record<string, string[]> = {};
                action.payload.results.forEach((location) => {
                    if (location.label === BaseLocationLabel.SiteObject) {
                        state.siteObjects.push(mapToGraphNode(location));
                        const parentGroups = location.parents.filter((parent) => parent.label === BaseLocationLabel.Group);
                        parentGroups.forEach((parentGroup) => {
                            if (groupLocations[parentGroup.id]) {
                                groupLocations[parentGroup.id].push(location.id);
                            } else {
                                groupLocations[parentGroup.id] = [location.id];
                            }
                        });
                    }
                });
                state.siteObjects = getSitesObjectWithUniqueNames(state.siteObjects);
                action.payload.results.forEach((location) => {
                    if (location.label !== BaseLocationLabel.Group) {
                        return;
                    }
                    const parent =
                        location.parents.find((parent) => parent.label === BaseLocationLabel.Group) ??
                        location.parents.find((parent) => parent.label === BaseLocationLabel.Client);

                    state.groups.push({ id: location.id, parentId: parent?.id, name: location.name, locationIds: groupLocations[location.id] ?? [] });
                });
            })
            .addMatcher(authorizationApi.endpoints.getClientDescendantsByLabel.matchRejected, (state) => {
                state.descendantsFetchStatus = RequestStatus.error;
            });
    },
});
