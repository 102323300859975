import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IUser } from "../../models/UserModel";
import { RequestStatus } from "../RequestStatus";
import UserSelectors from "../user/UserSelectors";
import UserActions from "./UsersActions";
import UsersSelectors from "./UsersSelectors";

export const useUsers = (
    usersIds: string[],
): {
    users: IUser[];
    fetchStatus: RequestStatus;
} => {
    const dispatch = useDispatch();
    const usersState = useSelector(UsersSelectors.getUsers);
    const currentUser = useSelector(UserSelectors.getUserInfo);

    const usersToFetch: string[] = useMemo(() => {
        return usersIds?.filter((id) => currentUser.info?.id !== id && !usersState.users[id]);
    }, [usersIds, usersState, currentUser]);

    useEffect(() => {
        if (usersToFetch?.length && usersState.status !== RequestStatus.loading && currentUser.info) {
            dispatch(UserActions.getUsersByIds(usersToFetch));
        }
    }, [dispatch, usersToFetch, usersState, currentUser]);

    const fetchStatus: RequestStatus = useMemo(() => {
        if (usersToFetch.length) {
            return usersState.status;
        }
        return RequestStatus.success;
    }, [usersState, usersToFetch]);

    const users: IUser[] = useMemo(() => {
        return Object.values(usersState.users).concat(currentUser.info);
    }, [usersState, currentUser]);

    return {
        users: users,
        fetchStatus,
    };
};
