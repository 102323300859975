import { parseSqliteResult } from "../../../../helpers/parseSqliteResult";
import { WorkerExecResult } from "../../../../models/DbModel";
import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import {
    getDateTimeFieldName,
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "../../../../sql/scripts/queryUtilities";

export const getDeviantHoursQuery = ({
    siteIds,
    startDate,
    endDate,
    withSiteZone,
}: { siteIds: string[]; startDate: Date; endDate: Date } & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    const dateFieldName = getDateTimeFieldName(withSiteZone);

    return {
        sql: `
            SELECT strftime('%H', ${dateFieldName}) as hour, count(id) as totalEvents
            FROM reports
            WHERE category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY hour
            ORDER BY hour ASC
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseDeviantHours = ({ results }: WorkerExecResult) => {
    return parseSqliteResult<{ totalEvents: number }>(results[0], true);
};
