import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";

import { CellWrapperStyled } from "./OtherCell.styles";

type OtherCellProps = {
    otherIncidentCount: number;
};

export const OtherCell = ({ otherIncidentCount }: OtherCellProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <>
            {!isMobile && (
                <CellWrapperStyled disabled={true}>
                    <Text color={otherIncidentCount === 0 ? "disabled" : "primary"}>{otherIncidentCount}</Text>
                </CellWrapperStyled>
            )}
        </>
    );
};
