import { Separator, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const PostalAddressIconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${Spacing.XXXS}px;
`;

export const SeparatorStyled = styled(Separator)`
    min-height: ${Spacing.S}px;
`;
