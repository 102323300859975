import { createExplicitDateFromReference, getStartDateByEndTimeAndReferenceDate } from "../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { AlarmResponseFields } from "../../../models/reportTemplates";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { DateRangeSummaryField } from "../SharedComponents/DateRangeSummaryField";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getAlarmResponseReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.description.title",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    title: getFieldValueByFieldName(AlarmResponseFields.title, report),
                    description: getFieldValueByFieldName(AlarmResponseFields.description, report),
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.detailedInfo.alarmReportSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateRangeSummaryField,
                props: {
                    from: getStartDateByEndTimeAndReferenceDate(
                        getFieldValueByFieldName(AlarmResponseFields.timeArrivedOnSite, report),
                        getFieldValueByFieldName(AlarmResponseFields.timeDepartedFromSite, report),
                        report.reportDateTime,
                    ),
                    to: createExplicitDateFromReference(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AlarmResponseFields.timeDepartedFromSite, report),
                        report.reportDateTime,
                    ),
                    fieldName: "reportdetails.guardOnSite",
                    desktopDirection: "row",
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.onSiteContact",
                    fieldValue: getFieldValueByFieldName(AlarmResponseFields.onSiteContact, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(AlarmResponseFields)),
        ],
    },
];
