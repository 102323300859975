import { Context, createContext as createReactContext, useContext as useReactContext } from "react";

type CreateContextProps<T> = {
    defaultValue?: T;
    providerName?: string;
};

type CreateContextReturn<T> = [Context<T>, <S extends T = T>() => S];

export const createContext = <T>(props: CreateContextProps<T> = {}) => {
    const { defaultValue, providerName } = props;

    const Context = createReactContext<T | undefined>(defaultValue);

    Context.displayName = providerName ?? "NoName";

    const useContext = () => {
        const context = useReactContext(Context);

        if (!context) {
            const error = new Error(`use${providerName} returned \`undefined\`. Seems you forgot to wrap component within ${providerName}`);
            error.name = "ContextError";
            Error.captureStackTrace?.(error, useContext);
            throw error;
        }

        return context;
    };

    return [Context, useContext] as CreateContextReturn<T>;
};
