import { Banner, Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../../styles/templates/Flex";
import { ReportDetailsContainer } from "./Generic.styles";

export const SharedReportWrapper = styled.div`
    ${flexColumn}
    flex: 1;
    width: 100%;

    @media (min-width: ${Breakpoints.S}) {
        ${ReportDetailsContainer} {
            width: calc(50% - 1px);
        }
    }
`;

export const LinkExpiredBanner = styled(Banner)`
    height: 56px;
    width: 100vw;
    background-color: ${Palette.Purple600};
    z-index: 9999;
    padding-left: ${Spacing.XL - Spacing.XXS}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding-left: ${Spacing.S}px;
    }
`;
