import { unescape } from "lodash";

import { RequestStatus } from "../RequestStatus";

export const APP_ID = "Global";

export const makeUserMentionInComment = (userId: string) => `@<${userId}>`;

export interface ICommentDto {
    appContext?: string;
    appId?: string;
    content: string;
    createdTime: string;
    creatorId: string;
    id: string;
    isDeleted: boolean;
    lastModifiedTime?: string;
    mentionedUserIds: string[];
    referredEntityId: string;
}

export interface IUpsertCommentDto {
    id?: string;
    appContext?: string;
    appId: string;
    creatorId: string;
    comment: string;
    mentionedUserIds: string[];
}
export interface IDeleteCommentDto {
    appContext?: string;
    appId: string;
    creatorId: string;
    id: string;
}

export interface ICommentModel extends ICommentDto {
    updateStatus: RequestStatus;
}

export const mapComment = (commentDto: ICommentDto): ICommentModel => {
    return { ...commentDto, content: unescape(commentDto.content), updateStatus: RequestStatus.undefined };
};
