import { EVENTS_DEFAULT_CATEGORIES } from "src/pages/Insights/Events/Events.constants";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";

export const useCategoryInfo = (categoryKey: string | null): { categories: string[]; level: number } => {
    const { reportCategories } = useReportCategories();
    const isCategoryKeyValid = useIsCategoryKeyValid(categoryKey);

    if (!categoryKey) {
        return { categories: EVENTS_DEFAULT_CATEGORIES, level: 1 };
    }

    if (!isCategoryKeyValid) {
        return null;
    }

    for (const category of reportCategories) {
        if (category.key === categoryKey) {
            return {
                categories: category.level2Categories.map((subCategory) => subCategory.key),
                level: 2,
            };
        }

        const level2Category = category.level2Categories.find((subCategory) => subCategory.key === categoryKey);
        if (level2Category) {
            return {
                categories: level2Category.level3Categories.map((subCategory) => subCategory.key),
                level: 3,
            };
        }
    }

    return { categories: EVENTS_DEFAULT_CATEGORIES, level: 1 };
};

const useIsCategoryKeyValid = (categoryKey: string): boolean => {
    const { reportCategories } = useReportCategories();

    return reportCategories.some(
        (level1) =>
            level1.key === categoryKey ||
            level1.level2Categories.some((level2) => level2.key === categoryKey || level2.level3Categories.some((level3) => level3.key === categoryKey)),
    );
};
