import { Image, Text, View } from "@react-pdf/renderer";

import { styles } from "../pdfStyles";

type PdfLogoHeaderProps = {
    logo: string;
};

export const PdfLogoHeader = ({ logo }: PdfLogoHeaderProps) => {
    return (
        <View fixed style={styles.logoSection}>
            <Image style={styles.logo} src={logo} />
        </View>
    );
};

type PdfHeaderProps = {
    header: string;
    subheader?: string;
    sideText?: string;
};

export const PdfTemplateHeadline = ({ header, subheader, sideText }: PdfHeaderProps) => {
    return (
        <View style={styles.headlineWrapper}>
            <Text style={styles.headlineHeaderText}>{header}</Text>
            {subheader && <Text style={styles.headlineSubheaderText}>{subheader.toUpperCase()}</Text>}
            {sideText && <Text style={styles.headlineSideText}>{sideText}</Text>}
        </View>
    );
};
