export enum DataTimeRange {
    day,
    week,
    month,
    halfYear,
    anyRange,
}

export enum CategoryKey {
    categoryLevel1 = "categoryLevel1",
    categoryLevel2 = "categoryLevel2",
}

export type PatrolDeviationItem = {
    count: number;
    histCount: number;
};

export type PatrolDeviationData = {
    [k: string]: PatrolDeviationItem | string | PatrolDeviationData[];
};

export type PatrolDeviationResponse = {
    categoryLevel1: string;
    count: number;
};

export type HourlyPatrolDeviationResponse = PatrolDeviationResponse & {
    categoryLevel2: string;
    hours: string;
};
export type DailyPatrolDeviationResponse = PatrolDeviationResponse & {
    categoryLevel2: string;
    dateTime: string;
};
