import { Palette, Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TileContainer } from "src/components/Insights/common/InsightTile";
import { TileLoader } from "src/components/Insights/TileLoader/TileLoader";
import { EmptyInboxContent } from "src/components/shared/NoContent/EmptyInboxContent";
import { useFilterableReportTypes } from "src/hooks/ReportTypeHooks";
import { useBlueCards } from "src/store/insights/CardsHooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";
import styled from "styled-components";

import { cardWidth } from "./Cards.styled";
import { CardsCarousel } from "./CardsCarousel";
import { CardsSection } from "./CardsSection";
import { GenerateImprovementCards } from "./ImprovementCardsGenerator";
import { GenerateSummaryCards } from "./SummaryCardsGenerator";

const EmptyStateBackground = styled(Stack)`
    background-color: ${Palette.Navy750};
`;

const EmptyState = () => {
    const { t } = useTranslation();
    return (
        <EmptyStateBackground>
            <EmptyInboxContent
                title={t("insights.guardKpis.emptyState.title")}
                subtitle={t("insights.guardKpis.emptyState.subtitle")}
                mt="XL"
                mr="S"
                mb="XL"
                ml="S"
            />
        </EmptyStateBackground>
    );
};

export const BlueCards = () => {
    const { t } = useTranslation();
    const { isLoading, cards } = useBlueCards();
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const { defaultInsightsReportTypes } = useFilterableReportTypes();

    const slides = useMemo(() => {
        if (isLoading || !cards) {
            return [];
        }

        const summaryCards = GenerateSummaryCards(cards, cardWidth, selectedRegions, defaultInsightsReportTypes);

        if (!summaryCards.length) {
            return [];
        }

        return GenerateImprovementCards(new Date(), cardWidth).concat(summaryCards);
    }, [cards, isLoading, selectedRegions, defaultInsightsReportTypes]);

    return (
        <TileContainer>
            <CardsSection title={t("insights.guardKpis.title")} subtitle={t("insights.guardKpis.subtitle")}>
                {isLoading ? <TileLoader height="370px" /> : slides.length ? <CardsCarousel cardWidth={cardWidth}>{slides}</CardsCarousel> : <EmptyState />}
            </CardsSection>
        </TileContainer>
    );
};
