import { Breakpoints, Icon, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckpointIcon } from "../../../../assets/icons/checkpoints_icon.svg";
import { TABLE_TOTAL_KEY } from "../../../../store/insights/InsightsModel";
import { getTrendArrow } from "../../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import { getScannedCheckpointsPercentage } from "../PatrolTasks.helpers";
import { IconWrapper, StyledTourNameCell } from "./ToursTable.styles";

export const TourNameCell = ({ cell }) => {
    const { t } = useTranslation();

    if (cell === TABLE_TOTAL_KEY) {
        return (
            <Text small bold color="neutral">
                {t("insights.exceptionTrend.total_count")}
            </Text>
        );
    }
    return (
        <StyledTourNameCell alignItems="center" gap="XXS">
            <Icon size={"M"} variant="Route" />
            <Text truncate>{cell}</Text>
        </StyledTourNameCell>
    );
};
export const CompletedCell = ({ row }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const trend = getTrendArrow(row.histCount, row.count);
    const isTotal = row?.name === TABLE_TOTAL_KEY;

    return (
        <Stack alignItems="center" gap="XXS">
            {isMobile && <Icon variant="Route" />}
            <Text small={isTotal} bold={isTotal}>
                {row.count}
            </Text>
            {trend && <Icon size={isTotal ? "S" : "M"} variant={trend} />}
        </Stack>
    );
};
export const DeviationsCell = ({ row }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const trend = getTrendArrow(row.histExceptionsCount, row.exceptionsCount);
    const isTotal = row?.name === TABLE_TOTAL_KEY;
    return (
        <Stack alignItems="center" gap="XXS">
            {isMobile && <Icon variant="Deviations" />}
            <Text small={isTotal} bold={isTotal}>
                {row.exceptionsCount}
            </Text>
            {trend && <Icon size={isTotal ? "S" : "M"} variant={trend} />}
        </Stack>
    );
};

export const CheckpointsCell = ({ row }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const trend = getTrendArrow(
        getScannedCheckpointsPercentage(row.histCheckpointsMissed, row.histCheckpointsScanned),
        getScannedCheckpointsPercentage(row.checkpointsMissed, row.checkpointsScanned),
    );
    const isTotal = row?.name === TABLE_TOTAL_KEY;

    return (
        <Stack alignItems="center" gap="XXS">
            {isMobile && (
                <IconWrapper>
                    <CheckpointIcon />
                </IconWrapper>
            )}
            <Text small={isTotal} bold={isTotal}>
                {getScannedCheckpointsPercentage(row.checkpointsMissed, row.checkpointsScanned)} %
            </Text>
            {trend && <Icon size={isTotal ? "S" : "M"} variant={trend} />}
        </Stack>
    );
};

export const ToursDataHeader = (header: string) => {
    const { t } = useTranslation();
    const isSmallScreen = useHasMaxWidth(Breakpoints.S);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    if (isMobile) {
        return t(`insights.toursTable.header.${header}`);
    }
    if (isSmallScreen) {
        if (header === "completed") {
            return <Icon color="neutral" variant="Route" />;
        }
        if (header === "deviations") {
            return <Icon color="neutral" variant="Deviations" />;
        }
        if (header === "checkpoints") {
            return <CheckpointIcon />;
        }
    } else {
        return t(`insights.toursTable.header.${header}`);
    }
};
