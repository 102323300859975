export enum CalloutFields {
    additionalDescription = "additionalDescription",
    personalData = "enterPersonalData",
    alarmCallType = "alarm/CallType",
    additionalInformation = "additionalInformation",
    didAnIncidentOccurOnSite = "didAnIncidentOccurOn-site?",
    didAnEventOccurOnSite = "didAnEventOccurOn-site?",
    describeTheIncident = "pleaseDescribeTheIncident(excludePersonalData)",
    describeTheEvent = "pleaseDescribeTheEvent(excludePersonalData)",
    alarmOrganization = "dispatchAlarmOrganization",
    dispatchJob = "dispatchJob",
    dispatchJobId = "dispatchJobId",
    dispatchWorkflow = "dispatchWorkflow",
}
