import { useCallback, useMemo, useState } from "react";
import type { BarRectangleItem } from "recharts/types/cartesian/Bar";
import { getAxisTicks } from "src/helpers/graphs";

import { DEFAUT_X_AXIS_WIDTH, DEFAUT_X_AXIS_WIDTH_VERTICAL } from "./Graph.constants";
import { GraphBarSize, GraphData } from "./Graph.types";

const getBarSize = (barSize?: GraphBarSize): number | undefined => {
    switch (barSize) {
        case "sm":
            return 14;
        case "md":
            return 28;
        default:
            return undefined;
    }
};

export const useBarChart = (data: GraphData, maxValue?: number, tickCount?: number, barSize?: GraphBarSize) => {
    const chartMaxBarSize = getBarSize(barSize);

    const YAxisTicks: number[] = useMemo(() => {
        if (!maxValue || !tickCount) {
            return undefined;
        }
        return getAxisTicks(maxValue, tickCount);
    }, [maxValue, tickCount]);

    const chartData: GraphData = useMemo(() => {
        if (!data.length) {
            return [{ key: "", name: "", value: null }];
        }
        return data;
    }, [data]);

    const YAxisDomain: number[] = useMemo(() => {
        return [0, maxValue || Math.max(...chartData.map((x) => x.value))];
    }, [chartData, maxValue]);

    return {
        chartData,
        chartMaxBarSize,
        YAxisDomain,
        YAxisTicks,
    };
};

export const useTooltip = (isVertical?: boolean) => {
    const [activeBarPostion, setActiveBarPostion] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [tooltipSize, setTooltipSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    const tooltipPosition = useMemo(() => {
        return {
            x: Math.floor(activeBarPostion.x) - tooltipSize.width / 2,
            y: activeBarPostion.y - tooltipSize.height,
        };
    }, [activeBarPostion, tooltipSize]);

    const updateActiveBarPosition = useCallback(
        (data: BarRectangleItem) => {
            const x = data.x + data.width / 2;
            const y = isVertical ? data.y - data.height / 2 : data.background.height - data.height;

            setActiveBarPostion({ x, y });
        },
        [isVertical],
    );

    return {
        tooltipPosition,
        isTooltipVisible,
        setTooltipSize,
        setIsTooltipVisible,
        updateActiveBarPosition,
    };
};

export const useTickFormatter = (isVertical?: boolean) => {
    const [yAxisWidth, setYAxisWidth] = useState<number>();

    const tickFormatter = useCallback(
        (value: number) => {
            // the only way to access domain values in order to resize y-axis width. ref on yAxis does not work in recharts
            const tickWidth = value?.toString()?.length;
            const defaultXAxisWidth = isVertical ? DEFAUT_X_AXIS_WIDTH_VERTICAL : DEFAUT_X_AXIS_WIDTH;
            const newWidth = defaultXAxisWidth + (tickWidth - 1) * 8;

            if (tickWidth > 2 && (!yAxisWidth || newWidth > yAxisWidth)) {
                setTimeout(() => {
                    setYAxisWidth(newWidth);
                });
            }

            return value.toString();
        },
        [isVertical, yAxisWidth],
    );

    return {
        yAxisWidth,
        tickFormatter,
    };
};
