import { Headline, Icon, Skeleton, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { TranslatableEmptyInboxComponent } from "../../../../components/shared/NoContent/EmptyInboxContent";
import { Tooltip } from "../../../../components/shared/Tooltip";
import { CompareTableParent } from "../../../../store/insights/InsightsPatrolTasksModel";
import { SectionWrapper, TooltipContainer } from "../PatrolTasks.styled";
import { CompareTable } from "./CompareLocationsConfig";
import { TooltipContent } from "./TooltipContent";

type Props = {
    tourSessionsSummaryByLocation: CompareTableParent[];
    isEmpty: boolean;
    isLoading: boolean;
};

export const CompareLocations = ({ tourSessionsSummaryByLocation, isLoading, isEmpty }: Props) => {
    const { t } = useTranslation();
    const { isFilterActive } = useInsightsPageContext();

    if (tourSessionsSummaryByLocation.length) {
        return (
            <SectionWrapper isFilterActive={isFilterActive} display="flex" direction="column" data-testid="insights-tasks-compare">
                <TooltipContainer>
                    <Headline bold>{t("insights.patrolTours.compareTitle")}</Headline>
                    <Tooltip title={t("insights.patrolTours.compareTitle")} message={<TooltipContent />} place="top-start">
                        <Icon variant={"Info"} size="M"></Icon>
                    </Tooltip>
                </TooltipContainer>
                {isLoading ? (
                    <Stack direction="column" gap="XS" marginTop="M">
                        <Skeleton height={48} mode="rectangular" width="100%" />
                        <Skeleton height={64} mode="rectangular" width="100%" />
                        <Skeleton height={64} mode="rectangular" width="100%" />
                        <Skeleton height={64} mode="rectangular" width="100%" />
                        <Skeleton height={64} mode="rectangular" width="100%" />
                        <Skeleton height={48} mode="rectangular" width="100%" />
                    </Stack>
                ) : (
                    <>
                        {isEmpty ? (
                            <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} />
                        ) : (
                            <CompareTable tourSessions={tourSessionsSummaryByLocation} />
                        )}
                    </>
                )}
            </SectionWrapper>
        );
    }
};
