import { Breakpoints, ButtonText, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { sumBy } from "lodash";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { useRedAndPurpleCards } from "../../../store/insights/CardsHooks";
import { IHighlightsCard } from "../../../store/insights/InsightsModel";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { isFinished } from "../../../store/RequestStatus";
import { criticalCardHeight } from "../Cards/Cards.styled";
import { RedAndPurpleCards } from "../Cards/RedAndPurpleCards";
import { CardsWrapper, DetailsWrapper, FooterContentWrapper, StyledFooter, ToggleButtonWrapper } from "./Map.styles";
import { MapFooterDetails } from "./MapFooterDetails";

type Props = {
    activeSites: ISiteObject[];
    siteIds: string[];
    isMobileMapExpanded: boolean;
    isDetailsExpanded: boolean;
    isIncidentsCountLoading: boolean;
    incidents: {
        location_id: string;
        count: number;
    }[];
    handleExpandDetails: () => void;
    handleCardHover: (card: IHighlightsCard) => void;
    isMapInProgress: boolean;
};

export const MapFooter = ({
    activeSites,
    siteIds,
    isMobileMapExpanded,
    isDetailsExpanded,
    incidents,
    isIncidentsCountLoading,
    handleExpandDetails,
    handleCardHover,
    isMapInProgress,
}: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const { loadNextPage } = useRedAndPurpleCards(siteIds);
    const { cards, queryStatus } = useSelector(InsightsSelectors.getCriticalCards);
    const incidentsAmount = useMemo(() => sumBy(incidents, "count"), [incidents]);
    const [maxHeightOverride, setMaxHeightOverride] = useState(null);
    const isLoading = useMemo(() => {
        return !siteIds || !isFinished(queryStatus);
    }, [siteIds, queryStatus]);
    const footerDetailsRef = useRef<HTMLDivElement>(null);
    const footerContentRef = useRef<HTMLDivElement>(null);
    const toggleButtonRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (activeSites.length <= 1) {
            setMaxHeightOverride(null);
            return;
        }
        const detailsHeight = (footerDetailsRef.current?.clientHeight || 0) + Spacing.XS;
        if (isDetailsExpanded) {
            setMaxHeightOverride(isMobile ? detailsHeight + criticalCardHeight : detailsHeight + criticalCardHeight + Spacing.L);
            return;
        }
        setMaxHeightOverride(isMobile ? detailsHeight : detailsHeight + Spacing.XS);
    }, [isDetailsExpanded, activeSites.length, isMobile, footerDetailsRef]);

    useEffect(() => {
        if (!isDetailsExpanded && activeSites.length > 1 && footerContentRef.current) {
            footerContentRef.current.scrollTo({ top: 0 });
        }
    }, [isDetailsExpanded, activeSites.length, footerContentRef]);

    return (
        <StyledFooter data-testid="map-footer" isMobileMapExpanded={isMobileMapExpanded}>
            <ToggleButtonWrapper ref={toggleButtonRef}>
                <ButtonText
                    data-testid="map-footer-control-button"
                    onClick={handleExpandDetails}
                    small
                    direction="row-reverse"
                    icon={isDetailsExpanded ? "ArrowUp" : "ArrowDown"}
                >
                    {!isMobile && <>{isDetailsExpanded ? t("common.showLess") : t("common.showMore")}</>}
                </ButtonText>
            </ToggleButtonWrapper>
            <FooterContentWrapper ref={footerContentRef} isDetailsExpanded={isDetailsExpanded} maxHeightOverride={maxHeightOverride}>
                <DetailsWrapper rightMargin={toggleButtonRef.current?.offsetWidth || 0}>
                    <MapFooterDetails
                        inProgress={isIncidentsCountLoading || isMapInProgress || isLoading}
                        incidents={incidents}
                        activeSites={activeSites}
                        incidentsAmount={incidentsAmount}
                        ref={footerDetailsRef}
                        key={`${isDetailsExpanded}-${activeSites.length}`}
                    />
                </DetailsWrapper>
                <CardsWrapper isDetailsExpanded={isDetailsExpanded} data-testid="map-cards-wrapper">
                    <RedAndPurpleCards
                        data-testid="red-and-purple-cards"
                        isLoading={isIncidentsCountLoading || isMapInProgress || isLoading}
                        loadNextPage={loadNextPage}
                        onHoverCard={handleCardHover}
                        cards={cards}
                    />
                </CardsWrapper>
            </FooterContentWrapper>
        </StyledFooter>
    );
};
