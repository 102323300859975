export const getAxisTicks = (value: number, ticksCount: number): number[] => {
    const result = [];
    const nonZeroTicksCount = ticksCount >= 2 ? ticksCount - 1 : 1;
    const step = Math.ceil(value / nonZeroTicksCount);

    if (value === 0) {
        return [0];
    }

    if (value < nonZeroTicksCount) {
        return [0, value];
    }

    for (let i = 0; i <= nonZeroTicksCount; i++) {
        result.push(i * step);
    }

    return result;
};
