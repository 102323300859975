import { Breakpoints, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Tooltip } from "../../shared/Tooltip";

export const HeaderTooltip = styled(Tooltip)`
    display: flex;

    @media (max-width: ${Breakpoints.XS}) {
        align-self: center;
    }
    & > div {
        display: flex;
    }
`;

export const Header = styled(Stack)`
    align-items: center;
    margin-right: ${Spacing.S}px;

    div[class^="text"] {
        margin-left: ${Spacing.XS}px;
    }
    .__react_component_tooltip.place-top:after {
        left: 20px;
    }

    @media (min-width: ${Breakpoints.XS}) {
        .__react_component_tooltip.show {
            padding: 16px;
        }
    }
`;

export const HeaderContainer = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-bottom: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        align-items: normal;
    }
`;

export const HeaderText = styled(Text)`
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    overflow: hidden;
    overflow-wrap: break-word;
`;

export const HeaderTextTooltip = styled(Tooltip)`
    margin-right: ${Spacing.S}px;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;

    @media (max-width: ${Breakpoints.S}) {
        margin-right: ${Spacing.XS}px;
    }
`;
