import { Icon, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { ScheduleType } from "src/store/schedule/types";
import styled from "styled-components";

type Props = {
    cell: ScheduleType;
};

const WIDTH = Spacing.XL;

const IconWrapperStyled = styled(Stack)`
    background-color: ${Palette.Navy100};
    border-radius: ${Spacing.S}px;
    width: ${WIDTH}px;
`;

const EmptyStateWrapperStyled = styled(Stack)`
    width: ${WIDTH}px;
`;

export const ScheduleTypeCell = ({ cell }: Props) => {
    if (cell === ScheduleType.recurring) {
        return (
            <IconWrapperStyled paddingVertical="XXXS" paddingHorizontal="S">
                <Icon size="S" color="dark" variant="Recurring" />
            </IconWrapperStyled>
        );
    }
    return (
        <EmptyStateWrapperStyled justifyContent="center">
            <Text small>-</Text>
        </EmptyStateWrapperStyled>
    );
};
