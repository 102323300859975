import { IncidentsLocationsType } from "../../store/insights/InsightsModel";
import { ROUND_VALUES_LIST } from "./constants";
import { ChartDataType } from "./types";

export { addLineDirectionToData } from "./addLineDirectionToData";
export { getAxisTicks } from "./getAxisTicks";
export { mapIncidentsLocationsToColors } from "./mapIncidentsLocationsToColors";

export type { GraphDataItem } from "./types";

// value is real data, visualValue is for chart to display thin line for 0 real data value
// to display thin line in chart for 0 value we take biggest value from data divide it by 180 (not 360 to make line a little bit thicker)
// and pass it to items with value 0. value for tooltips and labels, visualValue for pie cell
export const fillChartVisualValues = (incidents: (IncidentsLocationsType & { color: string })[]): ChartDataType[] => {
    const biggestValue = incidents.reduce((acc, { value }) => (value <= acc ? acc : value), 0);
    const mockValue = biggestValue / 180;
    return incidents.map((val) => ({
        ...val,
        key: val.name,
        label: val.name,
        visualValue: val.value === 0 ? mockValue : val.value,
    }));
};

export const mapIncidentsToVisualValues = (
    incidents: { key: string; name: string; value: number }[],
): { visualValue: number; key: string; name: string; value: number }[] => {
    const biggestValue = incidents.reduce((acc, { value }) => (value <= acc ? acc : value), 0);
    // 2% of maximum height
    const mockValue = biggestValue / 50;
    return incidents.map((val) => ({
        ...val,
        visualValue: val.value > 0 && val.value < mockValue ? mockValue : val.value,
    }));
};

export const removeActiveChartElements = (): void => {
    const activeElements = document.getElementsByClassName("recharts-sector active");
    for (const el of activeElements) {
        el.classList.remove("active");
    }
};

export const getGraphMaxValue = (value: number): number => {
    const firstDigitsCount = value < 10 ? 1 : 2;
    const multiplier = Math.pow(10, value.toString().length - firstDigitsCount);
    const firstDigits = Math.ceil(value / multiplier);
    const roundValue = ROUND_VALUES_LIST.find((x) => x >= firstDigits);

    return roundValue * multiplier;
};

export const getLettersFromNumber = (index: number): string => {
    let result = "";
    index++;

    while (index > 0) {
        const remainder = (index - 1) % 26;
        result = String.fromCharCode(65 + remainder) + result;
        index = Math.floor((index - 1) / 26);
    }

    return result;
};
