import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { contactInfoByCountry } from "src/data/contacts";

import { styles } from "../pdfStyles";
import { LocationIconPdf, PhoneIconPdf, PostalAddressIconPdf } from "./Icons";
import { DynamicTemplateSectionPdf } from "./PdfDynamicTemplateSection";

type Props = {
    countryCode: string;
};

export const PdfContactInfo = ({ countryCode = "" }: Props) => {
    const { t } = useTranslation();
    const contactInfo = contactInfoByCountry[countryCode?.toLowerCase()];

    if (!contactInfo) {
        return null;
    }

    return (
        <View wrap={false}>
            <DynamicTemplateSectionPdf label={t("reportdetails.contactInfo.header")} renderLineBreak={false} marginTop="S">
                <View style={{ ...styles.inlineFullWidth, fontSize: 11 }} wrap={false}>
                    <Text style={styles.summaryFieldName}>{contactInfo.companyName}</Text>
                    <Text style={{ flex: 1, textAlign: "right", fontSize: 9 }}>
                        {t("reportdetails.contactInfo.businessID")}: {contactInfo.businessId}
                    </Text>
                </View>
                <View style={{ ...styles.sectionLineBreak, marginTop: 8 }}></View>
                <View style={{ ...styles.detailsLine, marginTop: 6 }}>
                    <View style={{ ...styles.detailsWrappableWrapper, marginBottom: 4 }}>
                        <View style={styles.detailsIconContainer}>
                            <LocationIconPdf />
                            <Text style={styles.locationBoldText}>{contactInfo.address}</Text>
                        </View>
                        <View style={styles.verticalDivider} />
                        <View style={styles.detailsIconContainer}>
                            <PhoneIconPdf />
                            <Text style={styles.locationBoldText}>{contactInfo.phone}</Text>
                        </View>
                        <View style={styles.verticalDivider} />
                        <View style={styles.detailsIconContainer}>
                            <PostalAddressIconPdf />
                            <Text style={styles.locationBoldText}>{contactInfo.postalAddress}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.sectionLineBreak, marginTop: 2 }}></View>
            </DynamicTemplateSectionPdf>
        </View>
    );
};
