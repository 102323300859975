import { PropsWithChildren, useMemo, useState } from "react";
import { createContext } from "src/helpers/createContext";
export type PlacementSide = "top" | "left" | "right" | "bottom";
export type TooltipPlacementContextType = {
    tooltipWidth: number;
    tooltipHeight: number;
    tooltipPosition: { x: number; y: number } | null;
    placementSide: PlacementSide;
    setTooltipPosition: (position: { x: number; y: number }) => void;
    saveTooltipWidth: (width: number) => void;
    saveTooltipHeight: (height: number) => void;
    setPlacementSide: (side: PlacementSide) => void;
};

const [TooltipPlacementContext, useTooltipPlacementContext] = createContext<TooltipPlacementContextType>({
    defaultValue: {
        tooltipWidth: 0,
        tooltipHeight: 0,
        tooltipPosition: null,
        placementSide: "top",
        setTooltipPosition: () => {},
        saveTooltipWidth: () => {},
        saveTooltipHeight: () => {},
        setPlacementSide: () => {},
    },
    providerName: "TooltipPlacement",
});

export const TooltipPlacementProvider = ({ children }: PropsWithChildren) => {
    const [tooltipWidth, setTooltipWidth] = useState(0);
    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [placementSide, setPlacementSide] = useState<PlacementSide>("top");

    const providerValue: TooltipPlacementContextType = useMemo(
        () => ({
            tooltipWidth,
            tooltipHeight,
            tooltipPosition,
            placementSide,
            saveTooltipWidth: setTooltipWidth,
            saveTooltipHeight: setTooltipHeight,
            setTooltipPosition,
            setPlacementSide,
        }),
        [tooltipWidth, tooltipHeight, tooltipPosition, setTooltipHeight, setTooltipWidth, setTooltipPosition],
    );

    return <TooltipPlacementContext.Provider value={providerValue}>{children}</TooltipPlacementContext.Provider>;
};

export { useTooltipPlacementContext };
