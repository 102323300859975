/**
 *
 * @param n number of dates that should be generated
 * getRandomDates returns an array of @param n dates as a strings generated for given @param month and @param year
 */
export const getRandomDates = (n: number, month: number, year: number): number[] => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based in JavaScript

    // If the specified month is in the future, return an empty array
    if (year > currentYear || (year === currentYear && month > currentMonth)) {
        return [];
    }

    const daysInMonth = new Date(year, month, 0).getDate();
    const randomDates: number[] = [];

    for (let i = 0; i < n; i++) {
        let randomDay: number, randomHour: number, randomMinute: number, randomSecond: number;

        do {
            randomDay = Math.floor(Math.random() * daysInMonth) + 1;
            randomHour = Math.floor(Math.random() * 24);
            randomMinute = Math.floor(Math.random() * 60);
            randomSecond = Math.floor(Math.random() * 60);
            const generatedDate = new Date(year, month - 1, randomDay);
            // Check if the generated date is not after the current date
            if (generatedDate <= currentDate) {
                const hour = isCurrentDay(generatedDate, currentDate) ? new Date(currentDate).getHours() - 1 : randomHour;

                randomDates.push(new Date(year, month - 1, randomDay, hour, randomMinute, randomSecond).getTime());
            }
        } while (randomDates.length < i + 1); // Repeat until valid date is generated
    }

    // Sort the dates in descending order
    randomDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    return randomDates;
};

export const isCurrentDay = (date1: Date, date2: Date) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export default getRandomDates;
