import { NavigationLinkModel } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { NavigationStyled } from "./Navbar.styles";

export const Navbar = () => {
    const { t } = useTranslation();

    const links: NavigationLinkModel[] = [
        { to: "/insights", icon: "Insights", label: t("navbar.link.insights") },
        { to: "/reports", icon: "Report", label: t("navbar.link.reports") },
        { to: "/sites", icon: "Building", label: t("navbar.link.objects") },
    ];
    return <NavigationStyled title={t("navigation.title")} isExpanded isExpandable links={links} />;
};
