import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { IReportImage } from "../../../../models/ReportImagesModels";
import { DynamicReportType } from "../../../../models/ReportModel";
import { VehicleInspectionFields } from "../../../../models/reportTemplates/VehicleInspection";
import { base64Assets } from "../../../PdfReports/Base64Assets";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { PdfImagesCarousel } from "../../../PdfReports/shared/PdfImageSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { PdfLogoHeader } from "../../../PdfReports/shared/PdfTemplateHeadline";
import { TextTextAreaSummaryFieldPdf } from "../../../PdfReports/shared/PdfTextTextAreaSummaryField";
import { PdfVehicleInspectionDetailsHeader } from "../../../PdfReports/VehicleInspection/PdfVehicleInspectionDetailsHeader/PdfVehicleInspectionDetailsHeader";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { shouldRenderVehicleStatusSection, VEHICLE_IMAGE_HEIGHT_IN_PX } from "../vehicleInspectionReportConfig";

export const getVehicleInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    {
        component: PdfLogoHeader,
        props: {
            logo: `data:image/png;base64,${base64Assets.securitasLogo}`,
        },
    },
    {
        component: PdfVehicleInspectionDetailsHeader,
        props: { report, siteObject, isShared: !!sharedReportId, user },
    },
    getVehicleInspectionDescriptionSection(report, images),
    getIndicateTheLocationsOfDamageToTheVehicleSection(images),
    getVehicleInspectionSummarySection(report, images),
    getVehicleStatusSection(report),
];

function getVehicleInspectionDescriptionSection(report: DynamicReportType, images: IReportImage[]): DynamicComponentConfigType {
    return conditionalRender(!!getFieldValueByFieldName(VehicleInspectionFields.comments, report) || report.images.length, () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.vehicleInspection.vehicleInspectionDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName(VehicleInspectionFields.comments, report),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        };
    });
}

const PX_TO_PT_RATIO = 72 / 96;

function getIndicateTheLocationsOfDamageToTheVehicleSection(images: IReportImage[]): DynamicComponentConfigType {
    const vehicleImage = images?.find((i) => i.reportFieldName === VehicleInspectionFields.indicateTheLocationsOfDamageToTheVehicle);

    return conditionalRender(vehicleImage, () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.vehicleInspection.indicateTheLocationsOfDamageToTheVehicle",
            marginTop: "S",
        },
        children: [
            {
                component: PdfImagesCarousel,
                props: {
                    images: [vehicleImage],
                    imageStyle: {
                        marginRight: 4,
                        maxHeight: VEHICLE_IMAGE_HEIGHT_IN_PX * PX_TO_PT_RATIO,
                        objectFit: "contain",
                        objectPosition: "left",
                    },
                },
            },
        ],
    }));
}

function getVehicleInspectionSummarySection(report: DynamicReportType, images: IReportImage[]): DynamicComponentConfigType {
    return {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.vehicleInspection.vehicleInspectionSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.exteriorDemage",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.exteriorDamage, report),
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.interiorDamage",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.interiorDamage, report),
                    shouldAutoTranslate: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.brokenCrackedWindows",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.brokenCrackedWindows, report),
                    shouldAutoTranslate: true,
                },
            },
            getEquipmentTextTextAreaSummaryField(report),
            ...getPdfUnknownFields(report, Object.values(VehicleInspectionFields), images),
        ],
    };
}

function getEquipmentTextTextAreaSummaryField(report: DynamicReportType): DynamicComponentConfigType {
    const isAllEmergencyEquipmentAvailable = getFieldValueByFieldName(VehicleInspectionFields.isAllEmergencyEquipmentAvailable, report);
    const missingEquipment = getFieldValueByFieldName(VehicleInspectionFields.missingEquipment, report);

    const value =
        isAllEmergencyEquipmentAvailable || missingEquipment
            ? {
                  textSummaryFieldProps: {
                      fieldName: "reportdetails.vehicleInspection.isAllEmergencyEquipmentAvailable",
                      fieldValue: isAllEmergencyEquipmentAvailable,
                      isRequired: false,
                      shouldAutoTranslate: true,
                  },
                  textAreaSummaryFieldProps: {
                      fieldName: "reportdetails.vehicleInspection.missingEquipment",
                      fieldValue: missingEquipment,
                      isRequired: false,
                      shouldAutoTranslate: true,
                  },
              }
            : null;

    return {
        component: TextTextAreaSummaryFieldPdf,
        props: {
            isRequired: false,
            fieldValue: value,
        },
    };
}

function getVehicleStatusSection(report: DynamicReportType): DynamicComponentConfigType {
    return conditionalRender(shouldRenderVehicleStatusSection(report), () => ({
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.vehicleInspection.vehicleStatus",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.mileageStart",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.mileageStart, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.mileageEnd",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.mileageEnd, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.fuelLevel",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.fuelLevel, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.vehicleInspection.oilLevel",
                    fieldValue: getFieldValueByFieldName(VehicleInspectionFields.oilLevel, report),
                    isRequired: false,
                },
            },
        ],
    }));
}
