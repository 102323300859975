import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { ToursRequestBody } from "../../../store/insights/InsightsModel";
import { db } from "../../db";
import { createDateTimeFilter } from "../../helpers";

export const getLocationsTourSessionSummaryResolver = (payload: ToursRequestBody) => {
    const filter = buildFilter(payload);

    const tours = db.reports.findMany({
        where: filter,
    });
    const byLocationId = tours.reduce((acc, tour) => {
        const hasMissedCheckpoints = tour.checkpointsMissedTotal;

        if (acc[tour.locationId]) {
            acc[tour.locationId].exceptionsCount += tour.exceptionReportIds.length;
            acc[tour.locationId].checkpointsMissed += tour.checkpointsMissedTotal;
            acc[tour.locationId].checkpointsScanned += tour.checkpointsScannedTotal;
            acc[tour.locationId].completedCount += hasMissedCheckpoints ? 0 : 1;
            acc[tour.locationId].incompleteCount += hasMissedCheckpoints ? 1 : 0;
            acc[tour.locationId].count += 1;
        } else {
            acc[tour.locationId] = {
                locationId: tour.locationId,
                completedCount: hasMissedCheckpoints ? 0 : 1,
                incompleteCount: hasMissedCheckpoints ? 1 : 0,
                exceptionsCount: tour.exceptionReportIds?.length ?? 0,
                checkpointsMissed: tour.checkpointsMissedTotal ?? 0,
                checkpointsScanned: tour.checkpointsScannedTotal ?? 0,
                count: 1,
            };
        }

        return acc;
    }, {});

    const result = Object.values(byLocationId);

    return HttpResponse.json(result);
};

const buildFilter = (requestParams: ToursRequestBody) => {
    const locationFilter = {
        locationId: {
            in: requestParams.siteIds,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.patrolTour],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter(requestParams),
        ...typeFilter,
    };
};

export default getLocationsTourSessionSummaryResolver;
