import { useAuthorizedSiteGroups } from "../hooks/SitesGroupsHooks";
import { SitesGroupsListItem } from "./SitesGroupsListItem";

type Props = {
    selectedGroupId?: string;
    onGroupSelect: (groupId: string) => void;
};

export const SitesGroupsList = ({ selectedGroupId, onGroupSelect }: Props) => {
    const siteGroups = useAuthorizedSiteGroups();
    const visibleSiteGroups = siteGroups.filter((x) => x.parentId === selectedGroupId);

    if (!visibleSiteGroups.length) {
        return null;
    }

    return (
        <div data-testid="sites-group-list">
            {visibleSiteGroups.map((group) => (
                <SitesGroupsListItem
                    key={group.id}
                    data-testid="site-group-item"
                    title={group.name}
                    sitesCount={group.descendantSitesCount}
                    onClick={() => onGroupSelect(group.id)}
                />
            ))}
        </div>
    );
};
