import { Checkbox, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../styles/templates/Flex";

export const ItemContainer = styled.div`
    ${flexColumn}
    position: relative;
    min-width: 0;
`;

export const ItemCheckbox = styled(Checkbox)`
    max-width: 100%;
    margin-top: 3px;
    & ~ div label {
        overflow: visible;
        white-space: pre-wrap;
        display: inline-block;
        word-wrap: break-word;
    }
`;

export const DropdownList = styled.ul`
    padding: 0px;
    scrollbar-color: ${Palette.Navy500} transparent;
`;
