import { sha256 } from "js-sha256";
import { RSAA } from "redux-api-middleware";
import { v4 as uuidv4 } from "uuid";

import { batchArray } from "../../helpers/ArrayHelper";
import { getOperatingSystem } from "../../helpers/osHelpers";
import { MixpanelEvent } from "../../models/tracking/mixpanelEvents";
import { IMixpanelPageViewAdditionalProperties, IMixpanelPageViewProperties, PageName } from "../../models/tracking/pageView";
import { UserType } from "../../models/tracking/tracking.model";
import { IAvailableClient } from "../clientSwitcher/types";
import { APP_TRACKING, TRACK_EVENT } from "./types";

interface ITrackingAction<T> {
    type: string;
    payload: { event: string; properties: T };
}

const trackEvent = (event: string, properties?: unknown): ITrackingAction<unknown> => ({
    type: TRACK_EVENT,
    payload: {
        event,
        properties,
    },
});

const initializeTracking = (
    selectedClient: IAvailableClient,
    userType: UserType,
): { type: string; payload: { selectedClient: IAvailableClient; userType: UserType } } => ({
    type: APP_TRACKING,
    payload: { selectedClient, userType },
});

const trackPageVisit = (pageName: PageName, additionalData?: IMixpanelPageViewAdditionalProperties): ITrackingAction<IMixpanelPageViewProperties> => {
    const properties: IMixpanelPageViewProperties = {
        Page: window.location.pathname,
        "Page Title": pageName,
        ...additionalData,
    };

    return {
        type: TRACK_EVENT,
        payload: {
            event: MixpanelEvent.PageView,
            properties,
        },
    };
};

//batchSize introduced to limit amount of calls to mixpanel when user have a lot of sites, one request can hold up to 50 events: https://developer.mixpanel.com/reference/track-event
const sendUniqueSites = (dispatch, userId: string, sitesData: { countryCode: string; siteId: string }[], batchSize = 50) => {
    const batches = batchArray(sitesData, batchSize);
    batches.forEach((batch) => {
        const events = batch.map((item) => ({
            event: "Site Accessed",
            properties: {
                token: process.env.REACT_APP_MIXPANEL_TOKEN,
                Country: item.countryCode,
                "Site Id": item.siteId,
                $insert_id: uuidv4(),
                distinct_id: sha256(userId),
            },
        }));
        dispatch({
            [RSAA]: {
                accessless: true,
                silent: true,
                endpoint: `https://api-eu.mixpanel.com/track${process.env.NODE_ENV === "development" ? "/?verbose=1" : ""}`,
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `data=${JSON.stringify(events)}`,
                types: ["SITE_ACCESSED_ID_REQUEST", "SITE_ACCESSED_ID_SUCCESS", "SITE_ACCESSED_ID_FAIL"],
            },
        });
    });
};

const sendReachAndAdoption = (
    dispatch,
    userId: string,
    clientId: string,
    clientName: string,
    userType: UserType,
    sitesData: { countryCode: string; siteIds: string[] }[],
) => {
    const os = getOperatingSystem();
    sitesData.forEach((batch) => {
        const request = {
            userId,
            clientId,
            clientName,
            countryCode: batch.countryCode,
            siteIds: batch.siteIds,
            operatingSystem: os,
            userType,
        };
        dispatch({
            [RSAA]: {
                auth: true,
                endpoint: `/v1/global/tracking/reach`,
                method: "POST",
                body: request,
                types: ["REACH_AND_ADOPTION_REQUEST", "REACH_AND_ADOPTION_SUCCESS", "REACH_AND_ADOPTION_FAIL"],
            },
        });
    });
};

export default {
    initializeTracking,
    sendReachAndAdoption,
    sendUniqueSites,
    trackEvent,
    trackPageVisit,
};
