import { createContext, useMemo } from "react";
import { useWebHubGlobalUrl } from "src/hooks/WebHubHooks";

import { ChildrenProps } from "../models/ChildrenPropModel";
import { IntercomContextModel, IntercomTopic } from "../models/IntercomModel";

export const IntercomContext = createContext<IntercomContextModel>({} as IntercomContextModel);

export const IntercomProvider = ({ children }: ChildrenProps) => {
    const webHubGlobalUrl = useWebHubGlobalUrl();

    const postMessage = (topic: IntercomTopic, message: string) => {
        const payload = {
            topic,
            message,
            timestamp: new Date(),
        };
        window.parent.postMessage(payload, webHubGlobalUrl);
    };

    const contextValue = useMemo(() => {
        return { postMessage };
    }, []);

    return <IntercomContext.Provider value={contextValue}>{children}</IntercomContext.Provider>;
};
