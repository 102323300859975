import { Box, ButtonIcon, ButtonText, Stack } from "@secuis/ccp-react-components";
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChildrenProps } from "../../../models/ChildrenPropModel";

type AccordionContextProps = {
    activeItems: string[];
    setActiveItems: Dispatch<SetStateAction<string[]>>;
};

type AccordionItemContextProps = {
    id: string;
};

export const AccordionContext = createContext({} as AccordionContextProps);
const AccordionItemContext = createContext({} as AccordionItemContextProps);

const Accordion = ({ children }: ChildrenProps) => {
    const [activeItems, setActiveItems] = useState<string[]>([]);
    return (
        <AccordionContext.Provider
            value={{
                activeItems,
                setActiveItems,
            }}
        >
            {children}
        </AccordionContext.Provider>
    );
};

Accordion.Item = function Item({ children, id }: { children: ReactNode; id: string }) {
    return <AccordionItemContext.Provider value={{ id }}>{children}</AccordionItemContext.Provider>;
};

Accordion.Header = function Header({ children, hasContent }: { children: ReactNode; hasContent: boolean }) {
    const { activeItems, setActiveItems } = useContext(AccordionContext);
    const { id } = useContext(AccordionItemContext);

    const isActive = useMemo(() => {
        return activeItems.includes(id);
    }, [activeItems, id]);

    const toggleContent = useCallback(() => {
        if (isActive) {
            setActiveItems(activeItems.filter((i) => i !== id));
            return;
        }

        setActiveItems([...activeItems, id]);
    }, [activeItems, id, isActive, setActiveItems]);

    const headerButton = useMemo(() => {
        if (hasContent) {
            return (
                <Box ml="XS">
                    <ButtonText data-testid="collapse-button" color="primary" icon={isActive ? "Minus" : "Plus"} onClick={toggleContent} />
                </Box>
            );
        }
        // if there is no content, return invisible button to preserve proper spacing and text trim
        return <ButtonText style={{ visibility: "hidden" }} data-testid="hidden-button" icon="ArrowDown" />;
    }, [hasContent, isActive, toggleContent]);

    return (
        <Stack justifyContent="space-between" mb="XS">
            {children}
            {headerButton}
        </Stack>
    );
};

Accordion.Back = function Back({ filterActiveItems }: { filterActiveItems: string[] }) {
    const { setActiveItems } = useContext(AccordionContext);
    const { t } = useTranslation();

    const goBack = () => {
        setActiveItems((items) => items.slice(0, -1));
    };

    const goToTop = () => {
        setActiveItems([]);
    };

    return (
        filterActiveItems.length !== 0 && (
            <Stack justifyContent="space-between" alignItems="center" onClick={goBack} mb={"S"}>
                <ButtonText data-testid="back-navigation-button" icon="BackNavigation">
                    {t("common.back")}
                </ButtonText>
                <ButtonIcon data-testid="icon-clear-button" icon="Clear" onClick={goToTop} mode="stateless" iconSize={"L"} />
            </Stack>
        )
    );
};

Accordion.Content = function Content({ children }: ChildrenProps) {
    const { activeItems } = useContext(AccordionContext);
    const { id } = useContext(AccordionItemContext);
    return activeItems.includes(id) ? <>{children}</> : null;
};

export default Accordion;
