import i18next from "i18next";
import { JSX } from "react";

import { HighlightsCategories } from "../../../models";
import {
    accessControlCategories,
    accessControlSystemsCategories,
    confrontationCategories,
    equipmentAccidentCategories,
    medicalAssistanceCategories,
    reportedAuditsCategories,
} from "../../../sql/scripts/insights/highlightsCategories";
import { IBlueCards } from "../../../store/insights/InsightsModel";
import { SummaryCard } from "./SummaryCard";
import { SUMMARY_CARDS_CONFIGURATION } from "./SummaryCardsConfiguration";

const generateUrl = (categories: HighlightsCategories[], startDate: string, endDate: string, locationIds: string[], reportTypes: string[]): string => {
    const url = categories
        .flatMap((x) => x.categoriesLevel3)
        .reduce((urlString, categoryName) => {
            urlString += `selectedCategoryThree=${categoryName}&`;
            return urlString;
        }, "reports?")
        .slice(0, -1);

    return `/${url}&selectedStartDate=${startDate}&selectedEndDate=${endDate}&selectedReportTypes=${reportTypes.toString()}&selectedRegions=${locationIds.toString()}`;
};

export const GenerateSummaryCards = (cards: IBlueCards, cardWidth: number, locationIds: string[], reportTypes: string[]): JSX.Element[] => {
    const summaryCards = [];
    if (cards.medicalAssistanceCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.health_and_safety")}
                subheaderText={i18next.t("insights.highlights.medicalAssistance.header")}
                count={cards.medicalAssistanceCount.current}
                prevPeriodCount={cards.medicalAssistanceCount.prev}
                url={generateUrl(
                    medicalAssistanceCategories,
                    SUMMARY_CARDS_CONFIGURATION.medicalAssistanceCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.medicalAssistanceCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="medical_assistance_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.medicalAssistanceCategories.dbQueryDateRange}
            />,
        );
    }

    if (cards.accessControlCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.people_and_assets")}
                subheaderText={i18next.t("incident.category.paa_access_control")}
                count={cards.accessControlCount.current}
                prevPeriodCount={cards.accessControlCount.prev}
                url={generateUrl(
                    accessControlCategories,
                    SUMMARY_CARDS_CONFIGURATION.accessControlCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.accessControlCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="access_control_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.accessControlCategories.dbQueryDateRange}
            />,
        );
    }

    if (cards.accessControlSystemsCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.facility")}
                subheaderText={i18next.t("incident.category.fac_access_control_systems")}
                count={cards.accessControlSystemsCount.current}
                prevPeriodCount={cards.accessControlSystemsCount.prev}
                url={generateUrl(
                    accessControlSystemsCategories,
                    SUMMARY_CARDS_CONFIGURATION.accessControlSystemsCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.accessControlSystemsCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="access_control_systems_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.accessControlSystemsCategories.dbQueryDateRange}
            />,
        );
    }

    if (cards.confrontationCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.health_and_safety")}
                subheaderText={i18next.t("insights.highlights.confrontation.header")}
                count={cards.confrontationCount.current}
                prevPeriodCount={cards.confrontationCount.prev}
                url={generateUrl(
                    confrontationCategories,
                    SUMMARY_CARDS_CONFIGURATION.confrontationCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.confrontationCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="confrontation_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.confrontationCategories.dbQueryDateRange}
            />,
        );
    }

    if (cards.equipmentAccidentCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.health_and_safety")}
                subheaderText={i18next.t("incident.category.equipment_accident.header")}
                count={cards.equipmentAccidentCount.current}
                prevPeriodCount={cards.equipmentAccidentCount.prev}
                url={generateUrl(
                    equipmentAccidentCategories,
                    SUMMARY_CARDS_CONFIGURATION.equipmentAccidentCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.equipmentAccidentCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="equipment_accident_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.equipmentAccidentCategories.dbQueryDateRange}
            />,
        );
    }

    if (cards.reportedAuditsCount?.current) {
        summaryCards.push(
            <SummaryCard
                headerText={i18next.t("incident.category.health_and_safety")}
                subheaderText={i18next.t("incident.category.reported_audits.header")}
                secondarySubheaderText={i18next.t("incident.category.reported_audits.secondarySubheader")}
                count={cards.reportedAuditsCount.current}
                prevPeriodCount={cards.reportedAuditsCount.prev}
                url={generateUrl(
                    reportedAuditsCategories,
                    SUMMARY_CARDS_CONFIGURATION.reportedAuditsCategories.urlStartDate,
                    SUMMARY_CARDS_CONFIGURATION.reportedAuditsCategories.urlEndDate,
                    locationIds,
                    reportTypes,
                )}
                cardWidth={cardWidth}
                key="reported_audits_quick_card"
                dateRange={SUMMARY_CARDS_CONFIGURATION.reportedAuditsCategories.dbQueryDateRange}
            />,
        );
    }

    return summaryCards;
};
