import { OpenFeatureProvider } from "@openfeature/react-sdk";
import { Document, Font, Link, Page, Text, View } from "@react-pdf/renderer";
import { Palette } from "@secuis/ccp-react-components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Provider, useSelector } from "react-redux";

import { ChildrenProps } from "../../models/ChildrenPropModel";
import { store } from "../../Store";
import UserSelectors from "../../store/user/UserSelectors";
import { ldClientName } from "../FeatureFlags/FeatureFlagProvider";
import { InMemoryFeatureFlagProvider } from "../InMemoryFeatureFlagProvider";
import { base64Assets } from "./Base64Assets";
import { styles } from "./pdfStyles";
import { PdfContactInfo } from "./shared/PdfContactInfo";

type PdfReportContainerProps = {
    children?: ReactNode | ReactNode[];
    footerLinkUrl: string;
    countryCode: string;
};

const PdfFeatureFlagProvider = ({ children }: ChildrenProps) => {
    const userId = useSelector(UserSelectors.getUserId);

    if (userId) {
        return <OpenFeatureProvider clientName={ldClientName}>{children}</OpenFeatureProvider>;
    } else {
        return <InMemoryFeatureFlagProvider>{children}</InMemoryFeatureFlagProvider>;
    }
};

export const PdfReportContainer = ({ children, footerLinkUrl, countryCode }: PdfReportContainerProps) => {
    const { t } = useTranslation();

    Font.register({
        family: "SecuritasPro-Regular",
        fonts: [
            { src: "data:font/truetype;base64," + base64Assets.securitasFont },
            { src: "data:font/truetype;base64," + base64Assets.securitasFontBold, fontWeight: "bold" },
            { src: "data:font/truetype;base64," + base64Assets.securitasFontItalic, fontStyle: "italic" },
        ],
    });

    Font.registerHyphenationCallback((word) => [word]);

    return (
        <Document>
            <Page style={styles.body}>
                <Provider store={store}>
                    <PdfFeatureFlagProvider>
                        {children}
                        <PdfContactInfo countryCode={countryCode} />
                        <View fixed style={styles.footerLine} />
                        <Text fixed style={styles.footerText}>
                            {`${t("reportdetails.readFullReportOnMySecuritas")} `}
                            <Link style={{ color: Palette.Purple400 }} src={footerLinkUrl}>
                                {footerLinkUrl.replace(/^https?:\/\//, "")}
                            </Link>
                        </Text>
                        <Text
                            fixed
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) =>
                                `${t("common.page")} ${!!pageNumber && pageNumber} ${t("common.of")} ${!!totalPages && totalPages}`
                            }
                        />
                    </PdfFeatureFlagProvider>
                </Provider>
            </Page>
        </Document>
    );
};
