import { Breakpoints } from "@secuis/ccp-react-components";
import { useEffect, useMemo, useState } from "react";

import { MobileBreakpoints } from "../styles/Constants";
import { useIsDevice } from "./CommonHooks";

export const useIsInViewport = (ref: any): boolean => {
    const [isInViewport, setIsInViewport] = useState(false);
    const isMobileOrTablet = useIsDevice(Breakpoints.XS, MobileBreakpoints.LandscapeHeight);

    const options: IntersectionObserverInit = useMemo(() => {
        return { threshold: isMobileOrTablet ? 0.5 : 0.8 };
    }, [isMobileOrTablet]);

    const callbackFn = (entries) => {
        const [entry] = entries;
        setIsInViewport(entry.isIntersecting);
    };

    const refCurrent = ref?.current;

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFn, options);
        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref) observer.disconnect();
        };
    }, [options, ref, refCurrent]);

    return isInViewport;
};
