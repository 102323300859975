import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loading } from "./components/shared/Loading";
import { featureFlags } from "./data/featureFlags";
import { useFeatureFlag } from "./hooks/featureFlags";
import { disableMock, enableMock } from "./mocks";
import LocationsSelectors from "./store/locations/LocationsSelectors";
import { isFinished } from "./store/RequestStatus";

export const DemoResolver = ({ children }: PropsWithChildren) => {
    const isDemoClientFeatureEnabled = useFeatureFlag(featureFlags.inMemoryDemoClient);
    const { descendantsFetchStatus, siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDemoWorkerActive, setIsDemoWorkerActive] = useState<boolean>(false);

    const manageMock = useCallback(async () => {
        setIsLoading(true);

        if (isDemoClientFeatureEnabled) {
            await enableMock(siteObjects);
            setIsDemoWorkerActive(true);
        } else {
            if (isDemoWorkerActive) {
                await disableMock();
                setIsDemoWorkerActive(false);
            } else {
                await disableMock();
            }
        }

        setIsLoading(false);
    }, [isDemoClientFeatureEnabled, isDemoWorkerActive, siteObjects]);

    useEffect(() => {
        if (isFinished(descendantsFetchStatus)) {
            manageMock();
        }
    }, [descendantsFetchStatus, manageMock]);

    return <>{isLoading ? <Loading /> : <>{children}</>}</>;
};
