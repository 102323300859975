import { DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { ReportDetailsHeader } from "../SharedComponents/ReportDetailsHeader/ReportDetailsHeader";

export const getBaseReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>) => [
    {
        component: ReportDetailsHeader,
        props: {
            report: report,
            siteObject: siteObject,
            isShared: !!sharedReportId,
        },
    },
];
