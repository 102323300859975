import { parseSqliteResult } from "src/helpers/parseSqliteResult";
import { CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import {
    getDateTimeFieldName,
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "src/sql/scripts/queryUtilities";

export const query = ({ siteIds, startDate, endDate, withSiteZone }: { siteIds: string[]; startDate: Date; endDate: Date } & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    const dateFieldName = getDateTimeFieldName(withSiteZone);

    return {
        sql: `
            SELECT strftime('%w', ${dateFieldName}) as weekDay, count(id) as totalEvents
            FROM reports
            WHERE category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY weekDay
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseResult = ({ results }) => parseSqliteResult<{ weekDay: string; totalEvents: number }>(results[0], true);
