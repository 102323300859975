// import { StrictMode } from "react";
import "./styles.css";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import mixpanel from "mixpanel-browser";
import { createRoot } from "react-dom/client";

import App from "./App";

// initailize MixPanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: "https://api-eu.mixpanel.com" });

const onRedirectCallback = (appState: AppState) => {
    window.location.href = appState.targetUrl || appState.returnTo || window.location.pathname;
};

const root = createRoot(document.getElementById("root"));
root.render(
    <>
        {/**
        Strict mode is useful tool for finding bugs during development: https://react.dev/reference/react/StrictMode.
        It fires hooks twice though, which will cause chaos with current http requests handling.
        Using it will be possible after switch from using RSAA + useEffect hooks to RTK.
        Feel free to check it, but please do it only locally.
    */}
        {/*<StrictMode> */}
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            responseType="id_token"
            scope="openid profile email"
            onRedirectCallback={onRedirectCallback}
        >
            <App />
        </Auth0Provider>
        {/*</StrictMode> */}
    </>,
);
