import { createSelector } from "reselect";

import { IState } from "../../Store";
import { RequestStatus } from "../RequestStatus";

const mapAvailableClients = ({ clientSwitcher: { availableClients: availableClientsState } }: IState) => availableClientsState;
const getAvailableClients = createSelector([mapAvailableClients], ({ results, totalCount, skip, status }) => ({ results, totalCount, skip, status }));

const mapSelectedClient = ({ clientSwitcher: { selectedClient: selectedClientState } }: IState) => selectedClientState;
const getSelectedClient = createSelector(mapSelectedClient, (selectedClient) => selectedClient);

const mapClientTotalCount = ({ clientSwitcher: { initialClientCount, initialStatus } }: IState) => ({ initialClientCount, initialStatus });
const getClientTotalCount = createSelector(mapClientTotalCount, ({ initialClientCount, initialStatus }) => ({ initialClientCount, initialStatus }));

const mapUserClientAuthorizations = ({ clientSwitcher: { userAuthorizations, selectedClient } }: IState) => ({ userAuthorizations, selectedClient });
const getIsClientUser = createSelector(mapUserClientAuthorizations, (userClientAuthorizations) =>
    userClientAuthorizations.selectedClient?.id ? !!userClientAuthorizations.userAuthorizations[userClientAuthorizations.selectedClient.id]?.authorized : false,
);
const getUserClientAuthorizationStatus = createSelector(mapUserClientAuthorizations, (userClientAuthorizations) =>
    userClientAuthorizations.selectedClient?.id
        ? userClientAuthorizations.userAuthorizations[userClientAuthorizations.selectedClient.id]?.status ?? RequestStatus.undefined
        : RequestStatus.undefined,
);

const mapClientAuthorizations = ({ clientSwitcher: { clientAuthorizations } }: IState) => ({ clientAuthorizations });
const getClientAuthorizationsData = createSelector(mapClientAuthorizations, ({ clientAuthorizations }) => clientAuthorizations.data);
const getClientAuthorizationsStatus = createSelector(mapClientAuthorizations, ({ clientAuthorizations }) => clientAuthorizations.status);

const selectors = {
    getAvailableClients,
    getIsClientUser,
    getSelectedClient,
    getClientTotalCount,
    getUserClientAuthorizationStatus,
    getClientAuthorizationsData,
    getClientAuthorizationsStatus,
};

export default selectors;
